import partnerDetailsActionTypes from './partner-details.types';

const INITIAL_STATE = {
  partnerDetails: [],
  error: null,
  isLoading: false
};

const partnerDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_START:
      return { ...state, isLoading: true };
    case partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_SUCCESS:
      return { ...state, partnerDetails: action.payload, error: null };
    case partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_FAILURE:
      return { ...state, error: action.payload };
    case partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default partnerDetailsReducer;
