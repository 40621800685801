import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import CategorizationTypes from './categorization.types';
import api from '../../api/api.jsx';
import {
  fetchCategorizationMediaSuccess,
  fetchCategorizationMediaFailure,
  fetchCategorizationMediaFinished,
  sendCategorizationDataSuccess,
  sendCategorizationDataFailure,
  sendCategorizationDataFinished
} from './categorization.actions';
import { mediasBuilderWithPages } from '../../shared/builders/medias';

export const selectCreateProgram = state => state.createProgram;

export function* fetchCategorizationMedia({ payload: media }) {
  try {
    const { data } = yield call(api.getCategorizationMedias, media);
    const { communes } = yield select(selectCreateProgram);
    const dataBuilded = yield mediasBuilderWithPages(data, communes);
    yield put(fetchCategorizationMediaSuccess(dataBuilded));
  } catch (error) {
    yield put(fetchCategorizationMediaFailure(error));
  }
  yield put(fetchCategorizationMediaFinished());
}

export function* sendCategorizationMedia({ payload: media }) {
  try {
    const { data } = yield call(api.patchCategorizationMedias, media);
    yield put(sendCategorizationDataSuccess(data));
  } catch (error) {
    yield put(sendCategorizationDataFailure(error));
  }
  yield put(sendCategorizationDataFinished());
}

export function* onFetchCategorizationMedia() {
  yield takeLatest(
    CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_START,
    fetchCategorizationMedia
  );
}

export function* onSendCategorizationMedia() {
  yield takeLatest(
    CategorizationTypes.SEND_CATEGORIZATION_DATA_START,
    sendCategorizationMedia
  );
}

export function* categorizationSagas() {
  yield all([
    call(onFetchCategorizationMedia),
    call(onSendCategorizationMedia)
  ]);
}
