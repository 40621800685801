import loginActionTypes from './loginTypes';

const INITIAL_STATE = {
  login: null,
  error: null,
  isLoading: false
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case loginActionTypes.FETCH_LOGIN_START:
    case loginActionTypes.FETCH_LOGIN_UPDATE_START:
      return { ...state, isLoading: true };
    case loginActionTypes.FETCH_LOGIN_SUCCESS:
    case loginActionTypes.FETCH_LOGIN_UPDATE_SUCCESS:
      return { ...state, login: action.payload, error: null };
    case loginActionTypes.FETCH_LOGIN_FAILURE:
    case loginActionTypes.FETCH_LOGIN_UPDATE_FAILURE:
      return { ...state, error: action.payload };
    case loginActionTypes.FETCH_LOGIN_FINISHED:
    case loginActionTypes.FETCH_LOGIN_UPDATE_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default loginReducer;
