import ModificationTypes from './partner-modification.types';

export const sendModificationPartnerDataStart = data => ({
  type: ModificationTypes.SEND_MODIFICATION_PARTNER_DATA_START,
  payload: data
});

export const sendModificationPartnerDataSuccess = partnerUrl => ({
  type: ModificationTypes.SEND_MODIFICATION_PARTNER_DATA_SUCCESS,
  payload: partnerUrl
});

export const sendModificationPartnerDataFailure = error => ({
  type: ModificationTypes.SEND_MODIFICATION_PARTNER_DATA_FAILURE,
  payload: error
});

export const sendModificationPartnerDataFinished = () => ({
  type: ModificationTypes.SEND_MODIFICATION_PARTNER_DATA_FINISHED
});
