export function formatListBuilder(data) {
  return data?.values?.map(format => {
    return {
      shortLabel: format?.shortLabel,
      longLabel: format?.longLabel,
      order: format?.order,
      valueCode: format?.valueCode,
      parentCode: format?.parentCode,
      status: format?.status,
      startValidityDate: format?.startValidityDate,
      endValidityDate: format?.endValidityDate,
      refValue: format?.refValue
    };
  });
}
