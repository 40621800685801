import { put, all, call, takeLatest } from 'redux-saga/effects';
import societyQualitiesActionTypes from './society-qualities.types';
import apiNomenclature from '../../api/apiNomenclature';
import {
  fetchSocietyQualitiesSuccess,
  fetchSocietyQualitiesFailure,
  fetchSocietyQualitiesFinished
} from './society-qualities.actions';
import { societyQualityBuilder } from './society-qualities.builder';

export const selectCreateProgram = state => state.createProgram;

export function* fetchSocietyQualities() {
  try {
    const { data } = yield call(apiNomenclature.getListOfQualites);
    const dataBuilder = yield call(societyQualityBuilder, data);
    yield put(fetchSocietyQualitiesSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSocietyQualitiesFailure(error));
  }
  yield put(fetchSocietyQualitiesFinished());
}

export function* onFetchSocietyQualitiesStart() {
  yield takeLatest(
    societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_START,
    fetchSocietyQualities
  );
}

export function* SocietyQualitiesSagas() {
  yield all([call(onFetchSocietyQualitiesStart)]);
}
