const societyBuilder = organizationalEntity => {
  return organizationalEntity?.result?.map(item => {
    return {
      refOrganizationalEntity: item.refOrganizationalEntity,
      organizationalEntityCode: item.organizationalEntityCode,
      pathOrganizationalEntity: item.pathOrganizationalEntity,
      organizationalEntityParent: item.legalStructureProgramParent?.reference,
      shortLabel: item.shortLabel,
      name: item.shortLabel,
      status: item.currentLifeCycle.status
    };
  });
};

export function organizationalEntityBuilder(data) {
  return societyBuilder(data);
}
