import LegalEntityTypes from './legalEntity.types';

export const fetchLegalEntityStart = params => ({
  type: LegalEntityTypes.FETCH_LEGALENTITY_START,
  payload: params
});

export const fetchLegalEntitySuccess = params => ({
  type: LegalEntityTypes.FETCH_LEGALENTITY_SUCCESS,
  payload: params
});

export const fetchLegalEntityFailure = error => ({
  type: LegalEntityTypes.FETCH_LEGALENTITY_FAILURE,
  payload: error
});

export const fetchLegalEntityFinished = () => ({
  type: LegalEntityTypes.FETCH_LEGALENTITY_FINISHED
});
