import { put, all, call, takeLatest } from 'redux-saga/effects';
import SearchActionTypes from './search.types';
import { searchBuilder } from './search-builder';
import api from '../../api/api.jsx';
import {
  fetchSearchResultsSuccess,
  fetchSearchResultsFailure,
  fetchSearchResultsFinished,
  fetchSearchResultsWithMediaSuccess,
  fetchSearchResultsWithMediaFailure,
  fetchSearchResultsWithMediaFinished,
  fetchSearchResultsWithoutMediaSuccess,
  fetchSearchResultsWithoutMediaFailure,
  fetchSearchResultsWithoutMediaFinished,
  fetchSearchSuggestionsSuccess,
  fetchSearchSuggestionsFinished,
  fetchSearchSuggestionsFailure
} from './search.actions';
import { SEARCH_TYPE } from '../../constants/global-constants';

export function* getThumbnail(thumbnail) {
  try {
    if (thumbnail.thumbnail !== undefined) {
      const result = yield call(api.getMediaThumbnail, thumbnail.thumbnail);

      return {
        thumbnail: result.headers.location,
        thumbnailApiUrl: thumbnail.thumbnail
      };
    }
    return {};
  } catch (error) {
    return error;
  }
}

export function* fetchSearchResults({ payload: params }) {
  try {
    const { data } = yield call(api.getSearchResults, params);
    // Get of items thumbnails
    const promises = data?.results
      ?.map(opt => opt?.match)
      .map(item => getThumbnail(item));
    const datathumbnails = yield all(promises);
    const dataBuilder = yield call(searchBuilder, data, datathumbnails);
    yield put(fetchSearchResultsSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSearchResultsFailure(error?.data));
  }
  yield put(fetchSearchResultsFinished());
}

export function* onFetchSearchResultsStart() {
  yield takeLatest(
    SearchActionTypes.FETCH_SEARCH_RESULTS_START,
    fetchSearchResults
  );
}

export function* fetchSearchResultsWithMedia({ payload: params }) {
  try {
    let paramsWithMedia = {
      ...params
    };

    if (params?.type === SEARCH_TYPE.program) {
      paramsWithMedia = {
        ...paramsWithMedia,
        withMedia: 'TRUE'
      };
    }
    const { data } = yield call(api.getSearchResults, paramsWithMedia);
    // Get of items thumbnails
    const promises = data?.results
      ?.map(opt => opt?.match)
      .map(item => getThumbnail(item));
    const datathumbnails = yield all(promises);
    const dataBuilder = yield call(searchBuilder, data, datathumbnails);
    yield put(fetchSearchResultsWithMediaSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSearchResultsWithMediaFailure(error?.data));
  }
  yield put(fetchSearchResultsWithMediaFinished());
}

export function* onFetchSearchResultsWithMediaStart() {
  yield takeLatest(
    SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_START,
    fetchSearchResultsWithMedia
  );
}

export function* fetchSearchResultsWithoutMedia({ payload: params }) {
  try {
    let paramsWithoutMedia = {
      ...params,
      size: 2000
    };

    if (params?.type === SEARCH_TYPE.program) {
      paramsWithoutMedia = {
        ...paramsWithoutMedia,
        withMedia: 'FALSE'
      };
    }

    const { data } = yield call(api.getSearchResults, paramsWithoutMedia);
    const dataBuilder = yield call(searchBuilder, data);
    yield put(fetchSearchResultsWithoutMediaSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSearchResultsWithoutMediaFailure(error?.data));
  }
  yield put(fetchSearchResultsWithoutMediaFinished());
}

export function* onFetchSearchResultsWithoutMediaStart() {
  yield takeLatest(
    SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_START,
    fetchSearchResultsWithoutMedia
  );
}

export function* fetchSearchSuggests({ payload: params }) {
  try {
    const { data } = yield call(api.getSearchSuggestions, params);
    yield put(fetchSearchSuggestionsSuccess(data?.results));
  } catch (error) {
    yield put(fetchSearchSuggestionsFailure(error?.data));
  }
  yield put(fetchSearchSuggestionsFinished());
}

export function* onFetchSearchSuggestsStart() {
  yield takeLatest(
    SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_START,
    fetchSearchSuggests
  );
}

export function* searchResultsSagas() {
  yield all([
    call(onFetchSearchResultsStart),
    call(onFetchSearchSuggestsStart),
    call(onFetchSearchResultsWithMediaStart),
    call(onFetchSearchResultsWithoutMediaStart)
  ]);
}
