import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import './listFiliales.scss';
import {
  InputLabel,
  Select,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';

const ListFiliales = ({
  checkedValue,
  organizationalEntitysResult,
  handleFilial,
  checked
}) => {
  return (
    <>
      {checkedValue?.map(value => {
        return (
          <InputLabel
            className="filiale-label"
            key={`filiale-select-label-${value}`}
            id={`filiale-select-label-${value}`}
          >
            {value}
          </InputLabel>
        );
      })}

      <Select labelId="filiale-select-label" id="filiale-select">
        <List>
          {organizationalEntitysResult?.map(value => {
            const labelId = { value };
            return (
              <ListItem
                role={undefined}
                dense
                button
                onClick={handleFilial(value)}
                key={uuidv4()}
              >
                <ListItemIcon key={uuidv4()}>
                  <Checkbox
                    edge="start"
                    checked={checked.includes(value.refOrganizationalEntity)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId?.value?.name }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId?.value?.name}
                  primary={labelId?.value.name}
                />
              </ListItem>
            );
          })}
        </List>
      </Select>
    </>
  );
};

ListFiliales.propTypes = {
  checkedValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  organizationalEntitysResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  handleFilial: PropTypes.func,
  checked: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

export default ListFiliales;
