import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './style.jsx';
import './style.scss';

const FullScreen = ({ open, handleClose, children }) => {
  const style = makeStyles(styles)();

  const printCloseIcon = () => (
    <IconButton
      className={style.closeButton}
      onClick={() => handleClose(false)}
    >
      <CloseIcon className="close-icon" />
    </IconButton>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog"
        onClick={() => handleClose(false)}
      >
        {printCloseIcon()}
        {children}
      </Dialog>
    </>
  );
};

FullScreen.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.element
};
export default FullScreen;
