import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import dialogTransition from '../dialogTransition/dialogTransition';
import styles from './style.jsx';
import './style.scss';

const Transition = dialogTransition;

const dialogWithValue = ({
  open,
  title,
  content,
  handleAction,
  handleClose,
  textButtonOk,
  textButtonCancel
}) => {
  const classes = makeStyles(styles)();
  const [value, setValue] = useState('');

  const setTextValue = e => {
    setValue(e.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
          {title}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            id="content_textfield"
            label={content}
            variant="outlined"
            fullWidth
            value={value}
            onChange={setTextValue}
          />
        </DialogContent>

        <DialogActions className={classes.buttons}>
          {textButtonOk?.length > 0 && (
            <Button
              onClick={() => handleAction(value)}
              id="ButtonOK"
              variant="outlined"
              color="primary"
              className={classes.buttonOk}
            >
              <span className="button__text-margin">{textButtonOk}</span>
            </Button>
          )}
          {textButtonCancel?.length > 0 && (
            <Button
              onClick={() => handleClose()}
              id="ButtonKO"
              variant="outlined"
              color="primary"
              className={classes.button}
            >
              <span className="button__text-margin">{textButtonCancel}</span>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

dialogWithValue.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  textButtonOk: PropTypes.string,
  textButtonCancel: PropTypes.string,
  handleAction: PropTypes.func,
  handleClose: PropTypes.func
};

export default dialogWithValue;
