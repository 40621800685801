import { put, all, call, takeLatest } from 'redux-saga/effects';
import ConditionsActionTypes from './conditions.types';
import api from '../../api/api.jsx';
import {
  fetchConditionsSuccess,
  fetchConditionsFailure,
  fetchConditionsFinished
} from './conditions.actions';

export function* fetchConditions() {
  try {
    const { data } = yield call(api.getConditions);
    const dataBuilded = JSON.stringify(data).toString();
    yield put(fetchConditionsSuccess(dataBuilded));
  } catch (error) {
    yield put(fetchConditionsFailure(error));
  }
  yield put(fetchConditionsFinished());
}

export function* onFetchConditionsStart() {
  yield takeLatest(
    ConditionsActionTypes.FETCH_CONDITIONS_START,
    fetchConditions
  );
}

export function* conditionsSagas() {
  yield all([call(onFetchConditionsStart)]);
}
