import ProgramListTypes from './program.list.types';

const INITIAL_STATE = {
  results: [],
  error: null,
  isLoading: false,
  resultsWithoutMedia: [],
  isLoadingForWithoutMedia: false
};

const programListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProgramListTypes.FETCH_PROGRAM_LIST_START:
      return { ...state, isLoading: true };
    case ProgramListTypes.FETCH_PROGRAM_LIST_SUCCESS:
      return { ...state, results: action.payload, error: null };
    case ProgramListTypes.FETCH_PROGRAM_LIST_FAILURE:
      return { ...state, error: action.payload };
    case ProgramListTypes.FETCH_PROGRAM_LIST_FINISHED:
      return { ...state, isLoading: false };
    case ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_START:
      return { ...state, isLoadingForWithoutMedia: true };
    case ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_SUCCESS:
      return {
        ...state,
        resultsWithoutMedia: action.payload,
        error: null
      };
    case ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_FAILURE:
      return { ...state, error: action.payload };
    case ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_FINISHED:
      return { ...state, isLoadingForWithoutMedia: false };
    default:
      return state;
  }
};

export default programListReducer;
