import SearchWithGeopositionActionTypes from './searchWithGeoposition.types';

export const fetchSearchWithGeopositionResultsStart = params => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_START,
  payload: params
});

export const fetchSearchWithGeopositionResultsSuccess = data => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_SUCCESS,
  payload: data
});

export const fetchSearchWithGeopositionResultsFailure = error => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_FAILURE,
  payload: error
});

export const fetchSearchWithGeopositionResultsFinished = () => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_FINISHED
});

export const fetchSearchWithGeopositionSuggestionsStart = params => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_START,
  payload: params
});

export const fetchSearchWithGeopositionSuggestionsSuccess = data => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_SUCCESS,
  payload: data
});

export const fetchSearchWithGeopositionSuggestionsFailure = error => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_FAILURE,
  payload: error
});

export const fetchSearchWithGeopositionSuggestionsFinished = () => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_FINISHED
});

export const fetchSearchWithGeopositionToolbar = params => ({
  type: SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_TOOLBAR,
  payload: params
});

export const fetchSearchWithGeopositionResultsWithMediaStart = params => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_START,
  payload: params
});

export const fetchSearchWithGeopositionResultsWithMediaSuccess = data => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_SUCCESS,
  payload: data
});

export const fetchSearchWithGeopositionResultsWithMediaFailure = error => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_FAILURE,
  payload: error
});

export const fetchSearchWithGeopositionResultsWithMediaFinished = () => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_FINISHED
});

export const fetchSearchWithGeopositionResultsWithoutMediaStart = params => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_START,
  payload: params
});

export const fetchSearchWithGeopositionResultsWithoutMediaSuccess = data => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_SUCCESS,
  payload: data
});

export const fetchSearchWithGeopositionResultsWithoutMediaFailure = error => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_FAILURE,
  payload: error
});

export const fetchSearchWithGeopositionResultsWithoutMediaFinished = () => ({
  type:
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_FINISHED
});
