import { store as state } from '../../redux/store';
import { PROGRAM_TYPE } from '../../constants/global-constants';

export const getCleoProgramList = () =>
  state.getState()?.programListCleo?.results?.program;

export const findCleoProgramByRef = refProgram => {
  return getCleoProgramList()?.find(
    cleo => cleo.programRef.toString() === refProgram
  );
};

export const getProgramList = () => {
  const { search, lastMedias } = state.getState();
  const concatWithMedia =
    search?.searchResults?.results?.withMedia?.concat(
      search?.searchResultsWithMedia?.results?.withMedia
    ) || [];
  const concatWithoutMedia =
    search?.searchResults?.results?.withoutMedia?.concat(
      search?.searchResultsWithMedia?.results?.withoutMedia
    ) || [];

  const generalPrograms = concatWithMedia?.concat(concatWithoutMedia);

  const withLastMediaPrograms =
    generalPrograms?.concat(lastMedias?.lastMediaResult?.results?.withMedia) ||
    [];
  return withLastMediaPrograms?.filter(prog => prog !== undefined);
};

export const findProgramByRef = refProgram => {
  const program = getProgramList()?.find(
    prog => prog?.match?.programRef === refProgram
  );
  return program?.match;
};

export const getPathOrganizationalEntitybyRef = refProgram => {
  if (refProgram) {
    const prog = findProgramByRef(refProgram);
    if (prog) {
      return prog?.section?.refSection === PROGRAM_TYPE.IE_PROGRAM_NAME
        ? [true]
        : prog?.organizationalentitypaths;
    }
  }
  return [true];
};
