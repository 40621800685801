export default {
  buttonDowload: {
    width: '240px',
    height: '48px',
    color: '#F5F6FA',
    backgroundColor: '#C82832',
    '&:hover': {
      backgroundColor: '#C82832',
      borderColor: '#C82832',
      color: '#F5F6FA'
    },
    textTransform: 'none'
  },
  showButton: {
    marginTop: '10px',
    width: '280px',
    height: '48px',
    textTransform: 'none'
  },
  carouselContainer: {
    background: '#DBE2F4',
    margin: '0px',
    display: 'flex'
  },
  iconTop: {
    borderRadius: '5px',
    display: 'table',
    verticalAlign: 'middle',
    width: '100%'
  },
  iconBot: {
    background: 'rgba(245, 246, 250, 0.5)',
    borderRadius: '5px',
    height: '30px',
    paddingTop: '6px',
    marginRight: '6px'
  }
};
