import React from 'react';
import Modal from '@material-ui/core/Modal';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles.jsx';

/**
 * Custom Modal Component based on Material UI Modal.
 */
const ModalComponent = ({ body, open, onClose }) => {
  const classes = makeStyles(styles)();

  return (
    <div>
      <Modal open={open} onClose={onClose} className={classes.modal}>
        {body}
      </Modal>
    </div>
  );
};

ModalComponent.propTypes = {
  open: PropTypes.bool,
  body: PropTypes.node,
  onClose: PropTypes.func
};

export default ModalComponent;
