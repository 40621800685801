import React, { useEffect } from 'react';
import './Faq.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { canEdit } from '../../shared/rights/root-rights.js';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import Spinner from '../../components/spinner/Spinner';
import TextEditor from '../../components/textEditor/TextEditor';
import t from '../../constants/translates';
import { fetchFaqStart } from '../../redux/faq/faq.actions';
import {
  selectFaqsResults,
  selectFaqsIsLoading
} from '../../redux/faq/faq.selectors';
import { sendPutFaqDataStart } from '../../redux/putFaq/putFaqActions';
import {
  selectPutFaqIsLoading,
  selectPutFaqSucceed
} from '../../redux/putFaq/putFaqSelectors';
import { ATTRIBUTES } from '../../shared/rights/roles';

const Faq = ({
  getFaq,
  faqResult,
  putFaq,
  selectFaqsIsLoadingProp,
  selectPutFaqIsLoadingProp,
  selectPutFaqSucceedProp
}) => {
  useEffect(() => {
    getFaq();
  }, []);

  useEffect(() => {
    if (selectPutFaqSucceedProp) {
      getFaq();
    }
  }, [selectPutFaqSucceedProp]);

  const getLoadings = () => {
    return selectFaqsIsLoadingProp || selectPutFaqIsLoadingProp;
  };

  return (
    <>
      {getLoadings() ? (
        <Spinner className="spinner" isLoading />
      ) : (
        <>
          <DismissableAlert />
          <h1 className="title">{t.footer.help}</h1>
          <div className="container">
            {faqResult?.length > 2 ? (
              <TextEditor
                dataProp={faqResult}
                isAdmin={canEdit(false, ATTRIBUTES.BME_CMS_WRITE)}
                putCms={putFaq}
              />
            ) : (
              <TextEditor
                isAdmin={canEdit(false, ATTRIBUTES.BME_CMS_WRITE)}
                putCms={putFaq}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

Faq.propTypes = {
  getFaq: PropTypes.func,
  faqResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  putFaq: PropTypes.func,
  selectFaqsIsLoadingProp: PropTypes.bool,
  selectPutFaqIsLoadingProp: PropTypes.bool,
  selectPutFaqSucceedProp: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  faqResult: selectFaqsResults,
  selectFaqsIsLoadingProp: selectFaqsIsLoading,
  selectPutFaqIsLoadingProp: selectPutFaqIsLoading,
  selectPutFaqSucceedProp: selectPutFaqSucceed
});

const mapDispatchToProps = dispatch => ({
  getFaq: () => dispatch(fetchFaqStart()),
  putFaq: params => dispatch(sendPutFaqDataStart(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
