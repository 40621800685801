import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ROUTES_CONSTANTS } from '../../../constants/route.constants';
import Submenu from '../../subMenu/Submenu';
import { fetchProgramDetailsStart } from '../../../redux/program-details/program-details.actions.js';
import { selectProgramDetails } from '../../../redux/program-details/program-details.selectors.js';
import { selectFolderDetails } from '../../../redux/folder-details/folder-details.selectors.js';
import { fetchFolderDetailsStart } from '../../../redux/folder-details/folder-details.actions.js';

/**
 * Component for show the submenu with the categories
 */
const CategorySubmenu = ({
  history,
  getProgramList,
  getProgramResults,
  fetchFolderDetails,
  selectFolder
}) => {
  const [selectedRefSection, setSelectedRefSection] = useState();
  // Params from URL to know who has called hte submenu component
  const refSection = useParams().category;
  const refProgram = useParams().medias;
  const refFolder = useParams().reportage;

  /**
   * Effect to dispatch sections results and program list or folderdetails when it did mount
   */
  useEffect(() => {
    if (refProgram) {
      getProgramList({ refProgram });
    } else if (refFolder) {
      fetchFolderDetails(refFolder);
    }
  }, []);

  useEffect(() => {
    let searchParam;
    if (refProgram) {
      searchParam = getProgramResults?.section?.refSection;
    } else if (refSection) {
      searchParam = refSection;
    } else if (refFolder) {
      searchParam = selectFolder?.program?.refSection;
    }
    setSelectedRefSection(searchParam);
  }, [refSection, refProgram, refFolder, getProgramResults, selectFolder]);

  /**
   * Redirect to section page
   * @param {Object} category Section
   */
  const redirect = category => {
    if (!category?.thematique) {
      history.push(
        `${ROUTES_CONSTANTS.programDetailLink}${category?.refSection}`
      );
    } else {
      history.push(`${ROUTES_CONSTANTS.categoriesLink}${category?.refSection}`);
    }
  };

  /**
   * Hanlde on submenu category change
   * @param {Object} category Section
   */
  const handleChange = category => {
    redirect(category);
  };

  return (
    <Submenu
      onCategoryChange={handleChange}
      forcedSelectSectionByRef={selectedRefSection}
    />
  );
};

CategorySubmenu.propTypes = {
  history: PropTypes.func,
  getProgramResults: PropTypes.func,
  getProgramList: PropTypes.func,
  fetchFolderDetails: PropTypes.func,
  selectFolder: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

const mapStateToProps = createStructuredSelector({
  getProgramResults: selectProgramDetails,
  selectFolder: selectFolderDetails
});

const mapDispatchToProps = dispatch => ({
  getProgramList: files => dispatch(fetchProgramDetailsStart(files)),
  fetchFolderDetails: refFolder => dispatch(fetchFolderDetailsStart(refFolder))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySubmenu);
