import { put, all, call, takeLatest } from 'redux-saga/effects';
import TagListTypes from './tag.list.types';
import { tagListBuilder } from './tag.list.builder';
import api from '../../api/api.jsx';
import {
  fetchTagListSuccess,
  fetchTagListFailure,
  fetchTagListFinished
} from './tag.list.actions';

export function* fetchTagList({ payload: params }) {
  try {
    const { data } = yield call(api.getTags, params);
    const dataBuilder = yield call(tagListBuilder, data);
    yield put(fetchTagListSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchTagListFailure(error));
  }
  yield put(fetchTagListFinished());
}

export function* onFetchTagListStart() {
  yield takeLatest(TagListTypes.TAG_LIST_START, fetchTagList);
}

export function* tagListSagas() {
  yield all([call(onFetchTagListStart)]);
}
