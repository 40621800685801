/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import 'react-image-gallery/styles/css/image-gallery.css';
import { makeStyles } from '@material-ui/core/styles';
import './style.scss';
import { connect } from 'react-redux';
import SnackbarAlert from '../snackBar/SnackbarAlert.jsx';
import styles from './style.jsx';
import Button from '../button/buttonComponent.jsx';
import text from '../../constants/translates';
import {
  colorType,
  snackBarSeverity,
  vitrineBigScreen,
  vitrineMediumScreen,
  vitrineSmallScreen
} from '../../constants/ui.constants';
import { addToMySelection } from '../../redux/mySelection/mySelection.actions';
import { selectMyCurrentSelection } from '../../redux/mySelection/mySelection.selectors';
import { downloadStart } from '../../redux/download/download.actions';
import AlertDialogSlide from '../dialog/dialog.jsx';
import {
  snackbarDuration,
  MEDIA_TYPE,
  ORIGINAL_MEDIA_INSTANCE,
  originalName
} from '../../constants/global-constants.jsx';
import CopyPasteModal from '../modal/copyPasteModal/CopyPasteModal.jsx';
import MediaDetails from '../medialDetails/MediaDetails.jsx';
import MediaDisplay from '../mediaDisplay/MediaDisplay.jsx';
import MediaShareModal from '../modal/mediaShareModal/mediaShareModal.jsx';
import { downloadMedia } from '../../shared/functions/mediaFunctions';
import { fetchMediaOriginalStart } from '../../redux/media-original/media-original.actions';
import { selectMediaOriginalResult } from '../../redux/media-original/media-original.selectors';
import { fetchURLInstanceStart } from '../../redux/urlInstances/urlInstances.actions';
import { selectInstanceURL } from '../../redux/urlInstances/urlInstances.selectors';
import { Grid } from '@material-ui/core';
import { useWindowDimensions } from '../../shared/functions/useWindowDimensions';
import { checkVitrine } from '../../shared/functions/vitrine';
/**
 * Component for visualize the detail of 1 image media
 */
const VisualImageProgram = ({
  image,
  updateVisualProgramVisibility,
  downloadStartProp,
  addToMySelectionProp,
  mySelection,
  selectedSearchMedia,
  downloadOriginalMedia,
  selectMediaOriginal,
  fetchURLInstance,
  instanceURL,
  vitrine
}) => {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [addedToMySelection, setAddedToMySelection] = useState(false);
  const history = useHistory();
  const [openCrop, setOpenCrop] = useState(false);
  const [cropImage, setCropImage] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [toastseverity, setToastseverity] = useState(false);
  const [resolution, setResolution] = useState();
  const [format, setFormat] = useState();
  const [instance, setInstance] = useState();
  const [openShareModal, setOpenShareModal] = useState();
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [selectedDowloadButton, setSelectedDowloadButton] = useState(false);
  const style = makeStyles(styles)();
  const bigScreenResolution = useWindowDimensions();

  const imageItems = [image];

  // Effect for download the original image
  useEffect(() => {
    if (selectedDowloadButton && selectMediaOriginal?.data) {
      downloadMedia(selectMediaOriginal, [{ mediaDetails: image }]);
      setSelectedDowloadButton(false);
    }
  }, [selectMediaOriginal]);

  useEffect(() => {
    setChecked(false);
    fetchURLInstance(image);
    if (imageItems.length > 0 && image?.instances) {
      setInstance(image?.instances[0]);
    }
  }, [image, openShareModal, addedToMySelection]);

  useEffect(() => {
    const currentInstance = image?.instances?.find(
      item =>
        item.format.refValue === format?.valueCode &&
        item.subFormat.refValue === resolution?.valueCode &&
        item.copyright === checked
    );
    setInstance(currentInstance);
  }, [format, resolution, checked]);

  /**
   * Constant for know if the the date of use of the media has expired or not
   */
  const isMediaExpired = () => {
    if (image?.durationOfUse) {
      return new Date(image?.durationOfUse) < new Date();
    }
    return false;
  };

  const close = () => {
    updateVisualProgramVisibility();
  };

  const getMediaInstance = () => {
    return {
      mediaDetails: image,
      refFile: instance?.refFile,
      instance: instance,
      isOriginal: format === ORIGINAL_MEDIA_INSTANCE
    };
  };

  const showToastAndAddToMySelection = () => {
    let findElement = null;
    if (instance !== undefined) {
      findElement = mySelection.find(
        item => item?.refFile === instance?.refFile
      );
    }

    if (findElement) {
      setToastMessage(text.visualProgram.errorAddMediaToMySelection);
      setToastseverity(snackBarSeverity.error);
    } else {
      setToastMessage(text.visualProgram.addedMediaToMySelection);
      setToastseverity(snackBarSeverity.success);
      addToMySelectionProp(getMediaInstance());
    }

    setAddedToMySelection(true);
  };

  const downloadSelectedImage = () => {
    if (openCrop) {
      setCropImage(true);
    } else {
      if (
        format?.valueCode === originalName ||
        resolution?.valueCode === originalName
      ) {
        setSelectedDowloadButton(true);
        downloadOriginalMedia({ refMedia: image?.refMedia });
      } else {
        downloadStartProp([
          {
            refFile: instance?.refFile,
            refMedia: image?.refMedia
          }
        ]);
      }
    }
  };

  const setCropImageVisibility = () => {
    setCropImage(false);
    setOpenCrop(false);
  };

  return (
    <>
      <SnackbarAlert
        id="AddedAlert"
        severity={toastseverity}
        open={addedToMySelection}
        message={toastMessage}
        handleClose={() => setAddedToMySelection(false)}
        duration={snackbarDuration}
      />
      <Grid container className={style.carouselContainer}>
        <Grid
          item
          xs={bigScreenResolution ? vitrineMediumScreen : vitrineSmallScreen}
        >
          <MediaDisplay
            onOpenCopyModal={() => setOpenCopyModal(true)}
            onOpenCrop={() => setOpenCrop(true)}
            onOpenShareModal={() => setOpenShareModal(true)}
            setCropImageVisibility={setCropImageVisibility}
            mediaType={MEDIA_TYPE.image}
            mediaList={imageItems}
            selectedMedia={image}
            openCrop={openCrop}
            cropImage={cropImage}
            history={history}
            onAddToSelection={() => showToastAndAddToMySelection()}
            isMediaExpired={isMediaExpired()}
            galleryMode={false}
            id="MediaDisplay"
            checked={checked}
            instanceURL={instanceURL}
            vitrine={vitrine}
          />
        </Grid>
        <Grid
          item
          xs={bigScreenResolution ? vitrineBigScreen : vitrineSmallScreen}
          className="image-gallery-info"
        >
          <MediaDetails
            media={image}
            program={selectedSearchMedia}
            openCrop={openCrop}
            checkedCopyright={checked}
            isMediaExpired={isMediaExpired()}
            onChangeCheckCopyright={e => setChecked(e)}
            onClose={() => close()}
            onChangeFormat={e => setFormat(e)}
            onChangeResolution={e => setResolution(e)}
            formatValue={format}
            resolutionValue={resolution}
            selectedSearchMedia={selectedSearchMedia}
            vitrine={vitrine}
            history={history}
          />

          <div className="button-block">
            <div className="button-block__dowload">
              {!checkVitrine(history) && (
                <Button
                  id="button_download"
                  variant="contained"
                  color={colorType.secondary}
                  className={style.buttonDowload}
                  onClick={downloadSelectedImage}
                  disabled={isMediaExpired()}
                >
                  {text.visualProgram.downloadButton}
                </Button>
              )}
              {openCrop && (
                <>
                  <Button
                    id="showButton"
                    variant="outlined"
                    color="primary"
                    className={style.showButton}
                  >
                    {text.visualProgram.shareButton}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <AlertDialogSlide
        open={open}
        title={text.visualProgram.questionDialog}
        textButtonOk={text.visualProgram.textButtonOk}
        textButtonCancel={text.cancel}
        handleAction={() => setOpen(false)}
        handleClose={() => setOpen(false)}
        id="AlertDialogSlide"
      />
      <CopyPasteModal
        open={openCopyModal}
        onClose={() => setOpenCopyModal(false)}
        history={history}
        media={image}
        id="CopyPasteModal"
      />
      <MediaShareModal
        open={openShareModal ? openShareModal : false}
        onClose={() => setOpenShareModal(false)}
        history={history}
        medias={[getMediaInstance()]}
        id="CopyPasteModal"
      />
    </>
  );
};

VisualImageProgram.propTypes = {
  downloadStartProp: PropTypes.func,
  addToMySelectionProp: PropTypes.func,
  updateVisualProgramVisibility: PropTypes.func,
  image: PropTypes.shape({
    dateUpload: PropTypes.string,
    userUpload: PropTypes.string,
    dateUpdate: PropTypes.string,
    userUpdate: PropTypes.string,
    copyright: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string)
  }),
  mySelection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  selectedSearchMedia: PropTypes.shape({
    mediaURL: PropTypes.string
  }),
  downloadOriginalMedia: PropTypes.func,
  selectMediaOriginal: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  vitrine: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  mySelection: selectMyCurrentSelection,
  selectMediaOriginal: selectMediaOriginalResult,
  instanceURL: selectInstanceURL
});

const mapDispatchToProps = dispatch => ({
  downloadStartProp: dataImage => dispatch(downloadStart(dataImage)),
  downloadOriginalMedia: refMedia =>
    dispatch(fetchMediaOriginalStart(refMedia)),
  addToMySelectionProp: selectedItem =>
    dispatch(addToMySelection(selectedItem)),
  fetchURLInstance: media => dispatch(fetchURLInstanceStart(media))
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualImageProgram);
