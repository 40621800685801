import CategoryAdminTypes from './categoryAdmin.types';

export const updateCategoriesStart = params => ({
  type: CategoryAdminTypes.UPDATE_CATEGORIES_START,
  payload: params
});

export const updateCategoriesSuccess = params => ({
  type: CategoryAdminTypes.UPDATE_CATEGORIES_SUCCESS,
  payload: params
});

export const updateCategoriesFailure = error => ({
  type: CategoryAdminTypes.UPDATE_CATEGORIES_FAILURE,
  payload: error
});

export const updateCategoriesFinished = () => ({
  type: CategoryAdminTypes.UPDATE_CATEGORIES_FINISHED
});

export const deleteCategoryStart = params => ({
  type: CategoryAdminTypes.DELETE_CATEGORY_START,
  payload: params
});

export const deleteCategorySuccess = params => ({
  type: CategoryAdminTypes.DELETE_CATEGORY_SUCCESS,
  payload: params
});

export const deleteCategoryFailure = error => ({
  type: CategoryAdminTypes.DELETE_CATEGORY_FAILURE,
  payload: error
});

export const deleteCategoryFinished = () => ({
  type: CategoryAdminTypes.DELETE_CATEGORY_FINISHED
});

export const createCategoryStart = params => ({
  type: CategoryAdminTypes.CREATE_CATEGORY_START,
  payload: params
});

export const createCategorySuccess = params => ({
  type: CategoryAdminTypes.CREATE_CATEGORY_SUCCESS,
  payload: params
});

export const createCategoryFailure = error => ({
  type: CategoryAdminTypes.CREATE_CATEGORY_FAILURE,
  payload: error
});

export const createCategoryFinished = () => ({
  type: CategoryAdminTypes.CREATE_CATEGORY_FINISHED
});
