import VisualFolderActionTypes from './visual-folder.types';

const INITIAL_STATE = {
  visualFolderResult: [],
  error: null,
  isLoading: false
};

const VisualFolderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VisualFolderActionTypes.FETCH_VISUAL_FOLDER_START:
      return { ...state, isLoading: true };
    case VisualFolderActionTypes.FETCH_VISUAL_FOLDER_SUCCESS:
      return { ...state, visualFolderResult: action.payload, error: null };
    case VisualFolderActionTypes.FETCH_VISUAL_FOLDER_FAILURE:
      return { ...state, error: action.payload };
    case VisualFolderActionTypes.FETCH_VISUAL_FOLDER_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default VisualFolderReducer;
