import mediaDetailsActionTypes from './media-details.types';

const INITIAL_STATE = {
  mediaDetails: [],
  error: null,
  isLoading: false,
  supportList: null,
  errorSupport: null,
  supportIsLoading: false
};

const mediaDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_START:
      return { ...state, isLoading: true };
    case mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_SUCCESS:
      return { ...state, mediaDetails: action.payload, error: null };
    case mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_FAILURE:
      return { ...state, error: action.payload };
    case mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_FINISHED:
      return { ...state, isLoading: false };
    case mediaDetailsActionTypes.FETCH_SUPPORT_START:
      return { ...state, supportIsLoading: true };
    case mediaDetailsActionTypes.FETCH_SUPPORT_SUCCESS:
      return { ...state, supportList: action.payload, error: null };
    case mediaDetailsActionTypes.FETCH_SUPPORT_FAILURE:
      return { ...state, errorSupport: action.payload };
    case mediaDetailsActionTypes.FETCH_SUPPORT_FINISH:
      return { ...state, supportIsLoading: false };
    default:
      return state;
  }
};

export default mediaDetailsReducer;
