import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './style.jsx';
import texts from '../../constants/translates';
import ButtonComponent from '../button/buttonComponent.jsx';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import { PROGRAM_TYPE } from '../../constants/global-constants.jsx';
import { canEditArray, canEdit } from '../../shared/rights/root-rights.js';
import { ATTRIBUTES } from '../../shared/rights/roles.js';
import { checkVitrine } from '../../shared/functions/vitrine';

const NoMediaCard = ({ options, history, type }) => {
  const style = makeStyles(styles)();
  const getTypePath = programType => {
    if (programType) {
      return `/${programType}`;
    }

    if (type) {
      return `/${type}`;
    }

    return '';
  };

  const getLabel = (code, name, section) => {
    if (name !== undefined) {
      if (code && section?.refSection !== PROGRAM_TYPE.IE_PROGRAM_NAME) {
        return `${code} - ${name}`;
      }

      return `${name}`;
    }
    if (code !== undefined) {
      return `${code}`;
    }
    return '';
  };

  return (
    <>
      {options &&
        options.map(option => (
          <Card className={style.card} key={option?.programRef}>
            <CardContent className={style.cardContent}>
              <Grid container>
                <Grid item xs={!checkVitrine(history) ? 6 : 12}>
                  <Typography
                    className={`${style.title} no-media-card__program`}
                    gutterBottom
                  >
                    {getLabel(option?.code, option?.name, option?.section)}
                  </Typography>
                  <Typography className={style.subTitle} gutterBottom>
                    {option?.city?.name}
                  </Typography>
                </Grid>
                {!checkVitrine(history) && (
                  <Grid item xs={6}>
                    <ButtonComponent
                      variant="outlined"
                      color="secondary"
                      className={style.addMediaButton}
                      disabled={
                        type === PROGRAM_TYPE.IE_PROGRAM
                          ? !canEdit(true, ATTRIBUTES.BME_MEDIA_WRITE)
                          : !canEditArray(
                              option?.organizationalentitypaths,
                              ATTRIBUTES.BME_MEDIA_WRITE
                            )
                      }
                      onClick={() =>
                        history.push(
                          `${ROUTES_CONSTANTS.filesUploaderBase}${
                            option?.programRef
                          }${getTypePath(option?.section?.type)}`
                        )
                      }
                    >
                      <Typography className={style.buttonText}>
                        {texts.noMediaCard.buttonAjounterMedia}
                      </Typography>
                    </ButtonComponent>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        ))}
    </>
  );
};
NoMediaCard.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  history: PropTypes.func,
  type: PropTypes.string
};
export default withRouter(NoMediaCard);
