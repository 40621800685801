import { put, all, call, takeLatest } from 'redux-saga/effects';
import FormatActionTypes from './format.types';
import { formatListBuilder } from './format.builder';
import apiNomenclature from '../../api/apiNomenclature';
import {
  fetchFormatSuccess,
  fetchFormatFailure,
  fetchFormatFinished
} from './format.actions';

export function* fetchFormat({ payload: params }) {
  try {
    const { data } = yield call(apiNomenclature.getListOfFormats, params);
    const dataBuilder = yield call(formatListBuilder, data);
    yield put(fetchFormatSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchFormatFailure(error));
  }
  yield put(fetchFormatFinished());
}

export function* onFetchFormatStart() {
  yield takeLatest(FormatActionTypes.FETCH_FORMAT_START, fetchFormat);
}

export function* formatSagas() {
  yield all([call(onFetchFormatStart)]);
}
