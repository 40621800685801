import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { createStructuredSelector } from 'reselect';
import texts from '../../constants/translates';
import './style.scss';
import CardComponent from '../../components/card/CardComponent.jsx';
import Spinner from '../../components/spinner/Spinner.jsx';
import { fetchSectionStart } from '../../redux/section/sections/section.actions';
import {
  selectSectionsLoading,
  selectSectionsResult
} from '../../redux/section/sections/section.selectors';

const ListCategories = ({ sectionIsLoading, sectionResults }) => {
  const [categoriesImportants, setCategoriesImportants] = useState();
  const [categoriesNoImportants, setCategoriesNoImportants] = useState();

  useEffect(() => {
    setCategoriesImportants(
      sectionResults?.filter(section => section.thematique === false)
    );

    setCategoriesNoImportants(
      sectionResults?.filter(section => section.thematique === true)
    );
  }, [sectionResults]);

  return (
    <>
      <Spinner isLoading={sectionIsLoading} />
      <div className="categoriesContainer">
        <p className="menu_title">{texts.listCategories.title}</p>
        <Grid container spacing={5}>
          {categoriesImportants?.map(category => {
            return (
              <Grid item xs key={category?.refSection}>
                <CardComponent
                  categoryImportant
                  id="cardcomponent"
                  isCategory
                  height="180"
                  image={category?.url}
                  category={{
                    title: category?.name,
                    abbreviation: category?.refSection,
                    thematique: category?.thematique
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={5}>
          {categoriesNoImportants?.map(category => {
            if (category?.urlSection || category?.url) {
              return (
                <Grid item xs={3} key={category?.refSection}>
                  <CardComponent
                    isCategory
                    height="180"
                    image={category?.urlSection || category?.url}
                    category={{
                      title: category?.name,
                      abbreviation: category?.refSection,
                      thematique: category?.thematique
                    }}
                  />
                </Grid>
              );
            }

            return null;
          })}
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  sectionResults: selectSectionsResult,
  sectionIsLoading: selectSectionsLoading
});

const mapDispatchToProps = dispatch => ({
  fetchSectionResults: () => dispatch(fetchSectionStart())
});

ListCategories.propTypes = {
  sectionIsLoading: PropTypes.bool,
  sectionResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCategories);
