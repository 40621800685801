import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/core';
import ArrowIcon from '../../assets/images/arrow.png';
import t from '../../constants/translates';
import ButtonComponent from '../button/buttonComponent.jsx';
import styles from './styles.jsx';
import './style.scss';

const SortableList = SortableContainer(
  ({ items = [], sendSortedArrayHandler, history }) => {
    const classes = makeStyles(styles)();
    const SortableItem = SortableElement(
      ({ label, category, number, thumbnail }) => (
        <div className="sortable-item">
          <div className="sortable-item__left-block">
            <span>{number + 1}</span>
            <img
              crossOrigin="anonymous"
              src={thumbnail}
              className="sortable-item__image"
              alt=""
            ></img>
          </div>
          <div className="sortable-item__middle-block">
            <span>{category}</span>
            <span>{label}</span>
          </div>
          <div className="sortable-item__right-block">
            <img crossOrigin="anonymous" alt="" src={ArrowIcon}></img>
            <span>{t.mediaOrder.changeOrder}</span>
          </div>
        </div>
      )
    );

    return (
      <div>
        <div className="media-order-title">{t.mediaOrder.titlePage}</div>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value?.id}`}
            index={index}
            number={index}
            thumbnail={value?.thumbnail}
            label={value?.label}
            category={value?.category}
          />
        ))}

        <div className="button-block">
          <ButtonComponent
            className={classes.cancelButton}
            onClick={() => history.goBack()}
          >
            <span className="button-sortable__text">{t.cancel}</span>
          </ButtonComponent>
          <ButtonComponent
            className={classes.saveButton}
            onClick={() => sendSortedArrayHandler()}
          >
            <span className="button-sortable__text">
              {t.mediaOrder.saveOrder}
            </span>
          </ButtonComponent>
        </div>
      </div>
    );
  }
);

export default SortableList;
