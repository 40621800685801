import { put, all, call, takeLatest } from 'redux-saga/effects';
import FileUploaderTypes from './fileUploaderTypes';
import api from '../../api/api.jsx';
import {
  addFilesSuccess,
  addFilesFinished,
  addFilesFailure
} from './fileUploaderActions';
import { createFilePayload } from '../../shared/functions/createFilesPayload';

function* addFile(file) {
  const convertedFile = createFilePayload(file);
  const result = yield call(api.postUploadMedia, {
    payload: {
      ...convertedFile,
      refProgram: file.refProgram,
      section: {
        listCode: file?.section?.listCode,
        level: file?.section?.level,
        refValue: file?.section?.refSection
      }
    }
  });
  return {
    url: result.headers['x-upload-content-location'],
    inputFile: file
  };
}

export function* addFiles({ payload: files }) {
  try {
    const promises = files.map(file => addFile(file));
    const data = yield all(promises);
    yield put(addFilesSuccess(data));
  } catch (error) {
    yield put(addFilesFailure(error));
  }
  yield put(addFilesFinished());
}

export function* onAddFilesStart() {
  yield takeLatest(FileUploaderTypes.ADD_FILES_START, addFiles);
}

export function* addFilesSagas() {
  yield all([call(onAddFilesStart)]);
}
