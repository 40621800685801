import DeleteTypes from './media-delete.types';

const INITIAL_STATE = {
  succeed: false,
  error: null,
  isLoading: false,
  message: null
};

const deleteMediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeleteTypes.SEND_DELETE_DATA_START:
      return { ...state, isLoading: true, succeed: false };
    case DeleteTypes.SEND_DELETE_DATA_SUCCESS:
      return {
        ...state,
        message: action.payload,
        error: null,
        succeed: true
      };
    case DeleteTypes.SEND_DELETE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload?.data,
        succeed: false
      };
    case DeleteTypes.SEND_DELETE_DATA_FINISHED:
      return { ...state, isLoading: false, succeed: false };

    default:
      return state;
  }
};

export default deleteMediaReducer;
