import { createSelector } from 'reselect';

const selectModified = state => state.editSection;

export const selectEditSection = createSelector(
  [selectModified],
  editSection => editSection.modified
);

export const selectEditSectionLoading = createSelector(
  [selectModified],
  editSection => editSection.isLoading
);

export const selectEditSectionError = createSelector(
  [selectModified],
  editSection => editSection.error
);

export const selectEditSectionSucceed = createSelector(
  [selectModified],
  editSection => editSection.succeed
);
