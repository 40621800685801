import { createSelector } from 'reselect';

const selectPartnerList = state => state.partnerList;

export const selectPartnerListResult = createSelector(
  [selectPartnerList],
  selectPartner => selectPartner.results
);

export const selectPartnerListIsLoading = createSelector(
  [selectPartnerList],
  selectPartner => selectPartner.isLoading
);

export const selectPartnerListPagination = createSelector(
  [selectPartnerList],
  selectPartner => selectPartner.results.page
);

export const selectPartnerListError = createSelector(
  [selectPartnerList],
  selectPartner => selectPartner.error
);
