const SearchWithGeopositionActionTypes = {
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_START:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_START',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_SUCCESS:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_SUCCESS',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_FAILURE:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_FAILURE',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_FINISHED:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_FINISHED',
  FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_START:
    'FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_START',
  FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_SUCCESS:
    'FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_SUCCESS',
  FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_FAILURE:
    'FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_FAILURE',
  FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_FINISHED:
    'FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_FINISHED',
  FETCH_SEARCHWITHGEOPOSITION_TOOLBAR: 'FETCH_SEARCHWITHGEOPOSITION_TOOLBAR',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_START:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_START',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_SUCCESS:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_SUCCESS',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_FAILURE:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_FAILURE',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_FINISHED:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_FINISHED',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_START:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_START',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_SUCCESS:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_SUCCESS',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_FAILURE:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_FAILURE',
  FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_FINISHED:
    'FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_FINISHED'
};

export default SearchWithGeopositionActionTypes;
