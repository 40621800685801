import { put, all, call, takeLatest } from 'redux-saga/effects';
import NaturalPersonsActionTypes from './naturalPersons.types';
import { naturalPersonsBuilder } from './naturalPersons.builder';
import apiIntervenants from '../../api/apiIntervenants';
import {
  fetchNaturalPersonsSuccess,
  fetchNaturalPersonsFailure,
  fetchNaturalPersonsFinished
} from './naturalPersons.actions';

export function* fetchNaturalPersons({ payload: params }) {
  try {
    const { data } = yield call(
      apiIntervenants.getListOfNaturalPersons,
      params
    );
    const dataBuilder = yield call(naturalPersonsBuilder, data);
    yield put(fetchNaturalPersonsSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchNaturalPersonsFailure(error));
  }
  yield put(fetchNaturalPersonsFinished());
}
export function* onFetchNaturalPersonsStart() {
  yield takeLatest(
    NaturalPersonsActionTypes.FETCH_NATURALPERSONS_START,
    fetchNaturalPersons
  );
}

export function* naturalPersonsSagas() {
  yield all([call(onFetchNaturalPersonsStart)]);
}
