import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CloseIcon from '@material-ui/icons/Close';
import styles from './style.jsx';
import './style.scss';
import { ROUTES_CONSTANTS } from '../../constants/route.constants';
import { colorType } from '../../constants/ui.constants';
import Button from '../../components/button/buttonComponent';
import text from '../../constants/translates';
import Spinner from '../../components/spinner/Spinner.jsx';
import DismissableAlert from '../../components/alert/DismissableAlert';
import {
  selectSectionsResult,
  selectSectionsLoading
} from '../../redux/section/sections/section.selectors.js';
import {
  PROGRAM_TYPE,
  CARACTERS,
  EMPTY_STRING,
  KEYS,
  SEARCH_TYPE
} from '../../constants/global-constants.jsx';
import { fetchSearchResultsStart } from '../../redux/search/search.actions.js';
import {
  selectSearchResults,
  selectSearchIsLoading
} from '../../redux/search/search.selectors';
import { getThumbnailsStart } from '../../redux/thumbnails/thumbnailsActions.js';
import {
  selectedThumbnailsIsLoading,
  selectedThumbnails
} from '../../redux/thumbnails/thumbnailsSelectors.js';
import { isFilialExt } from '../../shared/rights/root-rights.js';
import { getPrograms } from '../../shared/functions/mediaFunctions.js';

const AddMediaPage = ({
  history,
  sectionResults,
  sectionIsLoading,
  onClose,
  fetchSearchResults,
  searchProgramResults,
  searchLoading,
  fetchGetThumbnails,
  thumbnailsLoading,
  thumbnails
}) => {
  const classes = makeStyles(styles)();
  const [tabValue, setTabValue] = useState(0);
  const [valueSubTab, setValueSubTab] = useState();
  const [selectedProgram, setSelectedProgram] = useState({});
  const [currentProgramList, setCurrentProgramList] = useState([]);
  const [programType, setProgramType] = useState();
  let search = EMPTY_STRING;
  let indexProgramIR;
  let indexProgramIE;
  useEffect(() => {
    let typeProgram;
    if (indexProgramIR === tabValue) {
      // Case of program IR selected
      setProgramType(PROGRAM_TYPE.IR_PROGRAM);
      typeProgram = PROGRAM_TYPE.IR_PROGRAM_NAME;
    } else if (indexProgramIE === tabValue) {
      // Case of program IE selected
      setProgramType(PROGRAM_TYPE.IE_PROGRAM);
      typeProgram = PROGRAM_TYPE.IE_PROGRAM_NAME;
    } else {
      // Case sections
      setCurrentProgramList([]);
      setProgramType();
      setValueSubTab(0);
    }

    if (typeProgram) {
      const params = {
        type: SEARCH_TYPE.program,
        section: typeProgram,
        myPrograms: isFilialExt()
          ?.toString()
          ?.toUpperCase()
      };
      fetchSearchResults(params);
    }
  }, [tabValue]);

  useEffect(() => {
    if (currentProgramList?.length > 0) {
      const program = currentProgramList[valueSubTab];
      if (program?.totalImages > 0 || program?.totalVideos > 0) {
        fetchGetThumbnails([program]);
      }
    }
  }, [valueSubTab]);

  /**
   * One time the current program list has changed the set the first program like the selected program
   */
  useEffect(() => {
    if (currentProgramList?.length > 0) {
      setSelectedProgram(currentProgramList[0]);
      setValueSubTab(0);
    } else {
      setSelectedProgram({});
    }
  }, [currentProgramList]);

  useEffect(() => {
    setCurrentProgramList([]);
    if (
      searchProgramResults?.results?.withoutMedia?.length > 0 ||
      searchProgramResults?.results?.withMedia?.length > 0
    ) {
      setCurrentProgramList(getPrograms(searchProgramResults));
    }
  }, [searchProgramResults]);

  /**
   * Handle when tab is changed
   * @param {Object} event onChanged event
   * @param {Number} newValue changed tab value
   */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /**
   * Handle when program tab is changed
   * @param {Object} event onChanged event
   * @param {Number} newValue changed tab value
   */
  const handleSubTabChange = (event, newValue) => {
    setSelectedProgram(currentProgramList[newValue]);
    setValueSubTab(newValue);
  };

  /**
   * Properties related to tab panel for a Tab
   * @param {Number} index Tab index
   */
  function allProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`
    };
  }

  const getLoadings = () => {
    return sectionIsLoading;
  };

  /**
   * Function to assign the tab ndex of program IE and program IE
   * @param {*} section Section info
   * @param {*} index Current index
   */
  const setIndexPrograms = (section, index) => {
    if (section.type === PROGRAM_TYPE.IR_PROGRAM) {
      indexProgramIR = index;
    } else if (section.type === PROGRAM_TYPE.IE_PROGRAM) {
      indexProgramIE = index;
    }
  };

  /**
   * Function to search a program by the text writted by the user
   * @param {*} event
   */
  const handleSearchChanged = textSearch => {
    search = textSearch;
  };

  const searchProgram = () => {
    if (search.length > 0) {
      let typeProgram;
      if (programType === PROGRAM_TYPE.IR_PROGRAM) {
        typeProgram = PROGRAM_TYPE.IR_PROGRAM_NAME;
      } else if (programType === PROGRAM_TYPE.IE_PROGRAM) {
        typeProgram = PROGRAM_TYPE.IE_PROGRAM_NAME;
      }

      const params = {
        type: SEARCH_TYPE.program,
        text: search,
        section: typeProgram,
        myPrograms: isFilialExt()
          ?.toString()
          ?.toUpperCase()
      };

      fetchSearchResults(params);
    }
  };

  const handleKeyPress = e => {
    if (e.key === KEYS.enter && search.length > 0) {
      searchProgram();
    }
  };

  /**
   * Search icon adornment for input
   */
  const inputAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={searchProgram}>
        <SearchIcon />
      </IconButton>
    </InputAdornment>
  );
  /**
   * Displays the panel tabs column with programs
   */
  const tabsColumn = currentPrograms => (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={valueSubTab}
      onChange={handleSubTabChange}
      className={classes.tabs}
      TabIndicatorProps={{
        style: styles.indicator
      }}
    >
      {currentPrograms?.map(program => {
        // programListCopyResults
        const programElement = (
          <Grid container spacing={0}>
            <Grid item xs={6} className={classes.textAlign__left}>
              {`${
                programType === PROGRAM_TYPE.IR_PROGRAM
                  ? `${program?.programRef} `
                  : EMPTY_STRING
              }`}
              {`${
                program?.name !== undefined
                  ? ` - ${program?.name}`
                  : EMPTY_STRING
              }`}
            </Grid>
            <Grid item xs={4} className={classes.textAlign__left}>
              {program?.city?.name}
            </Grid>
            <Grid item xs={2} className={classes.iconFlex}>
              <div className={classes.icon}>
                <CameraAltOutlinedIcon />
                <span className="program-card__total-images">
                  {program?.totalImages}
                </span>
              </div>
              <div className={classes.icon}>
                <VideocamOutlinedIcon />
                <span className="program-card__total-videos">
                  {program?.totalVideos}
                </span>
              </div>
            </Grid>
          </Grid>
        );

        return (
          <Tab label={programElement} key={uuidv4()} className={classes.tab} />
        );
      })}
    </Tabs>
  );

  /**
   * Function for display the programs or display nothing in the case of sections
   * @param {index of the tab selected} index
   */
  const getListProgrammes = () => {
    return (
      <div className="program-column">
        {(tabValue === indexProgramIR || tabValue === indexProgramIE) && (
          <Grid item xs={12} className={classes.gridSearchRow}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>{text.homeBannerContainer.searchButton}</InputLabel>
              <OutlinedInput
                id="outlined-adornment"
                type="text"
                endAdornment={inputAdornment}
                labelWidth={styles.labelWidth}
                onChange={e => handleSearchChanged(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} className={classes.gridProgramsRow}>
          {searchLoading ? (
            <CircularProgress />
          ) : (
            tabsColumn(currentProgramList)
          )}
        </Grid>
      </div>
    );
  };

  /**
   * Display a preview panel with the selected program
   * @param {Object} program Selected program
   */
  const getPreviewPanel = () => {
    let title = CARACTERS.SPACE;
    let city = CARACTERS.SPACE;
    let uploadPath = CARACTERS.SPACE;
    let srcImage = null;
    if (
      programType === PROGRAM_TYPE.IR_PROGRAM ||
      programType === PROGRAM_TYPE.IE_PROGRAM
    ) {
      if (selectedProgram?.name !== undefined) {
        title = `${
          programType === PROGRAM_TYPE.IR_PROGRAM
            ? `${selectedProgram?.programRef} - `
            : EMPTY_STRING
        }${selectedProgram?.name}`;
      }
      city = selectedProgram?.city?.name;
      uploadPath = `${ROUTES_CONSTANTS.filesUploaderBase}${selectedProgram?.programRef}/${sectionResults[tabValue]?.type}`;
      srcImage =
        thumbnails?.length > 0
          ? thumbnails?.find(
              thumb => thumb.thumbnailApiUrl === selectedProgram?.thumbnailUrl
            )?.thumbnail
          : null;
    } else {
      title = sectionResults[tabValue]?.name;
      uploadPath = `${ROUTES_CONSTANTS.filesThematiqueUploaderBase}${sectionResults[tabValue]?.refSection}`;
      srcImage =
        sectionResults[tabValue]?.url || sectionResults[tabValue]?.urlSection;
    }

    if (
      (programType === PROGRAM_TYPE.IR_PROGRAM ||
        programType === PROGRAM_TYPE.IE_PROGRAM) &&
      currentProgramList.length === 0
    ) {
      return null;
    }
    return (
      <div className="program-column">
        <Grid item xs={12}>
          {thumbnailsLoading ? (
            <CircularProgress />
          ) : (
            srcImage !== null &&
            srcImage !== undefined && (
              <img
                crossOrigin="anonymous"
                className="image-preview"
                src={srcImage}
                alt={title}
              />
            )
          )}
          {title && <p className="text-preview">{title}</p>}
          {city && <p className="text-preview">{city}</p>}
          <Button
            id="buttonPaste"
            variant="contained"
            color={colorType.secondary}
            className={classes.button}
            onClick={() => history.push(uploadPath)}
          >
            {text.selectButton}
          </Button>
        </Grid>
      </div>
    );
  };

  const closeModalWithKey = e => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', closeModalWithKey, false);
  });

  const printCloseIcon = () => (
    <IconButton
      id="print-button"
      className={classes.closeButton}
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => onClose()}
      onKeyDown={e => closeModalWithKey(e)}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  return (
    <>
      {getLoadings() ? (
        <Spinner className="spinner" isLoading />
      ) : (
        <div className="mainDiv">
          {printCloseIcon()}
          <DismissableAlert />
          <h1 className="title">{text.fileUploader.title}</h1>
          <p>{text.addMediaPage.selection}</p>
          <Grid container spacing={0} className={classes.gridBorderFather}>
            <Grid item xs={2} className={classes.gridBorder}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: styles.indicator
                }}
                className={classes.tabs}
              >
                {sectionResults?.map((section, index) => {
                  setIndexPrograms(section, index);
                  return (
                    <Tab
                      className={classes.tab}
                      label={section.name}
                      key={section.refSection}
                      {...allProps(index)}
                    />
                  );
                })}
              </Tabs>
            </Grid>
            <Grid item xs={6} className={classes.gridBorder}>
              {getListProgrammes()}
            </Grid>
            <Grid item xs={4} className={classes.gridBorder}>
              {getPreviewPanel()}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

AddMediaPage.propTypes = {
  history: PropTypes.func,
  sectionResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  sectionIsLoading: PropTypes.bool,
  onClose: PropTypes.func,
  fetchSearchResults: PropTypes.func,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  searchLoading: PropTypes.bool,
  fetchGetThumbnails: PropTypes.func,
  thumbnailsLoading: PropTypes.bool,
  thumbnails: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

const mapStateToProps = createStructuredSelector({
  sectionResults: selectSectionsResult,
  sectionIsLoading: selectSectionsLoading,
  searchProgramResults: selectSearchResults,
  searchLoading: selectSearchIsLoading,
  thumbnailsLoading: selectedThumbnailsIsLoading,
  thumbnails: selectedThumbnails
});

const mapDispatchToProps = dispatch => ({
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params)),
  fetchGetThumbnails: params => dispatch(getThumbnailsStart(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMediaPage);
