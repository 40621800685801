export default {
  root: {
    display: 'flex'
  },

  Title: {
    fontSize: '16px',
    lineHeight: '20px'
  },

  description: {
    fontSize: '12px',
    lineHeight: '16px'
  },

  buttonText: {
    fontFamily: 'Neo Sans Pro',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    textTransform: 'none'
  },

  expansionPanel: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },

  contentPanel: {
    cursor: 'default'
  },

  arrowIcon: {
    cursor: 'pointer',
    color: '#BF7703',
    border: '1px solid #BF7703',
    borderRadius: '10px',
    position: 'relative'
  },

  buttonShowMore: {
    color: '#BF7703',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    lineHeight: '20px',
    '&:hover': {
      color: '#BF7703',
      backgroundColor: 'transparent'
    },
    marginLeft: '10px'
  },

  buttonCreateProgram: {
    marginLeft: 'auto',
    borderColor: 'black',
    textTransform: 'none',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '250px',
    display: 'flex',
    justifyContent: 'center',
    color: '#F5F6FA',
    backgroundColor: '#C82832',
    '&:hover': {
      backgroundColor: '#C82832',
      borderColor: '#C82832',
      color: '#F5F6FA'
    }
  },

  treeItem: {
    fontSize: '12px',
    lineHeight: '16px'
  },
  checkbox: {
    color: '#c82832'
  },
  startIcon: {
    marginRight: 0
  }
};
