import CopyProgramListTypes from './copy.program.list.types';

const INITIAL_STATE = {
  results: [],
  error: null,
  isLoading: false
};

/**
 * Reducer concerning program list results from copy/paste modal
 * @param {Object} state current application state
 * @param {Object} action action dispatched
 */
const copyProgramListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_START:
      return { ...state, isLoading: true };
    case CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_SUCCESS:
      return { ...state, results: action.payload, error: null };
    case CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_FAILURE:
      return { ...state, error: action.payload };
    case CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default copyProgramListReducer;
