import { createSelector } from 'reselect';

const selectModificationMedias = state => state.modification;

export const selectedModificationMedias = createSelector(
  [selectModificationMedias],
  modification => modification.mediaUrl
);

export const modificationIsLoading = createSelector(
  [selectModificationMedias],
  modification => modification.isLoading
);

export const modificationError = createSelector(
  [selectModificationMedias],
  modification => modification.error
);

export const modificationSucceed = createSelector(
  [selectModificationMedias],
  modification => modification.succeed
);
