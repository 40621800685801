import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import mediaDetailsActionTypes from './media-details.types';
import api from '../../api/api.jsx';
import apiNomenclature from '../../api/apiNomenclature';
import {
  fetchMediaDetailsSuccess,
  fetchMediaDetailsFailure,
  fetchMediaDetailsFinished,
  fetchSupportSuccess,
  fetchSupportFailure,
  fetchSupportFinished
} from './media-details.actions';
import { mediaBuilder } from '../../shared/builders/media';
import { supportBuilder } from '../../shared/builders/supportBuilder';

export const selectCreateProgram = state => state.createProgram;

function* getMediaDetails(refmedia) {
  const { data } = yield call(api.getMediaDetails, refmedia);
  const { communes } = yield select(selectCreateProgram);
  return yield call(mediaBuilder, data, communes);
}

export function* fetchMediaDetails({ payload: refmedia }) {
  try {
    let dataBuilder;
    // For the case of mode selection when we send a list of medias to get the details
    if (typeof refmedia !== 'string') {
      const promises = refmedia?.map(refmediaVal =>
        getMediaDetails(refmediaVal)
      );
      dataBuilder = yield all(promises);
    } else {
      const { data } = yield call(api.getMediaDetails, refmedia);
      const { communes } = yield select(selectCreateProgram);
      dataBuilder = yield call(mediaBuilder, data, communes);
    }
    yield put(fetchMediaDetailsSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchMediaDetailsFailure(error));
  }
  yield put(fetchMediaDetailsFinished());
}

export function* fetchSupport() {
  try {
    const { data } = yield call(apiNomenclature.getListOfSupport);
    const dataBuilder = yield call(supportBuilder, data);
    yield put(fetchSupportSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSupportFailure(error));
  }
  yield put(fetchSupportFinished());
}

export function* onFetchMediaDetailsStart() {
  yield takeLatest(
    mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_START,
    fetchMediaDetails
  );
}

export function* onFetchSupportStart() {
  yield takeLatest(mediaDetailsActionTypes.FETCH_SUPPORT_START, fetchSupport);
}

export function* MediaDetailsSagas() {
  yield all([call(onFetchMediaDetailsStart), call(onFetchSupportStart)]);
}
