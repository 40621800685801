import { put, all, call, takeLatest } from 'redux-saga/effects';
import ReorganizeMedias from './reorganize.types.js';
import api from '../../api/api.jsx';
import {
  reorganizeMediasSuccess,
  reorganizeMediasFailure,
  reorganizeMediasFinished
} from './reorganize.actions';

export function* reorganizeMedias({ payload: dataMedias }) {
  try {
    const { data } = yield call(api.reorganizerMedia, dataMedias.newArray);
    yield put(reorganizeMediasSuccess(data));
    yield dataMedias.history.goBack();
  } catch (error) {
    yield put(reorganizeMediasFailure(error));
  }
  yield put(reorganizeMediasFinished());
}

export function* onReorganizeMedias() {
  yield takeLatest(ReorganizeMedias.REORGANIZE_MEDIAS_START, reorganizeMedias);
}

export function* reorganizeMediasSagas() {
  yield all([call(onReorganizeMedias)]);
}
