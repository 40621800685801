import { put, all, call, takeLatest } from 'redux-saga/effects';
import EditSectionTypes from './editSectionTypes';
import apiNomenclature from '../../../api/apiNomenclature';
import {
  EditSectionSuccess,
  EditSectionFailure,
  EditSectionFinish
} from './editSectionActions';
import { NOM_INFO_TYPE } from '../../../constants/global-constants';
import api from '../../../api/api';
import { createFileUploadToAPI } from '../../../shared/functions/createFilesPayload';
import { getNowDate } from '../../../shared/functions/date';

function* upMedia(params) {
  const fileToUpload = yield createFileUploadToAPI(params.image);

  const payload = { refSection: params.refValue, fileToUpload };

  const data = yield call(api.sendThumbnailSection, payload);
  const { headers } = data;
  const newItem = {
    informationCode: NOM_INFO_TYPE.SECTION_IMAGE,
    value: headers.location,
    startValidityDate: getNowDate(new Date())
  };

  return params.form.additionnalInformation.push(newItem);
}

export function* editSection({ payload: params }) {
  try {
    if (params.image) {
      yield upMedia(params);
    }
    yield call(apiNomenclature.putSection, params.refValue, params.form);
    yield put(EditSectionSuccess());
  } catch (error) {
    yield put(EditSectionFailure(error.response?.data));
  }
  yield put(EditSectionFinish());
}

export function* onEditSectionStart() {
  yield takeLatest(EditSectionTypes.EDIT_SECTION_START, editSection);
}

export function* editSectionSagas() {
  yield all([call(onEditSectionStart)]);
}
