const ModeControllerTypes = {
  SET_MODE_START: 'SET_MODE_START',
  SET_MODE_SUCCESS: 'SET_MODE_SUCCESS',
  SEND_MEDIAS_TO_CALLBACK_START: 'SEND_MEDIAS_TO_CALLBACK_START',
  SEND_MEDIAS_TO_CALLBACK_SUCCESS: 'SEND_MEDIAS_TO_CALLBACK_SUCCESS',
  SEND_MEDIAS_TO_CALLBACK_FAILURE: 'SEND_MEDIAS_TO_CALLBACK_FAILURE',
  SEND_MEDIAS_TO_CALLBACK_FINISHED: 'SEND_MEDIAS_TO_CALLBACK_FINISHED'
};

export default ModeControllerTypes;
