const permissionsBuilder = permissions => {
  return permissions?.map(item => {
    return {
      label: item?.label,
      value: item?.value?.slice(1, item?.value?.length - 1)
    };
  });
};

export const userInfoBuilder = data => {
  return {
    firstname: data?.firstname,
    lastname: data?.lastname,
    preferredUsername: data?.preferredUsername,
    permissions: permissionsBuilder(data?.permissions),
    groups: data?.groups,
    email: data?.email,
    hasFilial: data.uos?.length > 0
  };
};
