import { put, all, call, takeLatest } from 'redux-saga/effects';
import ProgramListCleoTypes from './programListCleoTypes';
import {
  fetchProgramListCleoFailure,
  fetchProgramListCleoFinished,
  fetchProgramListCleoSuccess
} from './programListCleoActions';
import { isCPIActive } from '../../../shared/rights/interface';

function* searchProgramWithFilial(filial, searchParams, apiMethod) {
  const result = yield call(apiMethod, {
    ...searchParams,
    CompanyCode: filial
  });
  return result.data;
}

export function* getProgramsWithFilial(filialList, searchParams, apiMethod) {
  const promises = filialList.map(filial =>
    searchProgramWithFilial(filial, searchParams, apiMethod)
  );

  return yield all(promises);
}

export function* getPrograms(params, apiMethod) {
  if (params.companyCodes?.length > 0) {
    return yield call(
      getProgramsWithFilial,
      params.companyCodes,
      params.searchParams,
      apiMethod
    );
  }
  const { data } = yield call(apiMethod, params.searchParams);
  return data;
}

export function* ProgramListCleoResults() {
  let dataBuilder = [];
  try {
    if (isCPIActive()) {
      // TODO: L'appel à CLEO est désactivé, Il faut la supprimér
      /* const data = yield call(getPrograms, params, apiCleo.getCpiPrograms);
      dataBuilder = yield call(programListCpiBuilder, data); */
      dataBuilder = [];
    } else {
      // TODO: L'appel à CLEO est désactivé, Il faut la supprimér
      /* const data = yield call(getPrograms, params, apiCleo.getPrograms);
      dataBuilder = yield call(programListCleoBuilder, data); */
      dataBuilder = [];
    }

    yield put(fetchProgramListCleoSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchProgramListCleoFailure(error));
  }
  yield put(fetchProgramListCleoFinished());
}

export function* onFetchProgramListCleoStart() {
  yield takeLatest(
    ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_START,
    ProgramListCleoResults
  );
}

export function* ProgramListCleoSagas() {
  yield all([call(onFetchProgramListCleoStart)]);
}
