const ModificationTypes = {
  SEND_MODIFICATION_PARTNER_DATA_START: 'SEND_MODIFICATION_PARTNER_DATA_START',
  SEND_MODIFICATION_PARTNER_DATA_SUCCESS:
    'SEND_MODIFICATION_PARTNER_DATA_SUCCESS',
  SEND_MODIFICATION_PARTNER_DATA_FAILURE:
    'SEND_MODIFICATION_PARTNER_DATA_FAILURE',
  SEND_MODIFICATION_PARTNER_DATA_FINISHED:
    'SEND_MODIFICATION_PARTNER_DATA_FINISHED'
};

export default ModificationTypes;
