/* eslint-disable no-useless-escape */
export const formatDatePicker = 'dd/MM/yyyy';

export const acceptedFormats = {
  png: 'png',
  jpg: 'jpg',
  jpeg: 'jpeg',
  tiff: 'tiff',
  mp4: 'mp4',
  mov: 'mov',
  avi: 'avi',
  video: 'video'
};

export const imageTypes = [
  'image/tiff',
  'image/jpg',
  'image/jpeg',
  'image/png'
];

export const videoTypes = [
  'video/mp4',
  'video/mov',
  'video/avi',
  'video/mp4',
  'video/quicktime',
  'video/x-msvideo',
  'video/mpeg',
  'video/x-ms-wmv'
];

export const CALENDAR_TYPE = {
  SIMPLE: 'SIMPLE',
  COMPLETE: 'COMPLETE'
};

export const CALENDAR_STEP_EVENT = {
  CE: { key: 'CE', title: "Comité d'acquisition" },
  COA: { key: 'COA', title: "Comité d'engagement" },
  DT: { key: 'DT', title: 'Démarrage travaux' },
  LC: { key: 'LC', title: 'Prochain lancement commercial' },
  LI: { key: 'LI', title: 'Prochaine Livraison' }
};

export const fileTypes = ['application/pdf'];

export const pdfType = 'PDF';

export const acceptedDropzoneFormats = imageTypes.concat(videoTypes);

export const maxFileSize = 500 * 1000 * 1000;
export const maxSizeForThumbnail = 15 * 1000 * 1000;
export const fileLimit = 15;

export const alertDuration = 6000;

export const infiniteScrollThreshold = 750;

export const itemTypes = {
  selectedMedia: 'selectedMedia',
  folder: 'folder',
  media: 'media'
};

export const blocsToShow = 3;

export const minTagChar = 3;

export const itemPayload = 'newFolder';

export const image = 'image';

export const closeIconText = 'x';

export const checkboxValue = 'attachLogo';

export const snackbarDuration = 10000;

export const minFileSize = 100;

export const theme = 'thematique';

export const ENTER_KEY = 'Enter';

export const CREATION_STATUS = {
  SUCCESS: 'SUCCESSFUL'
};

export const operation = {
  addOperation: 'add',
  replaceOperation: 'replace',
  removeOperation: 'remove'
};

export const PATCH_PATHS = {
  support: 'supports/-',
  supportPosition: 'supports/0',
  expirationDate: 'expirationDate'
};

export const NOM_INFO_TYPE = {
  TYPE_SECTION: 'TYPE_SECTION',
  SECTION_IMAGE: 'THUMBNAIL',
  DELETEABLE: 'DELETEABLE'
};

export const PROGRAM_TYPE = {
  IE_PROGRAM: 'IE',
  IR_PROGRAM: 'IR',
  IE_PROGRAM_NAME: 'PROGRAM_IE',
  IR_PROGRAM_NAME: 'PROGRAM_IR',
  IR_PROGRAM_LONG_NAME: 'Programmes Immobiliers Residentiels'
};

export const THEME_NOM_TYPE = 'THEMATIQUE';

export const DELETEABLE_TYPES = {
  TRUE: '1',
  FALSE: '0'
};

export const THEME_TYPE = 'theme';

export const dateFormat = {
  DMY: 'DD-MM-YYYY',
  YMD: 'YYYY-MM-DD',
  LOCAL_DATE: 'DD/MM/YYYY'
};

export const MEDIA_TYPE = {
  video: 'VIDEO',
  image: 'IMAGE',
  document: 'DOCUMENT'
};

export const MEDIA_STATUS = {
  published: 'PUBLISHED',
  toPublish: 'TO_PUBLISH',
  toUnpublish: 'TO_UNPUBLISH',
  ready: 'READY'
};

export const MEDIA_STATUS_ERROR = {
  errorGed: 'ERROR_GED',
  errorCodec: 'ERROR_CODEC',
  errorResize: 'ERROR_RESIZE'
};

export const CARACTERS = {
  SPACE: ' ',
  UNDERSCORE: '_'
};

export const AUTHENTICATION = {
  bearerKey: 'Bearer',
  jwtTokenKey: 'jwtToken',
  refreshTokenKey: 'refreshToken',
  sessionId: 'sessionId',
  refreshIn: 'refreshIn',
  authorizationKey: 'Authorization'
};

export const LEVELS = {
  CATEGORIE: 2,
  SUBCATEGORIE: 3
};

export const CREATE_PROGRAMS_SECTION_PARAM = {
  programIE: {
    level: '1',
    listCode: 'FILTRE_MEDIA',
    refValue: 'PROGRAM_IE'
  }
};

export const HANDLE_CHANGE_TYPES = {
  typeProject: 'typeProjet',
  destinationProperty: 'propertyIntendedUse'
};

export const pageSize = 24;

export const programWithoutMediaCollapsed = 2;

export const SIZES = {
  addMediaPage: 25,
  defaultSize: 25,
  visualProgramSize: 3
};

export const SearchCleoParams = {
  ResultsPerPage: 2000,
  MinDeliveryDate: '2015-01-01',
  IncludeWorkphasesDetails: false
};

export const searchCpiParams = {
  ResultsPerPage: 2000,
  MinDeliveryDate: '2015-01-01',
  IncludeWorkphasesDetails: false
};

export const bigPageSize = 100;

export const SEARCH_TYPES = {
  media: 'MEDIA',
  program: 'PROGRAM'
};
export const selectCategory = 'selectCategory';
export const selectSubCategory = 'selectSubCategory';
export const selectTag = 'selectTag';
export const UNDEFINED_RESOLUTION = 'undefined x undefined';

export const SearchLegalEntityParams = {
  size: 1000,
  search: 'refAccountingSystem=false, status=actif',
  page: 1
};

export const SearchOrganizationalEntityParams = {
  size: 10000,
  search: 'type=FILIALE_OPERATIONNELLE'
};

export const getBMEPartnersParams = {
  pageSize: 10000,
  page: 1
};

export const KEYS = {
  enter: 'Enter'
};

export const FILTERS = {
  section: 'sectionname'
};

export const MIN_SEARCH_LENGTH = 3;

export const SEARCH_PAGE_SIZE = 20;

export const SEARCH_TYPE = {
  media: 'MEDIA',
  program: 'PROGRAM'
};

export const regex = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  telephone: /^[0-9\\(\\)\\+\\-\\.]{0,15}$/
};

export const adminRigthsColumns = {
  nameSociety: 'nameSociety',
  citySociety: 'citySociety',
  typeSociety: 'typeSociety',
  name: 'name',
  surname: 'surname',
  email: 'email',
  phoneNumber: 'phoneNumber',
  subsidiary: 'subsidiary'
};

export const EMPTY_STRING = '';
export const EXTERNE = 'user=';

export const INTERFACES = {
  INTERNAL: 'internal',
  PARTNER: 'partner'
};

export const MIN_SEARCH_USERS_LENGTH = 5;
export const PERMISSIONS_VALUES = {
  filial: 'filiale'
};

export const IR_ORG_PATH = '/Nexity/IR/';

export const MY_SELECTION_FORM_PROPS = {
  email: 'email',
  name: 'name',
  lastName: 'lastName',
  search: 'search'
};

export const OK_RESPONSE = 'ok';

export const PARTNER_FORM = {
  name: 'Prenom',
  surname: 'Nom',
  email: 'Email',
  phone: 'Teléphone',
  ville: 'Ville',
  society: 'Societe'
};

export const FOLDER_DOCUMENTS_NAME = 'Documents';

export const FILIAL = {
  aegide: 'AEGIDE',
  domitys: 'DOMITYS',
  perl: 'PERL',
  edouard: 'EDOUARD DENIS PROMOTION'
};

export const MODE_PARAMS = {
  callback: 'callback',
  programCode: 'programCode',
  selection: 'selection'
};

export const ORIGINAL_MEDIA_INSTANCE = {
  shortLabel: 'Original',
  longLabel: 'Original',
  refValue: 'Original',
  valueCode: 'Original'
};

export const originalName = 'Original';

export const webFormatImage = 'WEB_IMAGE';

export const webFormatVideo = 'WEB_VIDEO';

export const DEFAULT_VIDEO_THUMB =
  'https://i.vimeocdn.com/video/506389072_780x439.jpg';
export const ERROR_URL = '/error';

export const KIOSQUESI_URL =
  'http://kiosquesi.nexity.net/Ticket/MesTickets?isNew=True';

export const MAX_FILTER_COLLAPSE = 10;

export const ERROR_TYPES = {
  accessDenied: 'ACCES_DENIED'
};

export const REDIRECT_TO = {
  accessDenied: 'error/403'
};

export const pdfFormat = 'PDF';
export const pdfWidth = 800;
export const pdfScale = 0.9;
export const NO_PATH = 'NO_PATH';

export const initial = 'initial';

export const modeSelection = 'selection';

export const totalVideosForCheck = 2;
export const totalImagesForCheck = 10;
export const QualitiesSupporteds = [4, 48, 75, 89];

export const copyrightCode = 'COPYRIGHT_TOO_LONG';
export const badTypeUploadCode = 'ERROR_TYPE_DOCUMENT_SECTION';
export const networkErrorCode = 'Error: Network Error';

export const bucketUplaod = 'media-upload.s3';

export const sizeSearchProgramsMap = 20;

export const facets = [
  'region',
  'department',
  'city',
  'category',
  'subcategory',
  'typeprojet',
  'destinationprojet'
];

export const facetsVitrine = [
  'sectioncode',
  'organizationalentitypaths',
  'region',
  'department',
  'city'
];

export const size = {
  width: 640, // Map width in pixels
  height: 380 // Map height in pixels
};
export const zoom = 6;
export const coorInit = {
  nw: {
    lat: 51.080903651834184,
    lng: -5.703763776562507
  },
  se: {
    lat: 42.66403541738095,
    lng: 8.040132707812493
  }
};
