import { put, all, call, takeLatest } from 'redux-saga/effects';
import CreateProgram from './createProgramTypes';
import apiNomenclature from '../../api/apiNomenclature.jsx';
import apiGov from '../../api/apiGov.jsx';
import api from '../../api/api.jsx';
import {
  sendFormDataSuccess,
  sendFormDataFailure,
  sendFormDataFinished,
  fetchTypeOfProyectsSuccess,
  fetchTypeOfProyectsFailure,
  fetchTypeOfProyectsFinished,
  fetchDestinationPropertySuccess,
  fetchDestinationPropertyFailure,
  fetchDestinationPropertyFinished,
  fetchRegionsSuccess,
  fetchRegionsFailure,
  fetchRegionsFinished,
  fetchDepartmentsSuccess,
  fetchDepartmentsFailure,
  fetchDepartmentsFinished,
  fetchCommunesFinished,
  fetchCommunesFailure,
  fetchCommunesSuccess
} from './createProgramActions';
import { createProgramProjectTypesBuilder } from './createProgram.builder.js';

export function* createProgram({ payload: params }) {
  const { formData, history } = params;

  const newForm = { ...formData, year: formData?.year + '-01-01' };
  try {
    const { data } = yield call(api.postCreateProgram, newForm);
    yield put(sendFormDataSuccess(data));
    yield history?.goBack();
  } catch (error) {
    yield put(sendFormDataFailure(error?.data));
  }
  yield put(sendFormDataFinished());
}

export function* fetchTypeOfProjects() {
  try {
    const { data } = yield call(apiNomenclature.getTypeOfProjects);
    const dataBuilded = yield createProgramProjectTypesBuilder(data);
    yield put(fetchTypeOfProyectsSuccess(dataBuilded));
  } catch (error) {
    yield put(fetchTypeOfProyectsFailure(error.response.data));
  }
  yield put(fetchTypeOfProyectsFinished());
}

export function* fetchDestinationProperty() {
  try {
    const { data } = yield call(apiNomenclature.getDestinationProperties);
    const dataBuilded = yield createProgramProjectTypesBuilder(data);
    yield put(fetchDestinationPropertySuccess(dataBuilded));
  } catch (error) {
    yield put(fetchDestinationPropertyFailure(error.response.data));
  }
  yield put(fetchDestinationPropertyFinished());
}

export function* fetchRegions() {
  try {
    const { data } = yield call(apiGov.getRegions);
    yield put(fetchRegionsSuccess(data));
  } catch (error) {
    yield put(fetchRegionsFailure(error.response.data));
  }
  yield put(fetchRegionsFinished());
}

export function* fetchDepartments() {
  try {
    const { data } = yield call(apiGov.getDepartments);
    yield put(fetchDepartmentsSuccess(data));
  } catch (error) {
    yield put(fetchDepartmentsFailure(error.response.data));
  }
  yield put(fetchDepartmentsFinished());
}

export function* fetchCommunes() {
  try {
    const { data } = yield call(apiGov.getCommunes);
    yield put(fetchCommunesSuccess(data));
  } catch (error) {
    yield put(fetchCommunesFailure(error.response.data));
  }
  yield put(fetchCommunesFinished());
}

export function* onCreateProgramStart() {
  yield takeLatest(CreateProgram.SEND_FORM_DATA_START, createProgram);
}

export function* onFetchTypeOfProjects() {
  yield takeLatest(
    CreateProgram.FETCH_TYPE_OF_PROJECTS_START,
    fetchTypeOfProjects
  );
}

export function* onFetchDestinationProperty() {
  yield takeLatest(
    CreateProgram.FETCH_DESTINATION_PROPERTY_START,
    fetchDestinationProperty
  );
}

export function* onFetchRegions() {
  yield takeLatest(CreateProgram.FETCH_REGIONS_START, fetchRegions);
}

export function* onFetchDepartments() {
  yield takeLatest(CreateProgram.FETCH_DEPARTMENTS_START, fetchDepartments);
}

export function* onFetchCommunes() {
  yield takeLatest(CreateProgram.FETCH_COMMUNES_START, fetchCommunes);
}

export function* createProgramSagas() {
  yield all([
    call(onFetchTypeOfProjects),
    call(onFetchDestinationProperty),
    call(onFetchRegions),
    call(onFetchDepartments),
    call(onFetchCommunes),
    call(onCreateProgramStart)
  ]);
}
