export default {
  title: {
    fontSize: 16,
    marginBottom: 0
  },
  subTitle: {
    marginBottom: '0px',
    fontSize: 12
  },
  card: {
    width: '260px',
    background: '#F5F6FA',
    border: '1px solid #DBE2F4',
    boxShadow: '0px 8px 24px rgba(219, 226, 244, 0.5)',
    boxSizing: 'border-box',
    marginRight: '16px',
    marginTop: '20px'
  },
  cardContent: {
    padding: '7px',
    '&:last-child': {
      padding: 8
    }
  },
  cardContentLoader: {
    height: '220px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    height: '20.5px'
  },
  icon: {
    display: 'flex',
    alignItems: 'flex-end'
  }
};
