import { createSelector } from 'reselect';

const selectPublisUnpublishMedias = state => state.publishUnpublishMedias;

export const selectedErrorPublishUnpublish = createSelector(
  [selectPublisUnpublishMedias],
  publishUnpublishMedias => publishUnpublishMedias.error
);

export const selectedIsLoadingPublishUnpublish = createSelector(
  [selectPublisUnpublishMedias],
  publishUnpublishMedias => publishUnpublishMedias?.isLoading
);
