import { put, all, call, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import CategoryAdminTypes from './categoryAdmin.types';
import apiNomenclature from '../../api/apiNomenclature';
import {
  updateCategoriesSuccess,
  updateCategoriesFailure,
  updateCategoriesFinished,
  deleteCategorySuccess,
  deleteCategoryFailure,
  deleteCategoryFinished,
  createCategorySuccess,
  createCategoryFailure,
  createCategoryFinished
} from './categoryAdmin.actions';
import { dateFormat } from '../../constants/global-constants';
import { normalizeValueCode } from '../../shared/functions/sectionFunctions';

function* sendCategorie(param) {
  const result = yield call(apiNomenclature.putCategoryOrder, param);
  return result.data;
}

export function* updateCategories({ payload: params }) {
  try {
    const promises = params.map(param => sendCategorie(param));
    const data = yield all(promises);
    yield put(updateCategoriesSuccess(data));
  } catch (error) {
    yield put(updateCategoriesFailure(error.response.data));
  }
  yield put(updateCategoriesFinished());
}

export function* deleteCategories({ payload: params }) {
  try {
    const { data } = yield call(apiNomenclature.postDeactivateValue, params);
    yield put(deleteCategorySuccess(data));
  } catch (error) {
    yield put(deleteCategoryFailure(error.response.data));
  }
  yield put(deleteCategoryFinished());
}

export function* createCategories({ payload: params }) {
  const { level, newValue, section, category } = params;
  const date = moment().format(dateFormat.LOCAL_DATE);

  const getOrder = () => {
    let max = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const categorySection of section.categories) {
      // eslint-disable-next-line no-restricted-syntax
      for (const subCategory of categorySection.subCategory) {
        max = max >= subCategory.order ? max : subCategory.order;
      }
      max = categorySection.order >= max ? categorySection.order : max;
    }
    return max;
  };

  getOrder();
  const paramsForPost = {
    level,
    newValue,
    valueCode: normalizeValueCode(newValue),
    order: getOrder() + 1,
    parentData: level === 3 ? category?.refCategory : section?.refSection,
    date
  };

  try {
    const { data } = yield call(apiNomenclature.postNewCategory, paramsForPost);
    yield put(createCategorySuccess(data));
    yield call(apiNomenclature.postActivateValue, paramsForPost);
  } catch (error) {
    yield put(createCategoryFailure(error.response.data));
  }
  yield put(createCategoryFinished());
}

export function* onUpdateCategoriesStart() {
  yield takeLatest(
    CategoryAdminTypes.UPDATE_CATEGORIES_START,
    updateCategories
  );
}

export function* onDeleteCategoriesStart() {
  yield takeLatest(CategoryAdminTypes.DELETE_CATEGORY_START, deleteCategories);
}

export function* onCreateCategoriesStart() {
  yield takeLatest(CategoryAdminTypes.CREATE_CATEGORY_START, createCategories);
}

export function* categoryAdminSagas() {
  yield all([
    call(onUpdateCategoriesStart),
    call(onDeleteCategoriesStart),
    call(onCreateCategoriesStart)
  ]);
}
