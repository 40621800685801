export function partnerBuilder(itemPartner) {
  return {
    refRelationShipId: itemPartner?.refRelationShipId,
    refNaturalPerson: itemPartner?.refNaturalPerson,
    refLegalEntity: itemPartner?.refLegalEntity,
    lastName: itemPartner?.lastName,
    firstName: itemPartner?.firstName,
    contactPhone: itemPartner?.contactPhone,
    contactEmail: itemPartner?.contactEmail
  };
}
