import React, { useEffect } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createRootes } from './constants/routes.jsx';
import Header from './components/layout/header/Header.jsx';
import Footer from './components/layout/footer/Footer.jsx';
import AppRoute from './components/layout/AppRoute/AppRoute.jsx';
import { fetchLoginStart } from './redux/login/loginActions';
import { selectLogin } from './redux/login/loginSelectors';
import { fetchLegalEntityStart } from './redux/legalEntity/legalEntity.actions';

import { fetchCommunesStart } from './redux/createProgram/createProgramActions';
import { selectedCommunes } from './redux/createProgram/createProgramSelectors';
import { fetchProgramListCleoStart } from './redux/cleo/programListCleo/programListCleoActions.js';
import {
  SearchCleoParams,
  searchCpiParams,
  ERROR_URL,
  SearchLegalEntityParams,
  SearchOrganizationalEntityParams
} from './constants/global-constants.jsx';
import { selectProgramListCleoResults } from './redux/cleo/programListCleo/programListCleoSelectors.js';

import { fetchSectionStart } from './redux/section/sections/section.actions.js';
import { selectSectionsResult } from './redux/section/sections/section.selectors.js';
import { fetchUserInfoStart } from './redux/user-info/user-info.actions.js';
import { selectUserInfo } from './redux/user-info/user-info.selectors.js';
import { fetchFormatStart } from './redux/formats/format.actions.js';
import { selectFormatsResult } from './redux/formats/format.selectors.js';
import { fetchSubformatStart } from './redux/subformats/subformat.actions.js';
import { selectSubformatsResult } from './redux/subformats/subformat.selectors.js';
import { fetchAppMemberStart } from './redux/share/share.actions.js';
import { selectAppMember } from './redux/share/share.selectors.js';
import { isCPIActive } from './shared/rights/interface.js';
import { isExternalUser } from './shared/rights/root-rights.js';
import { INTERFACE } from './api/api.conf';
import { fetchOrganizationalEntityStart } from './redux/organizationalEntity/organizationalEntity.actions.js';
import { selectOrganizationalEntitysResult } from './redux/organizationalEntity/organizationalEntity.selectors.js';
import { checkVitrine } from './shared/functions/vitrine';
import '@nexity/avenir-components/dist/index.css';

const App = ({
  fetchLogin,
  login,
  communes,
  fetchCommunes,
  fetchProgramListCleo,
  programListCleo,
  sections,
  fetchSections,
  fetchUserInfo,
  userInfo,
  fetchFormats,
  formats,
  fetchSubformats,
  subformats,
  fetchAppMember,
  appMember,
  location,
  fetchLegalEntity,
  fetchOrganizationalEntity,
  organizationalEntitysResult,
  history
}) => {
  useEffect(() => {
    fetchLogin();
  }, []);

  const getParams = user => {
    let params = {};
    const { groups, permissions } = user;
    if (isExternalUser(permissions)) {
      params = {
        companyCodes: groups
          ?.map(group => {
            if (group.startsWith('/Nexity/IR/')) {
              return Number(group.split('/')[3]);
            }

            return null;
          })
          .filter(code => code !== null)
      };
    }
    params = {
      ...params,
      searchParams: isCPIActive() ? searchCpiParams : SearchCleoParams
    };

    return params;
  };

  useEffect(() => {
    if (login?.isAuthenticated) {
      fetchUserInfo();
      if (!communes) {
        fetchCommunes();
      }
      if (!sections.length > 0) {
        fetchSections();
      }
      if (!formats.length > 0) {
        fetchFormats();
      }
      if (!subformats.length > 0) {
        fetchSubformats();
      }
      if (!organizationalEntitysResult.length > 0) {
        fetchOrganizationalEntity(SearchOrganizationalEntityParams);
      }
    }
  }, [login]);

  useEffect(() => {
    if (userInfo && login?.isAuthenticated) {
      fetchAppMember({ email: userInfo?.email });

      if (!programListCleo) {
        fetchProgramListCleo(getParams(userInfo));
      }
      if (INTERFACE !== 'partner') {
        fetchLegalEntity(SearchLegalEntityParams);
      }
    }
  }, [userInfo]);

  if (!login?.isAuthenticated || !userInfo?.permissions) {
    return <div></div>;
  }

  return (
    <>
      {!location?.pathname.includes(ERROR_URL) && (
        <Header userInfo={appMember} />
      )}
      <Switch>
        {createRootes().map(route => (
          <AppRoute key={route} {...route} />
        ))}
      </Switch>
      {!checkVitrine(history) && <Footer />}
    </>
  );
};

App.propTypes = {
  fetchLogin: PropTypes.func,
  login: PropTypes.oneOfType([PropTypes.string]),
  communes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchCommunes: PropTypes.func,
  fetchProgramListCleo: PropTypes.func,
  programListCleo: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchSections: PropTypes.func,
  fetchUserInfo: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  userInfo: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchFormats: PropTypes.func,
  formats: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchSubformats: PropTypes.func,
  subformats: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchAppMember: PropTypes.func,
  appMember: PropTypes.func,
  location: PropTypes.objectOf(PropTypes.object()),
  fetchLegalEntity: PropTypes.func,
  fetchOrganizationalEntity: PropTypes.func,
  organizationalEntitysResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  history: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  login: selectLogin,
  communes: selectedCommunes,
  programListCleo: selectProgramListCleoResults,
  sections: selectSectionsResult,
  userInfo: selectUserInfo,
  formats: selectFormatsResult,
  subformats: selectSubformatsResult,
  appMember: selectAppMember,
  organizationalEntitysResult: selectOrganizationalEntitysResult
});

const mapDispatchToProps = dispatch => ({
  fetchLogin: () => dispatch(fetchLoginStart()),
  fetchCommunes: () => dispatch(fetchCommunesStart()),
  fetchProgramListCleo: params => dispatch(fetchProgramListCleoStart(params)),
  fetchSections: () => dispatch(fetchSectionStart()),
  fetchUserInfo: () => dispatch(fetchUserInfoStart()),
  fetchFormats: () => dispatch(fetchFormatStart()),
  fetchSubformats: () => dispatch(fetchSubformatStart()),
  fetchAppMember: params => dispatch(fetchAppMemberStart(params)),
  fetchLegalEntity: params => dispatch(fetchLegalEntityStart(params)),
  fetchOrganizationalEntity: params =>
    dispatch(fetchOrganizationalEntityStart(params))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
