import SubformatTypes from './subformat.types';

const INITIAL_STATE = {
  result: [],
  error: null,
  isLoading: false,
  responseSubformatImage: null,
  isLoadingSubformatImage: false
};

const SubformatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubformatTypes.FETCH_SUBFORMAT_START:
      return { ...state, isLoading: true };
    case SubformatTypes.FETCH_SUBFORMAT_SUCCESS:
      return { ...state, result: action.payload, error: null };
    case SubformatTypes.FETCH_SUBFORMAT_FAILURE:
      return { ...state, error: action.payload };
    case SubformatTypes.FETCH_SUBFORMAT_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default SubformatReducer;
