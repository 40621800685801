import axios from 'axios';
import { API_ROUTES } from './apiRoutes';
import { AUTHENTICATION } from '../constants/global-constants';
import { API_PLG, KEYCLOAK_TOKEN_URL } from './api.conf';

class API {
  http;

  constructor() {
    const instance = axios.create({
      baseURL: API_PLG,
      headers: {
        'X-request-Origin': 'PLG;component=Swagger'
      }
    });
    this.http = instance;
    this.addInterceptor();
  }

  ACCESS_DENIED_PLG = 'Access Denied';

  isTokenInvalidPLG = errorPlg =>
    (errorPlg.status && (errorPlg.status === 401 || errorPlg.status === 403)) ||
    errorPlg.data?.message === this.ACCESS_DENIED_PLG;

  isTokenExpirePLG = () => Date.now() > +localStorage.refreshIn * 1000;

  refreshTokenPlg = refreshTokenPlg => {
    const urlencodedPlg = new URLSearchParams();

    urlencodedPlg.append('grant_type', 'refresh_token');
    urlencodedPlg.append('client_id', 'avr-front');
    urlencodedPlg.append('refreshTokenPlg', refreshTokenPlg);

    const requestOptionsPlg = {
      method: 'POST',
      body: urlencodedPlg
    };

    return fetch(KEYCLOAK_TOKEN_URL, requestOptionsPlg).then(fetchResponse =>
      fetchResponse.json()
    );
  };

  rejectedRequestInterceptorPLG = err => {
    const errorPlg = err && err.response ? err.response : err;

    const originalRequest = errorPlg.config;
    let alreadyRetryingRequestPLG = false;

    const shouldRefreshToken = () =>
      (this.isTokenInvalidPLG(errorPlg) &&
        !alreadyRetryingRequestPLG &&
        !!localStorage.refreshTokenPlg) ||
      this.isTokenExpirePLG();

    const updateTokensPlg = (token, refreshTokenPlg) => {
      this.setAutorizationTokenPlg(token, refreshTokenPlg);
      originalRequest.headers[
        AUTHENTICATION.authorizationKey
      ] = `${AUTHENTICATION.bearerKey} ${token}`;
    };
    const fetchTokenRetry = () => {
      alreadyRetryingRequestPLG = true;
      return new Promise(resolve => {
        this.refreshTokenPlg(localStorage.refreshTokenPlg).then(response => {
          updateTokensPlg(response.access_token, response.refresh_token);
          alreadyRetryingRequestPLG = false;
          resolve(axios(originalRequest));
        });
      });
    };
    const redirectToAuthenticationErrorPlg = authError => {
      if (authError.status === 403) {
        window.location.reload(false);
      }
      return Promise.reject(authError);
    };

    return shouldRefreshToken()
      ? fetchTokenRetry()
      : redirectToAuthenticationErrorPlg(errorPlg);
  };

  addInterceptor = () =>
    this.http.interceptors.response.use(
      response => response,
      this.rejectedRequestInterceptorPLG
    );

  setAutorizationTokenPlg = (tokenPLG, refreshTokenPlg) => {
    if (tokenPLG) {
      localStorage.setItem(AUTHENTICATION.jwtTokenKey, tokenPLG);
      if (refreshTokenPlg) {
        localStorage.setItem(AUTHENTICATION.refreshTokenKey, refreshTokenPlg);
      }
      this.http.defaults.headers.common.Authorization = `${AUTHENTICATION.bearerKey} ${tokenPLG}`;
    } else {
      delete this.http.defaults.headers.common.Authorization;
      localStorage.clear();
    }
  };

  getDates = refOperation =>
    this.http.post(
      `${API_ROUTES.advancedSearch}?pageSize=1&pageIndex=1`,

      { operations: [refOperation], blockStatus: null, agencies: null }
    );
}

export default new API();
