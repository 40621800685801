import { createSelector } from 'reselect';

const selectSections = state => state.sections;

export const selectSectionsResult = createSelector(
  [selectSections],
  sections => sections.result
);

export const selectSectionByRef = refSection =>
  createSelector([selectSections], sections =>
    sections.result.find(sec => sec.refSection === refSection)
  );

export const selectSectionsCount = createSelector(
  [selectSections],
  sections => sections.result?.length
);

export const selectSectionsLoading = createSelector(
  [selectSections],
  sections => sections.isLoading
);

export const selectSectionsError = createSelector(
  [selectSections],
  sections => sections.error
);
