import VisualProgramActionTypes from './visual-program.types';

const INITIAL_STATE = {
  visualProgramResult: [],
  error: null,
  isLoading: false
};

const VisualProgramReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_START:
      return { ...state, isLoading: true };
    case VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_SUCCESS:
      return { ...state, visualProgramResult: action.payload, error: null };
    case VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_FAILURE:
      return { ...state, error: action.payload };
    case VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default VisualProgramReducer;
