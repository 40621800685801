import { put, all, call, takeLatest } from 'redux-saga/effects';
import createSectionTypes from './createSectionTypes';
import apiNomenclature from '../../../api/apiNomenclature';
import {
  CreateSectionSuccess,
  CreateSectionFailure,
  CreateSectionFinish
} from './createSectionActions';
import { createSectionBuilder } from './createSectionBuilder';
import api from '../../../api/api';
import { NOM_INFO_TYPE } from '../../../constants/global-constants';
import { createFileUploadToAPI } from '../../../shared/functions/createFilesPayload';
import { getNowDate } from '../../../shared/functions/date';

function* upMedia(params) {
  const fileToUpload = yield createFileUploadToAPI(params.image);
  const payload = { refSection: params.creationForm.valueCode, fileToUpload };
  const data = yield call(api.sendThumbnailSection, payload);
  const { headers } = data;
  const newItem = {
    informationCode: NOM_INFO_TYPE.SECTION_IMAGE,
    value: headers.location,
    startValidityDate: getNowDate(new Date())
  };

  return params.creationForm.additionnalInformation.push(newItem);
}

function* hasImage(params) {
  if (params.image) {
    yield upMedia(params);
    yield call(
      apiNomenclature.putSection,
      params.creationForm.valueCode,
      params.creationForm
    );
  }
}

export function* createSection({ payload: params }) {
  try {
    const { data } = yield call(
      apiNomenclature.postSection,
      params.creationForm
    );
    const dataBuilder = yield call(createSectionBuilder, data);
    yield call(apiNomenclature.postActivate, dataBuilder.reference);
    yield hasImage(params);
    yield put(CreateSectionSuccess(dataBuilder));
  } catch (error) {
    yield put(CreateSectionFailure(error.response?.data));
  }
  yield put(CreateSectionFinish());
}

export function* onCreateSectionStart() {
  yield takeLatest(createSectionTypes.CREATE_SECTION_START, createSection);
}

export function* createSectionSagas() {
  yield all([call(onCreateSectionStart)]);
}
