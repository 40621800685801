import { convertBytes } from '../functions/convertBytes';
import { tagListBuilder } from '../../redux/tag-list/tag.list.builder';
import { sectionItemBuilder } from '../../redux/section/sections/section.builder';
import { folderDetailsBuilder } from '../../redux/folder-details/folder-details.builder';
import { programBuilder } from './program.builder';
import pdfThumbnail from '../../assets/images/pdfThumbnail.png';
import { sortByPosition } from '../functions/sort';
import { MEDIA_TYPE } from '../../constants/global-constants';

export const categoryBuilder = itemCategory => {
  return {
    refCategory: itemCategory?.category?.refValue
  };
};

export const subcategoryBuilder = itemSubcategory => {
  return {
    refSubCategory: itemSubcategory?.subCategory?.refValue
  };
};

export function instanceListBuilder(files) {
  return files?.map(file => {
    return {
      mediaContentType: file?.contentType,
      copyright: file?.copyright,
      format: file?.format,
      subFormat: file?.subFormat,
      resolution: `${file?.width}x${file?.height}`,
      name: file?.name,
      status: file?.status,
      refFile: file?.refFile,
      mediaURL: file?.url,
      size: file?.size
    };
  });
}

function mediaItemBuilder(item, communes, payloadFiles) {
  // For get url to the file in user local browser
  const localFileUrl = payloadFiles?.filter(
    media => media.file?.size === item?.size
  )?.[0]?.file?.fileUrl;

  const programBuilded = programBuilder(
    item?.program || item?.folder?.program,
    communes
  );
  const folderBuilded = folderDetailsBuilder(item?.folder, communes);

  return {
    category: categoryBuilder(item),
    subCategory: subcategoryBuilder(item),
    currentSection: item?.section?.name,
    currentRefSection: item?.section?.refSection,
    nameMedia: item?.name,
    format: item?.format,
    resolution: `${item?.width} x ${item?.height}`,
    refMedia: item?.refMedia,
    mediaURL: item?.url,
    localFileUrl,
    copyright: item?.copyright,
    dateUpdate: item?.updatedDate,
    dateUpload: item?.createdDate,
    typeMedia: item?.type,
    userUpdate: item?.updatedBy,
    userUpload: item?.createdBy,
    mediaContentType: item?.contentType,
    original:
      item?.type?.refValue === MEDIA_TYPE.video
        ? item.preview
        : item?.thumbnail,
    thumbnail:
      item?.type?.refValue === MEDIA_TYPE.document
        ? pdfThumbnail
        : item?.thumbnail,
    name: item?.name,
    nameExt: item?.nameExt,
    folder: folderBuilded,
    size: convertBytes(item?.size),
    durationOfUse: item?.expirationDate,
    tags: tagListBuilder(item?.tags),
    instances: instanceListBuilder(item?.files),
    program: programBuilded,
    section: item?.section ? sectionItemBuilder(item?.section) : undefined,
    descriptionOfMedia: item?.description,
    support: item?.supports,
    type: item?.section?.type,
    position: item?.position,
    cityCode: item?.program?.cityCode,
    status: item?.status,
    title: item?.title,
    comment: item?.description
  };
}

function mediaListBuilder(mediaList, communes, payloadFiles) {
  return mediaList?.result?.map(item => {
    return mediaItemBuilder(item, communes, payloadFiles);
  });
}

export function mediasBuilderWithPages(data, communes, payloadFiles) {
  const buildData = mediaListBuilder(data, communes, payloadFiles);
  return {
    page: data?.page,
    medias: sortByPosition(buildData) || mediaListBuilder(data)
  };
}

export function mediasBuilderWithoutPages(data, communes, payloadFiles) {
  const buildData = mediaListBuilder(data, communes, payloadFiles);
  return sortByPosition(buildData) || mediaListBuilder(data);
}

export function mediasBuilderWithPagesNoOrdered(data, communes) {
  const buildData = mediaListBuilder(data, communes);
  return {
    page: data?.page,
    medias: buildData
  };
}

export function mediasBuilderWithoutPagesNoOrdered(data, communes) {
  return mediaListBuilder(data, communes);
}
