import { sectionItemBuilder } from '../../redux/section/sections/section.builder';
import { cityBuilder } from './cityBuilder';

export function programBuilder(itemProgram, communes) {
  const cityBuilded = cityBuilder(itemProgram?.cityCode, communes);
  return {
    name: itemProgram?.name,
    city: cityBuilded,
    code: itemProgram?.code,
    refProgram: itemProgram?.refProgram,
    address: itemProgram?.address,
    section: sectionItemBuilder(itemProgram?.section),
    vignette: itemProgram?.thumbnailUrl,
    filiale: itemProgram?.refOrganizationalEntity,
    cityCode: itemProgram?.cityCode,
    propertyUse: itemProgram?.propertyIntendedUse?.refValue,
    typeProjet: itemProgram?.typeProjet?.refValue,
    year: itemProgram?.year,
    location: {
      lat: itemProgram?.lat,
      lng: itemProgram?.lng
    },
    perimeter: itemProgram?.perimeter
  };
}
