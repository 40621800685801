import { createSelector } from 'reselect';

const selectPutFaq = state => state.putFaq;

export const selectPutFaqMessage = createSelector(
  [selectPutFaq],
  putFaq => putFaq.message
);

export const selectPutFaqIsLoading = createSelector(
  [selectPutFaq],
  putFaq => putFaq.isLoading
);

export const selectPutFaqError = createSelector(
  [selectPutFaq],
  putFaq => putFaq.error
);

export const selectPutFaqSucceed = createSelector(
  [selectPutFaq],
  putFaq => putFaq.succeed
);
