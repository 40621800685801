import { put, all, call, takeLatest } from 'redux-saga/effects';
import PutConditionsTypes from './putConditionsTypes';
import api from '../../api/api.jsx';
import {
  sendPutConditionsDataSuccess,
  sendPutConditionsDataFailure,
  sendPutConditionsDataFinished
} from './putConditionsActions';

export function* putConditions({ payload: form }) {
  try {
    const { data } = yield call(api.putConditions, form);
    yield put(sendPutConditionsDataSuccess(data));
  } catch (error) {
    yield put(sendPutConditionsDataFailure(error));
  }
  yield put(sendPutConditionsDataFinished());
}

export function* onPutConditionsStart() {
  yield takeLatest(PutConditionsTypes.SEND_PUT_CONDITIONS_START, putConditions);
}

export function* putConditionsSagas() {
  yield all([call(onPutConditionsStart)]);
}
