import folderDetailsActionTypes from './folder-details.types';

export const fetchFolderDetailsStart = params => ({
  type: folderDetailsActionTypes.FETCH_FOLDER_DETAILS_START,
  payload: params
});

export const fetchFolderDetailsSuccess = data => ({
  type: folderDetailsActionTypes.FETCH_FOLDER_DETAILS_SUCCESS,
  payload: data
});

export const fetchFolderDetailsFailure = error => ({
  type: folderDetailsActionTypes.FETCH_FOLDER_DETAILS_FAILURE,
  payload: error
});

export const fetchFolderDetailsFinished = () => ({
  type: folderDetailsActionTypes.FETCH_FOLDER_DETAILS_FINISHED
});
