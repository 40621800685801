/* eslint-disable no-unused-expressions */
import React from 'react';
// import { getUserInfo } from '../rights/user-info';
import FilialLogo from '../../components/layout/filialLogo/filialLogo';
import Logo from '../../components/layout/logo/Logo';
import { isPartener } from '../rights/interface';
import logoDomitys from '../../assets/filialLogos/logo-domitys.png';
import logoEdouardDenis from '../../assets/filialLogos/logo-edouard-denis.png';
import logoPerl from '../../assets/filialLogos/logo-perl.png';
import logoAvenir from '../../assets/filialLogos/logo-avenir.png';
import { FILIAL } from '../../constants/global-constants';

// TODO For modify after the partner api is finished
// const reg = /[./]/;

/* const getFilials = groups => {
  const filials = [];

  groups?.map(group => {
    const filial = group.split(reg).filter(Boolean);
    if (filial.includes('Applications')) {
      return null;
    }

    return filials.push(filial);
  });
  return filials;
}; */

const getImageFromFilial = filial => {
  if (filial?.toUpperCase().includes(FILIAL.aegide || FILIAL.domitys)) {
    return logoDomitys;
  }
  if (filial?.toUpperCase().includes(FILIAL.perl)) {
    return logoPerl;
  }
  if (filial?.toUpperCase().includes(FILIAL.edouard)) {
    return logoEdouardDenis;
  }

  /* Fix: use Avenir logo instead of Nexity logo */
  return logoAvenir;
};

export const getFilialLogo = userInfo => {
  if (isPartener()) {
    return <Logo />;
  }

  // TODO For modify after the partner api is finished
  /*   const { groups } = getUserInfo();
  const filials = getFilials(groups);
  if (filials?.length > 1) {
    return <Logo />;
  } */

  if (userInfo) {
    return (
      <FilialLogo
        getImage={() => getImageFromFilial(userInfo[0]?.filialName)}
      />
    );
  }
  return <Logo />;
};
