/**
 * Create file payload
 * @param {Object} oldIndex - old index
 * @param {Object} newIndex - new index
 * @param {Object} items - items
 */
export const arrayMove = (oldIndex, newIndex, items) => {
  const newItems = items;
  const element = newItems[oldIndex];
  newItems.splice(oldIndex, 1);
  newItems.splice(newIndex, 0, element);
  return newItems;
};
