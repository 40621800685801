/**
 * Actions types of copy program list
 */
const CopyProgramListActionTypes = {
  FETCH_COPY_PROGRAM_LIST_START: 'FETCH_COPY_PROGRAM_LIST_START',
  FETCH_COPY_PROGRAM_LIST_SUCCESS: 'FETCH_COPY_PROGRAM_LIST_SUCCESS',
  FETCH_COPY_PROGRAM_LIST_FAILURE: 'FETCH_COPY_PROGRAM_LIST_FAILURE',
  FETCH_COPY_PROGRAM_LIST_FINISHED: 'FETCH_COPY_PROGRAM_LIST_FINISHED'
};

export default CopyProgramListActionTypes;
