import { put, all, call, takeLatest } from 'redux-saga/effects';
import PostLegalEntity from './postLegalEntity.types.js';
import api from '../../api/apiIntervenants.jsx';
import {
  postLegalEntitySuccess,
  postLegalEntityFailure,
  postLegalEntityFinished
} from './postLegalEntity.actions';

function getLegalEntityId(string) {
  const n = string.split('/');
  return n[n.length - 1];
}

export function* postLegalEntity({ payload: dataLegalEntity }) {
  try {
    let result = null;
    if (dataLegalEntity.payloadSociety !== undefined) {
      const response = yield call(
        api.postLegalEntitySociety,
        dataLegalEntity.payloadSociety
      );
      const legalEntityId = getLegalEntityId(response.headers.location);
      const data = yield call(api.getLegalEntity, legalEntityId);

      result = data?.data?.legalEntityRelationships[0];
    } else {
      const { data } = yield call(api.postLegalEntity, dataLegalEntity);
      result = data;
    }
    yield put(postLegalEntitySuccess(result));
  } catch (error) {
    yield put(postLegalEntityFailure(error));
  }
  yield put(postLegalEntityFinished());
}

export function* onPostLegalEntity() {
  yield takeLatest(PostLegalEntity.POSTLEGALENTITY_START, postLegalEntity);
}

export function* postLegalEntitySagas() {
  yield all([call(onPostLegalEntity)]);
}
