import SearchActionTypes from './search.types';
import text from '../../constants/translates';
import { EMPTY_STRING } from '../../constants/global-constants';

const INITIAL_STATE = {
  searchResults: {},
  toolbarParams: {
    isVisible: false,
    text: EMPTY_STRING,
    refFilter: text.homeBannerContainer.refAll,
    filterDisabled: false
  },
  suggestions: [],
  suggestionsLoading: false,
  suggestionsError: null,
  error: null,
  isLoading: false,
  searchResultsWithMedia: {},
  withMediaIsLoading: false,
  searchResultsWithoutMedia: {},
  withoutMediaIsLoading: false
};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SearchActionTypes.FETCH_SEARCH_RESULTS_START:
      return { ...state, isLoading: true };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_START:
      return { ...state, withMediaIsLoading: true };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_START:
      return { ...state, withoutMediaIsLoading: true };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        searchResults: action.payload,
        error: null
      };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_SUCCESS:
      return {
        ...state,
        searchResultsWithMedia: action.payload,
        error: null
      };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_SUCCESS:
      return {
        ...state,
        searchResultsWithoutMedia: action.payload,
        error: null
      };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_FAILURE:
    case SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_FAILURE:
    case SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_FAILURE:
      return { ...state, error: action.payload };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_FINISHED:
      return { ...state, isLoading: false };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_FINISHED:
      return { ...state, withMediaIsLoading: false };
    case SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_FINISHED:
      return { ...state, withoutMediaIsLoading: false };
    case SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_START:
      return { ...state, suggestionsLoading: true };
    case SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: action.payload,
        suggestionsError: null
      };
    case SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_FAILURE:
      return { ...state, suggestionsError: action.payload };
    case SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_FINISHED:
      return { ...state, suggestionsLoading: false };
    case SearchActionTypes.FETCH_SEARCH_TOOLBAR:
      return {
        ...state,
        toolbarParams: { ...state.toolbarParams, ...action.payload }
      };
    default:
      return state;
  }
};

export default searchReducer;
