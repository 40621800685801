import FileFolderUploader from './fileFolderUploaderTypes';

const INITIAL_STATE = {
  files: [],
  error: null,
  isLoading: false
};

const fileFolderUploaderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FileFolderUploader.ADD_FILES_FOLDER_START:
      return {
        ...state,
        isLoading: true,
        payloadFiles: action.payload,
        succeed: false
      };

    case FileFolderUploader.ADD_FILES_FOLDER_SUCCESS:
      return { ...state, files: action.payload, error: null, succeed: true };

    case FileFolderUploader.ADD_FILES_FOLDER_FAILURE:
      return { ...state, error: action.payload, succeed: false };

    case FileFolderUploader.ADD_FILES_FOLDER_FINISHED:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default fileFolderUploaderReducer;
