import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import text from '../../constants/translates';
import FilterSection from './FilterSection';
import facetsTranslates from '../../constants/facets-translates.jsx';
import facetsTranslatesVitrine from '../../constants/facets-translates-vitrine.jsx';
import { checkVitrine } from '../../shared/functions/vitrine';

import './style.scss';

const Filter = ({
  filterTree,
  onFilterChanged,
  section,
  showFilial,
  onFilialChanged,
  filialChecked,
  history,
  isVitrine,
  filterState
}) => {
  const [filterList, setFilterList] = useState({});
  useEffect(() => {
    setFilterList({});
  }, [section]);

  let facets;
  const changeFacets = () => {
    if (checkVitrine(history) === false) {
      facets = facetsTranslates;
    } else {
      facets = facetsTranslatesVitrine;
    }
  };
  const uncheckChildrens = (filterSection, name) => {
    filterList[name] = filterSection;

    if (filterSection?.length < 1) {
      Object.keys(facets).forEach(key => {
        if (facets[key].parent === name) {
          filterList[key] = [];
          uncheckChildrens([], key);
        }
      });
    }
    return filterList;
  };

  const handleSectionFilterChanged = (filterSection, name) => {
    const newFilterList = uncheckChildrens(filterSection, name);
    setFilterList(newFilterList);
    if (onFilterChanged) {
      let listInArray = [];
      Object.keys(newFilterList).forEach(key => {
        if (newFilterList[key].length > 0) {
          listInArray = [...listInArray, ...newFilterList[key]];
        }
      });
      onFilterChanged(listInArray);
    }
  };

  const handleFilialChanged = event => {
    if (onFilialChanged) {
      onFilialChanged(event);
    }
  };

  changeFacets();

  return (
    <div className="filter__container">
      <p className="filter__title">{text.programListPage.myPrograms}</p>
      {showFilial && (
        <div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={e => handleFilialChanged(e)}
                  checked={filialChecked}
                />
              }
              label={
                <span className="filial-title">
                  {text.programListPage.seeMyProgram}
                </span>
              }
            />
          </FormGroup>
        </div>
      )}
      <p className="filter__subtitle">{text.filter.title}</p>
      {filterTree?.length > 0 ? (
        <>
          {filterTree
            ?.sort((a, b) => facets[a?.name]?.order - facets[b?.name]?.order)
            ?.map(filter => {
              return (
                <FilterSection
                  key={filter?.name}
                  facet={filter}
                  parents={filterList}
                  onSectionFilterChanged={filterSec =>
                    handleSectionFilterChanged(filterSec, filter?.name)
                  }
                  history={history}
                  isVitrine={isVitrine}
                  filterState={filterState}
                />
              );
            })}
        </>
      ) : (
        <p className="no-filter__title">{text.filter.noFilter}</p>
      )}
    </div>
  );
};

Filter.propTypes = {
  filterTree: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  onFilterChanged: PropTypes.func,
  section: PropTypes.string,
  showFilial: PropTypes.bool,
  onFilialChanged: PropTypes.func,
  filialChecked: PropTypes.bool,
  history: PropTypes.func,
  isVitrine: PropTypes.bool,
  filterState: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

export default Filter;
