import ModeControllerTypes from './modeController.types';

export const setModeStart = params => ({
  type: ModeControllerTypes.SET_MODE_START,
  payload: params
});

export const setModeSuccess = params => ({
  type: ModeControllerTypes.SET_MODE_SUCCESS,
  payload: params
});

export const sendMediasToCallbackStart = params => ({
  type: ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_START,
  payload: params
});

export const sendMediasToCallbackSuccess = () => ({
  type: ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_SUCCESS
});

export const sendMediasToCallbackFailure = error => ({
  type: ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_FAILURE,
  payload: error
});

export const sendMediasToCallbackFinished = () => ({
  type: ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_FINISHED
});
