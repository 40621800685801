import programExternalInfoActionTypes from './programExternalInfo.types';

const INITIAL_STATE = {
  programExternalInfoData: [],
  error: null,
  isLoading: false
};

const programExternalInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_START:
      return { ...state, isLoading: true };

    case programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_SUCCESS:
      return { ...state, programExternalInfoData: action.payload, error: null };

    case programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_FAILURE:
      return { ...state, error: action.payload };
    case programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_FINISHED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
export default programExternalInfoReducer;
