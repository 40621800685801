import { createSelector } from 'reselect';

const selectModeController = state => state.modeController;

export const selectedCallback = createSelector(
  [selectModeController],
  modeController => modeController?.callback
);

export const selectedMode = createSelector(
  [selectModeController],
  modeController => modeController
);
