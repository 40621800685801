import PutConditions from './putConditionsTypes';

const INITIAL_STATE = {
  message: null,
  error: null,
  isLoading: false
};

const putConditionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PutConditions.SEND_PUT_CONDITIONS_START:
      return { ...state, isLoading: true, succeed: false };

    case PutConditions.SEND_PUT_CONDITIONS_SUCCESS:
      return { ...state, message: action.payload, error: null, succeed: true };

    case PutConditions.SEND_PUT_CONDITIONS_FAILURE:
      return { ...state, error: action.payload.response.data, succeed: false };

    case PutConditions.SEND_PUT_CONDITIONS_FINISHED:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default putConditionsReducer;
