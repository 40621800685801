/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import style from './style';
import t from '../../constants/translates';
import { stateErrors } from '../../redux/errors/errorsHelper';
import {
  ERROR_TYPES,
  REDIRECT_TO,
  copyrightCode,
  badTypeUploadCode,
  networkErrorCode
} from '../../constants/global-constants';

const DismissableAlert = ({ stateErrorsProp, history }) => {
  const classes = makeStyles(style)();
  const [open, setOpen] = React.useState(true);
  if (
    stateErrorsProp?.data?.errors?.length > 0 &&
    stateErrorsProp?.data?.errors[0].code === ERROR_TYPES.accessDenied
  ) {
    history.push(REDIRECT_TO.accessDenied);
  }

  const printCloseIcon = () => (
    <IconButton
      id="print-button"
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => setOpen(false)}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  const scrollToInfo = () => {
    const elem = document.getElementById('errorAlert');
    if (elem !== null) {
      window.scrollTo(elem.offsetLeft, 0);
      setOpen(true);
    }
  };

  useEffect(() => {
    scrollToInfo();
  }, [stateErrorsProp]);

  const getDescription = error => {
    if (error.content?.length > 0) {
      return error.content[0].description;
    }

    return error.description;
  };

  const displayError = stateErrorsVar => {
    // Case of general network error
    if (stateErrorsVar?.toString()?.includes(networkErrorCode)) {
      return (
        <div id="errorAlert" className={classes.alert}>
          <Collapse in={open}>
            <Alert severity="error" action={printCloseIcon()}>
              <h3>{t.errorPage.errorNetwork}</h3>
            </Alert>
          </Collapse>
        </div>
      );
    }

    // Case of Base media api copyright response
    if (stateErrorsVar?.errors?.length > 0) {
      if (stateErrorsVar?.errors[0]?.code === copyrightCode) {
        return (
          <div id="errorAlert" className={classes.alert}>
            <Collapse in={open}>
              <Alert severity="error" action={printCloseIcon()}>
                {stateErrorsVar?.errors?.map(x => (
                  <AlertTitle>{getDescription(x)}</AlertTitle>
                ))}
              </Alert>
            </Collapse>
          </div>
        );
      }
    }
    // Case of Base media api response
    if (stateErrorsVar?.message) {
      return (
        <div id="errorAlert" className={classes.alert}>
          <Collapse in={open}>
            <Alert severity="error" action={printCloseIcon()}>
              <AlertTitle>{stateErrorsVar?.message}</AlertTitle>
              {stateErrorsVar?.errors?.map(x => (
                <h3>{getDescription(x)}</h3>
              ))}
            </Alert>
          </Collapse>
        </div>
      );
    }
    if (
      stateErrorsVar?.data?.errors?.length > 0 &&
      stateErrorsVar?.data?.errors[0]?.code === badTypeUploadCode
    ) {
      return (
        <div id="errorAlert" className={classes.alert}>
          <Collapse in={open}>
            <Alert severity="error" action={printCloseIcon()}>
              <h3>{stateErrorsVar?.data?.errors[0].description}</h3>
            </Alert>
          </Collapse>
        </div>
      );
    }

    // Case of Intervenants api response
    if (stateErrorsVar?.data?.errors?.length > 0) {
      return (
        <div id="errorAlert" className={classes.alert}>
          <Collapse in={open}>
            <Alert severity="error" action={printCloseIcon()}>
              <AlertTitle>{stateErrorsVar?.data?.errors[0].code}</AlertTitle>
              <h3>{stateErrorsVar?.data?.errors[0].description}</h3>
            </Alert>
          </Collapse>
        </div>
      );
    }
    return null;
  };

  return <>{displayError(stateErrorsProp)}</>;
};

DismissableAlert.propTypes = {
  stateErrorsProp: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

const mapStateToProps = createStructuredSelector({
  stateErrorsProp: stateErrors
});

export default withRouter(connect(mapStateToProps, null)(DismissableAlert));
