const LEFT_COLUMN_WIDTH = 320;

/**
 * Reorganize list according to screen resolution
 * and element size. Used for a correct visualization
 * of card components.
 * @param {Array} list - generic array list
 */
export const groupArrayByScreenSize = list => {
  if (!list || list.length === 0) {
    return [];
  }
  let resolution = window.screen.width - LEFT_COLUMN_WIDTH;
  resolution -= resolution * 0.2;

  const CARD_WIDTH = 250;
  const key = Math.floor(resolution / CARD_WIDTH);

  const map = [];
  if (!list || list.length <= key) {
    map.push(list);
    return map;
  }

  let index = 0;
  let tempMap = [];

  list.forEach(item => {
    index += 1;
    tempMap.push(item);

    if (index === key) {
      map.push(tempMap);
      tempMap = [];
      index = 0;
    }
  });

  if (tempMap.length > 0) {
    map.push(tempMap);
  }
  return map;
};
