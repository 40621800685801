import { createSelector } from 'reselect';

const selectOrganizationalEntitys = state => state.organizationalEntity;

export const selectOrganizationalEntitysResult = createSelector(
  [selectOrganizationalEntitys],
  organizationalEntity => organizationalEntity.result
);

export const selectOrganizationalEntitysCount = createSelector(
  [selectOrganizationalEntitys],
  organizationalEntity => organizationalEntity.result?.length
);

export const selectOrganizationalEntitysLoading = createSelector(
  [selectOrganizationalEntitys],
  organizationalEntity => organizationalEntity.isLoading
);

export const selectOrganizationalEntitysError = createSelector(
  [selectOrganizationalEntitys],
  organizationalEntity => organizationalEntity.error
);

export const selectCompanyDataResult = createSelector(
  [selectOrganizationalEntitys],
  organizationalEntity => organizationalEntity?.companyDataResult
);

export const selectCompanyDataResultisLoading = createSelector(
  [selectOrganizationalEntitys],
  organizationalEntity => organizationalEntity.isLoadingCompany
);
