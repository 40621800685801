import LastMediasTypes from './lastMedias.types';

const INITIAL_STATE = {
  lastMediaResult: [],
  error: null,
  isLoading: false
};

const LastMediasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LastMediasTypes.FETCH_LAST_MEDIAS_START:
      return { ...state, isLoading: true };
    case LastMediasTypes.FETCH_LAST_MEDIAS_SUCCESS:
      return { ...state, lastMediaResult: action.payload, error: null };
    case LastMediasTypes.FETCH_LAST_MEDIAS_FAILURE:
      return { ...state, error: action.payload };
    case LastMediasTypes.FETCH_LAST_MEDIAS_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default LastMediasReducer;
