import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button, Typography, makeStyles, Grid } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import { createStructuredSelector } from 'reselect';
import Dropzone from '../../components/dropzone/Dropzone';
import t from '../../constants/translates';
import { addFilesStart } from '../../redux/file-uploader/fileUploaderActions';
import { fetchProgramDetailsStart } from '../../redux/program-details/program-details.actions';
import {
  selectProgramDetails,
  selectProgramDetailsIsLoading,
  selectProgramDetailError
} from '../../redux/program-details/program-details.selectors';
import './style.scss';
import styles from './style.jsx';
import DismissableAlert from '../../components/alert/DismissableAlert';
import {
  selectedFilesIsLoading,
  selectedFilesError,
  selectedFilesSucceed,
  selectedFiles
} from '../../redux/file-uploader/fileUploaderSelectors';
import {
  selectedSectionFilesIsLoading,
  selectedSectionFilesError,
  selectedSectionFilesSucceed,
  selectedSectionFiles
} from '../../redux/file-section-uploader/fileSectionUploaderSelectors';
import {
  selectedFolderFilesIsLoading,
  selectedFolderFilesError,
  selectedFolderFilesSucceed,
  selectedFolderFiles
} from '../../redux/file-folder-uploader/fileFolderUploaderSelectors';
import {
  selectFolderDetails,
  selectFolderDetailsIsLoading,
  selectFolderDetailsError
} from '../../redux/folder-details/folder-details.selectors';
import { addFilesSectionStart } from '../../redux/file-section-uploader/fileSectionUploaderActions';
import { fetchSectionStart } from '../../redux/section/sections/section.actions';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import {
  imageTypes,
  videoTypes,
  PROGRAM_TYPE,
  EMPTY_STRING,
  SEARCH_TYPE,
  snackbarDuration,
  minFileSize
} from '../../constants/global-constants';
import { ROUTES_CONSTANTS } from '../../constants/route.constants';
import { addFilesFolderStart } from '../../redux/file-folder-uploader/fileFolderUploaderActions';
import { fetchFolderDetailsStart } from '../../redux/folder-details/folder-details.actions';
import { fetchSearchResultsStart } from '../../redux/search/search.actions';
import { selectSearchResults } from '../../redux/search/search.selectors';
import { isFilialExt } from '../../shared/rights/root-rights';
import { getPrograms } from '../../shared/functions/mediaFunctions';
import SingleFileUploadWithProgress from '../../components/dropzone/SingleFileUploadWithProgress/SingleFileUploadWithProgress';
import SnackbarAlert from '../../components/snackBar/SnackbarAlert';
import { snackBarSeverity } from '../../constants/ui.constants';

/**
 * Component for upload image or videos to the database (creation de medias)
 */
const FileUploader = ({
  addFiles,
  addFilesSection,
  addFilesFolder,
  programDetailsResult,
  isLoading,
  getProgramList,
  selectedFilesIsLoadingProp,
  selectedSectionFilesIsLoadingProp,
  selectedFolderFilesIsLoadingProp,
  history,
  selectedFilesErrorProp,
  selectedSectionFilesErrorProp,
  selectedFolderFilesErrorProp,
  fetchFolderDetails,
  selectFolderDetailsProp,
  selectFolderDetailsIsLoadingProp,
  selectFolderDetailsErrorProp,
  fetchSections,
  sections,
  fetchSearchResults,
  searchProgramResults,
  selectedSectionFilesSucceedProp,
  selectedFolderFilesSucceedProp,
  selectedFilesSucceedProp,
  selectedFilesProp,
  selectedSectionFilesProp,
  selectedFolderFilesProp
}) => {
  const style = makeStyles(styles)();
  const [files, setFiles] = useState([]);
  const [section, setSection] = useState();
  const [currentProgram, setcurrentProgram] = useState();
  const mediaResolutions = [];
  const [shouldNavigateNextPage, setShouldNavigateNextPage] = useState(false);
  const refParam = useParams();
  const isMediaProgram = refParam.refProgram !== undefined;
  const isMediaSection = refParam.refSection !== undefined;
  const isMediaFolder = refParam.refFolder !== undefined;
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [errorInFile, setErrorInFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [filesWithErrors, setFilesWithErrors] = useState([]);
  const { type } = refParam;
  let nameFolder = EMPTY_STRING;
  let count = 0;

  const getLabel = (code, name) => {
    if (code && name) {
      return `${code} - ${name}`;
    }
    if (code && !name) {
      return `${code}`;
    }
    if (!code && !name) {
      return '';
    }
    return `${name}`;
  };

  const getLoadings = () => {
    return (
      showProgress ||
      isLoading ||
      selectedFilesIsLoadingProp ||
      selectedSectionFilesIsLoadingProp ||
      selectedFolderFilesIsLoadingProp ||
      selectFolderDetailsIsLoadingProp
    );
  };

  const getStartConditions = () => {
    return (
      shouldNavigateNextPage &&
      !selectedFilesIsLoadingProp &&
      !selectedSectionFilesIsLoadingProp &&
      !selectedFolderFilesIsLoadingProp &&
      !selectedFilesErrorProp &&
      !selectedSectionFilesErrorProp &&
      !selectedFolderFilesErrorProp &&
      !selectFolderDetailsErrorProp
    );
  };

  /**
   * Effect for get the data if the media to upload is to 1 program or to 1 folder
   */
  useEffect(() => {
    if (isMediaProgram) {
      if (PROGRAM_TYPE.IR_PROGRAM !== type) {
        getProgramList(refParam);
      } else if (PROGRAM_TYPE.IR_PROGRAM === type) {
        const params = {
          type: SEARCH_TYPE.program,
          text: refParam.refProgram,
          section: PROGRAM_TYPE.IR_PROGRAM_NAME,
          myPrograms: isFilialExt()
            ?.toString()
            ?.toUpperCase()
        };
        fetchSearchResults(params);
      }
    } else if (isMediaFolder) {
      fetchFolderDetails({
        refFolder: refParam.refFolder,
        refProgram: refParam.medias
      });
    }
    if (!sections.length > 0) {
      fetchSections();
    }
  }, []);

  useEffect(() => {
    if (
      selectFolderDetailsProp?.program?.section?.refSection ===
      PROGRAM_TYPE.IR_PROGRAM_NAME
    ) {
      const params = {
        type: SEARCH_TYPE.program,
        text: selectFolderDetailsProp?.program?.refProgram,
        section,
        myPrograms: isFilialExt()
          ?.toString()
          ?.toUpperCase()
      };
      fetchSearchResults(params);
    }
  }, [selectFolderDetailsProp]);

  useEffect(() => {
    if (sections) {
      switch (type) {
        case PROGRAM_TYPE.IR_PROGRAM:
          setSection(sections.find(sec => sec.type === type));
          break;
        case PROGRAM_TYPE.IE_PROGRAM:
          setSection(
            sections.find(
              sec => sec.refSection === currentProgram?.section?.refSection
            )
          );
          break;
        default:
          setSection(
            sections.find(sec => sec.refSection === refParam.refSection)
          );
          break;
      }
    }
  }, [sections, type, currentProgram, refParam.refSection]);

  useEffect(() => {
    if (
      PROGRAM_TYPE.IR_PROGRAM === type ||
      PROGRAM_TYPE.IR_PROGRAM_NAME === type
    ) {
      const program = getPrograms(searchProgramResults)?.find(
        prog => prog.programRef.toString() === refParam.refProgram
      );
      setcurrentProgram(program);
    } else {
      setcurrentProgram(programDetailsResult);
    }
  }, [searchProgramResults, programDetailsResult]);

  useEffect(() => {
    if (
      selectedSectionFilesSucceedProp ||
      selectedFolderFilesSucceedProp ||
      selectedFilesSucceedProp
    ) {
      if (selectedSectionFilesSucceedProp) {
        setFilesToUpload(selectedSectionFilesProp);
      }
      if (selectedFolderFilesSucceedProp) {
        setFilesToUpload(selectedFolderFilesProp);
      }
      if (selectedFilesSucceedProp) {
        setFilesToUpload(selectedFilesProp);
      }

      setShowProgress(true);
    }
  }, [
    selectedSectionFilesSucceedProp,
    selectedFolderFilesSucceedProp,
    selectedFilesSucceedProp,
    selectedFilesProp,
    selectedFolderFilesProp,
    selectedSectionFilesProp
  ]);

  /**
   * Effect for route after the media upload depending if the media is to 1 program , 1 section or to 1 folder
   */

  useEffect(() => {
    if (getStartConditions() && shouldNavigateNextPage) {
      if (isMediaProgram) {
        history.push(
          `${ROUTES_CONSTANTS.programCategorisationBase}${refParam.refProgram}`
        );
      } else if (isMediaSection) {
        history.push(
          `${ROUTES_CONSTANTS.sectionCategorisationBase}${refParam.refSection}`
        );
      } else if (isMediaFolder) {
        history.push(
          `${ROUTES_CONSTANTS.folderCategorisationBase}${refParam.refFolder}`
        );
      }
    }
  }, [
    selectedFilesIsLoadingProp,
    selectedSectionFilesIsLoadingProp,
    selectedFolderFilesIsLoadingProp,
    shouldNavigateNextPage
  ]);
  const fileTimedOut = () => {
    setIsTimeOut(true);
  };

  const fileUploaded = () => {
    count += 1;
    if (count >= filesToUpload.length) {
      setShouldNavigateNextPage(true);
      count = 0;
    }
  };

  /**
   * Preparation of the data object to send to the upload media service
   * @param {object} Data to send to the upload media service
   */
  const addPayloadFiles = payload => {
    if (isMediaProgram) {
      addFiles(payload);
    } else if (isMediaSection) {
      addFilesSection(payload);
    } else if (isMediaFolder) {
      addFilesFolder(payload);
    }
  };

  /**
   * Function to add the image charged to the payload
   * @param {object} file
   * @param {object} payload
   */
  const addItemToPayload = (file, payload) => {
    const mediaInfo = mediaResolutions.find(media => media.name === file.name);
    if (isMediaProgram) {
      payload.push({
        file,
        refProgram: refParam.refProgram,
        width: mediaInfo?.width,
        height: mediaInfo?.height,
        section
      });
    } else if (isMediaSection) {
      payload.push({
        file,
        section,
        width: mediaInfo?.width,
        height: mediaInfo?.height
      });
    } else if (isMediaFolder) {
      payload.push({
        file,
        refFolder: refParam.refFolder,
        width: mediaInfo?.width,
        height: mediaInfo?.height
      });
    }
  };

  const onImgLoad = ({ target: img }, name) => {
    mediaResolutions.push({
      name,
      height: img.offsetHeight,
      width: img.offsetWidth
    });
  };

  const onVideoLoad = ({ target: video }, name) => {
    mediaResolutions.push({
      name,
      height: video.offsetHeight,
      width: video.offsetWidth
    });
  };

  useEffect(() => {
    if (filesWithErrors.length === 1) {
      setErrorMessage(
        t.formatString(t.fileUploader.uniqueFileWithNoSizeMinimun, {
          fileWithError: filesWithErrors[0].name
        })
      );
      setErrorInFile(true);
    } else if (filesWithErrors.length > 1) {
      setErrorMessage(t.fileUploader.multipleFileWithNoSizeMinimun);
      setErrorInFile(true);
    }
  }, [filesWithErrors]);

  const addFilesHandler = e => {
    const payload = [];
    const filesWithNoSizeMin = [];
    files.forEach(file => {
      if (file?.size <= minFileSize) {
        filesWithNoSizeMin.push(file);
      }
    });
    setFilesWithErrors(filesWithNoSizeMin);
    if (filesWithNoSizeMin.length >= 1) {
      e.preventDefault();
    } else {
      files.forEach(file => {
        const fileUrl = URL.createObjectURL(file); // Save the local image for the preview in the categorisation
        const media = file;
        media.fileUrl = fileUrl;
        addItemToPayload(media, payload);
      });
      addPayloadFiles(payload);
    }
  };

  const handleChange = filesChanged => {
    setFiles(filesChanged);
  };

  const handleSave = filesChanged => {
    setFiles(filesChanged);
  };

  const infoHeader = () => {
    if (isMediaProgram) {
      if (currentProgram?.name !== undefined) {
        return (
          <p id="programInfo">{`${currentProgram?.name} : ${getLabel(
            currentProgram?.code,
            currentProgram?.address
          )}`}</p>
        );
      }
      return (
        <p id="programInfo">{`${getLabel(
          currentProgram?.code,
          currentProgram?.address
        )}`}</p>
      );
    }
    if (isMediaFolder) {
      nameFolder = selectFolderDetailsProp?.name;
      const prog = {
        name: selectFolderDetailsProp?.program?.name,
        code: selectFolderDetailsProp?.program?.code,
        address: selectFolderDetailsProp?.program?.address
      };
      if (
        selectFolderDetailsProp?.program?.section?.refSection ===
        PROGRAM_TYPE.IR_PROGRAM_NAME
      ) {
        const program = getPrograms(searchProgramResults)?.find(
          progFind =>
            progFind.programRef.toString() ===
            selectFolderDetailsProp?.program?.refProgram
        );
        prog.name = program?.name;
        prog.code = program?.code;
        prog.address = program?.address;
      }
      if (prog?.name !== undefined) {
        return (
          <p id="programFolderInfo">{`${prog?.name} : ${getLabel(
            prog?.code,
            prog?.address
          )}`}</p>
        );
      }
      return (
        <p id="programFolderInfo">{`${getLabel(prog?.code, prog?.address)}`}</p>
      );
    }
    return <p></p>;
  };
  const returnLink = () => {
    return (
      <div className="returnLink">
        <Button
          variant="outlined"
          color="primary"
          className={style.returnButton}
          onClick={() =>
            history.push(
              `${ROUTES_CONSTANTS.mediaSummaryBase}${selectFolderDetailsProp?.program?.refProgram}${ROUTES_CONSTANTS.foldersLink}${selectFolderDetailsProp?.refFolder}`
            )
          }
        >
          <ArrowBackIosOutlinedIcon />
          <b className="button__text--paddingLeft">{t.programSheet.return}</b>
        </Button>
        <FolderOpenOutlinedIcon className={style.folderIcon} />
        <span className="text__refName">{selectFolderDetailsProp?.name}</span>
      </div>
    );
  };

  const goToCategoritzation = () => {
    setShouldNavigateNextPage(true);
  };

  const showButtonTimeUp = () => {
    if (isTimeOut) {
      return (
        <>
          <Button
            id="buttonNext"
            variant="outlined"
            className={
              files.length > 0
                ? `${style.button}`
                : `${style.button} ${style.buttonDisplay}`
            }
            color="secondary"
            onClick={goToCategoritzation}
          >
            <Typography className={style.buttonText}>{t.next}</Typography>
          </Button>
        </>
      );
    }
    return <></>;
  };

  const returnLoadingScreen = () => {
    return (
      <>
        <div className="fileUploadBar">
          {filesToUpload.map(fileWrapper => (
            <>
              <Grid item key={fileWrapper.inputFile.file.name}>
                <SingleFileUploadWithProgress
                  file={fileWrapper}
                  onUpload={fileUploaded}
                  onTimedOut={fileTimedOut}
                />
              </Grid>
            </>
          ))}
          {showButtonTimeUp()}
        </div>
      </>
    );
  };
  return (
    <>
      {getLoadings() ? (
        returnLoadingScreen()
      ) : (
        <>
          <SnackbarAlert
            id="AddedAlert"
            severity={snackBarSeverity.error}
            open={errorInFile}
            message={errorMessage}
            handleClose={() => setErrorInFile(false)}
            duration={snackbarDuration}
          />
          <DismissableAlert />
          <h1 className="title">{t.fileUploader.title}</h1>
          {infoHeader()}
          {isMediaFolder && returnLink()}
          <Dropzone
            onChange={handleChange}
            onSave={handleSave}
            isMediaProgram={isMediaProgram}
            folderName={nameFolder}
          />
          <Button
            id="buttonUpload"
            variant="outlined"
            className={
              files.length > 0
                ? `${style.button}`
                : `${style.button} ${style.buttonDisplay}`
            }
            color="secondary"
            onClick={() => addFilesHandler()}
          >
            <Typography className={style.buttonText}>{t.next}</Typography>
          </Button>
          <div className="img-resolution">
            {files
              .filter(file => imageTypes.indexOf(file.type) !== -1)
              ?.map(file => (
                <img
                  crossOrigin="anonymous"
                  onLoad={target => onImgLoad(target, file.name)}
                  key={file.name}
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                />
              ))}
            {files
              .filter(file => videoTypes.indexOf(file.type) !== -1)
              ?.map(file => (
                <video
                  muted
                  onLoadedData={target => onVideoLoad(target, file.name)}
                  key={file.name}
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                />
              ))}
          </div>
        </>
      )}
    </>
  );
};

FileUploader.propTypes = {
  addFiles: PropTypes.func,
  addFilesSection: PropTypes.func,
  addFilesFolder: PropTypes.func,
  getProgramList: PropTypes.func,
  history: PropTypes.func,
  programDetailsResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  isLoading: PropTypes.bool,
  selectedFilesIsLoadingProp: PropTypes.bool,
  selectedFilesErrorProp: PropTypes.string,
  selectedSectionFilesIsLoadingProp: PropTypes.bool,
  selectedSectionFilesErrorProp: PropTypes.string,
  selectedFolderFilesIsLoadingProp: PropTypes.bool,
  selectedFolderFilesErrorProp: PropTypes.string,
  fetchFolderDetails: PropTypes.func,
  selectFolderDetailsProp: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  selectFolderDetailsIsLoadingProp: PropTypes.bool,
  selectFolderDetailsErrorProp: PropTypes.string,
  fetchSections: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchSearchResults: PropTypes.func,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  selectedSectionFilesSucceedProp: PropTypes.string,
  selectedFolderFilesSucceedProp: PropTypes.string,
  selectedFilesSucceedProp: PropTypes.string,
  selectedFilesProp: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  selectedFolderFilesProp: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  selectedSectionFilesProp: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  )
};

const mapStateToProps = createStructuredSelector({
  programDetailsResult: selectProgramDetails,
  isLoading: selectProgramDetailsIsLoading,
  selectProgramDetailError,
  selectedFilesIsLoadingProp: selectedFilesIsLoading,
  selectedFilesErrorProp: selectedFilesError,
  selectedSectionFilesIsLoadingProp: selectedSectionFilesIsLoading,
  selectedSectionFilesErrorProp: selectedSectionFilesError,
  selectedFolderFilesIsLoadingProp: selectedFolderFilesIsLoading,
  selectedFolderFilesErrorProp: selectedFolderFilesError,
  selectFolderDetailsProp: selectFolderDetails,
  selectFolderDetailsIsLoadingProp: selectFolderDetailsIsLoading,
  selectFolderDetailsErrorProp: selectFolderDetailsError,
  sections: selectSectionsResult,
  searchProgramResults: selectSearchResults,
  selectedSectionFilesSucceedProp: selectedSectionFilesSucceed,
  selectedFolderFilesSucceedProp: selectedFolderFilesSucceed,
  selectedFilesSucceedProp: selectedFilesSucceed,
  selectedFilesProp: selectedFiles,
  selectedSectionFilesProp: selectedSectionFiles,
  selectedFolderFilesProp: selectedFolderFiles
});

const mapDispatchToProps = dispatch => ({
  addFiles: files => dispatch(addFilesStart(files)),
  addFilesSection: files => dispatch(addFilesSectionStart(files)),
  addFilesFolder: files => dispatch(addFilesFolderStart(files)),
  getProgramList: files => dispatch(fetchProgramDetailsStart(files)),
  fetchFolderDetails: refFolder => dispatch(fetchFolderDetailsStart(refFolder)),
  fetchSections: () => dispatch(fetchSectionStart()),
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
