import axios from 'axios';
import { NOMENCLATURE_API_URI as API_ROOT } from './api.conf';
import API_ROUTES_NOMENCLATURE, { PATH } from './apiRoutesNomenclature';
import { AUTHENTICATION } from '../constants/global-constants';

class API {
  http;

  constructor() {
    const instance = axios.create({
      baseURL: API_ROOT
    });
    this.http = instance;
  }

  setAutorizationToken = (token, refreshToken) => {
    if (token) {
      localStorage.setItem(AUTHENTICATION.jwtTokenKey, token);
      if (refreshToken) {
        localStorage.setItem(AUTHENTICATION.refreshTokenKey, refreshToken);
      }
      this.http.defaults.headers.common.Authorization = `${AUTHENTICATION.bearerKey} ${token}`;
    } else {
      delete this.http.defaults.headers.common.Authorization;
      localStorage.clear();
    }
  };

  getListOfValues = () =>
    this.http.get(`${API_ROUTES_NOMENCLATURE.listOfValue}`);

  getValuesFromListOfValues = refMainAct =>
    this.http.get(
      `${API_ROUTES_NOMENCLATURE.valueActivitePrincipal}/${refMainAct}`
    );

  getListOfQualites = () =>
    this.http.get(`${API_ROUTES_NOMENCLATURE.listOfQualites}`);

  getListOfSupport = () =>
    this.http.get(`${API_ROUTES_NOMENCLATURE.supportValues}`);

  putCategoryOrder = ({ item, order, value }) =>
    this.http.put(
      `${API_ROUTES_NOMENCLATURE.categoryValues}/${PATH.level}/${item.level}/${
        PATH.values
      }/${item.refCategory || item.refSubCategory}`,
      {
        shortLabel: value || item.shortLabel,
        longLabel: value || item.name,
        order: order || item.order,
        valueCode: item.refCategory
      }
    );

  postNewCategory = ({ level, newValue, order, valueCode, parentData, date }) =>
    this.http.post(
      `${API_ROUTES_NOMENCLATURE.categoryValues}/${PATH.level}/${level}/${PATH.values}`,
      {
        shortLabel: newValue,
        longLabel: newValue,
        order,
        valueCode,
        parentCode: parentData,
        startValidityDate: date,
        endValidityDate: date
      }
    );

  postActivateValue = ({ level, valueCode }) =>
    this.http.post(
      `${API_ROUTES_NOMENCLATURE.categoryValues}/${PATH.level}/${level}/${PATH.values}/${valueCode}/${PATH.activate}`
    );

  postDeactivateValue = ({ level, refValue }) =>
    this.http.post(
      `${API_ROUTES_NOMENCLATURE.categoryValues}/${PATH.level}/${level}/${PATH.values}/${refValue}/${PATH.deactivate}`
    );

  postSection = params =>
    this.http.post(`${API_ROUTES_NOMENCLATURE.sectionValues}`, params);

  postActivate = refValue =>
    this.http.post(
      `${API_ROUTES_NOMENCLATURE.sectionValues}/${refValue}/${PATH.activate}`
    );

  postDeactivate = refValue =>
    this.http.post(
      `${API_ROUTES_NOMENCLATURE.sectionValues}/${refValue}/${PATH.deactivate}`
    );

  putSection = (refValue, form) =>
    this.http.put(`${API_ROUTES_NOMENCLATURE.sectionValues}/${refValue}`, form);

  getTypeOfProjects = () =>
    this.http.get(`${API_ROUTES_NOMENCLATURE.projectTypes}`);

  getDestinationProperties = () =>
    this.http.get(`${API_ROUTES_NOMENCLATURE.destinationProperties}`);

  getListOfFormats = () =>
    this.http.get(
      `${PATH.listOfValue}/${PATH.formats2}/${PATH.level}/${PATH.formats}`
    );

  getListOfSubformats = () =>
    this.http.get(
      `${PATH.listOfValue}/${PATH.formats2}/${PATH.level}/${PATH.subformats}`
    );

  getListNature = () =>
    this.http.get(`${API_ROUTES_NOMENCLATURE.destinationProperties}`);

  getListNatureBloc = () =>
    this.http.get(`${API_ROUTES_NOMENCLATURE.natureProduit}`);
}

export default new API();
