import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import texts from '../../constants/translates';
import { PROGRAM_TYPE, MEDIA_TYPE } from '../../constants/global-constants';
import './style.scss';
import CardComponent from '../../components/card/CardComponent.jsx';
import VisualImageProgram from '../../components/visual-image-program/VisualImageProgram';
import VisualVideoProgram from '../../components/visual-video-program/VisualVideoProgram';
import {
  getSectionName,
  getpreview
} from '../../shared/functions/mediaFunctions';
import { copyCodeToClipboard } from '../../shared/fixtures/functions/keyboard';

import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import { selectProgramListCleoResults } from '../../redux/cleo/programListCleo/programListCleoSelectors';
import { selectMediaDetails } from '../../redux/media-details/media-details.selectors';
import { fetchMediaDetailsStart } from '../../redux/media-details/media-details.actions';
import { getThumbnailsStart } from '../../redux/thumbnails/thumbnailsActions';
import { selectedThumbnails } from '../../redux/thumbnails/thumbnailsSelectors';

/**
 * Component to show in the homepage the last 5 medias added
 */
const ListLastMedias = ({
  lastMediasResult,
  sections,
  history,
  fetchMediaDetails,
  mediaDetails,
  fetchGetThumbnails,
  thumbnails
}) => {
  const [visualProgramVisibility, setVisualProgramVisibility] = useState(false);
  const [mediaVideo, setMediaVideo] = useState(false);
  const [mediaImage, setMediaImage] = useState(false);
  const [isMediaImage, setIsMediaImage] = useState(false);
  const [mediaDocument, setMediaDocument] = useState(false);
  const [isMediaDocument, setIsMediaDocument] = useState(false);
  const updateVisualProgramVisibility = () => {
    setVisualProgramVisibility(!visualProgramVisibility);
  };

  useEffect(() => {
    fetchGetThumbnails(lastMediasResult?.map(media => media?.match));
  }, [lastMediasResult]);

  const getThumbnailFromList = media => {
    let thumbnail = getpreview(media?.status, media?.typeMedia);
    if (thumbnail === true) {
      thumbnail = thumbnails?.find(
        item => item.thumbnailApiUrl === media?.thumbnailUrl
      )?.thumbnail;
    }

    return thumbnail;
  };

  useEffect(() => {
    if (mediaImage?.refMedia) {
      fetchMediaDetails(mediaImage?.refMedia);
    }
  }, [mediaImage]);

  useEffect(() => {
    if (mediaVideo?.refMedia) {
      fetchMediaDetails(mediaVideo?.refMedia);
    }
  }, [mediaVideo]);

  useEffect(() => {
    if (mediaDocument?.refMedia) {
      fetchMediaDetails(mediaDocument?.refMedia);
    }
  }, [mediaDocument]);

  /**
   * Function for change the visibility of the media selected by the user
   * @param {object} media Media to change the visibility
   * @param {bool} visibility Visibility of the media
   */
  const showMedia = (media, visibility) => {
    if (media?.typeMedia === MEDIA_TYPE.video) {
      setIsMediaImage(false);
      setMediaVideo(media);
      setIsMediaDocument(false);
    } else if (media?.typeMedia === MEDIA_TYPE.document) {
      setIsMediaImage(false);
      setMediaDocument(media);
      setIsMediaDocument(true);
    } else {
      setIsMediaImage(true);
      setMediaImage(media);
      setIsMediaDocument(false);
    }
    setVisualProgramVisibility(visibility);
  };

  /**
   * Function for return know the type of media to return, image or video
   */
  const getVisual = () => {
    if (isMediaImage) {
      return (
        <VisualImageProgram
          image={mediaDetails}
          updateVisualProgramVisibility={updateVisualProgramVisibility}
        />
      );
    }
    if (isMediaDocument) {
      return (
        <VisualImageProgram
          image={mediaDetails}
          updateVisualProgramVisibility={updateVisualProgramVisibility}
          isDocument
        />
      );
    }
    return (
      <VisualVideoProgram
        video={mediaDetails}
        updateVisualProgramVisibility={updateVisualProgramVisibility}
        copyCodeToClipboard={copyCodeToClipboard}
      />
    );
  };

  /**
   * Function to gestion the subtitle for show
   * @param {string} lastMedia
   */
  const getSubtitle = lastMedia => {
    if (lastMedia?.programRef) {
      if (lastMedia?.programName !== undefined) {
        if (lastMedia?.section?.refSection === PROGRAM_TYPE.IR_PROGRAM_NAME) {
          return `${lastMedia?.programRef} - ${lastMedia?.programName}`;
        }
        return `${lastMedia?.programName}`;
      }
    }
    if (lastMedia?.section?.refSection) {
      return getSectionName(sections, lastMedia?.section?.refSection);
    }
    return null;
  };

  return (
    <>
      <div className="lastMediaContainer">
        <p className="menu_title">{texts.lastMedia.title}</p>
        <Grid container spacing={5}>
          {lastMediasResult?.map(lastMedia => {
            return (
              <Grid item xs key={uuidv4()}>
                <CardComponent
                  category={{
                    history,
                    title: lastMedia?.match?.category,
                    subTitle: getSubtitle(lastMedia?.match),
                    description: lastMedia?.match?.city?.name,
                    media: lastMedia?.match
                  }}
                  height="180"
                  image={getThumbnailFromList(lastMedia?.match)}
                  showMedia={showMedia}
                  media={lastMedia}
                />
              </Grid>
            );
          })}
        </Grid>
        {visualProgramVisibility && getVisual()}
      </div>
    </>
  );
};

ListLastMedias.propTypes = {
  history: PropTypes.func,
  lastMediasResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

const mapStateToProps = createStructuredSelector({
  sections: selectSectionsResult,
  cleoList: selectProgramListCleoResults,
  mediaDetails: selectMediaDetails,
  thumbnails: selectedThumbnails
});

const mapDispatchToProps = dispatch => ({
  fetchMediaDetails: refMedia => dispatch(fetchMediaDetailsStart(refMedia)),
  fetchGetThumbnails: thumbnails => dispatch(getThumbnailsStart(thumbnails))
});

ListLastMedias.propTypes = {
  mediaDetails: PropTypes.oneOfType([PropTypes.string]),
  fetchMediaDetails: PropTypes.func,
  fetchGetThumbnails: PropTypes.func,
  thumbnails: PropTypes.oneOfType([PropTypes.string])
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListLastMedias)
);
