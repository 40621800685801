import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../button/buttonComponent.jsx';
import ModalComponent from '../ModalComponent/ModalComponent';
import './styles.scss';
import styles from './styles.jsx';
import TabCopyPaste from '../../tabs/tabCopyPaste/TabCopyPaste';
import { PROGRAM_TYPE } from '../../../constants/global-constants';
import texts from '../../../constants/translates';
import {
  selectSectionsLoading,
  selectSectionsResult,
  selectSectionsError
} from '../../../redux/section/sections/section.selectors';
import {
  selectPasteMediaSucceed,
  selectPasteProgram,
  selectPasteMediaIsLoading,
  selectPasteMediaError
} from '../../../redux/paste.media/paste.media.selectors';
import { ROUTES_CONSTANTS } from '../../../constants/route.constants';

/**
 * Customize modal component to include Copy/Paste functionality
 * from one program to another one.
 */
const CopyPasteModal = ({
  open,
  onClose,
  sectionResults,
  pasteSuccess,
  pasteError,
  pasteLoading,
  program,
  history,
  media
}) => {
  const classes = makeStyles(styles)();
  const [success, setSuccess] = useState(false);
  const [sections, setSections] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const filterSection = sectionResults.filter(
      section => section.type === PROGRAM_TYPE.IR_PROGRAM
    );
    setSections(filterSection);
  }, [sectionResults]);

  useEffect(() => {
    if (pasteSuccess) setSuccess(pasteSuccess);
  }, [pasteSuccess]);

  useEffect(() => {
    if (pasteError) setError(pasteError);
  }, [pasteError]);

  /**
   * Handle to close modal and reset variables
   */
  const handleClose = () => {
    setSuccess(false);
    setError(null);
    onClose();
  };

  /**
   * Container with success message display
   */
  const successCopyContainer = (
    <div className="modal-container">
      <CheckCircleOutlinedIcon className={classes.succesIcon} />
      <p className="modal-form__success">{texts.copyPasteModal.success}</p>
      {program?.name !== undefined ? (
        <p className="modal-form__success">{`${program?.code} - ${program?.name}`}</p>
      ) : (
        <p className="modal-form__success">{`${program?.code}`}</p>
      )}
      <Button
        id="buttonSeeProgram"
        variant="contained"
        className={classes.button}
        onClick={() => {
          handleClose();
          history.push(
            `${ROUTES_CONSTANTS.mediaSummaryBase}${program?.programRef}`
          );
        }}
      >
        {texts.copyPasteModal.successButton}
      </Button>
    </div>
  );

  /**
   * Container with error message display
   */
  const errorCopyContainer = (
    <div className="modal-container">
      <ErrorOutlineIcon className={classes.errorIcon} />
      <p className="modal-form__success">
        {error?.response?.data?.errors
          ? error?.response?.data?.errors[0]?.description
          : error?.message}
      </p>
      <Button
        id="buttonReturn"
        variant="contained"
        className={classes.button}
        onClick={() => {
          handleClose();
        }}
      >
        {texts.copyPasteModal.return}
      </Button>
    </div>
  );

  /**
   * Container with Program list tabs, it is the left column
   */
  const processCopyContainer = (
    <div className="tabs-container">
      <TabCopyPaste sections={sections} media={media} />
    </div>
  );

  /**
   * Principal body container to include in modal component
   */
  const body = (
    <div className="modal-form">
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} className={classes.titleRow}>
          <IconButton
            className={classes.iconButton}
            onClick={() => handleClose()}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
          <h2 className="modal-form__title">{texts.copyPasteModal.title}</h2>
        </Grid>
        <Grid item xs={12} className={classes.contentRow}>
          {pasteLoading && <CircularProgress className={classes.loader} />}
          {error && errorCopyContainer}
          {success && successCopyContainer}
          {!error && !success && !pasteLoading && processCopyContainer}
        </Grid>
      </Grid>
    </div>
  );
  return <ModalComponent open={open} body={body} onClose={handleClose} />;
};

CopyPasteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  sectionResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  pasteSuccess: PropTypes.bool,
  history: PropTypes.func,
  media: PropTypes.oneOfType([PropTypes.object]),
  program: PropTypes.oneOfType([PropTypes.object]),
  pasteError: PropTypes.oneOfType([PropTypes.object]),
  pasteLoading: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  sectionResults: selectSectionsResult,
  sectionIsLoading: selectSectionsLoading,
  sectionError: selectSectionsError,
  pasteSuccess: selectPasteMediaSucceed,
  program: selectPasteProgram,
  pasteError: selectPasteMediaError,
  pasteLoading: selectPasteMediaIsLoading
});

export default connect(mapStateToProps, null)(CopyPasteModal);
