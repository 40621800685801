import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import VisualFolderActionTypes from './visual-folder.types';
import { visualFolderBuilder } from './visual-folder.builder';
import api from '../../api/api.jsx';
import {
  fetchVisualFolderSuccess,
  fetchVisualFolderFailure,
  fetchVisualFolderFinish
} from './visual-folder.actions';

export const selectCreateProgram = state => state.createProgram;
export const selectFolderFile = state => state.fileFolderUploader;
export const selectFile = state => state.fileUploader;

export function* fetchVisualFolder({ payload: params }) {
  let payloadFiles = null;
  const folderFile = yield select(selectFolderFile);
  const programFile = yield select(selectFile);
  if (
    folderFile?.payloadFiles !== undefined &&
    folderFile?.payloadFiles[0]?.file?.fileUrl !== undefined
  ) {
    payloadFiles = folderFile?.payloadFiles;
  } else {
    payloadFiles = programFile?.payloadFiles;
  }

  try {
    const { data } = yield call(api.getVisualFolder, params);
    const { communes } = yield select(selectCreateProgram);
    const dataBuilder = yield call(
      visualFolderBuilder,
      data,
      communes,
      payloadFiles
    );
    yield put(fetchVisualFolderSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchVisualFolderFailure(error));
  }
  yield put(fetchVisualFolderFinish());
}

export function* onFetchVisualFolderStart() {
  yield takeLatest(
    VisualFolderActionTypes.FETCH_VISUAL_FOLDER_START,
    fetchVisualFolder
  );
}

export function* visualFolderSagas() {
  yield all([call(onFetchVisualFolderStart)]);
}
