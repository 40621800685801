export default {
  title: {
    fontSize: 14
  },
  subTitle: {
    fontSize: 10
  },
  button: {
    color: '#C82832',
    border: '1.5px solid #C82832',
    background: '#F5F6FA'
  },
  buttonText: {
    fontFamily: 'Neo Sans Pro',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    color: '#C82832'
  },
  card: {
    width: '257px',
    background: '#F5F6FA',
    border: '1px solid #DBE2F4',
    boxShadow: '0px 8px 24px rgba(219, 226, 244, 0.5)',
    boxSizing: 'border-box',
    marginRight: '10px',
    marginTop: '20px',
    height: '220px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardContent: {
    padding: '7px',
    '&:last-child': {
      padding: 8
    }
  },
  cardContentLoader: {
    height: '220px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignSelf: 'flex-end'
  },
  icon: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  playIconGallery: {
    height: '5em',
    width: '5em',
    fill: 'white',
    zIndex: '1',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto'
  }
};
