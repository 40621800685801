import DeleteSectionTypes from './deleteSectionTypes';

const INITIAL_STATE = {
  deleted: false,
  error: null,
  isLoading: false
};

const deleteSectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeleteSectionTypes.DELETE_SECTION_START:
      return { ...state, isLoading: true, deleted: false };

    case DeleteSectionTypes.DELETE_SECTION_SUCCESS:
      return { ...state, deleted: true, error: null };

    case DeleteSectionTypes.DELETE_SECTION_FAILURE:
      return { ...state, error: action.payload, deleted: false };

    case DeleteSectionTypes.DELETE_SECTION_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default deleteSectionReducer;
