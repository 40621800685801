import { put, all, call, takeLatest } from 'redux-saga/effects';
import FileSectionUploaderTypes from './fileSectionUploaderTypes';
import api from '../../api/api.jsx';
import {
  addFilesSectionSuccess,
  addFilesSectionFinished,
  addFilesSectionFailure
} from './fileSectionUploaderActions';
import { createFilePayload } from '../../shared/functions/createFilesPayload';

function* addFile(file) {
  const convertedFile = createFilePayload(file);
  const result = yield call(api.postUploadMedia, {
    payload: {
      ...convertedFile,
      section: {
        listCode: file.section.listCode,
        level: file.section.level,
        refValue: file.section.refSection
      }
    }
  });

  return {
    url: result.headers['x-upload-content-location'],
    inputFile: file
  };
}

export function* addFiles({ payload: files }) {
  try {
    const promises = files.map(file => addFile(file));
    const data = yield all(promises);
    yield put(addFilesSectionSuccess(data));
  } catch (error) {
    yield put(addFilesSectionFailure(error));
  }
  yield put(addFilesSectionFinished());
}

export function* onAddFilesStart() {
  yield takeLatest(FileSectionUploaderTypes.ADD_FILES_SECTION_START, addFiles);
}

export function* addSectionFilesSagas() {
  yield all([call(onAddFilesStart)]);
}
