import { createSelector } from 'reselect';

const selectProgramList = state => state.programListCleo;

export const selectProgramListCleoResults = createSelector(
  [selectProgramList],
  programList => programList?.results?.program
);

export const selectProgramListCleoIsLoading = createSelector(
  [selectProgramList],
  programList => programList?.isLoading
);
