import EditSectionTypes from './editSectionTypes';

const INITIAL_STATE = {
  modified: false,
  error: null,
  isLoading: false,
  succeed: false
};

const editSectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EditSectionTypes.EDIT_SECTION_START:
      return { ...state, isLoading: true, modified: false, succeed: false };

    case EditSectionTypes.EDIT_SECTION_SUCCESS:
      return { ...state, modified: true, error: null, succeed: true };

    case EditSectionTypes.EDIT_SECTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        modified: false,
        succeed: false
      };

    case EditSectionTypes.EDIT_SECTION_FINISH:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default editSectionReducer;
