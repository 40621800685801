/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { LinearProgress } from '@material-ui/core';
import { fitBounds } from 'google-map-react';

import Map from './Map/Map.jsx';
import ButtonComponent from '../../components/button/buttonComponent.jsx';
import styles from './style.jsx';

import Filter from '../../components/filter/Filter';
import {
  SEARCH_TYPE,
  EMPTY_STRING,
  initial,
  facetsVitrine,
  coorInit,
  zoom,
  size
} from '../../constants/global-constants';
import Spinner from '../../components/spinner/Spinner.jsx';
import t from '../../constants/translates';
import './style.scss';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import {
  selectSearchWithGeopositionIsLoading,
  selectSearchWithGeopositionResults,
  selectSearchWithGeopositionResultsWithMedia,
  selectSearchWithGeopositionResultsWithoutMedia,
  selectSearchWithGeopositionWithMediaIsLoading
} from '../../redux/searchWithGeoposition/searchWithGeoposition.selectors';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import {
  fetchSearchWithGeopositionResultsStart,
  fetchSearchWithGeopositionToolbar,
  fetchSearchWithGeopositionResultsWithMediaStart,
  fetchSearchWithGeopositionResultsWithoutMediaStart
} from '../../redux/searchWithGeoposition/searchWithGeoposition.actions';
import { hasFilials } from '../../shared/rights/root-rights';
import facetsTranslates from '../../constants/facets-translates';
import { getThumbnailsStart } from '../../redux/thumbnails/thumbnailsActions.js';
import { selectedThumbnails } from '../../redux/thumbnails/thumbnailsSelectors.js';
import SearchList from '../../components/SearchList/SearchList';
import SearchListNoMedia from '../../components/SearchListNoMedia/SearchListNoMedia';
import { selectOrganizationalEntitysResult } from '../../redux/organizationalEntity/organizationalEntity.selectors.js';

const HomePageVitrine = ({
  fetchSearchResults,
  isLoading,
  currentResults,
  fetchToolbarVisible,
  fetchGetThumbnails,
  thumbnails,
  sections,
  history,
  resultsWithoutMedia,
  resultsWithMedia,
  fetchSearchResultsWithMedia,
  fetchSearchResultsWithoutMedia,
  isLoadingWithMedia,
  organizationalEntitysResult
}) => {
  const historyParams = useParams();
  const [showMyPrograms, setShowMyPrograms] = useState(hasFilials());
  const [params, setParams] = useState({
    type: SEARCH_TYPE.program
  });
  const [programs, setPrograms] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const [properties, setProperties] = useState(null);
  const [showMyProgramsNoMedia, setShowMyProgramsNoMedia] = useState(true);
  const style = makeStyles(styles)();
  const [pages, setPages] = useState({
    current: 1,
    total: 1,
    loading: false
  });
  const [programsToShow, setProgramsToShow] = useState([]);
  const [coordinates, setCoordinates] = useState();
  const [programsMapWithMedia, setProgramsMapWithMedia] = useState({});
  const [programsMapWithoutMedia, setProgramsMapWithoutMedia] = useState({});
  const [zoomNumber, setZoomNumber] = useState();
  const [propertiesChange, setPropertiesChange] = useState(false);
  const [filterState, setFilterState] = useState([]);
  const showMyProgramsNoMediaHandler = () => {
    setShowMyProgramsNoMedia(!showMyProgramsNoMedia);
  };

  const getFilialFilter = () => {
    const result = showMyPrograms;
    return result?.toString().toUpperCase();
  };

  useEffect(() => {
    const newParams = {
      ...params,
      text: historyParams.text,
      myPrograms: getFilialFilter(),
      organizationalEntitys: organizationalEntitysResult,
      cursor: initial,
      page: 1,
      size: 10000,
      withCoordinates: true
    };
    fetchSearchResults(newParams);
    fetchSearchResultsWithMedia(newParams);
    fetchSearchResultsWithoutMedia(newParams);
    setParams(newParams);

    fetchToolbarVisible({
      filterDisabled: true,
      text: historyParams.text || ''
    });
    const paramsToolbar = { isVisible: true };
    fetchToolbarVisible(paramsToolbar);
    return () =>
      fetchToolbarVisible({
        isVisible: false,
        filterDisabled: false,
        text: EMPTY_STRING,
        refFilter: t.homeBannerContainer.refAll
      });
  }, [historyParams.text, organizationalEntitysResult]);

  const getLoadings = () => {
    return isLoading;
  };

  const hasLocation = program => {
    if (
      program?.location?.lat !== undefined &&
      program?.location?.lng !== undefined
    ) {
      return true;
    }
    return false;
  };
  const isInBounds = (location, coordinatesValue) => {
    if (
      coordinatesValue?.nw?.lat >= location?.lat &&
      coordinatesValue?.se?.lat <= location?.lat &&
      coordinatesValue?.nw?.lng <= location?.lng &&
      coordinatesValue?.se?.lng >= location?.lng
    ) {
      return true;
    }
    return false;
  };
  const filterProgramsListByMap = (
    programsWithCoordinates,
    coordinatesValue
  ) => {
    let programsFiltered = {};
    programsFiltered = programsWithCoordinates?.filter(program => {
      return isInBounds(program?.location, coordinatesValue);
    });
    return programsFiltered;
  };

  const filterProgramsListByMapForMatch = (
    programsWithCoordinates,
    coordinatesValue
  ) => {
    let programsFiltered = {};
    programsFiltered = programsWithCoordinates?.filter(program => {
      return isInBounds(program?.match?.location, coordinatesValue);
    });
    return programsFiltered;
  };

  /**
    UseEffect for get all the programs and use his adress to consult the api geocoding for set the coordinates of each program
  */
  useEffect(() => {
    // Get of all programs
    if (
      resultsWithMedia?.results?.withMedia?.length > 0 ||
      resultsWithoutMedia?.results?.withoutMedia?.length > 0
    ) {
      let coordinatesValue;
      if (coordinates === undefined) {
        coordinatesValue = coorInit;
      } else {
        coordinatesValue = coordinates;
      }
      let zoomValue;
      if (zoomNumber === undefined) {
        zoomValue = zoom;
      } else {
        zoomValue = zoomNumber;
      }

      setProperties({ ...fitBounds(coordinatesValue, size), zoomValue });
      const programsWithMediaAndLocation = resultsWithMedia?.results?.withMedia?.filter(
        program => hasLocation(program.match)
      );
      const programsWithoutMediaAndLocation = resultsWithoutMedia?.results?.withoutMedia?.filter(
        program => hasLocation(program)
      );
      const totalProgramsWithLocation = programsWithMediaAndLocation
        ?.map(prog => prog?.match) // ?.map(prog => prog ?.match)
        ?.concat(programsWithoutMediaAndLocation);
      let allProgramsToRender = {};
      if (
        coordinatesValue !== null &&
        totalProgramsWithLocation !== undefined
      ) {
        allProgramsToRender = filterProgramsListByMap(
          totalProgramsWithLocation,
          coordinatesValue
        );
        setPrograms(allProgramsToRender);
        const programsWithMedia = filterProgramsListByMapForMatch(
          programsWithMediaAndLocation,
          coordinatesValue
        );
        setProgramsMapWithMedia(programsWithMedia);
        setProgramsMapWithoutMedia(
          filterProgramsListByMap(
            programsWithoutMediaAndLocation,
            coordinatesValue
          )
        );
        setPages({
          current: 1,
          total: Math.ceil(programsWithMedia.length / 20)
        });
        setProgramsToShow(programsWithMedia.slice(0, pages.current * 20));
      } else {
        setPrograms(totalProgramsWithLocation);
        allProgramsToRender = totalProgramsWithLocation;
        setPages({
          current: 1,
          total: currentResults?.page?.totalElements / 20
        });
        setProgramsToShow(
          allProgramsToRender?.results?.withMedia.slice(0, pages.current * 20)
        );
      }
    } else {
      setPrograms([]);
      setProgramsToShow([]);
      setProgramsMapWithMedia([]);
      setProgramsMapWithoutMedia([]);
    }
  }, [resultsWithoutMedia, resultsWithMedia, currentResults, propertiesChange]);

  useEffect(() => {
    if (historyParams.category) {
      fetchToolbarVisible({ text: EMPTY_STRING });
      setParams({
        type: SEARCH_TYPE.program,
        myPrograms: getFilialFilter()
      });
    }
  }, [sections, historyParams.category]);

  const handleFilterChanged = filterList => {
    setFilterState(filterList);
    const filtersString = [];
    let filterType = SEARCH_TYPE.program;
    if (filterList.length > 0) {
      filterList.forEach(filter => {
        // For the case of different filials selecteds with the same parent
        if (facetsVitrine.includes(filter?.name)) {
          filtersString.push(`"${filter?.name}":"${filter?.value}"`);

          if (
            facetsTranslates[filter.name]?.name ===
              facetsTranslates.category.name ||
            facetsTranslates[filter.name]?.name ===
              facetsTranslates.subcategory.name
          ) {
            filterType = SEARCH_TYPE.media;
          }
        }
      });
    }
    let newParams = {
      ...params,
      filters: filtersString,
      type: filterType
    };
    if (historyParams.text) {
      newParams = {
        ...newParams,
        text: historyParams.text
      };
    }

    delete newParams.cursor;
    setParams(newParams);
    fetchSearchResults(newParams);
    fetchSearchResultsWithMedia(newParams);
    fetchSearchResultsWithoutMedia(newParams);
  };

  const handleFilialChange = event => {
    const { checked } = event.target;
    const show = !checked;
    setShowMyPrograms(show);

    const newParams = {
      ...params,
      myPrograms: show.toString().toUpperCase()
    };

    delete newParams.cursor;
    setParams(newParams);
    fetchSearchResults(newParams);
    fetchSearchResultsWithMedia(newParams);
    fetchSearchResultsWithoutMedia(newParams);
  };

  const handleChange = (event, newTabSelected) => {
    setTabSelected(newTabSelected);
  };

  const fetchMoreData = () => {
    if (pages.current < pages.total) {
      setPages({
        ...pages,
        current: pages?.current + 1,
        loading: true
      });
    }
    setProgramsToShow(
      programsMapWithMedia?.slice(pages.current * 20, (pages.current + 1) * 20)
    );
  };
  useEffect(() => {
    const pageInfo = {
      ...pages,
      loading: false
    };
    setPages(pageInfo);
  }, [programsToShow]);
  const printLoader = () => {
    return (
      <div className="progress__container">
        <LinearProgress className={style.progress} />
      </div>
    );
  };

  const getCoordinates = childData => {
    setZoomNumber(childData.zoom);
    setCoordinates(childData.coor);
    setPropertiesChange(!propertiesChange);
  };

  const printShowMoreProgramsWithoutMedia = () => (
    <div className="icon-show-more">
      <ButtonComponent
        id="icon-show-more"
        onClick={showMyProgramsNoMediaHandler}
        className={style.buttonShowMore}
        classes={{ startIcon: style.startIcon }}
        startIcon={
          showMyProgramsNoMedia ? (
            <ExpandMoreIcon className={style.startIcon} />
          ) : (
            <ExpandLessIcon className={style.startIcon} />
          )
        }
      >
        {showMyProgramsNoMedia ? (
          <span>{t.programList.showMoreNoMediaPrograms}</span>
        ) : (
          <span>{t.programList.hideNoMediaPrograms}</span>
        )}
      </ButtonComponent>
    </div>
  );
  return (
    <>
      <Spinner className="spinner" isLoading={getLoadings()} />
      <DismissableAlert />
      <div className="page-header">
        <span className="title__text">{t.Vitrine.title}</span>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Filter
            filterTree={currentResults?.facets?.filter(element =>
              facetsVitrine.includes(element?.name)
            )}
            onFilterChanged={handleFilterChanged}
            showFilial={hasFilials()}
            onFilialChanged={e => handleFilialChange(e)}
            filialChecked={!showMyPrograms}
            history={history}
            isVitrine
            filterState={filterState}
          />
        </Grid>
        <Grid item xs={9}>
          <AppBar position="static" color="default">
            <Tabs
              value={tabSelected}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab id="map" label={t.Vitrine.map} />
              <Tab id="liste" label={t.Vitrine.resultList} />
            </Tabs>
          </AppBar>
          {tabSelected === 0 ? (
            <>
              <Map
                programs={programs}
                fetchGetThumbnails={fetchGetThumbnails}
                thumbnails={thumbnails}
                coordinatesCallback={getCoordinates}
                properties={properties}
              />
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="title__div">
                  <span className="title">
                    {t.formatString(
                      t.programListPage.titleProgramWithoutMedia,
                      {
                        myPrograms: showMyPrograms
                          ? t.programListPage.mine
                          : t.programListPage.all
                      }
                    )}
                  </span>
                  <span className="title__results">
                    {`(${programsMapWithoutMedia?.length || 0} ${
                      programsMapWithoutMedia?.length > 1
                        ? t.programListPage.results
                        : t.programListPage.result
                    })`}
                  </span>
                </div>
                {resultsWithoutMedia?.results?.withoutMedia?.length > 0 && (
                  <>
                    {resultsWithoutMedia?.results?.withoutMedia?.length > 2 &&
                    showMyProgramsNoMedia ? (
                      printShowMoreProgramsWithoutMedia()
                    ) : (
                      <>
                        {resultsWithoutMedia?.results?.withoutMedia?.length >
                          2 && printShowMoreProgramsWithoutMedia()}
                      </>
                    )}
                  </>
                )}

                {programsMapWithoutMedia?.length > 0 && (
                  <SearchListNoMedia
                    resultsWithoutMedia={programsMapWithoutMedia}
                    showMyProgramsNoMedia={showMyProgramsNoMedia}
                    section={undefined}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <div className="title__div">
                  <span className="title">
                    {t.formatString(t.programListPage.titleProgramWithMedia, {
                      myPrograms: showMyPrograms
                        ? t.programListPage.mine
                        : t.programListPage.all
                    })}
                  </span>
                  <span className="title__results">
                    {`(${programsMapWithMedia?.length || 0} ${
                      programsMapWithMedia?.length > 1
                        ? t.programListPage.results
                        : t.programListPage.result
                    })`}
                  </span>
                </div>
                {isLoadingWithMedia && printLoader()}
                <Grid container spacing={1}>
                  <SearchList
                    options={programsToShow}
                    fetchMoreData={fetchMoreData}
                    currentPage={pages?.current}
                    totalPages={Math.ceil(programsMapWithMedia?.length / 20)}
                    isLoading={isLoadingWithMedia || pages?.loading}
                    vitrine
                    fetchGetThumbnails={fetchGetThumbnails}
                    thumbnails={thumbnails}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectSearchWithGeopositionIsLoading,
  currentResults: selectSearchWithGeopositionResults,
  thumbnails: selectedThumbnails,
  sections: selectSectionsResult,
  resultsWithMedia: selectSearchWithGeopositionResultsWithMedia,
  resultsWithoutMedia: selectSearchWithGeopositionResultsWithoutMedia,
  isLoadingWithMedia: selectSearchWithGeopositionWithMediaIsLoading,
  organizationalEntitysResult: selectOrganizationalEntitysResult
});

const mapDispatchToProps = dispatch => ({
  fetchSearchResults: params =>
    dispatch(fetchSearchWithGeopositionResultsStart(params)),
  fetchToolbarVisible: params =>
    dispatch(fetchSearchWithGeopositionToolbar(params)),
  fetchGetThumbnails: thumbnails => dispatch(getThumbnailsStart(thumbnails)),
  fetchSearchResultsWithMedia: params =>
    dispatch(fetchSearchWithGeopositionResultsWithMediaStart(params)),
  fetchSearchResultsWithoutMedia: params =>
    dispatch(fetchSearchWithGeopositionResultsWithoutMediaStart(params))
});

HomePageVitrine.propTypes = {
  isLoading: PropTypes.bool,
  currentResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchToolbarVisible: PropTypes.func,
  fetchSearchResults: PropTypes.func,
  fetchGetThumbnails: PropTypes.func,
  thumbnails: PropTypes.oneOfType([PropTypes.string]),
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  history: PropTypes.func,
  resultsWithMedia: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  resultsWithoutMedia: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  isLoadingWithMedia: PropTypes.bool,
  fetchSearchResultsWithMedia: PropTypes.func,
  fetchSearchResultsWithoutMedia: PropTypes.func,
  organizationalEntitysResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  )
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePageVitrine)
);
