import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useParams, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import MediaList from '../../components/mediaList/mediaList';
import Filter from '../../components/filter/Filter';
import text from '../../constants/translates';
import {
  alertDuration,
  SEARCH_TYPE,
  EMPTY_STRING,
  facets
} from '../../constants/global-constants';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import Spinner from '../../components/spinner/Spinner.jsx';
import './style.scss';
import styles from './style.jsx';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import SnackbarAlert from '../../components/snackBar/SnackbarAlert';
import {
  selectSearchResults,
  selectSearchIsLoading
} from '../../redux/search/search.selectors';
import {
  fetchSearchToolbar,
  fetchSearchResultsStart
} from '../../redux/search/search.actions';
import { canEdit } from '../../shared/rights/root-rights';
import { ATTRIBUTES } from '../../shared/rights/roles';

/**
 * Component for show the list of programs
 */
const programListByTheme = ({
  match,
  history,
  fetchSearchResults,
  isLoading,
  currentResults,
  fetchToolbarVisible,
  sections
}) => {
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState();
  const [pages, setPages] = useState({
    current: 1,
    total: 1,
    loading: false
  });
  const [params, setParams] = useState({});
  const classes = makeStyles(styles)();
  const message = `${
    match?.params?.items > 1
      ? `${match?.params.items} ${text.programSheet.alertMessagePlural}`
      : `${match?.params.items} ${text.programSheet.alertMessage}`
  }`;

  const duration = alertDuration;
  const { category, searchText } = useParams();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const paramsTool = { isVisible: true };
    fetchToolbarVisible(paramsTool);
    return () =>
      fetchToolbarVisible({
        isVisible: false,
        filterDisabled: false,
        text: EMPTY_STRING,
        refFilter: text.homeBannerContainer.refAll
      });
  }, []);

  useEffect(() => {
    setSection(sections.find(sec => sec.refSection === category));
    setParams({
      type: SEARCH_TYPE.media
    });
    if (category) {
      fetchToolbarVisible({ text: EMPTY_STRING });
    }
  }, [sections, category]);

  useEffect(() => {
    const pageInfo = {
      ...pages,
      total: currentResults?.page?.totalPages,
      loading: false
    };
    setPages(pageInfo);
  }, [currentResults]);

  useEffect(() => {
    if (match?.path === ROUTES_CONSTANTS.categoriesAdded) {
      setOpen(true);
    }

    if (section) {
      let newParams = {
        ...params,
        section: section?.refSection
      };

      if (searchText) {
        newParams = {
          ...newParams,
          text: searchText
        };
      }
      setPages({ current: 1, total: 1 });
      setParams(newParams);
      fetchSearchResults(newParams);
      fetchToolbarVisible({
        refFilter: section?.refSection,
        filterDisabled: true,
        text: searchText || ''
      });
    }
  }, [section, searchText]);

  const fetchMoreData = () => {
    const newParams = {
      ...params,
      cursor: currentResults?.page?.nextCursor
    };

    setPages({ ...pages, current: pages.current + 1, loading: true });

    fetchSearchResults(newParams);
    setParams(newParams);
  };

  const handleFilterChanged = filterList => {
    const filtersString = [];
    if (filterList.length > 0) {
      filterList.forEach((filter, index) => {
        filtersString[index] = `"${filter?.name}":"${filter?.value}"`;
      });
    }
    let newParams = {
      ...params,
      filters: filtersString
    };

    if (searchText) {
      newParams = {
        ...newParams,
        text: searchText
      };
    }
    delete newParams.cursor;
    setPages({ current: 1, total: 1 });
    setParams(newParams);
    fetchSearchResults({ ...newParams, section: section?.refSection });
  };

  return (
    <>
      <Spinner isLoading={isLoading && pages.current === 1} />
      <DismissableAlert />
      <div className="programSheetContainer">
        <SnackbarAlert
          id="AddedAlert"
          open={open}
          message={message}
          handleClose={handleClose}
          duration={duration}
        />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Filter
              filterTree={currentResults?.facets?.filter(element =>
                facets.includes(element?.name)
              )}
              onFilterChanged={handleFilterChanged}
              section={category}
              history={history}
            />
          </Grid>
          <Grid container item xs={9} className={classes.gridContainer}>
            {canEdit(true, ATTRIBUTES.BME_MEDIA_WRITE) && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.addMediaButton}
                onClick={() =>
                  history.push(
                    `${ROUTES_CONSTANTS.filesThematiqueUploaderBase}${category}`
                  )
                }
              >
                <b className="button__text--padding">
                  {text.programSheet.programAd}
                </b>
              </Button>
            )}
            <Grid container spacing={1}>
              <MediaList
                mediaList={currentResults?.results?.withMedia}
                fetchMoreData={fetchMoreData}
                currentPage={pages?.current}
                totalPages={currentResults?.page?.totalPages}
                isLoading={isLoading}
                category={category}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectSearchIsLoading,
  currentResults: selectSearchResults,
  sections: selectSectionsResult
});

const mapDispatchToProps = dispatch => ({
  fetchToolbarVisible: params => dispatch(fetchSearchToolbar(params)),
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params))
});

programListByTheme.propTypes = {
  match: PropTypes.string,
  isLoading: PropTypes.bool,
  history: PropTypes.func,
  fetchSearchResults: PropTypes.func,
  currentResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchToolbarVisible: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(programListByTheme)
);
