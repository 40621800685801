import {
  NOM_INFO_TYPE,
  THEME_NOM_TYPE,
  CARACTERS
} from '../../constants/global-constants';
import { getNowDate } from './date';

// Function to modify all the accents
export const removeAccents = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const normalizeValueCode = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    ?.replace(new RegExp(CARACTERS.SPACE, 'g'), CARACTERS.UNDERSCORE)
    ?.toUpperCase();
};

/**
 * Function to get creation section form
 * @param {String} sectionName Name
 * @param {Number} order Position
 */
export const createSectionForm = (sectionName, order) => {
  return {
    additionnalInformation: [
      {
        informationCode: NOM_INFO_TYPE.TYPE_SECTION,
        value: THEME_NOM_TYPE,
        startValidityDate: getNowDate(new Date())
      },
      {
        informationCode: NOM_INFO_TYPE.DELETEABLE,
        value: true,
        startValidityDate: getNowDate(new Date())
      }
    ],
    startValidityDate: getNowDate(new Date()),
    order,
    longLabel: sectionName,
    shortLabel: sectionName,
    valueCode: removeAccents(sectionName)
      ?.replace(new RegExp(CARACTERS.SPACE, 'g'), CARACTERS.UNDERSCORE)
      ?.toUpperCase()
  };
};

/**
 * Get edit section form
 * @param {String} sectionName Name
 */
export const editSectionForm = (sectionName, section) => {
  return {
    longLabel: sectionName,
    shortLabel: sectionName,
    additionnalInformation: section?.additionnalInformation,
    order: section?.order
  };
};
