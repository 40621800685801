import { all, call } from 'redux-saga/effects';
import { searchResultsSagas } from './search/search.sagas';
import { searchWithGeopositionResultsSagas } from './searchWithGeoposition/searchWithGeoposition.sagas';
import { searchCopyResultsSagas } from './searchCopy/searchCopy.sagas';
import { ProgramListSagas } from './programList/program.list.sagas';
import { addFilesSagas } from './file-uploader/fileUploaderSagas';
import { addSectionFilesSagas } from './file-section-uploader/fileSectionUploaderSagas';
import { downloadSagas } from './download/download.sagas';
import { categorizationSagas } from './categorisation/categorization.sagas';
import { categorySagas } from './category/category.sagas';
import { visualProgramSagas } from './visual-program/visual-program.sagas';
import { visualFolderSagas } from './visual-folder/visual-folder.sagas';
import { folderProgramSagas } from './folder-program/folder-program.sagas';
import { FolderDetailsSagas } from './folder-details/folder-details.sagas';
import { ProgramDetailsSagas } from './program-details/program-details.sagas';
import { MediaDetailsSagas } from './media-details/media-details.sagas';
import { modificationSagas } from './media-modification/media-modification.sagas';
import { deleteSagas } from './media-delete/media-delete.sagas';
import { ThemeListSagas } from './list.theme/list.theme.sagas';
import { s3CallerSagas } from './s3-caller/s3-caller.sagas';
import { createProgramSagas } from './createProgram/createProgramSagas';
import { sectionSagas } from './section/sections/section.sagas';
import { lastMediasSagas } from './lastMedias/lastMedias.sagas';
import { reorganizeMediasSagas } from './reorganize/reorganize.sagas';
import { CopyProgramListSagas } from './copy-program-list/copy.program.list.sagas';
import { PasteMediaSagas } from './paste.media/paste.media.sagas';
import { createFolderSagas } from './createFolder/createFolderSagas';
import { addFolderFilesSagas } from './file-folder-uploader/fileFolderUploaderSagas';
import { updateProgramNameSagas } from './programModification/programModificationSagas';
import { createTagSagas } from './createTag/create.tag.sagas';
import { tagListSagas } from './tag-list/tag.list.sagas';
import { categoryAdminSagas } from './categoryAdmin/categoryAdmin.sagas';
import { createSectionSagas } from './section/createSection/createSectionSagas';
import { LoginSagas } from './login/loginSagas';
import { deleteSectionSagas } from './section/deleteSection/deleteSectionSagas';
import { editSectionSagas } from './section/editSection/editSectionSagas';
import { ProgramListCleoSagas } from './cleo/programListCleo/programListCleoSagas';
import { legalEntitySagas } from './legalEntity/legalEntity.sagas';
import { postLegalEntitySagas } from './postLegalEntity/postLegalEntity.sagas';
import { getThumbnailsSagas } from './thumbnails/thumbnailsSagas';
import { organizationalEntitySagas } from './organizationalEntity/organizationalEntity.sagas';
import { publishUnpublishSagas } from './publishAndUnpublishMedias/publishAndUnpublishMedias.sagas';
import { createPartnerSagas } from './createPartner/createPartnerSagas';
import { naturalPersonsSagas } from './naturalPersons/naturalPersons.sagas';
import { PartnerDeleteSagas } from './partner-delete/partner-delete.sagas';
import { PartnerDetailsSagas } from './partner-details/partner-details.sagas';
import { PartnerLegalEntitySagas } from './partner-legalEntity/partner-legalEntity.sagas';
import { PartnerListSagas } from './list.partner/list.partner.sagas';
import { modificationPartnerSagas } from './partner-modification/partner-modification.sagas';
import { shareMediaSagas } from './share/share.sagas';
import { userInfoSagas } from './user-info/user-info.sagas';
import { setModeSagas } from './modeController/modeController.sagas';
import { faqSagas } from './faq/faq.sagas';
import { putFaqSagas } from './putFaq/putFaqSagas';
import { conditionsSagas } from './conditions/conditions.sagas';
import { putConditionsSagas } from './putConditions/putConditionsSagas';
import { formatSagas } from './formats/format.sagas';
import { subformatSagas } from './subformats/subformat.sagas';
import { mediaOriginalSagas } from './media-original/media-original.sagas';
import { fetchURLMediaWithCopySagas } from './urlInstances/urlInstances.sagas';
import { SocietyQualitiesSagas } from './society-qualities/society-qualities.sagas';
import { ProgramExternalInfosSagas } from './programExternalInfo/programExternalInfo.sagas';

export default function* rootSaga() {
  yield all([
    call(searchResultsSagas),
    call(searchWithGeopositionResultsSagas),
    call(searchCopyResultsSagas),
    call(downloadSagas),
    call(addFilesSagas),
    call(addSectionFilesSagas),
    call(addFolderFilesSagas),
    call(categorizationSagas),
    call(categorySagas),
    call(ProgramListSagas),
    call(visualProgramSagas),
    call(folderProgramSagas),
    call(FolderDetailsSagas),
    call(visualFolderSagas),
    call(ProgramDetailsSagas),
    call(MediaDetailsSagas),
    call(modificationSagas),
    call(deleteSagas),
    call(ThemeListSagas),
    call(s3CallerSagas),
    call(createProgramSagas),
    call(sectionSagas),
    call(lastMediasSagas),
    call(reorganizeMediasSagas),
    call(CopyProgramListSagas),
    call(PasteMediaSagas),
    call(createFolderSagas),
    call(updateProgramNameSagas),
    call(createTagSagas),
    call(tagListSagas),
    call(categoryAdminSagas),
    call(createSectionSagas),
    call(LoginSagas),
    call(deleteSectionSagas),
    call(editSectionSagas),
    call(ProgramListCleoSagas),
    call(legalEntitySagas),
    call(postLegalEntitySagas),
    call(organizationalEntitySagas),
    call(createPartnerSagas),
    call(naturalPersonsSagas),
    call(PartnerDeleteSagas),
    call(PartnerDetailsSagas),
    call(PartnerLegalEntitySagas),
    call(PartnerListSagas),
    call(modificationPartnerSagas),
    call(getThumbnailsSagas),
    call(shareMediaSagas),
    call(publishUnpublishSagas),
    call(userInfoSagas),
    call(setModeSagas),
    call(faqSagas),
    call(putFaqSagas),
    call(conditionsSagas),
    call(putConditionsSagas),
    call(formatSagas),
    call(subformatSagas),
    call(mediaOriginalSagas),
    call(fetchURLMediaWithCopySagas),
    call(SocietyQualitiesSagas),
    call(ProgramExternalInfosSagas)
  ]);
}
