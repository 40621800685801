import { createSelector } from 'reselect';

const login = state => state.login;

export const selectLogin = createSelector(
  [login],
  loginDetails => loginDetails.login
);

export const selectUser = createSelector(
  [login],
  loginDetails => loginDetails.login.user
);
