export default {
  gridBorderFather: {
    borderStyle: 'solid',
    borderWidth: '0.5px',
    backgroundColor: 'white',
    height: 'inherit'
  },
  gridBorder: {
    borderStyle: 'solid',
    borderWidth: '0.5px',
    backgroundColor: 'white',
    height: '100%'
  },
  tabs: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    fontFamily: 'Avenir Next LT Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    height: '100%'
  },
  indicator: {
    background: 'transparent'
  },
  tab: {
    fontSize: 16,
    '&:hover': {
      background: '#4E5360',
      color: '#F5F6FA'
    }
  },
  formControl: {
    width: '98%',
    margin: 5
  },
  labelWidth: 70,
  icon: {
    display: 'flex',
    alignItems: 'flex-end',
    marginRight: '7px'
  },
  iconFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    width: '80%',
    background: '#C82832',
    borderRadius: 10
  },
  gridSearchRow: {
    height: '8%'
  },
  gridProgramsRow: {
    height: '92%',
    marginTop: '34px'
  },
  closeButton: {
    color: '#000000',
    marginLeft: '98%',
    fontSize: '25px'
  },
  textAlign__left: {
    textAlign: 'left'
  }
};
