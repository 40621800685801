import { put, all, call, takeLatest } from 'redux-saga/effects';
import SectionActionTypes from './section.types';
import api from '../../../api/api.jsx';
import { sectionBuilder } from './section.builder';
import apiNomenclature from '../../../api/apiNomenclature';
import {
  fetchSectionSuccess,
  fetchSectionFailure,
  fetchSectionFinished,
  getSectionThumbnailLinkSuccess,
  getSectionThumbnailLinkFailure,
  getSectionThumbnailLinkFinished
} from './section.actions';

export function* fetchSection({ payload: params }) {
  try {
    const { data } = yield call(apiNomenclature.getListOfValues, params);
    const dataBuilder = yield call(sectionBuilder, data);
    yield put(fetchSectionSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSectionFailure(error));
  }
  yield put(fetchSectionFinished());
}

export function* getSectionThumbnailLink({ payload: params }) {
  try {
    const { data } = yield call(api.thumbnailSection, params);
    yield put(getSectionThumbnailLinkSuccess(data));
  } catch (error) {
    yield put(getSectionThumbnailLinkFailure(error.response?.data));
  }
  yield put(getSectionThumbnailLinkFinished());
}

export function* onFetchSectionStart() {
  yield takeLatest(SectionActionTypes.FETCH_SECTION_START, fetchSection);
}

export function* onGetSectionThumbnailLink() {
  yield takeLatest(
    SectionActionTypes.GET_SECTION_THUMBNAIL_LINK_START,
    getSectionThumbnailLink
  );
}

export function* sectionSagas() {
  yield all([call(onFetchSectionStart), call(onGetSectionThumbnailLink)]);
}
