import { createSelector } from 'reselect';

const selectFormats = state => state.formats;

export const selectFormatsResult = createSelector(
  [selectFormats],
  formats => formats
);

export const selectFormatsCount = createSelector(
  [selectFormats],
  formats => formats?.length
);

export const selectFormatsLoading = createSelector(
  [selectFormats],
  formats => formats.isLoading
);

export const selectFormatsError = createSelector(
  [selectFormats],
  formats => formats.error
);
