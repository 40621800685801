import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import { acceptedFormats } from '../../constants/global-constants';
import styles from './style';

const cardHeigth = '220';

const imageCard = ({ src, name, typeMedia, height = cardHeigth, loading }) => {
  const classes = makeStyles(styles)();
  return (
    <Card className={classes.card}>
      <CardActionArea className={classes.cardButton}>
        {loading ? (
          <CardContent className={classes.cardContentLoader}>
            <CircularProgress />
          </CardContent>
        ) : (
          <>
            {typeMedia?.refValue?.toLowerCase() === acceptedFormats.video && (
              <PlayCircleOutlineOutlinedIcon
                className={classes.playIconGallery}
                id="playVideo"
              />
            )}
            <CardMedia
              crossOrigin="anonymous"
              component="img"
              alt=""
              height={height}
              image={src}
              title={name}
            />
          </>
        )}
      </CardActionArea>
    </Card>
  );
};

imageCard.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  typeMedia: PropTypes.string,
  height: PropTypes.string,
  loading: PropTypes.bool
};

export default imageCard;
