import ReorganizeMedias from './reorganize.types.js';

export const reorganizeMediasStart = medias => ({
  type: ReorganizeMedias.REORGANIZE_MEDIAS_START,
  payload: medias
});

export const reorganizeMediasSuccess = data => ({
  type: ReorganizeMedias.REORGANIZE_MEDIAS_SUCCESS,
  payload: data
});

export const reorganizeMediasFailure = error => ({
  type: ReorganizeMedias.REORGANIZE_MEDIAS_FAILURE,
  payload: error
});

export const reorganizeMediasFinished = () => ({
  type: ReorganizeMedias.REORGANIZE_MEDIAS_FINISHED
});
