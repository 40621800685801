/* eslint-disable */
import { imageTypes, videoTypes } from '../../constants/global-constants';

/**
 * Gets the file type ( image or video)
 * @param {string} type - Media type
 */
const getFileType = type => {
  if (imageTypes.find(e => e === type)) {
    return 'image';
  }

  if (videoTypes.find(e => e === type)) {
    return 'video';
  }

  return 'document';
};

/**
 * Gets the file type ( image or video)
 * @param {string} type - Media type
 */
const getFileFormat = type => {
  return type?.split('.')[type?.split('.').length - 1];
};

/**
 * Create file payload
 * @param {Object} form - File form
 */
export function createFilePayload(form) {
  const file = form.file;

  return {
    name: file.name,
    type: {
      listCode: 'FORMAT_MEDIA_2',
      level: '1',
      refValue: getFileType(file.type).toUpperCase()
    },
    contentType: file.type,
    format: getFileFormat(file.name).toUpperCase(),
    size: file.size,
    width: form.width,
    height: form.height
  };
}

/**
 * Create file payload
 * @param {Object} form - File form
 */
export function createFileUploadToAPI(file) {
  let payload = new FormData();
  payload.append('file', file);
  return payload;
}
