export const snackBarSeverity = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info'
};

export const colorType = {
  primary: 'primary',
  secondary: 'secondary'
};

export const buttonVariant = {
  outlined: 'outlined'
};

export const imageWidth = '594';

export const vitrineBigScreen = '5';

export const vitrineSmallScreen = '12';

export const vitrineMediumScreen = '7';

export const maxLengthComment = 2000;
