import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { setModeStart } from '../../redux/modeController/modeController.actions';
import { MODE_PARAMS } from '../../constants/global-constants';
import { fetchMediaDetailsStart } from '../../redux/media-details/media-details.actions';
import { selectMediaDetailsIsLoading } from '../../redux/media-details/media-details.selectors';
import Spinner from '../../components/spinner/Spinner';

const ModeController = ({
  setMode,
  history,
  location,
  fetchMediaDetails,
  detailsLoading
}) => {
  const params = useParams();

  useEffect(() => {
    const callback = new URLSearchParams(location?.search).get(
      MODE_PARAMS.callback
    );
    const programCode = new URLSearchParams(location?.search).get(
      MODE_PARAMS.programCode
    );
    const selection = new URLSearchParams(location?.search).get(
      MODE_PARAMS.selection
    );

    setMode({
      modeParams: {
        mode: params.mode,
        callback,
        programCode,
        selection
      },
      history
    });

    // If the callback come with medias pre selected we get the details of medias to add to the mode selection
    if (callback?.length > 0 && selection?.length > 0) {
      const mediasSelecteds = selection.split(',');
      fetchMediaDetails(mediasSelecteds);
    }
  }, []);

  if (detailsLoading) {
    return <Spinner className="spinner" isLoading={detailsLoading} />;
  }
  return <></>;
};

ModeController.propTypes = {
  setMode: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  fetchMediaDetails: PropTypes.func,
  detailsLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
  setMode: params => dispatch(setModeStart(params)),
  fetchMediaDetails: refMedia => dispatch(fetchMediaDetailsStart(refMedia))
});

const mapStateToProps = createStructuredSelector({
  detailsLoading: selectMediaDetailsIsLoading
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModeController)
);
