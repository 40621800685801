export default {
  card: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    height: '270px'
  },

  cartTitleCategoryImportant: {
    fontSize: '24px',
    textAlign: 'center',
    marginTop: '10px',
    margin: 'auto'
  },

  cardTitleCategoryNoImportant: {
    fontSize: '22px',
    textAlign: 'center',
    marginTop: '10px',
    margin: 'auto'
  },

  cardTitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#C82832'
  },

  subTitle: {
    fontSize: '16px',
    lineHeight: '20px'
  },

  description: {
    fontSize: '12px',
    lineHeight: '16px'
  },

  cardIconGroup: {
    zIndex: '1',
    position: 'relative',
    float: 'right',
    height: '0px',
    padding: '0'
  },

  cardIcon: {
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    margin: '3px',
    '&:hover': {
      color: 'blue'
    }
  },

  cardIconPadding: {
    padding: '2px'
  },

  cardActionArea: {
    height: '100%'
  }
};
