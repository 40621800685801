import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { createStructuredSelector } from 'reselect';
import InfiniteScroll from 'react-infinite-scroller';
import ProgramCard from '../programCard/ProgramCard.jsx';
import SearchMediaCard from '../searchMediaCard/SearchMediaCard';
import './style.scss';
import styles from './style.jsx';
import { groupArrayByScreenSize } from '../../shared/functions/dynamicDisplay';
import { copyCodeToClipboard } from '../../shared/fixtures/functions/keyboard';
import VisualProgram from '../visual-program/VisualProgram.jsx';
import VisualImageProgram from '../visual-image-program/VisualImageProgram';
import VisualVideoProgram from '../visual-video-program/VisualVideoProgram';
import { fetchMediaDetailsStart } from '../../redux/media-details/media-details.actions';
import {
  selectMediaDetails,
  selectMediaDetailsIsLoading
} from '../../redux/media-details/media-details.selectors';
import { selectedS3CallerResult } from '../../redux/s3-caller/s3-caller.selectors';
import {
  infiniteScrollThreshold,
  SEARCH_TYPES,
  MEDIA_TYPE
} from '../../constants/global-constants';
import warningLogo from '../../assets/images/warningLogo.png';

const SearchList = ({
  options,
  fetchMoreData,
  totalPages,
  isLoading,
  history,
  mediaDetails,
  fetchMediaDetails,
  detailsLoading,
  currentPage,
  s3CallerResult,
  vitrine,
  fetchGetThumbnails,
  thumbnails
}) => {
  const style = makeStyles(styles)();
  const [items, setItems] = useState([]);
  const [visualProgramVisibility, setVisualProgramVisibility] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [hasMore, setHasMore] = useState(false);
  const programs = [];

  useEffect(() => {
    if (currentPage === 1) {
      setItems(options);
    } else {
      setItems(items.concat(options));
    }
    setHasMore(currentPage < totalPages);
    setVisualProgramVisibility([]);

    // We get the vitrine with coordinate programs and the get the thumbnails
    if (vitrine) {
      for (let i = 0; i < options?.length; i += 1) {
        programs.push(options[i].match);
      }
      fetchGetThumbnails(programs);
    }
  }, [options]);

  const getThumbnailFromList = media => {
    // For the case that we have changed now the media
    if (media?.programRef === s3CallerResult?.refProgram) {
      return s3CallerResult.s3LocalFileUrl;
    }

    if (media?.thumbnailS3 !== undefined) {
      return media?.thumbnailS3;
    }

    // For the case of vitrine marketing we get the thumbnail from the list of thumbnais passed like prop
    if (vitrine) {
      const thumb = thumbnails?.find(
        item => item.thumbnailApiUrl === media?.thumbnailUrl
      );
      if (thumb?.thumbnail !== undefined) {
        return thumb?.thumbnail;
      }
    }
    return warningLogo;
  };

  const next = () => {
    if (!isLoading) {
      fetchMoreData();
    }
  };

  useEffect(() => {
    if (selectedItem !== null || selectedItem !== undefined) {
      if (selectedItem?.type === SEARCH_TYPES.media) {
        fetchMediaDetails(selectedItem?.match?.refMedia);
      }
    }
  }, [selectedItem]);

  const updateVisualProgramVisibility = (index, programWithSrc, event) => {
    if (event) {
      window.scrollTo(0, event.currentTarget.offsetTop);
    }
    setSelectedItem(programWithSrc);
    const visibility = [];
    visibility[index] = true;
    setVisualProgramVisibility([...visibility]);
  };

  const printLoader = () => {
    return (
      <div className="progress__container">
        <LinearProgress className={style.progress} />
      </div>
    );
  };

  const getVisual = item => {
    if (item?.type === SEARCH_TYPES.program) {
      return (
        <VisualProgram
          updateVisualProgramVisibility={updateVisualProgramVisibility}
          selectedProgram={item?.match}
          history={history}
          vitrine={vitrine}
        />
      );
    }

    if (detailsLoading) {
      return printLoader();
    }

    if (item?.match?.typeMedia === MEDIA_TYPE.video) {
      return (
        <VisualVideoProgram
          video={mediaDetails}
          updateVisualProgramVisibility={updateVisualProgramVisibility}
          copyCodeToClipboard={copyCodeToClipboard}
          selectedSearchMedia={item?.match}
          vitrine={vitrine}
        />
      );
    }

    return (
      <>
        <VisualImageProgram
          image={mediaDetails}
          selectedSearchMedia={item?.match}
          updateVisualProgramVisibility={updateVisualProgramVisibility}
          vitrine={vitrine}
        />
      </>
    );
  };

  return (
    <div>
      {items?.length > 0 ? (
        <InfiniteScroll
          loadMore={next}
          hasMore={hasMore}
          loader={printLoader()}
          threshold={infiniteScrollThreshold}
          key={uuidv4()}
        >
          {groupArrayByScreenSize(items).map((group, index) => (
            <React.Fragment key={uuidv4()}>
              <div className="program-list" key={uuidv4()}>
                {group.map(result => {
                  return (
                    <div
                      role="button"
                      tabIndex="0"
                      key={uuidv4()}
                      onClick={e =>
                        updateVisualProgramVisibility(index, result, e)
                      }
                      onKeyPress={updateVisualProgramVisibility}
                    >
                      {result?.type === SEARCH_TYPES.program ? (
                        <>
                          <ProgramCard
                            {...result?.match}
                            thumbnail={getThumbnailFromList(result?.match)}
                          />
                        </>
                      ) : (
                        <SearchMediaCard
                          media={result?.match}
                          thumbnail={getThumbnailFromList(result?.match)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {visualProgramVisibility[index] && getVisual(selectedItem)}
            </React.Fragment>
          ))}
        </InfiniteScroll>
      ) : null}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  mediaDetails: selectMediaDetails,
  detailsLoading: selectMediaDetailsIsLoading,
  s3CallerResult: selectedS3CallerResult
});

const mapDispatchToProps = dispatch => ({
  fetchMediaDetails: refMedia => dispatch(fetchMediaDetailsStart(refMedia))
});

SearchList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  hasMore: PropTypes.bool,
  fetchMoreData: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  isLoading: PropTypes.bool,
  history: PropTypes.func,
  mediaDetails: PropTypes.oneOfType([PropTypes.string]),
  fetchMediaDetails: PropTypes.func,
  fetchGetThumbnails: PropTypes.func,
  detailsLoading: PropTypes.bool,
  thumbnails: PropTypes.oneOfType([PropTypes.string]),
  thumbnailsLoading: PropTypes.bool,
  s3CallerResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  vitrine: PropTypes.bool
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchList)
);
