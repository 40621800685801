import { createSelector } from 'reselect';

const selectCreateTag = state => state.createTag;

export const selectCreatedTag = createSelector(
  [selectCreateTag],
  createTag => createTag.results
);

export const selectCreatedTagLoading = createSelector(
  [selectCreateTag],
  createTag => createTag.isLoading
);

export const selectCreatedTagError = createSelector(
  [selectCreateTag],
  createTag => createTag.error
);
