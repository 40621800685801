import PasteMediaActionTypes from './paste.media.types';

const INITIAL_STATE = {
  results: null,
  error: null,
  isLoading: false,
  succeed: false,
  params: null
};

/**
 * Reducer of paste media to another program
 * @param {Object} state Current state
 * @param {Object} action Action dispatched
 */
const pasteMediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PasteMediaActionTypes.FETCH_PASTE_MEDIA_START:
      return {
        ...state,
        isLoading: true,
        succeed: false,
        params: action.payload,
        error: null
      };
    case PasteMediaActionTypes.FETCH_PASTE_MEDIA_SUCCESS:
      return { ...state, results: action.payload, error: null, succeed: true };
    case PasteMediaActionTypes.FETCH_PASTE_MEDIA_FAILURE:
      return { ...state, error: action.payload, succeed: false };
    case PasteMediaActionTypes.FETCH_PASTE_MEDIA_FINISHED:
      return { ...state, isLoading: false, succeed: false, error: null };
    default:
      return state;
  }
};

export default pasteMediaReducer;
