import { put, all, call, takeLatest } from 'redux-saga/effects';
import CreatePartner from './createPartnerTypes';
import api from '../../api/api.jsx';
import {
  sendCreatePartnerDataSuccess,
  sendCreatePartnerDataFailure,
  sendCreatePartnerDataFinished
} from './createPartnerActions';

export function* createPartner({ payload: form }) {
  try {
    const { data } = yield call(api.postCreatePartner, form);
    yield put(sendCreatePartnerDataSuccess(data));
  } catch (error) {
    yield put(sendCreatePartnerDataFailure(error));
  }
  yield put(sendCreatePartnerDataFinished());
}

export function* onCreatePartnerStart() {
  yield takeLatest(CreatePartner.SEND_CREATE_PARTNER_START, createPartner);
}

export function* createPartnerSagas() {
  yield all([call(onCreatePartnerStart)]);
}
