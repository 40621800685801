import partnerLegalEntityActionTypes from './partner-legalEntity.types';

export const fetchPartnerLegalEntityStart = text => ({
  type: partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_START,
  payload: text
});

export const fetchPartnerLegalEntitySuccess = data => ({
  type: partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_SUCCESS,
  payload: data
});

export const fetchPartnerLegalEntityFailure = error => ({
  type: partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_FAILURE,
  payload: error
});

export const fetchPartnerLegalEntityFinished = () => ({
  type: partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_FINISHED
});
