import { createSelector } from 'reselect';

const selectFaq = state => state.faqCms;

export const selectFaqsResults = createSelector(
  [selectFaq],
  faq => faq.faqResults
);

export const selectFaqsIsLoading = createSelector(
  [selectFaq],
  faq => faq.isLoading
);

export const selectFaqsError = createSelector([selectFaq], faq => faq.error);
