import EditSectionTypes from './editSectionTypes';

export const EditSectionStart = params => {
  return {
    type: EditSectionTypes.EDIT_SECTION_START,
    payload: params
  };
};

export const EditSectionSuccess = () => {
  return {
    type: EditSectionTypes.EDIT_SECTION_SUCCESS
  };
};

export const EditSectionFailure = error => {
  return {
    type: EditSectionTypes.EDIT_SECTION_FAILURE,
    payload: error
  };
};

export const EditSectionFinish = () => {
  return {
    type: EditSectionTypes.EDIT_SECTION_FINISH
  };
};
