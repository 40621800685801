import UrlInstanceTypes from './urlInstances.types.js';

export const fetchURLInstanceStart = media => ({
  type: UrlInstanceTypes.FETCH_URL_INSTANCE_START,
  payload: media
});

export const fetchURLInstanceSuccess = urlInstance => ({
  type: UrlInstanceTypes.FETCH_URL_INSTANCE_SUCCESS,
  payload: urlInstance
});

export const fetchURLInstanceFailure = error => ({
  type: UrlInstanceTypes.FETCH_URL_INSTANCE_FAILURE,
  payload: error
});

export const fetchURLInstanceFinished = () => ({
  type: UrlInstanceTypes.FETCH_URL_INSTANCE_FINISHED
});
