import { put, all, call, takeLatest } from 'redux-saga/effects';
import S3CallerTypes from './s3-caller.types';
import api from '../../api/api.jsx';
import {
  sendS3DataSuccess,
  sendS3DataFailure,
  sendS3DataFinished
} from './s3-caller.actions';

export function* sendS3Caller({ payload: params }) {
  try {
    yield call(api.putS3, params.url, params.file);
    yield put(sendS3DataSuccess(params));
  } catch (error) {
    yield put(sendS3DataFailure(error));
  }
  yield put(sendS3DataFinished());
}

export function* onSendS3Caller() {
  yield takeLatest(S3CallerTypes.SEND_S3_DATA_START, sendS3Caller);
}

export function* s3CallerSagas() {
  yield all([call(onSendS3Caller)]);
}
