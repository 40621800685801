import { put, all, call, takeLatest } from 'redux-saga/effects';
import SearchWithGeopositionActionTypes from './searchWithGeoposition.types';
import { searchWithGeopositionBuilder } from './searchWithGeoposition-builder';
import api from '../../api/api.jsx';
import {
  fetchSearchWithGeopositionResultsSuccess,
  fetchSearchWithGeopositionResultsFailure,
  fetchSearchWithGeopositionResultsFinished,
  fetchSearchWithGeopositionResultsWithMediaSuccess,
  fetchSearchWithGeopositionResultsWithMediaFailure,
  fetchSearchWithGeopositionResultsWithMediaFinished,
  fetchSearchWithGeopositionResultsWithoutMediaSuccess,
  fetchSearchWithGeopositionResultsWithoutMediaFailure,
  fetchSearchWithGeopositionResultsWithoutMediaFinished,
  fetchSearchWithGeopositionSuggestionsSuccess,
  fetchSearchWithGeopositionSuggestionsFinished,
  fetchSearchWithGeopositionSuggestionsFailure
} from './searchWithGeoposition.actions';
import { SEARCH_TYPE } from '../../constants/global-constants';

export function* fetchSearchWithGeopositionResults({ payload: params }) {
  try {
    const { data } = yield call(api.getSearchResultsWithCoordinates, params);
    const dataBuilder = yield call(
      searchWithGeopositionBuilder,
      data,
      params.organizationalEntitys
    );
    yield put(fetchSearchWithGeopositionResultsSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSearchWithGeopositionResultsFailure(error?.data));
  }
  yield put(fetchSearchWithGeopositionResultsFinished());
}

export function* onFetchSearchWithGeopositionResultsStart() {
  yield takeLatest(
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_START,
    fetchSearchWithGeopositionResults
  );
}

export function* fetchSearchWithGeopositionResultsWithMedia({
  payload: params
}) {
  try {
    let paramsWithMedia = {
      ...params
    };

    if (params?.type === SEARCH_TYPE.program) {
      paramsWithMedia = {
        ...paramsWithMedia,
        withMedia: 'TRUE'
      };
    }
    const { data } = yield call(api.getSearchResults, paramsWithMedia);
    const dataBuilder = yield call(
      searchWithGeopositionBuilder,
      data,
      params.organizationalEntitys
    );
    yield put(fetchSearchWithGeopositionResultsWithMediaSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSearchWithGeopositionResultsWithMediaFailure(error?.data));
  }
  yield put(fetchSearchWithGeopositionResultsWithMediaFinished());
}

export function* onFetchSearchWithGeopositionResultsWithMediaStart() {
  yield takeLatest(
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHMEDIA_START,
    fetchSearchWithGeopositionResultsWithMedia
  );
}

export function* fetchSearchWithGeopositionResultsWithoutMedia({
  payload: params
}) {
  try {
    let paramsWithoutMedia = {
      ...params,
      size: 2000
    };

    if (params?.type === SEARCH_TYPE.program) {
      paramsWithoutMedia = {
        ...paramsWithoutMedia,
        withMedia: 'FALSE'
      };
    }

    const { data } = yield call(api.getSearchResults, paramsWithoutMedia);

    const dataBuilder = yield call(
      searchWithGeopositionBuilder,
      data,
      params.organizationalEntitys
    );

    yield put(
      fetchSearchWithGeopositionResultsWithoutMediaSuccess(dataBuilder)
    );
  } catch (error) {
    yield put(
      fetchSearchWithGeopositionResultsWithoutMediaFailure(error?.data)
    );
  }
  yield put(fetchSearchWithGeopositionResultsWithoutMediaFinished());
}

export function* onFetchSearchWithGeopositionResultsWithoutMediaStart() {
  yield takeLatest(
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_RESULTS_WITHOUTMEDIA_START,
    fetchSearchWithGeopositionResultsWithoutMedia
  );
}

export function* fetchSearchWithGeopositionSuggests({ payload: params }) {
  try {
    const { data } = yield call(api.getSearchSuggestions, params);
    yield put(fetchSearchWithGeopositionSuggestionsSuccess(data?.results));
  } catch (error) {
    yield put(fetchSearchWithGeopositionSuggestionsFailure(error?.data));
  }
  yield put(fetchSearchWithGeopositionSuggestionsFinished());
}

export function* onFetchSearchWithGeopositionSuggestsStart() {
  yield takeLatest(
    SearchWithGeopositionActionTypes.FETCH_SEARCHWITHGEOPOSITION_SUGGESTIONS_START,
    fetchSearchWithGeopositionSuggests
  );
}

export function* searchWithGeopositionResultsSagas() {
  yield all([
    call(onFetchSearchWithGeopositionResultsStart),
    call(onFetchSearchWithGeopositionSuggestsStart),
    call(onFetchSearchWithGeopositionResultsWithMediaStart),
    call(onFetchSearchWithGeopositionResultsWithoutMediaStart)
  ]);
}
