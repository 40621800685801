/* eslint-disable */
import { createSelector } from 'reselect';
import { snackBarSeverity } from '../../constants/ui.constants';
import { mediaStatus } from '../../constants/media-status';
import text from '../../constants/translates';

const getState = state => state;

const getStateError = state => {
  for (const entity of Object.entries(state)) {
    if (entity[1].error != null) {
      if (entity[1].error.response != null) {
        return JSON.parse(entity[1].error.response.request.response);
      }

      return entity[1].error;
    }
  }

  return null;
};

export const stateErrors = createSelector([getState], state =>
  getStateError(state)
);

export const getErrorMediaStatus = error => {
  if (!error || undefined) {
    return null;
  }

  switch (error) {
    case mediaStatus.error:
    case mediaStatus.errorCodec:
    case mediaStatus.errorGed:
    case mediaStatus.errorQualitySize:
    case mediaStatus.errorResize:
    case mediaStatus.errorUploadMediaNotFound:
      return {
        text: text.mediaStatusError.transformationError,
        severity: snackBarSeverity.error
      };
    case mediaStatus.upload:
    case mediaStatus.gedOk:
    case mediaStatus.gedPending:
    case mediaStatus.transform:
      return {
        text: text.mediaStatusError.transformationEncours,
        severity: snackBarSeverity.info
      };
  }
};
