import PutFaq from './putFaqTypes';

const INITIAL_STATE = {
  message: null,
  error: null,
  isLoading: false
};

const putFaqReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PutFaq.SEND_PUT_FAQ_START:
      return { ...state, isLoading: true, succeed: false };

    case PutFaq.SEND_PUT_FAQ_SUCCESS:
      return { ...state, message: action.payload, error: null, succeed: true };

    case PutFaq.SEND_PUT_FAQ_FAILURE:
      return { ...state, error: action.payload.response.data, succeed: false };

    case PutFaq.SEND_PUT_FAQ_FINISHED:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default putFaqReducer;
