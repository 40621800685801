import ShareActionTypes from './share.types';

const INITIAL_STATE = {
  isLoading: false,
  results: null,
  error: null,
  internalMembersResult: [],
  isLoadingInternalMembers: false,
  externalMembersResult: [],
  isLoadingExternalMembers: false,
  shareError: null,
  appMember: null,
  isLoadingAppMember: false
};

const shareReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ShareActionTypes.SHARE_MEDIA_START:
      return { ...state, isLoading: true };
    case ShareActionTypes.SHARE_MEDIA_SUCCESS:
      return { ...state, shareError: null, results: 'ok' };
    case ShareActionTypes.SHARE_MEDIA_FAILURE:
      return {
        ...state,
        shareError: action.payload
      };
    case ShareActionTypes.FETCH_INTERNAL_MEMBERS_FAILURE:
    case ShareActionTypes.FETCH_EXTERNAL_MEMBERS_FAILURE:
    case ShareActionTypes.FETCH_APP_MEMBER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case ShareActionTypes.SHARE_MEDIA_FINISHED:
      return { ...state, isLoading: false, shareError: null, results: null };

    case ShareActionTypes.FETCH_INTERNAL_MEMBERS_START:
      return { ...state, isLoadingInternalMembers: true };
    case ShareActionTypes.FETCH_INTERNAL_MEMBERS_SUCCESS:
      return { ...state, error: null, internalMembersResult: action.payload };
    case ShareActionTypes.FETCH_INTERNAL_MEMBERS_FINISHED:
      return { ...state, isLoadingInternalMembers: false };

    case ShareActionTypes.FETCH_EXTERNAL_MEMBERS_START:
      return { ...state, isLoadingExternalMembers: true };
    case ShareActionTypes.FETCH_EXTERNAL_MEMBERS_SUCCESS:
      return { ...state, error: null, externalMembersResult: action.payload };
    case ShareActionTypes.FETCH_EXTERNAL_MEMBERS_FINISHED:
      return { ...state, isLoadingExternalMembers: false };

    case ShareActionTypes.FETCH_APP_MEMBER_START:
      return { ...state, isLoadingAppMember: true };
    case ShareActionTypes.FETCH_APP_MEMBER_SUCCESS:
      return { ...state, error: null, appMember: action.payload };
    case ShareActionTypes.FETCH_APP_MEMBER_FINISHED:
      return { ...state, isLoadingAppMember: false };
    default:
      return state;
  }
};

export default shareReducer;
