import { put, all, call, takeLatest } from 'redux-saga/effects';
import FileFolderUploaderTypes from './fileFolderUploaderTypes';
import api from '../../api/api.jsx';
import {
  addFilesFolderSuccess,
  addFilesFolderFinished,
  addFilesFolderFailure
} from './fileFolderUploaderActions';
import { createFilePayload } from '../../shared/functions/createFilesPayload';

function* addFile(file) {
  const convertedFile = createFilePayload(file);
  const result = yield call(api.postUploadMedia, {
    payload: {
      ...convertedFile,
      refFolder: file.refFolder
    }
  });
  return {
    url: result.headers['x-upload-content-location'],
    inputFile: file
  };
}

export function* addFiles({ payload: files }) {
  try {
    const promises = files.map(file => addFile(file));
    const data = yield all(promises);
    yield put(addFilesFolderSuccess(data));
  } catch (error) {
    yield put(addFilesFolderFailure(error));
  }
  yield put(addFilesFolderFinished());
}

export function* onAddFilesStart() {
  yield takeLatest(FileFolderUploaderTypes.ADD_FILES_FOLDER_START, addFiles);
}

export function* addFolderFilesSagas() {
  yield all([call(onAddFilesStart)]);
}
