import PublishUnpublishMedias from './publishAndUnpublishMedias.types.js';

const INITIAL_STATE = {
  response: null,
  error: null,
  isLoading: false
};

const PublishUnpublishMediasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_START:
    case PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_START:
      return { ...state, isLoading: true };

    case PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_SUCCESS:
    case PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_SUCCESS:
      return { ...state, response: action.payload, error: null };

    case PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_FAILURE:
    case PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_FAILURE:
      return { ...state, error: action.payload };

    case PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_FINISHED:
    case PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default PublishUnpublishMediasReducer;
