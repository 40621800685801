import { convertBytes } from '../functions/convertBytes';
import { tagListBuilder } from '../../redux/tag-list/tag.list.builder';
import { sectionItemBuilder } from '../../redux/section/sections/section.builder';
import { cityBuilder } from './cityBuilder';
import { instanceListBuilder } from './medias';
import { folderDetailsBuilder } from '../../redux/folder-details/folder-details.builder';

const categoryBuilder = itemCategory => {
  return {
    listCode: itemCategory?.category?.listCode,
    refCategory: itemCategory?.category?.refValue,
    level: itemCategory?.category?.level
  };
};

const subcategoryBuilder = itemSubcategory => {
  return {
    listCode: itemSubcategory?.subCategory?.listCode,
    refSubCategory: itemSubcategory?.subCategory?.refValue,
    level: itemSubcategory?.subCategory?.level
  };
};

const programBuilder = (itemProgram, communes) => {
  return {
    zipCode: itemProgram?.program?.cityCode,
    name: itemProgram?.program?.name,
    city: cityBuilder(itemProgram?.program?.cityCode, communes),
    code: itemProgram?.program?.code,
    refProgram: itemProgram?.program?.refProgram,
    address: itemProgram?.program?.address,
    section: sectionItemBuilder(itemProgram?.program?.section),
    thumbnail: itemProgram?.program?.thumbnail,
    filiale: itemProgram?.program?.refOrganizationalEntity,
    perimeter: itemProgram?.program?.perimeter
  };
};

const mediaItemBuilder = (media, communes) => {
  const folderBuilded = folderDetailsBuilder(media?.folder, communes);
  return {
    refMedia: media?.refMedia,
    name: media?.name,
    nameExt: media?.nameExt,
    program: programBuilder(media, communes),
    category: categoryBuilder(media),
    subCategory: subcategoryBuilder(media),
    section: sectionItemBuilder(media.section),
    typeMedia: media?.type,
    dateUpload: media?.createdDate,
    userUpload: media?.createdBy,
    dateUpdate: media?.updatedDate,
    userUpdate: media?.updatedBy,
    instances: instanceListBuilder(media?.files),
    copyright: media?.copyright,
    format: media?.format,
    resolution: `${media?.width} x ${media?.height}`,
    size: convertBytes(media?.size),
    mediaURL: media?.url || media?.thumbnail,
    original: media?.thumbnail || media?.url,
    type: media?.section?.type,
    support: media?.supports,
    durationOfUse: media?.expirationDate,
    tags: tagListBuilder(media?.tags),
    thumbnail: media?.thumbnail,
    status: media?.status,
    folder: folderBuilded,
    title: media?.title,
    comment: media?.description
  };
};

export function mediaBuilder(data, communes) {
  return mediaItemBuilder(data, communes);
}
