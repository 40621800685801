const projectTypeBuilder = data => {
  return {
    ...data,
    values: data?.level[0]
  };
};

export const createProgramProjectTypesBuilder = data => {
  return projectTypeBuilder(data);
};
