import OrganizationalEntityTypes from './organizationalEntity.types';

export const fetchOrganizationalEntityStart = params => ({
  type: OrganizationalEntityTypes.FETCH_ORGANIZATIONALENTITY_START,
  payload: params
});

export const fetchOrganizationalEntitySuccess = params => ({
  type: OrganizationalEntityTypes.FETCH_ORGANIZATIONALENTITY_SUCCESS,
  payload: params
});

export const fetchOrganizationalEntityFailure = error => ({
  type: OrganizationalEntityTypes.FETCH_ORGANIZATIONALENTITY_FAILURE,
  payload: error
});

export const fetchOrganizationalEntityFinished = () => ({
  type: OrganizationalEntityTypes.FETCH_ORGANIZATIONALENTITY_FINISHED
});

export const fetchCompanyDataStart = params => ({
  type: OrganizationalEntityTypes.FETCH_COMPANY_DATA_START,
  payload: params
});

export const fetchCompanyDataSuccess = params => ({
  type: OrganizationalEntityTypes.FETCH_COMPANY_DATA_SUCCESS,
  payload: params
});

export const fetchCompanyDataFailure = error => ({
  type: OrganizationalEntityTypes.FETCH_COMPANY_DATA_FAILURE,
  payload: error
});

export const fetchCompanyDataFinished = () => ({
  type: OrganizationalEntityTypes.FETCH_COMPANY_DATA_FINISHED
});
