import programExternalInfoActionTypes from './programExternalInfo.types';

export const getProgramExternalInfoStart = text => ({
  type: programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_START,
  payload: text
});

export const getProgramExternalInfoSuccess = data => ({
  type: programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_SUCCESS,
  payload: data
});

export const getProgramExternalInfoFailure = error => ({
  type: programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_FAILURE,
  payload: error
});

export const getProgramExternalInfoFinished = () => ({
  type: programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_FINISHED
});
