import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import dialogTransition from '../dialogTransition/dialogTransition';
import styles from './style.jsx';
import './style.scss';

const Transition = dialogTransition;

const AlertDialogSlide = ({
  open,
  title,
  content,
  handleAction,
  handleClose,
  textButtonOk,
  textButtonCancel
}) => {
  const classes = makeStyles(styles)();
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {content}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {textButtonOk?.length > 0 && (
            <Button
              onClick={handleAction}
              id="ButtonOK"
              variant="outlined"
              color="primary"
              className={classes.buttonOk}
            >
              <span className="button-dialog__text">{textButtonOk}</span>
            </Button>
          )}
          {textButtonCancel?.length > 0 && (
            <Button
              onClick={handleClose}
              id="ButtonKO"
              variant="outlined"
              className={classes.button}
            >
              <span className="button-KO__text">{textButtonCancel}</span>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialogSlide.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  textButtonOk: PropTypes.string,
  textButtonCancel: PropTypes.string,
  handleAction: PropTypes.func,
  handleClose: PropTypes.func
};

export default AlertDialogSlide;
