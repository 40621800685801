import { put, all, call, takeLatest } from 'redux-saga/effects';
import ShareActionTypes from './share.types';
import api from '../../api/api.jsx';
import {
  shareMediaSuccess,
  shareMediaFailure,
  shareMediaFinished,
  fetchInternalMembersSuccess,
  fetchInternalMembersFailure,
  fetchExternalMembersSuccess,
  fetchExternalMembersFailure,
  fetchAppMemberSuccess,
  fetchAppMemberFailure,
  fetchAppMemberFinished
} from './share.actions';
import apiIntervenants from '../../api/apiIntervenants';
import {
  externalUsersDataBuilder,
  internalUsersDataBuilder
} from '../../shared/builders/usersBuilder.js';

export function* shareMedia({ payload: params }) {
  try {
    const { data } = yield call(api.sendShareMedia, params);
    yield put(shareMediaSuccess(data));
  } catch (error) {
    yield put(shareMediaFailure(error));
  }
  yield put(shareMediaFinished());
}

export function* fetchInternalMembers({ payload: params }) {
  try {
    const { data } = yield call(apiIntervenants.getInternalMembers, params);
    const dataBuilded = yield internalUsersDataBuilder(data);
    yield put(fetchInternalMembersSuccess(dataBuilded));
  } catch (error) {
    yield put(fetchInternalMembersFailure(error));
  }
  yield put(shareMediaFinished());
}

export function* fetchAppMember({ payload: params }) {
  try {
    const { data } = yield call(apiIntervenants.getInternalMembers, params);
    const dataBuilded = yield internalUsersDataBuilder(data);
    yield put(fetchAppMemberSuccess(dataBuilded));
  } catch (error) {
    yield put(fetchAppMemberFailure(error));
  }
  yield put(fetchAppMemberFinished());
}

export function* fetchExternalMembers({ payload: params }) {
  try {
    const { data } = yield call(apiIntervenants.searchExternalMembers, params);
    const dataBuilded = yield externalUsersDataBuilder(data);
    yield put(fetchExternalMembersSuccess(dataBuilded));
  } catch (error) {
    yield put(fetchExternalMembersFailure(error));
  }
  yield put(shareMediaFinished());
}

export function* onShareMediaStart() {
  yield takeLatest(ShareActionTypes.SHARE_MEDIA_START, shareMedia);
}

export function* onFetchInternalMembers() {
  yield takeLatest(
    ShareActionTypes.FETCH_INTERNAL_MEMBERS_START,
    fetchInternalMembers
  );
}

export function* onFetchAppMember() {
  yield takeLatest(ShareActionTypes.FETCH_APP_MEMBER_START, fetchAppMember);
}

export function* onFetchExternalMembers() {
  yield takeLatest(
    ShareActionTypes.FETCH_EXTERNAL_MEMBERS_START,
    fetchExternalMembers
  );
}

export function* shareMediaSagas() {
  yield all([
    call(onShareMediaStart),
    call(onFetchInternalMembers),
    call(onFetchExternalMembers),
    call(onFetchAppMember)
  ]);
}
