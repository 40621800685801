import { createSelector } from 'reselect';

const selectPostLegalEntity = state => state.postLegalEntity;

export const selectPostLegalEntityResult = createSelector(
  [selectPostLegalEntity],
  postLegalEntity => postLegalEntity.response
);

export const selectPostLegalEntityIsLoading = createSelector(
  [selectPostLegalEntity],
  postLegalEntity => postLegalEntity.isLoading
);

export const selectPostLegalEntityError = createSelector(
  [selectPostLegalEntity],
  postLegalEntity => postLegalEntity.error
);
