import { createSelector } from 'reselect';

const selectThumbnails = state => state.thumbnails;

export const selectedThumbnails = createSelector(
  [selectThumbnails],
  getThumbnails => getThumbnails.thumbnails
);

export const selectedSingleThumbnail = createSelector(
  [selectThumbnails],
  getThumbnails => getThumbnails.singleThumbnail
);

export const selectedThumbnailsIsLoading = createSelector(
  [selectThumbnails],
  getThumbnails => getThumbnails.isLoading
);
