import ConditionsActionTypes from './conditions.types';

const INITIAL_STATE = {
  conditionsResults: [],
  error: null,
  isLoading: false
};

const conditionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConditionsActionTypes.FETCH_CONDITIONS_START:
      return { ...state, isLoading: true };
    case ConditionsActionTypes.FETCH_CONDITIONS_SUCCESS:
      return { ...state, conditionsResults: action.payload, error: null };
    case ConditionsActionTypes.FETCH_CONDITIONS_FAILURE:
      return { ...state, error: action.payload };
    case ConditionsActionTypes.FETCH_CONDITIONS_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default conditionsReducer;
