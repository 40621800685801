import FileSectionUploader from './fileSectionUploaderTypes';

const INITIAL_STATE = {
  files: [],
  error: null,
  isLoading: false
};

const fileSectionUploaderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FileSectionUploader.ADD_FILES_SECTION_START:
      return {
        ...state,
        isLoading: true,
        payloadFiles: action.payload,
        succeed: false
      };

    case FileSectionUploader.ADD_FILES_SECTION_SUCCESS:
      return { ...state, files: action.payload, error: null, succeed: true };

    case FileSectionUploader.ADD_FILES_SECTION_FAILURE:
      return { ...state, error: action.payload, succeed: false };

    case FileSectionUploader.ADD_FILES_SECTION_FINISHED:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default fileSectionUploaderReducer;
