/* eslint-disable */
import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const clearStateError = state => {
  for (const entity of Object.entries(state)) {
    entity[1].error = null;
  }

  return state;
};

const clearState = storeVar => next => action => {
  let state = storeVar.getState();
  // after a successful login, update the token in the API
  if (action.type.includes('START')) {
    state = clearStateError(state);
  }

  // continue processing this action
  return next(action, state);
};

middlewares.push(clearState);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export default { store };
