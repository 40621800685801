import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Combobox } from 'react-widgets';
import { Checkbox, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toLocalDate } from '../../shared/functions/date';
import {
  getResolutionFromInstance,
  getFormatFromInstance
} from '../../shared/functions/getInstances';
import {
  getCategoryName,
  getPrograms,
  getSubCategoryName,
  getMediaTitle
} from '../../shared/functions/mediaFunctions';
import copyright from '../copyright/copyright';
import text from '../../constants/translates';
import { colorType } from '../../constants/ui.constants';
import styles from './style.jsx';
import './style.scss';
import {
  checkboxValue,
  PROGRAM_TYPE,
  MEDIA_TYPE,
  ORIGINAL_MEDIA_INSTANCE,
  webFormatVideo,
  webFormatImage,
  EMPTY_STRING,
  pdfFormat,
  SEARCH_TYPE
} from '../../constants/global-constants';
import { fetchSectionStart } from '../../redux/section/sections/section.actions';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import { selectFormatsResult } from '../../redux/formats/format.selectors';
import { selectSubformatsResult } from '../../redux/subformats/subformat.selectors';
import { findProgramByRef } from '../../shared/functions/cleoFunctions';
import { selectSearchCopyResults } from '../../redux/searchCopy/searchCopy.selectors';
import { fetchSearchCopyResultsStart } from '../../redux/searchCopy/searchCopy.actions';
import { isFilialExt } from '../../shared/rights/root-rights';
import {
  selectedCommunes,
  selectedIsLoadingCommunes
} from '../../redux/createProgram/createProgramSelectors';
import { checkVitrine } from '../../shared/functions/vitrine';

const MediaDetails = ({
  media,
  program,
  onClose,
  onChangeFormat,
  onChangeResolution,
  openCrop,
  checkedCopyright,
  onChangeCheckCopyright,
  isMediaExpired,
  isCopyrightVisible,
  sections,
  fetchSections,
  fetchSearchResults,
  searchProgramResults,
  formatValue,
  resolutionValue,
  selectedSearchMedia,
  formats,
  subformats,
  communes,
  isLoadingCommunes,
  history
}) => {
  const style = makeStyles(styles)();
  const [format, setFormat] = useState();
  const [section, setSection] = useState();
  const [currentProgram, setcurrentProgram] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubcategory] = useState();
  const [currentCleoProgram, setCurrentIRProgram] = useState();
  const [programDetails, setProgramDetails] = useState();
  const isDocument = media?.typeMedia?.refValue === MEDIA_TYPE.document;
  const originalResolution = media?.resolution?.replace(/ /g, '');
  const [commune, setCommune] = useState();

  // For get the resolution original
  const resolutionOriginal = { ...ORIGINAL_MEDIA_INSTANCE };
  if (media?.format !== pdfFormat) {
    resolutionOriginal.shortLabel = originalResolution;
  }
  /**
   * Method for check if the media instances includes web full HD image or video and set the default format and subformat
   */
  const checkWebFullHD = () => {
    const webInstance = media?.instances?.filter(el => {
      return (
        el.format.refValue === webFormatImage ||
        el.format.refValue === webFormatVideo
      );
    });
    if (webInstance?.length > 0) {
      /* eslint-disable */
      webInstance?.reverse().map(item => {
        const webFullHDSubFormat = subformats?.result?.filter(el => {
          return el.valueCode === item.subFormat.refValue;
        })?.[0];
        if (webFullHDSubFormat?.isDefault === 'true') {
          const webFullHDFormat = formats?.result?.filter(el => {
            return el.valueCode === item.format.refValue;
          })?.[0];

          setFormat(webFullHDFormat);
          onChangeFormat(webFullHDFormat);
          onChangeResolution(webFullHDSubFormat);
        } else {
          setFormat(ORIGINAL_MEDIA_INSTANCE);
          onChangeFormat(ORIGINAL_MEDIA_INSTANCE);
          onChangeResolution(resolutionOriginal);
        }
      });
    } else {
      setFormat(ORIGINAL_MEDIA_INSTANCE);
      onChangeFormat(ORIGINAL_MEDIA_INSTANCE);
      onChangeResolution(resolutionOriginal);
    }
  };

  useEffect(() => {
    let mediaSection = null;

    if (media?.section?.refSection) {
      mediaSection = media?.section;
    } else {
      if (media?.program?.section?.refSection) {
        mediaSection = media?.program?.section;
      } else {
        mediaSection = media?.folder?.program?.section;
      }
    }
    if (mediaSection) {
      if (sections?.length < 1) {
        fetchSections();
      } else {
        setSection(
          sections.find(sec => sec.refSection === mediaSection?.refSection)
        );
      }
    }
  }, [sections, media?.section, media?.program?.section]);

  useEffect(() => {
    let prog;
    if (program) {
      prog = program;
    } else if (media?.folder?.refFolder) {
      prog = media?.folder?.program;
    }
    prog = {
      ...media?.program,
      ...prog
    };
    setcurrentProgram(prog);
  }, [section, program, media]);

  useEffect(() => {
    // For call programs IR if the program is IR
    if (currentProgram !== undefined) {
      if (
        currentProgram?.section?.refSection === PROGRAM_TYPE.IR_PROGRAM_NAME
      ) {
        const params = {
          type: SEARCH_TYPE.program,
          text: currentProgram?.refProgram,
          section: PROGRAM_TYPE.IR_PROGRAM_NAME,
          myPrograms: isFilialExt()
            ?.toString()
            ?.toUpperCase()
        };
        fetchSearchResults(params);
      }
    }
  }, [currentProgram]);

  useEffect(() => {
    let varProgram = null;
    if (currentProgram?.section?.refSection === PROGRAM_TYPE.IR_PROGRAM_NAME) {
      varProgram = getPrograms(searchProgramResults)?.find(
        prog => prog.programRef.toString() === currentProgram?.refProgram
      );
      setCurrentIRProgram(varProgram);
    }

    if (varProgram) {
      setProgramDetails(varProgram);
    } else {
      setProgramDetails(currentProgram);
    }
  }, [searchProgramResults, currentProgram, media]);

  useEffect(() => {
    if (section !== undefined) {
      setCategory(
        getCategoryName(section?.categories, media?.category?.refCategory)
      );
      setSubcategory(
        getSubCategoryName(
          section?.categories,
          media?.category?.refCategory,
          media?.subCategory?.refSubCategory
        )
      );
      checkWebFullHD();
    }
  }, [media, section, program]);

  useEffect(() => {
    if (programDetails !== undefined) {
      setCommune(communes?.find(com => com.nom === programDetails?.city?.name));
    }
  }, [communes, programDetails, isLoadingCommunes]);

  const getProgFromState = () => {
    return findProgramByRef(
      currentProgram?.refProgram || currentProgram?.programRef
    );
  };

  /**
   * Function for print filial info
   */
  const printFilial = () => {
    if (currentProgram?.section?.refSection === PROGRAM_TYPE.IR_PROGRAM_NAME) {
      const prog = getProgFromState();
      if (
        programDetails?.filial ||
        program?.legalStructure ||
        prog?.legalStructure
      ) {
        return (
          <p className="data-adress__data">
            {`${text.visualProgram.subsidiary} ${programDetails?.filial ||
              program?.legalStructure ||
              prog?.legalStructure}`}
          </p>
        );
      }
    }
    return null;
  };

  const getZipCodes = city => {
    return city?.zipCodes?.length > 0
      ? city?.zipCodes[0]
      : commune?.codesPostaux?.length > 0
      ? commune?.codesPostaux[0]
      : EMPTY_STRING;
  };

  /**
   * Function to set the info of the media
   */
  const getInfo = () => {
    // Case media from a program
    if (media?.program?.section?.refSection) {
      return (
        <div className="data-adress">
          {currentProgram?.address ||
            (currentCleoProgram?.address && (
              <p className="data-adress__data">
                {currentProgram?.address || currentCleoProgram?.address}
              </p>
            ))}
          {currentProgram?.city?.name && (
            <p className="data-adress__data">
              {`${getZipCodes(currentProgram?.city)} ${
                currentProgram?.city?.name
              }`}
            </p>
          )}
          {printFilial()}
        </div>
      );
    }
    // Case media from a folder

    if (media?.folder?.refFolder) {
      return (
        <div className="data-adress">
          <p className="data-adress__data">{programDetails?.address}</p>
          <p className="data-adress__data">
            {`${getZipCodes(programDetails?.city)} ${
              programDetails?.city?.name
            }`}
          </p>
          {printFilial()}
        </div>
      );
    }
    return '';
  };

  /**
   * Function that returns the text for the date of use of the media
   */
  const mediaExpiration = () => {
    if (isMediaExpired) {
      return (
        <p className="data-info__end-of-use">
          {text.visualProgram.mediaUseExpired}
        </p>
      );
    }
    return (
      <p className="data-info__end-of-use">
        {`${text.visualProgram.endOfUse} ${toLocalDate(media?.durationOfUse)}`}
      </p>
    );
  };

  /**
   * Function for get the first avalaible resolution and change it
   */
  const changeResolution = e => {
    const resolution = getResolutionFromInstance(
      media?.instances,
      e,
      checkedCopyright,
      subformats,
      ORIGINAL_MEDIA_INSTANCE,
      originalResolution
    )?.[0];

    onChangeResolution(resolution);
  };

  const displayDurationOfUse = () => {
    if (media?.durationOfUse && !isDocument) {
      return mediaExpiration();
    }
    return null;
  };

  return (
    <>
      <div>
        <div className="title">
          <div className="title__container">
            <p className="title__section-name">
              {getMediaTitle(
                media,
                section,
                currentProgram,
                programDetails,
                currentCleoProgram,
                getProgFromState
              )}
            </p>
            <div
              role="button"
              className="close-button"
              tabIndex="0"
              onClick={onClose}
              onKeyPress={onClose}
            >
              <CloseIcon className={style.iconClose} />
            </div>
          </div>
        </div>
        {getInfo()}
        <div className="data-info">
          <p className="data-info__title">
            {media?.title && `${media?.title}`}
          </p>
        </div>

        <div className="data-info">
          {media?.comment && (
            <p className="data-info__title">
              <Tooltip
                title={
                  <Typography variant="body2"> {media?.comment}</Typography>
                }
                classes={{ tooltip: style.customWidth }}
              >
                <InfoIcon />
              </Tooltip>
              {category || selectedSearchMedia?.category}
            </p>
          )}
          {subCategory ||
            (selectedSearchMedia?.subCategory && (
              <span className="data-info__sub-category">
                {subCategory || selectedSearchMedia?.subCategory}
              </span>
            ))}
          {displayDurationOfUse()}
          <p>
            {media?.tags?.map(tag => (
              <span key={tag?.refTag} className="tag__field">
                <span>{tag?.name} </span>
              </span>
            ))}
          </p>
          {media?.format !== pdfFormat &&
            media?.copyright &&
            copyright(media?.copyright)}
          <p className="data-info__data">
            {`${text.visualProgram.add} ${toLocalDate(media?.dateUpload)}`}
          </p>
          {media?.userUpload !== undefined && (
            <p className="data-info__data">
              {`${text.visualProgram.by} ${media?.userUpload} (Nexity)`}
            </p>
          )}
          <p className="data-info__data">
            {`${text.visualProgram.change} ${toLocalDate(media?.dateUpdate)}`}
          </p>
          {media?.userUpdate !== undefined && (
            <p className="data-info__data">
              {`${text.visualProgram.by} ${media?.userUpdate} (Nexity)`}
            </p>
          )}
        </div>
        {!checkVitrine(history) && (
          <div className="format">
            <Combobox
              valueField="id"
              id="selectFormat"
              className={style.formatComboBox}
              textField="shortLabel"
              placeholder={text.visualProgram.placeholderFormatSelect}
              filter="contains"
              value={formatValue}
              defaultValue={ORIGINAL_MEDIA_INSTANCE}
              data={getFormatFromInstance(
                media?.instances,
                checkedCopyright,
                formats,
                ORIGINAL_MEDIA_INSTANCE
              )}
              onChange={e => {
                setFormat(e);
                onChangeFormat(e);
                changeResolution(e);
              }}
              messages={{
                emptyList: text.visualProgram.emptyList,
                emptyFilter: text.visualProgram.emptyFilter
              }}
              disabled={openCrop || isDocument}
            />

            <Combobox
              valueField="id"
              id="selectResolution"
              className={style.formatComboBox}
              textField="shortLabel"
              placeholder={text.visualProgram.placeholderResolutionSelect}
              filter="contains"
              value={resolutionValue}
              defaultValue={resolutionOriginal}
              data={getResolutionFromInstance(
                media?.instances,
                format,
                checkedCopyright,
                subformats,
                ORIGINAL_MEDIA_INSTANCE,
                originalResolution
              )}
              onChange={onChangeResolution}
              messages={{
                emptyList: text.visualProgram.emptyList,
                emptyFilter: text.visualProgram.emptyFilter
              }}
              disabled={openCrop || isDocument}
            />
          </div>
        )}
        {isCopyrightVisible && media?.copyright && !isDocument && (
          <>
            <div className="add-logo">
              <Checkbox
                checked={checkedCopyright}
                onChange={event => onChangeCheckCopyright(event.target.checked)}
                value={checkboxValue}
                color={colorType.primary}
              />
              {text.visualProgram.attachCopyrigth}
            </div>
          </>
        )}
      </div>
    </>
  );
};

MediaDetails.defaultProps = {
  isCopyrightVisible: true
};

MediaDetails.propTypes = {
  media: PropTypes.oneOfType([PropTypes.string]),
  program: PropTypes.oneOfType([PropTypes.string]),
  onClose: PropTypes.func,
  onChangeFormat: PropTypes.func,
  onChangeResolution: PropTypes.func,
  openCrop: PropTypes.bool,
  onChangeCheckCopyright: PropTypes.func,
  checkedCopyright: PropTypes.bool,
  isMediaExpired: PropTypes.bool,
  isCopyrightVisible: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchSections: PropTypes.func,
  formatValue: PropTypes.string,
  resolutionValue: PropTypes.string,
  selectedSearchMedia: PropTypes.shape({
    mediaURL: PropTypes.string,
    category: PropTypes.string,
    subCategory: PropTypes.string
  }),
  formats: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  subformats: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchSearchResults: PropTypes.func,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  communes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoadingCommunes: PropTypes.bool,
  vitrine: PropTypes.bool,
  history: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  sections: selectSectionsResult,
  formats: selectFormatsResult,
  subformats: selectSubformatsResult,
  searchProgramResults: selectSearchCopyResults,
  communes: selectedCommunes,
  isLoadingCommunes: selectedIsLoadingCommunes
});

const mapDispatchToProps = dispatch => ({
  fetchSections: () => dispatch(fetchSectionStart()),
  fetchSearchResults: params => dispatch(fetchSearchCopyResultsStart(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDetails);
