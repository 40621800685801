import MySelectionTypes from './mySelection.types';

const INITIAL_STATE = {
  mySelectionList: [],
  error: null,
  isLoading: false
};

const removeFromMySelecion = (mySelection, payload) => {
  const myArray = mySelection;
  return myArray.filter(item => item?.mediaDetails?.refMedia !== payload);
};

const mySelectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MySelectionTypes.ADD_TO_MY_SELECTION:
      return {
        ...state,
        mySelectionList: state.mySelectionList.concat(action.payload)
      };
    case MySelectionTypes.REMOVE_MY_SELECTION_ITEM:
      return {
        ...state,
        mySelectionList: removeFromMySelecion(
          state.mySelectionList,
          action.payload
        )
      };
    case MySelectionTypes.CLEAR_MY_SELECTION:
      return {
        ...state,
        mySelectionList: []
      };
    default:
      return state;
  }
};

export default mySelectionReducer;
