const OrganizationalEntityTypes = {
  FETCH_ORGANIZATIONALENTITY_START: 'FETCH_ORGANIZATIONALENTITY_START',
  FETCH_ORGANIZATIONALENTITY_SUCCESS: 'FETCH_ORGANIZATIONALENTITY_SUCCESS',
  FETCH_ORGANIZATIONALENTITY_FAILURE: 'FETCH_ORGANIZATIONALENTITY_FAILURE',
  FETCH_ORGANIZATIONALENTITY_FINISHED: 'FETCH_ORGANIZATIONALENTITY_FINISHED',

  FETCH_COMPANY_DATA_START: 'FETCH_COMPANY_DATA_START',
  FETCH_COMPANY_DATA_SUCCESS: 'FETCH_COMPANY_DATA_SUCCESS',
  FETCH_COMPANY_DATA_FAILURE: 'FETCH_COMPANY_DATA_FAILURE',
  FETCH_COMPANY_DATA_FINISHED: 'FETCH_COMPANY_DATA_FINISHED'
};

export default OrganizationalEntityTypes;
