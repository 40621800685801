import axios from 'axios';
import { API_ROUTES } from './apiRoutes';
import { AUTHENTICATION } from '../constants/global-constants';
import { API_TLC } from './api.conf';

class API {
  http;

  constructor() {
    const instance = axios.create({
      baseURL: API_TLC
    });
    this.http = instance;
  }

  setAutorizationToken = (token, refreshToken) => {
    if (token) {
      localStorage.setItem(AUTHENTICATION.jwtTokenKey, token);
      if (refreshToken) {
        localStorage.setItem(AUTHENTICATION.refreshTokenKey, refreshToken);
      }
      this.http.defaults.headers.common.Authorization = `${AUTHENTICATION.bearerKey} ${token}`;
    } else {
      delete this.http.defaults.headers.common.Authorization;
      localStorage.clear();
    }
  };

  getIndicators = refProgram =>
    this.http.get(`${API_ROUTES.lot}${refProgram}`, {});
}

export default new API();
