import { createSelector } from 'reselect';

const deletePartners = state => state.partnerDelete;

export const selectDeletePartnerIsLoading = createSelector(
  [deletePartners],
  deletePartner => deletePartner.isLoading
);

export const selectDeletePartnerError = createSelector(
  [deletePartners],
  deletePartner => deletePartner.error
);

export const selectDeletePartnerSucceed = createSelector(
  [deletePartners],
  deletePartner => deletePartner.succeed
);
