import S3CallerTypes from './s3-caller.types';

export const sendS3DataStart = data => ({
  type: S3CallerTypes.SEND_S3_DATA_START,
  payload: data
});

export const sendS3DataSuccess = data => ({
  type: S3CallerTypes.SEND_S3_DATA_SUCCESS,
  payload: data
});

export const sendS3DataFailure = error => ({
  type: S3CallerTypes.SEND_S3_DATA_FAILURE,
  payload: error
});

export const sendS3DataFinished = () => ({
  type: S3CallerTypes.SEND_S3_DATA_FINISHED
});
