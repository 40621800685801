import { createSelector } from 'reselect';

const partnerDetails = state => state.partnerDetails;

export const selectPartnerDetails = createSelector(
  [partnerDetails],
  partner => partner.partnerDetails
);

export const selectPartnerDetailsIsLoading = createSelector(
  [partnerDetails],
  partner => partner.isLoading
);

export const selectPartnerDetailError = createSelector(
  [partnerDetails],
  partner => partner.error
);
