import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MediaGallery from '../../components/MediaGallery/MediaGallery.jsx';
import t from '../../constants/translates';
import { PROGRAM_TYPE, SEARCH_TYPE } from '../../constants/global-constants';
import styles from './style.jsx';
import './style.scss';
import Spinner from '../../components/spinner/Spinner.jsx';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import { fetchVisualFolderStart } from '../../redux/visual-folder/visual-folder.actions.js';
import {
  selectVisualFolderResult,
  selectVisualFolderIsLoading
} from '../../redux/visual-folder/visual-folder.selectors.js';
import {
  selectFolderDetails,
  selectFolderDetailsIsLoading
} from '../../redux/folder-details/folder-details.selectors';
import { fetchFolderDetailsStart } from '../../redux/folder-details/folder-details.actions.js';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import { fetchCompanyDataStart } from '../../redux/organizationalEntity/organizationalEntity.actions.js';
import { selectCompanyDataResult } from '../../redux/organizationalEntity/organizationalEntity.selectors.js';
import { canEdit, isFilialExt } from '../../shared/rights/root-rights.js';
import { ATTRIBUTES } from '../../shared/rights/roles.js';
import { fetchSearchResultsStart } from '../../redux/search/search.actions.js';
import { selectSearchResults } from '../../redux/search/search.selectors.js';
import { getPrograms } from '../../shared/functions/mediaFunctions.js';
import {
  selectedCommunes,
  selectedIsLoadingCommunes
} from '../../redux/createProgram/createProgramSelectors';

/**
 * Component to show the list of medias of 1 folder
 */
const ProgramSheet = ({
  match,
  visualFolderData,
  fetchVisualFolder,
  selectVisualFolderIsLoadingProp,
  fetchFolderDetails,
  selectFolderDetailsProp,
  selectFolderDetailsIsLoadingProp,
  history,
  sections,
  fetchCompanyData,
  companyDataResult,
  fetchSearchResults,
  searchProgramResults,
  communes,
  isLoadingCommunes
}) => {
  const classes = makeStyles(styles)();
  const refFolder = match?.params.reportage;
  const refProgram = match?.params.medias;
  const [mediaData, setMediaData] = useState([]);
  const [section, setSection] = useState();
  const [currentProgramIR, setCurrentProgramIR] = useState();
  const [commune, setCommune] = useState();

  useEffect(() => {
    // For obtain the all the medias of the folder
    fetchVisualFolder({ refFolder, withCategory: null });
    // For obtain all the info detail of the folder (info program)
    fetchFolderDetails({ refFolder, refProgram });
  }, []);

  useEffect(() => {
    setMediaData(visualFolderData);
  }, [visualFolderData]);

  useEffect(() => {
    setSection(
      sections?.find(
        sec =>
          sec.refSection ===
          selectFolderDetailsProp?.program?.section?.refSection
      )
    );

    if (
      selectFolderDetailsProp?.program?.section?.refSection ===
      PROGRAM_TYPE.IR_PROGRAM_NAME
    ) {
      const params = {
        type: SEARCH_TYPE.program,
        text: refProgram,
        section: PROGRAM_TYPE.IR_PROGRAM_NAME,
        myPrograms: isFilialExt()
          ?.toString()
          ?.toUpperCase()
      };
      fetchSearchResults(params);
    }
  }, [sections, selectFolderDetailsProp]);

  const getCleoProgram = () => {
    return getPrograms(searchProgramResults)?.find(
      prog => prog?.programRef?.toString() === refProgram
    );
  };

  useEffect(() => {
    const prog = getCleoProgram();
    setCurrentProgramIR(prog);
  }, [selectFolderDetailsProp, searchProgramResults]);

  const getIsIR = () => {
    return section?.type === PROGRAM_TYPE.IR_PROGRAM;
  };

  useEffect(() => {
    if (currentProgramIR?.companyCode) {
      const companyCode = `000${currentProgramIR?.companyCode}`.slice(-4);
      fetchCompanyData(companyCode);
    }
  }, [currentProgramIR]);

  useEffect(() => {
    setCommune(
      communes?.find(
        com => com.code === selectFolderDetailsProp?.program?.cityCode
      )
    );
  }, [communes, selectFolderDetailsProp, isLoadingCommunes]);

  const getTitle = () => {
    let codePart = '';
    let namePart = selectFolderDetailsProp?.program?.name;
    if (getIsIR()) {
      const cleoProg = getCleoProgram();
      codePart = `${cleoProg?.code}`;
      namePart = cleoProg?.name;
      if (namePart === undefined) {
        return `${section?.name} : ${codePart}`;
      }
    }
    return `${section?.name} : ${codePart} - ${namePart}`;
  };

  const returnLink = () => {
    return (
      <div className="returnLink">
        <Button
          variant="outlined"
          color="primary"
          className={classes.returnButton}
          onClick={() =>
            history.push(
              `${ROUTES_CONSTANTS.mediaSummaryBase}${selectFolderDetailsProp?.program?.refProgram}`
            )
          }
        >
          <ArrowBackIosOutlinedIcon />
          <b className="button__text--paddingLeft">{t.programSheet.return}</b>
        </Button>
        <FolderOpenOutlinedIcon className={classes.folderIcon} />
        <span className="text__refName">{selectFolderDetailsProp?.name}</span>
      </div>
    );
  };

  const programAdress = () => {
    const prog = {
      address: selectFolderDetailsProp?.program?.address,
      city: selectFolderDetailsProp?.program?.city
    };
    if (getIsIR()) {
      const cleoProg = getCleoProgram();
      prog.address = cleoProg?.address;
      prog.city = cleoProg?.city;
    }
    if (prog?.city?.name !== undefined) {
      if (prog?.address === undefined) {
        return (
          <p className="program__adress">
            {`${t.visualProgram.adresse} ${commune?.code} ${commune?.nom}`}
          </p>
        );
      }
      return (
        <p className="program__adress">
          {`${t.visualProgram.adresse} ${prog?.address} - ${commune?.code} ${commune?.nom}`}
        </p>
      );
    }
    return (
      <p className="program__adress">
        {`${t.visualProgram.adresse} ${prog?.address}`}
      </p>
    );
  };

  const getPathOrganizationalEntity = () => {
    if (getIsIR()) {
      return getCleoProgram()?.pathOrganizationalEntity;
    }

    return true;
  };

  return (
    <>
      <Spinner
        isLoading={
          selectVisualFolderIsLoadingProp || selectFolderDetailsIsLoadingProp
        }
      />
      <>
        <DismissableAlert />
        <div className="programSheetContainer">
          <Grid container spacing={0}>
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={9}
              direction="row"
              alignItems="center"
            >
              <p className="program__title">{getTitle()}</p>
            </Grid>
            <Grid container item xs={12} sm={6} md={3}>
              {canEdit(
                getPathOrganizationalEntity(),
                ATTRIBUTES.BME_PROGRAM_WRITE
              ) && (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.addMediaButton}
                  onClick={() =>
                    history.push(
                      `${ROUTES_CONSTANTS.mediaSummaryBase}${refProgram}${ROUTES_CONSTANTS.filesFolderUploaderBase}${refFolder}`
                    )
                  }
                >
                  <b className="button__text--padding">
                    {t.programSheet.programAd}
                  </b>
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              {programAdress()}
              {getIsIR() && companyDataResult?.organizationalEntity && (
                <div className="program__adress">
                  <p className="program__filial">
                    {`${t.visualProgram.subsidiary} ${companyDataResult?.organizationalEntity?.shortLabel}`}
                  </p>
                </div>
              )}
            </Grid>
          </Grid>
          {returnLink()}
          <MediaGallery history mediaList={mediaData} isFolderList />
        </div>
      </>
    </>
  );
};

ProgramSheet.propTypes = {
  match: PropTypes.string,
  fetchVisualFolder: PropTypes.func,
  history: PropTypes.func,
  visualFolderData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  selectVisualFolderIsLoadingProp: PropTypes.bool,
  fetchFolderDetails: PropTypes.func,
  selectFolderDetailsProp: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  selectFolderDetailsIsLoadingProp: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchCompanyData: PropTypes.func,
  companyDataResult: PropTypes.oneOfType([PropTypes.string]),
  fetchSearchResults: PropTypes.func,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  communes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoadingCommunes: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  visualFolderData: selectVisualFolderResult,
  selectVisualFolderIsLoadingProp: selectVisualFolderIsLoading,
  selectFolderDetailsProp: selectFolderDetails,
  selectFolderDetailsIsLoadingProp: selectFolderDetailsIsLoading,
  sections: selectSectionsResult,
  companyDataResult: selectCompanyDataResult,
  searchProgramResults: selectSearchResults,
  communes: selectedCommunes,
  isLoadingCommunes: selectedIsLoadingCommunes
});

const mapDispatchToProps = dispatch => ({
  fetchVisualFolder: folderRef => dispatch(fetchVisualFolderStart(folderRef)),
  fetchFolderDetails: refFolder => dispatch(fetchFolderDetailsStart(refFolder)),
  fetchCompanyData: code => dispatch(fetchCompanyDataStart(code)),
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgramSheet)
);
