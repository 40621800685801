import CreateFolder from './createFolderTypes.js';

export const sendCreateFolderDataStart = formData => ({
  type: CreateFolder.SEND_CREATE_FOLDER_START,
  payload: formData
});

export const sendCreateFolderDataSuccess = message => ({
  type: CreateFolder.SEND_CREATE_FOLDER_SUCCESS,
  payload: message
});

export const sendCreateFolderDataFailure = error => ({
  type: CreateFolder.SEND_CREATE_FOLDER_FAILURE,
  payload: error
});

export const sendCreateFolderDataFinished = () => ({
  type: CreateFolder.SEND_CREATE_FOLDER_FINISHED
});
