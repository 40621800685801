import CreatePartner from './createPartnerTypes.js';

export const sendCreatePartnerDataStart = formData => ({
  type: CreatePartner.SEND_CREATE_PARTNER_START,
  payload: formData
});

export const sendCreatePartnerDataSuccess = message => ({
  type: CreatePartner.SEND_CREATE_PARTNER_SUCCESS,
  payload: message
});

export const sendCreatePartnerDataFailure = error => ({
  type: CreatePartner.SEND_CREATE_PARTNER_FAILURE,
  payload: error
});

export const sendCreatePartnerDataFinished = () => ({
  type: CreatePartner.SEND_CREATE_PARTNER_FINISHED
});
