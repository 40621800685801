import partnerLegalEntityActionTypes from './partner-legalEntity.types';

const INITIAL_STATE = {
  partnerLegalEntity: [],
  error: null,
  isLoading: false
};

const partnerLegalEntityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_START:
      return { ...state, isLoading: true };
    case partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_SUCCESS:
      return { ...state, partnerLegalEntity: action.payload, error: null };
    case partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_FAILURE:
      return { ...state, error: action.payload };
    case partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default partnerLegalEntityReducer;
