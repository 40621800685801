import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import './createProgram.scss';
import {
  sendFormDataStart,
  fetchTypeOfProyectsStart,
  fetchDestinationPropertyStart,
  fetchRegionsStart,
  fetchDepartmentsStart,
  fetchCommunesStart
} from '../../redux/createProgram/createProgramActions.js';
import {
  selectedIsLoading,
  selectedIsLoadingTypeProjects,
  selectedTypeOfProjects,
  selectedDestinationProperty,
  selectedIsLoadingDestinationProperty,
  selectedRegions,
  selectedIsLoadingRegions,
  selectedDepartments,
  selectedIsLoadingDepartments,
  selectedCommunes,
  selectedIsLoadingCommunes
} from '../../redux/createProgram/createProgramSelectors.js';
import CreateProgramIE from '../../components/createProgramIE/createProgramIE.jsx';
import Spinner from '../../components/spinner/Spinner.jsx';
import DismissableAlert from '../../components/alert/DismissableAlert';
import { canEdit } from '../../shared/rights/root-rights';
import { ATTRIBUTES } from '../../shared/rights/roles';

const CreateProgram = ({
  match,
  sendFormData,
  fetchTypeOfProyects,
  typeOfProjects,
  isLoading,
  isLoadingTypeProjects,
  destinationPropertyItems,
  isLoadingDestinationProperty,
  fetchDestinationProperty,
  fetchRegions,
  fetchDepartments,
  regions,
  isLoadingRegions,
  departments,
  isLoadingDepartments,
  fetchCommunes,
  communes,
  isLoadingCommunes,
  history
}) => {
  // Passing props to child component CreateProgramIE
  let isEditMode = false;
  let refProgram = null;
  if (match?.params?.refProgram) {
    isEditMode = true;
    refProgram = match?.params?.refProgram;
  }

  useEffect(() => {
    if (canEdit(true, ATTRIBUTES.BME_PROGRAM_WRITE)) {
      fetchTypeOfProyects();
      fetchDestinationProperty();
      fetchRegions();
      fetchDepartments();
      fetchCommunes();
    }
  }, []);

  if (!canEdit(true, ATTRIBUTES.BME_PROGRAM_WRITE)) {
    return <div></div>;
  }

  return (
    <>
      <DismissableAlert />
      <Spinner
        isLoading={
          isLoading ||
          isLoadingTypeProjects ||
          isLoadingDestinationProperty ||
          isLoadingRegions ||
          isLoadingCommunes ||
          isLoadingDepartments
        }
      />
      <CreateProgramIE
        sendFormData={sendFormData}
        typeOfProjects={typeOfProjects}
        destinationPropertyItems={destinationPropertyItems}
        regions={regions}
        departments={departments}
        communes={communes}
        history={history}
        isEditMode={isEditMode}
        refProgram={refProgram}
      />
    </>
  );
};

CreateProgram.propTypes = {
  match: PropTypes.string,
  sendFormData: PropTypes.func,
  fetchTypeOfProyects: PropTypes.func,
  fetchDestinationProperty: PropTypes.func,
  fetchRegions: PropTypes.func,
  fetchDepartments: PropTypes.func,
  regions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoadingRegions: PropTypes.bool,
  departments: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoadingDepartments: PropTypes.bool,
  fetchCommunes: PropTypes.func,
  communes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoadingCommunes: PropTypes.bool,
  typeOfProjects: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  isLoadingTypeProjects: PropTypes.bool,
  destinationPropertyItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  isLoadingDestinationProperty: PropTypes.bool,
  history: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  typeOfProjects: selectedTypeOfProjects,
  isLoading: selectedIsLoading,
  isLoadingTypeProjects: selectedIsLoadingTypeProjects,
  destinationPropertyItems: selectedDestinationProperty,
  isLoadingDestinationProperty: selectedIsLoadingDestinationProperty,
  regions: selectedRegions,
  isLoadingRegions: selectedIsLoadingRegions,
  departments: selectedDepartments,
  isLoadingDepartments: selectedIsLoadingDepartments,
  communes: selectedCommunes,
  isLoadingCommunes: selectedIsLoadingCommunes
});

const mapDispatchToProps = dispatch => ({
  sendFormData: formData => dispatch(sendFormDataStart(formData)),
  fetchTypeOfProyects: () => dispatch(fetchTypeOfProyectsStart()),
  fetchDestinationProperty: () => dispatch(fetchDestinationPropertyStart()),
  fetchRegions: () => dispatch(fetchRegionsStart()),
  fetchDepartments: () => dispatch(fetchDepartmentsStart()),
  fetchCommunes: () => dispatch(fetchCommunesStart())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateProgram)
);
