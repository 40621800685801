import { createSelector } from 'reselect';

const mediaDetails = state => state.mediaDetails;

export const selectMediaDetails = createSelector(
  [mediaDetails],
  media => media.mediaDetails
);

export const selectMediaDetailsIsLoading = createSelector(
  [mediaDetails],
  media => media.isLoading
);

export const selectMediaDetailsError = createSelector(
  [mediaDetails],
  media => media.error
);

export const selectSupportList = createSelector(
  [mediaDetails],
  media => media.supportList
);
