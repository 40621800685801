import { createSelector } from 'reselect';

const selectFolderFiles = state => state.fileFolderUploader;

export const selectedFolderFiles = createSelector(
  [selectFolderFiles],
  fileFolderUploader => fileFolderUploader.files
);

export const selectedFolderFilesIsLoading = createSelector(
  [selectFolderFiles],
  fileFolderUploader => fileFolderUploader.isLoading
);

export const selectedFolderFilesError = createSelector(
  [selectFolderFiles],
  fileFolderUploader => fileFolderUploader.error
);

export const selectedFolderFilesSucceed = createSelector(
  [selectFolderFiles],
  fileFolderUploader => fileFolderUploader.succeed
);
