import { createSelector } from 'reselect';

const selectLegalEntitys = state => state.legalEntity;

export const selectLegalEntitysResult = createSelector(
  [selectLegalEntitys],
  legalEntity => legalEntity.result
);

export const selectLegalEntitysCount = createSelector(
  [selectLegalEntitys],
  legalEntity => legalEntity.result?.length
);

export const selectLegalEntitysLoading = createSelector(
  [selectLegalEntitys],
  legalEntity => legalEntity.isLoading
);

export const selectLegalEntitysError = createSelector(
  [selectLegalEntitys],
  legalEntity => legalEntity.error
);
