import societyQualitiesActionTypes from './society-qualities.types';

const INITIAL_STATE = {
  societyQualities: [],
  error: null,
  isLoading: false
};

const societyQualitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_START:
      return { ...state, isLoading: true };
    case societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_SUCCESS:
      return { ...state, societyQualities: action.payload, error: null };
    case societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_FAILURE:
      return { ...state, error: action.payload };
    case societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default societyQualitiesReducer;
