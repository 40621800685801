/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

import useSupercluster from 'use-supercluster';
import './style.scss';
import { REACT_APP_GOOGLE_KEY } from '../../../api/api.conf';
import ProgramCard from '../../../components/programCard/ProgramCard';
import NoMediaCard from '../../../components/noMediaCard/NoMediaCard';
import { ROUTES_CONSTANTS } from '../../../constants/route.constants';
import {
  PROGRAM_TYPE,
  zoom as zoomInitial
} from '../../../constants/global-constants';

const Marker = ({ children }) => children;

const Map = ({
  programs,
  fetchGetThumbnails,
  thumbnails,
  coordinatesCallback,
  properties
}) => {
  const mapRef = useRef();
  // get map boundsbounds
  const [bounds, setBounds] = useState(null);
  const [zoomMap, setZoomMap] = useState(zoomInitial);
  const [points, setPoints] = useState([]);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [programShowed, setProgramShowed] = useState(null);
  const [zoomOld, setZoomOld] = useState();
  const programFilter = id => {
    return programs.filter(prog => prog?.programRef === id)?.[0];
  };

  const getThumbnailFromList = media => {
    const thumb = thumbnails?.find(
      item => item.thumbnailApiUrl === media?.thumbnailUrl
    );
    if (thumb?.thumbnail !== undefined) {
      return thumb?.thumbnail;
    }
    return null;
  };

  useEffect(() => {
    if (programs?.length > 0) {
      const geometrys = [];
      // Preparation of markers geometry with the programs
      programs?.map(program =>
        geometrys.push({
          type: 'Feature',
          properties: {
            cluster: false,
            id: program?.programRef,
            name: program?.name,
            category: program?.section?.type,
            thumbnailUrl: program?.thumbnailUrl
          },
          geometry: {
            type: 'Point',
            coordinates: [
              parseFloat(program?.location?.lng),
              parseFloat(program?.location?.lat)
            ]
          }
        })
      );
      setPoints(geometrys);
      const highestlat = properties?.newBounds?.nw?.lat;
      const highestlong = properties?.newBounds?.se?.lng;
      const lowestlat = properties?.newBounds?.se?.lat;
      const lowestlong = properties?.newBounds?.nw?.lat;
      setBounds([
        lowestlong - 0.05,
        lowestlat - 0.05,
        highestlong + 0.05,
        highestlat + 0.05
      ]);
    } else {
      setPoints([]);
    }
  }, [programs]);

  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoomMap,
    options: { radius: 75, maxZoom: 20 }
  });

  return (
    <div
      style={{ height: '80vh', width: '100%', marginTop: '20px', zIndex: '0' }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: REACT_APP_GOOGLE_KEY }}
        center={properties?.center}
        zoom={properties?.zoomValue}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom }) => {
          setZoomMap(zoom);
        }}
        onDragEnd={() => {
          const nw = {
            lat: mapRef.current
              ?.getBounds()
              .getNorthEast()
              ?.lat(),
            lng: mapRef.current
              ?.getBounds()
              .getSouthWest()
              ?.lng()
          };
          const se = {
            lat: mapRef.current
              ?.getBounds()
              .getSouthWest()
              ?.lat(),
            lng: mapRef.current
              ?.getBounds()
              .getNorthEast()
              ?.lng()
          };
          const coor = { nw, se };
          coordinatesCallback({ coor: { nw, se }, zoom: zoomMap });
          setZoomMap(zoomMap);
          if (coor !== undefined) {
            setBounds([nw.lng, se.lat, se.lng, nw.lat]);
          }
        }}
        onClick={() => {
          setShowingInfoWindow(false);
        }}
      >
        {/* markers here */}

        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                className="marker"
              >
                <div
                  className="cluster-marker"
                  role="none"
                  style={{
                    width: `${10 + (pointCount / points.length) * 20}px`,
                    height: `${10 + (pointCount / points.length) * 20}px`
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    if (expansionZoom >= zoomOld) {
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                      setZoomOld(expansionZoom);
                    }
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          return (
            <Marker
              key={`program-${cluster.properties.id}`}
              lat={latitude}
              lng={longitude}
              className="marker"
            >
              <div
                className={
                  cluster.properties.category === PROGRAM_TYPE.IR_PROGRAM
                    ? 'cluster-marker-IR'
                    : 'cluster-marker-IE'
                }
                id={cluster.properties.id}
                role="none"
                style={{
                  width: `${10 + (pointCount / points.length) * 20}px`,
                  height: `${10 + (pointCount / points.length) * 20}px`
                }}
                onMouseOver={() => {
                  const programSelected = programs.filter(
                    program => program.programRef === cluster.properties.id
                  );
                  fetchGetThumbnails(programSelected);
                  setShowingInfoWindow(!showingInfoWindow);
                  setProgramShowed(cluster.properties.id);
                }}
                onMouseOut={() => {
                  setTimeout(() => {
                    setProgramShowed(null);
                    setShowingInfoWindow(false);
                  }, 500);
                }}
              ></div>
              {showingInfoWindow && programShowed === cluster.properties.id && (
                <>
                  {getThumbnailFromList(
                    programFilter(cluster.properties.id)
                  ) === null ? (
                    <div
                      role="none"
                      onMouseOver={() => {
                        const programSelected = programs.filter(
                          program =>
                            program.programRef === cluster.properties.id
                        );
                        fetchGetThumbnails(programSelected);
                        setTimeout(() => {
                          setShowingInfoWindow(true);
                          setProgramShowed(cluster.properties.id);
                        }, 500);
                      }}
                      onMouseOut={() => {
                        setTimeout(() => {
                          setProgramShowed(null);
                          setShowingInfoWindow(false);
                        }, 500);
                      }}
                    >
                      <NoMediaCard
                        options={[programFilter(cluster.properties.id)]}
                      />
                    </div>
                  ) : (
                    <div
                      className="card-info"
                      role="none"
                      onClick={() => {
                        const win = window.open(
                          `${ROUTES_CONSTANTS.vitrineProgramSheetBase}${cluster.properties.id}`,
                          '_blank'
                        );
                        win.focus();
                      }}
                      onMouseOver={() => {
                        const programSelected = programs.filter(
                          program =>
                            program.programRef === cluster.properties.id
                        );
                        fetchGetThumbnails(programSelected);
                        setTimeout(() => {
                          setShowingInfoWindow(true);
                          setProgramShowed(cluster.properties.id);
                        }, 500);
                      }}
                      onMouseOut={() => {
                        setTimeout(() => {
                          setProgramShowed(null);
                          setShowingInfoWindow(false);
                        }, 500);
                      }}
                    >
                      <ProgramCard
                        {...programFilter(cluster.properties.id)}
                        thumbnail={getThumbnailFromList(
                          programFilter(cluster.properties.id)
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            </Marker>
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

Map.propTypes = {
  programs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  thumbnails: PropTypes.oneOfType([PropTypes.string]),
  fetchGetThumbnails: PropTypes.func,
  coordinatesCallback: PropTypes.func,
  properties: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

export default withRouter(Map);
