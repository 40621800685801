import ModificationTypes from './media-modification.types';

const INITIAL_STATE = {
  mediaUrl: null,
  error: null,
  isLoading: false,
  message: null,
  succeed: false
};

const modificationMediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModificationTypes.SEND_MODIFICATION_DATA_START:
      return { ...state, isLoading: true, succeed: false };
    case ModificationTypes.SEND_MODIFICATION_DATA_SUCCESS:
      return {
        ...state,
        mediaUrl: action.payload,
        error: null,
        succeed: true
      };
    case ModificationTypes.SEND_MODIFICATION_DATA_FAILURE:
      return {
        ...state,
        error: action.payload?.data,
        succeed: false
      };
    case ModificationTypes.SEND_MODIFICATION_DATA_FINISHED:
      return { ...state, isLoading: false, succeed: false };

    default:
      return state;
  }
};

export default modificationMediaReducer;
