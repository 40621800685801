import { put, all, call, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import DeleteTypes from './partner-delete.types';
import api from '../../api/api.jsx';
import apiInter from '../../api/apiIntervenants.jsx';
import {
  sendPartnerDeleteDataSuccess,
  sendPartnerDeleteDataFailure,
  sendPartnerDeleteDataFinished
} from './partner-delete.actions';
import { toLocalDate } from '../../shared/functions/date';

export function* sendDeletePartner({ payload: partner }) {
  try {
    const { data } = yield call(api.deletePartner, partner.refPartner);
    const dataExpiration = toLocalDate(moment());
    const refLegalEntity = partner.nameSociety;
    const { refRelationShipId } = partner;
    const partnerPath = [
      { op: 'add', path: '/relationshipEndDate', value: dataExpiration }
    ];
    yield call(apiInter.patchModificationPartners, {
      refLegalEntity,
      refRelationShipId,
      partnerPath
    });
    yield put(sendPartnerDeleteDataSuccess(data));
  } catch (error) {
    yield put(sendPartnerDeleteDataFailure(error));
  }
  yield put(sendPartnerDeleteDataFinished());
}

export function* onSendDeletePartner() {
  yield takeLatest(
    DeleteTypes.SEND_PARTNER_DELETE_DATA_START,
    sendDeletePartner
  );
}

export function* PartnerDeleteSagas() {
  yield all([call(onSendDeletePartner)]);
}
