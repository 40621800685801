const CategorizationTypes = {
  FETCH_CATEGORIZATION_MEDIA_START: 'FETCH_CATEGORIZATION_MEDIA_START',
  FETCH_CATEGORIZATION_MEDIA_SUCCESS: 'FETCH_CATEGORIZATION_MEDIA_SUCCESS',
  FETCH_CATEGORIZATION_MEDIA_FAILURE: 'FETCH_CATEGORIZATION_MEDIA_FAILURE',
  FETCH_CATEGORIZATION_MEDIA_FINISHED: 'FETCH_CATEGORIZATION_MEDIA_FINISHED',
  SEND_CATEGORIZATION_DATA_START: 'SEND_CATEGORIZATION_DATA_START',
  SEND_CATEGORIZATION_DATA_SUCCESS: 'SEND_CATEGORIZATION_DATA_SUCCESS',
  SEND_CATEGORIZATION_DATA_FAILURE: 'SEND_CATEGORIZATION_DATA_FAILURE',
  SEND_CATEGORIZATION_DATA_FINISHED: 'SEND_CATEGORIZATION_DATA_FINISHED'
};

export default CategorizationTypes;
