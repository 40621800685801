export default {
  formatComboBox: {
    width: '50%',
    marginLeft: '7px',
    marginTop: '20px'
  },
  iconClose: {
    fontSize: '40px',
    color: '#4E5360'
  },
  customWidth: {
    maxWidth: 500
  }
};
