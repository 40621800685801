import { createSelector } from 'reselect';

const selectCreateSection = state => state.createSection;

export const selectCreatedSection = createSelector(
  [selectCreateSection],
  createSection => createSection.results
);

export const selectCreatedSectionLoading = createSelector(
  [selectCreateSection],
  createSection => createSection.isLoading
);

export const selectCreatedSectionError = createSelector(
  [selectCreateSection],
  createSection => createSection.error
);

export const selectCreatedSectionSucceed = createSelector(
  [selectCreateSection],
  createSection => createSection.succeed
);
