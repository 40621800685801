import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import { createStructuredSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { ROUTES_CONSTANTS } from '../../constants/route.constants';
import texts from '../../constants/translates';
import {
  KEYS,
  MIN_SEARCH_LENGTH,
  SEARCH_TYPES,
  initial
} from '../../constants/global-constants';
import './style.scss';
import style from './style.jsx';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import {
  selectToolbarParams,
  selectSearchSuggestions
} from '../../redux/search/search.selectors';
import {
  fetchSearchSuggestionsStart,
  fetchSearchResultsStart
} from '../../redux/search/search.actions';
import { useWindowDimensions } from '../../shared/functions/useWindowDimensions';
import { checkVitrine } from '../../shared/functions/vitrine';

const SearchInput = ({
  sections,
  onSearchChange,
  history,
  toolbarParams,
  suggestions,
  fetchSuggestions,
  fetchSearchResults,
  deleteSearch
}) => {
  const classes = makeStyles(style)();
  const [search, setSearch] = useState({
    refFilter: texts.homeBannerContainer.refAll,
    inputSearch: ''
  });
  const [isDisabledDeleteButton, setIsDisabledDeleteButton] = useState(true);
  const [isDisabledSearchButton, setIsDisabledSearchButton] = useState(true);
  const [lastSearch, setLastSearch] = useState(0);

  const bigScreenResolution = useWindowDimensions();

  useEffect(() => {
    if (
      history.location.pathname !== ROUTES_CONSTANTS.vitrineMediatheque &&
      history.location.pathname !== `${ROUTES_CONSTANTS.vitrineMediatheque}/`
    ) {
      // For reset the search if user delete text search
      if (search?.inputSearch?.length === 0 && lastSearch === 1) {
        const section = sections?.find(
          sec => sec.refSection === search.refFilter
        );
        history.push(
          `${ROUTES_CONSTANTS.programDetailLink}${section?.refSection}`
        );
      }

      const isDisabled = search?.inputSearch?.length < MIN_SEARCH_LENGTH;
      setIsDisabledSearchButton(isDisabled);
      setIsDisabledDeleteButton(isDisabled);
      if (!isDisabled) {
        fetchSuggestions(search.inputSearch);
      }
      if (onSearchChange) {
        onSearchChange(search);
      }
      setLastSearch(search?.inputSearch?.length);
    }
  }, [search]);

  const handleDelete = () => {
    setIsDisabledSearchButton(true);
    setIsDisabledDeleteButton(true);
    if (search?.inputSearch !== undefined) {
      setSearch({
        refFilter: texts.homeBannerContainer.refAll,
        inputSearch: ''
      });
      if (checkVitrine(history)) {
        history.push(`${ROUTES_CONSTANTS.vitrineMediatheque}`);
      }
    }
  };

  useEffect(() => {
    if (deleteSearch === true) {
      handleDelete();
    }
  }, [deleteSearch]);

  useEffect(() => {
    setSearch({
      inputSearch: toolbarParams?.text
        ?.replace('?', '%3F')
        ?.replace('%', '%25'),
      refFilter: toolbarParams?.refFilter
    });
  }, [toolbarParams]);

  const handleChange = prop => event => {
    const isDisabled = event.target.value < MIN_SEARCH_LENGTH;
    setIsDisabledSearchButton(isDisabled);
    setIsDisabledDeleteButton(isDisabled);
    setSearch({
      ...search,
      [prop]: event.target.value?.replace('?', '%3F')?.replace('%', '%25')
    });
    if (
      history.location.pathname === ROUTES_CONSTANTS.vitrineMediatheque &&
      history.location.pathname === `${ROUTES_CONSTANTS.vitrineMediatheque}/`
    ) {
      const newParams = {
        text: event.target.value,
        type: SEARCH_TYPES.program,
        cursor: initial,
        page: 1,
        size: 100
      };
      fetchSearchResults(newParams);
    }
  };
  /*
  const handleAutocompleteChange = (e, value) => {
    setSearch({
      ...search,
      inputSearch: value?.replace('?', '%3F')?.replace('%', '%25')
    });
  }; */

  const handleSearch = () => {
    if (checkVitrine(history)) {
      history.push(
        `${ROUTES_CONSTANTS.vitrineMediatheque}/${search.inputSearch}`
      );
    } else if (!toolbarParams.filterDisabled) {
      history.push(
        `${ROUTES_CONSTANTS.searchBase}${search.inputSearch}/${search.refFilter}`
      );
    } else {
      const section = sections?.find(
        sec => sec.refSection === search.refFilter
      );
      if (section?.thematique) {
        history.push(
          `${ROUTES_CONSTANTS.categoriesSearchBase}${section?.refSection}/${search.inputSearch}`
        );
      } else {
        history.push(
          `${ROUTES_CONSTANTS.programDetailLink}${section?.refSection}/${search.inputSearch}`
        );
      }
    }
  };

  const handleKeyPress = e => {
    if (e.key === KEYS.enter && !isDisabledSearchButton) {
      handleSearch();
    }
  };

  const isFilterShowed = () => {
    return bigScreenResolution && !checkVitrine(history);
  };
  const inputAdornment = (
    <>
      <InputAdornment position="end">
        <IconButton
          id="deleteIcon"
          onClick={handleDelete}
          disabled={isDisabledSearchButton}
        >
          <ClearIcon />
        </IconButton>
      </InputAdornment>
      <InputAdornment position="end">
        <IconButton
          id="searchIcon"
          onClick={handleSearch}
          disabled={isDisabledDeleteButton}
        >
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    </>
  );

  return (
    <div className="searchInput">
      {isFilterShowed() && (
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="simple-select-filled-label"
            id="selectFilter"
            onChange={handleChange('refFilter')}
            value={search.refFilter}
            disabled={toolbarParams?.filterDisabled}
            autoWidth
          >
            <MenuItem value={texts.homeBannerContainer.refAll}>
              {texts.homeBannerContainer.selectionText}
            </MenuItem>
            {sections?.map(section => {
              if (section?.urlSection || section?.url) {
                return (
                  <MenuItem value={section.refSection} key={section.refSection}>
                    {section.shortLabel || section.name}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Select>
        </FormControl>
      )}
      <FormControl className={classes.textField} variant="outlined">
        <Autocomplete
          freeSolo
          id="searchInputAutoComplete"
          disableClearable
          options={suggestions}
          value={search?.inputSearch}
          renderInput={params => (
            <>
              <TextField
                {...params}
                id="searchInput"
                label={texts.homeBannerContainer.searchButton}
                type="text"
                variant="outlined"
                onKeyPress={handleKeyPress}
                onChange={handleChange('inputSearch')}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: inputAdornment,
                  labelWidth: 70
                }}
              />
            </>
          )}
        />
      </FormControl>
    </div>
  );
};

SearchInput.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  onSearchChange: PropTypes.func,
  history: PropTypes.func,
  toolbarParams: PropTypes.oneOfType([PropTypes.object]),
  suggestions: PropTypes.arrayOf(PropTypes.string),
  fetchSuggestions: PropTypes.func,
  fetchSearchResults: PropTypes.func,
  deleteSearch: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  sections: selectSectionsResult,
  toolbarParams: selectToolbarParams,
  suggestions: selectSearchSuggestions
});

const mapDispatchToProps = dispatch => ({
  fetchSuggestions: params => dispatch(fetchSearchSuggestionsStart(params)),
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchInput)
);
