import DeleteTypes from './partner-delete.types';

export const sendPartnerDeleteDataStart = data => ({
  type: DeleteTypes.SEND_PARTNER_DELETE_DATA_START,
  payload: data
});

export const sendPartnerDeleteDataSuccess = partnerUrl => ({
  type: DeleteTypes.SEND_PARTNER_DELETE_DATA_SUCCESS,
  payload: partnerUrl
});

export const sendPartnerDeleteDataFailure = error => ({
  type: DeleteTypes.SEND_PARTNER_DELETE_DATA_FAILURE,
  payload: error
});

export const sendPartnerDeleteDataFinished = () => ({
  type: DeleteTypes.SEND_PARTNER_DELETE_DATA_FINISHED
});
