import VisualProgramActionTypes from './visual-program.types';

export const fetchVisualProgramStart = refProgram => ({
  type: VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_START,
  payload: refProgram
});

export const fetchVisualProgramSuccess = data => ({
  type: VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_SUCCESS,
  payload: data
});

export const fetchVisualProgramFailure = error => ({
  type: VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_FAILURE,
  payload: error
});

export const fetchVisualProgramFinish = () => ({
  type: VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_FINISH
});
