import ProgramModify from './programModificationTypes.js';

export const updateProgramNameStart = programName => ({
  type: ProgramModify.UPDATE_PROGRAM_NAME_START,
  payload: programName
});

export const updateProgramNameSuccess = message => ({
  type: ProgramModify.UPDATE_PROGRAM_NAME_SUCCESS,
  payload: message
});

export const updateProgramNameFailure = error => ({
  type: ProgramModify.UPDATE_PROGRAM_NAME_FAILURE,
  payload: error
});

export const updateProgramNameFinished = () => ({
  type: ProgramModify.UPDATE_PROGRAM_NAME_FINISHED
});
