import { createSelector } from 'reselect';

const selectThemeList = state => state.themeList;

export const selectThemeListResult = createSelector(
  [selectThemeList],
  selectTheme => selectTheme.results.medias
);

export const selectThemeListIsLoading = createSelector(
  [selectThemeList],
  selectTheme => selectTheme.isLoading
);

export const selectThemeListPagination = createSelector(
  [selectThemeList],
  selectTheme => selectTheme.results.page
);

export const selectThemeListError = createSelector(
  [selectThemeList],
  selectTheme => selectTheme.error
);
