import { put, all, call, takeLatest } from 'redux-saga/effects';
import ModificationTypes from './partner-modification.types';
import api from '../../api/apiIntervenants.jsx';
import {
  sendModificationPartnerDataSuccess,
  sendModificationPartnerDataFailure,
  sendModificationPartnerDataFinished
} from './partner-modification.actions';

export function* sendModificationPartner({ payload: partner }) {
  try {
    const data = yield call(api.patchModificationPartners, partner);
    yield put(sendModificationPartnerDataSuccess(data));
  } catch (error) {
    yield put(sendModificationPartnerDataFailure(error));
  }
  yield put(sendModificationPartnerDataFinished());
}

export function* onSendModificationPartner() {
  yield takeLatest(
    ModificationTypes.SEND_MODIFICATION_PARTNER_DATA_START,
    sendModificationPartner
  );
}

export function* modificationPartnerSagas() {
  yield all([call(onSendModificationPartner)]);
}
