import { put, all, call, takeLatest } from 'redux-saga/effects';
import userInfoActionTypes from './user-info.types';
import { userInfoBuilder } from './user-info.builder';
import api from '../../api/api.jsx';
import {
  fetchUserInfoSuccess,
  fetchUserInfoFailure,
  fetchUserInfoFinished
} from './user-info.actions';

export function* fetchUserInfo({ payload: refmedia }) {
  try {
    const { data } = yield call(api.userInfo, refmedia);
    const dataBuilded = yield call(userInfoBuilder, data);
    yield put(fetchUserInfoSuccess(dataBuilded));
  } catch (error) {
    yield put(fetchUserInfoFailure(error));
  }
  yield put(fetchUserInfoFinished());
}

export function* onFetchUserInfoStart() {
  yield takeLatest(userInfoActionTypes.FETCH_USER_INFO_START, fetchUserInfo);
}

export function* userInfoSagas() {
  yield all([call(onFetchUserInfoStart)]);
}
