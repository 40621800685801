import { SEARCH_PAGE_SIZE } from '../../constants/global-constants';
import { formatFilter } from '../functions/filter';

const queryBuilder = params => {
  let filterPart = '';
  let cursor = `cursor=initial`;
  let sectionPart = '';
  let queryPart = '';
  let typePart = '';
  let sizePart = '';
  let withMediaPart = '';
  let myProgramsPart = '';
  let withCoordinates = '';
  if (params?.size) {
    sizePart = `&size=${params?.size}`;
  } else {
    sizePart = `&size=${SEARCH_PAGE_SIZE}`;
  }
  if (params?.filters) {
    filterPart = `&filters=${formatFilter(params?.filters)}`;
  }
  if (params?.cursor) {
    cursor = `cursor=${params?.cursor}`;
  }

  if (params?.section) {
    sectionPart = `&section=${params?.section}`;
  }

  if (params?.type) {
    typePart = `&type=${params?.type}`;
  }

  if (params?.text) {
    queryPart = `&query=${encodeURIComponent(params?.text)}`;
  }

  if (params?.withMedia) {
    withMediaPart = `&withMedia=${params?.withMedia}`;
  }

  if (params?.myPrograms) {
    myProgramsPart = `&myPrograms=${params?.myPrograms}`;
  }
  if (params?.withCoordinates) {
    withCoordinates = `&withCoordinates=${params?.withCoordinates}`;
  }
  return `${cursor}${sizePart}${queryPart}${sectionPart}${filterPart}${typePart}${withMediaPart}${myProgramsPart}${withCoordinates}`;
};

export default queryBuilder;
