import urljoin from 'url-join';
import { INTERFACES } from '../constants/global-constants';

let envVariables = {};

if (global.window?.env?.ENVIRONMENT === '#{ENVIRONMENT}#') {
  /* eslint-disable */
  envVariables = require(`../../.env.${process.env.ENVIRONMENT}.js`);
} else {
  envVariables = {
    env: global.window?.env?.ENVIRONMENT,
    API_ROOT:
      global.window?.env?.INTERFACE === INTERFACES.PARTNER
        ? global.window?.env?.API_URL_EXTERNAL
        : global.window?.env?.API_URL_INTERNAL,
    NOMENCLATURE_API_URI:
      global.window?.env?.INTERFACE === INTERFACES.PARTNER
        ? urljoin(global.window?.env?.API_URL_EXTERNAL, '/nom')
        : global.window?.env?.NOMENCLATURE_API_URI,
    INTERVENANTS_API_ROOT: global.window?.env?.INTERVENANTS_API_ROOT,
    STRUCTURE_API_ROOT:
      global.window?.env?.INTERFACE === INTERFACES.PARTNER
        ? urljoin(global.window?.env?.API_URL_EXTERNAL, '/org')
        : global.window?.env?.STRUCTURE_API_ROOT,
    API_GOV: global.window?.env?.API_GOV,
    CLEO_WS_URI_HTTPS: global.window?.env?.CLEO_WS_URI_HTTPS,
    CLEO_WS_CUSTOMER_ID: global.window?.env?.CLEO_WS_CUSTOMER_ID,
    CLEO_WS_CUSTOMER_KEY: global.window?.env?.CLEO_WS_CUSTOMER_KEY,
    CPI_ACTIVE: global.window?.env?.CPI_ACTIVE,
    CPI_WS_URI: global.window?.env?.CPI_WS_URI,
    INTERFACE: global.window?.env?.INTERFACE,
    KEYCLOAK_ROOT_URL: global.window?.env?.KEYCLOAK_ROOT_URL,
    KEYCLOAK_REALM_APP: global.window?.env?.KEYCLOAK_REALM_APP,
    KEYCLOAK_FRONT_ID: global.window?.env?.KEYCLOAK_FRONT_ID,
    KEYCLOAK_TOKEN_URL: global.window?.env?.KEYCLOAK_TOKEN_URL,
    REACT_APP_GOOGLE_KEY: global.window?.env?.REACT_APP_GOOGLE_KEY,
    API_CPI: global.window?.env?.API_CPI,
    API_TLC: global.window?.env?.API_TLC,
    API_PLG: global.window?.env?.API_PLG
  };
}

export const {
  API_ROOT,
  NOMENCLATURE_API_URI,
  INTERVENANTS_API_ROOT,
  STRUCTURE_API_ROOT,
  API_GOV,
  CLEO_WS_URI_HTTPS,
  CLEO_WS_CUSTOMER_ID,
  CLEO_WS_CUSTOMER_KEY,
  INTERFACE,
  KEYCLOAK_ROOT_URL,
  KEYCLOAK_REALM_APP,
  KEYCLOAK_FRONT_ID,
  CPI_ACTIVE,
  CPI_WS_URI,
  KEYCLOAK_TOKEN_URL,
  REACT_APP_GOOGLE_KEY,
  API_CPI,
  API_TLC,
  API_PLG
} = envVariables;
