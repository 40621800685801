import PostLegalEntity from './postLegalEntity.types.js';

export const postLegalEntityStart = data => ({
  type: PostLegalEntity.POSTLEGALENTITY_START,
  payload: data
});

export const postLegalEntitySuccess = data => ({
  type: PostLegalEntity.POSTLEGALENTITY_SUCCESS,
  payload: data
});

export const postLegalEntityFailure = error => ({
  type: PostLegalEntity.POSTLEGALENTITY_FAILURE,
  payload: error
});

export const postLegalEntityFinished = () => ({
  type: PostLegalEntity.POSTLEGALENTITY_FINISHED
});
