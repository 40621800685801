import DeleteSectionTypes from './deleteSectionTypes';

export const DeleteSectionStart = params => {
  return {
    type: DeleteSectionTypes.DELETE_SECTION_START,
    payload: params
  };
};

export const DeleteSectionSuccess = () => {
  return {
    type: DeleteSectionTypes.DELETE_SECTION_SUCCESS
  };
};

export const DeleteSectionFailure = error => {
  return {
    type: DeleteSectionTypes.DELETE_SECTION_FAILURE,
    payload: error
  };
};

export const DeleteSectionFinish = () => {
  return {
    type: DeleteSectionTypes.DELETE_SECTION_FINISH
  };
};
