export default {
  playIcon: {
    height: '200px',
    width: '200px',
    fill: 'white'
  },
  button: {
    width: '180px',
    marginLeft: '50px',
    marginTop: '20px'
  },
  buttonText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    color: '#FFFFFF'
  },
  cardIconGroup: {
    zIndex: '1',
    position: 'relative',
    float: 'left',
    height: '0px',
    padding: '0'
  },
  cardIcon: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    padding: '2px',
    margin: '6px',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#C82832'
    }
  },
  listBox: {
    width: 488,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white'
    }
  },
  deleteIcon: {
    padding: '2px',
    margin: '6px',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#C82832'
    }
  },
  deleteIconDissabled: {
    padding: '2px',
    margin: '6px',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#C82832'
    },
    color: '#4E5360'
  },
  tooltip: {
    fontSize: '20px'
  }
};
