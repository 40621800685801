import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PDFReader } from 'reactjs-pdf-view';

import styles from './style.jsx';
import { pdfFormat } from '../../constants/global-constants';
import './style.scss';

const SearchMediaCard = ({ media, loading, thumbnail }) => {
  const style = makeStyles(styles)();
  return (
    <>
      <Card className={style.card} key={media?.refMedia}>
        <CardActionArea>
          {loading ? (
            <CardContent className={style.cardContentLoader}>
              <CircularProgress />
            </CardContent>
          ) : (
            <>
              {media?.format === pdfFormat ? (
                <>
                  <PDFReader url={media?.thumbnailUrl} scale={0.33} />
                </>
              ) : (
                <>
                  <CardMedia
                    crossOrigin="anonymous"
                    component="img"
                    alt=""
                    height="220"
                    image={thumbnail}
                    title={media?.name}
                  />
                </>
              )}
            </>
          )}
          <CardContent className={style.cardContent}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={`${style.title} card__program`}
                  gutterBottom
                >
                  {media?.programName || media?.category}
                </Typography>
                {media?.city?.name && (
                  <Typography className={style.subTitle} gutterBottom>
                    {media?.city?.name}
                  </Typography>
                )}
              </Grid>
              <Grid className={style.icons} item xs={12} />
              <Grid className={style.icons} item xs={12}>
                <>
                  <div className={style.icon}>
                    <span className="section">
                      {media?.programName
                        ? media?.category
                        : media?.section?.name}
                    </span>
                  </div>
                </>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

SearchMediaCard.propTypes = {
  media: PropTypes.oneOfType([PropTypes.string]),
  loading: PropTypes.bool,
  thumbnail: PropTypes.string
};

export default SearchMediaCard;
