import { put, all, call, takeLatest } from 'redux-saga/effects';
import DeleteSectionTypes from './deleteSectionTypes';
import apiNomenclature from '../../../api/apiNomenclature';
import {
  DeleteSectionSuccess,
  DeleteSectionFailure,
  DeleteSectionFinish
} from './deleteSectionActions';

export function* deleteSection({ payload: params }) {
  try {
    yield call(apiNomenclature.postDeactivate, params);
    yield put(DeleteSectionSuccess());
  } catch (error) {
    yield put(DeleteSectionFailure(error.response?.data));
  }
  yield put(DeleteSectionFinish());
}

export function* onDeleteSectionStart() {
  yield takeLatest(DeleteSectionTypes.DELETE_SECTION_START, deleteSection);
}

export function* deleteSectionSagas() {
  yield all([call(onDeleteSectionStart)]);
}
