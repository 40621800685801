import { createSelector } from 'reselect';

const selectSearchCopy = state => state.searchCopy;

export const selectSearchCopyResults = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy.searchCopyResults
);

export const selectSearchCopyIsLoading = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy.isLoading
);

export const selectSearchCopyResultsWithMedia = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy.searchCopyResultsWithMedia
);

export const selectSearchCopyWithMediaIsLoading = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy.withMediaIsLoading
);

export const selectSearchCopyResultsWithoutMedia = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy.searchCopyResultsWithoutMedia
);

export const selectSearchCopyWithoutMediaIsLoading = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy.withoutMediaIsLoading
);

export const selectSearchCopyToolbarVisible = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy?.toolbarParams?.isVisible
);

export const selectSearchCopySuggestions = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy.suggestions
);

export const selectToolbarParams = createSelector(
  [selectSearchCopy],
  searchCopy => searchCopy.toolbarParams
);
