// ES6 module syntax
import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  fr: {
    general: {
      plural: 's',
      singular: ''
    },
    header: {
      home: 'Accueil',
      about: 'À propos',
      users: 'Utilisateurs',
      menuTitle: 'MÉDIATHÈQUE',
      collection: 'Ma sélection',
      welcome: 'bonjour {userName}',
      logout: 'Déconnexion'
    },
    footer: {
      copyright: 'Conditions générales du site',
      help: 'Besoin d’aide ? - FAQ',
      admin: 'Administration'
    },
    counter: {
      numbers: 'Numbers: {number}'
    },
    listCategories: {
      title: 'rechercher par catégories'
    },
    lastMedia: {
      title: 'LES DERNIERS MÉDIAS CHARGÉS DANS LA BASE'
    },
    homeBannerContainer: {
      title:
        'Ajoutez, recherchez, partagez, plus de  {numberMedias} médias Nexity',
      search: 'Rechercher un média',
      addButton: 'Ajouter un média',
      searchButton: 'Rechercher',
      selectionText: 'Toute la base',
      refAll: 'toute'
    },
    fileUploader: {
      textAreaDescription:
        'Glissez vos médias ici ou sélectionnez des fichiers',
      fileAddedMessage: 'Le fichier {fileName} a été ajouté avec succès.',
      fileLimitExceedMessage:
        'Vous avez dépassé le nombre maximum autorisé de fichiers. Seulement {filesLimit} permis.',
      fileRemovedMessage: 'Le fichier {fileName} a été supprimé.',
      dropRejectMessage: 'Le fichier {fileName} a été rejeté.',
      dropIncorrectFormatMessage:
        "Le format du fichier {fileName} n'est pas accepté." +
        'Seuls les fichiers au format suivant {formatList} sont acceptés',
      mediaResolutionPreference:
        'Pour les images, préférez les fichiers tiff de haute résolution (3000*2000)',
      title: 'ajouter un média',
      forbiddenAction: "Cette action n'est pas autorisée",
      uniqueFileWithNoSizeMinimun:
        "Le fichier {fileWithError} n'a pas la taille minimale autorisée. ",
      multipleFileWithNoSizeMinimun:
        'Il existe plusieurs fichiers sans la taille minimale'
    },
    singleFileUpload: {
      erreurTemp:
        'Chargement de le fichier {fileName} en échec, veuillez recharger votre média ou contacter le support'
    },
    noMediaCard: {
      buttonAjounterMedia: 'Ajouter un média'
    },
    programList: {
      titleNoMedia: 'Nouveau programme immobilier ans média',
      buttonShowMore: 'Voir plus de programmes',
      buttonShowMoreMedia: 'Voir plus de médias',
      titlePrograms: 'vos programmes immobiliers',
      hideNoMediaPrograms: 'voir moins',
      showMoreNoMediaPrograms: 'voir plus'
    },
    resultsSearchPage: {
      programs: 'Programmes',
      titleProgramWithoutMedia: 'Programmes immobiliers sans média',
      mediaIsusCategoryTitle: 'Media issus d’un programme',
      sansMedia: 'sans Média',
      titlePage: 'Résultat pour',
      results: 'résultats',
      noResults: "Il n'y a pas de résultat correspondant à votre recherche!"
    },
    programSheet: {
      programModification: 'Modifier le programme',
      programAd: 'Ajouter un média',
      mediaOrganiser: 'Réorganiser les médias',
      alertMessagePlural: ' Medias ajoutés avec succès',
      alertMessage: ' Media ajouté avec succès',
      return: 'Retour',
      alertCheckbox: 'Média ajouté a Ma selection',
      disclaimerTitle: 'Attention !',
      disclaimerBodyPartOne:
        'Cochez les visuels que vous souhaitez envoyer sur nexity.fr et les portails immobiliers. Nombre limité à 10 images et 2 vidéos.',
      disclaimerBodyPartTwo:
        ' Les 4 premières images cochées seront envoyées par défaut à Cléo (alimentation de Mon Book Immo)',
      disclaimerAvenir:
        'Pour publier vos médias merci de vous rendre sur le catalogue de lots Avenir'
    },
    programExternalInfo: {
      activitePrincipal: 'Activité principal',
      filiale: 'Filiale',
      adresse: 'Adresse',
      lots: 'Lots',
      blocs: 'Blocs',
      surface: 'Surface plancher',
      timeline: {
        comiteAcquisition: "Comité d'acquisition",
        comiteEngagement: "Comité d'engagement",
        demarrageTravaux: 'Démarrage travaux',
        prochainLancement: 'Prochain lancement commercial',
        prochainLivraison: 'Prochaine Livraison'
      },
      performance: 'Perf. énergitique et carbone',
      metre: ' m2',
      noInformation: 'Pas d’information disponible pour ce programme '
    },
    mediaGallery: {
      programModification: 'Créer un nouveau dossier',
      create: 'Créer',
      nameFolder: 'Titre du dossier',
      textCheckbox1:
        'Pour publier vos médias merci de vous rendre sur le catalogue de lots Avenir',
      textCheckbox2: null
    },
    programListPage: {
      titleProgramWithoutMedia:
        '{myPrograms} programmes immobiliers sans média',
      titleProgramWithMedia: '{myPrograms} programmes immobiliers avec média',
      results: 'résultats',
      result: 'résultat',
      creationIEProgram: 'Créer un programme',
      showMore: 'voir tous les programmes',
      showMes: 'voir mes programmes',
      noMedia: 'Pas de médias sur cette thématique',
      myPrograms: 'Gestion des résultats',
      seeMyProgram: 'Voir tous les programmes',
      mine: 'mes',
      all: 'tous les'
    },
    carousel: {
      mediaState: 'Média{plural} {selected} sur {total}',
      fileName: 'Nom du fichier : {name}',
      date: 'Date de chargement : le {date}',
      by: 'Par : {author}',
      resolution: 'Résolution: {resolution}',
      weight: 'Poids: {weight}',
      format: 'Format: {format}',
      uploadDate: 'Date d’upload: {date}',
      userInfo: 'Par {user} ({filial})',
      addTag: 'Ajouter un tag',
      addCopyright: 'Saisir le copyright',
      author: 'Auteur',
      addUseDuration: 'Ajouter une durée d’utilisation',
      deleteUseDuration: 'Supprimer la durée d’utilisation',
      useUtilisationHelp:
        'le média est par défaut utilisable indéfiniment sur tous supports',
      support: 'Support:',
      supportNumber: {
        first: '1',
        second: '2',
        third: '3'
      },
      web: 'Web',
      print: 'Impression',
      both: 'Les deux',
      selectSubCategory: 'Définir la sous-catégorie',
      selectCategory: 'Définir la catégorie *',
      errorMessageDatePicker: 'Date invalide',
      loadingResolution: 'En cours',
      pdfTitle: 'Titre du pdf'
    },
    optionCardButtons: {
      fullScreen: 'Plein écran',
      resize: 'Recadrer',
      copy: 'Copier',
      addToSelection: 'Ajouter à la sélection',
      share: 'Partager',
      dowloadButton: 'Télécharger',
      plus: '+',
      plusText: 'Voir plus de média',
      modification: 'Modifier',
      suppression: 'Supprimer '
    },
    visualProgram: {
      format: 'Format :',
      adresse: 'Adresse :',
      subsidiary: 'Filiale :',
      placeholderSelect: 'Sélectionner',
      placeholderResolutionSelect: 'Résolution',
      placeholderFormatSelect: 'Format',
      emptyList: 'La liste est vide',
      emptyFilter: 'emptyFilter',
      attachCopyrigth: 'Ajouter le copyright',
      title: 'Vidéo du programme',
      add: 'Ajouté le :',
      by: 'Par :',
      change: 'Modifié le :',
      url: 'URL de la vidéo :',
      downloadButton: 'Télécharger',
      addButton: 'Ajouter à la sélection',
      shareButton: 'Partager',
      addedMediaToMySelection: 'Média ajouté',
      errorAddMediaToMySelection: 'Le média existe deja dans votre sélection',
      errorAddMediaToMySelectionMode:
        'La sélection est limité à 10 images et 2 vidéos.',
      subCategory: 'Sous-catégorie :',
      copyright: 'Copyright :',
      questionDialog: 'Etes-vous sûr de vouloir supprimer ce média?',
      textButtonOk: 'Valider',
      textButtonCancel: 'Annuler',
      video: 'Video',
      endOfUse: 'Fin d’utilisation le',
      mediaUseExpired: 'Droit expiré',
      titleFile: 'Nom PDF: '
    },
    mediaModification: {
      title: 'Modifier un média',
      changerMedia: 'Changer le média',
      changerPhoto: 'Changer la photo',
      changerVideo: 'Changer la video',
      delete: 'Supprimer le média',
      tooltipDelete: 'Merci de dépublier ce média afin de pouvoir le supprimer'
    },
    noResults: 'Pas de résultats',
    close: 'x',
    mySelection: {
      mySelection: 'Ma sélection',
      totalMedia: '{numMedias} média',
      totalMedias: '{numMedias} médias'
    },
    mySelectionContact: {
      shareTheSelection: 'Partager la sélection',
      attachAddressee: '+ Ajouter un destinataire',
      send: 'Envoyer',
      emailLabel: 'Email',
      lastNameLabel: 'Nom',
      nameLabel: 'Prénom',
      errorEmail: "l'adresse email n'est pas valide",
      sendOk: `Médias envoyés avec succès`,
      sendFail: `L'envoi de votre média a échoué, nous vous invitons à faire une nouvelle tentative. Si le problème persiste, merci de contacter le support`
    },
    mySelectionItems: {
      placeholderAttachMore: 'Continuer à ajouter des médias',
      dowloadSelection: 'Télécharger la sélection',
      shareSelection: 'Partager la sélection',
      validateSelection: 'Recuperer la sélection'
    },
    filter: {
      title: 'Affiner votre recherche',
      noFilter: 'Pas de filtres disponibles',
      more: 'Plus',
      less: 'Moins'
    },
    next: 'Suivant',
    finish: 'Terminer',
    save: 'Sauvegarder',
    cancel: 'Annuler',
    space: ' ',
    createProgram: {
      createProgramIE: 'CRÉER UN PROGRAMME IE',
      modifyProgramIE: 'MODIFIER UN PROGRAMME IE',
      programName: 'Nom du programme* :',
      programType: 'Type de projet* :',
      destinationProperty: 'Destination du bien:',
      year: 'Année* :',
      region: 'Région* :',
      department: 'Département* :',
      city: 'Ville* :',
      address: 'Adresse* :',
      yearError: 'L’année saisie n’est pas valide'
    },
    copyPasteModal: {
      title: 'Emplacement pour coller le média',
      success: 'Image collée avec succès dans le programme',
      successButton: 'Voir le programme',
      paste: 'Coller dans ce programme',
      warningMessage: 'Le média se placera en dernière position',
      return: 'Retour',
      alertMessage:
        "Attention, en copiant/collant ce visuel dans votre programme, vous générez un nouveau visuel, si le média d'origine est modifié, votre version ne sera pas modifiée"
    },
    mediaOrder: {
      changeOrder: 'Changer l’ordre de la photo',
      saveOrder: 'Figer les positions',
      titlePage: 'organiser les médias'
    },
    Administration: {
      title: 'Administration',
      gestionRights: 'Gestion des droits utilisateurs',
      gestionCategories: 'Gestion des thématiques et catégories',
      gestionProgrammesIR: 'Créer un programme IR',
      messageErrorData: 'Données manquantes à compléter',
      messageErrorEmail: "L'email n'est pas valide",
      messageErrorPhone: "Le téléphone n'est pas valide"
    },
    AdminCategories: {
      title: 'Gestion des catégories',
      vignetteLabel: 'Image de couverture:',
      editImage: "Modifier l'image",
      addImage: 'Ajouter une image',
      deleteSection: 'Supprimer la section',
      addSection: 'Ajouter section',
      newSection: 'Nouvelle section',
      alertMessage: 'Etes-vous sûr de vouloir supprimer cette section ?',
      refNewSection: 'newSection',
      editedSection: 'Section modifiée',
      goToSubcategories: 'Voir les sous-catégories',
      goToCategories: 'Retour aux catégories',
      setPositions: 'Figer les positions',
      saveCategory: 'Enregistrer la catégorie',
      saveSubcategory: 'Enregistrer la sous-catégorie',
      edit: 'Modifier les catégories',
      editSubcategories: 'Modifier les sous-catégories',
      modifyNames: 'Modifier les names',
      createNewCategory: '+ Créer une nouvelle catégorie',
      createNewSubcategory: '+ Ajouter une sous-catégorie',
      createdSection: 'Section créée',
      deleteSectionMedias:
        'Impossible de supprimer une section qui a au moins un média associé'
    },
    AdminRights: {
      title: "Gestion d'accès partenaires",
      addText: 'Créer un utilisateur',
      editText: 'Editer',
      deleteText: 'Supprimer',
      search: 'Rechercher',
      firstPage: 'Première page',
      lastPage: 'Dernière page',
      nextPage: 'Page suivante',
      previousPage: 'Page précédente',
      rows: 'Lignes',
      displayedRows: '{from}-{to} de {count}',
      noData: "Il n'y a pas d'utilisateur",
      nameSociety: 'Nom de la societé',
      citySociety: 'Ville',
      typeSociety: 'Type',
      name: 'Prenom',
      surname: 'Nom',
      email: 'Email',
      phoneNumber: 'Teléphone',
      subsidiary: 'Filiale',
      society: 'Societé',
      deleteQuestion: 'Etes-vous sûr de vouloir supprimer cet utilisateur?'
    },
    Vitrine: {
      title: 'Vitrine Médiathèque',
      map: 'Carte',
      resultList: 'Liste des résultats'
    },
    download: {
      messageError:
        'Le téléchargement a échoué, nous vous invitons à faire une nouvelle tentative. Si le problème persiste, merci de contacter le support'
    },
    adminBase: {
      userManagement: 'Gestion des accès partenaires',
      categoryManagement: 'Gestion des thématiques et catégories',
      programCreation: 'Créer un programme IR',
      title: 'Administration'
    },
    addMediaPage: {
      selection: 'Sélectionnez un emplacement'
    },
    deleteButton: 'Supprimer',
    selectButton: 'Sélectionner',
    errorPage: {
      titleError: 'Erreur {errorNumber}',
      error403Message: `Vous n'êtes pas habilité à utiliser l'application. 
      Merci de contacter votre responsable ou créer un ticket sur `,
      error403Link: 'Kiosque SI',
      errorNetwork:
        'Une erreur technique est survenue, merci de réessayer ou contacter le support'
    },
    mediaStatusError: {
      transformationError:
        'Un problème est survenu. Veuillez recharger votre média.',
      transformationEncours:
        'Le process de chargement de votre média est en cours, merci de patienter quelques minutes.'
    },
    counterTextArea: {
      caracterRestant: '{numCaracter} caractère restant',
      caracteresRestants: '{numCaracter} caractères restants'
    }
  }
});
