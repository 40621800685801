const SectionTypes = {
  FETCH_SECTION_START: 'FETCH_SECTION_START',
  FETCH_SECTION_SUCCESS: 'FETCH_SECTION_SUCCESS',
  FETCH_SECTION_FAILURE: 'FETCH_SECTION_FAILURE',
  FETCH_SECTION_FINISHED: 'FETCH_SECTION_FINISHED',

  GET_SECTION_THUMBNAIL_LINK_START: 'GET_SECTION_THUMBNAIL_LINK_START',
  GET_SECTION_THUMBNAIL_LINK_SUCCESS: 'GET_SECTION_THUMBNAIL_LINK_SUCCESS',
  GET_SECTION_THUMBNAIL_LINK_FAILURE: 'GET_SECTION_THUMBNAIL_LINK_FAILURE',
  GET_SECTION_THUMBNAIL_LINK_FINISHED: 'GET_SECTION_THUMBNAIL_LINK_FINISHED'
};

export default SectionTypes;
