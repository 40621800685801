import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import './sortableCategories.scss';

const SortableCategories = SortableContainer(
  ({ printCategoriesAndSubcategories, items = [] }) => {
    const SortableItem = SortableElement(({ value }) =>
      printCategoriesAndSubcategories(value)
    );

    return (
      <div>
        {items.map((value, index) => (
          <>
            <SortableItem index={index} value={value} />
          </>
        ))}
      </div>
    );
  }
);

export default SortableCategories;
