/* eslint-disable */
const internalUserBuilder = internalMember => {
  return {
    email: internalMember?.Email,
    lastName: internalMember?.LastName,
    firstName: internalMember?.FirstName,
    showNameAndEmail: `${internalMember?.FirstName} - ${internalMember?.Email}`,
    showLastNameAndEmail: `${internalMember?.LastName} - ${internalMember?.Email}`,
    filialName: internalMember?.LibelleEstablishment
  };
};

const externalUserBuilder = externalMember => {
  return {
    email: externalMember?.contactEmail,
    lastName: externalMember?.lastName,
    firstName: externalMember?.firstName,
    showNameAndEmail: `${externalMember?.firstName} - ${externalMember?.contactEmail}`,
    showLastNameAndEmail: `${externalMember?.lastName} - ${externalMember?.contactEmail}`
  };
};

function internalUsersListBuilder(internalUsersList) {
  return internalUsersList?.map(internalMember => {
    return internalUserBuilder(internalMember);
  });
}

function externalUsersListBuilder(externalUsersList) {
  const newArray = [];
  // eslint-disable-next-line no-unused-expressions
  externalUsersList?.map(externalMember => {
    if (externalMember?.type === 'contact') {
      const externalBuilded = externalUserBuilder(externalMember);
      newArray.push(externalBuilded);
    }
  });
  return newArray;
}

export function internalUsersDataBuilder(internalUsersList) {
  return internalUsersListBuilder(internalUsersList?.Items);
}

export function externalUsersDataBuilder(externalUsersList) {
  return externalUsersListBuilder(externalUsersList?.result);
}
