import { put, all, call, takeLatest } from 'redux-saga/effects';
import PublishUnpublishMedias from './publishAndUnpublishMedias.types.js';
import api from '../../api/api.jsx';
import {
  publishCheckedMediaSuccess,
  publishCheckedMediaFailure,
  publishCheckedMediaFinished,
  unpublishCheckedMediaSuccess,
  unpublishCheckedMediaFailure,
  unpublishCheckedMediaFinished
} from './publishAndUnpublishMedias.actions';

export function* publishMedias({ payload: refMedia }) {
  try {
    const { data } = yield call(api.publishMedia, refMedia);
    yield put(publishCheckedMediaSuccess(data));
  } catch (error) {
    yield put(publishCheckedMediaFailure(error));
  }
  yield put(publishCheckedMediaFinished());
}

export function* unpublishMedias({ payload: refMedia }) {
  try {
    const { data } = yield call(api.unpublishMedia, refMedia);
    yield put(unpublishCheckedMediaSuccess(data));
  } catch (error) {
    yield put(unpublishCheckedMediaFailure(error));
  }
  yield put(unpublishCheckedMediaFinished());
}

export function* onPublishMedias() {
  yield takeLatest(
    PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_START,
    publishMedias
  );
}

export function* onUnpublishMedias() {
  yield takeLatest(
    PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_START,
    unpublishMedias
  );
}

export function* publishUnpublishSagas() {
  yield all([call(onPublishMedias), call(onUnpublishMedias)]);
}
