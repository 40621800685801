const VERSION = {
  version1: 'v1'
};

export const PATH = {
  organizationalEntitySearch: '/organizationalEntity/search',
  organizationalEntity: '/organizationalEntity'
};

const API_ROUTES_STRUCTURE = {
  organizationalEntitySearch: `${VERSION.version1}${PATH.organizationalEntitySearch}`,
  organizationalEntity: `${VERSION.version1}${PATH.organizationalEntity}`
};

export default API_ROUTES_STRUCTURE;
