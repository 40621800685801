import { createSelector } from 'reselect';

const selectDeleteSection = state => state.deleteSection;

export const selectDeletedSection = createSelector(
  [selectDeleteSection],
  deleteSection => deleteSection.deleted
);

export const selectDeleteSectionLoading = createSelector(
  [selectDeleteSection],
  deleteSection => deleteSection.isLoading
);

export const selectDeleteSectionError = createSelector(
  [selectDeleteSection],
  deleteSection => deleteSection.error
);
