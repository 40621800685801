import { LinearProgress, Box, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../../api/api.jsx';
import styles from './style.jsx';
import t from '../../../constants/translates';

const SingleFileUploadWithProgress = ({ file, onUpload, onTimedOut }) => {
  const [progress, setProgress] = useState(0);
  const [timedOut, setTimedOut] = useState(false);
  const style = makeStyles(styles)();
  useEffect(() => {
    const config = {
      onUploadProgress(progressEvent) {
        const timer = setTimeout(() => {
          setTimedOut(true);
          onTimedOut();
        }, 30000);
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted <= 100 && percentCompleted >= progress) {
          setProgress(percentCompleted);
          clearTimeout(timer);
        }
        if (percentCompleted >= 100) {
          onUpload();
        }
      }
    };

    api.putS3config(file.url, file.inputFile.file, config);
  }, []);

  const isErrorUpload = () => {
    if (timedOut && progress !== 100) {
      return (
        <Box alignItems="center" paddingTop="4%" paddingLeft="4%">
          <Typography variant="body2" color="textSecondary">
            {t.formatString(t.singleFileUpload.erreurTemp, {
              fileName: file.inputFile.file.name
            })}
          </Typography>
        </Box>
      );
    }
    return <></>;
  };
  return (
    <>
      {isErrorUpload()}
      <Box alignItems="center" padding="4%">
        <Typography variant="body2" color="textSecondary">
          {file.inputFile.file.name}
        </Typography>
        <Box alignItems="center" display="flex">
          <Box width="100%" padding="1%">
            <LinearProgress
              variant="determinate"
              value={progress}
              color={timedOut && progress !== 100 ? 'secondary' : 'primary'}
              classes={style}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              progress
            )}%`}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
SingleFileUploadWithProgress.propTypes = {
  file: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  onUpload: PropTypes.func,
  onTimedOut: PropTypes.func
};
export default SingleFileUploadWithProgress;
