module.exports = {
  API_ROOT: 'https://bme-api.dev.nexity.net/',
  NOMENCLATURE_API_URI: 'https://nom-api.dev.nexity.net/v1/',
  INTERVENANTS_API_ROOT: 'https://ine-api.dev.nexity.net/',
  STRUCTURE_API_ROOT: 'https://org-api.dev.nexity.net/',
  CLEO_WS_URI_HTTPS:
    'https://ir-cataloguelots-api.dev.nexity.net/api/v1/LotCatalog/MarketedPrograms',
  CLEO_WS_CUSTOMER_ID: 'Base Media',
  CLEO_WS_CUSTOMER_KEY: '6DACDDD2-9450-4AF4-B142-901201643B66',
  API_GOV: 'https://geo.api.gouv.fr/',
  INTERFACE: 'internal',
  KEYCLOAK_ROOT_URL: 'https://avenir-auth.dev.nexity.net/auth',
  KEYCLOAK_REALM_APP: 'avr-dev',
  KEYCLOAK_FRONT_ID: 'avr-front',
  KEYCLOAK_TOKEN_URL:
    'https://avenir-auth.dev.nexity.net/auth/realms/avr-dev/protocol/openid-connect/token',
  CPI_ACTIVE: false,
  CPI_WS_URI: '',
  REACT_APP_GOOGLE_KEY: 'AIzaSyAtKit5fWau1-PwRRKG27jxNwSw8ZpnFdY',
  API_CPI: 'https://cpi-api.dev.nexity.net/',
  API_PLG: 'https://plg-api.dev.nexity.net/',
  API_TLC: 'https://tlc-api.dev.nexity.net/'
};
