import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalComponent from '../ModalComponent/ModalComponent';
import MySelectionContact from '../../mySelectionContact/mySelectionContact.jsx';
import {
  shareMediaStart,
  fetchInternalMembersStart,
  fetchExternalMembersStart
} from '../../../redux/share/share.actions';
import {
  selectInternalMembersResult,
  selectExternalMembersResult,
  selectShareResult,
  selectShareError
} from '../../../redux/share/share.selectors';
import './mediaShareModal.scss';
/**
 * Customize modal component to include MediaShareModal functionality
 * from one program to another one.
 */
const MediaShareModal = ({
  open,
  medias,
  shareMedia,
  fetchInternalMembers,
  internalMembersResult,
  externalMembersResult,
  fetchExternalMembers,
  onClose,
  shareResult,
  shareError
}) => {
  /**
   * Handle to close modal and reset variables
   */
  const handleClose = () => {
    onClose();
  };
  const body = (
    <div className="my-selection-modal-container">
      <MySelectionContact
        open={handleClose}
        shareMedia={shareMedia}
        medias={medias}
        fetchInternalMembers={fetchInternalMembers}
        internalMembersResult={internalMembersResult}
        externalMembersResult={externalMembersResult}
        fetchExternalMembers={fetchExternalMembers}
        shareResult={shareResult}
        shareError={shareError}
      />
    </div>
  );
  return <ModalComponent open={open} body={body} onClose={handleClose} />;
};

MediaShareModal.propTypes = {
  medias: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  open: PropTypes.bool,
  shareMedia: PropTypes.func,
  fetchInternalMembers: PropTypes.func,
  internalMembersResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  externalMembersResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  fetchExternalMembers: PropTypes.func,
  onClose: PropTypes.func,
  shareResult: PropTypes.string,
  shareError: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
  internalMembersResult: selectInternalMembersResult,
  externalMembersResult: selectExternalMembersResult,
  shareResult: selectShareResult,
  shareError: selectShareError
});

const mapDispatchToProps = dispatch => ({
  shareMedia: params => dispatch(shareMediaStart(params)),
  fetchInternalMembers: params => dispatch(fetchInternalMembersStart(params)),
  fetchExternalMembers: params => dispatch(fetchExternalMembersStart(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaShareModal);
