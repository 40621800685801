import React, { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import style from './style.jsx';

const StyledTreeItem = ({
  bucket,
  onCheckListChanged,
  fieldName,
  isVitrine,
  saveChecked,
  valueChecked,
  filterState
}) => {
  const classes = makeStyles(style)();
  const [checkedFilters, setCheckedFilters] = useState(() => {
    if (valueChecked !== undefined) {
      return valueChecked;
    }
    if (filterState !== undefined) {
      return filterState;
    }
    return [];
  });

  // We check if there is another filial with the same parent checked, in this case we check all the filials with the same parent
  const hasOtherCheckedSameParent = () => {
    let others = [];

    if (valueChecked?.length > 0) {
      others = valueChecked;
    } else if (checkedFilters?.length > 0) {
      others = checkedFilters;
    }

    if (others?.length > 0 && bucket.organizationalEntityParent !== undefined) {
      return others?.some(
        value =>
          value.organizationalEntityParent === bucket.organizationalEntityParent
      );
    }
    return false;
  };

  const handleCheckboxChange = (event, checkBoxChangeBucket, name) => {
    const { checked } = event.target;
    const bucketName = { ...checkBoxChangeBucket, name };
    let filters = [];
    if (checked) {
      filters = [bucketName];
    } else if (
      fieldName === 'organizationalentitypaths' &&
      hasOtherCheckedSameParent()
    ) {
      filters =
        valueChecked.filter(
          chk => !Object.keys(chk).includes('organizationalEntityParent')
        ) || [];
    } else {
      filters =
        checkedFilters.filter(
          chk => chk.value !== checkBoxChangeBucket.value
        ) || [];
    }
    saveChecked(filters);
    setCheckedFilters(filters);
    if (onCheckListChanged) {
      onCheckListChanged(filters);
    }
  };

  const getSubBucketName = styledLevelBucket => {
    let splited;
    if (isVitrine === true) {
      splited = styledLevelBucket?.name?.split('/');
    } else {
      splited = styledLevelBucket?.value?.split('/');
    }
    if (splited?.length > 1) {
      return splited[1];
    }

    return splited[0];
  };

  const getChecked = checkedBucket => {
    if (
      fieldName === 'organizationalentitypaths' &&
      hasOtherCheckedSameParent()
    ) {
      if (valueChecked?.length > 0) {
        if (
          valueChecked?.findIndex(chk => {
            return chk.value === bucket?.value;
          }) === -1
        ) {
          const filters = valueChecked;
          filters.push(bucket);
          setCheckedFilters(filters);
        }
        return true;
      }
      return false;
    }
    return (
      checkedFilters?.findIndex(chk => chk.value === checkedBucket?.value) !==
      -1
    );
  };

  const styledLabel = (styledLevelBucket, name) => (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <>
              <Checkbox
                color="primary"
                onChange={e => handleCheckboxChange(e, styledLevelBucket, name)}
                checked={getChecked(styledLevelBucket)}
              />
            </>
          }
          label={
            <Typography
              className={classes.treeItem}
              variant="caption"
            >{`${getSubBucketName(styledLevelBucket)} (${
              styledLevelBucket?.count
            })`}</Typography>
          }
        />
      </FormGroup>
    </div>
  );
  return (
    <>
      <TreeItem
        key={bucket?.value}
        label={styledLabel(bucket, fieldName)}
        nodeId={bucket?.value}
      >
        {bucket?.subFacet?.subBuckets?.map(sub => {
          return (
            <TreeItem
              key={sub.value}
              nodeId={sub.value}
              label={styledLabel(sub, bucket?.subFacet?.name)}
            />
          );
        })}
      </TreeItem>
    </>
  );
};

StyledTreeItem.propTypes = {
  bucket: PropTypes.oneOfType([PropTypes.string]),
  onCheckListChanged: PropTypes.func,
  fieldName: PropTypes.string,
  isVitrine: PropTypes.bool,
  saveChecked: PropTypes.func,
  valueChecked: PropTypes.oneOfType([PropTypes.string]),
  filterState: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

export default StyledTreeItem;
