export const getHigherInstance = media => {
  const mediaFilteredByCopyrigth = media?.instances?.filter(
    x => x.copyright === true
  );

  if (mediaFilteredByCopyrigth && mediaFilteredByCopyrigth.length > 0) {
    const instanceWithHigherResolution = mediaFilteredByCopyrigth?.reduce(
      (x, y) => (x.size > y.size ? x : y)
    );
    return instanceWithHigherResolution;
  }
  return false;
};
