import Home from '../containers/home/Home.jsx';
import ProgramSheet from '../containers/programSheet/ProgramSheet.jsx';
import ProgramCategorisation from '../containers/programCategorisation/ProgramCategorisation.jsx';
import ProgramListPage from '../containers/programListPage/ProgramListPage.jsx';
import SearchResultsList from '../containers/searchResultsList/SearchResultsList.jsx';
import MediaModification from '../containers/mediaModification/MediaModification.jsx';
import ProgramListByTheme from '../containers/programListByTheme/programListByTheme.jsx';
import FileUploader from '../containers/fileUploader/FileUploader.jsx';
import CreateProgram from '../containers/createProgram/createProgram.jsx';
import { ROUTES_CONSTANTS } from './route.constants.jsx';
import CategorySubmenu from '../components/layout/CategorySubmenu/CategorySubmenu';
import MediaOrder from '../containers/mediaOrder/MediaOrder.jsx';
import FolderSheet from '../containers/folderSheet/FolderSheet.jsx';
import AdminCategories from '../containers/admin/categories/AdminCategories';
import AdminRights from '../containers/admin/rights/AdminRights';
import AdminPage from '../containers/admin/general/AdminPage';
import FaqPage from '../containers/faq/Faq';
import GeneralConditionsPage from '../containers/generalConditions/GeneralConditions';
import { showAdminRoot } from '../shared/rights/root-rights.js';
import ModeController from '../containers/modeController/modeController.jsx';
import Partner from '../components/partner/partner.jsx';
import ErrorPage from '../containers/errorPage/errorPage.jsx';
import HomePageVitrine from '../containers/vitrine/HomePageVitrine.jsx';

export const createRootes = () => {
  const rootes = [
    { exact: true, path: ROUTES_CONSTANTS.home, component: Home },
    {
      exact: true,
      path: ROUTES_CONSTANTS.mediaSummaryAdded,
      component: ProgramSheet,
      navBar: CategorySubmenu
    },
    {
      exact: true,
      path: ROUTES_CONSTANTS.mediaSummary,
      component: ProgramSheet,
      navBar: CategorySubmenu
    },
    {
      path: ROUTES_CONSTANTS.mediaOrder,
      component: MediaOrder,
      navBar: CategorySubmenu
    },
    {
      path: ROUTES_CONSTANTS.folders,
      component: FolderSheet,
      navBar: CategorySubmenu
    },
    {
      exact: true,
      path: ROUTES_CONSTANTS.programListCategory,
      component: ProgramListPage,
      navBar: CategorySubmenu
    },
    {
      path: ROUTES_CONSTANTS.programListCategorySearch,
      component: ProgramListPage,
      navBar: CategorySubmenu
    },
    {
      exact: true,
      path: ROUTES_CONSTANTS.filesUploader,
      component: FileUploader
    },
    { path: ROUTES_CONSTANTS.filesUploaderType, component: FileUploader },
    { path: ROUTES_CONSTANTS.filesThematiqueUploader, component: FileUploader },
    { path: ROUTES_CONSTANTS.filesFolderUploader, component: FileUploader },
    {
      path: ROUTES_CONSTANTS.search,
      component: SearchResultsList,
      navBar: CategorySubmenu
    },
    {
      exact: true,
      path: ROUTES_CONSTANTS.categoriesAdded,
      component: ProgramListByTheme,
      navBar: CategorySubmenu
    },
    {
      exact: true,
      path: ROUTES_CONSTANTS.categoriesSearch,
      component: ProgramListByTheme,
      navBar: CategorySubmenu
    },
    {
      path: ROUTES_CONSTANTS.categories,
      component: ProgramListByTheme,
      navBar: CategorySubmenu
    },
    {
      path: ROUTES_CONSTANTS.programCategorisation,
      component: ProgramCategorisation
    },
    {
      path: ROUTES_CONSTANTS.sectionCategorisation,
      component: ProgramCategorisation
    },
    {
      path: ROUTES_CONSTANTS.folderCategorisation,
      component: ProgramCategorisation
    },
    {
      exact: true,
      path: ROUTES_CONSTANTS.mediaUpdate,
      component: MediaModification
    },
    {
      exact: true,
      path: ROUTES_CONSTANTS.mediaDelete,
      component: MediaModification
    },
    {
      path: ROUTES_CONSTANTS.createProgram,
      component: CreateProgram
    },
    {
      path: ROUTES_CONSTANTS.modifyProgram,
      component: CreateProgram
    },
    {
      path: ROUTES_CONSTANTS.mode,
      component: ModeController
    },
    { path: ROUTES_CONSTANTS.faq, component: FaqPage },
    {
      path: ROUTES_CONSTANTS.generalConditions,
      component: GeneralConditionsPage
    },
    { path: ROUTES_CONSTANTS.partner, component: Partner },
    { path: ROUTES_CONSTANTS.error, component: ErrorPage },

    {
      exact: true,
      path: ROUTES_CONSTANTS.vitrineMediatheque,
      component: HomePageVitrine
    },
    {
      exact: true,
      path: ROUTES_CONSTANTS.vitrineProgramSheetMedia,
      component: ProgramSheet
    },
    {
      path: ROUTES_CONSTANTS.vitrineMediathequeSearch,
      component: HomePageVitrine
    }
  ];

  if (showAdminRoot()) {
    rootes.push({
      path: ROUTES_CONSTANTS.adminCategories,
      component: AdminCategories
    });
    rootes.push({
      exact: true,
      path: ROUTES_CONSTANTS.admin,
      component: AdminPage
    });
    rootes.push({ path: ROUTES_CONSTANTS.adminRights, component: AdminRights });
  }
  return rootes;
};

export const ROUTES = createRootes();
