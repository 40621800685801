import VisualFolderActionTypes from './visual-folder.types';

export const fetchVisualFolderStart = refFolder => ({
  type: VisualFolderActionTypes.FETCH_VISUAL_FOLDER_START,
  payload: refFolder
});

export const fetchVisualFolderSuccess = data => ({
  type: VisualFolderActionTypes.FETCH_VISUAL_FOLDER_SUCCESS,
  payload: data
});

export const fetchVisualFolderFailure = error => ({
  type: VisualFolderActionTypes.FETCH_VISUAL_FOLDER_FAILURE,
  payload: error
});

export const fetchVisualFolderFinish = () => ({
  type: VisualFolderActionTypes.FETCH_VISUAL_FOLDER_FINISH
});
