/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './style.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styles from './style.jsx';
import Button from '../button/buttonComponent.jsx';
import text from '../../constants/translates';
import {
  colorType,
  snackBarSeverity,
  vitrineBigScreen,
  vitrineMediumScreen,
  vitrineSmallScreen
} from '../../constants/ui.constants';
import { downloadStart } from '../../redux/download/download.actions';
import { addToMySelection } from '../../redux/mySelection/mySelection.actions';
import { selectMyCurrentSelection } from '../../redux/mySelection/mySelection.selectors';
import {
  snackbarDuration,
  MEDIA_TYPE,
  ORIGINAL_MEDIA_INSTANCE,
  originalName
} from '../../constants/global-constants.jsx';
import CopyPasteModal from '../modal/copyPasteModal/CopyPasteModal.jsx';
import SnackbarAlert from '../snackBar/SnackbarAlert.jsx';
import MediaDetails from '../medialDetails/MediaDetails.jsx';
import MediaDisplay from '../mediaDisplay/MediaDisplay.jsx';
import MediaShareModal from '../modal/mediaShareModal/mediaShareModal.jsx';
import { downloadMedia } from '../../shared/functions/mediaFunctions';
import { fetchMediaOriginalStart } from '../../redux/media-original/media-original.actions';
import { selectMediaOriginalResult } from '../../redux/media-original/media-original.selectors';
import { useWindowDimensions } from '../../shared/functions/useWindowDimensions';
import { checkVitrine } from '../../shared/functions/vitrine';
/**
 * Component for visualize the detail of 1 video media
 */
const VisualVideoProgram = ({
  video,
  updateVisualProgramVisibility,
  dowloadMediaStart,
  history,
  addToMySelectionProp,
  mySelection,
  downloadOriginalMedia,
  selectMediaOriginal,
  vitrine
}) => {
  const style = makeStyles(styles)();
  const [toastMessage, setToastMessage] = useState(false);
  const [toastseverity, setToastseverity] = useState(false);
  const [addedToMySelection, setAddedToMySelection] = useState(false);
  const [resolution, setResolution] = useState();
  const [format, setFormat] = useState();
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [instance, setInstance] = useState();
  const [selectedDowloadButton, setSelectedDowloadButton] = useState(false);
  const [openShareModal, setOpenShareModal] = useState();
  const bigScreenResolution = useWindowDimensions();

  // Effect for download the original image
  useEffect(() => {
    if (selectedDowloadButton && selectMediaOriginal?.data) {
      downloadMedia(selectMediaOriginal);
      setSelectedDowloadButton(false);
    }
  }, [selectMediaOriginal]);

  useEffect(() => {
    if (video?.instances) {
      setInstance(video?.instances[0]);
    }
  }, [video, openShareModal]);

  useEffect(() => {
    const currentInstance = video?.instances?.find(
      item =>
        item.format.refValue === format?.valueCode &&
        item.subFormat.refValue === resolution?.valueCode
    );

    setInstance(currentInstance);
  }, [format, resolution]);

  /**
   * Constant for know if the the date of use of the media has expired or not
   */
  const isMediaExpired = () => {
    if (video?.durationOfUse) {
      return new Date(video?.durationOfUse) < new Date();
    }
    return false;
  };

  const getMediaInstance = () => {
    return {
      mediaDetails: video,
      instance: instance,
      refFile: instance?.refFile,
      isOriginal: format === ORIGINAL_MEDIA_INSTANCE
    };
  };

  /**
   * Function for add media to mySelection
   */
  const showToastAndAddToMySelection = () => {
    let findElement = null;
    if (instance !== undefined) {
      findElement = mySelection.find(
        item => item?.refFile === instance?.refFile
      );
    }

    if (findElement) {
      setToastMessage(text.visualProgram.errorAddMediaToMySelection);
      setToastseverity(snackBarSeverity.error);
    } else {
      setToastMessage(text.visualProgram.addedMediaToMySelection);
      setToastseverity(snackBarSeverity.success);
      addToMySelectionProp(getMediaInstance());
    }

    setAddedToMySelection(true);
  };

  const downloadSelectedImage = () => {
    if (
      format?.valueCode === originalName ||
      resolution?.valueCode === originalName
    ) {
      setSelectedDowloadButton(true);
      downloadOriginalMedia({ refMedia: video?.refMedia });
    } else {
      dowloadMediaStart([
        {
          refFile: instance?.refFile,
          refMedia: video?.refMedia
        }
      ]);
    }
  };

  return (
    <>
      <SnackbarAlert
        id="AddedAlert"
        severity={toastseverity}
        open={addedToMySelection}
        message={toastMessage}
        handleClose={() => setAddedToMySelection(false)}
        duration={snackbarDuration}
      />
      <div className="video__player">
        <Grid container spacing={1}>
          <Grid
            item
            xs={bigScreenResolution ? vitrineMediumScreen : vitrineSmallScreen}
            className="videoMargin"
          >
            <MediaDisplay
              onOpenCopyModal={() => setOpenCopyModal(true)}
              mediaType={MEDIA_TYPE.video}
              selectedMedia={video}
              history={history}
              onAddToSelection={() => showToastAndAddToMySelection()}
              isMediaExpired={isMediaExpired()}
              galleryMode={false}
              onOpenShareModal={() => setOpenShareModal(true)}
              vitrine={vitrine}
            />
          </Grid>
          <Grid
            item
            xs={bigScreenResolution ? vitrineBigScreen : vitrineSmallScreen}
            className="video-gallery-info"
          >
            <MediaDetails
              media={video}
              isMediaExpired={isMediaExpired()}
              onClose={() => updateVisualProgramVisibility()}
              onChangeFormat={e => setFormat(e)}
              onChangeResolution={e => setResolution(e)}
              isCopyrightVisible={false}
              formatValue={format}
              resolutionValue={resolution}
              vitrine={vitrine}
              history={history}
            />

            <div className="button-block-video">
              <div className="button-block-video__dowload">
                {!checkVitrine(history) && (
                  <Button
                    id="button_download"
                    variant="contained"
                    color={colorType.secondary}
                    className={style.buttonDowload}
                    disabled={isMediaExpired()}
                    onClick={downloadSelectedImage}
                  >
                    {text.visualProgram.downloadButton}
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <CopyPasteModal
        open={openCopyModal}
        onClose={() => setOpenCopyModal(false)}
        history={history}
        media={video}
      />
      <MediaShareModal
        open={openShareModal ? openShareModal : false}
        onClose={() => setOpenShareModal(false)}
        history={history}
        medias={[getMediaInstance()]}
        id="CopyPasteModal"
      />
    </>
  );
};

VisualVideoProgram.propTypes = {
  dowloadMediaStart: PropTypes.func,
  video: PropTypes.shape({
    add: PropTypes.string,
    by: PropTypes.string,
    format: PropTypes.string,
    change: PropTypes.string,
    mediaURL: PropTypes.string
  }),
  updateVisualProgramVisibility: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  mySelection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  addToMySelectionProp: PropTypes.func,
  downloadOriginalMedia: PropTypes.func,
  selectMediaOriginal: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  vitrine: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  mySelection: selectMyCurrentSelection,
  selectMediaOriginal: selectMediaOriginalResult
});

const mapDispatchToProps = dispatch => ({
  dowloadMediaStart: dataImage => dispatch(downloadStart(dataImage)),
  downloadOriginalMedia: refMedia =>
    dispatch(fetchMediaOriginalStart(refMedia)),
  addToMySelectionProp: selectedItem => dispatch(addToMySelection(selectedItem))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VisualVideoProgram)
);
