import PartnerListTypes from './list.partner.types';

const INITIAL_STATE = {
  results: [],
  error: null,
  isLoading: false
};

const partnerListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PartnerListTypes.FETCH_PARTNER_LIST_START:
      return { ...state, isLoading: true };
    case PartnerListTypes.FETCH_PARTNER_LIST_SUCCESS:
      return { ...state, results: action.payload, error: null };
    case PartnerListTypes.FETCH_PARTNER_LIST_FAILURE:
      return { ...state, error: action.payload };
    case PartnerListTypes.FETCH_PARTNER_LIST_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default partnerListReducer;
