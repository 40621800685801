import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import ThemeListTypes from './list.theme.types';
import api from '../../api/api.jsx';
import {
  fetchThemeListFailure,
  fetchThemeListFinished,
  fetchThemeListSuccess
} from './list.theme.actions';
import { mediasBuilderWithPages } from '../../shared/builders/medias';

export const selectFile = state => state.fileSectionUploader;

export function* ThemeListResults({ payload: params }) {
  try {
    const { data } = yield call(api.getThemeList, params);
    const { payloadFiles } = yield select(selectFile);
    const dataBuilder = yield call(
      mediasBuilderWithPages,
      data,
      null,
      payloadFiles
    );
    yield put(fetchThemeListSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchThemeListFailure(error));
  }
  yield put(fetchThemeListFinished());
}

export function* onFetchThemeListStart() {
  yield takeLatest(ThemeListTypes.FETCH_THEME_LIST_START, ThemeListResults);
}

export function* ThemeListSagas() {
  yield all([call(onFetchThemeListStart)]);
}
