import userInfoActionTypes from './user-info.types';

export const fetchUserInfoStart = refMedia => ({
  type: userInfoActionTypes.FETCH_USER_INFO_START,
  payload: refMedia
});

export const fetchUserInfoSuccess = data => ({
  type: userInfoActionTypes.FETCH_USER_INFO_SUCCESS,
  payload: data
});

export const fetchUserInfoFailure = error => ({
  type: userInfoActionTypes.FETCH_USER_INFO_FAILURE,
  payload: error
});

export const fetchUserInfoFinished = () => ({
  type: userInfoActionTypes.FETCH_USER_INFO_FINISHED
});
