import { call } from 'redux-saga/effects';
import UTIF from 'utif';
import api from '../../api/api';

export function* uploadFile(item) {
  const result = yield call(api.putS3, item.url, item.file);
  return result.data;
}

const BASE64_MARKER = ';base64,';

export function convertDataURIToBinary(dataURI) {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export function tiffToDataURI(arrayBuffer) {
  const decodedTiffs = UTIF.decode(arrayBuffer);
  UTIF.decodeImage(arrayBuffer, decodedTiffs[0]);
  const rgba = UTIF.toRGBA8(decodedTiffs[0]);
  if (rgba) {
    const canvas = document.createElement('canvas');
    canvas.width = decodedTiffs[0].width;
    canvas.height = decodedTiffs[0].height;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.createImageData(
      decodedTiffs[0].width,
      decodedTiffs[0].height
    );
    for (let i = 0; i < rgba.length; i += 1) {
      imageData.data[i] = rgba[i];
    }
    ctx.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
  }

  return undefined;
}

export async function UrlToBufferArray(url) {
  return new Promise((resolve, reject) => {
    try {
      fetch(url).then(response =>
        response
          .blob()
          .then(blob => blob.arrayBuffer().then(buffer => resolve(buffer)))
      );
    } catch (error) {
      reject(error);
    }
  });
}
