import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { withRouter } from 'react-router-dom';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { PDFReader } from 'reactjs-pdf-view';
import style from './style.jsx';
import { pdfType } from '../../constants/global-constants.jsx';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';

const CardComponent = ({
  history,
  isCategory,
  hasIcons,
  height,
  image,
  category,
  showMedia,
  categoryImportant,
  media
}) => {
  const classes = makeStyles(style)();

  const redirect = () => {
    if (!category.thematique) {
      history.push(
        `${ROUTES_CONSTANTS.programDetailLink}${category.abbreviation}`
      );
    } else {
      history.push(
        `${ROUTES_CONSTANTS.categoriesLink}${category.abbreviation}`
      );
    }
  };

  return (
    <Card className={classes.card}>
      {hasIcons && (
        <div className={classes.cardIconGroup}>
          <IconButton
            color="inherit"
            aria-label="menu"
            className={classes.cardIconPadding}
          >
            <FileCopyOutlinedIcon className={classes.cardIcon} />
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="menu"
            className={classes.cardIconPadding}
          >
            <LibraryAddOutlinedIcon className={classes.cardIcon} />
          </IconButton>
        </div>
      )}
      <CardActionArea
        className={classes.cardActionArea}
        onClick={() => {
          if (isCategory) redirect();
          else showMedia(category?.media, true);
        }}
      >
        {media?.match?.format === pdfType ? (
          <PDFReader url={media?.match?.thumbnailUrl} scale={0.2} />
        ) : (
          <CardMedia
            crossOrigin="anonymous"
            alt={category.alt}
            height={height}
            image={image}
            component="img"
          />
        )}

        <CardContent>
          {isCategory ? (
            <Typography
              gutterBottom
              className={
                categoryImportant
                  ? classes.cartTitleCategoryImportant
                  : classes.cardTitleCategoryNoImportant
              }
            >
              {category.title}
            </Typography>
          ) : (
            <>
              {category.title?.length > 0 && (
                <Typography gutterBottom className={classes.cardTitle}>
                  {category.title}
                </Typography>
              )}
              <Typography gutterBottom className={classes.subTitle}>
                {category.subTitle}
              </Typography>
              <Typography gutterBottom className={classes.description}>
                {category.description}
              </Typography>
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardComponent.propTypes = {
  isCategory: PropTypes.bool,
  hasIcons: PropTypes.bool,
  height: PropTypes.string,
  image: PropTypes.string,
  history: PropTypes.func,
  categoryImportant: PropTypes.bool,
  category: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    alt: PropTypes.string,
    abbreviation: PropTypes.string,
    thematique: PropTypes.bool,
    media: PropTypes.oneOfType([PropTypes.object])
  }),
  showMedia: PropTypes.func,
  media: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(CardComponent);
