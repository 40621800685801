import { put, all, call, takeLatest } from 'redux-saga/effects';
import CategoryActionTypes from './category.types';
import { categoryBuilder } from './category.builder';
import api from '../../api/api.jsx';
import {
  fetchCategorySuccess,
  fetchCategoryFailure,
  fetchCategoryFinished
} from './category.actions';

export function* fetchCategory() {
  try {
    const { data } = yield call(api.getCategories);
    const dataBuilder = yield call(categoryBuilder, data);
    yield put(fetchCategorySuccess(dataBuilder));
  } catch (error) {
    yield put(fetchCategoryFailure(error));
  }
  yield put(fetchCategoryFinished());
}

export function* onFetchCategoryStart() {
  yield takeLatest(CategoryActionTypes.FETCH_CATEGORY_START, fetchCategory);
}

export function* categorySagas() {
  yield all([call(onFetchCategoryStart)]);
}
