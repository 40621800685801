import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import './style.scss';
import Spinner from '../../components/spinner/Spinner.jsx';
import {
  selectVisualProgramIsLoading,
  selectVisualProgramResult
} from '../../redux/visual-program/visual-program.selectors.js';
import { PROGRAM_TYPE, SEARCH_TYPE } from '../../constants/global-constants';
import { fetchVisualProgramStart } from '../../redux/visual-program/visual-program.actions.js';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import { arrayMove } from '../../shared/functions/arrayMove';
import SortableList from '../../components/sortableList/sortableList';
import { reorganizeMediasStart } from '../../redux/reorganize/reorganize.actions';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import { canEditArray, isFilialExt } from '../../shared/rights/root-rights';
import { getPathOrganizationalEntitybyRef } from '../../shared/functions/cleoFunctions';
import { ATTRIBUTES } from '../../shared/rights/roles';
import { fetchSectionStart } from '../../redux/section/sections/section.actions';
import {
  getCategoryName,
  getPrograms
} from '../../shared/functions/mediaFunctions';
import { selectSearchCopyResults } from '../../redux/searchCopy/searchCopy.selectors';
import { fetchSearchCopyResultsStart } from '../../redux/searchCopy/searchCopy.actions';

const MediaOrder = ({
  match,
  fetchVisualProgram,
  visualProgramData,
  isLoading,
  history,
  reorganizeMedias,
  sections,
  fetchSections,
  fetchSearchResults,
  searchProgramResults
}) => {
  const [items, setItems] = useState([]);
  const [section, setSection] = useState();

  const refProgram = match?.params.refProgram;

  useEffect(() => {
    fetchVisualProgram({ refProgram, withCategory: null });

    if (!sections.length > 0) {
      fetchSections();
    }
  }, []);

  useEffect(() => {
    // For call programs IR if the program is IR
    if (
      visualProgramData[0]?.program?.section?.refSection ===
      PROGRAM_TYPE.IR_PROGRAM_NAME
    ) {
      const params = {
        type: SEARCH_TYPE.program,
        text: refProgram,
        section: PROGRAM_TYPE.IR_PROGRAM_NAME,
        myPrograms: isFilialExt()
          ?.toString()
          ?.toUpperCase()
      };
      fetchSearchResults(params);
    }

    setSection(
      sections?.find(
        sec =>
          sec.refSection === visualProgramData[0]?.program?.section?.refSection
      )
    );

    const initialMedias = visualProgramData?.map(media => {
      return {
        id: media?.refMedia,
        label: media?.nameMedia,
        thumbnail: media?.thumbnail,
        category: getCategoryName(
          sections?.find(
            sec =>
              sec.refSection ===
              visualProgramData[0]?.program?.section?.refSection
          )?.categories,
          media?.category?.refCategory
        )
      };
    });
    setItems(initialMedias);
  }, [visualProgramData, sections]);

  const getTitle = () => {
    let programInfo = '';
    if (section?.type === PROGRAM_TYPE.IR_PROGRAM) {
      const cleoProg = getPrograms(searchProgramResults)?.find(
        cleo =>
          cleo.programRef.toString() ===
          visualProgramData[0]?.program?.refProgram
      );
      if (cleoProg?.name !== undefined) {
        programInfo = `${cleoProg?.code} - ${cleoProg?.name}`;
      } else {
        programInfo = `${cleoProg?.code}`;
      }
    } else if (visualProgramData[0]?.program?.name !== undefined) {
      programInfo = visualProgramData[0]?.program?.name;
    }
    return `${section?.name} : ${programInfo}`;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems([...arrayMove(oldIndex, newIndex, items)]);
  };

  const sendSortedArrayHandler = () => {
    const newArray = {
      refProgram: visualProgramData[0]?.program?.refProgram,
      medias: []
    };
    items.forEach((item, index) => {
      const newPosition = index + 1;
      const addToArray = { newPosition, refMedia: item.id };
      return newArray.medias.push(addToArray);
    });
    reorganizeMedias({ newArray, history });
  };

  if (
    !canEditArray(
      getPathOrganizationalEntitybyRef(refProgram),
      ATTRIBUTES.BME_PROGRAM_WRITE
    )
  ) {
    return <div></div>;
  }

  return (
    <>
      <Spinner isLoading={isLoading} />
      <DismissableAlert />
      <div className="programSheetContainer">
        <p className="program__title">{getTitle()}</p>
        <SortableList
          items={items}
          onSortEnd={onSortEnd}
          sendSortedArrayHandler={sendSortedArrayHandler}
          history={history}
        />
      </div>
    </>
  );
};

MediaOrder.propTypes = {
  match: PropTypes.string,
  fetchVisualProgram: PropTypes.func,
  visualProgramData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  isLoading: PropTypes.bool,
  history: PropTypes.func,
  reorganizeMedias: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchSections: PropTypes.func,
  fetchSearchResults: PropTypes.func,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  )
};

const mapStateToProps = createStructuredSelector({
  visualProgramData: selectVisualProgramResult,
  isLoading: selectVisualProgramIsLoading,
  sections: selectSectionsResult,
  searchProgramResults: selectSearchCopyResults
});

const mapDispatchToProps = dispatch => ({
  fetchVisualProgram: programRef =>
    dispatch(fetchVisualProgramStart(programRef)),
  reorganizeMedias: medias => dispatch(reorganizeMediasStart(medias)),
  fetchSections: () => dispatch(fetchSectionStart()),
  fetchSearchResults: params => dispatch(fetchSearchCopyResultsStart(params))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MediaOrder)
);
