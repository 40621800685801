import axios from 'axios';
import { API_ROUTES } from './apiRoutes';
import { AUTHENTICATION } from '../constants/global-constants';
import { API_CPI } from './api.conf';
/* eslint-disable */

class API {
  http;

  constructor() {
    const instance = axios.create({
      baseURL: API_CPI
    });
    this.http = instance;
  }

  setAutorizationToken = (token, refreshToken) => {
    if (token) {
      localStorage.setItem(AUTHENTICATION.jwtTokenKey, token);
      if (refreshToken) {
        localStorage.setItem(AUTHENTICATION.refreshTokenKey, refreshToken);
      }
      this.http.defaults.headers.common.Authorization = `${AUTHENTICATION.bearerKey} ${token}`;
    } else {
      delete this.http.defaults.headers.common.Authorization;
      localStorage.clear();
    }
  };

  getProgramCPI = refProgram =>
    this.http.get(`${API_ROUTES.infoProgram}/${refProgram}`, {});

  getAssumptions = refAssumption =>
    this.http.get(`${API_ROUTES.assumptions}/${refAssumption}`, {});

  getFavouriteAssumptionsBlock = refProgram =>
    this.http.get(
      `${API_ROUTES.programs}/${refProgram}${API_ROUTES.favAssumptions}`
    );
}

export default new API();
