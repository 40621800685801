import React from 'react';
import PropTypes from 'prop-types';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { PDFReader } from 'reactjs-pdf-view';

import './style.scss';
import { IconButton, makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import styles from './style.jsx';
import { pdfFormat } from '../../constants/global-constants';

const MySelectionImage = ({
  src,
  id,
  removeFromMySelection,
  name,

  format,
  programNumber,
  section
}) => {
  const classes = makeStyles(styles)();

  return (
    <Tooltip
      title={
        <Typography fontSize={30}>
          <>
            {section ? (
              <>
                {format === pdfFormat
                  ? `THM_${section?.refSection}${name}`
                  : `${name}`}
              </>
            ) : (
              <>
                {format === pdfFormat ? (
                  <>{programNumber ? `${programNumber}-${name}` : `${name}`}</>
                ) : (
                  `${name}`
                )}
              </>
            )}
          </>
        </Typography>
      }
      placement="top-start"
      className={classes.tooltip}
    >
      <div className="image-container">
        <>
          {format === pdfFormat ? (
            <PDFReader url={src} scale={0.13} />
          ) : (
            <img
              crossOrigin="anonymous"
              className="image-selection"
              src={src}
              alt=""
            />
          )}

          <IconButton
            color="inherit"
            variant="text"
            id="deleteSection"
            className={classes.deleteSectionButton}
            onKeyDown={() => {
              removeFromMySelection(id);
            }}
            onClick={() => {
              removeFromMySelection(id);
            }}
          >
            <DeleteOutlinedIcon className={classes.deleteIcon} />
          </IconButton>
        </>
      </div>
    </Tooltip>
  );
};

MySelectionImage.propTypes = {
  src: PropTypes.string,
  id: PropTypes.string,
  removeFromMySelection: PropTypes.func,
  name: PropTypes.string,
  format: PropTypes.string,
  programNumber: PropTypes.string,
  section: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

export default MySelectionImage;
