import SearchActionTypes from './search.types';

export const fetchSearchResultsStart = params => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_START,
  payload: params
});

export const fetchSearchResultsSuccess = data => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_SUCCESS,
  payload: data
});

export const fetchSearchResultsFailure = error => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_FAILURE,
  payload: error
});

export const fetchSearchResultsFinished = () => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_FINISHED
});

export const fetchSearchSuggestionsStart = params => ({
  type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_START,
  payload: params
});

export const fetchSearchSuggestionsSuccess = data => ({
  type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  payload: data
});

export const fetchSearchSuggestionsFailure = error => ({
  type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_FAILURE,
  payload: error
});

export const fetchSearchSuggestionsFinished = () => ({
  type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_FINISHED
});

export const fetchSearchToolbar = params => ({
  type: SearchActionTypes.FETCH_SEARCH_TOOLBAR,
  payload: params
});

export const fetchSearchResultsWithMediaStart = params => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_START,
  payload: params
});

export const fetchSearchResultsWithMediaSuccess = data => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_SUCCESS,
  payload: data
});

export const fetchSearchResultsWithMediaFailure = error => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_FAILURE,
  payload: error
});

export const fetchSearchResultsWithMediaFinished = () => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_WITHMEDIA_FINISHED
});

export const fetchSearchResultsWithoutMediaStart = params => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_START,
  payload: params
});

export const fetchSearchResultsWithoutMediaSuccess = data => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_SUCCESS,
  payload: data
});

export const fetchSearchResultsWithoutMediaFailure = error => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_FAILURE,
  payload: error
});

export const fetchSearchResultsWithoutMediaFinished = () => ({
  type: SearchActionTypes.FETCH_SEARCH_RESULTS_WITHOUTMEDIA_FINISHED
});
