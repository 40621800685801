import { createSelector } from 'reselect';

const selectCategoryAdmin = state => state.categoryManage;

export const selectDeletedCategory = createSelector(
  [selectCategoryAdmin],
  deleteCategory => deleteCategory.deleted
);

export const selectUpdatedCategory = createSelector(
  [selectCategoryAdmin],
  updateCategory => updateCategory.updated
);

export const selectCreatedCategory = createSelector(
  [selectCategoryAdmin],
  createdCategory => createdCategory.created
);

export const selectIsLoadingCategory = createSelector(
  [selectCategoryAdmin],
  createdCategory => createdCategory.isLoading
);
