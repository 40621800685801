const SearchActionTypes = {
  FETCH_SEARCH_RESULTS_START: 'FETCH_SEARCH_RESULTS_START',
  FETCH_SEARCH_RESULTS_SUCCESS: 'FETCH_SEARCH_RESULTS_SUCCESS',
  FETCH_SEARCH_RESULTS_FAILURE: 'FETCH_SEARCH_RESULTS_FAILURE',
  FETCH_SEARCH_RESULTS_FINISHED: 'FETCH_SEARCH_RESULTS_FINISHED',
  FETCH_SEARCH_SUGGESTIONS_START: 'FETCH_SEARCH_SUGGESTIONS_START',
  FETCH_SEARCH_SUGGESTIONS_SUCCESS: 'FETCH_SEARCH_SUGGESTIONS_SUCCESS',
  FETCH_SEARCH_SUGGESTIONS_FAILURE: 'FETCH_SEARCH_SUGGESTIONS_FAILURE',
  FETCH_SEARCH_SUGGESTIONS_FINISHED: 'FETCH_SEARCH_SUGGESTIONS_FINISHED',
  FETCH_SEARCH_TOOLBAR: 'FETCH_SEARCH_TOOLBAR',
  FETCH_SEARCH_RESULTS_WITHMEDIA_START: 'FETCH_SEARCH_RESULTS_WITHMEDIA_START',
  FETCH_SEARCH_RESULTS_WITHMEDIA_SUCCESS:
    'FETCH_SEARCH_RESULTS_WITHMEDIA_SUCCESS',
  FETCH_SEARCH_RESULTS_WITHMEDIA_FAILURE:
    'FETCH_SEARCH_RESULTS_WITHMEDIA_FAILURE',
  FETCH_SEARCH_RESULTS_WITHMEDIA_FINISHED:
    'FETCH_SEARCH_RESULTS_WITHMEDIA_FINISHED',
  FETCH_SEARCH_RESULTS_WITHOUTMEDIA_START:
    'FETCH_SEARCH_RESULTS_WITHOUTMEDIA_START',
  FETCH_SEARCH_RESULTS_WITHOUTMEDIA_SUCCESS:
    'FETCH_SEARCH_RESULTS_WITHOUTMEDIA_SUCCESS',
  FETCH_SEARCH_RESULTS_WITHOUTMEDIA_FAILURE:
    'FETCH_SEARCH_RESULTS_WITHOUTMEDIA_FAILURE',
  FETCH_SEARCH_RESULTS_WITHOUTMEDIA_FINISHED:
    'FETCH_SEARCH_RESULTS_WITHOUTMEDIA_FINISHED'
};

export default SearchActionTypes;
