import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { Drawer } from '@material-ui/core';
import { connect } from 'react-redux';
import MySelectionContact from '../../components/mySelectionContact/mySelectionContact.jsx';
import MySelectionItems from '../../components/mySelectionItems/mySelectionItems.jsx';
import {
  removeImageFromMySelection,
  clearMySelection
} from '../../redux/mySelection/mySelection.actions';
import { selectMyCurrentSelection } from '../../redux/mySelection/mySelection.selectors';
import t from '../../constants/translates';
import './styles.scss';
import { downloadStart } from '../../redux/download/download.actions.js';
import {
  shareMediaStart,
  fetchInternalMembersStart,
  fetchExternalMembersStart
} from '../../redux/share/share.actions.js';
import {
  selectInternalMembersResult,
  selectExternalMembersResult,
  selectShareResult,
  selectShareError
} from '../../redux/share/share.selectors.js';
import { selectedCallback } from '../../redux/modeController/modeController.selectors.js';
import { sendMediasToCallbackStart } from '../../redux/modeController/modeController.actions.js';

const MySelection = ({
  open,
  setOpen,
  myCurrentSelection,
  removeFromMySelection,
  clearMySelectionProp,
  dowloadMySelection,
  shareMedia,
  fetchInternalMembers,
  internalMembersResult,
  externalMembersResult,
  fetchExternalMembers,
  shareResult,
  shareError,
  callback,
  sendMediasToCallback
}) => {
  const [showShareForm, setShowShareForm] = useState(false);
  const numMedias = myCurrentSelection.length;

  const sendMediasToCallbackHandler = () => {
    sendMediasToCallback({ callback, items: myCurrentSelection });
  };
  const dowloadFilesAndDeleteMySelection = items => {
    dowloadMySelection(items);
    clearMySelectionProp();
  };

  const printMediasText = () => {
    if (numMedias === 1) {
      return t.formatString(t.mySelection.totalMedia, { numMedias });
    }
    return t.formatString(t.mySelection.totalMedias, { numMedias });
  };

  const sideList = () => (
    <div className="my-selection-menu">
      <div className="my-selection-menu__header">
        <span className="my-selection-menu__title-header">
          {t.mySelection.mySelection}
        </span>
        <div className="my-selection-menu__total-medias">
          <span className="my-selection-menu__total-medias-text">
            {printMediasText()}
          </span>
        </div>
      </div>
      {showShareForm ? (
        <MySelectionContact
          send={setOpen}
          open={setShowShareForm}
          shareMedia={shareMedia}
          medias={myCurrentSelection}
          fetchInternalMembers={fetchInternalMembers}
          internalMembersResult={internalMembersResult}
          externalMembersResult={externalMembersResult}
          fetchExternalMembers={fetchExternalMembers}
          shareResult={shareResult}
          shareError={shareError}
        />
      ) : (
        <MySelectionItems
          openContactForm={setShowShareForm}
          items={myCurrentSelection}
          dowloadFiles={dowloadFilesAndDeleteMySelection}
          removeFromMySelection={removeFromMySelection}
          sendMediasToCallbackHandler={sendMediasToCallbackHandler}
          callback={callback}
        />
      )}
    </div>
  );

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      {sideList()}
    </Drawer>
  );
};

MySelection.propTypes = {
  myCurrentSelection: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  removeFromMySelection: PropTypes.func,
  clearMySelectionProp: PropTypes.func,
  dowloadMySelection: PropTypes.func,
  shareMedia: PropTypes.func,
  fetchInternalMembers: PropTypes.func,
  internalMembersResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  externalMembersResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  fetchExternalMembers: PropTypes.func,
  shareResult: PropTypes.string,
  shareError: PropTypes.string,
  callback: PropTypes.string,
  sendMediasToCallback: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  myCurrentSelection: selectMyCurrentSelection,
  internalMembersResult: selectInternalMembersResult,
  externalMembersResult: selectExternalMembersResult,
  shareResult: selectShareResult,
  shareError: selectShareError,
  callback: selectedCallback
});

const mapDispatchToProps = dispatch => ({
  removeFromMySelection: idImage =>
    dispatch(removeImageFromMySelection(idImage)),
  clearMySelectionProp: () => dispatch(clearMySelection()),
  dowloadMySelection: medias => dispatch(downloadStart(medias)),
  shareMedia: params => dispatch(shareMediaStart(params)),
  fetchInternalMembers: params => dispatch(fetchInternalMembersStart(params)),
  fetchExternalMembers: params => dispatch(fetchExternalMembersStart(params)),
  sendMediasToCallback: params => dispatch(sendMediasToCallbackStart(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(MySelection);
