import programDetailsActionTypes from './program-details.types';

export const fetchProgramDetailsStart = text => ({
  type: programDetailsActionTypes.FETCH_PROGRAM_DETAILS_START,
  payload: text
});

export const fetchProgramDetailsSuccess = data => ({
  type: programDetailsActionTypes.FETCH_PROGRAM_DETAILS_SUCCESS,
  payload: data
});

export const fetchProgramDetailsFailure = error => ({
  type: programDetailsActionTypes.FETCH_PROGRAM_DETAILS_FAILURE,
  payload: error
});

export const fetchProgramDetailsFinished = () => ({
  type: programDetailsActionTypes.FETCH_PROGRAM_DETAILS_FINISHED
});
