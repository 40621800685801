import PutFaqTypes from './putFaqTypes';

export const sendPutFaqDataStart = formData => ({
  type: PutFaqTypes.SEND_PUT_FAQ_START,
  payload: formData
});

export const sendPutFaqDataSuccess = message => ({
  type: PutFaqTypes.SEND_PUT_FAQ_SUCCESS,
  payload: message
});

export const sendPutFaqDataFailure = error => ({
  type: PutFaqTypes.SEND_PUT_FAQ_FAILURE,
  payload: error
});

export const sendPutFaqDataFinished = () => ({
  type: PutFaqTypes.SEND_PUT_FAQ_FINISHED
});
