/* eslint-disable */
import {
  THEME_NOM_TYPE,
  NOM_INFO_TYPE
} from '../../../constants/global-constants';
import imageIR from '../../../assets/images/HP_programme_IR.jpg';
import imageIE from '../../../assets/images/HP_Programme_IE.jpg';
import imageCampagne from '../../../assets/images/HP_thematique_CampagneNationale_Tuile3.jpg';
import imageLifestyle from '../../../assets/images/HP_thematique_lifestyle_Tuile1.jpg';
import imageServices from '../../../assets/images/HP_thematique_Services_Tuile4.jpg';
import imageVille from '../../../assets/images/HP_thematique_Ville_Tuile2.jpg';

const getSectionImage = refSection => {
  const sectionImages = [
    { name: 'PROGRAM_IR', image: imageIR },
    { name: 'PROGRAM_IE', image: imageIE },
    { name: 'LIFESTYLE', image: imageLifestyle },
    { name: 'VILLES', image: imageVille },
    { name: 'CAMPAGNES_NATIONALES', image: imageCampagne },
    { name: 'SERVICES', image: imageServices }
  ];

  return sectionImages.find(section => section.name === refSection)?.image;
};

const getSectionImageObsolete = refSection => {
  const sectionImages = [
    { name: 'SEC1', image: imageIR },
    { name: 'SEC2', image: imageIE },
    { name: 'SEC3', image: imageLifestyle },
    { name: 'SEC4', image: imageVille },
    { name: 'SEC5', image: imageCampagne },
    { name: 'SEC6', image: imageServices }
  ];
  return sectionImages.find(section => section.name === refSection)?.image;
};

const sectionListBuilder = sectionList => {
  return sectionList?.map(item => {
    return sectionItemNomBuilder(item);
  });
};

export function sectionItemBuilder(item) {
  return {
    listCode: item?.listCode,
    level: item?.level,
    refSection: item?.refValue,
    url: getSectionImage(item?.refValue)
  };
}

export function sectionItemNomBuilder(item) {
  return item?.level[0]?.value?.map(section => {
    const type = section.additionnalInformation?.find(
      info => info.informationCode === NOM_INFO_TYPE.TYPE_SECTION
    )?.value;

    const deleteable = section.additionnalInformation?.find(
      info => info.informationCode === NOM_INFO_TYPE.DELETEABLE
    )?.value;

    const urlSection = section.additionnalInformation?.find(
      info => info.informationCode === NOM_INFO_TYPE.SECTION_IMAGE
    )?.value;

    return {
      ...listOfValueItemBuilder(section),
      refSection: section.valueCode,
      listCode: item?.listCode,
      level: 1,
      categories: categoryItemBuilder(item, section.valueCode),
      url: getSectionImage(section?.valueCode),
      thematique: type === THEME_NOM_TYPE,
      type: type,
      deleteable:
        isNaN(deleteable) && deleteable
          ? JSON.parse(deleteable)
          : !!+deleteable,
      urlSection: urlSection,
      additionnalInformation: section?.additionnalInformation
    };
  });
}

export function categoryItemBuilder(item, refSection) {
  return item?.level[1]?.value
    ?.filter(cat => cat.parentCode === refSection)
    ?.map(category => {
      return {
        ...listOfValueItemBuilder(category),
        subCategory: childrenListBuilder(item, category.valueCode),
        refCategory: category.valueCode,
        level: item?.level[1]?.position,
        listCode: item?.listCode
      };
    });
}

export function childrenListBuilder(item, refCategory) {
  return item?.level[2]?.value
    ?.filter(cat => cat.parentCode === refCategory)
    ?.map(subCategory => {
      return {
        ...listOfValueItemBuilder(subCategory),
        refSubCategory: subCategory.valueCode,
        level: item?.level[2]?.position
      };
    });
}

export function listOfValueItemBuilder(value) {
  return {
    shortLabel: value.shortLabel,
    name: value.longLabel,
    order: value.order,
    parentCode: value.parentCode
  };
}

export function sectionBuilder(data) {
  return sectionItemNomBuilder(data);
}
