import FaqActionTypes from './faq.types';

export const fetchFaqStart = () => ({
  type: FaqActionTypes.FETCH_FAQ_START
});

export const fetchFaqSuccess = data => ({
  type: FaqActionTypes.FETCH_FAQ_SUCCESS,
  payload: data
});

export const fetchFaqFailure = error => ({
  type: FaqActionTypes.FETCH_FAQ_FAILURE,
  payload: error
});

export const fetchFaqFinished = () => ({
  type: FaqActionTypes.FETCH_FAQ_FINISHED
});
