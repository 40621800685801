import SearchCopyActionTypes from './searchCopy.types';

export const fetchSearchCopyResultsStart = params => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_START,
  payload: params
});

export const fetchSearchCopyResultsSuccess = data => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_SUCCESS,
  payload: data
});

export const fetchSearchCopyResultsFailure = error => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_FAILURE,
  payload: error
});

export const fetchSearchCopyResultsFinished = () => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_FINISHED
});

export const fetchSearchCopySuggestionsStart = params => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_SUGGESTIONS_START,
  payload: params
});

export const fetchSearchCopySuggestionsSuccess = data => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_SUGGESTIONS_SUCCESS,
  payload: data
});

export const fetchSearchCopySuggestionsFailure = error => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_SUGGESTIONS_FAILURE,
  payload: error
});

export const fetchSearchCopySuggestionsFinished = () => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_SUGGESTIONS_FINISHED
});

export const fetchSearchCopyToolbar = params => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_TOOLBAR,
  payload: params
});

export const fetchSearchCopyResultsWithMediaStart = params => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_START,
  payload: params
});

export const fetchSearchCopyResultsWithMediaSuccess = data => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_SUCCESS,
  payload: data
});

export const fetchSearchCopyResultsWithMediaFailure = error => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_FAILURE,
  payload: error
});

export const fetchSearchCopyResultsWithMediaFinished = () => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_FINISHED
});

export const fetchSearchCopyResultsWithoutMediaStart = params => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_START,
  payload: params
});

export const fetchSearchCopyResultsWithoutMediaSuccess = data => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_SUCCESS,
  payload: data
});

export const fetchSearchCopyResultsWithoutMediaFailure = error => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_FAILURE,
  payload: error
});

export const fetchSearchCopyResultsWithoutMediaFinished = () => ({
  type: SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_FINISHED
});
