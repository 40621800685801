import { programBuilder } from '../../shared/builders/program.builder';

function folderBuilder(item, communes) {
  const builded = programBuilder(item?.program, communes);
  return {
    refFolder: item?.refFolder,
    name: item?.name,
    program: builded
  };
}

export function folderDetailsBuilder(data, communes) {
  return folderBuilder(data, communes);
}
