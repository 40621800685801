import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { PDFReader } from 'reactjs-pdf-view';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import ImageGallery from 'react-image-gallery';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ShareIcon from '@material-ui/icons/Share';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import pdfThumbnail from '../../assets/images/pdfThumbnail.png';
import FullScreen from '../fullScreen/fullScreen.jsx';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import ImageCrop from '../imageCrop/imageCrop';
import text from '../../constants/translates';
import './style.scss';
import styles from './style.jsx';
import {
  MEDIA_TYPE,
  PROGRAM_TYPE,
  snackbarDuration,
  pdfFormat,
  MEDIA_STATUS_ERROR
} from '../../constants/global-constants';
import { canEditArray } from '../../shared/rights/root-rights.js';
import { ATTRIBUTES } from '../../shared/rights/roles.js';
import { getPathOrganizationalEntitybyRef } from '../../shared/functions/cleoFunctions.js';
import { getErrorMediaStatus } from '../../redux/errors/errorsHelper';
import SnackbarAlert from '../snackBar/SnackbarAlert';
import warningLogo from '../../assets/images/warningLogo.png';
import { checkVitrine } from '../../shared/functions/vitrine';

const MediaDisplay = ({
  onOpenShareModal,
  mediaType,
  mediaList,
  isMediaGallery,
  selectedMedia,
  openCrop,
  cropImage,
  history,
  onAddToSelection,
  onChangeSlide,
  onOpenCopyModal,
  onOpenCrop,
  setCropImageVisibility,
  isMediaExpired,
  galleryMode,
  checked,
  instanceURL
}) => {
  const [defaultIndex, setDefaultIndex] = useState(0);
  let player;
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [snackbarAlertOpened, setSnackbarAlertOpened] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [toastseverity, setToastseverity] = useState(false);
  const style = makeStyles(styles)();
  const routeModifyMedia = () => {
    history.push(
      `${ROUTES_CONSTANTS.mediaModificationBase}${selectedMedia?.refMedia}/program`
    );
  };
  const settings = {
    showNav: false,
    showFullscreenButton: false,
    showPlayButton: false,
    showThumbnails: galleryMode
  };

  useEffect(() => {
    const errorMessage = getErrorMediaStatus(selectedMedia?.status);

    if (errorMessage) {
      setToastMessage(errorMessage.text);
      setToastseverity(errorMessage.severity);
      setSnackbarAlertOpened(true);
    }
  }, [selectedMedia]);

  const getRefProgram = () => {
    return (
      selectedMedia?.program?.refProgram ||
      selectedMedia?.folder?.program?.refProgram
    );
  };

  const showCopyIcon = () => {
    if (
      selectedMedia?.program?.section?.refSection !==
        PROGRAM_TYPE.IE_PROGRAM_NAME &&
      selectedMedia?.format !== pdfFormat
    ) {
      return (
        ((selectedMedia?.copyright === undefined &&
          selectedMedia?.program?.section?.refSection) ||
          selectedMedia?.folder?.refFolder) &&
        canEditArray(
          getPathOrganizationalEntitybyRef(getRefProgram()),
          ATTRIBUTES.BME_MEDIA_WRITE
        )
      );
    }
    return null;
  };

  const onClickFullscreen = () => {
    if (mediaType === MEDIA_TYPE.video) {
      // eslint-disable-next-line react/no-find-dom-node
      screenfull.request(findDOMNode(player));
    } else {
      setOpenFullScreen(true);
    }
  };

  const renderCustomButtons = () => {
    return (
      <div className="icons">
        {(mediaType !== MEDIA_TYPE.video ||
          mediaType !== MEDIA_TYPE.document) && (
          <div className="icon-top">
            <IconButton
              className={style.iconTop}
              aria-label="menu"
              onClick={() => onClickFullscreen()}
            >
              <FullscreenIcon />
              <span className="icon-top__fullscreen-text">
                {text.optionCardButtons.fullScreen}
              </span>
            </IconButton>
          </div>
        )}

        {canEditArray(
          getPathOrganizationalEntitybyRef(getRefProgram()),
          ATTRIBUTES.BME_MEDIA_WRITE
        ) && (
          <>
            {!checkVitrine(history) && (
              <div className="icon-top--right">
                <IconButton
                  className={style.iconTop}
                  aria-label="menu"
                  onClick={routeModifyMedia}
                >
                  <EditOutlinedIcon />
                  <span className="icon-top__fullscreen-text">
                    {text.optionCardButtons.modification}
                  </span>
                </IconButton>
              </div>
            )}
          </>
        )}

        {!checkVitrine(history) && (
          <div className="icon-bottom">
            <div
              className={
                showCopyIcon()
                  ? 'icon-bottom--left'
                  : 'icon-bottom--left-no-copy'
              }
            >
              {selectedMedia?.typeMedia?.refValue === MEDIA_TYPE.image && (
                <>
                  <IconButton
                    id="cropButton"
                    className={style.iconBot}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => onOpenCrop()}
                  >
                    <OpenInNewIcon />
                    <span className="icon-bottom__text">
                      {text.optionCardButtons.resize}
                    </span>
                  </IconButton>
                </>
              )}
              {showCopyIcon() && (
                <>
                  <IconButton
                    className={style.iconBot}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => {
                      onOpenCopyModal();
                    }}
                  >
                    <FileCopyOutlinedIcon />
                    <span className="icon-bottom__text">
                      {text.optionCardButtons.copy}
                    </span>
                  </IconButton>
                </>
              )}
            </div>

            <>
              {showCopyIcon() && <span className="separator" />}
              <div
                className={
                  showCopyIcon()
                    ? 'icon-bottom--rigth'
                    : 'icon-bottom--rigth-no-copy'
                }
              >
                <IconButton
                  id="copy-outlined-button"
                  className={style.iconBot}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => onAddToSelection()}
                  disabled={isMediaExpired}
                >
                  <FileCopyOutlinedIcon />
                  <span className="icon-bottom__text">
                    {text.optionCardButtons.addToSelection}
                  </span>
                </IconButton>

                {canEditArray(
                  getPathOrganizationalEntitybyRef(getRefProgram()),
                  ATTRIBUTES.BME_MAIL_WRITE
                ) && (
                  <IconButton
                    className={style.iconBot}
                    color="inherit"
                    aria-label="menu"
                    disabled={isMediaExpired}
                    id="share-outlined-button"
                    onClick={() => {
                      onOpenShareModal();
                    }}
                  >
                    <ShareIcon />
                    <span className="icon-bottom__text">
                      {text.optionCardButtons.share}
                    </span>
                  </IconButton>
                )}
              </div>
            </>
          </div>
        )}
      </div>
    );
  };
  const getVideoDisplay = () => (
    <div className="video-gallery-container">
      <div className="player-wrapper">
        {renderCustomButtons()}
        <ReactPlayer
          ref={el => {
            player = el;
          }}
          className="react-player"
          url={selectedMedia?.mediaURL}
          light={selectedMedia?.thumbnail}
          playing
          controls
          playIcon={<PlayCircleOutlineOutlinedIcon style={styles.playIcon} />}
        />
      </div>
    </div>
  );

  const getVideoGallery = (url, thumbnail) => (
    <div className="video-gallery-container">
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={url}
          light={thumbnail}
          playing
          controls
          playIcon={<PlayCircleOutlineOutlinedIcon style={styles.playIcon} />}
        />
      </div>
    </div>
  );

  const getImagePreview = media => {
    if (checked) {
      return instanceURL;
    }
    return media?.mediaURL;
  };

  const getImageGallery = media => (
    <div>
      <img
        alt={media.mediaName}
        crossOrigin="anonymous"
        className="image-gallery-image"
        src={getImagePreview(media)}
        onError={e => {
          e.target.src = warningLogo;
        }}
      />
    </div>
  );
  const getThumbnailImageGallery = (thumbnail, media) => (
    <>
      {media?.format === pdfFormat ? (
        <div className="pdf-gallery">
          <PDFReader url={media?.mediaURL} scale={0.15} />
        </div>
      ) : (
        <img
          alt={media.mediaName}
          crossOrigin="anonymous"
          className="image-gallery-thumbnail-image"
          src={thumbnail}
        />
      )}
    </>
  );

  const getDocumentGallery = media => {
    if (
      media.status === MEDIA_STATUS_ERROR.errorCodec ||
      media.status === MEDIA_STATUS_ERROR.errorGed ||
      MEDIA_STATUS_ERROR.errorResize === media.status
    ) {
      return getImageGallery(media);
    }
    if (media.mediaURL !== undefined) {
      return (
        <div className="video-gallery-container">
          <PDFReader url={media.mediaURL} width={800} scale={0.95} />
        </div>
      );
    }
    return null;
  };

  // Function for show the custom render in the case of videos and documents
  const renderItem = media => {
    const mediaUrl = media.mediaURL;
    switch (media.typeMedia?.refValue) {
      case MEDIA_TYPE.image:
        return () => getImageGallery(media);
      case MEDIA_TYPE.video:
        return () => getVideoGallery(mediaUrl, media.thumbnail);
      case MEDIA_TYPE.document:
        return () => getDocumentGallery(media);
      default:
        return null;
    }
  };

  const getImageDisplay = () => {
    const medias = mediaList.map(media => {
      let { thumbnail } = media;
      // In the case of pdf we show a pdf default image
      if (media.typeMedia?.refValue === MEDIA_TYPE.document) {
        thumbnail = pdfThumbnail;
      }
      return {
        original: checked ? instanceURL : media.mediaURL,
        // thumbnail: original,
        renderItem: renderItem(media),

        renderThumbInner: () => getThumbnailImageGallery(thumbnail, media)
      };
    });
    if (openCrop) {
      return (
        <div className="image-gallery-container">
          <ImageCrop
            cropImage={cropImage}
            imageSource={selectedMedia?.mediaURL}
            setCropImageVisibility={setCropImageVisibility}
          />
        </div>
      );
    }
    return (
      <div className="image-gallery-container">
        <ImageGallery
          items={medias?.length > 3 ? medias?.slice(0, 3) : medias}
          {...settings}
          renderCustomControls={renderCustomButtons}
          startIndex={defaultIndex}
          onSlide={e => {
            setDefaultIndex(e);
            onChangeSlide(e);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <SnackbarAlert
        id="AddedAlert"
        severity={toastseverity}
        open={snackbarAlertOpened}
        message={toastMessage}
        handleClose={() => setSnackbarAlertOpened(false)}
        duration={snackbarDuration}
      />

      {openFullScreen && (
        <FullScreen open={openFullScreen} handleClose={setOpenFullScreen}>
          {selectedMedia.typeMedia?.refValue === MEDIA_TYPE.document ? (
            <div>
              <PDFReader
                url={selectedMedia?.mediaURL}
                scale={2}
                showAllPage
                isShowHeader
              />
            </div>
          ) : (
            <img crossOrigin="anonymous" src={selectedMedia?.mediaURL} alt="" />
          )}
        </FullScreen>
      )}

      {!isMediaGallery && mediaType === MEDIA_TYPE.video
        ? getVideoDisplay()
        : getImageDisplay()}
    </>
  );
};

MediaDisplay.propTypes = {
  mediaType: PropTypes.bool,
  mediaList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  selectedMedia: PropTypes.oneOfType([PropTypes.string]),
  openCrop: PropTypes.bool,
  history: PropTypes.func,
  onAddToSelection: PropTypes.func,
  onChangeSlide: PropTypes.func,
  onOpenCopyModal: PropTypes.func,
  onOpenCrop: PropTypes.func,
  setCropImageVisibility: PropTypes.func,
  cropImage: PropTypes.bool,
  isMediaExpired: PropTypes.bool,
  galleryMode: PropTypes.bool,
  onOpenShareModal: PropTypes.func,
  checked: PropTypes.bool,
  instanceURL: PropTypes.string,
  isMediaGallery: PropTypes.bool
};

export default MediaDisplay;
