import axios from 'axios';

class API {
  http;

  constructor() {
    const instance = axios.create({});
    this.http = instance;
  }

  postSelectionMedias = ({ getRefMedias, callback }) => {
    const targetUrl = new URL(`${callback}/?selection=${getRefMedias}`);
    window.location.replace(targetUrl);
  };
}

export default new API();
