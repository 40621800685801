import { createSelector } from 'reselect';

const partnerLegalEntity = state => state.partnerLegalEntity;

export const selectPartnerLegalEntity = createSelector(
  [partnerLegalEntity],
  partner => partner.partnerLegalEntity
);

export const selectPartnerLegalEntityIsLoading = createSelector(
  [partnerLegalEntity],
  partner => partner.isLoading
);

export const selectPartnerLegalEntityError = createSelector(
  [partnerLegalEntity],
  partner => partner.error
);
