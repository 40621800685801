import ModificationTypes from './media-modification.types';

export const sendModificationDataStart = data => ({
  type: ModificationTypes.SEND_MODIFICATION_DATA_START,
  payload: data
});

export const sendModificationDataSuccess = mediaUrl => ({
  type: ModificationTypes.SEND_MODIFICATION_DATA_SUCCESS,
  payload: mediaUrl
});

export const sendModificationDataFailure = error => ({
  type: ModificationTypes.SEND_MODIFICATION_DATA_FAILURE,
  payload: error
});

export const sendModificationDataFinished = () => ({
  type: ModificationTypes.SEND_MODIFICATION_DATA_FINISHED
});
