const ProgramListActionTypes = {
  FETCH_PROGRAM_LIST_START: 'FETCH_PROGRAM_LIST_START',
  FETCH_PROGRAM_LIST_SUCCESS: 'FETCH_PROGRAM_LIST_SUCCESS',
  FETCH_PROGRAM_LIST_FAILURE: 'FETCH_PROGRAM_LIST_FAILURE',
  FETCH_PROGRAM_LIST_FINISHED: 'FETCH_PROGRAM_LIST_FINISHED',
  FETCH_PROGRAM_LIST_WITHOUT_MEDIA_START:
    'FETCH_PROGRAM_LIST_WITHOUT_MEDIA_START',
  FETCH_PROGRAM_LIST_WITHOUT_MEDIA_SUCCESS:
    'FETCH_PROGRAM_WITHOUT_MEDIA_LIST_SUCCESS',
  FETCH_PROGRAM_LIST_WITHOUT_MEDIA_FAILURE:
    'FETCH_PROGRAM_WITHOUT_MEDIA_LIST_FAILURE',
  FETCH_PROGRAM_LIST_WITHOUT_MEDIA_FINISHED:
    'FETCH_PROGRAM_WITHOUT_MEDIA_LIST_FINISHED'
};

export default ProgramListActionTypes;
