function partnerItemBuilder(itemPartner) {
  return {
    refRelationShipId: itemPartner?.refRelationShipId,
    refNaturalPerson: itemPartner?.refNaturalPerson,
    refLegalEntity: itemPartner?.refLegalEntity,
    lastName: itemPartner?.lastName,
    firstName: itemPartner?.firstName,
    contactPhone: itemPartner?.contactPhone,
    contactEmail: itemPartner?.contactEmail
  };
}

function partnerListBuilder(partnerList) {
  return partnerList?.map(item => {
    return partnerItemBuilder(item);
  });
}

export function partnerByLegalEntityBuilder(data) {
  return partnerListBuilder(data);
}
