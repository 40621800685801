import CategoryAdminTypes from './categoryAdmin.types';

const INITIAL_STATE = {
  result: null,
  error: null,
  isLoading: false,
  updated: false,
  created: false,
  deleted: false
};

const CategoryAdminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoryAdminTypes.UPDATE_CATEGORIES_START:
      return { ...state, isLoading: true, updated: false };

    case CategoryAdminTypes.DELETE_CATEGORY_START:
      return { ...state, isLoading: true, deleted: false };

    case CategoryAdminTypes.CREATE_CATEGORY_START:
      return { ...state, isLoading: true, created: false };

    case CategoryAdminTypes.UPDATE_CATEGORIES_SUCCESS:
      return { ...state, result: action.payload, updated: true, error: null };

    case CategoryAdminTypes.DELETE_CATEGORY_SUCCESS:
      return { ...state, result: action.payload, deleted: true, error: null };

    case CategoryAdminTypes.CREATE_CATEGORY_SUCCESS:
      return { ...state, result: action.payload, created: true, error: null };

    case CategoryAdminTypes.UPDATE_CATEGORIES_FAILURE:
      return { ...state, error: action.payload, updated: false };

    case CategoryAdminTypes.DELETE_CATEGORY_FAILURE:
      return { ...state, error: action.payload, deleted: false };

    case CategoryAdminTypes.CREATE_CATEGORY_FAILURE:
      return { ...state, error: action.payload, created: false };

    case CategoryAdminTypes.UPDATE_CATEGORIES_FINISHED:
      return { ...state, isLoading: false, updated: false };

    case CategoryAdminTypes.DELETE_CATEGORY_FINISHED:
      return { ...state, isLoading: false, deleted: false };

    case CategoryAdminTypes.CREATE_CATEGORY_FINISHED:
      return { ...state, isLoading: false, created: false };
    default:
      return state;
  }
};

export default CategoryAdminReducer;
