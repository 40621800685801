import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import texts from '../../constants/translates';
import { MIN_SEARCH_LENGTH } from '../../constants/global-constants';
import './style.scss';
import style from './style.jsx';
import SearchInput from '../../components/searchInput/SearchInput.jsx';
import AddMediaPage from '../addMediaPage/addMediaPage';
import { ROUTES_CONSTANTS } from '../../constants/route.constants';
import ModalComponent from '../../components/modal/ModalComponent/ModalComponent';
import { canEdit } from '../../shared/rights/root-rights';
import { ATTRIBUTES } from '../../shared/rights/roles';

const TopHomeBanner = ({ numberMedias, history }) => {
  const classes = makeStyles(style)();
  const [search, setSearch] = useState();
  const [isDisabledSearchButton, setIsDisabledSearchButton] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setIsDisabledSearchButton(search?.inputSearch?.length < MIN_SEARCH_LENGTH);
  }, [search]);

  const handleSearch = () => {
    history.push(
      `${ROUTES_CONSTANTS.searchBase}${search.inputSearch}/${search.refFilter}`
    );
  };

  return (
    <>
      <div className="topHomeBannerContainer">
        <p className="menu__title">
          {texts.formatString(texts.homeBannerContainer.title, {
            numberMedias
          })}
        </p>
        <Grid container>
          <Grid item xs={1} md={2} xl={3} />
          <Grid item xs={10} md={8} xl={6}>
            <div className="searchFrame">
              <div className="searchTitle">
                <p className="searchTitle__text">
                  {texts.homeBannerContainer.search}
                </p>
                {canEdit(true, ATTRIBUTES.BME_MEDIA_WRITE) && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonAddMedia}
                    onClick={() => setOpenModal(true)}
                    id="open-modal-button"
                  >
                    {texts.homeBannerContainer.addButton}
                  </Button>
                )}
              </div>
              <div className="search__container">
                <SearchInput
                  onSearchChange={e => setSearch(e)}
                  isDisabledSearchButton
                />
                <div className="search__button">
                  <Button
                    variant="contained"
                    id="searchButton"
                    color="primary"
                    className={classes.buttonSearch}
                    onClick={() => handleSearch()}
                    disabled={isDisabledSearchButton}
                  >
                    {texts.homeBannerContainer.searchButton}
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={1} md={2} xl={3} />
        </Grid>
      </div>
      <ModalComponent
        id="modal-component"
        open={openModal}
        body={
          <AddMediaPage history={history} onClose={() => setOpenModal(false)} />
        }
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

TopHomeBanner.propTypes = {
  numberMedias: PropTypes.number,
  history: PropTypes.func
};

export default withRouter(TopHomeBanner);
