const PublishUnpublishMedias = {
  PUBLISH_CHECKED_MEDIA_START: 'PUBLISH_CHECKED_MEDIA_START',
  PUBLISH_CHECKED_MEDIA_SUCCESS: 'PUBLISH_CHECKED_MEDIA_SUCCESS',
  PUBLISH_CHECKED_MEDIA_FAILURE: 'PUBLISH_CHECKED_MEDIA_FAILURE',
  PUBLISH_CHECKED_MEDIA_FINISHED: 'PUBLISH_CHECKED_MEDIA_FINISHED',

  UNPUBLISH_CHECKED_MEDIA_START: 'UNPUBLISH_CHECKED_MEDIA_START',
  UNPUBLISH_CHECKED_MEDIA_SUCCESS: 'UNPUBLISH_CHECKED_MEDIA_SUCCESS',
  UNPUBLISH_CHECKED_MEDIA_FAILURE: 'UNPUBLISH_CHECKED_MEDIA_FAILURE',
  UNPUBLISH_CHECKED_MEDIA_FINISHED: 'UNPUBLISH_CHECKED_MEDIA_FINISHED'
};

export default PublishUnpublishMedias;
