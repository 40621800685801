import React, { useEffect } from 'react';
import './GeneralConditions.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { canEdit } from '../../shared/rights/root-rights.js';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import Spinner from '../../components/spinner/Spinner';
import TextEditor from '../../components/textEditor/TextEditor';
import t from '../../constants/translates';
import { fetchConditionsStart } from '../../redux/conditions/conditions.actions';
import {
  selectConditionssResults,
  selectConditionssIsLoading
} from '../../redux/conditions/conditions.selectors';
import { sendPutConditionsDataStart } from '../../redux/putConditions/putConditionsActions';
import {
  selectPutConditionsIsLoading,
  selectPutConditionsSucceed
} from '../../redux/putConditions/putConditionsSelectors';
import { ATTRIBUTES } from '../../shared/rights/roles';

const GeneralConditions = ({
  getConditions,
  conditionsResult,
  putConditions,
  selectConditionssIsLoadingProp,
  selectPutConditionsIsLoadingProp,
  selectPutConditionsSucceedProp
}) => {
  useEffect(() => {
    getConditions();
  }, []);

  useEffect(() => {
    if (selectPutConditionsSucceedProp) {
      getConditions();
    }
  }, [selectPutConditionsSucceedProp]);

  const getLoadings = () => {
    return selectConditionssIsLoadingProp || selectPutConditionsIsLoadingProp;
  };

  return (
    <>
      {getLoadings() ? (
        <Spinner className="spinner" isLoading />
      ) : (
        <>
          <DismissableAlert />
          <h1 className="title">{t.footer.copyright}</h1>
          <div className="container">
            {conditionsResult?.length > 2 ? (
              <TextEditor
                dataProp={conditionsResult}
                isAdmin={canEdit(false, ATTRIBUTES.BME_CMS_WRITE)}
                putCms={putConditions}
              />
            ) : (
              <TextEditor
                isAdmin={canEdit(false, ATTRIBUTES.BME_CMS_WRITE)}
                putCms={putConditions}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

GeneralConditions.propTypes = {
  getConditions: PropTypes.func,
  conditionsResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  putConditions: PropTypes.func,
  selectConditionssIsLoadingProp: PropTypes.bool,
  selectPutConditionsIsLoadingProp: PropTypes.bool,
  selectPutConditionsSucceedProp: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  conditionsResult: selectConditionssResults,
  selectConditionssIsLoadingProp: selectConditionssIsLoading,
  selectPutConditionsIsLoadingProp: selectPutConditionsIsLoading,
  selectPutConditionsSucceedProp: selectPutConditionsSucceed
});

const mapDispatchToProps = dispatch => ({
  getConditions: () => dispatch(fetchConditionsStart()),
  putConditions: params => dispatch(sendPutConditionsDataStart(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConditions);
