import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { createStructuredSelector } from 'reselect';
import { PDFReader } from 'reactjs-pdf-view';
import text from '../../constants/translates';
import {
  infiniteScrollThreshold,
  MEDIA_TYPE,
  MAX_FILTER_COLLAPSE,
  bucketUplaod,
  pdfFormat
} from '../../constants/global-constants';
import './style.scss';
import styles from './style.jsx';

import { groupArrayByScreenSize } from '../../shared/functions/dynamicDisplay';
import VisualImageProgram from '../visual-image-program/VisualImageProgram.jsx';
import ImageCard from '../imageCard/ImageCard.jsx';
import { copyCodeToClipboard } from '../../shared/fixtures/functions/keyboard';
import VisualVideoProgram from '../visual-video-program/VisualVideoProgram';
import { getThumbnail } from '../../shared/functions/mediaFunctions';
import { fetchMediaDetailsStart } from '../../redux/media-details/media-details.actions';
import { getThumbnailsStart } from '../../redux/thumbnails/thumbnailsActions';
import {
  selectedThumbnails,
  selectedThumbnailsIsLoading
} from '../../redux/thumbnails/thumbnailsSelectors';
import {
  selectMediaDetails,
  selectMediaDetailsIsLoading
} from '../../redux/media-details/media-details.selectors';
import { selectedS3CallerResult } from '../../redux/s3-caller/s3-caller.selectors';

const MediaList = ({
  mediaList,
  fetchMoreData,
  currentPage,
  totalPages,
  isLoading,
  mediaDetails,
  fetchMediaDetails,
  detailsLoading,
  fetchGetThumbnails,
  thumbnails,
  thumbnailsLoading,
  category,
  s3CallerResult
}) => {
  const style = makeStyles(styles)();
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [itemsThumbnails, setItemsThumbnails] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState();
  const [thumbnailQueue, setThumbnailQueue] = useState({
    queue: [],
    isLoading: false
  });
  const [visualProgramVisibility, setVisualProgramVisibility] = useState([]);
  const classes = makeStyles(styles)();
  const getThumbnailsFromQueue = async () => {
    if (thumbnailQueue.queue.length > 0) {
      thumbnailQueue.isLoading = true;
      const fetchParams = thumbnailQueue.queue
        .splice(0, MAX_FILTER_COLLAPSE)
        .map(t => t.match);
      fetchGetThumbnails(fetchParams);
    }
  };

  const addMediasToQueue = medias => {
    const thumbnailsToRetreive = [];
    if (medias && itemsThumbnails) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < medias.length; i++) {
        const media = medias[i];
        if (
          !itemsThumbnails.find(
            t => t.thumbnailApiUrl === media.match.thumbnailUrl
          )
        ) {
          thumbnailsToRetreive.push(media);
        }
      }
      setThumbnailQueue({
        ...thumbnailQueue,
        queue: thumbnailQueue.queue.concat(thumbnailsToRetreive)
      });
    }
  };

  useEffect(() => {
    let medias = [];
    if (currentPage === 1) {
      medias = mediaList;
    } else {
      medias = items.concat(mediaList);
    }

    setItems(medias);
    setHasMore(currentPage < totalPages);
    addMediasToQueue(mediaList);
    setVisualProgramVisibility([]);
  }, [mediaList]);

  useEffect(() => {
    if (currentPage === 1) {
      setItemsThumbnails(thumbnails);
    } else {
      setItemsThumbnails(itemsThumbnails.concat(thumbnails));
    }

    if (thumbnails?.length > 0) {
      setThumbnailQueue({
        ...thumbnailQueue,
        isLoading: false
      });
    }
  }, [thumbnails]);

  useEffect(() => {
    if (!thumbnailQueue.isLoading) {
      getThumbnailsFromQueue();
    }
  }, [thumbnailQueue]);

  /**
   * Get the thumbnail of the media from the list
   * @param {Object} media object
   */
  const getThumbnailFromList = media => {
    // For the case that we have changed now the media
    if (media.refMedia === s3CallerResult.refMedia) {
      return s3CallerResult.s3LocalFileUrl;
    }

    const thumb = itemsThumbnails?.find(
      item => item.thumbnailApiUrl === media?.thumbnailUrl
    );

    // To know if the thumbnail is yet in upload before pass to the bucket ready
    const isInBucketUpload = thumb?.thumbnail?.match(bucketUplaod)?.length > 0;

    if (thumb?.thumbnail !== undefined && !isInBucketUpload) {
      return thumb?.thumbnail;
    }
    return getThumbnail(media);
  };

  /**
   * Returns true if media exists on list
   * @param {Object} media object
   */
  const thumbnailExist = media => {
    const index = itemsThumbnails?.findIndex(
      item => item.thumbnailApiUrl === media?.thumbnailUrl
    );
    return index > -1;
  };

  useEffect(() => {
    setSelectedMedia();
    setVisualProgramVisibility([]);
  }, [category]);

  useEffect(() => {
    if (selectedMedia !== undefined) {
      if (selectedMedia?.refMedia) {
        fetchMediaDetails(selectedMedia?.refMedia);
      }
    }
  }, [selectedMedia]);

  const next = () => {
    if (!isLoading) {
      fetchMoreData();
    }
  };

  const updateVisualProgramVisibility = (index, media) => {
    setSelectedMedia(media);
    const visibility = [];
    visibility[index] = true;
    setVisualProgramVisibility([...visibility]);
  };

  /**
   * Prints the loader component
   */
  const printLoader = () => {
    return (
      <div className="progress__container" key={0}>
        <LinearProgress className={style.progress} />
      </div>
    );
  };

  /**
   * Print image card of media
   * @param {Object} image object
   */
  const printMedias = (index, image) => {
    if (image.format === pdfFormat) {
      const url = getThumbnailFromList(image);

      return (
        <>
          <Card
            className={classes.card}
            onClick={() => updateVisualProgramVisibility(index, image)}
          >
            <CardActionArea className={classes.cardButton}>
              <CardContent>
                <PDFReader url={url} scale={0.22} />
                <p className={classes.title_name}>{image.title}</p>
              </CardContent>
            </CardActionArea>
          </Card>
        </>
      );
    }
    return (
      <>
        <ImageCard
          src={getThumbnailFromList(image)}
          name={image?.name}
          typeMedia={image?.typeMedia}
          loading={thumbnailsLoading && !thumbnailExist(image)}
        />
      </>
    );
  };

  /**
   * Return the correct visual corresponding to the media type
   */
  const getVisuals = () => {
    switch (selectedMedia?.typeMedia) {
      case MEDIA_TYPE.image:
        return (
          <VisualImageProgram
            image={mediaDetails}
            updateVisualProgramVisibility={updateVisualProgramVisibility}
          />
        );
      case MEDIA_TYPE.video:
        return (
          <VisualVideoProgram
            video={mediaDetails}
            updateVisualProgramVisibility={updateVisualProgramVisibility}
            copyCodeToClipboard={copyCodeToClipboard}
          />
        );
      case MEDIA_TYPE.document:
        return (
          <VisualImageProgram
            image={mediaDetails}
            updateVisualProgramVisibility={updateVisualProgramVisibility}
            isDocument
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="media-list">
      <p className="category-title">
        {items?.categorie}
        {text.space}
      </p>
      {items?.length > 0 ? (
        <InfiniteScroll
          threshold={infiniteScrollThreshold}
          key={uuidv4()}
          hasMore={hasMore}
          loadMore={next}
          loader={printLoader()}
        >
          {groupArrayByScreenSize(items)?.map((group, index) => (
            <div key={uuidv4()}>
              <div className="program-list">
                {group?.map(programWithSrc => {
                  return (
                    <div
                      className="media-item"
                      role="button"
                      tabIndex="0"
                      key={uuidv4()}
                      onClick={() =>
                        updateVisualProgramVisibility(
                          index,
                          programWithSrc?.match
                        )
                      }
                      onKeyPress={updateVisualProgramVisibility}
                    >
                      {printMedias(index, programWithSrc?.match)}
                    </div>
                  );
                })}
              </div>
              {visualProgramVisibility[index] &&
                (detailsLoading ? printLoader() : getVisuals())}
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <span className="category-title-results">
          {text.programListPage.noMedia}
        </span>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  mediaDetails: selectMediaDetails,
  detailsLoading: selectMediaDetailsIsLoading,
  thumbnails: selectedThumbnails,
  thumbnailsLoading: selectedThumbnailsIsLoading,
  s3CallerResult: selectedS3CallerResult
});

const mapDispatchToProps = dispatch => ({
  fetchMediaDetails: refMedia => dispatch(fetchMediaDetailsStart(refMedia)),
  fetchGetThumbnails: thumbnails => dispatch(getThumbnailsStart(thumbnails))
});

MediaList.propTypes = {
  mediaList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchMoreData: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  isLoading: PropTypes.bool,
  category: PropTypes.string,
  mediaDetails: PropTypes.oneOfType([PropTypes.string]),
  fetchMediaDetails: PropTypes.func,
  fetchGetThumbnails: PropTypes.func,
  detailsLoading: PropTypes.bool,
  thumbnails: PropTypes.oneOfType([PropTypes.string]),
  thumbnailsLoading: PropTypes.bool,
  s3CallerResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);
