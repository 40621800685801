import React, { useState, useEffect } from 'react';
import Cropper from 'react-cropper';
import { saveAs } from 'file-saver';
import 'cropperjs/dist/cropper.css';
import PropTypes from 'prop-types';
import './style.scss';
import { LinearProgress } from '@material-ui/core';

let croppedImage;
const ImageCrop = ({ imageSource, cropImage, setCropImageVisibility }) => {
  let cropper;
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageExtension = imageSource
    ?.split('.')
    [imageSource?.split('.').length - 1].split('?')[0]
    .split('&')[0];

  useEffect(() => {
    if (cropImage) {
      saveAs(croppedImage, `image_${Date.now()}.${imageExtension}`, {
        autoBom: true
      });
      setCropImageVisibility();
    }
  }, [cropImage]);

  useEffect(() => {
    if (imageSource && imageSource.length > 0) {
      fetch(imageSource, {
        method: 'GET',
        cache: 'no-cache'
      }).then(() => setImageLoaded(true));
    }
  }, imageSource);

  const crop = () => {
    croppedImage = cropper?.getCroppedCanvas().toDataURL();
  };
  return (
    <>
      {imageLoaded ? (
        <div>
          <Cropper
            crossOrigin="anonymous"
            key={Date.now()}
            checkCrossOrigin={false}
            ref={el => {
              cropper = el;
            }}
            src={imageSource}
            className="cropper"
            guides={false}
            crop={crop}
          />
        </div>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

ImageCrop.propTypes = {
  setCropImageVisibility: PropTypes.func,
  imageSource: PropTypes.string,
  cropImage: PropTypes.bool
};
export default ImageCrop;
