import createSectionTypes from './createSectionTypes';

export const CreateSectionStart = params => {
  return {
    type: createSectionTypes.CREATE_SECTION_START,
    payload: params
  };
};

export const CreateSectionSuccess = data => {
  return {
    type: createSectionTypes.CREATE_SECTION_SUCCESS,
    payload: data
  };
};

export const CreateSectionFailure = error => {
  return {
    type: createSectionTypes.CREATE_SECTION_FAILURE,
    payload: error
  };
};

export const CreateSectionFinish = () => {
  return {
    type: createSectionTypes.CREATE_SECTION_FINISH
  };
};
