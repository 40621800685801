import ConditionsActionTypes from './conditions.types';

export const fetchConditionsStart = () => ({
  type: ConditionsActionTypes.FETCH_CONDITIONS_START
});

export const fetchConditionsSuccess = data => ({
  type: ConditionsActionTypes.FETCH_CONDITIONS_SUCCESS,
  payload: data
});

export const fetchConditionsFailure = error => ({
  type: ConditionsActionTypes.FETCH_CONDITIONS_FAILURE,
  payload: error
});

export const fetchConditionsFinished = () => ({
  type: ConditionsActionTypes.FETCH_CONDITIONS_FINISHED
});
