export default {
  icon: {
    fontSize: '8px',
    color: '#4E5360',
    backgroundColor: '#F5F6FA',
    marginLeft: '5px',
    marginTop: '-15px'
  },

  gridBar: {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  },

  gridList: {
    width: '100%',
    margin: '0px'
  },

  folderIcon: {
    margin: 'auto',
    marginTop: '40px',
    display: 'block',
    width: '120px',
    height: '120px'
  },

  pdfIcon: {
    margin: 'auto',
    marginTop: '15%',
    display: 'block',
    width: '120px',
    height: '120px'
  },

  playIcon: {
    height: '100px',
    width: '100px',
    fill: 'white'
  },

  playIconGallery: {
    height: '100px',
    width: '100px',
    fill: '#695E5C',
    zIndex: '1',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto'
  },
  gridItem: {
    cursor: 'pointer',
    justifyContent: 'center',
    display: 'flex'
  },
  iconStyle: {
    fill: 'red'
  },
  iconStyleDisabled: {
    fill: 'grey'
  },
  title_name: {
    textAlign: 'center',
    marginTop: '1%',
    marginBottom: '1%',
    fontSize: '14px'
  },

  deleteIconDissabled: {
    padding: '2px',
    margin: '6px',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#C82832'
    },
    color: '#4E5360'
  },
  tooltip: {
    fontSize: '20px'
  }
};
