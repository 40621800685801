import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import 'core-js-pure/stable';
import 'regenerator-runtime/runtime';
import { Provider } from 'react-redux';
import 'react-widgets/dist/css/react-widgets.css';
import { ThemeProvider } from '@material-ui/core';
import { store } from './redux/store';
import theme from './styles/theme';
import App from './App.jsx';
import './index.scss';

const history = createHistory();
history.listen(() => {
  window.scrollTo(0, 0);
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('app')
);
