import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import LastMediasTypes from './lastMedias.types';

import api from '../../api/api.jsx';
import {
  fetchLastMediasSuccess,
  fetchLastMediasFailure,
  fetchLastMediasFinished
} from './lastMedias.actions';
import { searchBuilder } from '../search/search-builder';

export const selectCreateProgram = state => state.createProgram;

export function* fetchLastMedias({ payload: params }) {
  try {
    const { data } = yield call(api.getLastMedias, params);
    const { communes } = yield select(selectCreateProgram);
    const dataBuilder = yield call(searchBuilder, data, communes);
    yield put(fetchLastMediasSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchLastMediasFailure(error));
  }
  yield put(fetchLastMediasFinished());
}

export function* onFetchLastMediasStart() {
  yield takeLatest(LastMediasTypes.FETCH_LAST_MEDIAS_START, fetchLastMedias);
}

export function* lastMediasSagas() {
  yield all([call(onFetchLastMediasStart)]);
}
