import S3CallerTypes from './s3-caller.types';

const INITIAL_STATE = {
  succeed: false,
  error: null,
  isLoading: false,
  s3LocalFileUrl: null,
  refMedia: null,
  refProgram: null
};

const s3CallerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case S3CallerTypes.SEND_S3_DATA_START:
      return { ...state, isLoading: true, succeed: false };
    case S3CallerTypes.SEND_S3_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        succeed: true,
        s3LocalFileUrl: action.payload.localUrl,
        refMedia: action.payload.refMedia,
        refProgram: action.payload.refProgram
      };
    case S3CallerTypes.SEND_S3_DATA_FAILURE:
      return { ...state, error: action.payload, succeed: false };
    case S3CallerTypes.SEND_S3_DATA_FINISHED:
      return { ...state, isLoading: false, succeed: false };

    default:
      return state;
  }
};

export default s3CallerReducer;
