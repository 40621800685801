import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const AppRoute = props => {
  const { path } = props;
  return (
    <Route
      path={path}
      render={routeProps => (
        <>
          {props.navBar ? React.createElement(props.navBar, routeProps) : null}
          <div className="page-container">
            {React.createElement(props.component, routeProps)}
          </div>
        </>
      )}
    />
  );
};

AppRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object]),
  path: PropTypes.string.isRequired,
  navBar: PropTypes.oneOfType([PropTypes.object])
};

export default AppRoute;
