import { put, all, call, takeLatest } from 'redux-saga/effects';
import FolderProgramActionTypes from './folder-program.types';
import { folderProgramBuilder } from './folder-program.builder';
import api from '../../api/api.jsx';
import {
  fetchFolderProgramSuccess,
  fetchFolderProgramFailure,
  fetchFolderProgramFinish
} from './folder-program.actions';

export function* fetchFolderProgram({ payload: params }) {
  try {
    const { data } = yield call(api.getFolderByProgram, params);
    const dataBuilder = yield call(folderProgramBuilder, data);
    yield put(fetchFolderProgramSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchFolderProgramFailure(error));
  }
  yield put(fetchFolderProgramFinish());
}

export function* onFetchFolderProgramStart() {
  yield takeLatest(
    FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_START,
    fetchFolderProgram
  );
}

export function* folderProgramSagas() {
  yield all([call(onFetchFolderProgramStart)]);
}
