const societyBuilder = legalEntity => {
  return legalEntity?.level[0]?.value.map(item => {
    return {
      name: item.longLabel,
      quality: item.valueCode
    };
  });
};

export function societyQualityBuilder(data) {
  return societyBuilder(data);
}
