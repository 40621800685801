import societyQualitiesActionTypes from './society-qualities.types';

export const fetchSocietyQualitiesStart = () => ({
  type: societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_START
});

export const fetchSocietyQualitiesSuccess = data => ({
  type: societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_SUCCESS,
  payload: data
});

export const fetchSocietyQualitiesFailure = error => ({
  type: societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_FAILURE,
  payload: error
});

export const fetchSocietyQualitiesFinished = () => ({
  type: societyQualitiesActionTypes.FETCH_SOCIETY_QUALITIES_FINISHED
});
