import { createSelector } from 'reselect';

const programModification = state => state.programUpdate;

export const selectedConfirmMessage = createSelector(
  [programModification],
  message => message
);

export const selectedProgramUpdateError = createSelector(
  [programModification],
  error => error?.error
);

export const selectedProgramUpdateSucceed = createSelector(
  [programModification],
  programUpdate => programUpdate.succeed
);
