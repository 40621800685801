import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import noImage from '../../../assets/images/no-image-icon.png';
import AlertDialogSlide from '../../dialog/dialog';
import text from '../../../constants/translates';
import styles from './style';
import './style.scss';
import {
  MEDIA_TYPE,
  SEARCH_TYPE,
  snackbarDuration
} from '../../../constants/global-constants';
import { snackBarSeverity } from '../../../constants/ui.constants';
import SnackbarAlert from '../../snackBar/SnackbarAlert';
import { selectSearchResults } from '../../../redux/search/search.selectors';

const AdminSectionPanel = ({
  section,
  onChangeSectionName,
  onDeleteSection,
  onSave,
  fetchSearchResults,
  currentResults
}) => {
  const fileUpload = useRef(null);
  const onFileUploadClick = () => {
    fileUpload.current.click();
  };

  const classes = makeStyles(styles)();
  const [sectionName, setSectionName] = useState(section?.name);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [toastMessage, setToastMessage] = useState(false);
  const [toastseverity, setToastseverity] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  /**
   * Setting change section state name to SectionName hook
   */
  useEffect(() => {
    setSectionName(section?.name);
    setImage(section?.urlSection || section?.url);
    const params = {
      type: SEARCH_TYPE.media,
      section: section?.refSection?.toUpperCase(),
      size: 1
    };
    fetchSearchResults(params);
  }, [section]);

  /**
   * Handle when input change
   * @param {Object} e Event
   */
  const handleChangeInput = e => {
    setSectionName(e.target.value);
    onChangeSectionName(e.target.value);
  };

  const isSaveButtonDisabled = () =>
    sectionName?.length < 2 || sectionName === undefined;

  /**
   * Handle when delete button is clicked
   */
  const handleDelete = () => {
    if (currentResults?.page?.totalElements === 0) {
      onDeleteSection(section?.refSection);
    } else {
      setToastMessage(text.AdminCategories.deleteSectionMedias);
      setToastseverity(snackBarSeverity.error);
      setOpenToast(true);
    }
  };

  /**
   * Handle when alert dialog confirmed
   */
  const handleAction = () => {
    handleDelete();
    setOpenAlertDialog(false);
  };

  /**
   * Handle to close Alert Dialog
   */
  const handleClose = () => {
    setOpenAlertDialog(false);
  };

  const handleFileUpload = () => {
    const selectedType = fileUpload.current.files[0]?.type
      ?.split('/')[0]
      .toUpperCase();

    if (selectedType === MEDIA_TYPE?.image) {
      setFile(fileUpload.current.files[0]);
      setImage(URL.createObjectURL(fileUpload.current.files[0]));
    } else {
      setToastMessage(text.fileUploader.forbiddenAction);
      setToastseverity(snackBarSeverity.error);
      setOpenToast(true);
      fileUpload.current.value = '';
    }
  };

  return (
    <div className="column-container">
      <SnackbarAlert
        id="AddedAlert"
        open={openToast}
        severity={toastseverity}
        message={toastMessage}
        handleClose={() => setOpenToast(false)}
        duration={snackbarDuration}
      />
      <input
        id="inputSectionName"
        className="section-name__input"
        type="text"
        placeholder={text.AdminCategories.newSection}
        value={sectionName || ''}
        onChange={e => handleChangeInput(e)}
      />
      <p className="section-vignette_text">
        {text.AdminCategories.vignetteLabel}
      </p>
      <img
        crossOrigin="anonymous"
        id="vignette"
        alt=""
        className="image-thumbnail"
        src={image || noImage}
      />
      <div>
        <IconButton
          color="inherit"
          variant="text"
          id="editImage"
          className={classes.editImageButton}
          onClick={onFileUploadClick}
        >
          {image
            ? text.AdminCategories.editImage
            : text.AdminCategories.addImage}
        </IconButton>
        <input
          type="file"
          ref={fileUpload}
          className="input-file"
          onChange={handleFileUpload}
        />
      </div>
      {section?.deleteable && (
        <div>
          <IconButton
            color="inherit"
            variant="text"
            id="deleteSection"
            className={classes.deleteSectionButton}
            onClick={() => setOpenAlertDialog(true)}
          >
            <DeleteOutlinedIcon /> {text.AdminCategories.deleteSection}
          </IconButton>
        </div>
      )}
      <Button
        id="saveButton"
        variant="outlined"
        color="primary"
        disabled={isSaveButtonDisabled()}
        className={classes.saveButton}
        onClick={() => {
          onSave(file);
          setFile(null);
        }}
      >
        {text.save}
      </Button>
      <AlertDialogSlide
        open={openAlertDialog}
        title={text.AdminCategories.alertMessage}
        textButtonOk={text.visualProgram.textButtonOk}
        textButtonCancel={text.visualProgram.textButtonCancel}
        handleAction={handleAction}
        handleClose={handleClose}
      />
    </div>
  );
};

AdminSectionPanel.propTypes = {
  section: PropTypes.objectOf(PropTypes.object()),
  onChangeSectionName: PropTypes.func,
  onDeleteSection: PropTypes.func,
  onSave: PropTypes.func,
  currentResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchSearchResults: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  currentResults: selectSearchResults
});

export default connect(mapStateToProps, null)(AdminSectionPanel);
