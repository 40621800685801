const subCategoryListBuilder = subcategoryList => {
  return subcategoryList.map(subitem => {
    return {
      id: subitem.refSubCategory,
      name: subitem.name
    };
  });
};

const categoryListBuilder = categoryList => {
  return categoryList.map(item => {
    return {
      id: item.refCategory,
      name: item.name,
      subCategories: subCategoryListBuilder(item.subCategories)
    };
  });
};

export const categoryBuilder = data => {
  return categoryListBuilder(data);
};
