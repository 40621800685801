import SubformatTypes from './subformat.types';

export const fetchSubformatStart = () => ({
  type: SubformatTypes.FETCH_SUBFORMAT_START
});

export const fetchSubformatSuccess = params => ({
  type: SubformatTypes.FETCH_SUBFORMAT_SUCCESS,
  payload: params
});

export const fetchSubformatFailure = error => ({
  type: SubformatTypes.FETCH_SUBFORMAT_FAILURE,
  payload: error
});

export const fetchSubformatFinished = () => ({
  type: SubformatTypes.FETCH_SUBFORMAT_FINISHED
});
