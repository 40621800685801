import CreateProgram from './createProgramTypes.js';

export const sendFormDataStart = formData => ({
  type: CreateProgram.SEND_FORM_DATA_START,
  payload: formData
});

export const sendFormDataSuccess = message => ({
  type: CreateProgram.SEND_FORM_DATA_SUCCESS,
  payload: message
});

export const sendFormDataFailure = error => ({
  type: CreateProgram.SEND_FORM_DATA_FAILURE,
  payload: error
});

export const sendFormDataFinished = () => ({
  type: CreateProgram.SEND_FORM_DATA_FINISHED
});

export const fetchTypeOfProyectsStart = () => ({
  type: CreateProgram.FETCH_TYPE_OF_PROJECTS_START
});

export const fetchTypeOfProyectsSuccess = data => ({
  type: CreateProgram.FETCH_TYPE_OF_PROJECTS_SUCCESS,
  payload: data
});

export const fetchTypeOfProyectsFailure = error => ({
  type: CreateProgram.FETCH_TYPE_OF_PROJECTS_FAILURE,
  payload: error
});

export const fetchTypeOfProyectsFinished = () => ({
  type: CreateProgram.FETCH_TYPE_OF_PROJECTS_FINISHED
});

export const fetchDestinationPropertyStart = () => ({
  type: CreateProgram.FETCH_DESTINATION_PROPERTY_START
});

export const fetchDestinationPropertySuccess = data => ({
  type: CreateProgram.FETCH_DESTINATION_PROPERTY_SUCCESS,
  payload: data
});

export const fetchDestinationPropertyFailure = error => ({
  type: CreateProgram.FETCH_DESTINATION_PROPERTY_FAILURE,
  payload: error
});

export const fetchDestinationPropertyFinished = () => ({
  type: CreateProgram.FETCH_DESTINATION_PROPERTY_FINISHED
});

export const fetchRegionsStart = () => ({
  type: CreateProgram.FETCH_REGIONS_START
});

export const fetchRegionsSuccess = data => ({
  type: CreateProgram.FETCH_REGIONS_SUCCESS,
  payload: data
});

export const fetchRegionsFailure = error => ({
  type: CreateProgram.FETCH_REGIONS_FAILURE,
  payload: error
});

export const fetchRegionsFinished = () => ({
  type: CreateProgram.FETCH_REGIONS_FINISHED
});

export const fetchDepartmentsStart = () => ({
  type: CreateProgram.FETCH_DEPARTMENTS_START
});

export const fetchDepartmentsSuccess = data => ({
  type: CreateProgram.FETCH_DEPARTMENTS_SUCCESS,
  payload: data
});

export const fetchDepartmentsFailure = error => ({
  type: CreateProgram.FETCH_DEPARTMENTS_FAILURE,
  payload: error
});

export const fetchDepartmentsFinished = () => ({
  type: CreateProgram.FETCH_DEPARTMENTS_FINISHED
});

export const fetchCommunesStart = () => ({
  type: CreateProgram.FETCH_COMMUNES_START
});

export const fetchCommunesSuccess = data => ({
  type: CreateProgram.FETCH_COMMUNES_SUCCESS,
  payload: data
});

export const fetchCommunesFailure = error => ({
  type: CreateProgram.FETCH_COMMUNES_FAILURE,
  payload: error
});

export const fetchCommunesFinished = () => ({
  type: CreateProgram.FETCH_COMMUNES_FINISHED
});
