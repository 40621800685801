import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Collapse, IconButton } from '@material-ui/core';
import text from '../../constants/translates';
import StyledTreeItem from './StyledTreeItem';
import facetsTranslates from '../../constants/facets-translates.jsx';
import { MAX_FILTER_COLLAPSE } from '../../constants/global-constants';
import style from './style.jsx';
import facetsTranslatesVitrine from '../../constants/facets-translates-vitrine.jsx';
import { selectOrganizationalEntitysResult } from '../../redux/organizationalEntity/organizationalEntity.selectors.js';
import { checkVitrine } from '../../shared/functions/vitrine';

const FilterSection = ({
  facet,
  onSectionFilterChanged,
  parents,
  history,
  isVitrine,
  organizationalEntitysResult,
  filterState
}) => {
  const classes = makeStyles(style)();
  const [checked, setChecked] = useState(false);
  const [valueChecked, setValueChecked] = useState();
  const [facetDivision, setFacetDivision] = useState({
    normal: [],
    showMore: []
  });
  let facets;

  const changeFacets = () => {
    if (checkVitrine(history) === false) {
      facets = facetsTranslates;
    } else {
      facets = facetsTranslatesVitrine;
    }
  };

  const saveChecked = value => {
    setValueChecked(value);
  };

  useEffect(() => {
    let faceWithUO = facet.buckets;
    // For add organizational entity parent to filials facets info
    if (facet.name === 'organizationalentitypaths') {
      faceWithUO = facet.buckets?.filter(el => {
        const orga = organizationalEntitysResult.find(
          organization => organization.pathOrganizationalEntity === el.value
        );
        // eslint-disable-next-line no-param-reassign
        el.organizationalEntityParent = orga?.organizationalEntityParent;
        return el;
      });
    }

    const newFacet = {
      normal: faceWithUO?.slice(0, MAX_FILTER_COLLAPSE),
      showMore: faceWithUO?.slice(MAX_FILTER_COLLAPSE)
    };

    setFacetDivision(newFacet);
  }, [facet]);

  const handleCheckListChanged = checkList => {
    if (onSectionFilterChanged) {
      onSectionFilterChanged(checkList);
    }
  };

  const collapseItems = () => {
    return (
      <Collapse in={checked}>
        {facetDivision?.showMore?.map(bucket => {
          return (
            <StyledTreeItem
              bucket={bucket}
              fieldName={facet?.name}
              key={bucket?.value}
              onCheckListChanged={checkList =>
                handleCheckListChanged(checkList)
              }
              isVitrine={isVitrine}
              saveChecked={saveChecked}
              valueChecked={valueChecked}
            />
          );
        })}
      </Collapse>
    );
  };

  const treeViewSection = (
    <TreeView
      disableSelection
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
    >
      {facetDivision?.normal?.map(bucket => {
        return (
          <StyledTreeItem
            bucket={bucket}
            fieldName={facet?.name}
            key={bucket?.value}
            onCheckListChanged={checkList => handleCheckListChanged(checkList)}
            isVitrine={isVitrine}
            saveChecked={saveChecked}
            valueChecked={valueChecked}
            filterState={filterState}
          />
        );
      })}
      {facetDivision?.showMore?.length > 0 && (
        <>
          {collapseItems()}
          <IconButton
            onClick={() => setChecked(!checked)}
            className={classes.showMoreButton}
          >
            {checked ? (
              <>
                <ExpandLessIcon /> {text.filter.less}
              </>
            ) : (
              <>
                <ExpandMoreIcon /> {text.filter.more}
              </>
            )}
          </IconButton>
        </>
      )}
    </TreeView>
  );

  const showFacetsByParents = () => {
    if (
      !facets[facet?.name]?.parent ||
      parents[facets[facet?.name]?.parent]?.length > 0
    ) {
      return treeViewSection;
    }

    return (
      <p className="section__subtitle">
        <ExpandMoreIcon />
      </p>
    );
  };
  changeFacets();

  return (
    <div
      className={`hierarchy-${
        facets[facet?.name]?.hierarchy ? facets[facet?.name]?.hierarchy : 0
      }`}
    >
      <p className="filter-section__title">{facets[facet?.name]?.name}</p>
      {showFacetsByParents()}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  organizationalEntitysResult: selectOrganizationalEntitysResult
});

FilterSection.propTypes = {
  facet: PropTypes.oneOfType([PropTypes.string]),
  onSectionFilterChanged: PropTypes.func,
  parents: PropTypes.oneOfType([PropTypes.string]),
  history: PropTypes.func,
  isVitrine: PropTypes.bool,
  organizationalEntitysResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  filterState: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

export default connect(mapStateToProps, null)(FilterSection);
