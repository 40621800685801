const COLUMS = 3;

/**
 * Return de correct array secuence to display in
 * MediaGallery in order to show in correct line
 * the visual image component.
 * @param {Array} mediaList List of medias
 * @param {Array} folderList List of folders
 * @param {Array} selectedIndex Index of selected media
 */
export function galleryDisplay(mediaList, folderList, selectedIndex) {
  let result = [];

  if (mediaList !== undefined) {
    const resultMedia = mediaList?.map((item, index) => {
      return {
        type: 'media',
        payload: item,
        index
      };
    });
    result = result.concat(resultMedia);
  }

  if (folderList !== undefined) {
    const resultFolder = folderList?.map(item => {
      return {
        type: 'folder',
        payload: item
      };
    });
    result = result.concat(resultFolder);
  }
  result.push({
    type: 'folder',
    payload: 'newFolder'
  });
  if (selectedIndex > -1) {
    const setIndex = Math.ceil((selectedIndex + 1) / COLUMS) * COLUMS;
    result.splice(setIndex, 0, {
      type: 'selectedMedia',
      payload: selectedIndex
    });
  }
  return result;
}
