import loginActionTypes from './loginTypes';

export const fetchLoginStart = () => ({
  type: loginActionTypes.FETCH_LOGIN_START
});

export const fetchLoginSuccess = data => ({
  type: loginActionTypes.FETCH_LOGIN_SUCCESS,
  payload: data
});

export const fetchLoginFailure = error => ({
  type: loginActionTypes.FETCH_LOGIN_FAILURE,
  payload: error
});

export const fetchLoginFinished = () => ({
  type: loginActionTypes.FETCH_LOGIN_FINISHED
});

export const fetchLoginUpdateStart = () => ({
  type: loginActionTypes.FETCH_LOGIN_UPDATE_START
});

export const fetchLoginUpdateSuccess = data => ({
  type: loginActionTypes.FETCH_LOGIN_UPDATE_SUCCESS,
  payload: data
});

export const fetchLoginUpdateFailure = error => ({
  type: loginActionTypes.FETCH_LOGIN_UPDATE_FAILURE,
  payload: error
});

export const fetchLoginUpdateFinished = () => ({
  type: loginActionTypes.FETCH_LOGIN_UPDATE_FINISHED
});
