import DownloadTypes from './download.types.js';

export const downloadStart = medias => ({
  type: DownloadTypes.DOWNLOAD_START,
  payload: medias
});

export const downloadSuccess = medias => ({
  type: DownloadTypes.DOWNLOAD_SUCCESS,
  payload: medias
});

export const downloadFailure = error => ({
  type: DownloadTypes.DOWNLOAD_FAILURE,
  payload: error
});

export const downloadFinished = () => ({
  type: DownloadTypes.DOWNLOAD_FINISHED
});
