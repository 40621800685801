import { createSelector } from 'reselect';

const programDetails = state => state.programDetails;

export const selectProgramDetails = createSelector(
  [programDetails],
  program => program.programDetails
);

export const selectProgramDetailsIsLoading = createSelector(
  [programDetails],
  program => program.isLoading
);

export const selectProgramDetailError = createSelector(
  [programDetails],
  program => program.error
);
