import FormatTypes from './format.types';

export const fetchFormatStart = () => ({
  type: FormatTypes.FETCH_FORMAT_START
});

export const fetchFormatSuccess = params => ({
  type: FormatTypes.FETCH_FORMAT_SUCCESS,
  payload: params
});

export const fetchFormatFailure = error => ({
  type: FormatTypes.FETCH_FORMAT_FAILURE,
  payload: error
});

export const fetchFormatFinished = () => ({
  type: FormatTypes.FETCH_FORMAT_FINISHED
});
