import OrganizationalEntityTypes from './organizationalEntity.types';

const INITIAL_STATE = {
  result: [],
  error: null,
  isLoading: false,
  isLoadingCompany: false,
  companyDataResult: null
};

const OrganizationalEntityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrganizationalEntityTypes.FETCH_ORGANIZATIONALENTITY_START:
      return { ...state, isLoading: true };
    case OrganizationalEntityTypes.FETCH_COMPANY_DATA_START:
      return { ...state, isLoadingCompany: true };

    case OrganizationalEntityTypes.FETCH_ORGANIZATIONALENTITY_SUCCESS:
      return { ...state, result: action.payload, error: null };
    case OrganizationalEntityTypes.FETCH_COMPANY_DATA_SUCCESS:
      return { ...state, companyDataResult: action.payload, error: null };

    case OrganizationalEntityTypes.FETCH_ORGANIZATIONALENTITY_FAILURE:
    case OrganizationalEntityTypes.FETCH_COMPANY_DATA_FAILURE:
      return { ...state, error: action.payload };

    case OrganizationalEntityTypes.FETCH_ORGANIZATIONALENTITY_FINISHED:
      return { ...state, isLoading: false };
    case OrganizationalEntityTypes.FETCH_COMPANY_DATA_FINISHED:
      return { ...state, isLoadingCompany: false };

    default:
      return state;
  }
};

export default OrganizationalEntityReducer;
