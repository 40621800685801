export default {
  iconButton: {
    position: 'absolute',
    top: '17px',
    right: '40px'
  },
  icon: {
    fontSize: '25px'
  },
  rootTabs: {
    flexGrow: 1,
    display: 'flex',
    height: 224
  },
  gridContainer: {
    height: 'inherit'
  },
  titleRow: {
    height: '15%'
  },
  contentRow: {
    height: '85%',
    margin: 32,
    textAlign: 'left'
  },
  succesIcon: {
    width: '200px',
    height: '200px',
    color: '#219653'
  },
  errorIcon: {
    width: '200px',
    height: '200px',
    color: 'red'
  },
  button: {
    width: 280,
    height: 48,
    background: '#C82832',
    borderRadius: 10,
    color: '#F5F6FA'
  },
  loader: {
    width: 200,
    height: 200
  }
};
