import { createSelector } from 'reselect';

const selectPutConditions = state => state.putConditions;

export const selectPutConditionsMessage = createSelector(
  [selectPutConditions],
  putConditions => putConditions.message
);

export const selectPutConditionsIsLoading = createSelector(
  [selectPutConditions],
  putConditions => putConditions.isLoading
);

export const selectPutConditionsError = createSelector(
  [selectPutConditions],
  putConditions => putConditions.error
);

export const selectPutConditionsSucceed = createSelector(
  [selectPutConditions],
  putConditions => putConditions.succeed
);
