import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DateFnsUtils from '@date-io/date-fns';
import './style.scss';
import { Combobox } from 'react-widgets';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { Radio } from '@material-ui/core';
import t from '../../constants/translates';
import {
  MEDIA_TYPE,
  formatDatePicker,
  selectCategory,
  selectSubCategory,
  selectTag,
  pdfFormat
} from '../../constants/global-constants';
import { parseDate } from '../../shared/functions/date';

import { fetchSupportStart } from '../../redux/media-details/media-details.actions';
import { selectSupportList } from '../../redux/media-details/media-details.selectors';

/**
 * Component used for the media categorization or modification for set or change the data of the media
 */
const MediaDataForm = ({
  handleCategories,
  currentCategories,
  Categorie,
  subCategories,
  subCategorie,
  setSubCategorie,
  handleTagChange,
  addTagTolist,
  getTagsSpanList,
  copyright,
  handleCopyright,
  showDatePicker,
  setShowDatePicker,
  selectedDate,
  getTagsDataList,
  tag,
  setSelectedDate,
  refSupport,
  onSupportChange,
  supportList,
  fetchSupportList,
  mediaDetailsResult,
  isProgramIR,
  pdfTitle,
  handlePdfTitle
}) => {
  useEffect(() => {
    if (!supportList) {
      fetchSupportList();
    }
  }, []);

  const isMediaSection = () => {
    return !(
      mediaDetailsResult?.program?.section?.refSection ||
      mediaDetailsResult?.folder?.program?.section?.refSection
    );
  };

  /** Render of combo for select the category subcategory or tag */
  const comboBoxForm = (
    id,
    placeholder,
    data,
    value,
    handleOnChange,
    handleKeyPress
  ) => {
    // We only disable the category combobox and fix his value to video if is program IR
    let comboDisabled = false;
    let categoryDataValue = value;
    let categoryDataSource = data;
    if (
      id === selectCategory &&
      mediaDetailsResult?.typeMedia?.refValue === MEDIA_TYPE.video &&
      isProgramIR
    ) {
      categoryDataSource = data?.filter(
        item => item.refCategory === MEDIA_TYPE.video
      );
      comboDisabled = isProgramIR;
      if (categoryDataSource?.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        categoryDataValue = categoryDataSource[0];
      }
    }
    return (
      <div className="header__input__container">
        <Combobox
          id={id}
          valueField="id"
          className="combobox"
          textField="name"
          placeholder={placeholder}
          data={categoryDataSource}
          value={categoryDataValue}
          messages={{
            emptyList: t.noResults,
            emptyFilter: t.noResults
          }}
          onChange={e => handleOnChange(e)}
          onKeyPress={handleKeyPress}
          disabled={comboDisabled}
        />
      </div>
    );
  };

  /** Render of combo for select tag */
  const comboBoxTagForm = (
    id,
    placeholder,
    data,
    value,
    handleOnChange,
    handleKeyPress
  ) => {
    return (
      <div className="header__input__container">
        <Combobox
          id={id}
          valueField="id"
          className="combobox"
          textField="name"
          placeholder={placeholder}
          data={data}
          value={value}
          selectIcon={null}
          filter="contains"
          messages={{
            emptyList: t.noResults,
            emptyFilter: t.noResults
          }}
          onChange={e => handleOnChange(e)}
          onKeyPress={handleKeyPress}
        />
      </div>
    );
  };

  /**
   * Handle when change support value
   * @param {String} value refSupport
   */
  const handleChangeSupport = value => {
    const sup = supportList?.find(s => s.refSupport === value);
    onSupportChange(sup);
  };

  /** Render of the radiobutton for select the support */
  const radioSupport = (supportNumber, name) => {
    return (
      <>
        <Radio
          checked={refSupport === supportNumber}
          onChange={event => handleChangeSupport(event.target.value)}
          value={supportNumber}
          name={name}
          color="default"
        />
        <p className="radio__input">{name}</p>
      </>
    );
  };

  /** For delete the date of usage of the media */
  const supprimerDateUtilisation = () => {
    setSelectedDate();
    setShowDatePicker(false);
  };

  const isPDF = () => {
    if (mediaDetailsResult?.format === pdfFormat) {
      return (
        <input
          id="pdfTitle"
          className="text__field margin-top"
          placeholder={t.carousel.pdfTitle}
          value={pdfTitle}
          onChange={e => {
            handlePdfTitle(e.target.value);
          }}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      {isPDF()}
      {comboBoxForm(
        selectCategory,
        t.carousel.selectCategory,
        currentCategories,
        Categorie,
        handleCategories
      )}
      {comboBoxForm(
        selectSubCategory,
        t.carousel.selectSubCategory,
        subCategories,
        subCategorie,
        e =>
          setSubCategorie(
            subCategories.find(item => {
              return item.refSubCategory === e.refSubCategory;
            })
          )
      )}
      {comboBoxTagForm(
        selectTag,
        t.carousel.addTag,
        getTagsDataList(),
        tag,
        handleTagChange,
        addTagTolist
      )}
      <div className="header__input__container">
        <p>{getTagsSpanList()}</p>
      </div>
      {mediaDetailsResult?.format !== pdfFormat && (
        <>
          <div className="header__input__container">
            <input
              id="copyright"
              className="text__field margin-top"
              placeholder={
                isMediaSection()
                  ? `${t.carousel.addCopyright} *`
                  : t.carousel.addCopyright
              }
              value={copyright}
              onChange={e => handleCopyright(e.target.value)}
            />
          </div>
        </>
      )}
      {isMediaSection() && mediaDetailsResult?.format !== pdfFormat && (
        <>
          <div className="header__input__container">
            {!showDatePicker ? (
              <>
                <div
                  className="select-date"
                  onClick={() => setShowDatePicker(true)}
                  role="button"
                  tabIndex="0"
                  onKeyPress={() => setShowDatePicker(true)}
                >
                  <p className="header__input underlined">
                    {t.carousel.addUseDuration}
                  </p>
                </div>
                <p className="header__input help">
                  {t.carousel.useUtilisationHelp}
                </p>
                <p className="header__input underlined">
                  {t.carousel.useUtilisation}
                </p>
              </>
            ) : (
              <>
                <div className="select-date">
                  <div
                    onClick={() => supprimerDateUtilisation()}
                    className="supprimer-date"
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => supprimerDateUtilisation()}
                  >
                    <p className="header__input underlined">
                      {t.carousel.deleteUseDuration}
                    </p>
                  </div>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      invalidDateMessage={t.carousel.errorMessageDatePicker}
                      className="input-date-picker"
                      variant="inline"
                      format={formatDatePicker}
                      margin="none"
                      id="date-picker-inline"
                      value={selectedDate}
                      onChange={date => setSelectedDate(parseDate(date))}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </>
            )}
            <p className="header__input">{t.carousel.support}</p>
            <div className="support__container">
              {supportList?.map(item => {
                return radioSupport(item?.refSupport, item?.name);
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

MediaDataForm.propTypes = {
  handleCategories: PropTypes.func,
  currentCategories: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  Categorie: PropTypes.string,
  subCategories: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  subCategorie: PropTypes.string,
  setSubCategorie: PropTypes.func,
  handleTagChange: PropTypes.func,
  addTagTolist: PropTypes.func,
  getTagsSpanList: PropTypes.func,
  getTagsDataList: PropTypes.func,
  tag: PropTypes.string,
  copyright: PropTypes.string,
  handleCopyright: PropTypes.func,
  showDatePicker: PropTypes.bool,
  setShowDatePicker: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
  setSelectedDate: PropTypes.func,
  refSupport: PropTypes.string,
  onSupportChange: PropTypes.func,
  supportList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchSupportList: PropTypes.func,
  mediaDetailsResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  isProgramIR: PropTypes.bool,
  pdfTitle: PropTypes.string,
  handlePdfTitle: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  supportList: selectSupportList
});

const mapDispatchToProps = dispatch => ({
  fetchSupportList: () => dispatch(fetchSupportStart())
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDataForm);
