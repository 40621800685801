export const getResolutionFromInstance = (
  instances,
  format,
  hasCopyright,
  subformats,
  ORIGINAL_MEDIA_INSTANCE,
  originalResolution
) => {
  let hasCopy = hasCopyright;
  if (hasCopy === undefined) {
    hasCopy = false;
  }

  const currentSubformats = [];
  const currentSubformatsValue = instances
    ?.filter(el => {
      return (
        el.format.refValue === format?.valueCode && el.copyright === hasCopy
      );
    })
    .map(instance => instance?.subFormat?.refValue)
    .reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );

  // eslint-disable-next-line no-unused-expressions
  currentSubformatsValue?.map(currentSubformat => {
    return currentSubformats.push(
      subformats?.result?.filter(el => {
        return el?.valueCode === currentSubformat;
      })?.[0]
    );
  });

  if (format?.valueCode === ORIGINAL_MEDIA_INSTANCE?.valueCode) {
    const resolutionOriginal = { ...ORIGINAL_MEDIA_INSTANCE };
    resolutionOriginal.shortLabel = originalResolution;
    currentSubformats.push(resolutionOriginal);
  }
  return currentSubformats;
};

export const getFormatFromInstance = (
  instances,
  hasCopyright,
  formats,
  ORIGINAL_MEDIA_INSTANCE
) => {
  let hasCopy = hasCopyright;
  if (hasCopy === undefined) {
    hasCopy = false;
  }
  const currentFormats = [];
  const currentFormatsValue = instances
    ?.filter(el => {
      return el.copyright === hasCopy;
    })
    .map(instance => instance?.format?.refValue)
    .reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );

  // eslint-disable-next-line no-unused-expressions
  currentFormatsValue?.map(currentFormat => {
    return currentFormats.push(
      formats?.result?.filter(el => {
        return el?.valueCode === currentFormat;
      })?.[0]
    );
  });
  currentFormats.push(ORIGINAL_MEDIA_INSTANCE);
  return currentFormats;
};
