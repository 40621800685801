export const mediaStatus = {
  upload: 'UPLOAD',
  /** Original Media being uploaded to GED. */
  gedPending: 'GED_PENDING',
  /** Original Media already uploaded to GED. */
  gedOk: 'GED_OK',
  /** Media in bucket transform. */
  transform: 'TRANSFORM',
  /** Media in bucket ready. */
  ready: 'READY',
  /** Media in bucket ready to move to bucket public. */
  topublish: 'TO_PUBLISH',
  /** Media in bucket public. */
  published: 'PUBLISHED',
  /** Media in bucket public to move to bucket ready. */
  toUnpublished: 'TO_UNPUBLISH',
  /** The error with lambda codec. */
  errorCodec: 'ERROR_CODEC',
  /** The error in lambda resizing. */
  errorResize: 'ERROR_RESIZE',
  /** The error of too small size format. */
  errorQualitySize: 'ERROR_QUALITY_SIZE',
  /** The error with GED connection. */
  errorGed: 'ERROR_GED',
  /** Default error. */
  error: 'ERROR',
  errorUploadMediaNotFound: 'ERROR_UPLOAD_MEDIA_NOT_FOUND'
};
