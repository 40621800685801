import CreateProgram from './createProgramTypes';

const INITIAL_STATE = {
  message: null,
  error: null,
  isLoading: false,
  isLoadingTypeProjects: false,
  typeOfProjects: null,
  destinationProperty: null,
  isLoadingDestinationProperty: false,
  isLoadingRegions: false,
  isLoadingDepartments: false,
  isLoadingCommunes: false,
  departments: null,
  regions: null,
  communes: null
};

const createProgramReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreateProgram.SEND_FORM_DATA_START:
      return { ...state, isLoading: true };
    case CreateProgram.FETCH_TYPE_OF_PROJECTS_START:
      return { ...state, isLoadingTypeProjects: true };
    case CreateProgram.FETCH_DESTINATION_PROPERTY_START:
      return { ...state, isLoadingDestinationProperty: true };
    case CreateProgram.FETCH_REGIONS_START:
      return { ...state, isLoadingRegions: true };
    case CreateProgram.FETCH_DEPARTMENTS_START:
      return { ...state, isLoadingDepartments: true };
    case CreateProgram.FETCH_COMMUNES_START:
      return { ...state, isLoadingCommunes: true };

    case CreateProgram.SEND_FORM_DATA_SUCCESS:
      return { ...state, message: action.payload, error: null };
    case CreateProgram.FETCH_TYPE_OF_PROJECTS_SUCCESS:
      return { ...state, typeOfProjects: action.payload, error: null };
    case CreateProgram.FETCH_DESTINATION_PROPERTY_SUCCESS:
      return { ...state, destinationProperty: action.payload, error: null };
    case CreateProgram.FETCH_REGIONS_SUCCESS:
      return { ...state, regions: action.payload, error: null };
    case CreateProgram.FETCH_DEPARTMENTS_SUCCESS:
      return { ...state, departments: action.payload, error: null };
    case CreateProgram.FETCH_COMMUNES_SUCCESS:
      return { ...state, communes: action.payload, error: null };

    case CreateProgram.SEND_FORM_DATA_FAILURE:
    case CreateProgram.FETCH_TYPE_OF_PROJECTS_FAILURE:
    case CreateProgram.FETCH_DESTINATION_PROPERTY_FAILURE:
    case CreateProgram.FETCH_REGIONS_FAILURE:
    case CreateProgram.FETCH_DEPARTMENTS_FAILURE:
    case CreateProgram.FETCH_COMMUNES_FAILURE:
      return { ...state, error: action.payload };

    case CreateProgram.SEND_FORM_DATA_FINISHED:
      return { ...state, isLoading: false };
    case CreateProgram.FETCH_DESTINATION_PROPERTY_FINISHED:
      return { ...state, isLoadingDestinationProperty: false };
    case CreateProgram.FETCH_TYPE_OF_PROJECTS_FINISHED:
      return { ...state, isLoadingTypeProjects: false };
    case CreateProgram.FETCH_REGIONS_FINISHED:
      return { ...state, isLoadingRegions: false };
    case CreateProgram.FETCH_DEPARTMENTS_FINISHED:
      return { ...state, isLoadingDepartments: false };
    case CreateProgram.FETCH_COMMUNES_FINISHED:
      return { ...state, isLoadingCommunes: false };

    default:
      return state;
  }
};

export default createProgramReducer;
