import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import style from './style.jsx';
import TabPanelCopyPaste from '../tabPanelCopyPaste/TabPanelCopyPaste';
import texts from '../../../constants/translates';

/**
 * Tab component with program tabs and its related panels
 */
const TabCopyPaste = ({ sections, media }) => {
  const classes = makeStyles(style)();
  const [tabValue, setTabValue] = useState(0);

  /**
   * Handle when tab is changed
   * @param {Object} event onChanged event
   * @param {Number} newValue changed tab value
   */
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /**
   * Gets the label to be display on tab for a program (section)
   * @param {Object} section Section concerning a program
   */
  const getLabel = section => {
    return `${texts.resultsSearchPage.programs} ${section.type}`;
  };

  /**
   * Properties related to tab panel for a Tab
   * @param {Number} index Tab index
   */
  function allProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`
    };
  }

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={3} className={classes.gridContainer}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          TabIndicatorProps={{
            style: style.indicator
          }}
          className={classes.tabs}
        >
          {sections?.map((section, index) => {
            return (
              <Tab
                className={classes.tab}
                label={getLabel(section)}
                key={section.refSection}
                {...allProps(index)}
              />
            );
          })}
        </Tabs>
      </Grid>
      <Grid item xs={9} className={classes.gridContainer}>
        <TabPanelCopyPaste section={sections[tabValue]} media={media} />
      </Grid>
    </Grid>
  );
};

TabCopyPaste.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  media: PropTypes.oneOfType([PropTypes.string])
};

export default TabCopyPaste;
