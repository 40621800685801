import { put, all, call, takeLatest } from 'redux-saga/effects';
import UrlInstanceTypes from './urlInstances.types';
import api from '../../api/api.jsx';
import {
  fetchURLInstanceSuccess,
  fetchURLInstanceFailure,
  fetchURLInstanceFinished
} from './urlInstances.actions';
import { getHigherInstance } from '../../shared/functions/instances';

export function* fetchURLMediaWithCopy({ payload: media }) {
  const instance = yield getHigherInstance(media);
  if (instance) {
    try {
      const { data } = yield call(api.getInstanceURL, {
        refFile: instance?.refFile,
        refMedia: media?.refMedia
      });
      yield put(fetchURLInstanceSuccess(data));
    } catch (error) {
      yield put(fetchURLInstanceFailure(error));
    }
  } else {
    yield put(fetchURLInstanceSuccess({}));
  }
  yield put(fetchURLInstanceFinished());
}
export function* onFetchURLMediaWithCopy() {
  yield takeLatest(
    UrlInstanceTypes.FETCH_URL_INSTANCE_START,
    fetchURLMediaWithCopy
  );
}

export function* fetchURLMediaWithCopySagas() {
  yield all([call(onFetchURLMediaWithCopy)]);
}
