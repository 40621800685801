/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PDFReader } from 'reactjs-pdf-view';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import { createStructuredSelector } from 'reselect';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Button, Grid, Typography, IconButton } from '@material-ui/core';
import './style.scss';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactPlayer from 'react-player';
import t from '../../constants/translates';
import styles from './style.jsx';
import {
  operation,
  dateFormat,
  MEDIA_TYPE,
  snackbarDuration,
  imageTypes,
  videoTypes,
  minTagChar,
  ENTER_KEY,
  THEME_NOM_TYPE,
  PATCH_PATHS,
  PROGRAM_TYPE,
  DEFAULT_VIDEO_THUMB,
  pdfWidth,
  pdfScale,
  pdfFormat,
  fileTypes,
  MEDIA_STATUS,
  EMPTY_STRING
} from '../../constants/global-constants';
import { counter } from '../../shared/functions/counter';
import {
  snackBarSeverity,
  maxLengthComment
} from '../../constants/ui.constants';
import AlertDialogSlide from '../../components/dialog/dialog';
import { sendModificationDataStart } from '../../redux/media-modification/media-modification.actions';
import { fetchMediaDetailsStart } from '../../redux/media-details/media-details.actions';
import {
  selectMediaDetails,
  selectMediaDetailsIsLoading
} from '../../redux/media-details/media-details.selectors';
import Spinner from '../../components/spinner/Spinner';
import { sendDeleteDataStart } from '../../redux/media-delete/media-delete.actions';
import {
  selectDeleteMediaIsLoading,
  selectDeleteMediaSucceed
} from '../../redux/media-delete/media-delete.selectors';
import {
  selectedModificationMedias,
  modificationIsLoading,
  modificationSucceed
} from '../../redux/media-modification/media-modification.selectors';
import { sendS3DataStart } from '../../redux/s3-caller/s3-caller.actions';
import {
  selectedS3CallerisLoading,
  selectedS3CallerSucceed
} from '../../redux/s3-caller/s3-caller.selectors';
import DismissableAlert from '../../components/alert/DismissableAlert';
import {
  handleMediaModification,
  handleMediaCategoryModification,
  patchConstructor,
  getCategoryName,
  getSubCategoryName,
  handleMediaModificationSupport,
  getFormatFile
} from '../../shared/functions/mediaFunctions';
import { fetchSectionStart } from '../../redux/section/sections/section.actions';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import { fetchTagListStart } from '../../redux/tag-list/tag.list.actions';
import { selectTagListResult } from '../../redux/tag-list/tag.list.selectors';
import { createTagStart } from '../../redux/createTag/create.tag.actions';
import { selectCreatedTag } from '../../redux/createTag/create.tag.selectors';
import { toLocalDate } from '../../shared/functions/date';
import SnackbarAlert from '../../components/snackBar/SnackbarAlert.jsx';
import MediaDataForm from '../../components/mediaDataForm/MediaDataForm';
import { canEditArray } from '../../shared/rights/root-rights';
import { ATTRIBUTES } from '../../shared/rights/roles';
import { getPathOrganizationalEntitybyRef } from '../../shared/functions/cleoFunctions';
import { resetThumbnails } from '../../redux/thumbnails/thumbnailsActions';
import mediaDetailsActionTypes from '../../redux/media-details/media-details.types';
import { checkAvenir } from '../../shared/functions/checkAvenir.js';
import { selectedCallback } from '../../redux/modeController/modeController.selectors.js';
import { selectSearchResults } from '../../redux/search/search.selectors.js';

/**
 * Component to modify 1 media
 */
const MediaModification = ({
  match,
  history,
  fetchMediaDetails,
  mediaDetailsResult,
  isLoading,
  deleteMediaIsLoading,
  sendModificationData,
  sendModificationLoading,
  sendModificationSucceed,
  modificationResult,
  sendDeleteData,
  fetchS3Data,
  s3CallerIsLoading,
  s3CallerSucceed,
  deleteMediaSucceed,
  fetchTagList,
  tagList,
  fetchCreateTag,
  createdTag,
  fetchSections,
  sections,
  resetThumbnailsProp,
  callback,
  searchProgramResults
}) => {
  const fileUpload = useRef(null);
  const onFileUploadClick = () => {
    fileUpload.current.click();
  };
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [toastseverity, setToastseverity] = useState(false);
  const style = makeStyles(styles)();
  const [selectedDate, setSelectedDate] = useState();
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState();
  const [onCreationTagName, setOnCreationTagName] = useState();
  const [support, setSupportValue] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [Categorie, setCategorie] = useState(null);
  const [copyright, setCopyright] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [initialSupport, setIntialSupport] = useState();
  const [subCategorie, setSubCategorie] = useState({
    id: '',
    name: ''
  });
  const [title, setTitle] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(null);
  const [light, setLight] = useState(null);
  const [isMediaSection, setMediaSection] = useState(false);
  const refMedia = match?.params.id;
  const mediaResolutions = [];
  const [currentCategories, setCurrentCategories] = useState([]);
  const [section, setSection] = useState();
  const [isProgramIR, setIsProgramIR] = useState(false);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [program, setProgram] = useState();
  const [pdfUrl, setPdfUrl] = useState();
  const [comment, setComment] = useState('');

  useEffect(() => {
    setProgram(searchProgramResults);
  }, [searchProgramResults]);
  // Variables from params URL to know who call the categorization page
  const refId = useParams();
  const isMediaProgram = refId.type !== undefined;
  // Style for the icon of the video media
  const playIconStyle = {
    height: '200px',
    width: '200px',
    fill: 'white'
  };

  /**
   * Function for get the categorys et subcategorys
   */
  const fillCategories = () => {
    if (!mediaDetailsResult || !sections) {
      return;
    }
    const actualSection =
      mediaDetailsResult?.folder?.program?.section?.refSection ||
      mediaDetailsResult?.program?.section?.refSection ||
      mediaDetailsResult?.section?.refSection;
    setCurrentCategories(
      sections.filter(
        sectionFilter => sectionFilter.refSection === actualSection
      )[0]?.categories
    );

    setCategorie({
      id: mediaDetailsResult?.category?.refCategory,
      name: getCategoryName(
        currentCategories,
        mediaDetailsResult?.category?.refCategory
      ),
      level: mediaDetailsResult?.category?.level,
      listCode: mediaDetailsResult?.category?.listCode
    });
    setSubCategorie({
      refSubCategory: mediaDetailsResult?.subCategory?.refSubCategory,
      name: getSubCategoryName(
        currentCategories,
        mediaDetailsResult?.category?.refCategory,
        mediaDetailsResult?.subCategory?.refSubCategory
      ),
      level: mediaDetailsResult?.subCategory?.level
    });

    setSubCategories(
      currentCategories?.filter(obj => {
        return obj.refCategory === mediaDetailsResult?.category?.refCategory;
      })[0]?.subCategory
    );
  };

  useEffect(() => {
    fetchMediaDetails(refMedia);
    if (!sections.length > 0) {
      fetchSections();
    }
  }, []);

  /**
   * Once the tag is created, add it in tags
   */
  useEffect(() => {
    if (createdTag?.refTag && onCreationTagName) {
      const addTag = {
        refTag: createdTag.refTag,
        name: onCreationTagName
      };
      setTags([...tags, addTag]);
    }
  }, [createdTag]);

  /**
   * Effect for get the data one time we have the result of the back services
   */
  useEffect(() => {
    setCopyright(mediaDetailsResult?.copyright || EMPTY_STRING);
    setTitle(mediaDetailsResult?.title || EMPTY_STRING);
    setImage(mediaDetailsResult?.mediaURL);
    setPdfUrl(mediaDetailsResult?.mediaURL);
    setUrl(mediaDetailsResult?.mediaURL);
    setTags(mediaDetailsResult?.tags);
    setLight(mediaDetailsResult?.thumbnail);
    setComment(mediaDetailsResult?.comment || '');
    const sectionFinded = sections?.find(sec => {
      return sec.refSection === mediaDetailsResult?.section?.refSection;
    });
    setSection(sectionFinded);

    fillCategories();
    if (mediaDetailsResult?.durationOfUse) {
      setSelectedDate(mediaDetailsResult?.durationOfUse);
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }

    const mediaSupport =
      mediaDetailsResult?.support && mediaDetailsResult?.support[0];
    if (mediaSupport) {
      setSupportValue({
        refSupport: mediaSupport?.refValue,
        level: mediaSupport?.level,
        listCode: mediaSupport?.listCode
      });

      setIntialSupport(mediaSupport);
    }
  }, [mediaDetailsResult, sections]);

  useEffect(() => {
    if (section) {
      setMediaSection(section?.type === THEME_NOM_TYPE);
    }
  }, [section]);

  /**
   * Effect for get the data one tima we have the result of the back sections services
   */
  useEffect(() => {
    fillCategories();
  }, [sections]);

  /**
   * Effect for show the datepicker and change the date
   */
  useEffect(() => {
    if (!showDatePicker) {
      setSelectedDate();
    } else if (!selectedDate) {
      setSelectedDate(moment());
    }
  }, [showDatePicker]);

  useEffect(() => {
    fillCategories();
  }, [currentCategories]);

  useEffect(() => {
    // Boolean for know if we are in 1 media from Program type IR
    if (isMediaProgram) {
      setIsProgramIR(
        mediaDetailsResult?.program?.section?.refSection ===
          PROGRAM_TYPE.IR_PROGRAM_NAME
      );
    }
    fillCategories();
  }, [mediaDetailsResult, currentCategories]);

  const handleGoBack = () => {
    history.goBack();
  };

  /**
   * Effects for change the AWS S3 image after we have modified the data of the media
   */
  useEffect(() => {
    if (file) {
      const params = {
        url: modificationResult?.urlS3,
        file,
        localUrl: URL.createObjectURL(file),
        refMedia,
        refProgram: mediaDetailsResult?.program?.refProgram
      };
      fetchS3Data(params);
    }
  }, [modificationResult]);

  useEffect(() => {
    if (sendModificationSucceed) {
      handleGoBack();
    }
  }, [sendModificationSucceed]);

  useEffect(() => {
    if (s3CallerSucceed || deleteMediaSucceed) {
      resetThumbnailsProp();
      handleGoBack();
    }
  }, [s3CallerSucceed, deleteMediaSucceed]);

  const handleCategories = e => {
    setCategorie({
      id: e.refCategory,
      name: e.shortLabel,
      level: e.level,
      listCode: e.listCode
    });
    setSubCategories(e.subCategory);

    setSubCategorie(null);
  };

  const handleAction = () => {
    sendDeleteData(refMedia);
    setOpen(false);
  };

  const handleCopyright = value => {
    return setCopyright(value);
  };

  const handlePdfTitle = value => {
    return setTitle(value);
  };

  const handleComment = value => {
    return setComment(value);
  };

  const handleTagChange = e => {
    if (e.length >= minTagChar) {
      fetchTagList(e);
    }
    setTag(e);
  };

  const addTagTolist = e => {
    if (tag?.length === 0) {
      return;
    }

    if (e.key === ENTER_KEY) {
      const addTag = tagList.find(tagFind => tagFind.name === e.target.value);

      if (addTag) {
        if (!tags?.find(item => item.refTag === addTag.refTag)) {
          setTags([...tags, addTag]);
        }
      } else {
        fetchCreateTag({ name: e.target.value });
        setOnCreationTagName(e.target.value);
      }
      setTag('');
    }
  };

  const removeTag = tagToRemove => {
    setTags(tags.filter(item => item.refTag !== tagToRemove.refTag));
  };

  /**
   * Get the list of tags span elements
   */
  const getTagsSpanList = () => {
    return tags?.map(tagValue => (
      <span key={tagValue.refTag} className="tag__field">
        <span>{tagValue.name}</span>
        <span
          className="close__field"
          role="button"
          tabIndex="0"
          onKeyPress={() => removeTag(tagValue)}
          onClick={() => removeTag(tagValue)}
        >
          {t.close}
        </span>
      </span>
    ));
  };

  /**
   * Function for know if the user has made some change for activate the visibility of the save button
   */
  const genericSaveVisibility = () => {
    if (mediaDetailsResult?.format === pdfFormat) {
      return (
        copyright === mediaDetailsResult?.copyright &&
        title === mediaDetailsResult?.title &&
        Categorie?.id === mediaDetailsResult?.category?.refCategory &&
        subCategorie?.refSubCategory ===
          mediaDetailsResult?.subCategory?.refSubCategory &&
        file === null &&
        tags === mediaDetailsResult?.tags &&
        support?.refSupport === initialSupport
      );
    }
    if (comment?.length > maxLengthComment) {
      return true;
    }
    return (
      copyright === mediaDetailsResult?.copyright &&
      title === mediaDetailsResult?.title &&
      Categorie?.id === mediaDetailsResult?.category?.refCategory &&
      subCategorie?.refSubCategory ===
        mediaDetailsResult?.subCategory?.refSubCategory &&
      file === null &&
      tags === mediaDetailsResult?.tags &&
      support?.refSupport === initialSupport &&
      comment === mediaDetailsResult?.comment
    );
  };

  /**
   * The same that genericSaveVisibility() but in the case of SectionMedias with his copyright
   */
  const saveVisibilityForTheme = () => {
    if (mediaDetailsResult?.format !== pdfFormat) {
      if (!copyright) {
        return true;
      }
    } else {
      return (
        genericSaveVisibility() &&
        selectedDate === mediaDetailsResult?.durationOfUse
      );
    }
  };

  const saveVisibility = () => {
    if (isMediaSection) {
      return saveVisibilityForTheme();
    }
    return genericSaveVisibility();
  };

  const handleFileUpload = e => {
    const selectedType = fileUpload.current.files[0]?.type
      ?.split('/')[0]
      .toUpperCase();
    const mediaType = fileUpload.current.files[0]?.type
      ?.split('/')[1]
      .toUpperCase();
    if (
      selectedType === mediaDetailsResult?.typeMedia?.refValue ||
      (mediaDetailsResult?.typeMedia?.refValue === MEDIA_TYPE.document &&
        mediaType === pdfFormat)
    ) {
      setFile(fileUpload.current.files[0]);
      setImage(URL.createObjectURL(fileUpload.current.files[0]));
      setLight(DEFAULT_VIDEO_THUMB);
      if (
        mediaDetailsResult?.typeMedia?.refValue === MEDIA_TYPE.document &&
        mediaType === pdfFormat
      ) {
        var reader = new FileReader();
        let base64String;
        reader.onload = () => {
          base64String = reader.result;
          setPdfUrl(
            'data:application/pdf;base64,'.concat(
              base64String.substr(base64String.indexOf(',') + 1)
            )
          );
        };
        reader.readAsDataURL(e);
      }
    } else {
      setToastMessage(t.fileUploader.forbiddenAction);
      setToastseverity(snackBarSeverity.error);
      setOpenToast(true);
      fileUpload.current.value = '';
    }
  };

  /**
   * Gets the tags list to show in combobox
   */
  const getTagsDataList = () => {
    if (tag?.length < minTagChar || tagList.find(item => item.name === tag)) {
      return tagList;
    }

    return [{ name: tag }].concat(tagList);
  };

  /**
   * Constructor to create patch tag operations
   * @param {Object} mediaModification Array with media modification json patch operations
   */
  const mediaModificationConstructorTags = mediaModification => {
    if (tags) {
      tags.forEach(tagValue => {
        handleMediaModification(
          tagValue.refTag,
          mediaDetailsResult?.tags?.find(
            mediaTag => mediaTag.refTag === tagValue.refTag
          )?.refTag,
          mediaModification,
          'tags/-'
        );
      });
    }

    const tagsRemoveOperations = [];

    if (mediaDetailsResult?.tags) {
      mediaDetailsResult.tags.forEach((mediaTag, index) => {
        handleMediaModification(
          tags?.find(tagFind => tagFind.refTag === mediaTag.refTag)?.refTag,
          mediaTag.refTag,
          tagsRemoveOperations,
          `tags/${index}`
        );
      });
    }

    tagsRemoveOperations.reverse();
    Array.prototype.push.apply(mediaModification, tagsRemoveOperations);
    return mediaModification;
  };

  const shouldSendSupportData = () => {
    if (support?.refSupport) {
      return {
        listCode: support?.listCode,
        level: support?.level?.toString(),
        refValue: support?.refSupport
      };
    }
    return null;
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  /**
   * Function for build the modifications object with the modifications of the media
   * @param {object} mediaModification object with the media mdifications to send to back
   */
  const mediaModificationConstructor = mediaModification => {
    mediaModificationConstructorTags(mediaModification);

    handleMediaModification(
      copyright,
      mediaDetailsResult?.copyright,
      mediaModification,
      'copyright'
    );
    handleMediaModification(
      title,
      mediaDetailsResult?.title,
      mediaModification,
      'title'
    );

    handleMediaModificationSupport(
      shouldSendSupportData(),
      mediaDetailsResult?.support[0],
      mediaModification,
      PATCH_PATHS.support
    );
    if (mediaResolutions.length > 0) {
      if (mediaDetailsResult.format === pdfFormat) {
        mediaModification.push(
          patchConstructor(
            operation.replaceOperation,
            `/size`,
            mediaResolutions.size
          )
        );
      } else {
        mediaModification.push(
          patchConstructor(
            operation.replaceOperation,
            `/size`,
            mediaResolutions.size
          ),
          patchConstructor(
            operation.replaceOperation,
            `/width`,
            mediaResolutions.width
          ),
          patchConstructor(
            operation.replaceOperation,
            `/height`,
            mediaResolutions.height
          ),
          patchConstructor(
            operation.replaceOperation,
            '/contentType',
            mediaResolutions.contentType
          ),
          patchConstructor(
            operation.replaceOperation,
            '/format',
            mediaResolutions.format.toUpperCase()
          )
        );
      }
    }
    if (isMediaSection) {
      if (!selectedDate && mediaDetailsResult?.durationOfUse) {
        mediaModification.push(
          patchConstructor(
            operation.removeOperation,
            `/${PATCH_PATHS.expirationDate}`
          )
        );
      } else if (selectedDate) {
        handleMediaModification(
          moment(selectedDate).format(dateFormat.YMD),
          mediaDetailsResult?.durationOfUse,
          mediaModification,
          PATCH_PATHS.expirationDate
        );
      }
    }

    handleMediaCategoryModification(
      Categorie,
      mediaDetailsResult?.category?.refCategory,
      mediaModification,
      'category',
      Categorie?.listCode
    );

    handleMediaCategoryModification(
      subCategorie,
      mediaDetailsResult?.subCategory?.refSubCategory,
      mediaModification,
      'subCategory',
      Categorie?.listCode
    );
    if (file && file?.type === fileTypes[0]) {
      handleMediaModification(
        file?.name,
        mediaDetailsResult?.nameExt,
        mediaModification,
        'nameExt'
      );
    }
    handleMediaModification(
      comment,
      mediaDetailsResult?.comment,
      mediaModification,
      'description'
    );
    return mediaModification;
  };

  const handleSave = () => {
    let mediaModification = [];
    mediaModification = mediaModificationConstructor(mediaModification);
    sendModificationData({
      mediaModification,
      refMediaSend: refMedia
    });
  };

  const onMediaLoad = ({ target: img }, name) => {
    mediaResolutions.name = name;
    mediaResolutions.height = img.offsetHeight;
    mediaResolutions.width = img.offsetWidth;
    mediaResolutions.size = file?.size;
    mediaResolutions.length = 1;
    mediaResolutions.contentType = file.type;
    mediaResolutions.format = getFormatFile(file);
  };
  const onPDFLoad = name => {
    mediaResolutions.name = name;
    mediaResolutions.size = file?.size;
    mediaResolutions.length = 1;
  };

  const printMedia = () => {
    return (
      <div key={mediaDetailsResult?.refMedia}>
        <div className={style.cardIconGroup}>
          <IconButton
            id="changePhotoIcon"
            color="inherit"
            aria-label="menu"
            className={style.cardIcon}
            onClick={onFileUploadClick}
          >
            <EditOutlinedIcon />
            {t.mediaModification.changerMedia}
          </IconButton>
          <input
            data-testid="file-upload"
            id="file-upload"
            type="file"
            ref={fileUpload}
            className="input-file"
            onChange={event => handleFileUpload(event.target.files[0] || null)}
          />
        </div>
        {mediaDetailsResult?.typeMedia?.refValue === MEDIA_TYPE.video ? (
          <ReactPlayer
            url={image}
            light={light}
            playing
            controls
            playIcon={<PlayCircleOutlineOutlinedIcon style={playIconStyle} />}
          />
        ) : mediaDetailsResult?.typeMedia?.refValue === MEDIA_TYPE.image ? (
          <img
            crossOrigin="anonymous"
            key={mediaDetailsResult?.refMedia}
            id={mediaDetailsResult?.refMedia}
            className="current-media"
            src={image}
            name={mediaDetailsResult?.name}
            alt={mediaDetailsResult?.name}
          />
        ) : (
          (pdfUrl !== undefined) & (pdfUrl !== null) && (
            <div className="pdf-container">
              <PDFReader
                key={pdfUrl}
                url={pdfUrl}
                width={pdfWidth}
                scale={pdfScale}
              />
            </div>
          )
        )}
      </div>
    );
  };

  if (
    !canEditArray(
      getPathOrganizationalEntitybyRef(mediaDetailsResult?.program?.refProgram),
      ATTRIBUTES.BME_MEDIA_WRITE
    )
  ) {
    return <div></div>;
  }

  return (
    <>
      <DismissableAlert />
      <SnackbarAlert
        id="AddedAlert"
        open={openToast}
        severity={toastseverity}
        message={toastMessage}
        handleClose={() => setOpenToast(false)}
        duration={snackbarDuration}
      />
      <Spinner
        className="spinner"
        isLoading={
          isLoading ||
          deleteMediaIsLoading ||
          s3CallerIsLoading ||
          sendModificationLoading
        }
      />
      <>
        <div className="title__div">
          <h1 className="title">{t.mediaModification.title}</h1>
          <span className="deleteMedia">
            {checkAvenir(program?.results?.withMedia[0]?.match) &&
            callback === null &&
            (mediaDetailsResult?.status === MEDIA_STATUS.published ||
              mediaDetailsResult?.status === MEDIA_STATUS.toPublish) ? (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <Typography fontSize={30}>
                        {t.mediaModification.tooltipDelete}
                      </Typography>
                    }
                    className={style.tooltip}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="menu"
                      className={style.deleteIconDissabled}
                      onClick={handleTooltipOpen}
                    >
                      {t.mediaModification.delete}
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            ) : (
              <IconButton
                color="inherit"
                aria-label="menu"
                className={style.deleteIcon}
                onClick={() => setOpen(true)}
              >
                {t.mediaModification.delete}
                <DeleteForeverOutlinedIcon />
              </IconButton>
            )}
          </span>
        </div>
        {isMediaSection ? (
          <p className="subTitle">{`${section?.name}`}</p>
        ) : (
          mediaDetailsResult?.program?.code && (
            <p className="subTitle">
              {`${mediaDetailsResult?.program?.section?.nameSection} : ${mediaDetailsResult?.program?.code} ${mediaDetailsResult?.program?.name}`}
            </p>
          )
        )}
        <Grid container spacing={2} className="carousel__container">
          <Grid item xs={6}>
            {printMedia()}
            <div>
              <p className="header__input">
                {t.formatString(t.carousel.fileName, {
                  name: mediaDetailsResult?.nameExt
                })}
              </p>
              <p className="header__input">
                {t.formatString(t.carousel.date, {
                  date: toLocalDate(mediaDetailsResult?.dateUpload)
                })}
              </p>
              <p className="header__input">
                {t.formatString(t.carousel.userInfo, {
                  user: mediaDetailsResult?.userUpload,
                  filial: 'Nexity'
                })}
              </p>
              <p className="header__input">
                {mediaDetailsResult?.typeMedia?.refValue !==
                  MEDIA_TYPE.document && (
                  <span className="formatData">
                    {t.formatString(t.carousel.resolution, {
                      resolution: mediaDetailsResult?.resolution
                    })}
                  </span>
                )}
                <span className="formatData">
                  {t.formatString(t.carousel.weight, {
                    weight: mediaDetailsResult?.size
                  })}
                </span>
                <span className="formatData">
                  {t.space}
                  {t.formatString(t.carousel.format, {
                    format: mediaDetailsResult?.format
                  })}
                </span>
              </p>
            </div>
            {mediaDetailsResult?.format !== pdfFormat && (
              <div>
                <textarea
                  id="comment"
                  className="text__area margin-top"
                  onChange={e => {
                    handleComment(e.target.value);
                  }}
                  value={comment}
                  maxLength={maxLengthComment}
                ></textarea>
                {comment?.length < maxLengthComment - 1 ? (
                  <>
                    <p>
                      {t.formatString(t.counterTextArea.caracteresRestants, {
                        numCaracter: counter(maxLengthComment, comment?.length)
                      })}
                    </p>
                  </>
                ) : (
                  <p
                    className={
                      counter(maxLengthComment, comment?.length) === 0
                        ? 'rouge'
                        : ''
                    }
                  >
                    {t.formatString(t.counterTextArea.caracterRestant, {
                      numCaracter: counter(maxLengthComment, comment?.length)
                    })}
                  </p>
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={6} className={style.categoriesContainer}>
            <MediaDataForm
              mediaDetailsResult={mediaDetailsResult}
              handleCategories={handleCategories}
              currentCategories={currentCategories}
              Categorie={Categorie}
              subCategories={subCategories}
              subCategorie={subCategorie}
              setSubCategorie={setSubCategorie}
              handleTagChange={handleTagChange}
              addTagTolist={addTagTolist}
              getTagsSpanList={getTagsSpanList}
              getTagsDataList={getTagsDataList}
              tag={tag}
              isMediaSection
              copyright={copyright}
              handleCopyright={handleCopyright}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              refSupport={support?.refSupport}
              onSupportChange={setSupportValue}
              isProgramIR={isProgramIR}
              pdfTitle={title}
              handlePdfTitle={handlePdfTitle}
            />
            <Button
              variant="contained"
              className={`${style.button} `}
              color="secondary"
              onClick={handleGoBack}
            >
              <Typography className={style.buttonText}>{t.cancel}</Typography>
            </Button>
            <Button
              id="saveButton"
              variant="contained"
              className={`${style.button}`}
              color="primary"
              onClick={handleSave}
              disabled={saveVisibility()}
            >
              <Typography className={style.buttonText}>{t.save}</Typography>
            </Button>
          </Grid>
        </Grid>
        <AlertDialogSlide
          open={open}
          title={t.visualProgram.questionDialog}
          textButtonOk={t.visualProgram.textButtonOk}
          textButtonCancel={t.visualProgram.textButtonCancel}
          handleAction={handleAction}
          handleClose={() => setOpen(false)}
        />
      </>
      <div className="img-resolution">
        {file && imageTypes.indexOf(file?.type) !== -1 && (
          <img
            crossOrigin="anonymous"
            onLoad={target => onMediaLoad(target, file.name)}
            key={file.name}
            src={URL.createObjectURL(file)}
            alt={file.name}
          />
        )}
        {file && videoTypes.indexOf(file?.type) !== -1 && (
          <video
            muted
            onLoadedData={target => onMediaLoad(target, file.name)}
            key={file.name}
            src={URL.createObjectURL(file)}
            alt={file.name}
          />
        )}

        {file && fileTypes.indexOf(file?.type) !== -1 && onPDFLoad(file.name)}
      </div>
    </>
  );
};

MediaModification.propTypes = {
  history: PropTypes.func,
  match: PropTypes.string,
  fetchMediaDetails: PropTypes.func,
  isLoading: PropTypes.bool,
  mediaDetailsResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  modificationResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  sendModificationData: PropTypes.func,
  sendModificationLoading: PropTypes.bool,
  sendDeleteData: PropTypes.func,
  deleteMediaIsLoading: PropTypes.bool,
  fetchS3Data: PropTypes.func,
  s3CallerIsLoading: PropTypes.bool,
  s3CallerSucceed: PropTypes.bool,
  sendModificationSucceed: PropTypes.bool,
  deleteMediaSucceed: PropTypes.func,
  fetchTagList: PropTypes.func,
  tagList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchCreateTag: PropTypes.func,
  createdTag: PropTypes.oneOfType([PropTypes.string]),
  fetchSections: PropTypes.func,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  callback: PropTypes.string,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  )
};

const mapStateToProps = createStructuredSelector({
  mediaDetailsResult: selectMediaDetails,
  isLoading: selectMediaDetailsIsLoading,
  deleteMediaIsLoading: selectDeleteMediaIsLoading,
  modificationResult: selectedModificationMedias,
  s3CallerIsLoading: selectedS3CallerisLoading,
  s3CallerSucceed: selectedS3CallerSucceed,
  sendModificationLoading: modificationIsLoading,
  sendModificationSucceed: modificationSucceed,
  deleteMediaSucceed: selectDeleteMediaSucceed,
  tagList: selectTagListResult,
  createdTag: selectCreatedTag,
  sections: selectSectionsResult,
  callback: selectedCallback,
  searchProgramResults: selectSearchResults
});

const mapDispatchToProps = dispatch => ({
  fetchMediaDetails: refMedia => dispatch(fetchMediaDetailsStart(refMedia)),
  sendModificationData: params => dispatch(sendModificationDataStart(params)),
  sendDeleteData: refMedia => dispatch(sendDeleteDataStart(refMedia)),
  fetchS3Data: params => dispatch(sendS3DataStart(params)),
  fetchTagList: params => dispatch(fetchTagListStart(params)),
  fetchCreateTag: params => dispatch(createTagStart(params)),
  fetchSections: () => dispatch(fetchSectionStart()),
  resetThumbnailsProp: () => dispatch(resetThumbnails())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MediaModification)
);
