const userBuilder = item => {
  return {
    roles: item.roles,
    email: item.email,
    givenName: item.given_name,
    familyName: item.family_name,
    userName: item.preferred_username,
    groups: item.groups,
    hasFilial: item.uos?.length > 0
  };
};

export const loginBuilder = (kc, authenticated) => {
  const logoutOptions = {
    redirectUri: window.location.origin
  };

  return {
    isAuthenticated: authenticated,
    token: kc.token,
    refreshToken: kc.refreshToken,
    sessionId: kc.sessionId,
    expireIn: kc.refreshTokenParsed.exp,
    user: userBuilder(kc.tokenParsed),
    logOut: () => kc.logout(),
    logOutToHome: () => kc.logout(logoutOptions)
  };
};
