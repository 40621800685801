import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import MediaGallery from '../../components/MediaGallery/MediaGallery.jsx';
import t from '../../constants/translates';
import {
  alertDuration,
  EMPTY_STRING,
  PROGRAM_TYPE,
  SEARCH_TYPE
} from '../../constants/global-constants';
import styles from './style.jsx';
import './style.scss';
import SnackbarAlert from '../../components/snackBar/SnackbarAlert.jsx';
import Spinner from '../../components/spinner/Spinner.jsx';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import {
  selectVisualProgramIsLoading,
  selectVisualProgramResult
} from '../../redux/visual-program/visual-program.selectors.js';
import { fetchVisualProgramStart } from '../../redux/visual-program/visual-program.actions.js';
import {
  selectFolderProgramIsLoading,
  selectFolderProgramResult
} from '../../redux/folder-program/folder-program.selectors.js';
import { fetchFolderProgramStart } from '../../redux/folder-program/folder-program.actions.js';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import { fetchProgramDetailsStart } from '../../redux/program-details/program-details.actions.js';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import { selectedCommunes } from '../../redux/createProgram/createProgramSelectors.js';
import { canEditArray } from '../../shared/rights/root-rights.js';
import { ATTRIBUTES } from '../../shared/rights/roles.js';
import {
  selectProgramDetails,
  selectProgramDetailsIsLoading
} from '../../redux/program-details/program-details.selectors.js';
import {
  getPathOrganizationalEntitybyRef,
  findProgramByRef
} from '../../shared/functions/cleoFunctions.js';
import { selectSearchResults } from '../../redux/search/search.selectors.js';
import { fetchSearchResultsStart } from '../../redux/search/search.actions.js';
import { getPrograms } from '../../shared/functions/mediaFunctions.js';
import { checkAvenir } from '../../shared/functions/checkAvenir.js';
import { selectedCallback } from '../../redux/modeController/modeController.selectors.js';
import { checkVitrine } from '../../shared/functions/vitrine';

const ProgramSheet = ({
  match,
  fetchVisualProgram,
  visualProgramData,
  getMediasIsLoading,
  fetchFolderProgram,
  folderProgramData,
  getFolderIsLoading,
  history,
  getProgramList,
  getProgramIsLoading,
  sections,
  communes,
  programDetails,
  programDetailsIsLoading,
  fetchSearchResults,
  searchProgramResults,
  callback
}) => {
  const classes = makeStyles(styles)();
  const [open, setOpen] = useState(false);
  const [section, setSection] = useState();
  const message = `${
    match?.params?.items > 1
      ? `${match?.params.items} ${t.programSheet.alertMessagePlural}`
      : `${match?.params.items} ${t.programSheet.alertMessage}`
  }`;
  const [commune, setCommune] = useState();
  const [currentProgramIR, setCurrentProgramIR] = useState();
  const duration = alertDuration;
  const refProgram = match?.params.medias;
  const [program, setProgram] = useState();
  const messageCheckBox = `${t.programSheet.alertCheckbox}`;
  const [openMessageSelect, setOpenMessageSelect] = useState(false);
  useEffect(() => {
    setProgram(searchProgramResults);
  }, [searchProgramResults]);

  const getIsIR = () => {
    return section?.type === PROGRAM_TYPE.IR_PROGRAM;
  };

  useEffect(() => {
    if (match?.path === ROUTES_CONSTANTS.mediaSummaryAdded) {
      setOpen(true);
    }
    fetchVisualProgram({ refProgram, withCategory: null });
    fetchFolderProgram({ refProgram });
    getProgramList({ refProgram });
  }, [refProgram]);

  useEffect(() => {
    setSection(
      sections?.find(
        sec => sec.refSection === programDetails?.section?.refSection
      )
    );
    if (programDetails?.section?.refSection === PROGRAM_TYPE.IR_PROGRAM_NAME) {
      const params = {
        type: SEARCH_TYPE.program,
        text: refProgram,
        section: PROGRAM_TYPE.IR_PROGRAM_NAME,
        myPrograms: false
      };
      fetchSearchResults(params);
    }
  }, [sections, programDetails, refProgram]);

  useEffect(() => {
    const programFound = getPrograms(searchProgramResults)?.find(
      prog => prog?.programRef?.toString() === refProgram
    );
    setCurrentProgramIR(programFound);
  }, [searchProgramResults]);

  useEffect(() => {
    setCommune(communes?.find(com => com.code === programDetails?.cityCode));
  }, [communes, programDetails]);

  const getTitle = () => {
    let title = EMPTY_STRING;
    let codePart;
    if (getIsIR()) {
      codePart = `${currentProgramIR?.code} `;
      if (currentProgramIR?.name !== undefined && codePart !== undefined) {
        title = `${section?.name} : ${codePart} -  ${currentProgramIR?.name}`;
        return title;
      }
      if (codePart !== undefined) {
        title = `${section?.name} : ${codePart}`;
        return title;
      }
      title = `${section?.name}`;
      return title;
    }
    if (visualProgramData[0]?.program?.name !== undefined) {
      title = `${section?.name} : ${visualProgramData[0]?.program?.name}`;
      return title;
    }
    title = `${section?.name}`;
    return title;
  };

  const getAdress = () => {
    if (visualProgramData[0]?.program?.address) {
      if (commune?.code !== undefined) {
        return `${visualProgramData[0]?.program?.address} - ${commune?.code} ${commune?.nom}`;
      }
      return `${visualProgramData[0]?.program?.address}`;
    }
    if (currentProgramIR?.address) {
      if (commune?.code !== undefined) {
        return `${currentProgramIR?.address} - ${commune?.code} ${commune?.nom}`;
      }
      return `${currentProgramIR?.address}`;
    }
    if (commune?.code !== undefined) {
      return `${commune?.code} ${commune?.nom}`;
    }
    return EMPTY_STRING;
  };

  const getLoadings = () => {
    return (
      getMediasIsLoading ||
      getProgramIsLoading ||
      getFolderIsLoading ||
      programDetailsIsLoading
    );
  };

  if (
    !canEditArray(
      getPathOrganizationalEntitybyRef(refProgram),
      ATTRIBUTES.BME_PROGRAM_READ
    )
  ) {
    return <div></div>;
  }
  const popMessageSelected = () => {
    if (openMessageSelect === true) {
      setOpenMessageSelect(false);
    } else {
      setOpenMessageSelect(true);
    }
  };

  return (
    <>
      <Spinner isLoading={getLoadings()} />
      <>
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            direction="row"
            alignItems="center"
          >
            <DismissableAlert />
            {getIsIR() && !checkVitrine(history) && (
              <div className="disclaimer">
                <h1 className="disclaimer__title">
                  {t.programSheet.disclaimerTitle}
                </h1>
                <p className="disclaimer__body">
                  {checkAvenir(program?.results?.withMedia[0]?.match) &&
                  callback === null ? (
                    <>{t.programSheet.disclaimerAvenir}</>
                  ) : (
                    <>
                      {t.programSheet.disclaimerBodyPartOne}
                      <br />
                      {t.programSheet.disclaimerBodyPartTwo}
                    </>
                  )}
                </p>
              </div>
            )}
            <div className="programSheetContainer">
              <SnackbarAlert
                id="AddedAlert"
                open={open}
                message={message}
                handleClose={() => setOpen(false)}
                duration={duration}
              />

              <SnackbarAlert
                id="AddedAlert"
                open={openMessageSelect}
                message={messageCheckBox}
                duration={duration}
                handleClose={setOpenMessageSelect}
              />
              <Grid container spacing={0}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  md={9}
                  direction="row"
                  alignItems="center"
                >
                  <p className="program__title">{getTitle()}</p>
                  {!getIsIR() &&
                    canEditArray(
                      getPathOrganizationalEntitybyRef(refProgram),
                      ATTRIBUTES.BME_PROGRAM_WRITE
                    ) && (
                      <>
                        {!checkVitrine(history) && (
                          <span>
                            <IconButton
                              color="inherit"
                              aria-label="menu"
                              id="program-title"
                              className={classes.programModification}
                              onClick={() =>
                                history.push(
                                  `${ROUTES_CONSTANTS.modifyProgramBase}${section?.type}/${refProgram}`
                                )
                              }
                            >
                              <EditIcon /> {t.programSheet.programModification}
                            </IconButton>
                          </span>
                        )}
                      </>
                    )}
                </Grid>
                <Grid container item xs={12} sm={6} md={3}>
                  {canEditArray(
                    getPathOrganizationalEntitybyRef(refProgram),
                    ATTRIBUTES.BME_MEDIA_WRITE
                  ) && (
                    <>
                      {!checkVitrine(history) && (
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.addMediaButton}
                          onClick={() =>
                            history.push(
                              `${ROUTES_CONSTANTS.filesUploaderBase}${refProgram}/${section?.type}`
                            )
                          }
                        >
                          <b className="button__text--padding">
                            {t.programSheet.programAd}
                          </b>
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                  <p className="program__adress">{getAdress()}</p>
                  {getIsIR() && findProgramByRef(refProgram) !== undefined && (
                    <div className="program__adress">
                      <p className="program__filial">
                        {findProgramByRef(refProgram)?.legalStructure !==
                        undefined ? (
                          <>
                            {`${t.visualProgram.subsidiary} ${
                              findProgramByRef(refProgram)?.legalStructure
                            }`}
                          </>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  )}
                </Grid>
                <Grid container item xs={12} sm={6} md={3}>
                  {canEditArray(
                    getPathOrganizationalEntitybyRef(refProgram),
                    ATTRIBUTES.BME_PROGRAM_WRITE
                  ) && (
                    <>
                      {checkVitrine(history) === false && (
                        <>
                          {program?.results?.withMedia[0]?.match?.section
                            ?.type === PROGRAM_TYPE.IE_PROGRAM ? (
                            // eslint-disable-next-line
                            <>
                              <IconButton
                                color="inherit"
                                aria-label="menu"
                                className={classes.mediaOrganisation}
                                onClick={() =>
                                  history.push(
                                    `${ROUTES_CONSTANTS.programMediaOrder}${refProgram}`
                                  )
                                }
                              >
                                <SwapHorizIcon />{' '}
                                {t.programSheet.mediaOrganiser}
                              </IconButton>
                            </>
                          ) : (
                            <>
                              {checkAvenir(
                                program?.results?.withMedia[0]?.match
                              ) && callback === null ? (
                                <></>
                              ) : (
                                <>
                                  <IconButton
                                    color="inherit"
                                    aria-label="menu"
                                    className={classes.mediaOrganisation}
                                    onClick={() =>
                                      history.push(
                                        `${ROUTES_CONSTANTS.programMediaOrder}${refProgram}`
                                      )
                                    }
                                  >
                                    <SwapHorizIcon />{' '}
                                    {t.programSheet.mediaOrganiser}
                                  </IconButton>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              <MediaGallery
                history
                mediaList={visualProgramData}
                folderList={folderProgramData}
                refProgram={refProgram}
                isIR={getIsIR()}
                programDetails={programDetails}
                messageSelection={popMessageSelected}
              />
            </div>
          </Grid>
        </Grid>
      </>
    </>
  );
};

ProgramSheet.propTypes = {
  match: PropTypes.string,
  fetchVisualProgram: PropTypes.func,
  visualProgramData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchFolderProgram: PropTypes.func,
  folderProgramData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  getMediasIsLoading: PropTypes.bool,
  history: PropTypes.func,
  getProgramList: PropTypes.func,
  getProgramIsLoading: PropTypes.bool,
  getFolderIsLoading: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  communes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  programDetails: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  programDetailsIsLoading: PropTypes.func,
  fetchSearchResults: PropTypes.func,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  callback: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
  visualProgramData: selectVisualProgramResult,
  getMediasIsLoading: selectVisualProgramIsLoading,
  folderProgramData: selectFolderProgramResult,
  getFolderIsLoading: selectFolderProgramIsLoading,
  isLoading: selectVisualProgramIsLoading,
  sections: selectSectionsResult,
  communes: selectedCommunes,
  programDetails: selectProgramDetails,
  programDetailsIsLoading: selectProgramDetailsIsLoading,
  searchProgramResults: selectSearchResults,
  callback: selectedCallback
});

const mapDispatchToProps = dispatch => ({
  fetchVisualProgram: programRef =>
    dispatch(fetchVisualProgramStart(programRef)),
  fetchFolderProgram: programRef =>
    dispatch(fetchFolderProgramStart(programRef)),
  getProgramList: files => dispatch(fetchProgramDetailsStart(files)),
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgramSheet)
);
