import CreatePartner from './createPartnerTypes';

const INITIAL_STATE = {
  message: null,
  error: null,
  isLoading: false
};

const createPartnerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreatePartner.SEND_CREATE_PARTNER_START:
      return { ...state, isLoading: true, succeed: false };

    case CreatePartner.SEND_CREATE_PARTNER_SUCCESS:
      return { ...state, message: action.payload, error: null, succeed: true };

    case CreatePartner.SEND_CREATE_PARTNER_FAILURE:
      return { ...state, error: action.payload.response.data, succeed: false };

    case CreatePartner.SEND_CREATE_PARTNER_FINISHED:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default createPartnerReducer;
