import { sectionItemBuilder } from '../section/sections/section.builder';

const programBuilder = itemProgram => {
  return {
    zipCode: itemProgram?.zipCode,
    name: itemProgram?.name,
    city: itemProgram?.city,
    code: itemProgram?.code,
    refProgram: itemProgram?.refProgram,
    address: itemProgram?.address,
    section: sectionItemBuilder(itemProgram?.section),
    filiale: itemProgram?.filiale
  };
};

const folderListBuilder = folderList => {
  return folderList.map(item => {
    return {
      refFolder: item?.refFolder,
      name: item?.name,
      program: programBuilder(item?.program)
    };
  });
};

export function folderProgramBuilder(data) {
  return folderListBuilder(data);
}
