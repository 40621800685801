import { INTERFACE, CPI_ACTIVE } from '../../api/api.conf';
import { INTERFACES } from '../../constants/global-constants';
import { ATTRIBUTES } from './roles';

export const isInternal = () => {
  return INTERFACE === INTERFACES.INTERNAL;
};

export const isPartener = () => {
  return INTERFACE === ATTRIBUTES.EXTERN;
};

export const isCPIActive = () => {
  return CPI_ACTIVE === true;
};
