export default {
  inputCategory: {
    background: '#FFFFFF',
    boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '592px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'move'
  },

  modifyCategory: {
    background: '#FFFFFF',
    boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '592px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  inputCreateCategory: {
    background: '#FFFFFF',
    boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '592px',
    height: '52px'
  },

  inputSubcategory: {
    background: '#FFFFFF',
    boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    width: '488px',
    height: '48px'
  },

  deleteSectionButton: {
    fontFamily: 'Avenir Next LT Pro',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#C82832',
    zIndex: '100',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex'
  },

  goToSubcategoriesButton: {
    fontFamily: 'Avenir Next LT Pro',
    background: '#C82832',
    borderRadius: '10px',
    fontSize: '16px',
    color: '#FFFFFF',
    marginLeft: '10px',
    height: '34px',
    zIndex: '100',
    cursor: 'pointer'
  },

  setPositionsButton: {
    fontFamily: 'Avenir Next LT Pro',
    background: '#C82832',
    borderRadius: '15px',
    fontSize: '16px',
    color: '#FFFFFF',
    height: '45px',
    cursor: 'pointer',
    width: '200px',
    marginLeft: '20px'
  },

  setPositionsButtonSubcategory: {
    fontFamily: 'Avenir Next LT Pro',
    background: '#C82832',
    borderRadius: '15px',
    fontSize: '16px',
    color: '#FFFFFF',
    height: '45px',
    cursor: 'pointer',
    width: '200px'
  },

  setCategoriesButton: {
    background: '#C82832',
    borderRadius: '15px',
    fontSize: '16px',
    color: '#FFFFFF',
    height: '45px',
    cursor: 'pointer',
    width: '200px',
    marginLeft: '-132px'
  },

  changeOrderEditButton: {
    fontFamily: 'Avenir Next LT Pro',
    background: '#C82832',
    borderRadius: '15px',
    fontSize: '16px',
    color: '#FFFFFF',
    height: '45px',
    cursor: 'pointer',
    width: 'auto',
    minWidth: '230px',
    marginLeft: '-327px'
  },

  changeOrderEditButtonSubcategory: {
    background: '#C82832',
    borderRadius: '15px',
    fontSize: '16px',
    color: '#FFFFFF',
    height: '45px',
    cursor: 'pointer',
    width: 'auto',
    minWidth: '230px'
  },

  saveNewCategoryButton: {
    fontFamily: 'Avenir Next LT Pro',
    background: '#C82832',
    borderRadius: '10px',
    fontSize: '16px',
    color: '#FFFFFF',
    marginLeft: '10px',
    height: '34px',
    zIndex: '100',
    cursor: 'pointer'
  },

  updateNamesButton: {
    fontFamily: 'Avenir Next LT Pro',
    background: '#C82832',
    borderRadius: '15px',
    fontSize: '16px',
    color: '#FFFFFF',
    height: '45px',
    cursor: 'pointer',
    width: '200px',
    marginLeft: '20px',
    marginTop: '10px'
  }
};
