import { createSelector } from 'reselect';

const selectSectionFiles = state => state.fileSectionUploader;

export const selectedSectionFiles = createSelector(
  [selectSectionFiles],
  fileSectionUploader => fileSectionUploader.files
);

export const selectedSectionFilesIsLoading = createSelector(
  [selectSectionFiles],
  fileSectionUploader => fileSectionUploader.isLoading
);

export const selectedSectionFilesError = createSelector(
  [selectSectionFiles],
  fileSectionUploader => fileSectionUploader.error
);

export const selectedSectionFilesSucceed = createSelector(
  [selectSectionFiles],
  fileSectionUploader => fileSectionUploader.succeed
);
