/* eslint-disable */

import { CALENDAR_STEP_EVENT } from '../../constants/global-constants';
import { toLocalDate } from '../../shared/functions/date.js';

function blocsAndNatureBuilder(dataFavAssumption, dataNature) {
  const listBloc = dataFavAssumption.map(bloc => {
    const nature = dataNature.find(
      element => element.valueCode === bloc.refBlockProductNatureExt
    );
    return bloc?.blocklabel.concat(' - ').concat(nature.longLabel);
  });

  return listBloc.sort();
}

function calendarItemBuilder(dates, title, id) {
  return {
    date: dates,
    id,
    title
  };
}

function dateCalendarBuilder(calendarData, refProgram) {
  const programFind = calendarData?.programs.find(program => {
    return program?.refProgram === refProgram;
  });
  const favAssumption = programFind?.assumptions.find(assumption => {
    return assumption?.favorite === true;
  });
  const calendarDataBuilded = [];
  const datesCE = [];
  const datesCOA = [];
  const datesDT = [];
  const datesLC = [];
  const datesLI = [];
  favAssumption?.assumptionBlocks?.map(assumptionBlock => {
    assumptionBlock?.eventBlocks?.map(eventBloc => {
      switch (eventBloc?.eventCode) {
        case CALENDAR_STEP_EVENT.CE.key:
          datesCE.push(
            assumptionBlock?.label
              .concat(' - ')
              .concat(toLocalDate(eventBloc?.date))
          );
          break;
        case CALENDAR_STEP_EVENT.COA.key:
          datesCOA.push(
            assumptionBlock?.label
              .concat(' - ')
              .concat(toLocalDate(eventBloc?.date))
          );
          break;
        case CALENDAR_STEP_EVENT.DT.key:
          datesDT.push(
            assumptionBlock?.label
              .concat(' - ')
              .concat(toLocalDate(eventBloc?.date))
          );
          break;
        case CALENDAR_STEP_EVENT.LC.key:
          datesLC.push(
            assumptionBlock?.label
              .concat(' - ')
              .concat(toLocalDate(eventBloc?.date))
          );
          break;
        case CALENDAR_STEP_EVENT.LI.key:
          datesLI.push(
            assumptionBlock?.label
              .concat(' - ')
              .concat(toLocalDate(eventBloc?.date))
          );
          break;
        default:
          break;
      }
    });
  });
  calendarDataBuilded.push(
    calendarItemBuilder(datesCE, CALENDAR_STEP_EVENT.CE.title, 1)
  );
  calendarDataBuilded.push(
    calendarItemBuilder(datesCOA, CALENDAR_STEP_EVENT.COA.title, 2)
  );
  calendarDataBuilded.push(
    calendarItemBuilder(datesDT, CALENDAR_STEP_EVENT.DT.title, 3)
  );
  calendarDataBuilded.push(
    calendarItemBuilder(datesLC, CALENDAR_STEP_EVENT.LC.title, 4)
  );
  calendarDataBuilded.push(
    calendarItemBuilder(datesLI, CALENDAR_STEP_EVENT.LI.title, 5)
  );
  return calendarDataBuilded;
}

export function externalInfoBuilder(data) {
  const blocsAndNatureData = blocsAndNatureBuilder(
    data.dataFavAssumption,
    data.dataNature
  );
  const dateCalendarDataBuilded = dateCalendarBuilder(
    data.calendarData?.data[0],
    data.refProgram
  );

  return {
    activitePrincipale: data?.dataActivitePrincipal?.value?.longLabel,
    numLots: data?.dataLots[0]?.totalLotsNumber,
    listBlocsAndNatureData: blocsAndNatureData,
    dataSurface: data?.dataSurface?.assumptionIndicator?.totalFloorArea
      ? data?.dataSurface?.assumptionIndicator?.totalFloorArea
      : 0,
    dataCalendar: dateCalendarDataBuilded
  };
}
