import { createSelector } from 'reselect';

const selectConditions = state => state.conditionsCms;

export const selectConditionssResults = createSelector(
  [selectConditions],
  conditions => conditions.conditionsResults
);

export const selectConditionssIsLoading = createSelector(
  [selectConditions],
  conditions => conditions.isLoading
);

export const selectConditionssError = createSelector(
  [selectConditions],
  conditions => conditions.error
);
