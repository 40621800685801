import { put, all, call, takeLatest } from 'redux-saga/effects';
import DeleteTypes from './media-delete.types';
import api from '../../api/api.jsx';
import {
  sendDeleteDataSuccess,
  sendDeleteDataFailure,
  sendDeleteDataFinished
} from './media-delete.actions';

export function* sendDeleteMedia({ payload: media }) {
  try {
    const { data } = yield call(api.deleteMedia, media);
    yield put(sendDeleteDataSuccess(data));
  } catch (error) {
    yield put(sendDeleteDataFailure(error));
  }
  yield put(sendDeleteDataFinished());
}

export function* onSendDeleteMedia() {
  yield takeLatest(DeleteTypes.SEND_DELETE_DATA_START, sendDeleteMedia);
}

export function* deleteSagas() {
  yield all([call(onSendDeleteMedia)]);
}
