const ThumbnailTypes = {
  GET_THUMBNAILS_START: 'GET_THUMBNAILS_START',
  GET_THUMBNAILS_SUCCESS: 'GET_THUMBNAILS_SUCCESS',
  GET_THUMBNAILS_FAILURE: 'GET_THUMBNAILS_FAILURE',
  GET_THUMBNAILS_FINISHED: 'GET_THUMBNAILS_FINISHED',
  RESET_THUMBNAILS: 'RESET_THUMBNAILS',
  GET_SINGLE_THUMBNAILS_START: 'GET_SINGLE_THUMBNAILS_START',
  GET_SINGLE_THUMBNAILS_SUCCESS: 'GET_SINGLE_THUMBNAILS_SUCCESS',
  GET_SINGLE_THUMBNAILS_FAILURE: 'GET_SINGLE_THUMBNAILS_FAILURE',
  GET_SINGLE_THUMBNAILS_FINISHED: 'GET_SINGLE_THUMBNAILS_FINISHED'
};

export default ThumbnailTypes;
