import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { v4 as uuidv4 } from 'uuid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IconButton } from '@material-ui/core';
import Button from '../../button/buttonComponent.jsx';
import { colorType } from '../../../constants/ui.constants';
import { SEARCH_TYPE, KEYS } from '../../../constants/global-constants';
import texts from '../../../constants/translates';
import style from './style.jsx';
import AlertDialogSlide from '../../dialog/dialog';
import { fetchCopyProgramListStart } from '../../../redux/copy-program-list/copy.program.list.actions';
import {
  selectCopyProgramListResults,
  selectCopyProgramListIsLoading,
  selectCopyError
} from '../../../redux/copy-program-list/copy.program.list.selectors';
import { fetchPasteMediaStart } from '../../../redux/paste.media/paste.media.actions';
import './style.scss';
import noImage from '../../../assets/images/no-image-icon.png';
import { isFilialExt } from '../../../shared/rights/root-rights.js';
import { getSingleThumbnailStart } from '../../../redux/thumbnails/thumbnailsActions.js';
import { selectedSingleThumbnail } from '../../../redux/thumbnails/thumbnailsSelectors.js';

/**
 * Tab panel related to program tabs
 */
const TabPanelCopyPaste = ({
  section,
  fetchProgramListResults,
  fetchPasteMedia,
  programListResults,
  isLoading,
  media,
  fetchGetThumbnails,
  thumbnails
}) => {
  const classes = makeStyles(style)();
  const [valueSubTab, setValueSubTab] = useState(0);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [currentProgramList, setCurrentProgramList] = useState([]);
  const [searchText, setSearchText] = useState();

  /**
   * Handle when tab is changed
   * @param {Object} event onChanged event
   * @param {Number} newValue changed tab value
   */
  const handleChange = (event, newValue) => {
    setSelectedProgram(currentProgramList[newValue]);
    setValueSubTab(newValue);
    fetchGetThumbnails(currentProgramList[newValue]);
  };

  /**
   * Handle when alert dialog confirmed
   */
  const handleAction = () => {
    fetchPasteMedia({
      refTargetProgram: selectedProgram?.programRef,
      refMedia: media?.refMedia,
      mediaURL: media?.mediaURL,
      program: selectedProgram
    });
    setOpenAlertDialog(false);
  };

  /**
   * Handle to close Alert Dialog
   */
  const handleClose = () => {
    setOpenAlertDialog(false);
  };

  const searchProgram = () => {
    let params = {
      type: SEARCH_TYPE.program,
      section: section?.refSection,
      myPrograms: isFilialExt()
        ?.toString()
        ?.toUpperCase()
    };
    if (searchText?.length > 0) {
      params = {
        ...params,
        text: searchText
      };
    }
    fetchProgramListResults(params);
  };

  useEffect(() => {
    searchProgram();
    setSelectedProgram({});
    setValueSubTab();
  }, [section?.refSection]);

  useEffect(() => {
    const totalPrograms = programListResults?.results?.withMedia
      ?.map(prog => prog?.match)
      ?.concat(programListResults?.results?.withoutMedia);
    setCurrentProgramList(totalPrograms);
  }, [programListResults, section?.refSection]);

  const handleSearchChanged = event => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleKeyPress = e => {
    if (e.key === KEYS.enter) {
      searchProgram();
    }
  };

  /**
   * Search icon adornment for input
   */
  const inputAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={searchProgram}>
        <SearchIcon />
      </IconButton>
    </InputAdornment>
  );

  /**
   * Display a preview panel with the selected program
   * @param {Object} program Selected program
   */
  const previewPanel = program => (
    <div className="detail-container">
      <img
        crossOrigin="anonymous"
        src={thumbnails?.thumbnail || noImage}
        alt="preview"
        className="detail-container__image"
      />
      {program?.name !== undefined ? (
        <p className="detail-container__name">{`${program?.name}`}</p>
      ) : (
        ''
      )}
      <p className="detail-container__city">{`${program?.city?.name}`}</p>
      <Button
        id="buttonPaste"
        variant="contained"
        color={colorType.secondary}
        className={classes.button}
        onClick={() => {
          setOpenAlertDialog(true);
        }}
      >
        {texts.copyPasteModal.paste}
      </Button>
      <p className="detail-container__message">
        {texts.copyPasteModal.warningMessage}
      </p>
    </div>
  );

  /**
   * Displays the panel tabs column with programs
   */
  const tabsColumn = () => (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={valueSubTab}
      onChange={handleChange}
      className={classes.tabs}
      TabIndicatorProps={{
        style: style.indicator
      }}
    >
      {currentProgramList?.map(program => {
        if (program?.name !== undefined) {
          return (
            <Tab
              label={`${program?.code} - ${program?.name}`}
              key={uuidv4()}
              className={classes.tab}
            />
          );
        }
        return (
          <Tab
            label={`${program.code}`}
            key={uuidv4()}
            className={classes.tab}
          />
        );
      })}
    </Tabs>
  );

  /**
   * Display circular progress loader
   */

  return (
    <>
      <Grid container className={classes.gridContainer}>
        <Grid container xs={7} className={classes.gridContainer}>
          <div className="program-column">
            <Grid item xs={12} className={classes.gridSearchRow}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>
                  {texts.homeBannerContainer.searchButton}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment"
                  type="text"
                  endAdornment={inputAdornment}
                  labelWidth={style.labelWidth}
                  onChange={handleSearchChanged}
                  onKeyPress={handleKeyPress}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.gridProgramsRow}>
              {isLoading ? <CircularProgress /> : tabsColumn()}
            </Grid>
          </div>
        </Grid>
        <Grid container xs={5} className={classes.gridContainer}>
          {selectedProgram?.code && previewPanel(selectedProgram)}
        </Grid>
      </Grid>
      <AlertDialogSlide
        open={openAlertDialog}
        title={texts.copyPasteModal.alertMessage}
        textButtonOk={texts.visualProgram.textButtonOk}
        textButtonCancel={texts.visualProgram.textButtonCancel}
        handleAction={handleAction}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  programListResults: selectCopyProgramListResults,
  isLoading: selectCopyProgramListIsLoading,
  error: selectCopyError,
  thumbnails: selectedSingleThumbnail
});

const mapDispatchToProps = dispatch => ({
  fetchProgramListResults: params =>
    dispatch(fetchCopyProgramListStart(params)),
  fetchPasteMedia: params => dispatch(fetchPasteMediaStart(params)),
  fetchGetThumbnails: params => dispatch(getSingleThumbnailStart(params))
});

TabPanelCopyPaste.propTypes = {
  section: PropTypes.oneOfType([PropTypes.object]),
  programListResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  fetchProgramListResults: PropTypes.func,
  fetchPasteMedia: PropTypes.func,
  media: PropTypes.oneOfType([PropTypes.object]),
  isLoading: PropTypes.bool,
  fetchGetThumbnails: PropTypes.func,
  thumbnails: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

export default connect(mapStateToProps, mapDispatchToProps)(TabPanelCopyPaste);
