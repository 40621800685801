import thumbnailsTypes from './thumbnailsTypes';

export const getThumbnailsStart = thumbnails => ({
  type: thumbnailsTypes.GET_THUMBNAILS_START,
  payload: thumbnails
});

export const getThumbnailsSuccess = medias => ({
  type: thumbnailsTypes.GET_THUMBNAILS_SUCCESS,
  payload: medias
});

export const getThumbnailsFailure = error => ({
  type: thumbnailsTypes.GET_THUMBNAILS_FAILURE,
  payload: error
});

export const getThumbnailsFinished = () => ({
  type: thumbnailsTypes.GET_THUMBNAILS_FINISHED
});

export const getSingleThumbnailStart = thumbnails => ({
  type: thumbnailsTypes.GET_SINGLE_THUMBNAILS_START,
  payload: thumbnails
});

export const getSingleThumbnailsSuccess = medias => ({
  type: thumbnailsTypes.GET_SINGLE_THUMBNAILS_SUCCESS,
  payload: medias
});

export const getSingleThumbnailsFailure = error => ({
  type: thumbnailsTypes.GET_SINGLE_THUMBNAILS_FAILURE,
  payload: error
});

export const getSingleThumbnailsFinished = () => ({
  type: thumbnailsTypes.GET_SINGLE_THUMBNAILS_FINISHED
});

export const resetThumbnails = () => ({
  type: thumbnailsTypes.RESET_THUMBNAILS
});
