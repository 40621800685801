export default {
  formControl: {
    backgroundColor: '#F5F6FA',
    minWidth: 140,
    display: 'contents'
  },
  textField: {
    width: '100%',
    marginLeft: '5px'
  }
};
