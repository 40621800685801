import { put, all, call, takeLatest } from 'redux-saga/effects';
import CreateTag from './create.tag.types';
import api from '../../api/api.jsx';
import {
  createTagSuccess,
  createTagFailure,
  createTagFinished
} from './create.tag.actions';
import { tagCreatedBuilder } from './create.tag.builder';

export function* createTag({ payload: form }) {
  try {
    const { data } = yield call(api.postTags, form);
    const dataBuilder = yield call(tagCreatedBuilder, data);
    yield put(createTagSuccess(dataBuilder));
  } catch (error) {
    yield put(createTagFailure(error.response?.data));
  }
  yield put(createTagFinished());
}

export function* onCreateTagStart() {
  yield takeLatest(CreateTag.CREATE_TAG_START, createTag);
}

export function* createTagSagas() {
  yield all([call(onCreateTagStart)]);
}
