const societyBuilder = legalEntityList => {
  return legalEntityList.map(legalEntity => {
    return legalEntity.result.map(item => {
      return {
        refLegalEntity: item.refLegalEntity,
        name: item.name,
        status: item.status,
        ville: item.locationInformation?.address?.city,
        quality: item.quality?.reference
      };
    });
  });
};

export function legalEntityBuilder(data) {
  return societyBuilder(data);
}
