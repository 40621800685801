import partnerDetailsActionTypes from './partner-details.types';

export const fetchPartnerDetailsStart = text => ({
  type: partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_START,
  payload: text
});

export const fetchPartnerDetailsSuccess = data => ({
  type: partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_SUCCESS,
  payload: data
});

export const fetchPartnerDetailsFailure = error => ({
  type: partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_FAILURE,
  payload: error
});

export const fetchPartnerDetailsFinished = () => ({
  type: partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_FINISHED
});
