import { put, all, call, takeLatest } from 'redux-saga/effects';
import LegalEntityActionTypes from './legalEntity.types';
import { legalEntityBuilder } from './legalEntity.builder';
import apiIntervenants from '../../api/apiIntervenants';
import {
  fetchLegalEntitySuccess,
  fetchLegalEntityFailure,
  fetchLegalEntityFinished
} from './legalEntity.actions';

export function* fetchLegalEntity({ payload: params }) {
  try {
    const legalEntityList = [];
    const { data } = yield call(apiIntervenants.getListOfLegalEntities, params);
    const numPagesTotal = data?.page?.totalPages;
    let pageActual = data?.page?.number;
    legalEntityList.push(data);
    pageActual += 1;

    while (pageActual <= numPagesTotal) {
      const paramsUpdated = { ...params, page: pageActual };
      const { data } = yield call(
        apiIntervenants.getListOfLegalEntities,
        paramsUpdated
      );
      legalEntityList.push(data);
      pageActual += 1;
    }
    const dataBuilder = yield call(legalEntityBuilder, legalEntityList);
    const allLegalEntity = dataBuilder.flat();
    yield put(fetchLegalEntitySuccess(allLegalEntity));
  } catch (error) {
    yield put(fetchLegalEntityFailure(error));
  }
  yield put(fetchLegalEntityFinished());
}

export function* onFetchLegalEntityStart() {
  yield takeLatest(
    LegalEntityActionTypes.FETCH_LEGALENTITY_START,
    fetchLegalEntity
  );
}

export function* legalEntitySagas() {
  yield all([call(onFetchLegalEntityStart)]);
}
