import axios from 'axios';
import { API_ROOT, KEYCLOAK_TOKEN_URL } from './api.conf';
import { API_ROUTES, PATHS } from './apiRoutes';
import API_HEADERS from './apiHeaders';
import { AUTHENTICATION, SIZES } from '../constants/global-constants';
import queryBuilder from '../shared/builders/queryBuilder';

export function downLoadFile(link) {
  return axios.get(link, { responseType: 'blob' });
}

class API {
  http;

  httpS3;

  constructor() {
    axios.defaults.baseURL = API_ROOT;
    this.http = axios;
    this.addInterceptor();
    const instance = axios.create({
      baseURL: API_ROOT
    });
    this.httpS3 = instance;
  }

  ACCESS_DENIED = 'Access Denied';

  isTokenInvalid = error =>
    (error.status && (error.status === 401 || error.status === 403)) ||
    error.data?.message === this.ACCESS_DENIED;

  isTokenExpire = () => Date.now() > +localStorage.refreshIn * 1000;

  refreshToken = refreshToken => {
    const urlencoded = new URLSearchParams();

    urlencoded.append('grant_type', 'refresh_token');
    urlencoded.append('client_id', 'avr-front');
    urlencoded.append('refresh_token', refreshToken);

    const requestOptions = {
      method: 'POST',
      body: urlencoded
    };

    const response = fetch(
      KEYCLOAK_TOKEN_URL,
      requestOptions
    ).then(fetchResponse => fetchResponse.json());
    return response;
  };

  rejectedRequestInterceptor = err => {
    const error = err && err.response ? err.response : err;

    const originalRequest = error.config;
    let alreadyRetryingRequest = false;

    const shouldRefreshToken = () =>
      (this.isTokenInvalid(error) &&
        !alreadyRetryingRequest &&
        !!localStorage.refreshToken) ||
      this.isTokenExpire();

    const updateTokens = (token, refreshToken) => {
      this.setAutorizationToken(token, refreshToken);
      originalRequest.headers[
        AUTHENTICATION.authorizationKey
      ] = `${AUTHENTICATION.bearerKey} ${token}`;
    };
    const fetchTokenRetry = () => {
      alreadyRetryingRequest = true;
      return new Promise(resolve => {
        this.refreshToken(localStorage.refreshToken).then(response => {
          updateTokens(response.access_token, response.refresh_token);
          alreadyRetryingRequest = false;
          resolve(axios(originalRequest));
        });
      });
    };
    const redirectToAuthenticationError = authError => {
      if (authError.status === 403) {
        window.location.reload(false);
      }
      return Promise.reject(authError);
    };

    return shouldRefreshToken()
      ? fetchTokenRetry()
      : redirectToAuthenticationError(error);
  };

  addInterceptor = () =>
    this.http.interceptors.response.use(
      response => response,
      this.rejectedRequestInterceptor
    );

  setAutorizationToken = (token, refreshToken, sessionId, refreshIn) => {
    if (token) {
      localStorage.setItem(AUTHENTICATION.jwtTokenKey, token);
      if (refreshToken) {
        localStorage.setItem(AUTHENTICATION.refreshTokenKey, refreshToken);
      }
      if (sessionId) {
        localStorage.setItem(AUTHENTICATION.sessionId, sessionId);
      }
      if (refreshIn) {
        localStorage.setItem(AUTHENTICATION.refreshIn, refreshIn);
      }
      this.http.defaults.headers.common.Authorization = `${AUTHENTICATION.bearerKey} ${token}`;
    } else {
      delete this.http.defaults.headers.common.Authorization;
      localStorage.clear();
    }
  };

  getCategories = () => this.http.get(`${API_ROUTES.categories}`);

  getFolderByProgram = params =>
    this.http.get(
      `${API_ROUTES.programs}/${params.refProgram}${PATHS.folders}`
    );

  getVisualProgram = params => {
    let pageSize = SIZES.defaultSize;
    if (params.pageSize) {
      pageSize = params.pageSize;
    }

    return this.http.get(`${API_ROUTES.medias}`, {
      params: {
        pageSize,
        refProgram: params.refProgram,
        category: params.withCategory
      }
    });
  };

  getVisualFolder = params =>
    this.http.get(`${API_ROUTES.medias}`, {
      params: {
        refFolder: params.refFolder,
        category: params.withCategory
      }
    });

  getFolderDetails = params =>
    this.http.get(
      `${API_ROUTES.programs}/${params.refProgram}${PATHS.folders}/${params.refFolder}`,
      {}
    );

  getMediaDetails = refMedia =>
    this.http.get(`${API_ROUTES.medias}/${refMedia}`, {});

  getSearchResults = params => {
    const query = queryBuilder(params);
    return this.http.get(`${API_ROUTES.searchResults}?${query}`);
  };

  getSearchResultsWithCoordinates = params => {
    const query = queryBuilder(params);
    return this.http.get(`${API_ROUTES.searchResults}?${query}`);
  };

  getSearchSuggestions = params => {
    return this.http.get(`${API_ROUTES.searchSuggest}?query=${params}`);
  };

  getMediaThumbnail = url => this.http.get(url);

  getProgramDetails = params =>
    this.http.get(`${API_ROUTES.programs}/${params.refProgram}`, {});

  postUploadProgramMedia = file => {
    return this.http.post(
      `${API_ROUTES.medias}?refProgram=${file.refProgram}`,
      file.payload
    );
  };

  postUploadSectionMedia = file => {
    return this.http.post(
      `${API_ROUTES.medias}?refSection=${file.refSection}`,
      file.payload
    );
  };

  postUploadFolderMedia = file => {
    return this.http.post(
      `${API_ROUTES.medias}?refFolder=${file.refFolder}`,
      file.payload
    );
  };

  postUploadMedia = file => {
    return this.http.post(`${API_ROUTES.medias}`, file.payload);
  };

  getProgramList = params =>
    this.http.get(`${API_ROUTES.programs}`, {
      params
    });

  getSections = params =>
    this.http.get(`${API_ROUTES.sections}`, {
      params
    });

  getLastMedias = params =>
    this.http.get(`${API_ROUTES.searchResults}`, {
      params
    });

  getThemeList = params => this.http.get(`${API_ROUTES.medias}`, { params });

  getTags = startsWith =>
    this.http.get(`${API_ROUTES.tags}?startsWith=${startsWith}`);

  getCategorizationMedias = idMedia =>
    this.http.get(`${API_ROUTES.medias}/${idMedia}`);

  patchCategorizationMedias = ({ media, refMedia }) =>
    this.http.patch(`${API_ROUTES.medias}/${refMedia}`, media);

  patchModificationMedias = ({ mediaModification, refMediaSend }) => {
    return this.http.patch(
      `${API_ROUTES.medias}/${refMediaSend}`,
      mediaModification,
      {
        headers: {
          'Content-Type': API_HEADERS.contentType.applicationJsonPath
        }
      }
    );
  };

  putS3 = (url, file) => {
    return this.httpS3.put(`${url}`, file);
  };

  putS3config = (url, file, config) => {
    return this.httpS3.put(`${url}`, file, config);
  };

  deleteMedia = idMedia => this.http.delete(`${API_ROUTES.medias}/${idMedia}`);

  postCreateProgram = form =>
    this.http.post(`${API_ROUTES.createProgram}`, form);

  postTags = params => this.http.post(`${API_ROUTES.tags}`, params);

  postCopyMedia = ({ refMedia, refTargetProgram }) =>
    this.http.post(
      `${API_ROUTES.medias}/${refMedia}${PATHS.copy}?refTargetProgram=${refTargetProgram}`
    );

  updateTitle = ({ refProgram, programName }) => {
    return this.http.patch(
      `${API_ROUTES.programs}/${refProgram}`,
      programName,
      {
        headers: {
          'Content-Type': API_HEADERS.contentType.applicationJsonPath
        }
      }
    );
  };

  postCreateFolder = form =>
    this.http.post(
      `${API_ROUTES.programs}/${form.refProgram}${PATHS.folders}`,
      form.nameFolder
    );

  postCreateProgram = form =>
    this.http.post(`${API_ROUTES.createProgram}`, form);

  getInstanceURL = ({ refFile, refMedia, mediaDetails }) =>
    this.http.get(
      `${API_ROUTES.medias}/${refMedia || mediaDetails?.refMedia}${
        PATHS.files
      }/${refFile}`
    );

  updateTitle = ({ refProgram, programName }) =>
    this.http.patch(`${API_ROUTES.programs}/${refProgram}`, programName, {
      headers: {
        'Content-Type': API_HEADERS.contentType.applicationJsonPath
      }
    });

  reorganizerMedia = ({ refProgram, medias }) =>
    this.http.post(
      `${API_ROUTES.medias}/reorganize?refProgram=${refProgram}`,
      medias
    );

  postCreatePartner = form => this.http.post(`${API_ROUTES.partners}`, form);

  getPartnerList = params =>
    this.http.get(`${API_ROUTES.partners}`, { params });

  thumbnailSection = section =>
    this.http.get(`/v1/workflows/thumbnailSection?nom=${section}`);

  sendThumbnailSection = ({ refSection, fileToUpload }) =>
    this.http.post(
      `/v1/workflows/thumbnailSection/${refSection}`,
      fileToUpload
    );

  sendShareMedia = param => this.http.post(`${API_ROUTES.shareMedia}`, param);

  publishMedia = refMedia =>
    this.http.post(`${API_ROUTES.publishMedia}/${refMedia}/publish`);

  unpublishMedia = refMedia =>
    this.http.post(`${API_ROUTES.publishMedia}/${refMedia}/unpublish`);

  userInfo = () => this.http.get(`${API_ROUTES.userInfo}`);

  getFaq = () => this.http.get(`${API_ROUTES.getFaq}`);

  putFaq = form =>
    this.http.put(`${API_ROUTES.putFaq}`, form, {
      headers: {
        'Content-Type': API_HEADERS.contentType.textPlain
      }
    });

  getConditions = () => this.http.get(`${API_ROUTES.getGeneralConditions}`);

  putConditions = form =>
    this.http.put(`${API_ROUTES.putGeneralConditions}`, form, {
      headers: {
        'Content-Type': API_HEADERS.contentType.textPlain
      }
    });

  deletePartner = refPartner =>
    this.http.delete(`${API_ROUTES.partners}/${refPartner}`);

  loginPartner = guidUser =>
    this.http.post(`${API_ROUTES.partnersLogin}/?${guidUser}`);

  /**
   * Downloads an original media from the backend server.
   *
   * Most of the time, the media will be less than 10 MB and will be directly returned by the server.
   * However, when the file is larger than 10 MB, it cannot pass throught the API Gateway. The server
   * will then upload it to a S3 bucket and return a presigned URL.
   *
   * The way the presigned URL is returned depends on the caller. Generally, a temporary redirect is
   * sufficient as the caller will follow it to finally download the file from the bucket.
   * But our calls to the server are using CORS. And redirects to another domain of forbidden
   * when doing a CORS-enabled request. That is why the server accepts a noRedirect parameter which
   * will transform the default redirect to an uncommon HTTP 206 status code with a Location header.
   * This return status will be treated as a "soft redirect" by the code below and lead to
   * a secondary request used to finally download the file.
   *
   * @param {{refMedia: string}} params Parameters used to download the original media.
   * @returns {Promise<Response>} Axios HTTP response promise.
   */
  getMediaOriginal = params => {
    return new Promise((resolve, reject) => {
      const targetUrl = `${API_ROUTES.medias}/${params.refMedia}${API_ROUTES.download}?noRedirect=true`;
      const downloadOptions = { maxRedirects: 0, responseType: 'blob' };
      // Response handler: returns the response immediately except when encoutering a HTTP 206 status code.
      // In this case, the redirect location is extracted from the header and a new HTTP call is issued.
      const responseHandler = response => {
        // Manually handle redirect since it may target another domain name

        if (response.status === 206) {
          const newLocation = response.headers.location;
          const httpClient = new URL(newLocation).hostname.endsWith(
            '.amazonaws.com'
          )
            ? this.httpS3
            : this.http;

          return httpClient
            .get(newLocation, downloadOptions)
            .then(responseHandler)
            .catch(reject);
        }

        return resolve(response);
      };

      // Reach the server for originla media download
      this.http
        .get(targetUrl, downloadOptions)
        .then(responseHandler)
        .catch(reject);
    });
  };
}

export default new API();
