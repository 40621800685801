/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import 'react-image-gallery/styles/css/image-gallery.css';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarAlert from '../snackBar/SnackbarAlert.jsx';
import './style.scss';
import styles from './style.jsx';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import Button from '../button/buttonComponent.jsx';
import text from '../../constants/translates';
import { colorType, snackBarSeverity } from '../../constants/ui.constants';
import { downloadStart } from '../../redux/download/download.actions';
import { addToMySelection } from '../../redux/mySelection/mySelection.actions';
import { fetchVisualProgramStart } from '../../redux/visual-program/visual-program.actions.js';
import {
  selectVisualProgramIsLoading,
  selectVisualProgramResult
} from '../../redux/visual-program/visual-program.selectors.js';
import {
  snackbarDuration,
  MEDIA_TYPE,
  SIZES,
  ORIGINAL_MEDIA_INSTANCE,
  originalName
} from '../../constants/global-constants.jsx';
import CopyPasteModal from '../modal/copyPasteModal/CopyPasteModal.jsx';
import MediaDetails from '../medialDetails/MediaDetails.jsx';
import { selectMyCurrentSelection } from '../../redux/mySelection/mySelection.selectors';
import MediaDisplay from '../mediaDisplay/MediaDisplay.jsx';
import MediaShareModal from '../modal/mediaShareModal/mediaShareModal.jsx';
import { downloadMedia } from '../../shared/functions/mediaFunctions';
import { fetchMediaOriginalStart } from '../../redux/media-original/media-original.actions';
import { selectMediaOriginalResult } from '../../redux/media-original/media-original.selectors';
import { selectInstanceURL } from '../../redux/urlInstances/urlInstances.selectors.js';
import { fetchURLInstanceStart } from '../../redux/urlInstances/urlInstances.actions.js';
import { checkVitrine } from '../../shared/functions/vitrine';
const VisualProgram = ({
  downloadStartProp,
  updateVisualProgramVisibility,
  selectedProgram,
  fetchVisualProgram,
  visualProgramData,
  isLoading,
  history,
  mySelection,
  addToMySelectionProp,
  downloadOriginalMedia,
  selectMediaOriginal,
  fetchURLInstance,
  instanceURL,
  vitrine
}) => {
  const [checked, setChecked] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [openToast, setOpenToast] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [cropImage, setCropImage] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [toastseverity, setToastseverity] = useState(false);
  const [resolution, setResolution] = useState();
  const [format, setFormat] = useState();
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState();
  const [instance, setInstance] = useState();
  const [selectedDowloadButton, setSelectedDowloadButton] = useState(false);
  const [mediaType, setMediaType] = useState();
  const style = makeStyles(styles)();

  // Function for know if the image selected is image or video
  const checkMediaType = () => {
    if (
      visualProgramData[currentSlide]?.category?.refCategory ===
      MEDIA_TYPE.video
    ) {
      setMediaType(MEDIA_TYPE.video);
    } else {
      setMediaType(MEDIA_TYPE.image);
    }
  };

  useEffect(() => {
    checkMediaType();
  }, [visualProgramData]);

  // Effect for download the original image
  useEffect(() => {
    if (selectMediaOriginal?.data && selectedDowloadButton) {
      setSelectedDowloadButton(false);
      downloadMedia(selectMediaOriginal, [
        { mediaDetails: visualProgramData[currentSlide] }
      ]);
    }
  }, [selectMediaOriginal]);

  useEffect(() => {
    fetchVisualProgram({
      refProgram: selectedProgram?.programRef,
      pageSize: SIZES.visualProgramSize
    });
  }, [selectedProgram?.programRef]);

  useEffect(() => {
    setChecked(false);
    checkMediaType();
  }, [currentSlide]);

  useEffect(() => {
    fetchURLInstance(visualProgramData[currentSlide]);
    const currentInstance = visualProgramData[currentSlide]?.instances?.find(
      item =>
        item.format.refValue === format?.valueCode &&
        item.subFormat.refValue === resolution?.valueCode &&
        item.copyright === checked
    );
    setInstance(currentInstance);
  }, [format, resolution, checked, currentSlide, visualProgramData]);

  const getMediaInstance = () => {
    return {
      mediaDetails: visualProgramData[currentSlide],
      instance: instance,
      refFile: instance?.refFile,
      isOriginal: format === ORIGINAL_MEDIA_INSTANCE
    };
  };

  const showToastAndAddToMySelection = () => {
    let findElement = null;

    if (instance !== undefined) {
      findElement = mySelection.find(
        item => item?.refFile === instance?.refFile
      );
    }

    if (findElement) {
      setToastMessage(text.visualProgram.errorAddMediaToMySelection);
      setToastseverity(snackBarSeverity.error);
    } else {
      setToastMessage(text.visualProgram.addedMediaToMySelection);
      setToastseverity(snackBarSeverity.success);
      addToMySelectionProp(getMediaInstance());
    }

    setOpenToast(true);
  };

  const close = () => {
    updateVisualProgramVisibility(null);
  };

  const downloadSelectedImage = () => {
    if (openCrop) {
      setCropImage(true);
    } else {
      if (
        format?.valueCode === originalName ||
        resolution?.valueCode === originalName
      ) {
        setSelectedDowloadButton(true);
        downloadOriginalMedia({
          refMedia: visualProgramData[currentSlide]?.refMedia
        });
      } else {
        downloadStartProp([
          {
            refFile: instance?.refFile,
            refMedia: visualProgramData[currentSlide]?.refMedia
          }
        ]);
      }
    }
  };

  const setCropImageVisibility = () => {
    setCropImage(false);
    setOpenCrop(false);
  };

  const routeProgram = program => {
    if (checkVitrine(history)) {
      history.push(`${ROUTES_CONSTANTS.vitrineProgramSheetBase}${program}`);
    } else {
      history.push(`${ROUTES_CONSTANTS.mediaSummaryBase}${program}`);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="progress__container">
          <LinearProgress className={style.progress} />
        </div>
      ) : (
        <>
          <SnackbarAlert
            id="AddedAlert"
            open={openToast}
            severity={toastseverity}
            message={toastMessage}
            handleClose={() => setOpenToast(false)}
            duration={snackbarDuration}
          />
          <Grid container className={style.carouselContainer}>
            <Grid item xs={7}>
              <MediaDisplay
                onChangeSlide={e => setCurrentSlide(e)}
                onOpenCopyModal={() => setOpenCopyModal(true)}
                onOpenCrop={() => setOpenCrop(true)}
                onOpenShareModal={() => setOpenShareModal(true)}
                setCropImageVisibility={setCropImageVisibility}
                mediaType={mediaType}
                isMediaGallery={true}
                mediaList={visualProgramData}
                selectedMedia={visualProgramData[currentSlide]}
                openCrop={openCrop}
                cropImage={cropImage}
                history={history}
                onAddToSelection={() => showToastAndAddToMySelection()}
                galleryMode
                checked={checked}
                instanceURL={instanceURL}
                vitrine={vitrine}
              />
            </Grid>
            <Grid item xs={5} className="image-gallery-info">
              <MediaDetails
                media={visualProgramData[currentSlide]}
                program={selectedProgram}
                onClose={() => close()}
                onChangeFormat={e => setFormat(e)}
                onChangeResolution={e => setResolution(e)}
                openCrop={openCrop}
                checkedCopyright={checked}
                onChangeCheckCopyright={e => setChecked(e)}
                formatValue={format}
                resolutionValue={resolution}
                selectedSearchMedia={selectedProgram}
                isCopyrightVisible={
                  visualProgramData[currentSlide]?.typeMedia?.refValue ===
                  MEDIA_TYPE.image
                }
                history={history}
                vitrine={vitrine}
              />
              <div className="button-block">
                <div className="button-block__dowload">
                  {!checkVitrine(history) && (
                    <Button
                      id="buttonDownload"
                      variant="contained"
                      color={colorType.secondary}
                      className={style.buttonDowload}
                      onClick={downloadSelectedImage}
                    >
                      {text.visualProgram.downloadButton}
                    </Button>
                  )}
                  {openCrop ? (
                    <Button
                      id="showButton"
                      variant="outlined"
                      color="primary"
                      className={style.showButton}
                    >
                      {text.visualProgram.shareButton}
                    </Button>
                  ) : (
                    <Button
                      id="showButton"
                      variant="outlined"
                      color="primary"
                      className={style.showButton}
                      onClick={() => routeProgram(selectedProgram?.programRef)}
                    >
                      {text.programList.buttonShowMoreMedia}
                    </Button>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <CopyPasteModal
            id="CopyPasteModal"
            open={openCopyModal}
            onClose={() => setOpenCopyModal(false)}
            history={history}
            media={visualProgramData[currentSlide]}
          />
          <MediaShareModal
            id="MediaShareModal"
            open={openShareModal ? openShareModal : false}
            onClose={() => setOpenShareModal(false)}
            history={history}
            medias={[getMediaInstance()]}
          />
        </>
      )}
    </>
  );
};

VisualProgram.propTypes = {
  downloadStartProp: PropTypes.func,
  updateVisualProgramVisibility: PropTypes.func,
  addToMySelectionProp: PropTypes.func,
  selectedProgram: PropTypes.shape({
    mediaURL: PropTypes.string
  }),
  fetchVisualProgram: PropTypes.func,
  visualProgramData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  isLoading: PropTypes.bool,
  history: PropTypes.func,
  mySelection: PropTypes.oneOfType([PropTypes.string]),
  downloadOriginalMedia: PropTypes.func,
  selectMediaOriginal: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  vitrine: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  visualProgramData: selectVisualProgramResult,
  isLoading: selectVisualProgramIsLoading,
  mySelection: selectMyCurrentSelection,
  selectMediaOriginal: selectMediaOriginalResult,
  instanceURL: selectInstanceURL
});

const mapDispatchToProps = dispatch => ({
  downloadStartProp: dataImage => dispatch(downloadStart(dataImage)),
  downloadOriginalMedia: refMedia =>
    dispatch(fetchMediaOriginalStart(refMedia)),
  addToMySelectionProp: selectedItem =>
    dispatch(addToMySelection(selectedItem)),
  fetchVisualProgram: programRef =>
    dispatch(fetchVisualProgramStart(programRef)),
  fetchURLInstance: media => dispatch(fetchURLInstanceStart(media))
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualProgram);
