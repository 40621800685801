import MediaOriginalActionTypes from './media-original.types';

const INITIAL_STATE = {
  mediaOriginalResult: [],
  error: null,
  isLoading: false
};

const MediaOriginalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_START:
      return { ...state, isLoading: true };
    case MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_SUCCESS:
      return { ...state, mediaOriginalResult: action.payload, error: null };
    case MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_FAILURE:
      return { ...state, error: action.payload };
    case MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default MediaOriginalReducer;
