export const VERSIONS = {
  version1: 'v1/'
};

export const PATH = {
  legalEntity: 'legalEntity',
  contacts: 'contacts',
  naturalperson: 'naturalPerson',
  internalMembers: 'directory/membres',
  search: 'search'
};

const API_ROUTES_INTERVENANTS = {
  legalEntity: `${PATH.legalEntity}`,
  contacts: `${PATH.contacts}`,
  naturalperson: `${PATH.naturalperson}`,
  internalMembers: `${VERSIONS.version1}${PATH.internalMembers}`,
  search: `${PATH.search}`
};

export default API_ROUTES_INTERVENANTS;
