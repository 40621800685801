import ThemeListTypes from './list.theme.types';

export const fetchThemeListStart = params => ({
  type: ThemeListTypes.FETCH_THEME_LIST_START,
  payload: params
});

export const fetchThemeListSuccess = data => ({
  type: ThemeListTypes.FETCH_THEME_LIST_SUCCESS,
  payload: data
});

export const fetchThemeListFailure = error => ({
  type: ThemeListTypes.FETCH_THEME_LIST_FAILURE,
  payload: error
});

export const fetchThemeListFinished = () => ({
  type: ThemeListTypes.FETCH_THEME_LIST_FINISHED
});
