import LegalEntityTypes from './legalEntity.types';

const INITIAL_STATE = {
  result: [],
  error: null,
  isLoading: false
};

const LegalEntityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LegalEntityTypes.FETCH_LEGALENTITY_START:
      return { ...state, isLoading: true };
    case LegalEntityTypes.FETCH_LEGALENTITY_SUCCESS:
      return { ...state, result: action.payload, error: null };
    case LegalEntityTypes.FETCH_LEGALENTITY_FAILURE:
      return { ...state, error: action.payload };
    case LegalEntityTypes.FETCH_LEGALENTITY_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default LegalEntityReducer;
