import MediaOriginalActionTypes from './media-original.types';

export const fetchMediaOriginalStart = refMedia => ({
  type: MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_START,
  payload: refMedia
});

export const fetchMediaOriginalSuccess = data => ({
  type: MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_SUCCESS,
  payload: data
});

export const fetchMediaOriginalFailure = error => ({
  type: MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_FAILURE,
  payload: error
});

export const fetchMediaOriginalFinish = () => ({
  type: MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_FINISH
});
