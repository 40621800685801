import { put, all, call, takeLatest } from 'redux-saga/effects';
import partnerLegalEntityActionTypes from './partner-legalEntity.types';
import { partnerLegalEntityBuilder } from './partner-legalEntity.builder';
import api from '../../api/apiIntervenants.jsx';
import {
  fetchPartnerLegalEntitySuccess,
  fetchPartnerLegalEntityFailure,
  fetchPartnerLegalEntityFinished
} from './partner-legalEntity.actions';

export function* fetchPartnerLegalEntity({ payload: partner }) {
  try {
    const { data } = yield call(api.getPartnerByLegalEntity, partner);
    const dataBuilder = yield call(partnerLegalEntityBuilder, data);
    yield put(fetchPartnerLegalEntitySuccess(dataBuilder));
  } catch (error) {
    yield put(fetchPartnerLegalEntityFailure(error));
  }
  yield put(fetchPartnerLegalEntityFinished());
}

export function* onFetchPartnerLegalEntityStart() {
  yield takeLatest(
    partnerLegalEntityActionTypes.FETCH_PARTNER_LEGALENTITY_START,
    fetchPartnerLegalEntity
  );
}

export function* PartnerLegalEntitySagas() {
  yield all([call(onFetchPartnerLegalEntityStart)]);
}
