import { createSelector } from 'reselect';

const selectVisualFolder = state => state.visualFolder;

export const selectVisualFolderResult = createSelector(
  [selectVisualFolder],
  visualFolder => visualFolder.visualFolderResult
);

export const selectVisualFolderIsLoading = createSelector(
  [selectVisualFolder],
  visualFolder => visualFolder.isLoading
);

export const selectVisualFolderError = createSelector(
  [selectVisualFolder],
  visualFolder => visualFolder.error
);
