import CreateTag from './create.tag.types';

const INITIAL_STATE = {
  results: null,
  error: null,
  isLoading: false
};

const createTagReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreateTag.CREATE_TAG_START:
      return { ...state, isLoading: true };

    case CreateTag.CREATE_TAG_SUCCESS:
      return { ...state, results: action.payload, error: null };

    case CreateTag.CREATE_TAG_FAILURE:
      return { ...state, error: action.payload };

    case CreateTag.CREATE_TAG_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default createTagReducer;
