import CategorizationTypes from './categorization.types';

const INITIAL_STATE = {
  medias: [],
  error: null,
  isLoading: false,
  message: null
};

const categorizationMediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategorizationTypes.SEND_CATEGORIZATION_DATA_START:
      return { ...state, isLoading: true };
    case CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_START:
      return { ...state, isLoading: true };

    case CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_SUCCESS:
      return { ...state, medias: action.payload, error: null };

    case CategorizationTypes.SEND_CATEGORIZATION_DATA_SUCCESS:
      return {
        ...state,
        message: action.payload,
        error: null
      };

    case CategorizationTypes.SEND_CATEGORIZATION_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        message: null
      };
    case CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_FAILURE:
      return { ...state, error: action.payload };

    case CategorizationTypes.SEND_CATEGORIZATION_DATA_FINISHED:
      return { ...state, isLoading: false, error: null };
    case CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default categorizationMediaReducer;
