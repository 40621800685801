export default {
  tabs: {
    fontFamily: 'Avenir Next LT Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    height: '100%'
  },
  formControl: {
    width: '302px',
    margin: 5
  },
  tab: {
    fontSize: 16,
    '&:hover': {
      background: '#4E5360',
      color: '#F5F6FA'
    },
    '&> span': {
      alignItems: 'end',
      textAlign: 'initial'
    }
  },
  indicator: {
    background: 'transparent'
  },
  gridContainer: {
    height: '100%'
  },
  gridSearchRow: {
    height: '20%'
  },
  gridProgramsRow: {
    height: '80%'
  },
  button: {
    height: 72,
    background: '#C82832',
    borderRadius: 10
  },
  labelWidth: 70
};
