import FolderProgramActionTypes from './folder-program.types';

const INITIAL_STATE = {
  folderProgramResult: [],
  error: null,
  isLoading: false
};

const FolderProgramReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_START:
      return { ...state, isLoading: true };
    case FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_SUCCESS:
      return { ...state, folderProgramResult: action.payload, error: null };
    case FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_FAILURE:
      return { ...state, error: action.payload };
    case FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_FINISH:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default FolderProgramReducer;
