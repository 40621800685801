import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import './style.scss';
import styles from './style';
import MySelectionImage from '../mySelectionImage/mySelectionImage';
import ButtonComponent from '../button/buttonComponent.jsx';
import t from '../../constants/translates';
import { pdfFormat } from '../../constants/global-constants';

const MySelectionItems = ({
  items,
  openContactForm,
  dowloadFiles,
  removeFromMySelection,
  sendMediasToCallbackHandler,
  callback
}) => {
  const style = makeStyles(styles)();

  const renderButtons = () => {
    if (callback) {
      return (
        <div className="my-selection-image__callback-button">
          <ButtonComponent
            id="dowload-button"
            className={style.callbackButton}
            onClick={() => sendMediasToCallbackHandler()}
          >
            <span className="button-dowload__text">
              {t.mySelectionItems.validateSelection}
            </span>
          </ButtonComponent>
        </div>
      );
    }
    return (
      <div className="my-selection-image__button-container">
        <ButtonComponent
          id="dowload-button"
          className={style.dowloadButton}
          onClick={() => dowloadFiles(items)}
        >
          <span className="button-dowload__text">
            {t.mySelectionItems.dowloadSelection}
          </span>
        </ButtonComponent>
        <ButtonComponent
          id="share-button"
          className={style.shareButton}
          onClick={() => openContactForm(true)}
        >
          <span className="button-share__text">
            {t.mySelectionItems.shareSelection}
          </span>
        </ButtonComponent>
      </div>
    );
  };

  return (
    <>
      <div className="my-selection-image">
        {items.map(({ mediaDetails }) => (
          <>
            <MySelectionImage
              key={mediaDetails?.nameMedia}
              id={mediaDetails?.refMedia}
              name={
                mediaDetails?.format === pdfFormat
                  ? mediaDetails?.nameExt
                  : mediaDetails?.name
              }
              src={
                mediaDetails?.format === pdfFormat
                  ? mediaDetails?.mediaURL
                  : mediaDetails?.thumbnail
              }
              removeFromMySelection={removeFromMySelection}
              programName={mediaDetails?.program?.name}
              format={mediaDetails?.format}
              programNumber={mediaDetails?.program?.refProgram}
              section={mediaDetails?.section}
            />
          </>
        ))}
      </div>
      {renderButtons()}
    </>
  );
};

MySelectionItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  openContactForm: PropTypes.func,
  dowloadFiles: PropTypes.func,
  removeFromMySelection: PropTypes.func,
  callback: PropTypes.string,
  sendMediasToCallbackHandler: PropTypes.func
};

export default MySelectionItems;
