/* eslint-disable */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MaterialTable from 'material-table';
import swal from 'sweetalert';
import { ListItem, ListItemText, List } from '@material-ui/core';
import { Combobox } from 'react-widgets';
import styles from './style.jsx';
import text from '../../../constants/translates.jsx';
import {
  SearchLegalEntityParams,
  SearchOrganizationalEntityParams,
  getBMEPartnersParams,
  regex,
  adminRigthsColumns,
  EMPTY_STRING,
  PARTNER_FORM,
  QualitiesSupporteds
} from '../../../constants/global-constants';
import Spinner from '../../../components/spinner/Spinner.jsx';
import DismissableAlert from '../../../components/alert/DismissableAlert.jsx';
import ListFiliales from './listFiliales/listFiliales.jsx';
import { fetchLegalEntityStart } from '../../../redux/legalEntity/legalEntity.actions';
import {
  selectLegalEntitysCount,
  selectLegalEntitysResult,
  selectLegalEntitysLoading
} from '../../../redux/legalEntity/legalEntity.selectors';
import { fetchOrganizationalEntityStart } from '../../../redux/organizationalEntity/organizationalEntity.actions';
import {
  selectPostLegalEntityResult,
  selectPostLegalEntityIsLoading,
  selectPostLegalEntityError
} from '../../../redux/postLegalEntity/postLegalEntity.selectors';
import { postLegalEntityStart } from '../../../redux/postLegalEntity/postLegalEntity.actions';
import {
  selectOrganizationalEntitysResult,
  selectOrganizationalEntitysCount,
  selectOrganizationalEntitysLoading
} from '../../../redux/organizationalEntity/organizationalEntity.selectors';
import { sendCreatePartnerDataStart } from '../../../redux/createPartner/createPartnerActions';
import {
  selectCreatePartnerIsLoading,
  selectCreatePartnerSucceed
} from '../../../redux/createPartner/createPartnerSelectors';
import { sendPartnerDeleteDataStart } from '../../../redux/partner-delete/partner-delete.actions';
import { fetchPartnerLegalEntityStart } from '../../../redux/partner-legalEntity/partner-legalEntity.actions';
import {
  selectPartnerDetails,
  selectPartnerDetailsIsLoading
} from '../../../redux/partner-details/partner-details.selectors';
import { fetchPartnerListStart } from '../../../redux/list.partner/list.partner.actions';
import {
  selectPartnerListResult,
  selectPartnerListIsLoading
} from '../../../redux/list.partner/list.partner.selectors';
import { canEdit } from '../../../shared/rights/root-rights';
import { ATTRIBUTES } from '../../../shared/rights/roles';
import {
  selectPartnerLegalEntity,
  selectPartnerLegalEntityIsLoading
} from '../../../redux/partner-legalEntity/partner-legalEntity.selectors';
import {
  selectDeletePartnerIsLoading,
  selectDeletePartnerSucceed
} from '../../../redux/partner-delete/partner-delete.selectors';
import { fetchPartnerDetailsStart } from '../../../redux/partner-details/partner-details.actions';
import { modificationIsLoading } from '../../../redux/partner-modification/partner-modification.selectors';
import { sendModificationPartnerDataStart } from '../../../redux/partner-modification/partner-modification.actions';
import { fetchSocietyQualitiesStart } from '../../../redux/society-qualities/society-qualities.actions';
import {
  selectSocietyQualities,
  selectSocietyQualitiesIsLoading
} from '../../../redux/society-qualities/society-qualities.selectors';

let checked = [];
let checkedValue = [];
let partnerValue = [];
let societyTemp = undefined;
let usersByLegalEntity = [];
let namesCombo = [];
let surNamesCombo = [];
let nameValue = EMPTY_STRING;
let surNameValue = EMPTY_STRING;
let emailValue = EMPTY_STRING;
let phoneValue = EMPTY_STRING;
let villeValue = EMPTY_STRING;
let qualityValue = EMPTY_STRING;
let modeCreationSociete = true;

const societyData = [];
const societyVilleData = [];
const societyQualityData = [];
let legalEntityQualitiesFiltereds = [];
const filialData = [];
const filialCodesData = [];

const AdminRights = ({
  history,
  fetchLegalEntity,
  legalEntitysResult,
  legalEntitysIsloading,
  fetchOrganizationalEntity,
  organizationalEntitysResult,
  organizationalEntitysIsloading,
  postLegalEntity,
  postLegalEntityResult,
  postLegalEntityError,
  postLegalEntityIsLoading,
  createPartner,
  createPartnerIsLoading,
  createPartnerSucceed,
  fetchPartnerDetails,
  fetchPartnerLegalEntity,
  partnerLegalEntity,
  partnerLegalEntityIsLoading,
  deletePartner,
  partnerDeleteIsLoading,
  deletePartnerSucceed,
  partnerDetailsProp,
  partnerDetailsIsLoading,
  fetchPartnerList,
  partnerListIsLoading,
  partnerListResult,
  modifyPartner,
  partnerModificationIsLoading,
  fetchSocietyQualities,
  legalEntityQualitiesResult,
  legalEntityQualitiesIsLoading
}) => {
  const classes = makeStyles(styles)();
  const [dataSetupColumns, setDataSetupColumns] = useState([]);
  const [dataSetup, setDataSetup] = useState([]);
  const [filiales, setFiliales] = useState([]);
  const [society, setSociety] = useState(EMPTY_STRING);
  const [email, setEmail] = useState(EMPTY_STRING);
  const [value, setValue] = useState(EMPTY_STRING);
  // Effect for call intervenants and structures for get the data of societys and filials(organizations)
  useEffect(() => {
    if (!organizationalEntitysResult.length > 0) {
      fetchOrganizationalEntity(SearchOrganizationalEntityParams);
    }
    fetchPartnerList(getBMEPartnersParams);
    fetchSocietyQualities();
  }, []);

  //Effect for refresh the data table after add or delete 1 partner
  useEffect(() => {
    if (createPartnerSucceed || deletePartnerSucceed) {
      // How we have created a new society we call to get societies again
      fetchLegalEntity(SearchLegalEntityParams);
      // We call again to the partner list fro refresh the list
      fetchPartnerList(getBMEPartnersParams);
    }
  }, [createPartnerSucceed, deletePartnerSucceed]);

  useEffect(() => {
    namesCombo = [];
    surNamesCombo = [];
    partnerLegalEntity?.map(item => {
      namesCombo.push(item.firstName);
      surNamesCombo.push(item.lastName);
    });
    usersByLegalEntity = partnerLegalEntity;
  }, [partnerLegalEntity]);

  // Build of the beginning data with the partners info
  useEffect(() => {
    if (partnerListResult.length > 0) {
      const params = [];
      partnerListResult.map(item =>
        // Call for get the partner details from intervenants of each partner that we have in BME
        params.push({
          refLegalEntity: item.refLegalEntity,
          refRelationShipId: item.refRelationShipId
        })
      );
      fetchPartnerDetails(params);
    }
  }, [partnerListResult]);

  useEffect(() => {
    legalEntitysResult.map(item => {
      // Array for the societies data
      societyData[item.refLegalEntity] = item.name;
      return societyData;
    });
    legalEntitysResult.map(item => {
      // Array for the cities of the societies
      societyVilleData[item.refLegalEntity] = item.ville;
      return societyVilleData;
    });
  }, [legalEntitysResult]);

  useEffect(() => {
    if (
      legalEntitysResult?.length > 0 &&
      legalEntityQualitiesResult?.length > 0
    ) {
      legalEntitysResult.map(item => {
        let qualityName = legalEntityQualitiesResult.find(
          quality => quality.quality === item.quality
        )?.name;
        // Array for the qualities of the societies
        societyQualityData[item.refLegalEntity] = qualityName;
        return societyQualityData;
      });

      //Array with the societys supporteds by BaseMedia
      legalEntityQualitiesResult.filter(function(currentElement) {
        QualitiesSupporteds.map(supported => {
          if (parseInt(currentElement.quality) === supported) {
            legalEntityQualitiesFiltereds.push(currentElement);
          }
        });
      });
    }
  }, [legalEntitysResult, legalEntityQualitiesResult]);

  const handleGoBack = () => {
    history.goBack();
  };

  // Function for reset the temporal data in the case that the user cancel the edit row
  const resetEditableData = () => {
    checked = [];
    checkedValue = [];
    setEmail(EMPTY_STRING);
    societyTemp = undefined;
    nameValue = EMPTY_STRING;
    surNameValue = EMPTY_STRING;
    emailValue = EMPTY_STRING;
    villeValue = EMPTY_STRING;
    qualityValue = EMPTY_STRING;
    phoneValue = EMPTY_STRING;
    namesCombo = [];
    surNamesCombo = [];
    modeCreationSociete = false;
  };

  // Effect for create the partenaires in BME with the result of intervenants
  useEffect(() => {
    if (
      !postLegalEntityIsLoading &&
      postLegalEntityError === null &&
      postLegalEntityResult !== null
    ) {
      const filialArray = [];
      filiales.forEach(filial => {
        return filialArray.push(filialCodesData[filial]);
      });
      const paramsPartner = {
        refLegalEntity: modeCreationSociete
          ? postLegalEntityResult?.refLegalEntity
          : society,
        refRelationShipId: modeCreationSociete
          ? postLegalEntityResult?.refRelationShipId
          : postLegalEntityResult?.refLegalEntityRelationship,
        email,
        organizationalUnits: filialArray
      };
      createPartner(paramsPartner);
      resetEditableData();
    }
  }, [postLegalEntityIsLoading, postLegalEntityResult]);

  // Function to set filter the array of names or surnames
  const handleName = (valueVar, type) => {
    if (type === PARTNER_FORM.name) {
      nameValue = valueVar;
      surNamesCombo = [];
      usersByLegalEntity?.map(item => {
        if (valueVar.length > 0) {
          item.firstName.includes(valueVar) &&
            surNamesCombo.push(item.lastName);
          setValue(valueVar);
          return valueVar;
        } else {
          surNamesCombo.push(item.lastName);
          setValue(valueVar);
          return valueVar;
        }
      });
    } else if (type === PARTNER_FORM.surname) {
      surNameValue = valueVar;
      namesCombo = [];
      usersByLegalEntity?.map(item => {
        if (valueVar.length > 0) {
          item.lastName.includes(valueVar) && namesCombo.push(item.firstName);
          setValue(valueVar);
          return valueVar;
        } else {
          namesCombo.push(item.firstName);
          setValue(valueVar);
          return valueVar;
        }
      });
    }
  };

  const handleNameSelect = (valueVar, type) => {
    let user = null;
    if (type === PARTNER_FORM.name) {
      nameValue = valueVar;
      user = usersByLegalEntity?.filter(
        legalEntity => legalEntity.firstName === valueVar
      )[0];
      surNameValue = user.lastName;
    } else if (type === PARTNER_FORM.surname) {
      surNameValue = valueVar;
      user = usersByLegalEntity?.filter(
        legalEntity => legalEntity.lastName === valueVar
      )[0];
      nameValue = user.firstName;
    }

    if (user !== null) {
      emailValue = user.contactEmail;
      phoneValue = user.contactPhone;
    }
  };

  const handleTextField = (valueVar, type) => {
    if (type === PARTNER_FORM.email) {
      emailValue = valueVar.target.value;
      setValue(valueVar);
    } else if (type === PARTNER_FORM.phone) {
      phoneValue = valueVar.target.value;
      setValue(valueVar);
    } else if (type === PARTNER_FORM.name) {
      nameValue = valueVar.target.value;
      setValue(valueVar);
    } else if (type === PARTNER_FORM.surname) {
      surNameValue = valueVar.target.value;
      setValue(valueVar);
    } else if (type === PARTNER_FORM.ville) {
      villeValue = valueVar.target.value;
      setValue(valueVar);
    }
  };

  // Function to set or delete the filiale selection done by the user
  const handleSociety = valueVar => {
    //For know if the mode for the society creation has to be activated
    if (legalEntitysResult.indexOf(valueVar) >= 0) {
      modeCreationSociete = false;
      setSociety(valueVar.refLegalEntity);
      societyTemp = valueVar.refLegalEntity;
    } else {
      modeCreationSociete = true;
      qualityValue = EMPTY_STRING; //Reset of the quality field if the create the society
      setSociety(valueVar);
      societyTemp = valueVar;
    }
  };

  // Function to set or delete the quality
  const handleQuality = valueVar => {
    qualityValue = valueVar;
  };

  // Function to get the partners of the selected society
  const getPartnersBySociety = valueVar => {
    modeCreationSociete = false;
    // We set the ville of the society selected
    villeValue = societyVilleData[valueVar.refLegalEntity];
    // We set the quality of the society selected
    qualityValue = societyQualityData[valueVar.refLegalEntity];
    fetchPartnerLegalEntity(valueVar.refLegalEntity);
  };

  // Function to set or delete the filiale selection done by the user
  const handleFilial = valueVar => () => {
    const currentIndex = checked.indexOf(valueVar.refOrganizationalEntity);
    const newChecked = [...checked];
    const newCheckedValue = [...checkedValue];

    if (currentIndex === -1) {
      newChecked.push(valueVar.refOrganizationalEntity);
      newCheckedValue.push(valueVar.name);
    } else {
      newChecked.splice(currentIndex, 1);
      newCheckedValue.splice(currentIndex, 1);
    }
    checked = newChecked;
    checkedValue = newCheckedValue;
    setFiliales(newChecked); // For call state and refresh check data
  };

  const getListFiliales = data => {
    if (data?.subsidiary?.length > 0) {
      return (
        <List>
          {data?.subsidiary?.map((subsidiaryValue, key) => {
            return (
              <ListItem key={key} disableGutters>
                <ListItemText disableTypography primary={subsidiaryValue} />
              </ListItem>
            );
          })}
        </List>
      );
    }
    return null;
  };

  // Function to show the select list with the filiales
  const getEditListFiliales = () => {
    return (
      <ListFiliales
        checkedValue={checkedValue}
        organizationalEntitysResult={organizationalEntitysResult}
        handleFilial={handleFilial}
        checked={checked}
      />
    );
  };

  // Function to show the textfield of email and phoneNumber
  const textField = type => {
    let textFieldValue = null;
    if (type === PARTNER_FORM.email) {
      textFieldValue = emailValue;
    } else if (type === PARTNER_FORM.phone) {
      textFieldValue = phoneValue;
    } else if (type === PARTNER_FORM.name) {
      textFieldValue = nameValue;
    } else if (type === PARTNER_FORM.surname) {
      textFieldValue = surNameValue;
    } else if (type === PARTNER_FORM.ville) {
      textFieldValue = villeValue;
    }
    return (
      <TextField
        id={type}
        label={type}
        value={textFieldValue}
        onChange={e => handleTextField(e, type)}
        disabled={isDisabled(type)}
      />
    );
  };

  // Function to show the select list with the societys
  const societyCombo = () => {
    const societyName = societyData?.filter(
      item => item.refLegalEntity === society
    )[0]?.name;
    return (
      <Combobox
        id="society"
        valueField="id"
        className="combobox-admin"
        textField="name"
        placeholder={text.AdminRights.society}
        data={legalEntitysResult}
        value={societyName}
        filter="contains"
        messages={{
          emptyList: text.noResultexts,
          emptyFilter: text.noResults
        }}
        onChange={e => handleSociety(e)}
        onSelect={e => getPartnersBySociety(e)}
      />
    );
  };

  // Function for change the visibilite of the form champs
  const isDisabled = type => {
    if ((type === PARTNER_FORM.ville) & modeCreationSociete) {
      return false;
    }
    if ((type === PARTNER_FORM.ville) & !modeCreationSociete) {
      return true;
    }
    if (societyTemp === undefined) {
      return true;
    }
    if (societyTemp?.length > 0) {
      return false;
    }
    return true;
  };

  // Function to show the quality list
  const qualityCombo = () => {
    return (
      <Combobox
        id="quality"
        valueField="quality"
        className="combobox-admin"
        textField="name"
        placeholder={text.AdminRights.typeSociety}
        data={legalEntityQualitiesFiltereds}
        value={modeCreationSociete ? qualityValue.name : qualityValue}
        disabled={isDisabled(PARTNER_FORM.ville)}
        filter="contains"
        messages={{
          emptyList: text.noResultexts,
          emptyFilter: text.noResults
        }}
        onChange={e => handleQuality(e)}
      />
    );
  };

  // Function to show the select the name
  const nameCombo = (placeholder, data, nameComboValue) => {
    if (data.length > 0) {
      return (
        <Combobox
          id={placeholder}
          valueField="id"
          className="combobox-name"
          textField="name"
          placeholder={placeholder}
          data={data}
          disabled={isDisabled()}
          value={nameComboValue}
          filter="contains"
          messages={{
            emptyList: text.noResultexts,
            emptyFilter: text.noResults
          }}
          onChange={e => handleName(e, placeholder)}
          onSelect={e => handleNameSelect(e, placeholder)}
        />
      );
    } else {
      return textField(placeholder);
    }
  };

  // Original data with the table columns formats
  const callDataSetupColumns = () => {
    setDataSetupColumns([
      {
        title: text.AdminRights.nameSociety,
        field: adminRigthsColumns.nameSociety,
        lookup: societyData,
        editComponent: () => societyCombo()
      },
      {
        title: text.AdminRights.citySociety,
        field: adminRigthsColumns.citySociety,
        lookup: societyVilleData,
        editComponent: () => textField(PARTNER_FORM.ville)
      },
      {
        title: text.AdminRights.typeSociety,
        field: adminRigthsColumns.typeSociety,
        lookup: societyQualityData,
        editComponent: () => qualityCombo()
      },
      {
        title: text.AdminRights.surname,
        field: adminRigthsColumns.surname,
        editComponent: () =>
          nameCombo(PARTNER_FORM.surname, surNamesCombo, surNameValue)
      },
      {
        title: text.AdminRights.name,
        field: adminRigthsColumns.name,
        editComponent: () => nameCombo(PARTNER_FORM.name, namesCombo, nameValue)
      },
      {
        title: text.AdminRights.email,
        field: adminRigthsColumns.email,
        editComponent: () => textField(PARTNER_FORM.email)
      },
      {
        title: text.AdminRights.phoneNumber,
        field: adminRigthsColumns.phoneNumber,
        editComponent: () => textField(PARTNER_FORM.phone)
      },
      {
        title: text.AdminRights.subsidiary,
        field: adminRigthsColumns.subsidiary,
        lookup: filialData,
        render: data => getListFiliales(data),
        editComponent: () => getEditListFiliales()
      }
    ]);
  };

  // Function for get the filials names from his ids
  const filterSubsidiary = subsidiaryList => {
    const subsidiary = [];
    subsidiaryList?.map(item =>
      subsidiary.push(
        organizationalEntitysResult?.filter(
          organizationalEntity =>
            organizationalEntity.organizationalEntityCode === item.id
        )[0]?.name
      )
    );
    return subsidiary;
  };

  // Function for format the partners data from BME
  const createPartnersData = partnerDetails => {
    partnerValue = [];
    partnerDetails.map(item => {
      const subsidiary = filterSubsidiary(
        partnerListResult?.filter(
          partnerFilter =>
            partnerFilter.refRelationShipId === item.refRelationShipId
        )[0]?.organizationalUnits
      );
      const refPartner = partnerListResult?.filter(
        partnerFilter =>
          partnerFilter.refRelationShipId === item.refRelationShipId
      )[0]?.refPartner;

      const partner = {
        nameSociety: item.refLegalEntity,
        citySociety: item.refLegalEntity,
        typeSociety: item.refLegalEntity,
        name: item.firstName,
        surname: item.lastName,
        email: item.contactEmail,
        phoneNumber: item.contactPhone,
        subsidiary,
        refRelationShipId: item.refRelationShipId,
        refPartner: refPartner
      };
      return partnerValue.push(partner);
    });
  };

  useEffect(() => {
    //We order the filials by name
    organizationalEntitysResult.sort((a, b) => (a.name > b.name ? 1 : -1));
    organizationalEntitysResult.map(item => {
      filialData[item.refOrganizationalEntity] = item.name;
      filialCodesData[item.refOrganizationalEntity] =
        item.organizationalEntityCode;
      return filialData;
    });
    callDataSetupColumns();
  }, [organizationalEntitysResult]);

  // Effect after receive the societys and filials and after all the calls for receive the detail of all partners is finished
  useEffect(() => {
    if (
      legalEntitysResult.length > 0 &&
      organizationalEntitysResult.length > 0 &&
      partnerListResult.length > 0 &&
      partnerDetailsProp.length > 0 // We assure that we have all the info detail of all the partners received from BME
    ) {
      createPartnersData(partnerDetailsProp);
      setDataSetup(partnerValue);
    } else {
      createPartnersData([]);
      setDataSetup([]);
    }
  }, [
    legalEntitysResult,
    organizationalEntitysResult,
    partnerListResult,
    partnerDetailsProp
  ]);

  useEffect(() => {
    callDataSetupColumns();
  }, [dataSetup, legalEntitysResult]);

  const showError = message => {
    swal(EMPTY_STRING, message, 'error', {
      buttons: text.visualProgram.textButtonOk
    });
  };

  const icons = () => ({
    Add: () => (
      <div data-testid="addTest">
        <AddBox className={classes.colorRed} />
        <span className="button-add__text">{text.AdminRights.addText}</span>
      </div>
    ),
    Edit: () => (
      <>
        <Edit className={classes.colorOrange} />
        <span className="button-edit__text">{text.AdminRights.editText}</span>
      </>
    ),
    Delete: () => (
      <>
        <Delete className={classes.colorRed} />
        <span className="button-delete__text">
          {text.AdminRights.deleteText}
        </span>
      </>
    )
  });

  const createPostPayload = () => {
    let postPayload = null;
    // If the society doe not exits we add the society payload to create the osciety before create the partner
    if (modeCreationSociete) {
      const societyPayload = {
        name: society,
        nature: {
          reference: '1'
        },
        quality: {
          reference: qualityValue.quality
        },
        locationInformation: {
          address: {
            city: villeValue
          }
        },
        legalEntityRelationships: [
          {
            lastName: surNameValue,
            firstName: nameValue,
            contactPhone: phoneValue,
            contactEmail: emailValue
          }
        ]
      };

      postPayload = {
        id: society,
        payload: {
          firstName: nameValue,
          lastName: surNameValue,
          contactEmail: emailValue,
          contactPhone: phoneValue
        },
        payloadSociety: societyPayload
      };
    } else {
      postPayload = {
        id: society,
        payload: {
          firstName: nameValue,
          lastName: surNameValue,
          contactEmail: emailValue,
          contactPhone: phoneValue
        }
      };
    }

    return postPayload;
  };

  const isLoading = () => {
    return (
      legalEntitysIsloading ||
      organizationalEntitysIsloading ||
      createPartnerIsLoading ||
      partnerListIsLoading ||
      partnerDetailsIsLoading ||
      partnerLegalEntityIsLoading ||
      partnerDeleteIsLoading ||
      partnerModificationIsLoading ||
      legalEntityQualitiesIsLoading
    );
  };

  const dataValidation = () => {
    return (
      nameValue?.length > 0 &&
      surNameValue?.length > 0 &&
      emailValue?.length > 0 &&
      phoneValue?.length > 0 &&
      society?.length > 0 &&
      filiales?.length > 0 &&
      (modeCreationSociete
        ? qualityValue?.quality?.toString().length > 0
        : true)
    );
  };

  // Function to validate and create the partner
  const onRowAdd = (resolve, reject) => {
    // we validate that exits the data to post
    if (dataValidation()) {
      // Validation of Email
      if (regex.email.test(emailValue.toLowerCase())) {
        // Validation of phone number
        if (regex.telephone.test(phoneValue.toLowerCase())) {
          setEmail(emailValue);

          const postPayload = createPostPayload();

          // Methode for create the legal entity in intervenants

          postLegalEntity(postPayload);
          resolve();
        } else {
          showError(text.Administration.messageErrorPhone);
          reject();
        }
      } else {
        showError(text.Administration.messageErrorEmail);
        reject();
      }
    } else {
      showError(text.Administration.messageErrorData);
      reject();
    }
  };

  // Function to delete the partner
  const onRowDelete = (resolve, reject, data) => {
    deletePartner(data);
    resolve();
  };

  if (!canEdit(true, ATTRIBUTES.BME_USER_WRITE)) {
    return <div></div>;
  }

  return (
    <div className="page-container">
      <DismissableAlert />
      <Spinner className="spinner" isLoading={isLoading()} />
      <div className="page-header">
        <Button
          id="goBackButton"
          variant="outlined"
          color="primary"
          className={classes.returnButton}
          onClick={handleGoBack}
        >
          <ArrowBackIosOutlinedIcon />
          <b>{text.programSheet.return}</b>
        </Button>
        <span className="title__text">{text.AdminRights.title}</span>
      </div>
      <div className="body-container">
        <MaterialTable
          title=""
          columns={dataSetupColumns}
          data={dataSetup}
          editable={{
            onRowUpdateCancelled: () => resetEditableData(),
            onRowAddCancelled: () => resetEditableData(),
            onRowAdd: () =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  onRowAdd(resolve, reject);
                }, 1000);
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  onRowDelete(resolve, reject, oldData);
                }, 1000);
              })
          }}
          // Custom icons for actions
          icons={icons}
          options={{
            addRowPosition: 'first',
            actionsColumnIndex: -1,
            headerStyle: {
              fontSize: '16px',
              color: '#8F97AD',
              width: 'calc((100% - 0px) / 9)',
              padding: '4px'
            },
            cellStyle: {
              fontSize: '13px',
              width: 'calc((100% - 0px) / 9)',
              padding: '50px'
            }
          }}
          localization={{
            toolbar: {
              searchPlaceholder: text.AdminRights.search,
              searchTooltip: EMPTY_STRING
            },
            pagination: {
              labelRowsSelect: text.AdminRights.rows,
              labelDisplayedRows: text.AdminRights.displayedRows,
              firstTooltip: text.AdminRights.firstPage,
              previousTooltip: text.AdminRights.previousPage,
              nextTooltip: text.AdminRights.nextPage,
              lastTooltip: text.AdminRights.lastPage
            },
            body: {
              addTooltip: EMPTY_STRING,
              deleteTooltip: EMPTY_STRING,
              editTooltip: EMPTY_STRING,
              emptyDataSourceMessage: text.AdminRights.noData,
              editRow: {
                deleteText: text.AdminRights.deleteQuestion
              }
            }
          }}
        />
      </div>
    </div>
  );
};

AdminRights.propTypes = {
  history: PropTypes.func,
  fetchLegalEntity: PropTypes.func,
  legalEntitysResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  legalEntitysIsloading: PropTypes.func,
  fetchOrganizationalEntity: PropTypes.func,
  organizationalEntitysResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  organizationalEntitysIsloading: PropTypes.func,
  postLegalEntityResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  postLegalEntityIsLoading: PropTypes.func,
  postLegalEntity: PropTypes.func,
  postLegalEntityError: PropTypes.func,
  createPartner: PropTypes.func,
  deletePartner: PropTypes.func,
  partnerDeleteIsLoading: PropTypes.func,
  createPartnerIsLoading: PropTypes.func,
  createPartnerSucceed: PropTypes.bool,
  partnerDetailsProp: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  partnerListResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  partnerDetailsIsLoading: PropTypes.func,
  partnerListIsLoading: PropTypes.func,
  fetchPartnerList: PropTypes.func,
  fetchPartnerDetails: PropTypes.func,
  fetchPartnerLegalEntity: PropTypes.func,
  modifyPartner: PropTypes.func,
  partnerModificationIsLoading: PropTypes.func,
  fetchSocietyQualities: PropTypes.func,
  legalEntityQualitiesResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  legalEntityQualitiesIsLoading: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  legalEntitysResult: selectLegalEntitysResult,
  legalEntitysCount: selectLegalEntitysCount,
  legalEntitysIsloading: selectLegalEntitysLoading,
  organizationalEntitysResult: selectOrganizationalEntitysResult,
  organizationalEntitysCount: selectOrganizationalEntitysCount,
  organizationalEntitysIsloading: selectOrganizationalEntitysLoading,
  postLegalEntityResult: selectPostLegalEntityResult,
  postLegalEntityIsLoading: selectPostLegalEntityIsLoading,
  postLegalEntityError: selectPostLegalEntityError,
  createPartnerIsLoading: selectCreatePartnerIsLoading,
  createPartnerSucceed: selectCreatePartnerSucceed,
  partnerDetailsProp: selectPartnerDetails,
  partnerDeleteIsLoading: selectDeletePartnerIsLoading,
  deletePartnerSucceed: selectDeletePartnerSucceed,
  partnerDetailsIsLoading: selectPartnerDetailsIsLoading,
  partnerListResult: selectPartnerListResult,
  partnerListIsLoading: selectPartnerListIsLoading,
  partnerLegalEntity: selectPartnerLegalEntity,
  partnerLegalEntityIsLoading: selectPartnerLegalEntityIsLoading,
  partnerModificationIsLoading: modificationIsLoading,
  legalEntityQualitiesResult: selectSocietyQualities,
  legalEntityQualitiesIsLoading: selectSocietyQualitiesIsLoading
});

const mapDispatchToProps = dispatch => ({
  fetchLegalEntity: params => dispatch(fetchLegalEntityStart(params)),
  postLegalEntity: params => dispatch(postLegalEntityStart(params)),
  fetchOrganizationalEntity: params =>
    dispatch(fetchOrganizationalEntityStart(params)),
  createPartner: params => dispatch(sendCreatePartnerDataStart(params)),
  deletePartner: params => dispatch(sendPartnerDeleteDataStart(params)),
  modifyPartner: params => dispatch(sendModificationPartnerDataStart(params)),
  fetchPartnerDetails: params => dispatch(fetchPartnerDetailsStart(params)),
  fetchPartnerList: params => dispatch(fetchPartnerListStart(params)),
  fetchPartnerLegalEntity: params =>
    dispatch(fetchPartnerLegalEntityStart(params)),
  fetchSocietyQualities: params => dispatch(fetchSocietyQualitiesStart(params))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminRights)
);
