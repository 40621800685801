import { createSelector } from 'reselect';

const folderDetails = state => state.folderDetails;

export const selectFolderDetails = createSelector(
  [folderDetails],
  folder => folder.folderDetails
);

export const selectFolderDetailsIsLoading = createSelector(
  [folderDetails],
  folder => folder.isLoading
);

export const selectFolderDetailsError = createSelector(
  [folderDetails],
  folder => folder.error
);
