export default {
  buttonAddMedia: {
    width: 'auto',
    marginLeft: 'auto',
    marginRight: '20px',
    border: '1px solid #FFFFFF',
    alignSelf: 'center'
  },
  buttonSearch: {
    width: 'auto',
    padding: '0px 40px',
    marginLeft: 'auto',
    marginRight: '20px',
    alignSelf: 'center'
  }
};
