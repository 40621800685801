export default {
  button: {
    color: '#000000',
    width: '280px',
    border: '1.5px solid #C82832',
    background: '#C82832',
    float: 'right',
    '&:hover': {
      border: '1px solid #BF7703',
      color: '#BF7703',
      background: '#b30000'
    },
    '&:disabled': {
      border: '1px solid #555555',
      color: '#BF7703',
      background: '#C82832',
      opacity: 0.5
    },
    marginTop: '50px',
    marginRight: '80px'
  },
  buttonText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    color: '#FFFFFF'
  },
  deleteIcon: {
    padding: '2px',
    margin: '6px',
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      color: '#C82832'
    }
  }
};
