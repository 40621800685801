import React from 'react';
import PropTypes from 'prop-types';
import t from '../../../constants/translates';
import './style.scss';

const FilialLogo = ({ getImage }) => {
  return (
    <>
      <img
        crossOrigin="anonymous"
        src={getImage()}
        alt="logo"
        className="menu__logo-filial"
      />
      <p className="menu__title-filial">{t.header.menuTitle}</p>
    </>
  );
};

FilialLogo.propTypes = {
  getImage: PropTypes.func
};

export default FilialLogo;
