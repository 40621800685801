import { createSelector } from 'reselect';

const selectMediaOriginal = state => state.mediaOriginal;

export const selectMediaOriginalResult = createSelector(
  [selectMediaOriginal],
  mediaOriginal => mediaOriginal.mediaOriginalResult
);

export const selectMediaOriginalIsLoading = createSelector(
  [selectMediaOriginal],
  mediaOriginal => mediaOriginal.isLoading
);

export const selectMediaOriginalError = createSelector(
  [selectMediaOriginal],
  mediaOriginal => mediaOriginal.error
);
