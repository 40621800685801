export default {
  title: {
    fontSize: 14,
    fontFamily: 'Avenir Next LT Pro'
  },
  subTitle: {
    fontSize: 10,
    fontFamily: 'Avenir Next LT Pro'
  },
  button: {
    color: '#C82832',
    border: '1.5px solid #C82832',
    background: '#F5F6FA'
  },
  buttonText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: '#C82832'
  },
  card: {
    width: '382px',
    background: '#F5F6FA',
    border: '1px solid #DBE2F4',
    boxShadow: '0px 8px 24px rgba(219, 226, 244, 0.5)',
    marginRight: '35px',
    marginTop: '20px'
  },
  cardContent: {
    '&:last-child': {
      padding: 8
    }
  },

  addMediaButton: {
    '&:disabled': {
      border: '1px solid #4E5360',
      opacity: '0.7',
      '&> span > p': {
        color: '#4E5360'
      }
    }
  }
};
