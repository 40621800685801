import NaturalPersonsTypes from './naturalPersons.types';

export const fetchNaturalPersonsStart = params => ({
  type: NaturalPersonsTypes.FETCH_NATURALPERSONS_START,
  payload: params
});

export const fetchNaturalPersonsSuccess = params => ({
  type: NaturalPersonsTypes.FETCH_NATURALPERSONS_SUCCESS,
  payload: params
});

export const fetchNaturalPersonsFailure = error => ({
  type: NaturalPersonsTypes.FETCH_NATURALPERSONS_FAILURE,
  payload: error
});

export const fetchNaturalPersonsFinished = () => ({
  type: NaturalPersonsTypes.FETCH_NATURALPERSONS_FINISHED
});
