import ProgramModify from './programModificationTypes';

const INITIAL_STATE = {
  succeed: false,
  message: null,
  error: null,
  isLoading: false
};

const programModifyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProgramModify.UPDATE_PROGRAM_NAME_START:
      return { ...state, isLoading: true, succeed: false };

    case ProgramModify.UPDATE_PROGRAM_NAME_SUCCESS:
      return { ...state, message: action.payload, error: null, succeed: true };

    case ProgramModify.UPDATE_PROGRAM_NAME_FAILURE:
      return { ...state, error: action.payload, succeed: false };

    case ProgramModify.UPDATE_PROGRAM_NAME_FINISHED:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default programModifyReducer;
