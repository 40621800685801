import { put, all, call, takeLatest } from 'redux-saga/effects';
import PutFaqTypes from './putFaqTypes';
import api from '../../api/api.jsx';
import {
  sendPutFaqDataSuccess,
  sendPutFaqDataFailure,
  sendPutFaqDataFinished
} from './putFaqActions';

export function* putFaq({ payload: form }) {
  try {
    const { data } = yield call(api.putFaq, form);
    yield put(sendPutFaqDataSuccess(data));
  } catch (error) {
    yield put(sendPutFaqDataFailure(error));
  }
  yield put(sendPutFaqDataFinished());
}

export function* onPutFaqStart() {
  yield takeLatest(PutFaqTypes.SEND_PUT_FAQ_START, putFaq);
}

export function* putFaqSagas() {
  yield all([call(onPutFaqStart)]);
}
