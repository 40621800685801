import CategorizationTypes from './categorization.types';

export const fetchCategorizationMediaStart = data => ({
  type: CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_START,
  payload: data
});

export const fetchCategorizationMediaSuccess = medias => ({
  type: CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_SUCCESS,
  payload: medias
});

export const fetchCategorizationMediaFailure = error => ({
  type: CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_FAILURE,
  payload: error
});

export const fetchCategorizationMediaFinished = () => ({
  type: CategorizationTypes.FETCH_CATEGORIZATION_MEDIA_FINISHED
});

export const sendCategorizationDataStart = data => ({
  type: CategorizationTypes.SEND_CATEGORIZATION_DATA_START,
  payload: data
});

export const sendCategorizationDataSuccess = medias => ({
  type: CategorizationTypes.SEND_CATEGORIZATION_DATA_SUCCESS,
  payload: medias
});

export const sendCategorizationDataFailure = error => ({
  type: CategorizationTypes.SEND_CATEGORIZATION_DATA_FAILURE,
  payload: error
});

export const sendCategorizationDataFinished = () => ({
  type: CategorizationTypes.SEND_CATEGORIZATION_DATA_FINISHED
});
