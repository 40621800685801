export default {
  button: {
    background: '#FFFFFF',
    border: '1px solid #8F97AD',
    boxSizing: 'border-box',
    boxShadow: '0px 8px 25px rgba(219, 226, 244, 0.5)',
    borderRadius: '5px',
    fontFamily: 'Avenir Next LT Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    textTransform: 'none',
    height: '108px',
    width: '100%'
  }
};
