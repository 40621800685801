import FileUploaderTypes from './fileUploaderTypes';

export const addFilesStart = files => ({
  type: FileUploaderTypes.ADD_FILES_START,
  payload: files
});

export const addFilesSuccess = medias => ({
  type: FileUploaderTypes.ADD_FILES_SUCCESS,
  payload: medias
});

export const addFilesFailure = error => ({
  type: FileUploaderTypes.ADD_FILES_FAILURE,
  payload: error
});

export const addFilesFinished = () => ({
  type: FileUploaderTypes.ADD_FILES_FINISHED
});

export const addFilesClear = () => ({
  type: FileUploaderTypes.ADD_FILES_CLEAR
});
