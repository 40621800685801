import FileSectionUploaderTypes from './fileSectionUploaderTypes';

export const addFilesSectionStart = files => ({
  type: FileSectionUploaderTypes.ADD_FILES_SECTION_START,
  payload: files
});

export const addFilesSectionSuccess = medias => ({
  type: FileSectionUploaderTypes.ADD_FILES_SECTION_SUCCESS,
  payload: medias
});

export const addFilesSectionFailure = error => ({
  type: FileSectionUploaderTypes.ADD_FILES_SECTION_FAILURE,
  payload: error
});

export const addFilesSectionFinished = () => ({
  type: FileSectionUploaderTypes.ADD_FILES_SECTION_FINISHED
});
