import { getCommunesFromCityCode } from '../regions/communesHelper.js';

const cityItemBuilder = city => {
  return {
    name: city?.nom,
    code: city?.code,
    zipCodes: city?.codesPostaux
  };
};

export function cityBuilder(cityCode, communes) {
  const city = getCommunesFromCityCode(cityCode, communes);
  return cityItemBuilder(city);
}
