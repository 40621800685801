import { put, all, call, takeLatest } from 'redux-saga/effects';
import OrganizationalEntityActionTypes from './organizationalEntity.types';
import { organizationalEntityBuilder } from './organizationalEntity.builder';
import apiStructure from '../../api/apiStructure';
import {
  fetchOrganizationalEntitySuccess,
  fetchOrganizationalEntityFailure,
  fetchOrganizationalEntityFinished,
  fetchCompanyDataSuccess,
  fetchCompanyDataFailure,
  fetchCompanyDataFinished
} from './organizationalEntity.actions';

export function* fetchOrganizationalEntity({ payload: params }) {
  try {
    const { data } = yield call(
      apiStructure.getListOforganizationalEntities,
      params
    );
    const dataBuilder = yield call(organizationalEntityBuilder, data);
    yield put(fetchOrganizationalEntitySuccess(dataBuilder));
  } catch (error) {
    yield put(fetchOrganizationalEntityFailure(error));
  }
  yield put(fetchOrganizationalEntityFinished());
}

export function* fetchCompanyData({ payload: params }) {
  try {
    const { data } = yield call(apiStructure.getFilial, params);
    yield put(fetchCompanyDataSuccess(data));
  } catch (error) {
    yield put(fetchCompanyDataFailure(error));
  }
  yield put(fetchCompanyDataFinished());
}

export function* onFetchOrganizationalEntityStart() {
  yield takeLatest(
    OrganizationalEntityActionTypes.FETCH_ORGANIZATIONALENTITY_START,
    fetchOrganizationalEntity
  );
}

export function* onFetchCompanyDataStart() {
  yield takeLatest(
    OrganizationalEntityActionTypes.FETCH_COMPANY_DATA_START,
    fetchCompanyData
  );
}

export function* organizationalEntitySagas() {
  yield all([
    call(onFetchOrganizationalEntityStart),
    call(onFetchCompanyDataStart)
  ]);
}
