import { createSelector } from 'reselect';

const selectCreateFolder = state => state.createFolder;

export const selectCreateFolderMessage = createSelector(
  [selectCreateFolder],
  createFolder => createFolder.message
);

export const selectCreateFolderIsLoading = createSelector(
  [selectCreateFolder],
  createFolder => createFolder.isLoading
);

export const selectCreateFolderError = createSelector(
  [selectCreateFolder],
  createFolder => createFolder.error
);

export const selectCreateFolderSucceed = createSelector(
  [selectCreateFolder],
  createFolder => createFolder.succeed
);
