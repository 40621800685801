import { put, all, call, takeLatest } from 'redux-saga/effects';
import programExternalInfoActionTypes from './programExternalInfo.types';
import apiCpi from '../../api/apiCpi.jsx';
import apiTlc from '../../api/apiTlc.jsx';
import apiNomenclature from '../../api/apiNomenclature.jsx';
import apiPlg from '../../api/apiPlg';

import {
  getProgramExternalInfoSuccess,
  getProgramExternalInfoFailure,
  getProgramExternalInfoFinished
} from './programExternalInfo.actions';

import { externalInfoBuilder } from './programExternalInfoBuilder';

export function* getProgramExternalInfo({ payload: program }) {
  try {
    const dataCpi = yield call(apiCpi.getProgramCPI, program.refProgram);

    const listNomenclature = yield call(
      apiNomenclature.getValuesFromListOfValues,
      dataCpi.data.refMainActivityExt
    );
    const dataTLC = yield call(apiTlc.getIndicators, program.refProgram);
    const dataFavAssumptionsCPI = yield call(
      apiCpi.getFavouriteAssumptionsBlock,
      program.refProgram
    );
    const dataNatureProduit = yield call(apiNomenclature.getListNatureBloc);
    // 6
    const favAssumption = dataCpi.data.assumptions.find(assumption => {
      return assumption?.favoriteFlag === true;
    });
    const dataAssumption = yield call(
      apiCpi.getAssumptions,
      favAssumption.refAssumption
    );
    const datesPLG = yield call(apiPlg.getDates, dataCpi.data.refOperation);

    const allData = {
      refProgram: program.refProgram,
      dataActivitePrincipal: listNomenclature.data,
      dataLots: dataTLC.data,
      dataFavAssumption: dataFavAssumptionsCPI.data.content,
      dataNature: dataNatureProduit.data.level[0].value,
      dataSurface: dataAssumption.data,
      calendarData: datesPLG
    };
    const dataBuilded = yield call(externalInfoBuilder, allData);

    yield put(getProgramExternalInfoSuccess(dataBuilded));
  } catch (error) {
    yield put(getProgramExternalInfoFailure(error));
  }
  yield put(getProgramExternalInfoFinished());
}
export function* onFetchProgramExternalInfoStart() {
  yield takeLatest(
    programExternalInfoActionTypes.GET_PROGRAM_EXTERNAL_INFO_START,
    getProgramExternalInfo
  );
}

export function* ProgramExternalInfosSagas() {
  yield all([call(onFetchProgramExternalInfoStart)]);
}
