import SectionTypes from './section.types';

const INITIAL_STATE = {
  result: [],
  error: null,
  isLoading: false,
  responseSectionImage: null,
  isLoadingSectionImage: false
};

const SectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SectionTypes.FETCH_SECTION_START:
      return { ...state, isLoading: true };
    case SectionTypes.FETCH_SECTION_SUCCESS:
      return { ...state, result: action.payload, error: null };
    case SectionTypes.FETCH_SECTION_FAILURE:
      return { ...state, error: action.payload };
    case SectionTypes.FETCH_SECTION_FINISHED:
      return { ...state, isLoading: false };

    case SectionTypes.GET_SECTION_THUMBNAIL_LINK_START:
      return { ...state, isLoadingSectionImage: true };
    case SectionTypes.GET_SECTION_THUMBNAIL_LINK_SUCCESS:
      return { ...state, responseSectionImage: action.payload, error: null };
    case SectionTypes.GET_SECTION_THUMBNAIL_LINK_FAILURE:
      return { ...state, error: action.payload };
    case SectionTypes.GET_SECTION_THUMBNAIL_LINK_FINISHED:
      return { ...state, isLoadingSectionImage: false };
    default:
      return state;
  }
};

export default SectionReducer;
