const loginActionTypes = {
  FETCH_LOGIN_START: 'FETCH_LOGIN_START',
  FETCH_LOGIN_SUCCESS: 'FETCH_LOGIN_SUCCESS',
  FETCH_LOGIN_FAILURE: 'FETCH_LOGIN_FAILURE',
  FETCH_LOGIN_FINISHED: 'FETCH_LOGIN_FINISHED',
  FETCH_LOGIN_UPDATE_START: 'FETCH_LOGIN_UPDATE_START',
  FETCH_LOGIN_UPDATE_SUCCESS: 'FETCH_LOGIN_UPDATE_SUCCESS',
  FETCH_LOGIN_UPDATE_FAILURE: 'FETCH_LOGIN_UPDATE_FAILURE',
  FETCH_LOGIN_UPDATE_FINISHED: 'FETCH_LOGIN_UPDATE_FINISHED'
};

export default loginActionTypes;
