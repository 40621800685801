import mediaDetailsActionTypes from './media-details.types';

export const fetchMediaDetailsStart = refMedia => ({
  type: mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_START,
  payload: refMedia
});

export const fetchMediaDetailsSuccess = data => ({
  type: mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_SUCCESS,
  payload: data
});

export const fetchMediaDetailsFailure = error => ({
  type: mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_FAILURE,
  payload: error
});

export const fetchMediaDetailsFinished = () => ({
  type: mediaDetailsActionTypes.FETCH_MEDIA_DETAILS_FINISHED
});

export const fetchSupportStart = () => ({
  type: mediaDetailsActionTypes.FETCH_SUPPORT_START
});

export const fetchSupportSuccess = data => ({
  type: mediaDetailsActionTypes.FETCH_SUPPORT_SUCCESS,
  payload: data
});

export const fetchSupportFailure = error => ({
  type: mediaDetailsActionTypes.FETCH_SUPPORT_FAILURE,
  payload: error
});

export const fetchSupportFinished = () => ({
  type: mediaDetailsActionTypes.FETCH_SUPPORT_FINISH
});
