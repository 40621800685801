export const PATH = {
  listOfValue: 'ListOfValue',
  level: 'level',
  values: 'Values',
  activate: 'activate',
  deactivate: 'deactivate',
  formats2: 'FORMAT_MEDIA_2',
  formats: 'FORMAT_MEDIA',
  subformats: 'SOUS_FORMAT_MEDIA'
};

const FILTER = {
  media: 'FILTRE_MEDIA',
  qualite: 'QUALITE',
  activitePricipal: 'ACTIVITE_PRINCIPALE'
};

const REF_LISTS = {
  proyectTypes: 'PROJET_IE_MEDIA',
  destinationProperties: 'BIEN_IE_MEDIA',
  supportTypes: 'SUPPORT_MEDIA',
  natureProduit: 'NATURE_PRODUIT'
};

const LEVELS = {
  section: 1,
  category: 2,
  subcategory: 3
};

const getListOfValue = listCode => {
  return `${PATH.listOfValue}/${listCode}`;
};

const getLevelList = (listCode, levelCode) => {
  return `${getListOfValue(listCode)}/${PATH.level}/${levelCode}`;
};

const API_ROUTES_NOMENCLATURE = {
  listOfValue: getListOfValue(FILTER.media),
  listOfQualites: getListOfValue(FILTER.qualite),
  sectionValues: `${getLevelList(FILTER.media, LEVELS.section)}/${PATH.values}`,
  categoryValues: getListOfValue(FILTER.media),
  projectTypes: `${PATH.listOfValue}/${REF_LISTS.proyectTypes}`,
  destinationProperties: `${PATH.listOfValue}/${REF_LISTS.destinationProperties}`,
  supportValues: getListOfValue(REF_LISTS.supportTypes),
  valueActivitePrincipal: `${getLevelList(
    FILTER.activitePricipal,
    LEVELS.section
  )}/${PATH.values}`,
  natureProduit: getListOfValue(REF_LISTS.natureProduit)
};

export default API_ROUTES_NOMENCLATURE;
