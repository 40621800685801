import { createSelector } from 'reselect';

const selectS3Caller = state => state.s3Caller;

export const selectedS3CallerError = createSelector(
  [selectS3Caller],
  s3Caller => s3Caller.error
);

export const selectedS3CallerisLoading = createSelector(
  [selectS3Caller],
  s3Caller => s3Caller.isLoading
);

export const selectedS3CallerSucceed = createSelector(
  [selectS3Caller],
  s3Caller => s3Caller.succeed
);

export const selectedS3CallerResult = createSelector(
  [selectS3Caller],
  s3Caller => s3Caller
);
