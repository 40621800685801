/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useParams, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { IconButton } from '@material-ui/core';
import logoImage from '../../assets/images/logo.png';
import t from '../../constants/translates';
import styles from './style.jsx';
import './style.scss';
import { KIOSQUESI_URL } from '../../constants/global-constants';
import { ROUTES_CONSTANTS } from '../../constants/route.constants';
import { selectLogin } from '../../redux/login/loginSelectors';

const ErrorPage = ({ history, login }) => {
  const classes = makeStyles(styles)();
  const { errorNumber } = useParams();

  return (
    <div className="error-page">
      <div>
        <img
          crossOrigin="anonymous"
          src={logoImage}
          alt="logo"
          className="menu__logo-error"
          onClick={() => history.push(ROUTES_CONSTANTS.home)}
        />
      </div>
      <h1>{t.formatString(t.errorPage.titleError, { errorNumber })}</h1>
      <br />
      <p>
        {t.errorPage.error403Message}
        <a href={KIOSQUESI_URL}>{t.errorPage.error403Link}</a>
      </p>
      <IconButton
        onClick={() => login?.logOutToHome()}
        className={classes.logOut}
      >
        <PowerSettingsNewIcon style={styles.logOutIcon} />
        <span>{t.header.logout}</span>
      </IconButton>
    </div>
  );
};

ErrorPage.propTypes = {
  history: PropTypes.func,
  login: PropTypes.oneOfType([PropTypes.string])
};

const mapStateToProps = createStructuredSelector({
  login: selectLogin
});

export default withRouter(connect(mapStateToProps)(ErrorPage));
