export default {
  menuTab: {
    maxWidth: '700px',
    minHeight: '65px',
    minWidth: 'fit-content'
  },
  addSectionTab: {
    textAlign: 'center',
    color: '#C82832'
  },
  scrollButton: {
    color: '#000'
  }
};
