import { put, all, call, takeLatest } from 'redux-saga/effects';
import DownloadTypes from './download.types';
import api from '../../api/api.jsx';
import {
  downloadSuccess,
  downloadFailure,
  downloadFinished
} from './download.actions';
import { dowloadMultipleFiles } from '../../shared/functions/dowload-files.js';
import { downloadMedia } from '../../shared/functions/mediaFunctions';

function* addInstance(media) {
  const result = yield call(api.getInstanceURL, media);
  return result.data;
}

function* addOriginal(media) {
  const result = yield call(api.getMediaOriginal, {
    refMedia: media?.mediaDetails?.refMedia
  });
  return {
    ...result,
    isOriginal: true
  };
}

export function* download({ payload: medias }) {
  try {
    const promises = medias.map(media =>
      media.isOriginal ? addOriginal(media) : addInstance(media)
    );
    const data = yield all(promises);
    yield dowloadMultipleFiles(data?.filter(dat => !dat.isOriginal));
    yield data
      ?.filter(dat => dat.isOriginal)
      ?.map(d => downloadMedia(d, medias));
    yield put(downloadSuccess(data));
  } catch (error) {
    yield put(downloadFailure(error));
  }
  yield put(downloadFinished());
}

export function* onDownload() {
  yield takeLatest(DownloadTypes.DOWNLOAD_START, download);
}

export function* downloadSagas() {
  yield all([call(onDownload)]);
}
