export default {
  dowloadButton: {
    background: '#f5f6fa',
    borderRadius: '10px',
    width: '100%',
    marginBottom: '20px'
  },
  shareButton: {
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '100%'
  },
  callbackButton: {
    width: '90%',
    background: '#f5f6fa',
    borderRadius: '10px',
    marginLeft: '18px',
    marginBottom: '20px'
  }
};
