import DownloadTypes from './download.types';

const INITIAL_STATE = {
  messageDownloadSuccess: null,
  error: null,
  isLoading: false
};

const downloadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DownloadTypes.DOWNLOAD_START:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case DownloadTypes.DOWNLOAD_SUCCESS:
      return {
        ...state,
        messageDownloadSuccess: action.payload
      };
    case DownloadTypes.DOWNLOAD_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case DownloadTypes.DOWNLOAD_FINISHED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default downloadReducer;
