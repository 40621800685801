import { createSelector } from 'reselect';

const selectModificationPartners = state => state.modificationPartner;

export const selectedModificationPartners = createSelector(
  [selectModificationPartners],
  modification => modification.partnerUrl
);

export const modificationIsLoading = createSelector(
  [selectModificationPartners],
  modification => modification.isLoading
);

export const modificationError = createSelector(
  [selectModificationPartners],
  modification => modification.error
);

export const modificationSucceed = createSelector(
  [selectModificationPartners],
  modification => modification.succeed
);
