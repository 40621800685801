import createSectionTypes from './createSectionTypes';

const INITIAL_STATE = {
  results: null,
  error: null,
  isLoading: false,
  succeed: false
};

const createSectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case createSectionTypes.CREATE_SECTION_START:
      return { ...state, isLoading: true, succeed: false };

    case createSectionTypes.CREATE_SECTION_SUCCESS:
      return { ...state, results: action.payload, error: null, succeed: true };

    case createSectionTypes.CREATE_SECTION_FAILURE:
      return { ...state, error: action.payload, succeed: false };

    case createSectionTypes.CREATE_SECTION_FINISH:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default createSectionReducer;
