import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { Button, LinearProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Filter from '../../components/filter/Filter';
import {
  PROGRAM_TYPE,
  SEARCH_TYPE,
  EMPTY_STRING,
  initial,
  facets
} from '../../constants/global-constants';
import SearchList from '../../components/SearchList/SearchList';
import SearchListNoMedia from '../../components/SearchListNoMedia/SearchListNoMedia';
import ButtonComponent from '../../components/button/buttonComponent.jsx';
import Spinner from '../../components/spinner/Spinner.jsx';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import { selectProgramListCleoResults } from '../../redux/cleo/programListCleo/programListCleoSelectors';
import t from '../../constants/translates';
import styles from './style.jsx';
import './style.scss';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import {
  selectSearchIsLoading,
  selectSearchResults,
  selectSearchResultsWithMedia,
  selectSearchResultsWithoutMedia,
  selectSearchWithMediaIsLoading,
  selectSearchWithoutMediaIsLoading
} from '../../redux/search/search.selectors';
import {
  fetchSearchResultsStart,
  fetchSearchToolbar,
  fetchSearchResultsWithMediaStart,
  fetchSearchResultsWithoutMediaStart
} from '../../redux/search/search.actions';
import { canEdit, hasFilials } from '../../shared/rights/root-rights';
import { ATTRIBUTES } from '../../shared/rights/roles';
import { colorType } from '../../constants/ui.constants';
import facetsTranslates from '../../constants/facets-translates';

const ProgramList = ({
  fetchSearchResults,
  isLoading,
  currentResults,
  resultsWithMedia,
  resultsWithoutMedia,
  fetchSearchResultsWithMedia,
  fetchSearchResultsWithoutMedia,
  isLoadingWithMedia,
  isLoadingWithoutMedia,
  fetchToolbarVisible,
  sections,
  history
}) => {
  const historyParams = useParams();
  const [showMyPrograms, setShowMyPrograms] = useState(hasFilials());
  const [showMyProgramsNoMedia, setShowMyProgramsNoMedia] = useState(true);
  const [section, setSection] = useState();
  const [params, setParams] = useState({
    type: SEARCH_TYPE.program
  });
  const showMyProgramsNoMediaHandler = () => {
    setShowMyProgramsNoMedia(!showMyProgramsNoMedia);
  };

  const isIR = () => {
    return section?.type === PROGRAM_TYPE.IR_PROGRAM;
  };

  const getFilialFilter = () => {
    const result = isIR() && showMyPrograms;
    return result.toString().toUpperCase();
  };
  const [pages, setPages] = useState({
    current: 1,
    total: 1,
    loading: false
  });

  useEffect(() => {
    const paramsToolbar = { isVisible: true };
    fetchToolbarVisible(paramsToolbar);
    return () =>
      fetchToolbarVisible({
        isVisible: false,
        filterDisabled: false,
        text: EMPTY_STRING,
        refFilter: t.homeBannerContainer.refAll
      });
  }, []);

  useEffect(() => {
    setSection(
      sections?.find(sec => sec.refSection === historyParams.category)
    );

    if (historyParams.category) {
      fetchToolbarVisible({ text: EMPTY_STRING });
      setParams({
        type: SEARCH_TYPE.program,
        myPrograms: getFilialFilter()
      });
    }
  }, [sections, historyParams.category]);

  useEffect(() => {
    if (section) {
      const newParams = {
        ...params,
        text: historyParams.text,
        section: section?.refSection,
        myPrograms: getFilialFilter(),
        cursor: initial
      };

      setPages({ current: 1, total: 1 });
      setParams(newParams);
      fetchSearchResults(newParams);
      fetchSearchResultsWithMedia(newParams);
      fetchSearchResultsWithoutMedia(newParams);
      fetchToolbarVisible({
        refFilter: section?.refSection,
        filterDisabled: true,
        text: historyParams.text || ''
      });
    }
  }, [section, historyParams.text]);

  useEffect(() => {
    const pageInfo = {
      ...pages,
      total: resultsWithMedia?.page?.totalPages,
      loading: false
    };
    setPages(pageInfo);
  }, [resultsWithMedia]);

  const fetchMoreData = () => {
    let newParams = {};
    if (section) {
      newParams = {
        ...params,
        section: section?.refSection,
        cursor: resultsWithMedia?.page?.nextCursor
      };
    } else {
      newParams = {
        ...params,
        cursor: resultsWithMedia?.page?.nextCursor
      };
    }

    setPages({ ...pages, current: pages.current + 1, loading: true });
    setParams(newParams);
    fetchSearchResultsWithMedia(newParams);
  };

  const handleFilterChanged = filterList => {
    const filtersString = [];
    let filterType = SEARCH_TYPE.program;
    if (filterList.length > 0) {
      filterList.forEach((filter, index) => {
        filtersString[index] = `"${filter?.name}":"${filter?.value}"`;

        if (
          facetsTranslates[filter.name]?.name ===
            facetsTranslates.category.name ||
          facetsTranslates[filter.name]?.name ===
            facetsTranslates.subcategory.name
        ) {
          filterType = SEARCH_TYPE.media;
        }
      });
    }
    let newParams = {
      ...params,
      filters: filtersString,
      section: section?.refSection,
      type: filterType
    };

    if (historyParams.text) {
      newParams = {
        ...newParams,
        text: historyParams.text
      };
    }

    delete newParams.cursor;
    setPages({ current: 1, total: 1 });
    setParams(newParams);
    fetchSearchResults(newParams);
    fetchSearchResultsWithMedia(newParams);
    fetchSearchResultsWithoutMedia(newParams);
  };

  const handleFilialChange = event => {
    const { checked } = event.target;
    const show = !checked;
    setShowMyPrograms(show);
    setSection(
      sections?.find(sec => sec.refSection === historyParams.category)
    );

    const newParams = {
      ...params,
      myPrograms: show.toString().toUpperCase()
    };

    delete newParams.cursor;
    setPages({ current: 1, total: 1 });
    setParams(newParams);
    fetchSearchResults(newParams);
    fetchSearchResultsWithMedia(newParams);
    fetchSearchResultsWithoutMedia(newParams);
  };

  const style = makeStyles(styles)();

  const printShowMoreProgramsWithoutMedia = () => (
    <div className="icon-show-more">
      <ButtonComponent
        id="icon-show-more"
        onClick={showMyProgramsNoMediaHandler}
        className={style.buttonShowMore}
        classes={{ startIcon: style.startIcon }}
        startIcon={
          showMyProgramsNoMedia ? (
            <ExpandMoreIcon className={style.startIcon} />
          ) : (
            <ExpandLessIcon className={style.startIcon} />
          )
        }
      >
        {showMyProgramsNoMedia ? (
          <span>{t.programList.showMoreNoMediaPrograms}</span>
        ) : (
          <span>{t.programList.hideNoMediaPrograms}</span>
        )}
      </ButtonComponent>
    </div>
  );

  const printLoader = () => {
    return (
      <div className="progress__container">
        <LinearProgress className={style.progress} />
      </div>
    );
  };

  return (
    <>
      <Spinner isLoading={isLoading && pages.current === 1} />
      <DismissableAlert />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Filter
            filterTree={currentResults?.facets?.filter(element =>
              facets.includes(element?.name)
            )}
            onFilterChanged={handleFilterChanged}
            section={historyParams.category}
            showFilial={hasFilials()}
            onFilialChanged={e => handleFilialChange(e)}
            filialChecked={!showMyPrograms}
            history={history}
          />
        </Grid>
        <Grid item xs={9}>
          {isLoadingWithoutMedia ? (
            printLoader()
          ) : (
            <div>
              <div className="title__div">
                <span className="title">
                  {t.formatString(t.programListPage.titleProgramWithoutMedia, {
                    myPrograms: showMyPrograms
                      ? t.programListPage.mine
                      : t.programListPage.all
                  })}
                </span>
                <span className="title__results">
                  {`(${resultsWithoutMedia?.results?.withoutMedia?.length ||
                    0} ${
                    resultsWithoutMedia?.results?.withoutMedia?.length > 1
                      ? t.programListPage.results
                      : t.programListPage.result
                  })`}
                </span>
                {resultsWithoutMedia?.results?.withoutMedia?.length > 0 && (
                  <>
                    {resultsWithoutMedia?.results?.withoutMedia?.length > 2 &&
                    showMyProgramsNoMedia ? (
                      printShowMoreProgramsWithoutMedia()
                    ) : (
                      <>
                        {resultsWithoutMedia?.results?.withoutMedia?.length >
                          2 && printShowMoreProgramsWithoutMedia()}
                      </>
                    )}
                  </>
                )}
                {!isIR() && canEdit(true, ATTRIBUTES.BME_PROGRAM_WRITE) && (
                  <>
                    <Button
                      id="icon-add-program"
                      variant="contained"
                      color={colorType.secondary}
                      onClick={() =>
                        history.push(`${ROUTES_CONSTANTS.createProgramIE}`)
                      }
                      className={style.buttonCreateProgram}
                    >
                      {t.programListPage.creationIEProgram}
                    </Button>
                  </>
                )}
              </div>
              {resultsWithoutMedia?.results?.withoutMedia?.length > 0 && (
                <SearchListNoMedia
                  resultsWithoutMedia={
                    resultsWithoutMedia?.results?.withoutMedia
                  }
                  showMyProgramsNoMedia={showMyProgramsNoMedia}
                  section={section}
                />
              )}
            </div>
          )}
          <div>
            <div className="title__div">
              <span className="title">
                {t.formatString(t.programListPage.titleProgramWithMedia, {
                  myPrograms: showMyPrograms
                    ? t.programListPage.mine
                    : t.programListPage.all
                })}
              </span>
              <span className="title__results">
                {`(${resultsWithMedia?.page?.totalElements || 0} ${
                  resultsWithMedia?.page?.totalElements > 1
                    ? t.programListPage.results
                    : t.programListPage.result
                })`}
              </span>
            </div>
            {isLoadingWithMedia && printLoader()}
            <Grid container spacing={1}>
              <SearchList
                options={resultsWithMedia?.results?.withMedia}
                fetchMoreData={fetchMoreData}
                currentPage={pages?.current}
                totalPages={resultsWithMedia?.page?.totalPages}
                isLoading={isLoadingWithMedia || pages?.loading}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectSearchIsLoading,
  isLoadingWithMedia: selectSearchWithMediaIsLoading,
  isLoadingWithoutMedia: selectSearchWithoutMediaIsLoading,
  sections: selectSectionsResult,
  programListCleo: selectProgramListCleoResults,
  currentResults: selectSearchResults,
  resultsWithMedia: selectSearchResultsWithMedia,
  resultsWithoutMedia: selectSearchResultsWithoutMedia
});

const mapDispatchToProps = dispatch => ({
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params)),
  fetchToolbarVisible: params => dispatch(fetchSearchToolbar(params)),
  fetchSearchResultsWithMedia: params =>
    dispatch(fetchSearchResultsWithMediaStart(params)),
  fetchSearchResultsWithoutMedia: params =>
    dispatch(fetchSearchResultsWithoutMediaStart(params))
});

ProgramList.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingWithMedia: PropTypes.bool,
  isLoadingWithoutMedia: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  history: PropTypes.func,
  currentResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  fetchToolbarVisible: PropTypes.func,
  fetchSearchResults: PropTypes.func,
  fetchSearchResultsWithMedia: PropTypes.func,
  fetchSearchResultsWithoutMedia: PropTypes.func,
  resultsWithMedia: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  resultsWithoutMedia: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  )
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgramList)
);
