const CategoryAdminTypes = {
  UPDATE_CATEGORIES_START: 'UPDATE_CATEGORIES_START',
  UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
  UPDATE_CATEGORIES_FAILURE: 'UPDATE_CATEGORIES_FAILURE',
  UPDATE_CATEGORIES_FINISHED: 'UPDATE_CATEGORIES_FINISHED',
  DELETE_CATEGORY_START: 'DELETE_CATEGORY_START',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',
  DELETE_CATEGORY_FINISHED: 'DELETE_CATEGORY_FINISHED',
  CREATE_CATEGORY_START: 'CREATE_CATEGORY_START',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',
  CREATE_CATEGORY_FINISHED: 'CREATE_CATEGORY_FINISHED'
};

export default CategoryAdminTypes;
