import { createSelector } from 'reselect';

const societyQualities = state => state.societyQualities;

export const selectSocietyQualities = createSelector(
  [societyQualities],
  societyQualitiesActionTypes => societyQualitiesActionTypes.societyQualities
);

export const selectSocietyQualitiesIsLoading = createSelector(
  [societyQualities],
  societyQualitiesActionTypes => societyQualitiesActionTypes.isLoading
);

export const selectSocietyQualitiesError = createSelector(
  [societyQualities],
  societyQualitiesActionTypes => societyQualitiesActionTypes.error
);
