import { sectionItemBuilder } from '../section/sections/section.builder';
import { folderDetailsBuilder } from '../folder-details/folder-details.builder';
import {
  categoryBuilder,
  subcategoryBuilder,
  instanceListBuilder
} from '../../shared/builders/medias';
import { convertBytes } from '../../shared/functions/convertBytes';
import { tagListBuilder } from '../tag-list/tag.list.builder';

const visualListBuilder = (visualList, communes, payloadFiles) => {
  return visualList.result.map(item => {
    // For get url to the file in user local browser
    const localFileUrl = payloadFiles?.filter(
      media => media.file?.size === item?.size
    )?.[0]?.file?.fileUrl;
    return {
      category: categoryBuilder(item),
      subCategory: subcategoryBuilder(item),
      nameMedia: item?.name,
      format: item?.format,
      refMedia: item?.refMedia,
      mediaURL: item?.url,
      localFileUrl,
      title: item?.title,
      nameExt: item?.nameExt,
      resolution: `${item?.width} x ${item?.height}`,
      copyright: item?.copyright,
      size: convertBytes(item?.size),
      instances: instanceListBuilder(item?.files),
      dateUpdate: item?.updatedDate,
      dateUpload: item?.createdDate,
      typeMedia: item?.type,
      userUpdate: item?.updatedBy,
      userUpload: item?.createdBy,
      mediaContentType: item?.contentType,
      author: item?.author,
      folder: folderDetailsBuilder(item?.folder, communes),
      tags: tagListBuilder(item?.tags),
      original: item?.url,
      thumbnail: item?.thumbnail,
      name: item?.name,
      section: sectionItemBuilder(item?.folder?.program?.section),
      status: item?.status
    };
  });
};

export function visualFolderBuilder(data, communes, payloadFiles) {
  return visualListBuilder(data, communes, payloadFiles);
}
