import FileFolderUploaderTypes from './fileFolderUploaderTypes';

export const addFilesFolderStart = files => ({
  type: FileFolderUploaderTypes.ADD_FILES_FOLDER_START,
  payload: files
});

export const addFilesFolderSuccess = medias => ({
  type: FileFolderUploaderTypes.ADD_FILES_FOLDER_SUCCESS,
  payload: medias
});

export const addFilesFolderFailure = error => ({
  type: FileFolderUploaderTypes.ADD_FILES_FOLDER_FAILURE,
  payload: error
});

export const addFilesFolderFinished = () => ({
  type: FileFolderUploaderTypes.ADD_FILES_FOLDER_FINISHED
});
