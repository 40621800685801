export default {
  programModification: {
    color: '#C82832',
    marginTop: '0px',
    marginBottom: '15px',
    fontFmily: 'Neo Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '2px'
  },

  mediaOrganisation: {
    color: '#C82832',
    marginTop: '0px',
    marginBottom: '15px',
    fontFmily: 'Neo Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    padding: '2px',
    marginLeft: 'auto',
    marginRight: '35px'
  },
  formInput: {
    width: '272px',
    background: '#FFFFFF',
    boxShadow: '0px 8px 24px rgba(219, 226, 244, 0.5)',
    borderRadius: '10px',
    marginBottom: '10px'
  },
  addMediaButton: {
    marginLeft: 'auto'
  },
  saveButton: {
    background: '#C82832',
    borderRadius: '10px',
    width: '200px',
    height: '48px',
    marginRight: '10px',
    color: '#FFFFFF',
    margin: '0px 17px'
  },
  cancelButton: {
    background: '#4E5360',
    borderRadius: '10px',
    width: '117px',
    height: '48px',
    marginRight: '10px',
    color: '#FFFFFF'
  },
  imageCheckBox: {
    position: 'absolute',
    zIndex: 2
  },
  programExternalInfo: {}
};
