import MySelectionTypes from './mySelection.types';

export const addToMySelection = selectedItem => ({
  type: MySelectionTypes.ADD_TO_MY_SELECTION,
  payload: selectedItem
});

export const removeImageFromMySelection = id => ({
  type: MySelectionTypes.REMOVE_MY_SELECTION_ITEM,
  payload: id
});

export const clearMySelection = () => ({
  type: MySelectionTypes.CLEAR_MY_SELECTION
});
