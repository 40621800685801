export default {
  dropzone: {
    minHeight: '500px',
    marginBottom: '18px',
    backgroundColor: '#F0F0F0'
  },
  paragraph: {
    fontSize: 24,
    fontFamily: 'Avenir Next LT Pro',
    marginTop: 10,
    fontWeight: 'bold',
    marginBottom: 0,
    zIndex: 10,
    '> DropzoneArea-uploadIconSize-104': {
      display: 'none'
    }
  }
};
