export default {
  formatComboBox: {
    width: '42%',
    marginRight: '7px'
  },
  buttonDowload: {
    width: '280px',
    height: '48px',
    marginTop: '20px',
    color: '#F5F6FA',
    backgroundColor: '#C82832',
    '&:hover': {
      backgroundColor: '#C82832',
      borderColor: '#C82832',
      color: '#F5F6FA'
    },
    textTransform: 'none'
  },

  addButton: {
    width: '55%',
    height: '48px',
    textTransform: 'none',
    fontFamily: 'Avenir Next LT Pro'
  },
  sharebutton: {
    width: '35%',
    height: '48px',
    color: '#4E5360',
    backgroundColor: '#F5F6FA',
    textTransform: 'none',
    float: 'right'
  },
  playIcon: {
    height: '200px',
    width: '200px',
    fill: 'white'
  },

  copyIcon: {
    marginLeft: 'auto',
    marginRight: '5px',
    marginTop: '5px',
    cursor: 'pointer'
  },
  shareIcon: {
    fontSize: '28px',
    marginLeft: '5px'
  },
  iconClose: {
    fontSize: '40px',
    color: '#4E5360'
  },
  iconBot: {
    background: 'rgba(245, 246, 250, 0.5)',
    borderRadius: '5px',
    height: '30px',
    paddingTop: '6px',
    marginRight: '6px'
  }
};
