import React from 'react';
import text from '../../constants/translates';
import './style.scss';

const copyright = varCopyright => (
  <p className="title__program-copyright">
    {`${text.visualProgram.copyright} ${text.space} ${varCopyright}`}
  </p>
);

export default copyright;
