export const supportBuilder = data => {
  return data?.level[0]?.value?.map(item => {
    return {
      level: 1,
      listCode: data?.listCode,
      name: item?.longLabel,
      shortName: item?.shortLabel,
      refSupport: item?.valueCode
    };
  });
};
