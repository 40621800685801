import PublishUnpublishMedias from './publishAndUnpublishMedias.types.js';

export const publishCheckedMediaStart = media => ({
  type: PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_START,
  payload: media
});

export const publishCheckedMediaSuccess = data => ({
  type: PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_SUCCESS,
  payload: data
});

export const publishCheckedMediaFailure = error => ({
  type: PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_FAILURE,
  payload: error
});

export const publishCheckedMediaFinished = () => ({
  type: PublishUnpublishMedias.PUBLISH_CHECKED_MEDIA_FINISHED
});

export const unpublishCheckedMediaStart = media => ({
  type: PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_START,
  payload: media
});

export const unpublishCheckedMediaSuccess = data => ({
  type: PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_SUCCESS,
  payload: data
});

export const unpublishCheckedMediaFailure = error => ({
  type: PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_FAILURE,
  payload: error
});

export const unpublishCheckedMediaFinished = () => ({
  type: PublishUnpublishMedias.UNPUBLISH_CHECKED_MEDIA_FINISHED
});
