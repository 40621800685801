export default {
  editImageButton: {
    fonFamily: 'Avenir Next LT Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    textDecorationLine: 'underline',
    color: '#C82832',
    marginTop: 0
  },
  deleteSectionButton: {
    fonFamily: 'Avenir Next LT Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#C82832',
    marginTop: '24px'
  },
  saveButton: {
    padding: '6px 88px',
    background: '#C82832',
    borderRadius: '10px',
    color: '#F5F6FA',
    marginLeft: 'auto',
    width: '100%',
    '&:disabled': {
      border: '1px solid #555555',
      color: '#BF7703',
      background: '#C82832',
      opacity: 0.5
    }
  }
};
