import ShareActionTypes from './share.types';

export const shareMediaStart = params => ({
  type: ShareActionTypes.SHARE_MEDIA_START,
  payload: params
});

export const shareMediaSuccess = data => ({
  type: ShareActionTypes.SHARE_MEDIA_SUCCESS,
  payload: data
});

export const shareMediaFailure = error => ({
  type: ShareActionTypes.SHARE_MEDIA_FAILURE,
  payload: error
});

export const shareMediaFinished = () => ({
  type: ShareActionTypes.SHARE_MEDIA_FINISHED
});

export const fetchInternalMembersStart = params => ({
  type: ShareActionTypes.FETCH_INTERNAL_MEMBERS_START,
  payload: params
});

export const fetchInternalMembersSuccess = params => ({
  type: ShareActionTypes.FETCH_INTERNAL_MEMBERS_SUCCESS,
  payload: params
});

export const fetchInternalMembersFailure = params => ({
  type: ShareActionTypes.FETCH_INTERNAL_MEMBERS_FAILURE,
  payload: params
});

export const fetchInternalMembersFinished = () => ({
  type: ShareActionTypes.FETCH_INTERNAL_MEMBERS_FINISHED
});

export const fetchExternalMembersStart = params => ({
  type: ShareActionTypes.FETCH_EXTERNAL_MEMBERS_START,
  payload: params
});

export const fetchExternalMembersSuccess = params => ({
  type: ShareActionTypes.FETCH_EXTERNAL_MEMBERS_SUCCESS,
  payload: params
});

export const fetchExternalMembersFailure = params => ({
  type: ShareActionTypes.FETCH_EXTERNAL_MEMBERS_FAILURE,
  payload: params
});

export const fetchExternalMembersFinished = () => ({
  type: ShareActionTypes.FETCH_EXTERNAL_MEMBERS_FINISHED
});

export const fetchAppMemberStart = params => ({
  type: ShareActionTypes.FETCH_APP_MEMBER_START,
  payload: params
});

export const fetchAppMemberSuccess = params => ({
  type: ShareActionTypes.FETCH_APP_MEMBER_SUCCESS,
  payload: params
});

export const fetchAppMemberFailure = params => ({
  type: ShareActionTypes.FETCH_APP_MEMBER_FAILURE,
  payload: params
});

export const fetchAppMemberFinished = () => ({
  type: ShareActionTypes.FETCH_APP_MEMBER_FINISHED
});
