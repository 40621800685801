import { createSelector } from 'reselect';

const selectCopyProgramList = state => state.copyProgramList;

/**
 * Selector of copy program list results
 */
export const selectCopyProgramListResults = createSelector(
  [selectCopyProgramList],
  copyProgramList => copyProgramList?.results
);

/**
 * Selector of copy program list isLoading
 */
export const selectCopyProgramListIsLoading = createSelector(
  [selectCopyProgramList],
  copyProgramList => copyProgramList?.isLoading
);

/**
 * Selector of copy program list error
 */
export const selectCopyError = createSelector(
  [selectCopyProgramList],
  copyProgramList => copyProgramList?.error
);
