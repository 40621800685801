import { createMuiTheme } from '@material-ui/core';

const backgroundColor = 'rgb(178, 178, 178)';
const theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true
    }
  },

  typography: {
    fontFamily: 'Avenir Next LT Pro',
    fontSize: 12
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: 'Avenir Next LT Pro',
        fontWeight: 500,
        fontSize: 18
      }
    },

    MuiButton: {
      root: {
        fontFamily: 'Neo Sans Pro',
        fontWeight: 500,
        fontSize: 18,
        height: 48,
        color: '#4E5360',
        borderRadius: '10px',
        '&:hover': {
          backgroundColor,
          color: '#C82832',
          borderColor: '#C82832'
        }
      },
      outlinedPrimary: {
        color: '#C82832',
        borderColor: '#C82832',
        borderStyle: 'solid',
        borderWidth: 'thin',
        '&:hover': {
          backgroundColor,
          borderColor: backgroundColor,
          color: '#F5F6FA'
        }
      },
      containedPrimary: {
        backgroundColor: '#C82832',
        color: '#F5F6FA',
        '&:hover': {
          backgroundColor,
          borderColor: backgroundColor,
          color: '#F5F6FA'
        }
      },
      containedSecondary: {
        color: '#F5F6FA',
        backgroundColor: '#4E5360',
        '&:hover': {
          backgroundColor,
          borderColor: backgroundColor,
          color: '#F5F6FA'
        }
      }
    },
    MuiIconButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiTabScrollButton: {
      root: {
        color: '#000'
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff'
      }
    },
    MuiCheckbox: {
      root: {
        padding: '0 5px'
      }
    },
    MuiTab: {
      root: {
        fontSize: 18,
        height: 'auto',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#F5F6FA'
        },
        maxWidth: 'none'
      },
      textColorPrimary: {
        color: '#202227',
        '&$selected': {
          color: '#202227',
          backgroundColor: '#F5F6FA'
        }
      }
    },
    MuiSelect: {
      icon: {
        '&$disabled': {
          display: 'none'
        }
      }
    },
    MUIRichTextEditor: {
      editor: {
        lineHeight: 1.5
      }
    }
  },

  status: {
    danger: 'orange'
  }
});

export default theme;
