import UrlInstanceTypes from './urlInstances.types';

const INITIAL_STATE = {
  instance: null,
  error: null,
  isLoading: false
};

const urlInstanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UrlInstanceTypes.FETCH_URL_INSTANCE_START:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case UrlInstanceTypes.FETCH_URL_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: action.payload
      };
    case UrlInstanceTypes.FETCH_URL_INSTANCE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case UrlInstanceTypes.FETCH_URL_INSTANCE_FINISHED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default urlInstanceReducer;
