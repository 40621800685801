/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { PDFReader } from 'reactjs-pdf-view';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Checkbox, Typography } from '@material-ui/core';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { v4 as uuidv4 } from 'uuid';
import Chip from '@material-ui/core/Chip';
import t from '../../constants/translates';
import { ROUTES_CONSTANTS } from '../../constants/route.constants';
import styles from './style.jsx';
import './style.scss';
import VisualVideoProgram from '../visual-video-program/VisualVideoProgram';
import { copyCodeToClipboard } from '../../shared/fixtures/functions/keyboard';
import VisualImageProgram from '../visual-image-program/VisualImageProgram';
import { galleryDisplay } from '../../shared/functions/galleryDisplay';
import AlertDialogCreateFolder from '../dialogWithValue/dialogWithValue';
import {
  acceptedFormats,
  itemPayload,
  itemTypes,
  image,
  MEDIA_TYPE,
  MEDIA_STATUS,
  modeSelection,
  totalImagesForCheck,
  totalVideosForCheck,
  snackbarDuration,
  pdfFormat,
  MEDIA_STATUS_ERROR
} from '../../constants/global-constants';
import { snackBarSeverity } from '../../constants/ui.constants';
import { getThumbnail } from '../../shared/functions/mediaFunctions';
import { sendCreateFolderDataStart } from '../../redux/createFolder/createFolderActions';
import {
  selectCreateFolderIsLoading,
  selectCreateFolderSucceed,
  selectCreateFolderMessage
} from '../../redux/createFolder/createFolderSelectors';
import Spinner from '../spinner/Spinner';
import {
  publishCheckedMediaStart,
  unpublishCheckedMediaStart
} from '../../redux/publishAndUnpublishMedias/publishAndUnpublishMedias.actions';
import { selectedIsLoadingPublishUnpublish } from '../../redux/publishAndUnpublishMedias/publishAndUnpublishMedias.selectors';
import { canEditArray } from '../../shared/rights/root-rights';
import { ATTRIBUTES } from '../../shared/rights/roles';
import { getPathOrganizationalEntitybyRef } from '../../shared/functions/cleoFunctions';
import { selectedMode } from '../../redux/modeController/modeController.selectors';
import { selectMediaDetails } from '../../redux/media-details/media-details.selectors';
import {
  addToMySelection,
  removeImageFromMySelection
} from '../../redux/mySelection/mySelection.actions';
import { selectMyCurrentSelection } from '../../redux/mySelection/mySelection.selectors';
import SnackbarAlert from '../snackBar/SnackbarAlert';
import { selectedS3CallerResult } from '../../redux/s3-caller/s3-caller.selectors';
import { useWindowDimensions } from '../../shared/functions/useWindowDimensions';
import warningLogo from '../../assets/images/warningLogo.png';
import { checkAvenir } from '../../shared/functions/checkAvenir.js';
import { selectedCallback } from '../../redux/modeController/modeController.selectors.js';
import { selectSearchResults } from '../../redux/search/search.selectors.js';
import { getContext } from 'redux-saga/effects';
import { checkVitrine } from '../../shared/functions/vitrine';

/**
 * Component for show a gallery of medias, can be image or videos
 */
const MediaGallery = ({
  mediaList,
  folderList,
  refProgram,
  sendCreateFolder,
  createFolderMessage,
  createFolderIsLoading,
  createFolderSucceed,
  history,
  isFolderList,
  isIR,
  publishCheckedMedia,
  unpublishCheckedMedia,
  isLoadingPublishUnpublish,
  selectedModeProp,
  addToMySelectionProp,
  removeImageFromMySelectionProp,
  mySelection,
  mediaDetails,
  s3CallerResult,
  programDetails,
  callback,
  currentProgram,
  searchProgramResults,
  messageSelection
}) => {
  const classes = makeStyles(styles)();
  const [program, setProgram] = useState();
  const [visualProgramVisibility, setVisualProgramVisibility] = useState(false);
  const [isModeSelection, setIsModeSelection] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [mediaVideo, setMediaVideo] = useState(false);
  const [mediaImage, setMediaImage] = useState(false);
  const [mediaDocument, setMediaDocument] = useState(false);
  const [isMediaImage, setIsMediaImage] = useState(false);
  const [isMediaDocument, setIsMediaDocument] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalImagesCheckeds, setTotalImagesCheckeds] = useState(0);
  const [totalVideosCheckeds, setTotalVideosCheckeds] = useState(0);
  const [toastMessage, setToastMessage] = useState(false);
  const [toastseverity, setToastseverity] = useState(false);
  const [addedToMySelection, setAddedToMySelection] = useState(false);
  const [medias, setMedias] = useState([]);
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const bigScreenResolution = useWindowDimensions();

  let mediasArray = [];
  /**
   * Function to open the modal for create 1 reportage
   * @param {string} value name of reportage
   */
  const openCreateOpen = value => {
    if (value) {
      const formData = {
        nameFolder: { name: value },
        refProgram
      };
      sendCreateFolder(formData);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (selectedModeProp?.mode === modeSelection) {
      setIsModeSelection(true);
    } else {
      setIsModeSelection(false);
    }
  }, [selectedModeProp]);

  useEffect(() => {
    setProgram(searchProgramResults);
  }, [searchProgramResults]);
  // Adittion of media to the selection and to the medias checkeds
  const addMediaFromCallback = media => {
    addToMySelectionProp(getMediaInstance(media));
    let isImage = media.typeMedia?.refValue === MEDIA_TYPE.image;
    mediasArray.push({ refMedia: media?.refMedia, checked: true, isImage });
  };

  // UseEffect for add each media who arrive from callback to mode selection

  useEffect(() => {
    if (selectedModeProp?.mode === modeSelection) {
      let mediaDetailsArray = [];
      if (typeof mediaDetails === 'object') {
        mediaDetailsArray.push(mediaDetails);
        if (mediaDetailsArray[0].length >= 1) {
          mediaDetailsArray?.map(media => {
            addMediaFromCallback(media);
          });
        }
      } else {
        if (mediaDetails) {
          mediaDetails?.map(media => addMediaFromCallback(media));
        }
      }
      setMedias(medias.concat(mediasArray));
    }
  }, [mediaDetails]);

  const setItemsInArray = newArray => {
    return mediaList?.map(item => {
      let isChecked = false;
      if (selectedModeProp?.mode !== modeSelection) {
        /* Only for the case of publish medias */
        if (
          item.status === MEDIA_STATUS.published ||
          item.status === MEDIA_STATUS.toPublish
        ) {
          isChecked = true;
        }
      }

      const isImage = item.typeMedia?.refValue === MEDIA_TYPE.image;

      newArray.push({ refMedia: item?.refMedia, checked: isChecked, isImage });
    });
  };

  useEffect(() => {
    const newArray = [];
    setItemsInArray(newArray);
    const CheckedImages = newArray.filter(
      item => item.checked === true && item.isImage === true
    );
    setTotalImagesCheckeds(CheckedImages.length);
    const CheckedVideos = newArray.filter(
      item => item.checked === true && item.isImage === false
    );
    setTotalVideosCheckeds(CheckedVideos.length);
    setMedias(medias.concat(newArray));
  }, [mediaList]);

  useEffect(() => {
    setVisualProgramVisibility(false);
  }, [refProgram, mediaList]);

  const closeCreateFolder = () => {
    setOpen(false);
  };

  const updateVisualProgramVisibility = () => {
    setVisualProgramVisibility(!visualProgramVisibility);
  };

  const order = index => (
    <Chip size="small" label={index} className={classes.icon} />
  );

  /**
   * Function for open the media selected
   * @param {object} media Media to display
   * @param {bool} visibility Visibility of the media
   * @param {int} index Index of the media selected
   */
  const showMedia = (media, visibility, index) => {
    if (media.typeMedia?.refValue === MEDIA_TYPE.image) {
      setIsMediaImage(true);
      setMediaImage(media);
      setIsMediaDocument(false);
    } else if (media.typeMedia?.refValue === MEDIA_TYPE.video) {
      setIsMediaImage(false);
      setMediaVideo(media);
      setIsMediaDocument(false);
    } else if (media.typeMedia?.refValue === MEDIA_TYPE.document) {
      setIsMediaImage(false);
      setMediaDocument(media);
      setIsMediaDocument(true);
    }
    setSelectedIndex(index);
    setVisualProgramVisibility(visibility);
  };

  useEffect(() => {
    if (createFolderSucceed) {
      history.push(
        `${ROUTES_CONSTANTS.mediaSummaryBase}${refProgram}${ROUTES_CONSTANTS.filesFolderUploaderBase}${createFolderMessage.refFolder}`
      );
    }
  }, [createFolderSucceed, createFolderMessage]);

  const limitPublishedImagesVideosHandler = typeMedia => {
    let canCheckMedia = false;

    if (typeMedia === MEDIA_TYPE.video) {
      canCheckMedia = totalVideosCheckeds < totalVideosForCheck;
    }
    if (typeMedia === MEDIA_TYPE.image) {
      canCheckMedia = totalImagesCheckeds < totalImagesForCheck;
    }

    return canCheckMedia;
  };

  const getMediaInstance = media => {
    let instance = null;
    if (media?.instances) {
      instance = media?.instances[0];
    }

    return {
      mediaDetails: media,
      isOriginal: true,
      instance: instance
    };
  };

  const setNewSelected = media => {
    const findMediaCheck = medias?.find(
      item => media?.refMedia === item?.refMedia
    );

    if (
      !findMediaCheck?.checked &&
      checkAvenir(program?.results?.withMedia[0]?.match) &&
      callback !== null
    ) {
      messageSelection();
    }
    if (findMediaCheck?.checked) {
      findMediaCheck.checked = !findMediaCheck?.checked;
      removeImageFromMySelectionProp(media?.refMedia);
      return media?.typeMedia?.refValue === MEDIA_TYPE.video
        ? setTotalVideosCheckeds(totalVideosCheckeds - 1)
        : setTotalImagesCheckeds(totalImagesCheckeds - 1);
    }
    if (
      !findMediaCheck?.checked &&
      limitPublishedImagesVideosHandler(media?.typeMedia?.refValue)
    ) {
      findMediaCheck.checked = !findMediaCheck?.checked;
      setToastMessage(t.visualProgram.addedMediaToMySelection);
      setToastseverity(snackBarSeverity.success);
      addToMySelectionProp(getMediaInstance(media));
      return media?.typeMedia?.refValue === MEDIA_TYPE.video
        ? setTotalVideosCheckeds(totalVideosCheckeds + 1)
        : setTotalImagesCheckeds(totalImagesCheckeds + 1);
    } else if (!limitPublishedImagesVideosHandler(media?.typeMedia?.refValue)) {
      setToastMessage(t.visualProgram.errorAddMediaToMySelectionMode);
      setToastseverity(snackBarSeverity.error);
    }
    setAddedToMySelection(true);
    return null;
  };

  const setNewCheck = media => {
    const findMediaCheck = medias?.find(
      item => media?.refMedia === item?.refMedia
    );
    if (
      !findMediaCheck?.checked &&
      checkAvenir(program?.results?.withMedia[0]?.match) &&
      callback !== null
    ) {
      messageSelection();
    }
    if (findMediaCheck?.checked) {
      findMediaCheck.checked = !findMediaCheck?.checked;
      unpublishCheckedMedia(media?.refMedia);
      return media?.typeMedia?.refValue === MEDIA_TYPE.video
        ? setTotalVideosCheckeds(totalVideosCheckeds - 1)
        : setTotalImagesCheckeds(totalImagesCheckeds - 1);
    }
    if (
      !findMediaCheck?.checked &&
      limitPublishedImagesVideosHandler(media?.typeMedia?.refValue)
    ) {
      findMediaCheck.checked = !findMediaCheck?.checked;
      publishCheckedMedia(media?.refMedia);
      return media?.typeMedia?.refValue === MEDIA_TYPE.video
        ? setTotalVideosCheckeds(totalVideosCheckeds + 1)
        : setTotalImagesCheckeds(totalImagesCheckeds + 1);
    }

    return null;
  };

  const isCheckedHandler = media => {
    const findMedia = medias?.find(item => media?.refMedia === item?.refMedia);
    return findMedia?.checked;
  };

  const isDisabledHandler = media => {
    let isDisabled;
    if (isModeSelection) {
      // For the case of mode selection
      return false;
    } else {
      // For the case of publish/unpublish medias
      const isCurrentMediaChecked = medias.find(
        item => media?.refMedia === item?.refMedia
      )?.checked;

      if (
        media?.typeMedia === MEDIA_TYPE.video &&
        totalVideosCheckeds === totalVideosForCheck &&
        !isCurrentMediaChecked
      ) {
        isDisabled = true;
      } else if (
        media?.typeMedia === MEDIA_TYPE.image &&
        totalImagesCheckeds === totalImagesForCheck &&
        !isCurrentMediaChecked
      ) {
        isDisabled = true;
      } else {
        isDisabled = false;
      }
      return isDisabled;
    }
  };

  const getImageTile = (tile, index) => {
    let mediaThumbnail = getThumbnail(tile);

    if (tile?.refMedia === s3CallerResult?.refMedia) {
      mediaThumbnail = s3CallerResult.s3LocalFileUrl;
    }
    if (tile.typeMedia?.refValue === MEDIA_TYPE.document) {
      if (
        tile.status == MEDIA_STATUS_ERROR.errorCodec ||
        tile.status == MEDIA_STATUS_ERROR.errorGed ||
        MEDIA_STATUS_ERROR.errorResize == tile.status
      ) {
        return (
          <img
            crossOrigin="anonymous"
            onClick={() => showMedia(tile, true, index)}
            src={warningLogo}
            alt={tile?.name}
            className="tile__media"
            onKeyDown={() => showMedia(tile, true, index)}
            role="none"
          />
        );
      } else {
        return (
          <div
            className="tile__folder"
            onClick={() => {
              showMedia(tile, true, index);
            }}
          >
            <>
              <PDFReader url={tile?.mediaURL} scale={0.33} />
              <p className={classes.title_name}>{tile?.title}</p>
            </>
          </div>
        );
      }
    } else {
      return (
        <img
          crossOrigin="anonymous"
          onClick={() => showMedia(tile, true, index)}
          src={mediaThumbnail}
          alt={tile?.name}
          className="tile__media"
          onKeyDown={() => showMedia(tile, true, index)}
          role="none"
          onError={e => (e.target.src = warningLogo)}
        />
      );
    }
  };

  const getText = tile => {
    if (
      isDisabledHandler(tile) ||
      isLoadingPublishUnpublish ||
      (checkAvenir(program?.results?.withMedia[0]?.match) && callback === null)
    ) {
      return (
        <Typography fontSize={30}>
          {isDisabledHandler(tile) ||
          isLoadingPublishUnpublish ||
          (checkAvenir(program?.results?.withMedia[0]?.match) &&
            callback === null)
            ? t.mediaGallery.textCheckbox1
            : null}
        </Typography>
      );
    }
  };

  const getCheckBox = (tile, index) => {
    return (
      <Checkbox
        id="checkbox-video"
        icon={
          <CheckBoxOutlineBlankOutlinedIcon
            fontSize="large"
            style={
              isDisabledHandler(tile) ||
              isLoadingPublishUnpublish ||
              (checkAvenir(program?.results?.withMedia[0]?.match) &&
                callback === null)
                ? styles.iconStyleDisabled
                : styles.iconStyle
            }
          />
        }
        checkedIcon={
          <CheckBoxOutlinedIcon
            fontSize="large"
            style={
              isDisabledHandler(tile) ||
              isLoadingPublishUnpublish ||
              (checkAvenir(program?.results?.withMedia[0]?.match) &&
                callback === null)
                ? styles.iconStyleDisabled
                : styles.iconStyle
            }
          />
        }
        disabled={
          isDisabledHandler(tile) ||
          isLoadingPublishUnpublish ||
          (checkAvenir(program?.results?.withMedia[0]?.match) &&
            callback === null)
        }
        checked={isCheckedHandler(tile)}
        onChange={() => {
          isModeSelection ? setNewSelected(tile) : setNewCheck(tile);
        }}
        name={index}
        color="secondary"
      />
    );
  };

  const toolTipMedia = (tile, index) => {
    return (
      <>
        {isIR && tile?.format !== pdfFormat ? (
          <>
            {(canEditArray(
              getPathOrganizationalEntitybyRef(refProgram),
              ATTRIBUTES.BME_PUBLISH_WRITE
            ) ||
              isModeSelection) &&
              !checkVitrine(history) && (
                <>
                  {checkAvenir(program?.results?.withMedia[0]?.match) &&
                  callback === null ? (
                    <>
                      <Tooltip
                        title={getText()}
                        arrow
                        className={styles.tooltip}
                      >
                        <span className="right">
                          {getCheckBox(tile, index)}
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    <>{getCheckBox(tile, index)}</>
                  )}
                </>
              )}
            {getImageTile(tile, index)}
          </>
        ) : (
          getImageTile(tile, index)
        )}
      </>
    );
  };

  /**
   * Display the tile of the media
   * @param {object} tile Media to display
   * @param {int} index Index of the media to display
   */
  const gridListImage = (tile, index) => {
    return (
      <GridListTile key={uuidv4()} className={classes.gridItem}>
        {tile.typeMedia?.refValue?.toLowerCase() === acceptedFormats.video && ( // Case for video media
          <>
            <PlayCircleOutlineOutlinedIcon
              onClick={() => showMedia(tile, true, index)}
              className={classes.playIconGallery}
              id="playVideo"
            />
          </>
        )}
        {toolTipMedia(tile, index)}
        <GridListTileBar
          className={classes.gridBar}
          titlePosition="top"
          actionIcon={!isFolderList && order(index + 1)}
          actionPosition="left"
        />
      </GridListTile>
    );
  };

  /**
   * Display the detail of the media selected
   */
  const gridListVisualProgram = () => {
    if (isMediaImage) {
      return (
        <GridListTile cols={3} rows={3}>
          <VisualImageProgram
            image={mediaImage}
            updateVisualProgramVisibility={updateVisualProgramVisibility}
            vitrine={checkVitrine(history)}
          />
        </GridListTile>
      );
    } else if (isMediaDocument) {
      return (
        <GridListTile cols={3} rows={3}>
          <VisualImageProgram
            image={mediaDocument}
            updateVisualProgramVisibility={updateVisualProgramVisibility}
            isDocument
            vitrine={checkVitrine(history)}
          />
        </GridListTile>
      );
    }
    return (
      <GridListTile cols={3} rows={3}>
        <VisualVideoProgram
          video={mediaVideo}
          updateVisualProgramVisibility={updateVisualProgramVisibility}
          copyCodeToClipboard={copyCodeToClipboard}
          vitrine={checkVitrine(history)}
        />
      </GridListTile>
    );
  };

  const routeFolderPage = refFolder => {
    history.push(
      `${ROUTES_CONSTANTS.mediaSummaryBase}${refProgram}${ROUTES_CONSTANTS.foldersLink}${refFolder}`
    );
  };

  /**
   * Display the tile of each reportage
   */
  const gridListFolder = tile => {
    return (
      <GridListTile
        key={uuidv4()}
        onClick={() => routeFolderPage(tile.refFolder)}
      >
        <div className="tile__folder">
          <PermMediaOutlinedIcon className={classes.folderIcon} />
          <GridListTileBar className={classes.gridBar} titlePosition="top" />
          <p className="tile__text">{tile.name}</p>
        </div>
      </GridListTile>
    );
  };

  /**
   * Display the tile for create 1 new reportage
   */
  const gridListNewFolder = () => (
    <GridListTile key={uuidv4()} onClick={() => setOpen(true)}>
      <div className="tile__folder">
        <CreateNewFolderOutlinedIcon className={classes.folderIcon} />
        <GridListTileBar className={classes.gridBar} titlePosition="top" />
        <p className="tile__text">{t.mediaGallery.programModification}</p>
      </div>
    </GridListTile>
  );

  const displayGridItems = galleryDisplay(
    mediaList,
    folderList,
    selectedIndex
  )?.map(item => {
    if (item.type === itemTypes.media)
      return gridListImage(item.payload, item.index);
    if (item.type === itemTypes.folder && item.payload !== itemPayload) {
      return gridListFolder(item.payload);
    }
    if (item.type === itemTypes.selectedMedia && visualProgramVisibility)
      return gridListVisualProgram();

    return null;
  });

  return (
    <>
      <Spinner className="spinner" isLoading={createFolderIsLoading} />
      <SnackbarAlert
        id="AddedAlert"
        severity={toastseverity}
        open={addedToMySelection}
        message={toastMessage}
        handleClose={() => setAddedToMySelection(false)}
        duration={snackbarDuration}
      />
      <div className="tile__root" key={uuidv4()}>
        <GridList
          cellHeight={280}
          cols={bigScreenResolution ? 3 : 2}
          spacing={30}
          className={classes.gridList}
        >
          {displayGridItems}
          {refProgram &&
            canEditArray(
              getPathOrganizationalEntitybyRef(refProgram),
              ATTRIBUTES.BME_MEDIA_WRITE
            ) &&
            !checkVitrine(history) &&
            gridListNewFolder()}
        </GridList>
      </div>
      <AlertDialogCreateFolder
        open={open}
        title={t.mediaGallery.programModification}
        textButtonOk={t.mediaGallery.create}
        textButtonCancel={t.visualProgram.textButtonCancel}
        content={t.mediaGallery.nameFolder}
        handleAction={openCreateOpen}
        handleClose={closeCreateFolder}
      />
    </>
  );
};

MediaGallery.propTypes = {
  history: PropTypes.func,
  mediaList: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    order: PropTypes.string
  }),
  folderList: PropTypes.shape({
    refFolder: PropTypes.string,
    name: PropTypes.string
  }),
  refProgram: PropTypes.string,
  sendCreateFolder: PropTypes.func,
  createFolderMessage: PropTypes.string,
  createFolderIsLoading: PropTypes.bool,
  createFolderSucceed: PropTypes.func,
  isFolderList: PropTypes.bool,
  isIR: PropTypes.func,
  publishCheckedMedia: PropTypes.func,
  unpublishCheckedMedia: PropTypes.func,
  isLoadingPublishUnpublish: PropTypes.bool,
  selectedModeProp: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  addToMySelectionProp: PropTypes.func,
  mySelection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  removeImageFromMySelectionProp: PropTypes.func,
  mediaDetails: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  s3CallerResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  programDetails: PropTypes.oneOfType([PropTypes.string]),
  callback: PropTypes.string,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  messageSelection: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  createFolderMessage: selectCreateFolderMessage,
  createFolderIsLoading: selectCreateFolderIsLoading,
  createFolderSucceed: selectCreateFolderSucceed,
  isLoadingPublishUnpublish: selectedIsLoadingPublishUnpublish,
  selectedModeProp: selectedMode,
  mySelection: selectMyCurrentSelection,
  mediaDetails: selectMediaDetails,
  s3CallerResult: selectedS3CallerResult,
  callback: selectedCallback,
  searchProgramResults: selectSearchResults
});

const mapDispatchToProps = dispatch => ({
  sendCreateFolder: formData => dispatch(sendCreateFolderDataStart(formData)),
  publishCheckedMedia: refMedia => dispatch(publishCheckedMediaStart(refMedia)),
  unpublishCheckedMedia: refMedia =>
    dispatch(unpublishCheckedMediaStart(refMedia)),
  addToMySelectionProp: selectedItem =>
    dispatch(addToMySelection(selectedItem)),
  removeImageFromMySelectionProp: selectedItem =>
    dispatch(removeImageFromMySelection(selectedItem))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MediaGallery)
);
