import { put, all, call, takeLatest } from 'redux-saga/effects';
import ProgramModify from './programModificationTypes';
import api from '../../api/api.jsx';
import {
  updateProgramNameSuccess,
  updateProgramNameFailure,
  updateProgramNameFinished
} from './programModificationActions';

export function* updateProgramName({ payload: programName }) {
  try {
    const { data } = yield call(api.updateTitle, programName);
    yield put(updateProgramNameSuccess(data));
  } catch (error) {
    yield put(updateProgramNameFailure(error?.response?.data));
  }
  yield put(updateProgramNameFinished());
}

export function* onUpdateProgramNameStart() {
  yield takeLatest(ProgramModify.UPDATE_PROGRAM_NAME_START, updateProgramName);
}

export function* updateProgramNameSagas() {
  yield all([call(onUpdateProgramNameStart)]);
}
