import { saveAs } from 'file-saver';
import text from '../../constants/translates';

/**
 * Download media from file
 * @param {Object} file - media file
 */
export const dowloadMedia = file => {
  saveAs(file, `image_${Date.now()}.jpg`, { autoBom: false });
};

/**
 * Download all files from an array
 * @param {Array} files - Files list
 */
export const dowloadMultipleFiles = files => {
  files.forEach(file => {
    if (file.url) {
      return dowloadMedia(file.url);
    }
    return { message: text.download.messageError };
  });
};
