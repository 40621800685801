import FolderProgramActionTypes from './folder-program.types';

export const fetchFolderProgramStart = refProgram => ({
  type: FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_START,
  payload: refProgram
});

export const fetchFolderProgramSuccess = data => ({
  type: FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_SUCCESS,
  payload: data
});

export const fetchFolderProgramFailure = error => ({
  type: FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_FAILURE,
  payload: error
});

export const fetchFolderProgramFinish = () => ({
  type: FolderProgramActionTypes.FETCH_FOLDER_PROGRAM_FINISH
});
