import { combineReducers } from 'redux';
import searchResultsReducer from './search/search.reducer';
import SearchWithGeopositionResultsReducer from './searchWithGeoposition/searchWithGeoposition.reducer';
import searchCopyResultsReducer from './searchCopy/searchCopy.reducer';
import fileUploaderReducer from './file-uploader/fileUploaderReducer';
import fileSectionUploaderReducer from './file-section-uploader/fileSectionUploaderReducer';
import downloadReducer from './download/download.reducer';
import programListReducer from './programList/Program.list.reducer';
import MySelectionReducer from './mySelection/mySelection.reducer';
import categorizationMediaReducer from './categorisation/categorization.reducer';
import CategoryReducer from './category/category.reducer';
import VisualProgramReducer from './visual-program/visual-program.reducer';
import VisualFolderReducer from './visual-folder/visual-folder.reducer';
import FolderProgramReducer from './folder-program/folder-program.reducer';
import folderDetailsReducer from './folder-details/folder-details.reducer';
import programDetailsReducer from './program-details/program-details.reducer';
import mediaDetailsReducer from './media-details/media-details.reducer';
import modificationMediaReducer from './media-modification/media-modification.reducer';
import deleteMediaReducer from './media-delete/media-delete.reducer';
import themeListReducer from './list.theme/list.theme.reducer';
import s3CallerReducer from './s3-caller/s3-caller.reducer';
import CreateProgramReducer from './createProgram/createProgramReducer';
import SectionReducer from './section/sections/section.reducer';
import LastMediasReducer from './lastMedias/lastMedias.reducer';
import ReorganizeMediasReducer from './reorganize/reorganize.reducer';
import copyProgramListReducer from './copy-program-list/copy.program.list.reducer';
import pasteMediaReducer from './paste.media/paste.media.reducer';
import CreateFolderReducer from './createFolder/createFolderReducer';
import fileFolderUploaderReducer from './file-folder-uploader/fileFolderUploaderReducer';
import programModifyReducer from './programModification/programModificationReducer';
import createTagReducer from './createTag/create.tag.reducer';
import tagListReducer from './tag-list/tag.list.reducer';
import CategoryAdminReducer from './categoryAdmin/categoryAdmin.reducer';
import createSectionReducer from './section/createSection/createSectionReducer';
import loginReducer from './login/loginReducer';
import deleteSectionReducer from './section/deleteSection/deleteSectionReducer';
import editSectionReducer from './section/editSection/editSectionReducer';
import programListCleoReducer from './cleo/programListCleo/programListCleoReducer';
import legalEntityReducer from './legalEntity/legalEntity.reducer';
import postLegalEntityReducer from './postLegalEntity/postLegalEntity.reducer';
import createPartnerReducer from './createPartner/createPartnerReducer';
import naturalPersonsReducer from './naturalPersons/naturalPersons.reducer';
import partnerDeleteReducer from './partner-delete/partner-delete.reducer';
import partnerDetailsReducer from './partner-details/partner-details.reducer';
import partnerLegalEntityReducer from './partner-legalEntity/partner-legalEntity.reducer';
import partnerGetReducer from './list.partner/list.partner.reducer';
import modificationPartnerReducer from './partner-modification/partner-modification.reducer';
import thumbnailsReducer from './thumbnails/thumbnailsReducer';
import shareReducer from './share/share.reducer';
import OrganizationalEntityReducer from './organizationalEntity/organizationalEntity.reducer';
import PublishUnpublishMediasReducer from './publishAndUnpublishMedias/publishAndUnpublishMedias.reducer';
import userInfoReducer from './user-info/user-info.reducer';
import modeControllerReducer from './modeController/modeController.reducer';
import faqReducer from './faq/faq.reducer';
import putFaqReducer from './putFaq/putFaqReducer';
import conditionsReducer from './conditions/conditions.reducer';
import putConditionsReducer from './putConditions/putConditionsReducer';
import FormatReducer from './formats/format.reducer';
import SubformatReducer from './subformats/subformat.reducer';
import mediaOriginalReducer from './media-original/media-original.reducer';
import urlInstanceReducer from './urlInstances/urlInstances.reducer';
import societyQualitiesReducer from './society-qualities/society-qualities.reducer';
import programExternalInfoReducer from './programExternalInfo/programExternalInfo.reducer';

const rootReducer = combineReducers({
  search: searchResultsReducer,
  searchWithGeoposition: SearchWithGeopositionResultsReducer,
  searchCopy: searchCopyResultsReducer,
  fileUploader: fileUploaderReducer,
  fileSectionUploader: fileSectionUploaderReducer,
  dowload: downloadReducer,
  fileFolderUploader: fileFolderUploaderReducer,
  programList: programListReducer,
  mySelection: MySelectionReducer,
  programDetails: programDetailsReducer,
  mediaDetails: mediaDetailsReducer,
  categorization: categorizationMediaReducer,
  categories: CategoryReducer,
  visualProgram: VisualProgramReducer,
  visualFolder: VisualFolderReducer,
  folderProgram: FolderProgramReducer,
  folderDetails: folderDetailsReducer,
  modification: modificationMediaReducer,
  deleteMedia: deleteMediaReducer,
  themeList: themeListReducer,
  s3Caller: s3CallerReducer,
  createProgram: CreateProgramReducer,
  sections: SectionReducer,
  lastMedias: LastMediasReducer,
  reorganizeMedias: ReorganizeMediasReducer,
  copyProgramList: copyProgramListReducer,
  pasteMediaResult: pasteMediaReducer,
  createFolder: CreateFolderReducer,
  programUpdate: programModifyReducer,
  tagList: tagListReducer,
  createTag: createTagReducer,
  categoryManage: CategoryAdminReducer,
  createSection: createSectionReducer,
  login: loginReducer,
  deleteSection: deleteSectionReducer,
  editSection: editSectionReducer,
  programListCleo: programListCleoReducer,
  thumbnails: thumbnailsReducer,
  organizationalEntity: OrganizationalEntityReducer,
  publishUnpublishMedias: PublishUnpublishMediasReducer,
  legalEntity: legalEntityReducer,
  postLegalEntity: postLegalEntityReducer,
  createPartner: createPartnerReducer,
  naturalPersons: naturalPersonsReducer,
  partnerDelete: partnerDeleteReducer,
  partnerDetails: partnerDetailsReducer,
  partnerLegalEntity: partnerLegalEntityReducer,
  partnerList: partnerGetReducer,
  modificationPartner: modificationPartnerReducer,
  share: shareReducer,
  userInfo: userInfoReducer,
  modeController: modeControllerReducer,
  faqCms: faqReducer,
  putFaq: putFaqReducer,
  conditionsCms: conditionsReducer,
  putConditions: putConditionsReducer,
  formats: FormatReducer,
  subformats: SubformatReducer,
  mediaOriginal: mediaOriginalReducer,
  urlInstance: urlInstanceReducer,
  societyQualities: societyQualitiesReducer,
  programExternalInfoData: programExternalInfoReducer
});

export default rootReducer;
