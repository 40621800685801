import { createSelector } from 'reselect';

const selectCreateProgram = state => state.createProgram;

export const selectedMessage = createSelector(
  [selectCreateProgram],
  createProgram => createProgram.message
);

export const selectedTypeOfProjects = createSelector(
  [selectCreateProgram],
  projectTypes => projectTypes?.typeOfProjects
);

export const selectedIsLoading = createSelector(
  [selectCreateProgram],
  createProgram => createProgram.isLoading
);

export const selectedIsLoadingTypeProjects = createSelector(
  [selectCreateProgram],
  createProgram => createProgram.isLoadingTypeProjects
);

export const selectedDestinationProperty = createSelector(
  [selectCreateProgram],
  projectTypes => projectTypes?.destinationProperty
);

export const selectedIsLoadingDestinationProperty = createSelector(
  [selectCreateProgram],
  createProgram => createProgram?.isLoadingDestinationProperty
);

export const selectedRegions = createSelector(
  [selectCreateProgram],
  projectTypes => projectTypes?.regions
);

export const selectedIsLoadingRegions = createSelector(
  [selectCreateProgram],
  createProgram => createProgram?.isLoadingRegions
);

export const selectedDepartments = createSelector(
  [selectCreateProgram],
  projectTypes => projectTypes?.departments
);

export const selectedIsLoadingDepartments = createSelector(
  [selectCreateProgram],
  createProgram => createProgram?.isLoadingDepartments
);

export const selectedCommunes = createSelector(
  [selectCreateProgram],
  projectTypes => projectTypes?.communes
);

export const selectedIsLoadingCommunes = createSelector(
  [selectCreateProgram],
  createProgram => createProgram?.isLoadingCommunes
);
