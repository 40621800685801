import { createSelector } from 'reselect';

const selectCreatePartner = state => state.createPartner;

export const selectCreatePartnerMessage = createSelector(
  [selectCreatePartner],
  createPartner => createPartner.message
);

export const selectCreatePartnerIsLoading = createSelector(
  [selectCreatePartner],
  createPartner => createPartner.isLoading
);

export const selectCreatePartnerError = createSelector(
  [selectCreatePartner],
  createPartner => createPartner.error
);

export const selectCreatePartnerSucceed = createSelector(
  [selectCreatePartner],
  createPartner => createPartner.succeed
);
