function organizationalUnitsBuilder(organizationalUnits) {
  return organizationalUnits?.map(organizationalUnit => {
    return {
      id: organizationalUnit
    };
  });
}
function partnerItemBuilder(itemPartner) {
  return {
    refPartner: itemPartner?.refPartner,
    refRelationShipId: itemPartner?.refRelationShipId,
    refLegalEntity: itemPartner?.refLegalEntity,
    email: itemPartner?.email,
    organizationalUnits: organizationalUnitsBuilder(
      itemPartner?.organizationalUnits
    )
  };
}

function partnerListBuilder(partnerList) {
  return partnerList?.result?.map(item => {
    return partnerItemBuilder(item);
  });
}

export function partnerBuilder(data) {
  return partnerListBuilder(data);
}
