import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { useParams, withRouter } from 'react-router-dom';
import api from '../../api/api.jsx';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import { REDIRECT_TO } from '../../constants/global-constants.jsx';

let checkingLogin = false;
const Partner = ({ history }) => {
  const { user } = useParams();

  const handleGoHomePage = () => {
    window.location = window.location.origin;
  };

  const handleGoErrorPage = () => {
    history.push(`${ROUTES_CONSTANTS.home}${REDIRECT_TO.accessDenied}`);
  };

  const checkLoginPatner = async () => {
    try {
      const response = await api.loginPartner(`guid=${user}`);
      if (response.status === 200) {
        api.refreshToken(localStorage.refreshToken).then(res => {
          api.setAutorizationToken(res.access_token, res.refresh_token);
        });
        handleGoHomePage();
      } else {
        handleGoErrorPage();
      }
    } catch (error) {
      handleGoErrorPage();
    }
  };

  if (!checkingLogin) {
    checkingLogin = true;
    checkLoginPatner();
  }

  return (
    <>
      <div className="progress__container">
        <LinearProgress />
      </div>
    </>
  );
};

Partner.propTypes = {
  history: PropTypes.func
};

export default withRouter(connect(null, null)(Partner));
