import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  AttachFile,
  Description,
  PictureAsPdf,
  Theaters,
  Image
} from '@material-ui/icons';
import './style.scss';
import { makeStyles } from '@material-ui/styles';
import styles from './style.jsx';
import {
  maxFileSize,
  fileLimit,
  acceptedDropzoneFormats,
  acceptedFormats,
  fileTypes,
  FOLDER_DOCUMENTS_NAME,
  maxSizeForThumbnail
} from '../../constants/global-constants';
import t from '../../constants/translates';
import {
  convertDataURIToBinary,
  tiffToDataURI
} from '../../shared/functions/uploadFile';
import { convertBytes } from '../../shared/functions/convertBytes';

const Dropzone = props => {
  const style = makeStyles(styles)();

  const getFileAddedMessage = fileName => {
    return t.formatString(t.fileUploader.fileAddedMessage, { fileName });
  };
  const getFileLimitExceedMessage = filesLimit => {
    return t.formatString(t.fileUploader.fileLimitExceedMessage, {
      filesLimit
    });
  };
  const getFileRemovedMessage = fileName => {
    return t.formatString(t.fileUploader.fileRemovedMessage, {
      fileName
    });
  };
  const getDropRejectMessage = file => {
    if (file.size > maxFileSize) {
      return getFileLimitExceedMessage(convertBytes(maxFileSize));
    }

    const arrayFormat = [];
    Object.values(acceptedFormats).forEach(value => {
      arrayFormat.push(` ${value}`);
    });
    return t.formatString(t.fileUploader.dropIncorrectFormatMessage, {
      fileName: file.name,
      formatList: arrayFormat
    });
  };

  const getPreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image
    };

    if (type.startsWith('video/')) return <Theaters {...iconProps} />;

    if (
      type.startsWith('image/tiff') &&
      fileObject.file.size < maxSizeForThumbnail
    ) {
      const arrayBuffer = convertDataURIToBinary(fileObject.data);
      const imgSource = tiffToDataURI(arrayBuffer);
      if (imgSource) {
        return (
          <img
            crossOrigin="anonymous"
            {...iconProps}
            src={imgSource}
            alt="preview"
          />
        );
      }

      return <AttachFile {...iconProps} />;
    }
    if (
      type.startsWith('image/') &&
      fileObject.file.size < maxSizeForThumbnail
    ) {
      return (
        <img
          crossOrigin="anonymous"
          {...iconProps}
          src={fileObject.data}
          alt="preview"
        />
      );
    }

    if (type.startsWith('image/')) {
      return <Image {...iconProps} />;
    }

    switch (type) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Description {...iconProps} />;
      case 'application/pdf':
        return <PictureAsPdf {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  return (
    <DropzoneArea
      {...props}
      dropzoneText={t?.fileUploader.textAreaDescription}
      dropzoneClass={style.dropzone}
      acceptedFiles={
        // eslint-disable-next-line react/destructuring-assignment
        props.folderName === FOLDER_DOCUMENTS_NAME // If we are in documents upload we change the accepted formats
          ? fileTypes
          : acceptedDropzoneFormats.concat(fileTypes)
      }
      filesLimit={fileLimit}
      maxFileSize={maxFileSize}
      dropzoneParagraphClass={style.paragraph}
      showFileNames
      getFileAddedMessage={getFileAddedMessage}
      getFileLimitExceedMessage={getFileLimitExceedMessage}
      getFileRemovedMessage={getFileRemovedMessage}
      getDropRejectMessage={getDropRejectMessage}
      getPreviewIcon={getPreviewIcon}
    />
  );
};
Dropzone.propTypes = {
  isMediaProgram: PropTypes.bool,
  folderName: PropTypes.string
};

export default Dropzone;
