import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import ProgramListTypes from './program.list.types';
import {
  programWithMediaBuilder,
  programWithoutMediaBuilder
} from './program-list-builder';
import api from '../../api/api.jsx';
import {
  fetchProgramListFailure,
  fetchProgramListFinished,
  fetchProgramListSuccess,
  fetchProgramListWithoutMediaSuccess,
  fetchProgramListWithoutMediaFailure,
  fetchProgramListWithoutMediaFinished
} from './program.list.actions';

export const selectCreateProgram = state => state.createProgram;

export function* ProgramListResults({ payload: params }) {
  try {
    const { data } = yield call(api.getProgramList, params);
    const { communes } = yield select(selectCreateProgram);
    const dataBuilder = yield call(programWithMediaBuilder, data, communes);
    yield put(fetchProgramListSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchProgramListFailure(error));
  }
  yield put(fetchProgramListFinished());
}

export function* ProgramListWithoutMediaResults({ payload: params }) {
  try {
    const { data } = yield call(api.getProgramList, params);
    const { communes } = yield select(selectCreateProgram);
    const dataBuilder = yield call(programWithoutMediaBuilder, data, communes);
    yield put(fetchProgramListWithoutMediaSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchProgramListWithoutMediaFailure(error));
  }
  yield put(fetchProgramListWithoutMediaFinished());
}

export function* onFetchProgramListStart() {
  yield takeLatest(
    ProgramListTypes.FETCH_PROGRAM_LIST_START,
    ProgramListResults
  );
}

export function* onFetchProgramListWithoutMediaStart() {
  yield takeLatest(
    ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_START,
    ProgramListWithoutMediaResults
  );
}

export function* ProgramListSagas() {
  yield all([
    call(onFetchProgramListWithoutMediaStart),
    call(onFetchProgramListStart)
  ]);
}
