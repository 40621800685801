export default {
  buttonOk: {
    textTransform: 'none',
    padding: '7px',
    height: '35px',
    justifyContent: 'center',
    fontSize: '16px',
    backgroundColor: '#c82832',
    color: '#ffffff'
  },

  button: {
    textTransform: 'none',
    padding: '7px',
    height: '35px',
    justifyContent: 'center',
    fontSize: '16px'
  }
};
