import folderDetailsActionTypes from './folder-details.types';

const INITIAL_STATE = {
  folderDetails: [],
  error: null,
  isLoading: false
};

const folderDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case folderDetailsActionTypes.FETCH_FOLDER_DETAILS_START:
      return { ...state, isLoading: true };
    case folderDetailsActionTypes.FETCH_FOLDER_DETAILS_SUCCESS:
      return { ...state, folderDetails: action.payload, error: null };
    case folderDetailsActionTypes.FETCH_FOLDER_DETAILS_FAILURE:
      return { ...state, error: action.payload };
    case folderDetailsActionTypes.FETCH_FOLDER_DETAILS_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default folderDetailsReducer;
