const SearchCopyActionTypes = {
  FETCH_SEARCHCOPY_RESULTS_START: 'FETCH_SEARCHCOPY_RESULTS_START',
  FETCH_SEARCHCOPY_RESULTS_SUCCESS: 'FETCH_SEARCHCOPY_RESULTS_SUCCESS',
  FETCH_SEARCHCOPY_RESULTS_FAILURE: 'FETCH_SEARCHCOPY_RESULTS_FAILURE',
  FETCH_SEARCHCOPY_RESULTS_FINISHED: 'FETCH_SEARCHCOPY_RESULTS_FINISHED',
  FETCH_SEARCHCOPY_SUGGESTIONS_START: 'FETCH_SEARCHCOPY_SUGGESTIONS_START',
  FETCH_SEARCHCOPY_SUGGESTIONS_SUCCESS: 'FETCH_SEARCHCOPY_SUGGESTIONS_SUCCESS',
  FETCH_SEARCHCOPY_SUGGESTIONS_FAILURE: 'FETCH_SEARCHCOPY_SUGGESTIONS_FAILURE',
  FETCH_SEARCHCOPY_SUGGESTIONS_FINISHED:
    'FETCH_SEARCHCOPY_SUGGESTIONS_FINISHED',
  FETCH_SEARCHCOPY_TOOLBAR: 'FETCH_SEARCHCOPY_TOOLBAR',
  FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_START:
    'FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_START',
  FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_SUCCESS:
    'FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_SUCCESS',
  FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_FAILURE:
    'FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_FAILURE',
  FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_FINISHED:
    'FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_FINISHED',
  FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_START:
    'FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_START',
  FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_SUCCESS:
    'FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_SUCCESS',
  FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_FAILURE:
    'FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_FAILURE',
  FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_FINISHED:
    'FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_FINISHED'
};

export default SearchCopyActionTypes;
