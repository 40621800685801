import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { sortBy } from 'lodash';
import LabelInput from '../labelInput/labelInput.jsx';
import Combobox from '../combobox/combobox.jsx';
import t from '../../constants/translates';
import ButtonComponent from '../button/buttonComponent.jsx';
import styles from './style';
import {
  CREATE_PROGRAMS_SECTION_PARAM,
  HANDLE_CHANGE_TYPES,
  operation
} from '../../constants/global-constants.jsx';
import { patchConstructor } from '../../shared/functions/mediaFunctions';
import { updateProgramNameStart } from '../../redux/programModification/programModificationActions.js';
import {
  selectedIsLoading,
  selectedIsLoadingTypeProjects,
  selectedTypeOfProjects,
  selectedDestinationProperty,
  selectedIsLoadingDestinationProperty,
  selectedRegions,
  selectedIsLoadingRegions,
  selectedDepartments,
  selectedIsLoadingDepartments,
  selectedCommunes,
  selectedIsLoadingCommunes
} from '../../redux/createProgram/createProgramSelectors.js';
import { fetchProgramDetailsStart } from '../../redux/program-details/program-details.actions.js';
import {
  selectProgramDetails,
  selectProgramDetailsIsLoading
} from '../../redux/program-details/program-details.selectors.js';
import Spinner from '../spinner/Spinner.jsx';
import { selectedProgramUpdateSucceed } from '../../redux/programModification/programModificationSelectors.js';

const CreateProgramIE = ({
  sendFormData,
  typeOfProjects,
  destinationPropertyItems,
  regions,
  departments,
  communes,
  history,
  isEditMode,
  sendEditProgram,
  getProgramDetails,
  refProgram,
  programDetails,
  programDetailsIsLoading,
  programUpdateSucceed
}) => {
  const style = makeStyles(styles)();
  const [currentRegion, setCurrentRegion] = useState();
  const [currentDepartment, setCurrentDepartment] = useState();
  const [currentCommunes, setCurrentCommunes] = useState();
  const [currentTypeProject, setCurrentTypeProject] = useState();
  const [
    currentDestinationProperty,
    setCurrentDestinationProperty
  ] = useState();
  const [formData, setFormData] = useState({
    name: null,
    section: CREATE_PROGRAMS_SECTION_PARAM.programIE,
    typeProjet: null,
    year: null,
    cityCode: null,
    address: null
  });
  // Manage data fetched through programDetails.cityCode
  const [missingProgramData, setMissingProgramData] = useState({
    codeDepartement: null,
    codeRegion: null,
    cityName: null
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonDisabledEditMode, setIsButtonDisabledEditMode] = useState(
    true
  );
  const [programTypesArray, setProgramTypesArray] = useState([]);
  const [destinationPropertyArray, setDestinationPropertyArray] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState();
  const [filteredCommunes, setFilteredCommunes] = useState();
  const [currentYear, setCurrentYear] = useState({
    year: undefined,
    isValidated: true
  });
  const patchModifyProgram = [];

  // Fetch programDetails
  useEffect(() => {
    if (isEditMode) {
      getProgramDetails({ refProgram });
    }
  }, []);

  const regionFormat = regionCode => {
    const findRegionFormat = regions?.find(obj => {
      return obj.code === regionCode;
    });
    return findRegionFormat;
  };

  const departementFormat = departementCode => {
    const findDepartmentFormat = departments?.find(obj => {
      return obj.code === departementCode;
    });
    return `${findDepartmentFormat?.code} - ${findDepartmentFormat?.nom}`;
  };

  // Convert fetch data to render format in Edit Mode
  const findItemValue = (array, item) => {
    return array?.values?.value.find(element => element.valueCode === item);
  };

  const findedItemType = findItemValue(
    typeOfProjects,
    programDetails?.typeProjet
  );

  const findedItemDestination = findItemValue(
    destinationPropertyItems,
    programDetails?.propertyUse
  );

  // Manage programDetails render in Edit Mode
  useEffect(() => {
    if (isEditMode) {
      if (programDetails?.year) {
        const { year } = programDetails;
        const parsedYear = year.slice(0, 4);
        setCurrentYear({ year: parsedYear, isValidated: true });
      }

      if (programDetails?.typeProjet === findedItemType?.valueCode) {
        setCurrentTypeProject(findedItemType?.shortLabel);
      }

      if (programDetails.propertyUse === findedItemDestination?.valueCode) {
        setCurrentDestinationProperty(findedItemDestination?.shortLabel);
      }

      if (regions?.length > 0) {
        setCurrentRegion(regionFormat(missingProgramData?.codeRegion));
      }

      if (departments?.length > 0) {
        setCurrentDepartment(
          departementFormat(missingProgramData?.codeDepartement)
        );
      }

      if (communes?.length > 0) {
        const findCityName = communes?.find(
          obj => obj.nom === missingProgramData?.cityName
        );
        setCurrentCommunes(findCityName);
      }

      setFormData({
        name: programDetails?.name,
        address: programDetails?.address
      });
    }
  }, [programDetails, regions, missingProgramData, communes, departments]);

  // Fetch cityCode from Communes to render Department, Region & City name
  useEffect(() => {
    setCurrentYear({ year: '', isValidated: true });

    if (isEditMode && communes) {
      const allProgramDetails = communes.find(obj => {
        return obj.code === programDetails?.cityCode;
      });
      setMissingProgramData({
        codeDepartement: allProgramDetails?.codeDepartement,
        codeRegion: allProgramDetails?.codeRegion,
        cityName: allProgramDetails?.nom
      });
    }
  }, [communes]);

  const saveButtonVisibilityHandler = () => {
    const values = Object.values(formData);
    delete values[4];
    let hasError = null;
    values.map(i => {
      if (!i && !isEditMode) {
        hasError = true;
        return hasError;
      }
      return hasError;
    });
    return setIsButtonDisabled(hasError);
  };

  useEffect(() => {
    saveButtonVisibilityHandler();
  }, [formData]);

  useEffect(() => {
    if (programUpdateSucceed) {
      history.goBack();
    }
  }, [programUpdateSucceed]);

  const mapPositionObject = (array, newArray) => {
    return array?.values?.value.map(type => newArray.push(type.shortLabel));
  };

  const findItem = (array, item) => {
    return array?.values?.value.find(element => element.shortLabel === item);
  };

  const mapRegions = (array, newArray) => {
    return array?.map(item => newArray.push(item.nom));
  };

  useEffect(() => {
    const varProgramTypesArray = [];
    mapPositionObject(typeOfProjects, varProgramTypesArray);
    setProgramTypesArray(varProgramTypesArray);
    const varDestinationPropertyArray = [];
    mapPositionObject(destinationPropertyItems, varDestinationPropertyArray);
    setDestinationPropertyArray(varDestinationPropertyArray);

    const regionArray = [];
    mapRegions(regions, regionArray);
    const regionArraySorted = sortBy(regionArray);
    setRegionList(regionArraySorted);
  }, [
    typeOfProjects,
    destinationPropertyItems,
    regions,
    departments,
    communes
  ]);

  const handleOnChange = (e, array, object) => {
    if (isEditMode && e) {
      setIsButtonDisabledEditMode(false);
    }
    const findedItem = findItem(array, e);

    if (object === HANDLE_CHANGE_TYPES.typeProject) {
      setCurrentTypeProject(findedItem?.shortLabel);
    } else {
      setCurrentDestinationProperty(findedItem?.shortLabel);
    }
    if (e.length) {
      setFormData({
        ...formData,
        [object]: {
          level: '1',
          listCode: array?.listCode,
          refValue: findedItem?.valueCode
        }
      });
    } else {
      setFormData({
        ...formData,
        [object]: null
      });
    }
    return findedItem;
  };

  const mapDepartments = (array, newArray) => {
    if (array !== undefined) {
      array.map(item => newArray.push(`${item.code} - ${item.nom}`));
    }
  };

  const mapCities = (array, newArray) => {
    if (array !== undefined) {
      array.map(item => newArray.push(item.nom));
    }
  };

  const handleOnChangeName = e => {
    setFormData({ ...formData, name: e.target.value });
    if (e.target.value !== programDetails?.name) {
      setIsButtonDisabledEditMode(false);
    } else {
      setIsButtonDisabledEditMode(true);
    }
  };

  const handleOnChangeYear = e => {
    const year = e.target.value;
    let valid = false;
    if (year.length === 4 && !Number.isNaN(Number(year))) {
      setFormData({ ...formData, year });
      valid = true;
    } else {
      setFormData({ ...formData, year: undefined });
    }
    setCurrentYear({ year, isValidated: valid });
    if (isEditMode) {
      if (year !== currentYear?.year) {
        setIsButtonDisabledEditMode(false);
      } else {
        setIsButtonDisabledEditMode(true);
      }
    }
  };

  const handleOnChangeAddress = e => {
    setFormData({ ...formData, address: e.target.value });
    if (e.target.value !== programDetails?.address) {
      setIsButtonDisabledEditMode(false);
    } else {
      setIsButtonDisabledEditMode(true);
    }
  };

  const handleOnChangeRegion = e => {
    const findedRegion = regions?.find(element => element.nom === e);

    const findAlldDepartments = departments?.filter(
      element => element?.codeRegion === findedRegion?.code
    );

    const newDepartmentsArray = [];
    mapDepartments(findAlldDepartments, newDepartmentsArray);
    setCurrentRegion(findedRegion);
    setCurrentDepartment();
    setCurrentCommunes();
    setFilteredCommunes();
    const newDepartmentsArraySorted = sortBy(newDepartmentsArray);
    setFilteredDepartments(newDepartmentsArraySorted);
  };

  const handleOnChangeDepartment = e => {
    setFilteredCommunes();
    setCurrentCommunes();

    const findedDepartment = departments?.find(
      element => `${element.code} - ${element.nom}` === e
    );

    if (findedDepartment !== undefined) {
      setCurrentDepartment(
        `${findedDepartment?.code} - ${findedDepartment?.nom}`
      );
      const findAllCommunes = communes?.filter(
        element => element?.codeDepartement === findedDepartment?.code
      );
      if (e.length !== 0) {
        const newCommunesArray = [];
        mapCities(findAllCommunes, newCommunesArray);
        const newCommunesArraySorted = sortBy(newCommunesArray);
        setFilteredCommunes(newCommunesArraySorted);
      }
    }
  };

  const handleOnChangeCommunes = e => {
    const findedCommune = communes?.find(element => element.nom === e);
    setCurrentCommunes(findedCommune);
    setFormData({ ...formData, cityCode: findedCommune?.code });
    if (missingProgramData.cityName !== e) {
      setIsButtonDisabledEditMode(false);
    } else {
      setIsButtonDisabledEditMode(true);
    }
  };

  // Convert to programDetails format
  const findItemShortLabel = (array, item) => {
    return array?.values?.value.find(element => element.shortLabel === item);
  };

  const findedItemTypeProject = findItemShortLabel(
    typeOfProjects,
    currentTypeProject
  );

  const findedItemDestinationProperty = findItemShortLabel(
    destinationPropertyItems,
    currentDestinationProperty
  );

  // Object value patch typeProjet
  const objectPatchTypeProject = {
    level: '1',
    listCode: typeOfProjects?.listCode,
    refValue: findedItemTypeProject?.valueCode
  };

  // Object value patch propertyIntendedUse
  const objectPatchPropertyUse = {
    level: '1',
    listCode: destinationPropertyItems?.listCode,
    refValue: findedItemDestinationProperty?.valueCode
  };

  // Repplace old values for new ones if program is modified
  const modifyProgram = () => {
    if (programDetails?.name !== formData?.name) {
      patchModifyProgram.push(
        patchConstructor(operation.replaceOperation, '/name', formData?.name)
      );
    }

    if (programDetails?.typeProjet !== findedItemTypeProject?.valueCode) {
      patchModifyProgram.push(
        patchConstructor(
          operation.replaceOperation,
          '/typeProjet',
          objectPatchTypeProject
        )
      );
    }

    if (
      programDetails?.propertyUse !== findedItemDestinationProperty?.valueCode
    ) {
      patchModifyProgram.push(
        patchConstructor(
          operation.replaceOperation,
          '/propertyIntendedUse',
          objectPatchPropertyUse
        )
      );
    }

    const { year } = programDetails;
    const parsedYear = year.slice(0, 4);
    const validateDateFormat = `${currentYear.year}-01-01`;
    if (currentYear.year !== parsedYear) {
      patchModifyProgram.push(
        patchConstructor(
          operation.replaceOperation,
          '/year',
          validateDateFormat
        )
      );
    }
    if (programDetails !== undefined) {
      if (programDetails?.cityCode !== currentCommunes?.code) {
        patchModifyProgram.push(
          patchConstructor(
            operation.replaceOperation,
            '/cityCode',
            currentCommunes?.code
          )
        );
      }
    }
    if (programDetails?.address !== formData?.address) {
      patchModifyProgram.push(
        patchConstructor(
          operation.replaceOperation,
          '/address',
          formData?.address
        )
      );
    }
  };

  const formSubmit = () => {
    if (!isEditMode) {
      sendFormData({ formData, history });
    } else {
      modifyProgram();
      const patchModifyProgramFormat = JSON.stringify(patchModifyProgram);
      sendEditProgram({
        refProgram,
        programName: patchModifyProgramFormat
      });
    }
  };

  const getLoadings = () => {
    return programDetailsIsLoading;
  };

  return (
    <>
      <Spinner isLoading={getLoadings()} />
      <>
        <div className="title-page">
          <p className="title-page__text">
            {isEditMode
              ? t.createProgram.modifyProgramIE
              : t.createProgram.createProgramIE}
          </p>
        </div>
        <div className="program-name">
          <LabelInput
            required
            label={t.createProgram.programName}
            value={formData.name}
            onChangeHandler={e => handleOnChangeName(e)}
          />
        </div>
        <div className="input-container">
          <Combobox
            label={t.createProgram.programType}
            data={programTypesArray}
            value={currentTypeProject}
            onChangeHandler={e =>
              handleOnChange(e, typeOfProjects, HANDLE_CHANGE_TYPES.typeProject)
            }
            filter={!isEditMode}
          />
        </div>
        <div className="input-container">
          <Combobox
            label={t.createProgram.destinationProperty}
            data={destinationPropertyArray}
            value={currentDestinationProperty}
            onChangeHandler={e =>
              handleOnChange(
                e,
                destinationPropertyItems,
                HANDLE_CHANGE_TYPES.destinationProperty
              )
            }
            filter={!isEditMode}
          />
        </div>
        <div className="year-container">
          <div className="year-input">
            <LabelInput
              label={t.createProgram.year}
              value={currentYear.year}
              onChangeHandler={e => handleOnChangeYear(e)}
            />
          </div>
          {!currentYear.isValidated && (
            <p className="year-input__error">{t.createProgram.yearError}</p>
          )}
        </div>
        <div className="vertical-block">
          <div className="input-container">
            <Combobox
              value={currentRegion?.nom}
              data={regionList}
              label={t.createProgram.region}
              onChangeHandler={e => handleOnChangeRegion(e)}
            />
          </div>
          <div className="input-container">
            <Combobox
              disabled={
                !isEditMode &&
                (!filteredDepartments || !filteredDepartments.length)
              }
              value={currentDepartment}
              label={t.createProgram.department}
              data={filteredDepartments}
              onChangeHandler={e => handleOnChangeDepartment(e)}
            />
          </div>
          <div className="input-container">
            <Combobox
              disabled={
                !isEditMode && (!filteredCommunes || !filteredCommunes.length)
              }
              label={t.createProgram.city}
              data={filteredCommunes}
              onChangeHandler={e => handleOnChangeCommunes(e)}
              value={currentCommunes?.nom}
            />
          </div>
        </div>
        <div className="address">
          <LabelInput
            label={t.createProgram.address}
            onChangeHandler={e => handleOnChangeAddress(e)}
            value={formData.address}
          />
        </div>
        <div className="button-group">
          <div className="cancel-button">
            <ButtonComponent
              className={style.cancelButton}
              onClick={() => history.goBack()}
              id="cancel-button"
            >
              <span className="cancel-button__text">{t.cancel}</span>
            </ButtonComponent>
          </div>
          <div className="save-button">
            <ButtonComponent
              className={style.saveButton}
              disabled={
                isButtonDisabled || (isEditMode && isButtonDisabledEditMode)
              }
              onClick={() => formSubmit()}
              id="saveButton"
            >
              <span className="save-button__text">{t.save}</span>
            </ButtonComponent>
          </div>
        </div>
      </>
    </>
  );
};

CreateProgramIE.propTypes = {
  sendFormData: PropTypes.func,
  history: PropTypes.func,
  typeOfProjects: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  destinationPropertyItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  regions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  departments: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  communes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isEditMode: PropTypes.bool,
  sendEditProgram: PropTypes.func,
  refProgram: PropTypes.string,
  getProgramDetails: PropTypes.func,
  programDetails: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  programDetailsIsLoading: PropTypes.func,
  programUpdateSucceed: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  typeOfProjects: selectedTypeOfProjects,
  isLoading: selectedIsLoading,
  isLoadingTypeProjects: selectedIsLoadingTypeProjects,
  destinationPropertyItems: selectedDestinationProperty,
  isLoadingDestinationProperty: selectedIsLoadingDestinationProperty,
  regions: selectedRegions,
  isLoadingRegions: selectedIsLoadingRegions,
  departments: selectedDepartments,
  isLoadingDepartments: selectedIsLoadingDepartments,
  communes: selectedCommunes,
  isLoadingCommunes: selectedIsLoadingCommunes,
  programDetails: selectProgramDetails,
  programDetailsIsLoading: selectProgramDetailsIsLoading,
  programUpdateSucceed: selectedProgramUpdateSucceed
});

const mapDispatchToProps = dispatch => ({
  sendEditProgram: programName => dispatch(updateProgramNameStart(programName)),
  getProgramDetails: refProgram =>
    dispatch(fetchProgramDetailsStart(refProgram))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateProgramIE)
);
