function getDefaultBool(additionnalInformation) {
  return additionnalInformation.filter(el => {
    return el.informationCode === 'DEFAULT';
  })?.[0].value;
}

export function subformatListBuilder(data) {
  return data?.values?.map(subformat => {
    return {
      shortLabel: subformat?.shortLabel,
      longLabel: subformat?.longLabel,
      order: subformat?.order,
      valueCode: subformat?.valueCode,
      parentCode: subformat?.parentCode,
      status: subformat?.status,
      startValidityDate: subformat?.startValidityDate,
      endValidityDate: subformat?.endValidityDate,
      refValue: subformat?.refValue,
      isDefault: getDefaultBool(subformat?.additionnalInformation)
    };
  });
}
