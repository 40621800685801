/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { makeStyles } from '@material-ui/core/styles';
import style from './style.jsx';
import { arrayMove } from '../../shared/functions/arrayMove.js';
import SortableCategories from '../../components/sortableCategories/sortableCategories.jsx';
import text from '../../constants/translates.jsx';
import './categoryManage.scss';
import { LEVELS } from '../../constants/global-constants.jsx';
import ArrowIcon from '../../assets/images/arrow.png';

const CategoryManage = ({
  section,
  updateCategories,
  deleteCategory,
  createCategory
}) => {
  const classes = makeStyles(style)();
  const [newCategory, setNewCategory] = useState();
  const [items, setItems] = useState();
  const [subcategories, setSubcategories] = useState();
  const [finalArray, setFinalArray] = useState();
  const [canOrder, setCanOrder] = useState(true);
  const [updateCategory, setUpdateCategory] = useState([]);
  const [currentCategory, setCurrentCategory] = useState();
  useEffect(() => {
    setSubcategories();
    setCurrentCategory();
    setItems(section?.categories);
  }, [section?.refSection]);
  useEffect(() => {
    const current = section?.categories?.find(
      cat => cat.refCategory === currentCategory?.refCategory
    );
    if (current) {
      setSubcategories(current.subCategory);
    }
    setItems(section?.categories);
  }, [section?.categories]);

  const onSortEndCategories = ({ oldIndex, newIndex }) => {
    setItems([...arrayMove(oldIndex, newIndex, items)]);
    setFinalArray(items);
  };

  const onSortEndSubcategories = ({ oldIndex, newIndex }) => {
    setSubcategories([...arrayMove(oldIndex, newIndex, subcategories)]);
    setFinalArray(subcategories);
  };

  /**
   * Function for overwrite value when this exists
   * @param {Object} item category
   * @param {Object} value the new value
   */

  const overwriteItem = (item, value) => {
    const newObject = [...updateCategory, { item, value }];

    const findCategory = updateCategory.find(
      category => category.item.refCategory === item.refCategory
    );

    if (findCategory) {
      findCategory.value = value;
    } else {
      setUpdateCategory([...newObject]);
    }
  };

  /**
   * Function for create  call dispatch action
   * @param {Object} item category
   * @param {Object} value the new value
   */

  const sendSortedArrayHandler = () => {
    const newArray = [];

    finalArray.map((item, index) => newArray.push({ item, order: index + 1 }));

    updateCategories(newArray);
  };

  /**
   * Function for print categories and subcategories
   * @param {Object} array category
   */

  const printEditCategoriesAndSubcategories = array =>
    array?.map(item => {
      return (
        <div className="sortable-category-item">
          <input
            className={classes.modifyCategory}
            defaultValue={item.name}
            onChange={e => overwriteItem(item, e.target.value)}
          />
        </div>
      );
    });

  /**
   * Function for print the button who dispatch delete action
   * @param {Object} value current category or subcategory
   */

  const deleteButton = value => {
    return (
      <button
        type="button"
        id="deleteSection"
        className={classes.deleteSectionButton}
        onClick={() => {
          deleteCategory({
            level: value.level,
            refValue: value.refCategory || value.refSubCategory
          });
        }}
      >
        <DeleteOutlinedIcon />
        {text.deleteButton}
      </button>
    );
  };

  /**
   * Function for print the button who dispatch delete action
   * @param {Object} value current category or subcategory
   */

  const setSubcategoriesFromCategory = value => {
    if (value.level === LEVELS.CATEGORIE) {
      return (
        <button
          type="button"
          className={classes.goToSubcategoriesButton}
          onClick={() => {
            setCurrentCategory(value);
            setSubcategories(value.subCategory);
          }}
        >
          {text.AdminCategories.goToSubcategories}
        </button>
      );
    }
    return null;
  };

  /**
   * Function for go back to categories
   */

  const setCategories = () => {
    let styleForSetCategories;
    if (subcategories && !canOrder) {
      styleForSetCategories = { marginLeft: '0px' };
    }
    return subcategories ? (
      <button
        type="button"
        className={classes.setCategoriesButton}
        style={styleForSetCategories}
        onClick={() => {
          setCurrentCategory(null);
          setSubcategories(null);
        }}
      >
        {text.AdminCategories.goToCategories}
      </button>
    ) : null;
  };

  /**
   * Function for print order categories
   * @param {Object} dataInput current category or subcategory
   */

  const printOrderCategoriesAndSubcategories = dataInput => {
    return (
      <div className="sortable-category-item">
        <div id={uuidv4()} className={classes.inputCategory}>
          <span>{dataInput?.category || dataInput?.name}</span>
        </div>
        <img
          crossOrigin="anonymous"
          className="arrow-icon"
          alt=""
          src={ArrowIcon}
        ></img>
        {setSubcategoriesFromCategory(dataInput)}
        {deleteButton(dataInput)}
      </div>
    );
  };

  /**
   * Function for dispatch createCategory action
   * @param {Object} level current level of category or subcategory
   */

  const saveNewCategoryOrSubcategory = level => {
    setNewCategory('');
    createCategory({
      level,
      newValue: newCategory,
      section,
      category: currentCategory
    });
  };

  /**
   * Function for print save button
   * @param {Object} level current level of category or subcategory
   */

  const saveNewCategoryButton = level => (
    <button
      className={classes.saveNewCategoryButton}
      id="saveNewCategoryButton"
      type="button"
      onClick={() => saveNewCategoryOrSubcategory(level)}
    >
      {level === LEVELS.CATEGORIE
        ? text.AdminCategories.saveCategory
        : text.AdminCategories.saveSubcategory}
    </button>
  );

  /**
   * Function for print set position button
   */

  const setPositionsButton = () => (
    <button
      id="setPositionButton"
      type="button"
      className={
        !subcategories
          ? classes.setPositionsButton
          : classes.setPositionsButtonSubcategory
      }
      onClick={() => sendSortedArrayHandler()}
    >
      {text.AdminCategories.setPositions}
    </button>
  );

  /**
   * Function for print button who calls updateCategories action
   */

  const updateNamesButton = () => (
    <button
      type="button"
      id="updateNameButton"
      className={classes.updateNamesButton}
      onClick={() => {
        updateCategories(updateCategory);
        setCanOrder(true);
      }}
    >
      {text.save}
    </button>
  );

  const renderTextCategoryChangeOrderEditButton = () => {
    if (canOrder && !subcategories) {
      return text.AdminCategories.edit;
    }
    return !subcategories && text.cancel;
  };

  const renderTextSubcategoryChangeOrderEditButton = () => {
    if (canOrder && subcategories) {
      return text.AdminCategories.editSubcategories;
    }
    return subcategories && text.cancel;
  };

  /**
   * Function for print button who changes into order categories or modify categories
   */
  const changeOrderEditButton = () => {
    let styleForModify;
    if (!canOrder) {
      styleForModify = {
        marginLeft: '10px',
        'min-width': '200px',
        'background-color': 'grey'
      };
    }
    return (
      <button
        id="changeOrderEditButton"
        className={
          !subcategories
            ? classes.changeOrderEditButton
            : classes.changeOrderEditButtonSubcategory
        }
        type="button"
        onClick={() => setCanOrder(!canOrder)}
        style={styleForModify}
      >
        {renderTextCategoryChangeOrderEditButton()}
        {renderTextSubcategoryChangeOrderEditButton()}
      </button>
    );
  };

  return (
    <>
      {section?.order && (
        <>
          {canOrder && setCategories()}
          {changeOrderEditButton()}
          {canOrder ? setPositionsButton() : updateNamesButton()}
          {!subcategories ? (
            <>
              {canOrder ? (
                <>
                  <SortableCategories
                    printCategoriesAndSubcategories={
                      printOrderCategoriesAndSubcategories
                    }
                    onSortEnd={onSortEndCategories}
                    items={items}
                    disabled
                  />
                  <div className="create-category-container">
                    <TextField
                      id="inputCreateCategory"
                      variant="outlined"
                      fullWidth
                      value={newCategory}
                      className={classes.inputCreateCategory}
                      onChange={e => setNewCategory(e.target.value)}
                      placeholder={text.AdminCategories.createNewCategory}
                    />
                    {saveNewCategoryButton(2)}
                  </div>
                </>
              ) : (
                <>{printEditCategoriesAndSubcategories(items)}</>
              )}
            </>
          ) : (
            <>
              {canOrder ? (
                <>
                  <div className="current-category">
                    <span className="current-category__name">
                      {currentCategory.name}
                    </span>
                  </div>
                  <SortableCategories
                    onSortEnd={onSortEndSubcategories}
                    printCategoriesAndSubcategories={
                      printOrderCategoriesAndSubcategories
                    }
                    items={subcategories}
                  />
                  <div className="create-subcategory-container">
                    <TextField
                      id={uuidv4()}
                      variant="outlined"
                      fullWidth
                      value={newCategory}
                      className={classes.inputCreateCategory}
                      onChange={e => setNewCategory(e.target.value)}
                      placeholder={text.AdminCategories.createNewSubcategory}
                    />
                    {saveNewCategoryButton(3)}
                  </div>
                </>
              ) : (
                <>{printEditCategoriesAndSubcategories(subcategories)}</>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

CategoryManage.propTypes = {
  section: PropTypes.objectOf(PropTypes.object()),
  updateCategories: PropTypes.func,
  deleteCategory: PropTypes.func,
  createCategory: PropTypes.func
};

export default CategoryManage;
