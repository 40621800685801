import FaqActionTypes from './faq.types';

const INITIAL_STATE = {
  faqResults: [],
  error: null,
  isLoading: false
};

const faqReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FaqActionTypes.FETCH_FAQ_START:
      return { ...state, isLoading: true };
    case FaqActionTypes.FETCH_FAQ_SUCCESS:
      return { ...state, faqResults: action.payload, error: null };
    case FaqActionTypes.FETCH_FAQ_FAILURE:
      return { ...state, error: action.payload };
    case FaqActionTypes.FETCH_FAQ_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default faqReducer;
