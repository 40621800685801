import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import NoMediaCard from '../noMediaCard/NoMediaCard.jsx';
import { programWithoutMediaCollapsed } from '../../constants/global-constants';

const SearchListNoMedia = ({
  resultsWithoutMedia,
  showMyProgramsNoMedia,
  section
}) => {
  return (
    <>
      <div>
        {resultsWithoutMedia?.length > programWithoutMediaCollapsed &&
        showMyProgramsNoMedia ? (
          // eslint-disable-next-line react/jsx-indent
          <Grid container spacing={1}>
            <NoMediaCard
              options={resultsWithoutMedia?.slice(0, 2)}
              type={section?.type}
            />
          </Grid>
        ) : (
          <>
            <Grid container spacing={1}>
              <NoMediaCard options={resultsWithoutMedia} type={section?.type} />
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({});
SearchListNoMedia.propTypes = {
  resultsWithoutMedia: PropTypes.oneOfType([PropTypes.string]),
  showMyProgramsNoMedia: PropTypes.bool,
  section: PropTypes.oneOfType([PropTypes.string])
};

export default withRouter(connect(mapStateToProps)(SearchListNoMedia));
