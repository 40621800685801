import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import styles from './style.jsx';
import './style.scss';
import ButtonComponent from '../../../components/button/buttonComponent';
import { ROUTES_CONSTANTS } from '../../../constants/route.constants';
import text from '../../../constants/translates';
import { canEdit } from '../../../shared/rights/root-rights.js';
import { ATTRIBUTES } from '../../../shared/rights/roles.js';

const AdminPage = ({ history }) => {
  const classes = makeStyles(styles)();
  return (
    <div>
      <h2 className="admin__title">{text.adminBase.title}</h2>
      <Grid container spacing={10}>
        <Grid item xs={6}>
          {canEdit(true, ATTRIBUTES.BME_USER_WRITE) && (
            <ButtonComponent
              className={classes.button}
              onClick={() => history.push(ROUTES_CONSTANTS.adminRights)}
            >
              {text.adminBase.userManagement}
            </ButtonComponent>
          )}
        </Grid>
        <Grid item xs={6}>
          {canEdit(true, ATTRIBUTES.BME_SECTION_WRITE) && (
            <ButtonComponent
              className={classes.button}
              id="adminCategoriesButton"
              onClick={() => history.push(ROUTES_CONSTANTS.adminCategories)}
            >
              {text.adminBase.categoryManagement}
            </ButtonComponent>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

AdminPage.propTypes = {
  history: PropTypes.func
};

export default withRouter(AdminPage);
