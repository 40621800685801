import React, { useState, useEffect } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { snackBarSeverity } from '../../../constants/ui.constants.jsx';
import styles from './style.jsx';
import text from '../../../constants/translates.jsx';
import {
  CREATION_STATUS,
  snackbarDuration
} from '../../../constants/global-constants.jsx';
import Submenu from '../../../components/subMenu/Submenu.jsx';
import AdminSectionPanel from '../../../components/admin/adminSectionPanel/AdminSectionPanel.jsx';
import CategoryManage from '../../categoryManage/categoryManage.jsx';
import { CreateSectionStart } from '../../../redux/section/createSection/createSectionActions.js';
import { fetchSectionStart } from '../../../redux/section/sections/section.actions.js';
import {
  selectCreatedSection,
  selectCreatedSectionLoading,
  selectCreatedSectionSucceed
} from '../../../redux/section/createSection/createSectionSelector.js';
import { DeleteSectionStart } from '../../../redux/section/deleteSection/deleteSectionActions.js';
import {
  selectDeletedSection,
  selectDeleteSectionLoading
} from '../../../redux/section/deleteSection/deleteSectionSelector.js';
import { EditSectionStart } from '../../../redux/section/editSection/editSectionActions.js';
import {
  selectEditSection,
  selectEditSectionLoading,
  selectEditSectionSucceed
} from '../../../redux/section/editSection/editSectionSelector.js';
import {
  createSectionForm,
  editSectionForm
} from '../../../shared/functions/sectionFunctions.js';
import {
  selectSectionsCount,
  selectSectionsResult
} from '../../../redux/section/sections/section.selectors.js';
import Spinner from '../../../components/spinner/Spinner.jsx';
import DismissableAlert from '../../../components/alert/DismissableAlert.jsx';
import SnackbarAlert from '../../../components/snackBar/SnackbarAlert.jsx';
import {
  updateCategoriesStart,
  deleteCategoryStart,
  createCategoryStart
} from '../../../redux/categoryAdmin/categoryAdmin.actions.js';
import {
  selectDeletedCategory,
  selectUpdatedCategory,
  selectCreatedCategory,
  selectIsLoadingCategory
} from '../../../redux/categoryAdmin/categoryAdmin.selectors.js';
import { canEdit } from '../../../shared/rights/root-rights';
import { ATTRIBUTES } from '../../../shared/rights/roles';
import { selectSearchIsLoading } from '../../../redux/search/search.selectors';
import { fetchSearchResultsStart } from '../../../redux/search/search.actions';

const AdminCategories = ({
  history,
  createdSection,
  createdSectionSucceed,
  editSectionSucceed,
  isLoading,
  fetchCreateSection,
  lastSectionOrder,
  fetchSections,
  fetchDeleteSection,
  sectionDeleted,
  deleteLoading,
  fetchEditSection,
  sectionEdited,
  editSectionLoading,
  updateCategories,
  deleteCategory,
  createCategory,
  deletedCategorySelector,
  updatedCategorySelector,
  createdCategorySelector,
  isLoadingCategory,
  searchIsLoading,
  fetchSearchResults
}) => {
  const classes = makeStyles(styles)();
  const [section, setSection] = useState();
  const [selectedRefCategory, setSelectedRefCategory] = useState();
  const [nameCategory, setNameCategory] = useState();
  const [tempValueCode, setTempValueCode] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [toastseverity, setToastseverity] = useState(false);

  useEffect(() => {
    if (createdSection?.status === CREATION_STATUS.SUCCESS) {
      setSelectedRefCategory(tempValueCode);
      fetchSections();
    }
  }, [createdSection]);

  useEffect(() => {
    setSelectedRefCategory(section?.refSection);
    fetchSections();
  }, [createdCategorySelector, deletedCategorySelector]);

  useEffect(() => {
    if (sectionDeleted || sectionEdited) {
      fetchSections();
    }

    if (sectionEdited) {
      setOpenToast(true);
    }
  }, [sectionDeleted, sectionEdited]);

  useEffect(() => {
    if (updatedCategorySelector) {
      setSelectedRefCategory(section?.refSection);
      fetchSections();
    }
  }, [updatedCategorySelector]);

  useEffect(() => {
    if (createdSectionSucceed) {
      setToastMessage(text.AdminCategories.createdSection);
      setToastseverity(snackBarSeverity.success);
      setOpenToast(true);
    }
  }, [createdSectionSucceed]);

  useEffect(() => {
    if (editSectionSucceed) {
      setToastMessage(text.AdminCategories.editedSection);
      setToastseverity(snackBarSeverity.success);
      setOpenToast(true);
    }
  }, [editSectionSucceed]);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleChangeCategory = category => {
    setSection(category);
    setNameCategory(category?.name);
  };

  const handleChangeName = name => {
    setNameCategory(name);
  };

  const handleSave = image => {
    if (section?.refSection === text.AdminCategories.refNewSection) {
      if (nameCategory.length === 0) {
        return;
      }
      const creationForm = createSectionForm(
        nameCategory,
        lastSectionOrder + 1
      );
      const payload = { creationForm, image };
      setTempValueCode(creationForm.valueCode);
      fetchCreateSection(payload);
    } else {
      const editForm = editSectionForm(nameCategory, section);
      const params = {
        refValue: section.refSection,
        form: editForm,
        image
      };
      fetchEditSection(params);
      setSelectedRefCategory(section.refSection);
    }
  };

  if (!canEdit(true, ATTRIBUTES.BME_SECTION_WRITE)) {
    return <div></div>;
  }

  return (
    <div className="page-container">
      <DismissableAlert />
      <Spinner
        className="spinner"
        isLoading={
          isLoading ||
          deleteLoading ||
          editSectionLoading ||
          isLoadingCategory ||
          searchIsLoading
        }
      />
      <SnackbarAlert
        id="AddedAlert"
        open={openToast}
        severity={toastseverity}
        message={toastMessage}
        handleClose={() => setOpenToast(false)}
        duration={snackbarDuration}
      />
      <div className="page-header">
        <Button
          id="goBackButton"
          variant="outlined"
          color="primary"
          className={classes.returnButton}
          onClick={handleGoBack}
        >
          <ArrowBackIosOutlinedIcon />
          <b className="button__text--paddingLeft">
            {text.programSheet.return}
          </b>
        </Button>
        <span className="title__text">{text.AdminCategories.title}</span>
      </div>
      <div className="body-container">
        <Submenu
          onCategoryChange={handleChangeCategory}
          showNewSectionTab
          forcedSelectSectionByRef={selectedRefCategory}
        />
        <Grid container className={classes.gridContainer}>
          <Grid item xs={3} className={classes.columnSection}>
            <AdminSectionPanel
              section={section}
              onChangeSectionName={name => handleChangeName(name)}
              onDeleteSection={refSection => fetchDeleteSection(refSection)}
              fetchSearchResults={fetchSearchResults}
              onSave={handleSave}
            />
          </Grid>
          <Grid item xs={9} className={classes.column}>
            <CategoryManage
              section={section}
              updateCategories={updateCategories}
              deleteCategory={deleteCategory}
              createCategory={createCategory}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

AdminCategories.propTypes = {
  fetchCreateSection: PropTypes.func,
  createdSection: PropTypes.oneOfType([PropTypes.string]),
  lastSectionOrder: PropTypes.number,
  isLoading: PropTypes.bool,
  fetchSections: PropTypes.func,
  fetchDeleteSection: PropTypes.func,
  sectionDeleted: PropTypes.bool,
  deleteLoading: PropTypes.func,
  fetchEditSection: PropTypes.func,
  sectionEdited: PropTypes.bool,
  editSectionLoading: PropTypes.bool,
  history: PropTypes.func,
  updateCategories: PropTypes.func,
  deleteCategory: PropTypes.func,
  createCategory: PropTypes.func,
  deletedCategorySelector: PropTypes.func,
  updatedCategorySelector: PropTypes.func,
  createdCategorySelector: PropTypes.func,
  isLoadingCategory: PropTypes.func,
  createdSectionSucceed: PropTypes.bool,
  editSectionSucceed: PropTypes.bool,
  searchIsLoading: PropTypes.func,
  fetchSearchResults: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  createdSection: selectCreatedSection,
  createdSectionSucceed: selectCreatedSectionSucceed,
  lastSectionOrder: selectSectionsCount,
  isLoading: selectCreatedSectionLoading,
  sectionDeleted: selectDeletedSection,
  deleteLoading: selectDeleteSectionLoading,
  sectionEdited: selectEditSection,
  editSectionSucceed: selectEditSectionSucceed,
  editSectionLoading: selectEditSectionLoading,
  deletedCategorySelector: selectDeletedCategory,
  updatedCategorySelector: selectUpdatedCategory,
  createdCategorySelector: selectCreatedCategory,
  isLoadingCategory: selectIsLoadingCategory,
  sections: selectSectionsResult,
  searchIsLoading: selectSearchIsLoading
});

const mapDispatchToProps = dispatch => ({
  fetchCreateSection: params => dispatch(CreateSectionStart(params)),
  fetchSections: () => dispatch(fetchSectionStart()),
  fetchDeleteSection: params => dispatch(DeleteSectionStart(params)),
  fetchEditSection: params => dispatch(EditSectionStart(params)),
  updateCategories: params => dispatch(updateCategoriesStart(params)),
  deleteCategory: params => dispatch(deleteCategoryStart(params)),
  createCategory: params => dispatch(createCategoryStart(params)),
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminCategories)
);
