/**
 * Action types of paste media to another program
 */
const PasteMediaActionTypes = {
  FETCH_PASTE_MEDIA_START: 'FETCH_PASTE_MEDIA_START',
  FETCH_PASTE_MEDIA_SUCCESS: 'FETCH_PASTE_MEDIA_SUCCESS',
  FETCH_PASTE_MEDIA_FAILURE: 'FETCH_PASTE_MEDIA_FAILURE',
  FETCH_PASTE_MEDIA_FINISHED: 'FETCH_PASTE_MEDIA_FINISHED'
};

export default PasteMediaActionTypes;
