import moment from 'moment';
import { dateFormat } from '../../constants/global-constants';

export const parseDateDMY = date => {
  return moment(date, dateFormat.DMY);
};

export const parseDate = date => {
  return parseDateDMY(date).format(dateFormat.YMD);
};

export const getNowDate = () => {
  return moment().format(dateFormat.LOCAL_DATE);
};

export const toLocalDate = date => {
  return moment(date).format(dateFormat.LOCAL_DATE);
};
