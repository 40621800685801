import PasteMediaActionTypes from './paste.media.types';

/**
 * Action start for paste media in a program
 * @param {Object} params Parameters for request
 */
export const fetchPasteMediaStart = params => ({
  type: PasteMediaActionTypes.FETCH_PASTE_MEDIA_START,
  payload: params
});

/**
 * Action when success paste media in a program
 * @param {Object} data Response data
 */
export const fetchPasteMediaSuccess = data => ({
  type: PasteMediaActionTypes.FETCH_PASTE_MEDIA_SUCCESS,
  payload: data
});

/**
 * Action when error during paste media in a program
 * @param {Object} error Response error
 */
export const fetchPasteMediaFailure = error => ({
  type: PasteMediaActionTypes.FETCH_PASTE_MEDIA_FAILURE,
  payload: error
});

/**
 * Action when paste media in program finish
 */
export const fetchPasteMediaFinished = () => ({
  type: PasteMediaActionTypes.FETCH_PASTE_MEDIA_FINISHED
});
