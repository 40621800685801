import SectionTypes from './section.types';

export const fetchSectionStart = () => ({
  type: SectionTypes.FETCH_SECTION_START
});

export const fetchSectionSuccess = params => ({
  type: SectionTypes.FETCH_SECTION_SUCCESS,
  payload: params
});

export const fetchSectionFailure = error => ({
  type: SectionTypes.FETCH_SECTION_FAILURE,
  payload: error
});

export const fetchSectionFinished = () => ({
  type: SectionTypes.FETCH_SECTION_FINISHED
});

export const getSectionThumbnailLinkStart = sectionName => ({
  type: SectionTypes.GET_SECTION_THUMBNAIL_LINK_START,
  payload: sectionName
});

export const getSectionThumbnailLinkSuccess = params => ({
  type: SectionTypes.GET_SECTION_THUMBNAIL_LINK_SUCCESS,
  payload: params
});

export const getSectionThumbnailLinkFailure = error => ({
  type: SectionTypes.GET_SECTION_THUMBNAIL_LINK_FAILURE,
  payload: error
});

export const getSectionThumbnailLinkFinished = () => ({
  type: SectionTypes.GET_SECTION_THUMBNAIL_LINK_FINISHED
});
