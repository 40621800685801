import LastMediasTypes from './lastMedias.types';

export const fetchLastMediasStart = data => ({
  type: LastMediasTypes.FETCH_LAST_MEDIAS_START,
  payload: data
});

export const fetchLastMediasSuccess = params => ({
  type: LastMediasTypes.FETCH_LAST_MEDIAS_SUCCESS,
  payload: params
});

export const fetchLastMediasFailure = error => ({
  type: LastMediasTypes.FETCH_LAST_MEDIAS_FAILURE,
  payload: error
});

export const fetchLastMediasFinished = () => ({
  type: LastMediasTypes.FETCH_LAST_MEDIAS_FINISHED
});
