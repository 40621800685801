import { createSelector } from 'reselect';

const selectSearchWithGeoposition = state => state.searchWithGeoposition;

export const selectSearchWithGeopositionResults = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition => searchWithGeoposition.searchWithGeopositionResults
);

export const selectSearchWithGeopositionIsLoading = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition => searchWithGeoposition.isLoading
);

export const selectSearchWithGeopositionResultsWithMedia = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition =>
    searchWithGeoposition.searchWithGeopositionResultsWithMedia
);

export const selectSearchWithGeopositionWithMediaIsLoading = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition => searchWithGeoposition.withMediaIsLoading
);

export const selectSearchWithGeopositionResultsWithoutMedia = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition =>
    searchWithGeoposition.searchWithGeopositionResultsWithoutMedia
);

export const selectSearchWithGeopositionWithoutMediaIsLoading = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition => searchWithGeoposition.withoutMediaIsLoading
);

export const selectSearchWithGeopositionToolbarVisible = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition => searchWithGeoposition?.toolbarParams?.isVisible
);

export const selectSearchWithGeopositionSuggestions = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition => searchWithGeoposition.suggestions
);

export const selectToolbarParams = createSelector(
  [selectSearchWithGeoposition],
  searchWithGeoposition => searchWithGeoposition.toolbarParams
);
