import { put, all, call, takeLatest } from 'redux-saga/effects';
import partnerDetailsActionTypes from './partner-details.types';
import { partnerDetailsBuilder } from './partner-details.builder';
import api from '../../api/apiIntervenants.jsx';
import {
  fetchPartnerDetailsSuccess,
  fetchPartnerDetailsFailure,
  fetchPartnerDetailsFinished
} from './partner-details.actions';

function* searchPartner(partner) {
  try {
    const { data } = yield call(api.getPartnerDetails, partner);
    return yield call(partnerDetailsBuilder, data);
  } catch (error) {
    yield put(fetchPartnerDetailsFailure(error));
    return null;
  }
}

export function* fetchPartnerDetails({ payload: partners }) {
  try {
    const promises = partners.map(partner => searchPartner(partner));
    const data = yield all(promises);
    yield put(
      fetchPartnerDetailsSuccess(
        data.filter(dat => {
          return dat !== null && dat.refRelationShipId !== undefined;
        })
      )
    );
  } catch (error) {
    yield put(fetchPartnerDetailsFailure(error));
  }
  yield put(fetchPartnerDetailsFinished());
}

export function* onFetchPartnerDetailsStart() {
  yield takeLatest(
    partnerDetailsActionTypes.FETCH_PARTNER_DETAILS_START,
    fetchPartnerDetails
  );
}

export function* PartnerDetailsSagas() {
  yield all([call(onFetchPartnerDetailsStart)]);
}
