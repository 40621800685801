import axios from 'axios';
import { INTERVENANTS_API_ROOT as API_ROOT } from './api.conf';
import API_HEADERS from './apiHeaders';
import API_ROUTES_INTERVENANTS from './apiRoutesIntervenants';
import { setRouteFromParam } from '../shared/functions/apiIntervenantsFunctions.js';

class API {
  http;

  constructor() {
    const instance = axios.create({
      baseURL: API_ROOT
    });
    this.http = instance;
  }

  setAutorizationToken = token => {
    if (token) {
      this.http.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  };

  getListOfLegalEntities = params =>
    this.http.get(`${API_ROUTES_INTERVENANTS.legalEntity}`, {
      params
    });

  getLegalEntity = refLegalEntityRelationShipId =>
    this.http.get(
      `${API_ROUTES_INTERVENANTS.legalEntity}/${refLegalEntityRelationShipId}`,
      {}
    );

  postLegalEntity = ({ id, payload }) =>
    this.http.post(
      `${API_ROUTES_INTERVENANTS.legalEntity}/${id}/${API_ROUTES_INTERVENANTS.contacts}`,
      payload
    );

  postLegalEntitySociety = payload =>
    this.http.post(`${API_ROUTES_INTERVENANTS.legalEntity}`, payload);

  getListOfNaturalPersons = params =>
    this.http.get(`${API_ROUTES_INTERVENANTS.naturalperson}`, {
      params
    });

  getPartnerDetails = params =>
    this.http.get(
      `${API_ROUTES_INTERVENANTS.legalEntity}/${params.refLegalEntity}/${API_ROUTES_INTERVENANTS.contacts}/${params.refRelationShipId}`,
      {}
    );

  getPartnerByLegalEntity = params =>
    this.http.get(
      `${API_ROUTES_INTERVENANTS.legalEntity}/${params}/${API_ROUTES_INTERVENANTS.contacts}`,
      {}
    );

  patchModificationPartners = params =>
    this.http.patch(
      `${API_ROUTES_INTERVENANTS.legalEntity}/${params.refLegalEntity}/${API_ROUTES_INTERVENANTS.contacts}/${params.refRelationShipId}`,
      params.partnerPath,
      {
        headers: {
          'Content-Type': API_HEADERS.contentType.applicationJsonPath
        }
      }
    );

  getInternalMembers = params => {
    const paramURL = setRouteFromParam(params);

    if (paramURL === undefined) {
      return [];
    }
    return this.http.get(
      `${API_ROUTES_INTERVENANTS.internalMembers}${paramURL}`
    );
  };

  searchExternalMembers = params =>
    this.http.get(`${API_ROUTES_INTERVENANTS.search}?search=${params}`);
}

export default new API();
