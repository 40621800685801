import { createSelector } from 'reselect';

const selectFiles = state => state.fileUploader;

export const selectedFiles = createSelector(
  [selectFiles],
  fileUploader => fileUploader.files
);

export const selectedFilesIsLoading = createSelector(
  [selectFiles],
  fileUploader => fileUploader.isLoading
);

export const selectedFilesError = createSelector(
  [selectFiles],
  fileUploader => fileUploader.error
);

export const selectedFilesSucceed = createSelector(
  [selectFiles],
  fileUploader => fileUploader.succeed
);
