export default {
  progress: {
    width: '550px',
    margin: '0 auto'
  },
  card: {
    width: '257px',
    background: '#F5F6FA',
    border: '1px solid #DBE2F4',
    boxShadow: '0px 8px 24px rgba(219, 226, 244, 0.5)',
    boxSizing: 'border-box',
    marginRight: '10px',
    marginTop: '20px',
    height: '220px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title_name: {
    textAlign: 'center',
    marginTop: '2%',
    marginBottom: '1%',
    fontSize: '14px'
  }
};
