export default {
  formInput: {
    width: '272px',
    background: '#FFFFFF',
    boxShadow: '0px 8px 24px rgba(219, 226, 244, 0.5)',
    borderRadius: '10px',
    marginBottom: '10px'
  },
  sendToButton: {
    background: '#C82832',
    borderRadius: '10px',
    width: '90%',
    '&:disabled': {
      border: '1px solid #555555',
      color: '#BF7703',
      background: '#C82832',
      opacity: 0.5
    }
  }
};
