/* eslint-disable no-else-return */
import { saveAs } from 'file-saver';
import {
  MEDIA_TYPE,
  operation,
  PATCH_PATHS,
  PROGRAM_TYPE,
  imageTypes,
  fileTypes
} from '../../constants/global-constants';
import waitLogo from '../../assets/images/hour-glass_small.png';
import warningLogo from '../../assets/images/warningLogo.png';
import pdfThumbnail from '../../assets/images/pdfThumbnail.png';
import { mediaStatus } from '../../constants/media-status';

export const basicElementConstructor = (op, path) => {
  const element = {};
  element.op = op;
  element.path = path;

  return element;
};

export const patchConstructor = (op, path, value) => {
  const element = basicElementConstructor(op, path);
  if (path.includes('tags')) {
    element.value = { refTag: value };
  } else if (value !== null) {
    element.value = value;
  }
  return element;
};

export const handleMediaModification = (
  state,
  value,
  mediaModification,
  path
) => {
  if (state !== value)
    if (!value) {
      mediaModification.push(
        patchConstructor(operation.addOperation, `/${path}`, state)
      );
    } else if (!state) {
      mediaModification.push(
        basicElementConstructor(operation.removeOperation, `/${path}`)
      );
    } else {
      mediaModification.push(
        patchConstructor(operation.replaceOperation, `/${path}`, state)
      );
    }
};

export const handleMediaModificationSupport = (
  state,
  value,
  mediaModification,
  path
) => {
  if (!state && value?.length === 0) {
    return;
  }
  if (state?.refValue !== value?.refValue) {
    if (!value || value?.length === 0) {
      mediaModification.push(
        patchConstructor(operation.addOperation, `/${path}`, state)
      );
    } else if (!state) {
      mediaModification.push(
        basicElementConstructor(operation.removeOperation, `/${path}`)
      );
    } else {
      mediaModification.push(
        patchConstructor(
          operation.replaceOperation,
          `/${PATCH_PATHS.supportPosition}`,
          state
        )
      );
    }
  }
};

export const handleMediaCategoryModification = (
  state,
  value,
  mediaModification,
  path,
  listCode
) => {
  let refValue = state?.id;
  if (path === 'subCategory') {
    refValue = state?.refSubCategory;
  }

  if (refValue !== value) {
    const addCategoryValue = {};
    if (refValue) {
      addCategoryValue.listCode = listCode;
      addCategoryValue.level = (state?.level).toString();
      addCategoryValue.refValue = refValue;
    }
    if (!value) {
      mediaModification.push(
        patchConstructor(operation.addOperation, `/${path}`, addCategoryValue)
      );
    } else if (!refValue) {
      mediaModification.push(
        basicElementConstructor(operation.removeOperation, `/${path}`)
      );
    } else {
      mediaModification.push(
        patchConstructor(
          operation.replaceOperation,
          `/${path}`,
          addCategoryValue
        )
      );
    }
  }
};

// Method for get the name of the nomenclature category from the ref category from the BME database
export const getCategoryName = (currentCategories, refCategory) => {
  return currentCategories?.filter(
    currentCategory => currentCategory.refCategory === refCategory
  )[0]?.shortLabel;
};

// Method for get the name of the nomenclature subcategory from the ref subcategory from the BME database
export const getSubCategoryName = (
  currentCategories,
  refCategory,
  refSubCategory
) => {
  return currentCategories
    ?.filter(currentCategory => currentCategory.refCategory === refCategory)[0]
    ?.subCategory?.filter(
      currentSubcategory => currentSubcategory.refSubCategory === refSubCategory
    )[0]?.shortLabel;
};

// Method for get section name from the section ref
export const getSectionName = (sections, refSection) => {
  return sections?.filter(section => section.refSection === refSection)[0]
    ?.shortLabel;
};

// Method for download medias from his blob
export const downloadMedia = (data, media) => {
  const fileExt = data?.data?.type?.split('/').pop();
  const file = new File([data.data], `media_${Date.now()}.${fileExt}`);
  let mediaFound;
  if (data?.data?.type === fileTypes[0]) {
    mediaFound = media.find(me => {
      return data.config.url.includes(me?.mediaDetails?.refMedia);
    });

    saveAs(
      file,
      mediaFound?.nameExt !== undefined
        ? mediaFound?.nameExt
        : mediaFound?.mediaDetails?.nameExt
    );
  } else {
    saveAs(file, media?.nameExt, {
      autoBom: true
    });
  }
};

/**
 * Function for know the image to show depending the media status
 */
export const getThumbnail = media => {
  if (
    media?.status === mediaStatus.ready ||
    media?.status === mediaStatus.topublish ||
    media?.status === mediaStatus.published ||
    media?.status === mediaStatus.toUnpublished
  ) {
    if (media?.thumbnailUrl !== undefined) {
      return media?.thumbnailUrl;
    } else if (media?.thumbnail !== undefined) {
      return media?.thumbnail;
    } else {
      return media?.mediaURL;
    }
  } else if (
    media?.status === mediaStatus.upload ||
    media?.status === mediaStatus.gedPending ||
    media?.status === mediaStatus.gedOk ||
    media?.status === mediaStatus.transform
  ) {
    return waitLogo;
  } else if (
    media?.status === mediaStatus.errorCodec ||
    media?.status === mediaStatus.errorResize ||
    media?.status === mediaStatus.errorQualitySize ||
    media?.status === mediaStatus.errorGed ||
    media?.status === mediaStatus.error ||
    media?.status === mediaStatus.errorUploadMediaNotFound
  ) {
    return warningLogo;
  }
  return null;
};

/**
 * Function for know the image to show depending the media status for lastmedias
 */
export const getpreview = (status, type) => {
  if (type === MEDIA_TYPE.document) {
    return pdfThumbnail;
  } else {
    if (
      status === mediaStatus.ready ||
      status === mediaStatus.topublish ||
      status === mediaStatus.published ||
      status === mediaStatus.toUnpublished
    ) {
      return true;
    } else if (
      status === mediaStatus.upload ||
      status === mediaStatus.gedPending ||
      status === mediaStatus.gedOk ||
      status === mediaStatus.transform
    ) {
      return waitLogo;
    } else if (
      status === mediaStatus.errorCodec ||
      status === mediaStatus.errorResize ||
      status === mediaStatus.errorQualitySize ||
      status === mediaStatus.errorGed ||
      status === mediaStatus.error ||
      status === mediaStatus.errorUploadMediaNotFound
    ) {
      return warningLogo;
    }
    return null;
  }
};

/**
 * Function for join the programs with media and without media
 */
export const getPrograms = searchProgramResults => {
  if (
    searchProgramResults?.results?.withoutMedia?.length > 0 ||
    searchProgramResults?.results?.withMedia?.length > 0
  ) {
    let programsWithMedia;
    const programList = searchProgramResults?.results?.withoutMedia;

    if (searchProgramResults?.results?.withMedia?.length > 0) {
      programsWithMedia = searchProgramResults?.results?.withMedia?.map(
        program => {
          return program.match;
        }
      );
    }
    if (programsWithMedia === undefined) {
      return programList;
    }
    return programList.concat(programsWithMedia);
  }
  return [];
};

/**
 * Function to set the title of the media
 */
export const getMediaTitle = (
  media,
  section,
  currentProgram,
  programDetails,
  currentCleoProgram,
  getProgFromState
) => {
  // Case media from a section
  if (media?.currentSection || media?.section?.refSection) {
    return `${media?.currentSection || section?.name}`;
  }

  // Case media from a program
  if (currentProgram?.section?.refSection) {
    if (currentProgram?.section?.refSection === PROGRAM_TYPE.IE_PROGRAM_NAME) {
      if (programDetails?.programName) {
        return `${programDetails?.programName}`;
      }
      return `${programDetails?.name}`;
    }

    const prog = getProgFromState();
    if (
      programDetails?.name ||
      currentCleoProgram?.name ||
      prog?.programName ||
      prog?.name
    ) {
      if (imageTypes.includes(prog?.mediaContentType)) {
        if (
          programDetails?.name !== undefined &&
          currentCleoProgram?.name !== undefined
        ) {
          return `${programDetails?.code ||
            currentCleoProgram?.code ||
            prog?.code} - ${programDetails?.name || currentCleoProgram?.name}`;
        }

        return `${programDetails?.code ||
          currentCleoProgram?.code ||
          prog?.code}`;
      }

      return `${programDetails?.code ||
        currentCleoProgram?.code ||
        prog?.code} - ${programDetails?.name ||
        currentCleoProgram?.name ||
        prog?.programName ||
        prog?.name}`;
    }

    return `${programDetails?.code || currentCleoProgram?.code || prog?.code} `;
  }

  // Case media from a folder
  if (media?.folder?.refFolder) {
    if (programDetails?.code) {
      return `${programDetails?.code} - ${programDetails?.name}`;
    }

    return `${programDetails?.name}`;
  }
  return '';
};

export const getFormatFile = file => {
  return file.name
    .split('.')
    .pop()
    .toLowerCase();
};
