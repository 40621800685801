const mediaDetailsActionTypes = {
  FETCH_MEDIA_DETAILS_START: 'FETCH_MEDIA_DETAILS_START',
  FETCH_MEDIA_DETAILS_SUCCESS: 'FETCH_MEDIA_DETAILS_SUCCESS',
  FETCH_MEDIA_DETAILS_FAILURE: 'FETCH_MEDIA_DETAILS_FAILURE',
  FETCH_MEDIA_DETAILS_FINISHED: 'FETCH_MEDIA_DETAILS_FINISHED',
  FETCH_SUPPORT_START: 'FETCH_SUPPORT_START',
  FETCH_SUPPORT_SUCCESS: 'FETCH_SUPPORT_SUCCESS',
  FETCH_SUPPORT_FAILURE: 'FETCH_SUPPORT_FAILURE',
  FETCH_SUPPORT_FINISH: 'FETCH_SUPPORT_FINISH'
};

export default mediaDetailsActionTypes;
