import { createSelector } from 'reselect';

const selectShare = state => state.share;

export const selectAppMember = createSelector(
  [selectShare],
  share => share?.appMember
);

export const selectInternalMembersResult = createSelector(
  [selectShare],
  share => share?.internalMembersResult
);

export const selectIsLoadingInternalMembers = createSelector(
  [selectShare],
  share => share.isLoadingInternalMembers
);

export const selectExternalMembersResult = createSelector(
  [selectShare],
  share => share?.externalMembersResult
);

export const selectShareResult = createSelector(
  [selectShare],
  share => share?.results
);

export const selectShareError = createSelector(
  [selectShare],
  share => share?.shareError
);
