import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import './style.scss';
import { withRouter } from 'react-router-dom';
import texts from '../../../constants/translates';
import { ROUTES_CONSTANTS } from '../../../constants/route.constants';
import { showAdminRoot } from '../../../shared/rights/root-rights';
import { selectUserInfo } from '../../../redux/user-info/user-info.selectors';

const Footer = ({ history, userInfo }) => {
  const [showAdminButton, setShowAdminButton] = useState(false);

  useEffect(() => {
    setShowAdminButton(showAdminRoot());
  }, [userInfo]);

  const handleGoGeneralConditionsPage = () => {
    history.push(ROUTES_CONSTANTS.generalConditions);
  };

  const handleGoFaqPage = () => {
    history.push(ROUTES_CONSTANTS.faq);
  };

  const handleGoAdminPage = () => {
    history.push(ROUTES_CONSTANTS.admin);
  };

  return (
    <div className="footer__container">
      <div className="footer__text">
        <Grid container spacing={3}>
          <Grid item xs>
            <span
              onClick={handleGoGeneralConditionsPage}
              role="link"
              onKeyPress={handleGoGeneralConditionsPage}
              tabIndex={0}
              className="footer__span"
              id="copyrightSpan"
            >
              {texts.formatString(texts.footer.copyright, {
                year: new Date().getFullYear()
              })}
            </span>
          </Grid>
          <Grid item xs className="footer__text--alignCenter">
            <span
              onClick={handleGoFaqPage}
              role="link"
              onKeyPress={handleGoFaqPage}
              tabIndex={0}
              className="footer__span"
              id="helpSpan"
            >
              {texts.footer.help}
            </span>
          </Grid>
          <Grid item xs className="footer__text--alignRight">
            {showAdminButton && (
              <span
                onClick={handleGoAdminPage}
                role="link"
                onKeyPress={handleGoAdminPage}
                tabIndex={0}
                className="footer__span"
                id="adminSpan"
              >
                {texts.footer.admin}
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  history: PropTypes.func,
  userInfo: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

const mapStateToProps = createStructuredSelector({
  userInfo: selectUserInfo
});

export default withRouter(connect(mapStateToProps, null)(Footer));
