import { createSelector } from 'reselect';

const selectPasteMedia = state => state.pasteMediaResult;

/**
 * Selector of paste media data response from state
 */
export const selectPasteMediaResult = createSelector(
  [selectPasteMedia],
  pasteMediaResult => pasteMediaResult.result
);

/**
 * Selector of paste media isLoading from state
 */
export const selectPasteMediaIsLoading = createSelector(
  [selectPasteMedia],
  pasteMediaResult => pasteMediaResult.isLoading
);

/**
 * Selector of paste media error from state
 */
export const selectPasteMediaError = createSelector(
  [selectPasteMedia],
  pasteMediaResult => pasteMediaResult.error
);

/**
 * Selector of paste media succeed from state
 */
export const selectPasteMediaSucceed = createSelector(
  [selectPasteMedia],
  pasteMediaResult => pasteMediaResult.succeed
);

/**
 * Selector of target paste media program from state
 */
export const selectPasteProgram = createSelector(
  [selectPasteMedia],
  pasteMediaResult => pasteMediaResult.params?.program
);
