export default {
  title: {
    fontSize: 14,
    fontFamily: 'Avenir Next LT Pro'
  },
  subTitle: {
    fontSize: 10,
    fontFamily: 'Avenir Next LT Pro'
  },
  button: {
    color: '#000000',
    width: '280px',
    border: '1.5px solid #C82832',
    background: '#C82832',
    float: 'right',
    '&:hover': {
      border: '1px solid #BF7703',
      color: '#BF7703',
      background: '#b30000'
    }
  },
  buttonText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    color: '#FFFFFF'
  },
  buttonDisplay: {
    border: '1px solid #555555',
    color: '#BF7703',
    background: '#C82832',
    opacity: 0.5,
    '&:hover': {
      background: '#C82832',
      opacity: 0.5
    }
  },

  folderIcon: {
    fontSize: '80px'
  },

  returnButton: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '30px'
  }
};
