import React from 'react';
import PropTypes from 'prop-types';
import { PDFReader } from 'reactjs-pdf-view';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { PROGRAM_TYPE } from '../../constants/global-constants';
import styles from './style.jsx';
import './style.scss';

const ProgramCard = ({
  programRef,
  name,
  city,
  totalImages,
  totalVideos,
  totalDocuments,
  mediaURL,
  code,
  section,
  thumbnail,
  loading
}) => {
  const style = makeStyles(styles)();
  const getLabel = (codeLabel, nameLabel) => {
    if (codeLabel && section?.refSection !== PROGRAM_TYPE.IE_PROGRAM_NAME) {
      if (nameLabel === undefined) {
        return `${codeLabel}`;
      }
      return `${codeLabel} - ${nameLabel}`;
    }
    return `${nameLabel}`;
  };

  return (
    <>
      <Card className={style.card} key={programRef}>
        <CardActionArea>
          {loading ? (
            <CardContent className={style.cardContentLoader}>
              <CircularProgress />
            </CardContent>
          ) : (
            <>
              {thumbnail.toUpperCase().includes('.PDF?') ? (
                <CardContent className={style.cardPdf}>
                  <PDFReader url={thumbnail} scale={0.24} />
                </CardContent>
              ) : (
                <CardMedia
                  component="img"
                  alt=""
                  height="220"
                  crossOrigin="anonymous"
                  image={thumbnail || mediaURL}
                  title={name}
                />
              )}
            </>
          )}
          <CardContent className={style.cardContent}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={`${style.title} card__program`}
                  gutterBottom
                >
                  {getLabel(code, name)}
                </Typography>
                <Typography className={style.subTitle} gutterBottom>
                  {city?.name}
                </Typography>
              </Grid>
              <Grid item xs={7} />
              <Grid className={style.icons} item xs={5}>
                <>
                  <div className={style.icon}>
                    <CameraAltOutlinedIcon />
                    <span className="program-card__total-images">
                      {totalImages}
                    </span>
                  </div>
                  <div className={style.icon}>
                    <VideocamOutlinedIcon />
                    <span className="program-card__total-videos">
                      {totalVideos}
                    </span>
                  </div>
                  <div className={style.icon}>
                    <InsertDriveFileOutlinedIcon />
                    <span className="program-card__total-videos">
                      {totalDocuments !== undefined ? totalDocuments : 0}
                    </span>
                  </div>
                </>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

ProgramCard.propTypes = {
  name: PropTypes.string,
  city: PropTypes.string,
  totalImages: PropTypes.number,
  totalVideos: PropTypes.number,
  totalDocuments: PropTypes.number,
  programRef: PropTypes.string,
  mediaURL: PropTypes.string,
  code: PropTypes.string,
  thumbnail: PropTypes.string,
  loading: PropTypes.bool,
  section: PropTypes.string
};

export default ProgramCard;
