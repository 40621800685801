import programDetailsActionTypes from './program-details.types';

const INITIAL_STATE = {
  programDetails: [],
  error: null,
  isLoading: false
};

const programDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case programDetailsActionTypes.FETCH_PROGRAM_DETAILS_START:
      return { ...state, isLoading: true };
    case programDetailsActionTypes.FETCH_PROGRAM_DETAILS_SUCCESS:
      return { ...state, programDetails: action.payload, error: null };
    case programDetailsActionTypes.FETCH_PROGRAM_DETAILS_FAILURE:
      return { ...state, error: action.payload };
    case programDetailsActionTypes.FETCH_PROGRAM_DETAILS_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default programDetailsReducer;
