import { put, all, call, takeLatest } from 'redux-saga/effects';
import CreateFolder from './createFolderTypes';
import api from '../../api/api.jsx';
import {
  sendCreateFolderDataSuccess,
  sendCreateFolderDataFailure,
  sendCreateFolderDataFinished
} from './createFolderActions';

export function* createFolder({ payload: form }) {
  try {
    const { data } = yield call(api.postCreateFolder, form);
    yield put(sendCreateFolderDataSuccess(data));
  } catch (error) {
    yield put(sendCreateFolderDataFailure(error));
  }
  yield put(sendCreateFolderDataFinished());
}

export function* onCreateFolderStart() {
  yield takeLatest(CreateFolder.SEND_CREATE_FOLDER_START, createFolder);
}

export function* createFolderSagas() {
  yield all([call(onCreateFolderStart)]);
}
