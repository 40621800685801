export default {
  saveButton: {
    background: '#C82832',
    borderRadius: '10px',
    width: '342px',
    height: '48px'
  },
  cancelButton: {
    background: '#4E5360',
    borderRadius: '10px',
    width: '117px',
    height: '48px',
    marginRight: '10px'
  }
};
