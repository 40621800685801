import { put, all, call, takeLatest } from 'redux-saga/effects';
import FaqActionTypes from './faq.types';
import api from '../../api/api.jsx';
import {
  fetchFaqSuccess,
  fetchFaqFailure,
  fetchFaqFinished
} from './faq.actions';

export function* fetchFaq() {
  try {
    const { data } = yield call(api.getFaq);
    const dataBuilded = JSON.stringify(data).toString();
    yield put(fetchFaqSuccess(dataBuilded));
  } catch (error) {
    yield put(fetchFaqFailure(error));
  }
  yield put(fetchFaqFinished());
}

export function* onFetchFaqStart() {
  yield takeLatest(FaqActionTypes.FETCH_FAQ_START, fetchFaq);
}

export function* faqSagas() {
  yield all([call(onFetchFaqStart)]);
}
