import { put, all, call, takeLatest } from 'redux-saga/effects';
import PartnerListTypes from './list.partner.types';
import api from '../../api/api.jsx';
import {
  fetchPartnerListFailure,
  fetchPartnerListFinished,
  fetchPartnerListSuccess
} from './list.partner.actions';
import { partnerBuilder } from '../../shared/builders/partnerFromBaseMedia.builder';

export function* PartnerListResults({ payload: params }) {
  try {
    const { data } = yield call(api.getPartnerList, params);
    const dataBuilder = yield call(partnerBuilder, data);
    yield put(fetchPartnerListSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchPartnerListFailure(error));
  }
  yield put(fetchPartnerListFinished());
}

export function* onFetchPartnerListStart() {
  yield takeLatest(
    PartnerListTypes.FETCH_PARTNER_LIST_START,
    PartnerListResults
  );
}

export function* PartnerListSagas() {
  yield all([call(onFetchPartnerListStart)]);
}
