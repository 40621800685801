import ProgramListCleoTypes from './programListCleoTypes';

const INITIAL_STATE = {
  results: null,
  error: null,
  isLoading: false
};

const programListCleoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_START:
      return { ...state, isLoading: true };
    case ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_SUCCESS:
      return { ...state, results: action.payload, error: null };
    case ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_FAILURE:
      return { ...state, error: action.payload };
    case ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default programListCleoReducer;
