import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { createStructuredSelector } from 'reselect';
import SearchList from '../../components/SearchList/SearchList';
import NoMediaCard from '../../components/noMediaCard/NoMediaCard';
import Filter from '../../components/filter/Filter';
import text from '../../constants/translates';
import { fetchSearchResultsStart } from '../../redux/search/search.actions';
import {
  selectSearchIsLoading,
  selectSearchResults
} from '../../redux/search/search.selectors';
import Spinner from '../../components/spinner/Spinner.jsx';
import './style.scss';
import { facets } from '../../constants/global-constants';

const SearchResultsList = ({
  fetchSearchResults,
  isLoading,
  currentResults
}) => {
  const searchParams = useParams();
  const [searchResults, setSearchResults] = useState({
    withoutMedia: [],
    withMedia: []
  });
  const history = useHistory();

  const [params, setParams] = useState({});
  const [pages, setPages] = useState({
    current: 1,
    total: 1,
    loading: false
  });

  useEffect(() => {
    // mover a fitros y ver params de la url searchkey
    let newParams = {
      ...params,
      text: searchParams?.text
    };
    if (searchParams?.refFilter !== text.homeBannerContainer.refAll) {
      newParams = {
        ...newParams,
        section: searchParams?.refFilter
      };
    }
    delete newParams.cursor;
    setPages({ current: 1, total: 1 });
    setParams(newParams);
    fetchSearchResults(newParams);
  }, [searchParams.text, searchParams.refFilter]);

  useEffect(() => {
    let noMedia = [];
    if (pages?.current === 1) {
      noMedia = currentResults?.results?.withoutMedia;
    } else {
      noMedia = searchResults?.withoutMedia?.concat(
        currentResults?.results?.withoutMedia
      );
    }
    setSearchResults({ ...currentResults?.results, withoutMedia: noMedia });
    const pageInfo = {
      ...pages,
      total: currentResults?.page?.totalPages,
      loading: false
    };
    setPages(pageInfo);
  }, [currentResults]);

  const fetchMoreData = () => {
    const newParams = {
      ...params,
      cursor: currentResults?.page?.nextCursor
    };

    setPages({ ...pages, current: pages.current + 1, loading: true });
    setParams(newParams);
    fetchSearchResults(newParams);
  };

  const handleFilterChanged = filterList => {
    const filtersString = [];
    let newParams = params;
    if (filterList.length > 0) {
      filterList.forEach((filter, index) => {
        filtersString[index] = `"${filter?.name}":"${filter?.value}"`;
      });
      newParams = {
        ...newParams,
        filters: filtersString
      };
    } else {
      delete newParams.filters;
    }
    delete newParams.cursor;
    setPages({ current: 1, total: 1 });
    setParams(newParams);
    fetchSearchResults(newParams);
  };

  const getWithMediaCount = () => {
    return (
      currentResults?.page?.totalElements - searchResults?.withoutMedia?.length
    );
  };

  return (
    <>
      <Spinner isLoading={isLoading && pages.current === 1} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Filter
            filterTree={currentResults?.facets?.filter(element =>
              facets.includes(element?.name)
            )}
            onFilterChanged={handleFilterChanged}
            history={history}
          />
        </Grid>
        <Grid item xs={9}>
          {currentResults?.page?.totalElements > 0 ? (
            <>
              {searchResults?.withoutMedia?.length > 0 && (
                <>
                  <h1 className="title-results">
                    {text.resultsSearchPage.titleProgramWithoutMedia}
                  </h1>
                  <span className="title-results__count">
                    {`${searchResults?.withoutMedia?.length || 0} ${
                      searchResults?.withoutMedia?.length > 1
                        ? text.programListPage.results
                        : text.programListPage.result
                    }`}
                  </span>
                  <Grid container spacing={1}>
                    <NoMediaCard options={searchResults?.withoutMedia} />
                  </Grid>
                </>
              )}
              <h1 className="title-results">
                {text.resultsSearchPage.titlePage}
                {text.space}“{searchParams?.text}”
              </h1>
              <span className="title-results__count">
                {`${getWithMediaCount() || 0} ${
                  getWithMediaCount() > 1
                    ? text.programListPage.results
                    : text.programListPage.result
                }`}
              </span>
              <Grid container spacing={1}>
                <SearchList
                  options={searchResults?.withMedia}
                  fetchMoreData={fetchMoreData}
                  currentPage={pages?.current}
                  totalPages={pages?.total}
                  isLoading={isLoading || pages?.loading}
                />
              </Grid>{' '}
            </>
          ) : (
            <h1 className="title-no-results">
              {text.resultsSearchPage.noResults}
            </h1>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentResults: selectSearchResults,
  isLoading: selectSearchIsLoading
});

const mapDispatchToProps = dispatch => ({
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params))
});

SearchResultsList.propTypes = {
  isLoading: PropTypes.bool,
  fetchSearchResults: PropTypes.func,
  currentResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsList);
