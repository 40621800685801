import ReorganizeMedias from './reorganize.types.js';

const INITIAL_STATE = {
  response: null,
  error: null,
  isLoading: false
};

const ReorganizeMediasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReorganizeMedias.REORGANIZE_MEDIAS_START:
      return { ...state, isLoading: true };

    case ReorganizeMedias.REORGANIZE_MEDIAS_SUCCESS:
      return { ...state, response: action.payload, error: null };

    case ReorganizeMedias.REORGANIZE_MEDIAS_FAILURE:
      return { ...state, error: action.payload };

    case ReorganizeMedias.REORGANIZE_MEDIAS_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default ReorganizeMediasReducer;
