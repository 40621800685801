import { createSelector } from 'reselect';

const deleteMedias = state => state.deleteMedia;

export const selectDeleteMediaIsLoading = createSelector(
  [deleteMedias],
  deleteMedia => deleteMedia.isLoading
);

export const selectDeleteMediaError = createSelector(
  [deleteMedias],
  deleteMedia => deleteMedia.error
);

export const selectDeleteMediaSucceed = createSelector(
  [deleteMedias],
  deleteMedia => deleteMedia.succeed
);
