import { put, all, call, takeLatest } from 'redux-saga/effects';
import thumbnailsTypes from './thumbnailsTypes';
import api from '../../api/api.jsx';
import {
  getThumbnailsSuccess,
  getThumbnailsFailure,
  getThumbnailsFinished,
  getSingleThumbnailsSuccess,
  getSingleThumbnailsFailure,
  getSingleThumbnailsFinished
} from './thumbnailsActions';

export function* getThumbnail(thumbnail) {
  try {
    const result = yield call(api.getMediaThumbnail, thumbnail.thumbnailUrl);
    return {
      thumbnail: result.headers.location,
      thumbnailApiUrl: thumbnail.thumbnailUrl
    };
  } catch (error) {
    return error;
  }
}

export function* getThumbnails({ payload: thumbnails }) {
  try {
    const promises = thumbnails.map(thumbnail => getThumbnail(thumbnail));
    const data = yield all(promises);
    yield put(
      getThumbnailsSuccess(data?.filter(x => x.thumbnail !== undefined))
    );
  } catch (error) {
    yield put(getThumbnailsFailure(error.response?.data));
  }
  yield put(getThumbnailsFinished());
}

export function* onGetThumbnailsStart() {
  yield takeLatest(thumbnailsTypes.GET_THUMBNAILS_START, getThumbnails);
}

export function* getSingleThumbnails({ payload: thumbnail }) {
  try {
    const data = yield call(getThumbnail, thumbnail);
    yield put(getSingleThumbnailsSuccess(data));
  } catch (error) {
    yield put(getSingleThumbnailsFailure(error.response?.data));
  }
  yield put(getSingleThumbnailsFinished());
}

export function* onGetSingleThumbnailsStart() {
  yield takeLatest(
    thumbnailsTypes.GET_SINGLE_THUMBNAILS_START,
    getSingleThumbnails
  );
}

export function* getThumbnailsSagas() {
  yield all([call(onGetThumbnailsStart), call(onGetSingleThumbnailsStart)]);
}
