import FormatTypes from './format.types';

const INITIAL_STATE = {
  result: [],
  error: null,
  isLoading: false,
  responseFormatImage: null,
  isLoadingFormatImage: false
};

const FormatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FormatTypes.FETCH_FORMAT_START:
      return { ...state, isLoading: true };
    case FormatTypes.FETCH_FORMAT_SUCCESS:
      return { ...state, result: action.payload, error: null };
    case FormatTypes.FETCH_FORMAT_FAILURE:
      return { ...state, error: action.payload };
    case FormatTypes.FETCH_FORMAT_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default FormatReducer;
