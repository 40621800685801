const societyBuilder = naturalPersons => {
  return naturalPersons.result.map(item => {
    return {
      refNaturalPerson: item.refNaturalPerson,
      firstName: item.firstName,
      lastName: item.lastName,
      organizationalUnitVisibility: item.organizationalUnitVisibility,
      status: item.status,
      telephone: item.locationInformation.telephone,
      email: item.locationInformation.email
    };
  });
};

export function naturalPersonsBuilder(data) {
  return societyBuilder(data);
}
