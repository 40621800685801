export default {
  returnButton: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '30px'
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 88px',
    background: '#C82832',
    borderRadius: '10px',
    color: '#F5F6FA',
    marginLeft: 'auto'
  },
  colorRed: {
    color: '#C82832'
  },
  colorOrange: {
    color: '#BF7703'
  }
};
