import CopyProgramListTypes from './copy.program.list.types';

/**
 * Action to start get api program list
 * @param {Object} params Action params
 */
export const fetchCopyProgramListStart = params => ({
  type: CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_START,
  payload: params
});

/**
 * Action on get api data success
 * @param {Object} data response data
 */
export const fetchCopyProgramListSuccess = data => ({
  type: CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_SUCCESS,
  payload: data
});

/**
 * Action on get api data error
 * @param {Object} error Error notification
 */
export const fetchCopyProgramListFailure = error => ({
  type: CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_FAILURE,
  payload: error
});

/**
 * Action when get api has finished
 */
export const fetchCopyProgramListFinished = () => ({
  type: CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_FINISHED
});
