export const ROUTES_CONSTANTS = {
  home: '/',
  mediaSummary: '/program/summary/:medias',
  mediaOrder: '/program/medias-order/:refProgram',
  mediaSummaryAdded: '/program/summary/:medias/:items',
  mediaSummaryBase: '/program/summary/',
  folders: '/program/summary/:medias/folder/:reportage',
  foldersLink: '/folder/',
  categories: '/category/:category',
  categoriesAdded: '/category/:category/:items',
  categoriesSearch: '/category/search/:category/:searchText',
  categoriesSearchBase: '/category/search/',
  categoriesLink: '/category/',
  programListCategory: '/programlist/:category',
  programListCategorySearch: '/programlist/:category/:text',
  filesUploader: '/files-uploader/:refProgram',
  filesUploaderType: '/files-uploader/:refProgram/:type',
  filesUploaderBase: '/files-uploader/',
  filesThematiqueUploader: '/files-thematiques-uploader/:refSection',
  filesThematiqueUploaderBase: '/files-thematiques-uploader/',
  filesFolderUploader:
    '/program/summary/:medias/files-folder-uploader/:refFolder',
  filesFolderUploaderBase: '/files-folder-uploader/',
  search: '/search/:text/:refFilter',
  searchBase: '/search/',
  programSumaryId: '/program/sumary/:id',
  programCategorisation: '/media-categorisation/:refProgram',
  programCategorisationBase: '/media-categorisation/',
  sectionCategorisation: '/media-thematique-categorisation/:refSection',
  sectionCategorisationBase: '/media-thematique-categorisation/',
  folderCategorisation: '/media-reportage-categorisation/:refFolder',
  folderCategorisationBase: '/media-reportage-categorisation/',
  mediaDelete: '/media-modification/:id/:type/:isDelete',
  mediaUpdate: '/media-modification/:id/:type',
  mediaModificationBase: '/media-modification/',
  programDetailLink: '/programlist/',
  createProgram: '/create-program/:programType',
  createProgramIE: '/create-program/IE',
  modifyProgram: '/modify-program/:programType/:refProgram',
  modifyProgramBase: '/modify-program/',
  sections: '/sections',
  programMediaOrder: '/program/medias-order/',
  admin: '/admin',
  adminCategories: '/admin/categories',
  adminRights: '/admin/rights',
  mode: '/mode/:mode/',
  faq: '/faq',
  generalConditions: '/conditions-generales',
  partner: '/partner/:user',
  error: '/error/:errorNumber',
  vitrineMediatheque: '/vitrine-mediatheque',
  vitrineProgramSheetBase: '/vitrine/program/summary/',
  vitrineProgramSheetMedia: '/vitrine/program/summary/:medias',
  vitrineMediathequeSearch: '/vitrine-mediatheque/:text'
};
export default ROUTES_CONSTANTS;
