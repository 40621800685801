import { createSelector } from 'reselect';

const selectFolderProgram = state => state.folderProgram;

export const selectFolderProgramResult = createSelector(
  [selectFolderProgram],
  folderProgram => folderProgram.folderProgramResult
);

export const selectFolderProgramIsLoading = createSelector(
  [selectFolderProgram],
  folderProgram => folderProgram.isLoading
);

export const selectFolderProgramError = createSelector(
  [selectFolderProgram],
  folderProgram => folderProgram.error
);
