export default {
  tooltip: {
    fontSize: '50px'
  },
  deleteSectionButton: {
    position: 'absolute',
    width: '34px',
    height: '34px',
    background: 'rgba(200, 40, 50, 0.7)',
    borderRadius: '5px',
    color: '#FFFFFF',
    marginLeft: '29%',
    marginTop: '1%'
  },
  deleteIcon: {
    marginTop: '-5px'
  }
};
