import { put, all, call, takeLatest } from 'redux-saga/effects';
import PasteMediaActionTypes from './paste.media.types';
import api from '../../api/api.jsx';
import {
  fetchPasteMediaFailure,
  fetchPasteMediaFinished,
  fetchPasteMediaSuccess
} from './paste.media.actions';
import { pdfFormat } from '../../constants/global-constants';

/**
 * Upload a file to an s3 url
 * @param {String} mediaURL s3 url to upload
 * @param {File} file image file
 */
export function* uploadFile(mediaURL, file) {
  const result = yield call(api.putS3, mediaURL, file);
  return result.data;
}

/**
 * Convert image url to a js file object
 * @param {String} url Image url
 * @param {String} fileName Name of the file
 */
export function* URLtoFile(url, fileName, format) {
  let cors = 'no-cors';
  if (format.toLowerCase() === pdfFormat.toLowerCase()) {
    cors = 'cors';
  }

  const options = {
    mode: cors,
    header: {
      'Access-Control-Allow-Origin': '*',
      credentials: 'include'
    }
  };

  const response = yield call(fetch, url, options);
  const blob = yield response.blob();
  return new File([blob], fileName);
}

/**
 * Process to paste medias in another program with sagas.
 * @param {Object} params Action parameters
 */
export function* PasteMediaResults({ payload: params }) {
  try {
    const result = yield call(api.postCopyMedia, params);
    yield put(fetchPasteMediaSuccess(result));
  } catch (error) {
    yield put(fetchPasteMediaFailure(error));
  }
  yield put(fetchPasteMediaFinished());
}

/**
 * Handle when paste media action start
 */
export function* onFetchPasteMediaStart() {
  yield takeLatest(
    PasteMediaActionTypes.FETCH_PASTE_MEDIA_START,
    PasteMediaResults
  );
}

/**
 * Sagas to paste media in another program
 */
export function* PasteMediaSagas() {
  yield all([call(onFetchPasteMediaStart)]);
}
