import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import VisualProgramActionTypes from './visual-program.types';
import api from '../../api/api.jsx';
import {
  fetchVisualProgramSuccess,
  fetchVisualProgramFailure,
  fetchVisualProgramFinish
} from './visual-program.actions';
import { mediasBuilderWithoutPages } from '../../shared/builders/medias';

export const selectCreateProgram = state => state.createProgram;
export const selectFile = state => state.fileUploader;

export function* fetchVisualProgram({ payload: params }) {
  try {
    const { data } = yield call(api.getVisualProgram, params);
    const { communes } = yield select(selectCreateProgram);
    const { payloadFiles } = yield select(selectFile);
    const dataBuilder = yield call(
      mediasBuilderWithoutPages,
      data,
      communes,
      payloadFiles
    );
    yield put(fetchVisualProgramSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchVisualProgramFailure(error));
  }
  yield put(fetchVisualProgramFinish());
}

export function* onFetchVisualProgramStart() {
  yield takeLatest(
    VisualProgramActionTypes.FETCH_VISUAL_PROGRAM_START,
    fetchVisualProgram
  );
}

export function* visualProgramSagas() {
  yield all([call(onFetchVisualProgramStart)]);
}
