export const ATTRIBUTES = {
  BME_MAIL_WRITE: 'BME-MAIL-WRITE',
  BME_TAG_WRITE: 'BME-TAG-WRITE',
  BME_PUBLISH_WRITE: 'BME-PUBLISH-WRITE',
  BME_PROGRAM_WRITE: 'BME-PROGRAM-WRITE',
  BME_SECTION_WRITE: 'BME-SECTION-WRITE',
  BME_USER_WRITE: 'BME-USER-WRITE',
  BME_MEDIA_WRITE: 'BME-MEDIA-WRITE',
  BME_CAT_WRITE: 'BME-CAT-WRITE',
  BME_SCAT_WRITE: 'BME-SCAT-WRITE',
  BME_SECTION_READ: 'BME-SECTION-READ',
  BME_SCAT_READ: 'BME-SCAT-READ',
  BME_CAT_READ: 'BME-CAT-READ',
  BME_TAG_READ: 'BME-TAG-READ',
  BME_MEDIA_READ: 'BME-MEDIA-READ',
  BME_PROGRAM_READ: 'BME-PROGRAM-READ',
  BME_USER_READ: 'BME-USER-READ',
  BME_RESTRICTION: 'BME-RESTRICTION',
  BME_CMS_WRITE: 'BME-CMS-WRITE',
  EXTERN: 'BME - Agence externe'
};

export const RESTRICTION_VALUES = {
  AEXT: 'agence-ext',
  FILIAL: 'filiale'
};
