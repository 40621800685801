import PartnerListTypes from './list.partner.types';

export const fetchPartnerListStart = params => ({
  type: PartnerListTypes.FETCH_PARTNER_LIST_START,
  payload: params
});

export const fetchPartnerListSuccess = data => ({
  type: PartnerListTypes.FETCH_PARTNER_LIST_SUCCESS,
  payload: data
});

export const fetchPartnerListFailure = error => ({
  type: PartnerListTypes.FETCH_PARTNER_LIST_FAILURE,
  payload: error
});

export const fetchPartnerListFinished = () => ({
  type: PartnerListTypes.FETCH_PARTNER_LIST_FINISHED
});
