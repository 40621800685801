import CreateTarg from './create.tag.types';

export const createTagStart = params => ({
  type: CreateTarg.CREATE_TAG_START,
  payload: params
});

export const createTagSuccess = data => ({
  type: CreateTarg.CREATE_TAG_SUCCESS,
  payload: data
});

export const createTagFailure = error => ({
  type: CreateTarg.CREATE_TAG_FAILURE,
  payload: error
});

export const createTagFinished = () => ({
  type: CreateTarg.CREATE_TAG_FINISHED
});
