import DeleteTypes from './media-delete.types';

export const sendDeleteDataStart = data => ({
  type: DeleteTypes.SEND_DELETE_DATA_START,
  payload: data
});

export const sendDeleteDataSuccess = mediaUrl => ({
  type: DeleteTypes.SEND_DELETE_DATA_SUCCESS,
  payload: mediaUrl
});

export const sendDeleteDataFailure = error => ({
  type: DeleteTypes.SEND_DELETE_DATA_FAILURE,
  payload: error
});

export const sendDeleteDataFinished = () => ({
  type: DeleteTypes.SEND_DELETE_DATA_FINISHED
});
