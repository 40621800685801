const CreateProgram = {
  SEND_FORM_DATA_START: 'SEND_FORM_DATA_START',
  SEND_FORM_DATA_SUCCESS: 'SEND_FORM_DATA_SUCCESS',
  SEND_FORM_DATA_FAILURE: 'SEND_FORM_DATA_FAILURE',
  SEND_FORM_DATA_FINISHED: 'SEND_FORM_DATA_FINISHED',
  FETCH_TYPE_OF_PROJECTS_START: 'FETCH_TYPE_OF_PROJECTS_START',
  FETCH_TYPE_OF_PROJECTS_SUCCESS: 'FETCH_TYPE_OF_PROJECTS_SUCCESS',
  FETCH_TYPE_OF_PROJECTS_FAILURE: 'FETCH_TYPE_OF_PROJECTS_FAILURE',
  FETCH_TYPE_OF_PROJECTS_FINISHED: 'FETCH_TYPE_OF_PROJECTS_FINISHED',
  FETCH_DESTINATION_PROPERTY_START: 'FETCH_DESTINATION_PROPERTY_START',
  FETCH_DESTINATION_PROPERTY_SUCCESS: 'FETCH_DESTINATION_PROPERTY_SUCCESS',
  FETCH_DESTINATION_PROPERTY_FAILURE: 'FETCH_DESTINATION_PROPERTY_FAILURE',
  FETCH_DESTINATION_PROPERTY_FINISHED: 'FETCH_DESTINATION_PROPERTY_FINISHED',
  FETCH_REGIONS_START: 'FETCH_REGIONS_START',
  FETCH_REGIONS_SUCCESS: 'FETCH_REGIONS_SUCCESS',
  FETCH_REGIONS_FAILURE: 'FETCH_REGIONS_FAILURE',
  FETCH_REGIONS_FINISHED: 'FETCH_REGIONS_FINISHED',
  FETCH_DEPARTMENTS_START: 'FETCH_DEPARTMENTS_START',
  FETCH_DEPARTMENTS_SUCCESS: 'FETCH_DEPARTMENTS_SUCCESS',
  FETCH_DEPARTMENTS_FAILURE: 'FETCH_DEPARTMENTS_FAILURE',
  FETCH_DEPARTMENTS_FINISHED: 'FETCH_DEPARTMENTS_FINISHED',
  FETCH_COMMUNES_START: 'FETCH_COMMUNES_START',
  FETCH_COMMUNES_SUCCESS: 'FETCH_COMMUNES_SUCCESS',
  FETCH_COMMUNES_FAILURE: 'FETCH_COMMUNES_FAILURE',
  FETCH_COMMUNES_FINISHED: 'FETCH_COMMUNES_FINISHED'
};

export default CreateProgram;
