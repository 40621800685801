import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TopHomeBanner from '../topHomeBanner/TopHomeBanner.jsx';
import ListCategories from '../listCategories/ListCategories.jsx';
import ListLastMedias from '../lastMedias/ListLastMedias.jsx';
import { fetchLastMediasStart } from '../../redux/lastMedias/lastMedias.actions';
import {
  selectLastMediasLoading,
  selectLastMediasResult,
  selectNumberMedias
} from '../../redux/lastMedias/lastMedias.selectors';
import DismissableAlert from '../../components/alert/DismissableAlert.jsx';
import Spinner from '../../components/spinner/Spinner.jsx';

const Home = ({
  fetchLastMedias,
  lastMediasResult,
  isLoading,
  numberMedias
}) => {
  const params = {
    type: 'MEDIA',
    cursor: 'initial',
    size: 4,
    sort: 'CREATEDDATE'
  };

  useEffect(() => {
    fetchLastMedias(params);
  }, []);

  return (
    <div>
      <Spinner isLoading={isLoading} />
      <DismissableAlert />
      <TopHomeBanner numberMedias={numberMedias} />
      <ListCategories />
      <ListLastMedias lastMediasResult={lastMediasResult} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  lastMediasResult: selectLastMediasResult,
  isLoading: selectLastMediasLoading,
  numberMedias: selectNumberMedias
});

const mapDispatchToProps = dispatch => ({
  fetchLastMedias: params => dispatch(fetchLastMediasStart(params))
});

Home.propTypes = {
  fetchLastMedias: PropTypes.func,
  lastMediasResult: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool,
  numberMedias: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
