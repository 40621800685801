import { createSelector } from 'reselect';

const selectTagList = state => state.tagList;

export const selectTagListResult = createSelector(
  [selectTagList],
  tagList => tagList.result
);

export const selectTagListLoading = createSelector(
  [selectTagList],
  tagList => tagList.isLoading
);

export const selectTagListError = createSelector(
  [selectTagList],
  tagList => tagList.error
);
