import { createSelector } from 'reselect';

const selectSearch = state => state.search;

export const selectSearchResults = createSelector(
  [selectSearch],
  search => search.searchResults
);

export const selectSearchIsLoading = createSelector(
  [selectSearch],
  search => search.isLoading
);

export const selectSearchResultsWithMedia = createSelector(
  [selectSearch],
  search => search.searchResultsWithMedia
);

export const selectSearchWithMediaIsLoading = createSelector(
  [selectSearch],
  search => search.withMediaIsLoading
);

export const selectSearchResultsWithoutMedia = createSelector(
  [selectSearch],
  search => search.searchResultsWithoutMedia
);

export const selectSearchWithoutMediaIsLoading = createSelector(
  [selectSearch],
  search => search.withoutMediaIsLoading
);

export const selectSearchToolbarVisible = createSelector(
  [selectSearch],
  search => search?.toolbarParams?.isVisible
);

export const selectSearchSuggestions = createSelector(
  [selectSearch],
  search => search.suggestions
);

export const selectToolbarParams = createSelector(
  [selectSearch],
  search => search.toolbarParams
);
