export const setRouteFromParam = params => {
  let paramURL;
  if (params.lastName) {
    paramURL = `?nom=${params.lastName}`;
  } else if (params.name) {
    paramURL = `?prenom=${params.name}`;
  } else if (params.firstName && params.lastName) {
    paramURL = `?nom=${params.lastName}&prenom=${params.firstName}`;
  } else if (params?.userName) {
    paramURL = `?login=${params?.userName}`;
  } else if (params?.email) {
    paramURL = `?email=${params?.email}`;
  }

  return paramURL;
};
