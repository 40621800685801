import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PDFReader } from 'reactjs-pdf-view';
import moment from 'moment';
import PropTypes from 'prop-types';
import Slider from 'infinite-react-carousel';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {
  Button,
  Grid,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import './style.scss';
import { makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import ReactPlayer from 'react-player';
import { v4 as uuidv4 } from 'uuid';
import t from '../../constants/translates';
import {
  FOLDER_DOCUMENTS_NAME,
  operation,
  dateFormat,
  MEDIA_TYPE,
  bigPageSize,
  PATCH_PATHS,
  PROGRAM_TYPE,
  minTagChar,
  UNDEFINED_RESOLUTION,
  acceptedFormats,
  pdfWidth,
  pdfScale,
  SEARCH_TYPE,
  pdfType
} from '../../constants/global-constants';
import styles from './style.jsx';
import { counter } from '../../shared/functions/counter';
import { ROUTES_CONSTANTS } from '../../constants/route.constants.jsx';
import { imageWidth, maxLengthComment } from '../../constants/ui.constants';
import { fetchVisualProgramStart } from '../../redux/visual-program/visual-program.actions';
import {
  selectVisualProgramResult,
  selectVisualProgramIsLoading
} from '../../redux/visual-program/visual-program.selectors';
import Spinner from '../../components/spinner/Spinner';
import DismissableAlert from '../../components/alert/DismissableAlert';
import { fetchThemeListStart } from '../../redux/list.theme/list.theme.actions';
import {
  selectThemeListResult,
  selectThemeListIsLoading
} from '../../redux/list.theme/list.theme.selectors';
import AlertDialogSlide from '../../components/dialog/dialog';
import { sendDeleteDataStart } from '../../redux/media-delete/media-delete.actions';
import {
  selectDeleteMediaError,
  selectDeleteMediaIsLoading
} from '../../redux/media-delete/media-delete.selectors';
import { sendModificationDataStart } from '../../redux/media-modification/media-modification.actions';
import {
  selectedModificationMedias,
  modificationIsLoading,
  modificationError,
  modificationSucceed
} from '../../redux/media-modification/media-modification.selectors';
import { toLocalDate } from '../../shared/functions/date';
import {
  selectVisualFolderResult,
  selectVisualFolderIsLoading
} from '../../redux/visual-folder/visual-folder.selectors';
import { fetchVisualFolderStart } from '../../redux/visual-folder/visual-folder.actions';
import { fetchTagListStart } from '../../redux/tag-list/tag.list.actions';
import { selectTagListResult } from '../../redux/tag-list/tag.list.selectors';
import { createTagStart } from '../../redux/createTag/create.tag.actions';
import { selectCreatedTag } from '../../redux/createTag/create.tag.selectors';
import MediaDataForm from '../../components/mediaDataForm/MediaDataForm';
import { fetchSectionStart } from '../../redux/section/sections/section.actions';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import {
  UrlToBufferArray,
  tiffToDataURI
} from '../../shared/functions/uploadFile';
import { fetchFolderProgramStart } from '../../redux/folder-program/folder-program.actions';
import {
  selectFolderProgramIsLoading,
  selectFolderProgramResult
} from '../../redux/folder-program/folder-program.selectors.js';
import { fetchSearchResultsStart } from '../../redux/search/search.actions';
import { selectSearchResults } from '../../redux/search/search.selectors';
import { isFilialExt } from '../../shared/rights/root-rights';
import { getPrograms } from '../../shared/functions/mediaFunctions';

/**
 * Component for insert the obligated data for each media after add the media
 */
const ProgramCategorisation = ({
  sections,
  ProgramMediasData,
  fetchMediasIsLoading,
  fetchVisualProgram,
  fetchSections,
  history,
  fetchThemeListResults,
  fetchSectionMediasIsLoading,
  sectionMediasData,
  sendDeleteData,
  sendDeleteMediaIsLoading,
  sendDeleteMediaError,
  modificationSuccees,
  modificationResult,
  sendModificationData,
  sendModificationLoading,
  sendModificationError,
  visualFolderData,
  fetchVisualFolder,
  selectVisualFolderIsLoadingProp,
  fetchTagList,
  tagList,
  fetchCreateTag,
  createdTag,
  fetchFolderProgram,
  folderProgramData,
  getFolderIsLoading,
  fetchSearchResults,
  searchProgramResults
}) => {
  const style = makeStyles(styles)();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selected, setSelected] = useState(0);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState();
  const [onCreationTagName, setOnCreationTagName] = useState();
  const [support, setSupportValue] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [Categorie, setCategorie] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategorie, setSubCategorie] = useState({
    id: '',
    name: ''
  });
  const [copyright, setCopyright] = useState(null);
  const [shouldGoNext, setShouldGoNext] = useState();
  const [mediaData, setMediaData] = useState([]);
  const [MediaModifiedCount, setMediaModifiedCount] = useState(0);
  let refMedia = null;
  const [open, setOpen] = useState(false);
  const [isProgramIR, setIsProgramIR] = useState(false);
  const [mediaTiffsUrl, setMediaTiffsUrl] = useState([]);
  const [tiffsLoading, setTiffsLoading] = useState(false);
  const [currentProgram, setcurrentProgram] = useState();
  const [pdfTitle, setPdfTitle] = useState();
  const [description, setDescription] = useState();
  const [comment, setComment] = useState('');
  // Style for the icon of the video media
  const playIconStyle = {
    height: '200px',
    width: '200px',
    fill: 'white'
  };

  const handleDelete = () => {
    setOpen(true);
  };

  /**
   * Delete media
   */
  const handleDeleteMedia = () => {
    refMedia = mediaData[selected]?.refMedia;
    sendDeleteData(refMedia);
    setOpen(false);
    // If is the last media, we return to the media upload page
    if (mediaData?.length === 1) {
      history.goBack();
    }
    // Pass to the next media
    setShouldGoNext(true);
  };

  // Close delete media dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle of categories
  const handleCategories = e => {
    setCategorie({
      id: e.refCategory,
      name: e.name,
      level: e.level,
      listCode: e.listCode
    });
    setSubCategories(e.subCategory);

    setSubCategorie(null);
  };

  // Variables from params URL to know who call the categorization page
  const refId = useParams();
  const isMediaProgram = refId.refProgram !== undefined;
  const isMediaSection = refId.refSection !== undefined;
  const isMediaFolder = refId.refFolder !== undefined;
  const isPDF = mediaData[selected]?.format === pdfType;
  /**
   * Function for get the categorys et subcategorys
   */

  const fillCategories = () => {
    if (
      (!ProgramMediasData && !sectionMediasData && !visualFolderData) ||
      !sections
    ) {
      return;
    }

    // Case from categorization of media section
    let { refSection } = refId;
    // Case from categorization of media program
    if (isMediaProgram) {
      if (ProgramMediasData.length > 0) {
        refSection = ProgramMediasData[0]?.program?.section?.refSection;
      } else {
        refSection = visualFolderData[0]?.folder?.program?.section?.refSection;
      }
    } else if (isMediaFolder) {
      refSection = visualFolderData[0]?.folder?.program?.section?.refSection;
    }

    // We search in nomenclature sections the section of the media to categorize
    setCurrentCategories(
      sections.filter(section => section.refSection === refSection)[0]
        ?.categories
    );

    if (
      currentCategories &&
      isProgramIR &&
      ProgramMediasData[selected]?.typeMedia?.refValue === MEDIA_TYPE.video
    ) {
      setSubCategories(
        currentCategories?.filter(
          item => item.refCategory === MEDIA_TYPE.video
        )[0]?.subCategory
      );
    } else if (subCategories?.length > 0) {
      setSubCategories([]);
    }
  };

  // Beginning of get datas
  useEffect(() => {
    if (isMediaProgram) {
      fetchFolderProgram({ refProgram: refId.refProgram });
    }
    if (!sections) {
      fetchSections();
    }
    fillCategories();
  }, []);

  useEffect(() => {
    folderProgramData.filter(folder => folder.name === FOLDER_DOCUMENTS_NAME);

    if (folderProgramData.length > 0) {
      fetchVisualFolder({
        refFolder: folderProgramData[0].refFolder,
        withCategory: false
      });
    }
  }, [folderProgramData]);

  useEffect(() => {
    if (isMediaProgram) {
      fetchVisualProgram({
        refProgram: refId.refProgram,
        withCategory: false
      });
    } else if (isMediaSection) {
      if (sections.length !== 0) {
        const actualSection = sections.filter(
          section => section.refSection === refId.refSection
        );
        fetchThemeListResults({
          pageSize: bigPageSize,
          refValue: refId.refSection,
          level: actualSection[0]?.level,
          listCode: actualSection[0]?.listCode,
          category: false
        });
      }
    } else if (isMediaFolder) {
      fetchVisualFolder({ refFolder: refId.refFolder, withCategory: false });
    }
    fillCategories();
  }, [sections]);

  useEffect(() => {
    if (visualFolderData.length > 0) {
      // The case of documents in documents folder of 1 program
      setMediaData(visualFolderData.concat(ProgramMediasData));
    } else {
      setMediaData(ProgramMediasData);
    }
    fillCategories();
  }, [ProgramMediasData]);

  useEffect(() => {
    const mediasTiff = mediaData?.filter(media =>
      media?.mediaContentType?.includes(acceptedFormats.tiff)
    );

    if (mediasTiff.length > 0) {
      setTiffsLoading(true);

      mediasTiff.forEach(mediaTiff => {
        let tiffUrl = null;
        if (mediaTiff?.localFileUrl !== undefined) {
          tiffUrl = mediaTiff?.localFileUrl;
        } else {
          tiffUrl = mediaTiff?.mediaURL;
        }

        UrlToBufferArray(tiffUrl).then(buffer => {
          setTiffsLoading(false);
          setMediaTiffsUrl([
            ...mediaTiffsUrl,
            { url: tiffToDataURI(buffer), refMedia: mediaTiff?.refMedia }
          ]);
        });
      });
    }
  }, [mediaData]);

  useEffect(() => {
    // Boolean for know if we are in 1 media from Program type IR

    if (isMediaProgram) {
      if (ProgramMediasData.length > 0) {
        setIsProgramIR(
          ProgramMediasData[0]?.program?.section?.refSection ===
            PROGRAM_TYPE.IR_PROGRAM_NAME
        );
      } else {
        setIsProgramIR(
          visualFolderData[0]?.folder?.program?.section?.refSection ===
            PROGRAM_TYPE.IR_PROGRAM_NAME
        );
      }
    }
    fillCategories();
  }, [ProgramMediasData, visualFolderData]);

  useEffect(() => {
    // For call programs IR if the program is IR
    if (isProgramIR) {
      const params = {
        type: SEARCH_TYPE.program,
        text: refId.refProgram,
        section: PROGRAM_TYPE.IR_PROGRAM_NAME,
        myPrograms: isFilialExt()
          ?.toString()
          ?.toUpperCase()
      };
      fetchSearchResults(params);
    }
  }, [isProgramIR]);

  useEffect(() => {
    if (isProgramIR) {
      const program = getPrograms(searchProgramResults)?.find(
        prog => prog.programRef.toString() === refId.refProgram
      );
      setcurrentProgram(program);
    }
  }, [searchProgramResults]);

  useEffect(() => {
    setMediaData(sectionMediasData);
  }, [sectionMediasData]);

  useEffect(() => {
    if (isMediaProgram) {
      // The case of documents in documents folder of 1 program
      setMediaData(ProgramMediasData.concat(visualFolderData));
    } else {
      setMediaData(visualFolderData);
    }
    fillCategories();
  }, [visualFolderData]);

  useEffect(() => {
    if (createdTag?.refTag && onCreationTagName) {
      const addTag = {
        refTag: createdTag.refTag,
        name: onCreationTagName
      };
      setTags([...tags, addTag]);
    }
  }, [createdTag]);

  let slider;
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  const handleCopyright = copyrightValue => {
    setCopyright(copyrightValue);
  };

  const handlePdfTitle = pdfTitleValue => {
    setPdfTitle(pdfTitleValue);
  };
  const handleDescription = descriptionValue => {
    setDescription(descriptionValue);
  };
  // Variable for know if is the last media and we have to change the button
  const finishVisibility = () => {
    return selected === mediaData?.length - 1;
  };

  // Reset values after change the page
  const resetValues = () => {
    refMedia = mediaData[selected + 1]?.refMedia;
    setSelected(selected + 1);
    setCategorie(null);
    setSubCategorie(null);
    setCopyright('');
    setPdfTitle('');
    setSupportValue(0);
    setTags([]);
    setSelectedDate(null);
    setShouldGoNext(false);
    setComment('');
  };

  // Conditions for change from 1 media to the next media
  const goNextDeleteConditions = () => {
    return !shouldGoNext || sendDeleteMediaIsLoading || sendDeleteMediaError;
  };

  // Change to the next media
  const goNextMedia = () => {
    // If is the last media we go to the page of the list of medias
    if (finishVisibility()) {
      if (isMediaProgram) {
        let program;
        if (mediaData[0]?.program?.refProgram) {
          program = mediaData[0]?.program?.refProgram;
        } else {
          program = visualFolderData[0]?.folder?.program?.refProgram;
        }

        let routePath = null;
        routePath = `${ROUTES_CONSTANTS.mediaSummaryBase}${program +
          '/'}${MediaModifiedCount}`;

        setMediaModifiedCount(0);
        history.push(routePath);
      } else if (isMediaSection) {
        const section = refId.refSection;
        const routePath = `${ROUTES_CONSTANTS.categoriesLink}${section +
          '/'}${MediaModifiedCount}`;
        setMediaModifiedCount(0);
        history.push(routePath);
      } else if (isMediaFolder) {
        const folder = refId.refFolder;
        const routePath = `${ROUTES_CONSTANTS.mediaSummaryBase}${visualFolderData[0]?.folder?.program?.refProgram}${ROUTES_CONSTANTS.foldersLink}${folder}`;
        setMediaModifiedCount(0);
        history.push(routePath);
      }
      // If is not the last media we go to the next media
    } else {
      resetValues();
      slider.slickNext();
    }
  };

  useEffect(() => {
    if (!showDatePicker) {
      setSelectedDate();
    } else if (!selectedDate) {
      setSelectedDate(moment());
    }
  }, [showDatePicker]);

  // Use effect for the case that 1 media is supprimed, we go to the next media
  useEffect(() => {
    if (goNextDeleteConditions()) {
      return;
    }
    goNextMedia();
  }, [sendDeleteMediaIsLoading, sendDeleteMediaError]);

  // Function to save the number of medias modified
  function incrementMediaModified() {
    setMediaModifiedCount(prevState => prevState + 1);
  }

  // Effect applied after categorize 1 media
  useEffect(() => {
    if (!shouldGoNext || !modificationSuccees) {
      return;
    }
    incrementMediaModified();
  }, [sendModificationLoading, sendModificationError, modificationResult]);

  useEffect(() => {
    if (!shouldGoNext) {
      return;
    }
    goNextMedia();
  }, [MediaModifiedCount]);

  const nextVisibility = () => {
    if (isPDF) {
      return Categorie?.id === undefined;
    }
    if (isMediaSection) {
      return (
        Categorie?.id === undefined ||
        copyright === undefined ||
        copyright === null ||
        copyright.length === 0
      );
    }
    // Case video upload in program IR
    if (
      isProgramIR &&
      mediaData[selected]?.typeMedia?.refValue === MEDIA_TYPE.video
    ) {
      return false;
    }
    return Categorie?.id === undefined || comment.length > maxLengthComment;
  };

  const handleTagChange = e => {
    if (e.length > 2) {
      fetchTagList(e);
    }
    setTag(e);
  };

  /**
   * Function to add 1 tag
   * @param {event} Event with the text introduced by the user
   */
  const addTagTolist = e => {
    if (tag?.length === 0) {
      return;
    }

    if (e.key === 'Enter') {
      const addTag = tagList.find(tagFind => tagFind.name === e.target.value);
      if (addTag) {
        if (!tags?.find(item => item.refTag === addTag.refTag)) {
          setTags([...tags, addTag]);
        }
      } else {
        fetchCreateTag({ name: e.target.value });
        setOnCreationTagName(e.target.value);
      }
      setTag('');
    }
  };

  const removeTag = tagToRemove => {
    setTags(tags.filter(item => item.refTag !== tagToRemove.refTag));
  };

  // Function to create the string with the JSON patch to send to the back
  const patchConstructor = (path, value) => {
    return {
      op: operation.addOperation,
      path,
      value
    };
  };

  const handleMediaModification = (property, state, mediaModification) => {
    if (state !== undefined) {
      mediaModification.push(patchConstructor(`/${property}`, state));
    }
  };

  const handleComment = value => {
    return setComment(value);
  };

  /**
   * Gets the tags list to show in combobox
   */
  const getTagsDataList = () => {
    if (tag?.length < minTagChar || tagList.find(item => item.name === tag)) {
      return tagList;
    }

    return [{ name: tag }].concat(tagList);
  };

  /**
   * Get the list of tags span elements
   */
  const getTagsSpanList = () => {
    return tags?.map(tagValue => (
      <span key={tagValue.refTag} className="tag__field">
        <span>{tagValue.name}</span>
        <span
          className="close__field"
          role="button"
          tabIndex="0"
          onKeyPress={() => removeTag(tagValue)}
          onClick={() => removeTag(tagValue)}
        >
          {t.close}
        </span>
      </span>
    ));
  };

  const printResolution = () => {
    if (mediaData[selected]?.resolution === UNDEFINED_RESOLUTION) {
      return t.carousel.loadingResolution;
    }
    return mediaData[selected]?.resolution;
  };

  /**
   * Function to create the object with the modifications of the media
   * @param {Object} mediaModification Object with the modifications of the media
   */
  const mediaModificationConstructor = mediaModification => {
    // eslint-disable-next-line no-unused-expressions
    tags?.forEach(tagValue => {
      handleMediaModification(
        'tags/-',
        { refTag: tagValue.refTag },
        mediaModification
      );
    });

    const addCategoryValue = {};
    addCategoryValue.listCode = Categorie?.listCode;
    addCategoryValue.level = Categorie?.level;
    addCategoryValue.refValue = Categorie?.id;

    // Case video upload in program IR
    if (
      isProgramIR &&
      mediaData[selected]?.typeMedia?.refValue === MEDIA_TYPE.video
    ) {
      const videoCategory = currentCategories?.filter(
        item => item.refCategory === MEDIA_TYPE.video
      )?.[0];
      addCategoryValue.listCode = videoCategory?.listCode;
      addCategoryValue.level = videoCategory?.level;
      addCategoryValue.refValue = videoCategory?.refCategory;
    }

    handleMediaModification('category', addCategoryValue, mediaModification);

    if (subCategorie?.refSubCategory !== undefined) {
      const addSubCategoryValue = {};
      addSubCategoryValue.refValue = subCategorie?.refSubCategory;
      addSubCategoryValue.listCode = Categorie?.listCode;
      addSubCategoryValue.level = subCategorie?.level;

      // Case video upload in program IR
      if (
        isProgramIR &&
        mediaData[selected]?.typeMedia?.refValue === MEDIA_TYPE.video
      ) {
        const videoCategory = currentCategories?.filter(
          item => item.refCategory === MEDIA_TYPE.video
        )?.[0];
        addSubCategoryValue.listCode = videoCategory?.listCode;
      }

      handleMediaModification(
        'subCategory',
        addSubCategoryValue,
        mediaModification
      );
    }

    if (
      copyright !== null &&
      copyright !== undefined &&
      copyright?.length !== 0
    ) {
      handleMediaModification('copyright', copyright, mediaModification);
    }

    handleMediaModification('description', comment, mediaModification);

    if (
      selectedDate !== undefined &&
      selectedDate !== null &&
      !isMediaProgram
    ) {
      handleMediaModification(
        PATCH_PATHS.expirationDate,
        moment(selectedDate).format(dateFormat.YMD),
        mediaModification
      );
    }
    if (support) {
      handleMediaModification(
        PATCH_PATHS.support,
        {
          listCode: support?.listCode,
          level: support?.level?.toString(),
          refValue: support?.refSupport
        },
        mediaModification
      );
    }
    handleMediaModification('title', pdfTitle, mediaModification);

    return mediaModification;
  };

  /**
   * Function to send the data od the media to modify
   * @param {string} refMedia Id of the media to modify
   */
  const sendDataMedia = refMediaSend => {
    let mediaModification = [];
    mediaModification = mediaModificationConstructor(mediaModification);
    sendModificationData({
      mediaModification,
      refMediaSend
    });
    setShouldGoNext(true);
  };

  const handleFinish = () => {
    refMedia = mediaData[selected]?.refMedia;
    sendDataMedia(refMedia);
  };

  const handleNext = () => {
    refMedia = mediaData[selected]?.refMedia;
    sendDataMedia(refMedia);
    fillCategories();
  };

  const programTitle = () => {
    if (mediaData[0]?.program?.code) {
      return (
        <p className="subTitle">{`${mediaData[0]?.program?.name} : ${mediaData[0]?.program?.code} - ${mediaData[0]?.program?.address}`}</p>
      );
    }
    if (isProgramIR) {
      if (currentProgram?.address) {
        if (currentProgram?.name !== undefined) {
          return (
            <p className="subTitle">{`${currentProgram?.name} : ${currentProgram?.programRef} - ${currentProgram?.address}`}</p>
          );
        }
        return (
          <p className="subTitle">{`${currentProgram?.programRef} - ${currentProgram?.address}`}</p>
        );
      }
      if (currentProgram?.name !== undefined) {
        return (
          <p className="subTitle">{`${currentProgram?.name} : ${currentProgram?.programRef}`}</p>
        );
      }
      return <p className="subTitle">{`${currentProgram?.programRef}`}</p>;
    }
    if (mediaData[0]?.folder?.program?.name) {
      // Case for folder media or PDF
      return (
        <p className="subTitle">{`${mediaData[0]?.folder?.program?.name} : ${mediaData[0]?.folder?.program?.address}`}</p>
      );
    }
    return (
      <p className="subTitle">{`${mediaData[0]?.program?.name} : ${mediaData[0]?.program?.address}`}</p>
    );
  };

  const getMediaUrl = (format, refMediaSend, mediaURL, localFileUrl) => {
    if (localFileUrl !== undefined) {
      if (format.toLowerCase() === acceptedFormats.tiff) {
        const findedMedia = mediaTiffsUrl?.find(
          media => media?.refMedia === mediaData[selected]?.refMedia
        );
        return findedMedia?.url;
      }
      return localFileUrl;
    }
    if (
      mediaURL
        .split('?')[0]
        .toLowerCase()
        .endsWith(acceptedFormats.tiff)
    ) {
      const findedMedia = mediaTiffsUrl?.find(
        media => media?.refMedia === refMediaSend
      );
      return findedMedia?.url;
    }

    return mediaURL;
  };

  const printMedia = (
    mediaURL,
    typeMedia,
    thumbnail,
    format,
    nameMedia,
    localFileUrl
  ) => {
    switch (typeMedia?.refValue) {
      case MEDIA_TYPE.image:
        return (
          <img
            crossOrigin="anonymous"
            key={uuidv4()}
            id={refMedia}
            src={getMediaUrl(format, refMedia, mediaURL, localFileUrl)}
            name={nameMedia}
            height="auto"
            width={imageWidth}
            alt={nameMedia}
          />
        );
      case MEDIA_TYPE.video:
        return (
          <ReactPlayer
            url={localFileUrl !== undefined ? localFileUrl : mediaURL}
            light={thumbnail}
            playing
            controls
            playIcon={<PlayCircleOutlineOutlinedIcon style={playIconStyle} />}
          />
        );
      case MEDIA_TYPE.document:
        if (
          localFileUrl !== undefined ||
          mediaURL.toLowerCase().indexOf('upload.s3') === -1
        ) {
          return (
            <div className="pdf-container">
              <PDFReader
                url={localFileUrl !== undefined ? localFileUrl : mediaURL}
                width={pdfWidth}
                scale={pdfScale}
              />
            </div>
          );
        }
        return null;
      default:
        return null;
    }
  };

  /**
   * Function to render the display of the media
   */
  const printMedias = () => {
    return mediaData?.map(
      ({
        refMediaValue,
        nameMedia,
        mediaURL,
        typeMedia,
        thumbnail,
        format,
        localFileUrl
      }) => (
        <div key={refMediaValue}>
          {printMedia(
            mediaURL,
            typeMedia,
            thumbnail,
            format,
            nameMedia,
            localFileUrl
          )}
        </div>
      )
    );
  };

  const getLoadings = () => {
    return (
      fetchMediasIsLoading ||
      fetchSectionMediasIsLoading ||
      sendDeleteMediaIsLoading ||
      sendModificationLoading ||
      selectVisualFolderIsLoadingProp ||
      getFolderIsLoading ||
      tiffsLoading
    );
  };

  const isVideoProgramIR = () => {
    return (
      subCategories ||
      !isProgramIR ||
      ProgramMediasData[selected]?.typeMedia?.refValue !== MEDIA_TYPE.video
    );
  };

  return (
    <>
      {selectVisualFolderIsLoadingProp ? (
        <Spinner className="spinner" isLoading /> // For problems of rendering in the pdf framework we assure that mediasData has been updated after render the pdf
      ) : (
        <>
          <Spinner className="spinner" isLoading={getLoadings()} />
          <DismissableAlert />
          <div className="title__div">
            <span className="title">{t.fileUploader.title}</span>
            <span className="deleteMedia">
              <IconButton
                id="deleteIcon"
                color="inherit"
                aria-label="menu"
                className={style.deleteIcon}
                onClick={handleDelete}
              >
                {t.mediaModification.delete}
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </span>
          </div>
          {isMediaProgram && mediaData && programTitle()}
          {mediaData?.length > 0 && isVideoProgramIR() && (
            <Grid container spacing={2} className="carousel__container">
              <Grid item xs={7} lg={6}>
                <>
                  {tiffsLoading && getLoadings() ? (
                    <CircularProgress />
                  ) : (
                    <Slider
                      className="dotsClass"
                      ref={el => {
                        slider = el;
                      }}
                      {...settings}
                    >
                      {printMedias()}
                    </Slider>
                  )}
                  <div>
                    <p>
                      {t.formatString(t.carousel.mediaState, {
                        selected: selected + 1,
                        total: mediaData?.length,
                        plural:
                          mediaData?.length > 1
                            ? t.general.plural
                            : t.general.singular
                      })}
                    </p>
                    <p className="header__input">
                      {t.formatString(t.carousel.fileName, {
                        name: mediaData[selected]?.nameMedia
                      })}
                    </p>
                    {mediaData[selected]?.typeMedia?.refValue !==
                      MEDIA_TYPE.document && (
                      <p className="header__input">
                        {t.formatString(t.carousel.resolution, {
                          resolution: printResolution()
                        })}
                      </p>
                    )}
                    <p className="header__input">
                      {t.formatString(t.carousel.weight, {
                        weight: mediaData[selected]?.size
                      })}
                    </p>
                    <p className="header__input">
                      {t.formatString(t.carousel.format, {
                        format: mediaData[selected]?.format
                      })}
                    </p>
                    <p className="header__input">
                      {t.formatString(t.carousel.uploadDate, {
                        date: toLocalDate(mediaData[selected]?.dateUpload)
                      })}
                    </p>
                    <p className="header__input">
                      {t.formatString(t.carousel.userInfo, {
                        user: mediaData[selected]?.userUpdate,
                        filial: 'Nexity'
                      })}
                    </p>
                    {!isPDF && (
                      <div>
                        <textarea
                          id="description"
                          className="text__area__create margin-top"
                          onChange={e => {
                            handleComment(e.target.value);
                          }}
                          value={comment}
                          maxLength={maxLengthComment}
                        />
                        {comment?.length < maxLengthComment - 1 ? (
                          <>
                            <p>
                              {t.formatString(
                                t.counterTextArea.caracteresRestants,
                                {
                                  numCaracter: counter(
                                    maxLengthComment,
                                    comment?.length
                                  )
                                }
                              )}
                            </p>
                          </>
                        ) : (
                          <p
                            className={
                              counter(maxLengthComment, comment?.length) === 0
                                ? 'rouge'
                                : ''
                            }
                          >
                            {t.formatString(t.counterTextArea.caracterRestant, {
                              numCaracter: counter(
                                maxLengthComment,
                                comment?.length
                              )
                            })}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </>
              </Grid>
              <Grid item xs={5} lg={6} className={style.categoriesContainer}>
                <MediaDataForm
                  mediaDetailsResult={mediaData[selected]}
                  handleCategories={handleCategories}
                  currentCategories={currentCategories}
                  Categorie={Categorie}
                  subCategories={subCategories}
                  subCategorie={subCategorie}
                  setSubCategorie={setSubCategorie}
                  handleTagChange={handleTagChange}
                  addTagTolist={addTagTolist}
                  getTagsSpanList={getTagsSpanList}
                  getTagsDataList={getTagsDataList}
                  tag={tag}
                  isMediaSection={isMediaSection}
                  copyright={copyright}
                  handleCopyright={handleCopyright}
                  showDatePicker={showDatePicker}
                  setShowDatePicker={setShowDatePicker}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  refSupport={support?.refSupport}
                  onSupportChange={setSupportValue}
                  isProgramIR={isProgramIR}
                  handlePdfTitle={handlePdfTitle}
                  pdfTitle={pdfTitle}
                  description={description}
                  handleDescription={handleDescription}
                />
                {finishVisibility() ? (
                  <Button
                    id="finishButton"
                    variant="outlined"
                    className={`${style.button} `}
                    color="secondary"
                    onClick={handleFinish}
                    disabled={nextVisibility()}
                  >
                    <Typography className={style.buttonText}>
                      {t.finish}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    id="modificationButton"
                    variant="outlined"
                    className={`${style.button} `}
                    color="secondary"
                    onClick={handleNext}
                    disabled={nextVisibility()}
                  >
                    <Typography className={style.buttonText}>
                      {t.next}
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          <AlertDialogSlide
            open={open}
            title={t.visualProgram.questionDialog}
            textButtonOk={t.visualProgram.textButtonOk}
            textButtonCancel={t.visualProgram.textButtonCancel}
            handleAction={handleDeleteMedia}
            handleClose={handleClose}
          />
        </>
      )}
    </>
  );
};

ProgramCategorisation.propTypes = {
  fetchCategorizationMedia: PropTypes.func,
  history: PropTypes.func,
  currentCategories: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  ProgramMediasData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchMediasIsLoading: PropTypes.func,
  fetchVisualProgram: PropTypes.func,
  fetchSections: PropTypes.func,
  fetchThemeListResults: PropTypes.func,
  fetchSectionMediasIsLoading: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  sectionMediasData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  sendDeleteData: PropTypes.func,
  sendDeleteMediaIsLoading: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  sendDeleteMediaError: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  modificationSuccees: PropTypes.bool,
  modificationResult: PropTypes.func,
  sendModificationData: PropTypes.func,
  sendModificationLoading: PropTypes.bool,
  sendModificationError: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  fetchVisualFolder: PropTypes.func,
  visualFolderData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  selectVisualFolderIsLoadingProp: PropTypes.bool,
  fetchTagList: PropTypes.func,
  tagList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchCreateTag: PropTypes.func,
  createdTag: PropTypes.oneOfType([PropTypes.string]),
  sections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
  fetchSearchResults: PropTypes.func,
  searchProgramResults: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  fetchFolderProgram: PropTypes.func,
  folderProgramData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  getFolderIsLoading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

const mapStateToProps = createStructuredSelector({
  ProgramMediasData: selectVisualProgramResult,
  fetchMediasIsLoading: selectVisualProgramIsLoading,
  sectionMediasData: selectThemeListResult,
  fetchSectionMediasIsLoading: selectThemeListIsLoading,
  sendDeleteMediaIsLoading: selectDeleteMediaIsLoading,
  sendDeleteMediaError: selectDeleteMediaError,
  modificationSuccees: modificationSucceed,
  modificationResult: selectedModificationMedias,
  sendModificationLoading: modificationIsLoading,
  sendModificationError: modificationError,
  visualFolderData: selectVisualFolderResult,
  selectVisualFolderIsLoadingProp: selectVisualFolderIsLoading,
  tagList: selectTagListResult,
  createdTag: selectCreatedTag,
  sections: selectSectionsResult,
  searchProgramResults: selectSearchResults,
  folderProgramData: selectFolderProgramResult,
  getFolderIsLoading: selectFolderProgramIsLoading
});

const mapDispatchToProps = dispatch => ({
  fetchVisualProgram: programRef =>
    dispatch(fetchVisualProgramStart(programRef)),
  fetchVisualFolder: folderRef => dispatch(fetchVisualFolderStart(folderRef)),
  fetchSections: () => dispatch(fetchSectionStart()),
  fetchThemeListResults: text => dispatch(fetchThemeListStart(text)),
  sendDeleteData: refMedia => dispatch(sendDeleteDataStart(refMedia)),
  sendModificationData: params => dispatch(sendModificationDataStart(params)),
  fetchTagList: params => dispatch(fetchTagListStart(params)),
  fetchCreateTag: params => dispatch(createTagStart(params)),
  fetchFolderProgram: programRef =>
    dispatch(fetchFolderProgramStart(programRef)),
  fetchSearchResults: params => dispatch(fetchSearchResultsStart(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramCategorisation);
