import { createSelector } from 'reselect';

const selectVisualProgram = state => state.visualProgram;

export const selectVisualProgramResult = createSelector(
  [selectVisualProgram],
  visualProgram => visualProgram.visualProgramResult
);

export const selectVisualProgramIsLoading = createSelector(
  [selectVisualProgram],
  visualProgram => visualProgram.isLoading
);

export const selectVisualProgramError = createSelector(
  [selectVisualProgram],
  visualProgram => visualProgram.error
);
