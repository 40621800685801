import { isInternal } from './interface';
import { ATTRIBUTES, RESTRICTION_VALUES } from './roles';
import { getUserInfo } from './user-info';
import { NO_PATH } from '../../constants/global-constants';

export const belongToMySubsidiary = subsidiary => {
  if (typeof subsidiary === 'boolean') {
    return subsidiary;
  }
  const { groups } = getUserInfo();
  let result = false;

  const newSubsidiary = subsidiary.replaceAll('.', '/');
  groups.forEach(group => {
    if (group.toLowerCase().includes(newSubsidiary.toLowerCase()) && !result) {
      result = true;
    }
  });
  return result;
};

const hasAttribute = attribute => {
  const { permissions } = getUserInfo();
  const findPerm = permissions?.find(perm => perm.label === attribute);

  if (findPerm?.value === Boolean(true).toString()) {
    return true;
  }

  return false;
};

export const showAdminRoot = () => {
  return (
    isInternal() &&
    (hasAttribute(ATTRIBUTES.BME_USER_WRITE) ||
      hasAttribute(ATTRIBUTES.BME_CAT_WRITE) ||
      hasAttribute(ATTRIBUTES.BME_SCAT_WRITE) ||
      hasAttribute(ATTRIBUTES.BME_SECTION_WRITE))
  );
};

export const isExternalUser = permissions => {
  return (
    permissions?.find(perm => perm.label === ATTRIBUTES.BME_RESTRICTION)
      ?.value === RESTRICTION_VALUES.AEXT
  );
};

export const isFilial = permissions => {
  return (
    permissions?.find(perm => perm.label === ATTRIBUTES.BME_RESTRICTION)
      ?.value === RESTRICTION_VALUES.FILIAL
  );
};

export const isFilialExt = () => {
  const { permissions } = getUserInfo();
  return isFilial(permissions);
};

export const hasFilials = () => {
  const { hasFilial, permissions } = getUserInfo();
  return hasFilial && !isExternalUser(permissions);
};

export const canEdit = (subsidiary, attribute) => {
  const userInfo = getUserInfo();
  const { permissions } = userInfo;
  const findPerm = permissions?.find(perm => perm.label === attribute);
  if (isExternalUser(permissions)) {
    return belongToMySubsidiary(subsidiary) && hasAttribute(attribute);
  }

  if (
    isFilial(permissions) &&
    findPerm?.label &&
    (findPerm?.label === ATTRIBUTES.BME_MEDIA_WRITE ||
      findPerm?.label === ATTRIBUTES.BME_PUBLISH_WRITE ||
      findPerm?.label === ATTRIBUTES.BME_PROGRAM_WRITE)
  ) {
    return belongToMySubsidiary(subsidiary);
  }
  if (findPerm) {
    switch (findPerm.value) {
      case Boolean(true).toString():
        return true;
      case Boolean(false).toString():
        return false;
      default:
        return false;
    }
  }

  return false;
};

export const canEditArray = (subsidiaryPath, attribute) => {
  if (!subsidiaryPath) {
    return true;
  }
  return subsidiaryPath?.some(subsidiary => {
    if (subsidiary === NO_PATH) {
      return canEdit(true, attribute);
    }
    return canEdit(subsidiary, attribute);
  });
};
