import ProgramListTypes from './program.list.types';

export const fetchProgramListStart = params => ({
  type: ProgramListTypes.FETCH_PROGRAM_LIST_START,
  payload: params
});

export const fetchProgramListSuccess = data => ({
  type: ProgramListTypes.FETCH_PROGRAM_LIST_SUCCESS,
  payload: data
});

export const fetchProgramListFailure = error => ({
  type: ProgramListTypes.FETCH_PROGRAM_LIST_FAILURE,
  payload: error
});

export const fetchProgramListFinished = () => ({
  type: ProgramListTypes.FETCH_PROGRAM_LIST_FINISHED
});

export const fetchProgramListWithoutMediaStart = params => ({
  type: ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_START,
  payload: params
});

export const fetchProgramListWithoutMediaSuccess = data => ({
  type: ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_SUCCESS,
  payload: data
});

export const fetchProgramListWithoutMediaFailure = error => ({
  type: ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_FAILURE,
  payload: error
});

export const fetchProgramListWithoutMediaFinished = () => ({
  type: ProgramListTypes.FETCH_PROGRAM_LIST_WITHOUT_MEDIA_FINISHED
});
