import ModeControllerTypes from './modeController.types';

const INITIAL_STATE = {
  mode: null,
  programCode: null,
  selection: null,
  callback: null,
  error: null,
  getCallbackResponse: null,
  isLoading: false
};

const modeControllerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModeControllerTypes.SET_MODE_SUCCESS:
      return {
        ...state,
        mode: action?.payload?.mode,
        programCode: action?.payload?.programCode,
        selection: action?.payload?.selection,
        callback: action?.payload?.callback
      };
    case ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_START:
      return {
        ...state,
        isLoading: true
      };
    case ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_SUCCESS:
      return {
        ...state,
        getCallbackResponse: action.payload
      };

    case ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_FINISHED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default modeControllerReducer;
