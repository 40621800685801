import axios from 'axios';
import { API_GOV } from './api.conf';
import { PATHS } from './apiRoutesGov';

class API {
  http;

  constructor() {
    const instance = axios.create({
      baseURL: API_GOV
    });
    this.http = instance;
  }

  getDepartments = () => this.http.get(`${PATHS.departments}`);

  getRegions = () => this.http.get(`${PATHS.regions}`);

  getCommunes = () => this.http.get(`${PATHS.communes}`);
}

export default new API();
