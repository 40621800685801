const ShareActionTypes = {
  SHARE_MEDIA_START: 'SHARE_MEDIA_START',
  SHARE_MEDIA_SUCCESS: 'SHARE_MEDIA_SUCCESS',
  SHARE_MEDIA_FAILURE: 'SHARE_MEDIA_FAILURE',
  SHARE_MEDIA_FINISHED: 'SHARE_MEDIA_FINISHED',
  FETCH_INTERNAL_MEMBERS_START: 'FETCH_INTERNAL_MEMBERS_START',
  FETCH_INTERNAL_MEMBERS_SUCCESS: 'FETCH_INTERNAL_MEMBERS_SUCCESS',
  FETCH_INTERNAL_MEMBERS_FAILURE: 'FETCH_INTERNAL_MEMBERS_FAILURE',
  FETCH_INTERNAL_MEMBERS_FINISHED: 'FETCH_INTERNAL_MEMBERS_FINISHED',
  FETCH_EXTERNAL_MEMBERS_START: 'FETCH_EXTERNAL_MEMBERS_START',
  FETCH_EXTERNAL_MEMBERS_SUCCESS: 'FETCH_EXTERNAL_MEMBERS_SUCCESS',
  FETCH_EXTERNAL_MEMBERS_FAILURE: 'FETCH_EXTERNAL_MEMBERS_FAILURE',
  FETCH_EXTERNAL_MEMBERS_FINISHED: 'FETCH_EXTERNAL_MEMBERS_FINISHED',
  FETCH_APP_MEMBER_START: 'FETCH_APP_MEMBER_START',
  FETCH_APP_MEMBER_SUCCESS: 'FETCH_APP_MEMBER_SUCCESS',
  FETCH_APP_MEMBER_FAILURE: 'FETCH_APP_MEMBER_FAILURE',
  FETCH_APP_MEMBER_FINISHED: 'FETCH_APP_MEMBER_FINISHED'
};

export default ShareActionTypes;
