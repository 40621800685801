import CategoryActionTypes from './category.types';

const INITIAL_STATE = {
  categoryResults: [],
  error: null,
  isLoading: false
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoryActionTypes.FETCH_CATEGORY_START:
      return { ...state, isLoading: true };
    case CategoryActionTypes.FETCH_CATEGORY_SUCCESS:
      return { ...state, categoryResults: action.payload, error: null };
    case CategoryActionTypes.FETCH_CATEGORY_FAILURE:
      return { ...state, error: action.payload };
    case CategoryActionTypes.FETCH_CATEGORY_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default categoryReducer;
