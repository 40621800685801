import { sectionItemBuilder } from '../section/sections/section.builder';
import { cityBuilder } from '../../shared/builders/cityBuilder';

export const programWithMediaBuilder = (data, communes) => {
  const dataBuilded = {
    page: null,
    program: []
  };

  dataBuilded.page = data.page;
  data.result.map(element => {
    const programItem = {
      programRef: element?.refProgram,
      name: element?.name,
      city: cityBuilder(element.cityCode, communes),
      zipCode: element?.zipCode,
      address: element?.address,
      totalImages: element?.totalImages,
      totalVideos: element?.totalVideos,
      totalDocuments: element?.totaldocuments,
      mediaURL: element?.thumbnailUrl,
      code: element?.code,
      section: sectionItemBuilder(element?.section),
      filial: element?.refOrganizationalEntity
    };

    return dataBuilded.program.push(programItem);
  });
  return dataBuilded;
};

function programListBuilder(programList, communes) {
  return programList?.map(element => {
    const cityBuilded = cityBuilder(element.cityCode, communes);
    return {
      programRef: element.refProgram,
      name: element.name,
      city: cityBuilded,
      totalImages: element.totalImages,
      totalVideos: element.totalVideos,
      totalDocuments: element?.totaldocuments,
      code: element.code
    };
  });
}
export function programWithoutMediaBuilder(data, communes) {
  const dataBuilded = {
    page: null,
    program: []
  };

  dataBuilded.page = data.page;
  dataBuilded.program = programListBuilder(data.result, communes);

  return dataBuilded;
}
