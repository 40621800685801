export default {
  programModification: {
    color: '#C82832',
    marginTop: '0px',
    marginBottom: '15px',
    fontFmily: 'Neo Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '2px'
  },

  mediaOrganisation: {
    color: '#C82832',
    marginTop: '0px',
    marginBottom: '15px',
    fontFmily: 'Neo Sans Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    padding: '2px',
    marginLeft: 'auto',
    marginRight: '35px'
  },

  folderIcon: {
    fontSize: '80px'
  },

  returnButton: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '30px'
  }
};
