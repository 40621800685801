import React from 'react';
import logoImage from '../../../assets/images/logo.png';
import t from '../../../constants/translates';
import './style.scss';
import { useWindowDimensions } from '../../../shared/functions/useWindowDimensions';

const Logo = () => {
  const bigScreenResolution = useWindowDimensions();

  return (
    <>
      <img
        crossOrigin="anonymous"
        src={logoImage}
        alt="logo"
        className="menu__logo"
      />
      {bigScreenResolution && (
        <p className="menu__title">{t.header.menuTitle}</p>
      )}
    </>
  );
};
export default Logo;
