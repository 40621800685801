import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonComponent from '../button/buttonComponent.jsx';
import t from '../../constants/translates';
import styles from './style';
import './styles.scss';
import {
  regex,
  snackbarDuration,
  EMPTY_STRING,
  MIN_SEARCH_USERS_LENGTH,
  MY_SELECTION_FORM_PROPS,
  OK_RESPONSE,
  ORIGINAL_MEDIA_INSTANCE
} from '../../constants/global-constants.jsx';
import { snackBarSeverity } from '../../constants/ui.constants.jsx';
import SnackbarAlert from '../snackBar/SnackbarAlert.jsx';

const MySelectionContact = ({
  open,
  shareMedia,
  medias,
  fetchInternalMembers,
  internalMembersResult,
  externalMembersResult,
  fetchExternalMembers,
  shareResult,
  shareError
}) => {
  const style = makeStyles(styles)();
  const [dataUser, setDataUser] = useState({
    email: '',
    name: '',
    lastName: ''
  });
  const [shareToUser, setShareToUser] = useState([]);
  const [nameMedias, setNameMedias] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [toastseverity, setToastseverity] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [shouldShowToast, setShouldShowToast] = useState(false);
  useEffect(() => {
    const newArray = medias?.map(media => {
      return {
        refMedia: media?.mediaDetails?.refMedia,
        refFile: media?.isOriginal
          ? ORIGINAL_MEDIA_INSTANCE.refValue
          : media?.instance?.refFile || media?.refFile
      };
    });
    setNameMedias(newArray);
  }, [medias]);

  useEffect(() => {
    setIsButtonDisabled(
      !(
        (shareToUser.length || dataUser?.email?.length > 0) &&
        nameMedias.length > 0
      )
    );
  }, [nameMedias, dataUser]);

  useEffect(() => {
    if (shareResult === OK_RESPONSE && shouldShowToast) {
      setToastMessage(t.mySelectionContact.sendOk);
      setToastseverity(snackBarSeverity.success);
      setOpenToast(true);
      setShouldShowToast(false);
      setTimeout(() => {
        open(false);
      }, 1000);
    } else if (shareError && shouldShowToast) {
      setToastMessage(t.mySelectionContact.sendFail);
      setToastseverity(snackBarSeverity.error);
      setOpenToast(true);
      setShouldShowToast(false);
    }
  }, [shareResult, shareError, shouldShowToast]);

  useEffect(() => {
    setDataUser({
      email: currentUser?.email,
      name: currentUser?.firstName,
      lastName: currentUser?.lastName
    });
  }, [currentUser]);

  /**
   * remove tag
   * @param {Object} tag object
   */
  const removeContact = tag => {
    setShareToUser(shareToUser?.filter(item => item?.email !== tag?.email));
  };

  useEffect(() => {
    setDataUser({
      email: EMPTY_STRING,
      name: EMPTY_STRING,
      lastName: EMPTY_STRING
    });
  }, [shareToUser]);

  useEffect(() => {
    let newArray;
    if (
      externalMembersResult?.length > 0 ||
      internalMembersResult?.length > 0
    ) {
      newArray = internalMembersResult?.concat(externalMembersResult);
      setUsers(newArray);
    }
  }, [internalMembersResult, externalMembersResult]);

  useEffect(() => {
    const name = dataUser?.name;
    const lastName = dataUser?.lastName;

    if (
      name?.length >= MIN_SEARCH_USERS_LENGTH &&
      lastName?.length >= MIN_SEARCH_USERS_LENGTH
    ) {
      fetchInternalMembers({ name, lastName });
      fetchExternalMembers(`${name} ${lastName}`);
    } else if (lastName?.length >= MIN_SEARCH_USERS_LENGTH) {
      fetchInternalMembers({ lastName });
      fetchExternalMembers(lastName);
    } else if (name?.length >= MIN_SEARCH_USERS_LENGTH) {
      fetchInternalMembers({ name });
      fetchExternalMembers(name);
    }
  }, [dataUser?.name, dataUser?.lastName]);

  const printNameOrEmail = tag =>
    tag?.name ? `${tag?.name} ${tag?.lastName}` : tag?.email;
  /**
   * Get the list of tags span elements
   */
  const getContactsSpanList = () => {
    return shareToUser?.map(tag => (
      <span key={tag?.email} className="tag__field">
        <span>{printNameOrEmail(tag)}</span>
        <span
          className="close__field-email"
          role="button"
          tabIndex="0"
          onKeyPress={() => removeContact(tag)}
          onClick={() => removeContact(tag)}
        >
          {t.close}
        </span>
      </span>
    ));
  };

  const userInfoChangeHandler = (prop, value) => {
    setDataUser({ ...dataUser, [prop]: value });
  };

  const handleChange = prop => event => {
    setDataUser({ ...dataUser, [prop]: event.target.value });
  };

  /**
   * Add contact for send medias
   */
  const addContactForShare = () => {
    const email = dataUser?.email;
    const name = dataUser?.name;
    const lastName = dataUser?.lastName;

    const newArray = [...shareToUser];
    if (email.length > 0) {
      if (regex.email.test(email?.toLowerCase())) {
        newArray.push({ email, name, lastName });
        setShareToUser(newArray);
        getContactsSpanList();
      } else {
        setToastMessage(t.mySelectionContact.errorEmail);
        setToastseverity(snackBarSeverity.error);
        setOpenToast(true);
      }
    }
    return null;
  };

  /**
   * Function for send the data to back
   */
  const sendShareData = () => {
    const newArray = [];
    if (shareToUser.length === 0) {
      newArray.push(dataUser?.email);
    } else {
      shareToUser.forEach(item => newArray.push(item?.email));
    }
    const payload = {
      recipients: newArray,
      files: nameMedias
    };
    setShouldShowToast(true);
    shareMedia(payload);
    setShareToUser([]);
  };

  const handleAutocompleteNameChange = (e, value) => {
    const userFind = users.find(user => user?.showNameAndEmail === value);
    setCurrentUser(userFind);
    userInfoChangeHandler(MY_SELECTION_FORM_PROPS.name, userFind?.firstName);
  };

  const handleAutocompleteLastNameChange = (e, value) => {
    const userFind = users.find(user => user?.showLastNameAndEmail === value);
    setCurrentUser(userFind);
    userInfoChangeHandler(MY_SELECTION_FORM_PROPS.lastName, userFind?.lastName);
  };

  return (
    <div className="share-form">
      <SnackbarAlert
        id="AddedAlert"
        severity={toastseverity}
        open={openToast}
        message={toastMessage}
        handleClose={() => {
          setOpenToast(false);
          setShouldShowToast(false);
        }}
        duration={snackbarDuration}
      />
      <div className="share-form__title">
        <span className="share-form__title-text">
          {t.mySelectionContact.shareTheSelection}
        </span>
        <span className="share-form__close">
          <CloseIcon onClick={() => open(false)} />
        </span>
      </div>
      <div>
        <form noValidate autoComplete="off" className="share-form__form">
          <TextField
            id="emailAutocompleteTextField"
            className={style.formInput}
            value={dataUser?.email}
            label={t.mySelectionContact.emailLabel}
            InputProps={{
              type: MY_SELECTION_FORM_PROPS.search
            }}
            onChange={handleChange(MY_SELECTION_FORM_PROPS.email)}
          />
          <Autocomplete
            freeSolo
            id="lastNameAutocomplete"
            disableClearable
            options={users?.map(option => option?.showLastNameAndEmail)}
            onChange={handleAutocompleteLastNameChange}
            value={dataUser?.lastName}
            renderInput={params => (
              <TextField
                id="lastNameAutocompleteTextField"
                {...params}
                className={style.formInput}
                value={dataUser?.lastName}
                label={t.mySelectionContact.lastNameLabel}
                onChange={handleChange(MY_SELECTION_FORM_PROPS.lastName)}
                InputProps={{
                  ...params.InputProps,
                  type: MY_SELECTION_FORM_PROPS.search
                }}
              />
            )}
          />
          <Autocomplete
            freeSolo
            id="nameAutocomplete"
            disableClearable
            options={users?.map(option => option?.showNameAndEmail)}
            onChange={handleAutocompleteNameChange}
            value={dataUser?.name}
            renderInput={params => (
              <TextField
                {...params}
                id="lastNameAutocompleteTextField"
                className={style.formInput}
                value={dataUser?.name}
                label={t.mySelectionContact.nameLabel}
                onChange={handleChange(MY_SELECTION_FORM_PROPS.name)}
                InputProps={{
                  ...params.InputProps,
                  type: MY_SELECTION_FORM_PROPS.search
                }}
              />
            )}
          />
        </form>
      </div>
      <div className="share-form__add-more">
        <span
          id="add-contact"
          disabled
          className="add-more__text"
          onClick={() => addContactForShare()}
          role="button"
          tabIndex="0"
          onKeyPress={() => addContactForShare()}
        >
          {t.mySelectionContact.attachAddressee}
        </span>
      </div>
      <div>{getContactsSpanList()} </div>
      <div className="share-form__button-container">
        <ButtonComponent
          id="send-button"
          className={style.sendToButton}
          onClick={sendShareData}
          disabled={isButtonDisabled}
        >
          <span className="send-button">{t.mySelectionContact.send}</span>
        </ButtonComponent>
      </div>
    </div>
  );
};

MySelectionContact.propTypes = {
  open: PropTypes.func,
  medias: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  shareMedia: PropTypes.func,
  fetchInternalMembers: PropTypes.func,
  internalMembersResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  externalMembersResult: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  fetchExternalMembers: PropTypes.func,
  shareResult: PropTypes.string,
  shareError: PropTypes.string
};

export default MySelectionContact;
