import { createSelector } from 'reselect';

const selectLastMedias = state => state.lastMedias;

export const selectLastMediasResult = createSelector(
  [selectLastMedias],
  medias => medias?.lastMediaResult?.results?.withMedia
);

export const selectLastMediasLoading = createSelector(
  [selectLastMedias],
  medias => medias?.isLoading
);

export const selectLastMediasError = createSelector(
  [selectLastMedias],
  medias => medias?.error
);

export const selectNumberMedias = createSelector(
  [selectLastMedias],
  medias => medias?.lastMediaResult?.page?.totalElements
);
