export default {
  treeItem: {
    fontSize: '12px',
    lineHeight: '16px'
  },
  showMoreButton: {
    fontFamily: 'Avenir Next LT Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#000000'
  }
};
