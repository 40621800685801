export default {
  iconStyle: {
    fontSize: '40px'
  },
  iconStyleWhite: {
    fontSize: '40px',
    color: '#ffffff'
  },
  userText: {
    fontSize: '10px',
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  menu: {
    backgroundColor: 'white'
  },
  logOut: {
    color: '#C82832',
    width: '368px'
  },
  logOutIcon: {
    marginRight: '5px'
  }
};
