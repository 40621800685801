import React from 'react';
import PropTypes from 'prop-types';
import MUIRichTextEditor from 'mui-rte';

const TextEditor = ({ dataProp, isAdmin, putCms }) => {
  const save = data => {
    putCms(data);
  };

  return (
    <div>
      <MUIRichTextEditor
        label="Écrivez quelque chose ici..."
        defaultValue={
          dataProp !== undefined && dataProp.length > 0 ? dataProp : null
        }
        onSave={save}
        inlineToolbar
        readOnly={!isAdmin}
        toolbar={isAdmin}
      />
    </div>
  );
};

TextEditor.propTypes = {
  isAdmin: PropTypes.bool,
  dataProp: PropTypes.string,
  putCms: PropTypes.func
};

export default TextEditor;
