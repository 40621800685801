import { put, all, call, takeLatest } from 'redux-saga/effects';
import programDetailsActionTypes from './program-details.types';
import { programDetailsBuilder } from './program-details.builder';
import api from '../../api/api.jsx';
import {
  fetchProgramDetailsSuccess,
  fetchProgramDetailsFailure,
  fetchProgramDetailsFinished
} from './program-details.actions';

export function* fetchProgramDetails({ payload: refProgram }) {
  try {
    const { data } = yield call(api.getProgramDetails, refProgram);
    const dataBuilder = yield call(programDetailsBuilder, data);

    yield put(fetchProgramDetailsSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchProgramDetailsFailure(error));
  }
  yield put(fetchProgramDetailsFinished());
}

export function* onFetchProgramDetailsStart() {
  yield takeLatest(
    programDetailsActionTypes.FETCH_PROGRAM_DETAILS_START,
    fetchProgramDetails
  );
}

export function* ProgramDetailsSagas() {
  yield all([call(onFetchProgramDetailsStart)]);
}
