import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './style.jsx';
import text from '../../constants/translates';
import { modeSelection } from '../../constants/global-constants';
import { fetchSectionStart } from '../../redux/section/sections/section.actions';
import { selectSectionsResult } from '../../redux/section/sections/section.selectors';
import { selectedMode } from '../../redux/modeController/modeController.selectors.js';

/**
 * Component for show the submenu with the categories
 */
const Submenu = ({
  fetchSectionResults,
  sectionResults,
  onCategoryChange,
  showNewSectionTab,
  forcedSelectSectionByRef,
  className,
  selectedModeProp
}) => {
  const [value, setValue] = React.useState(forcedSelectSectionByRef);
  const [newSection, setNewSection] = React.useState([]);

  /**
   * Effect to fetch section results
   */
  useEffect(() => {
    if (!sectionResults.length > 0) {
      fetchSectionResults();
    }
  }, []);

  /**
   * Effect for know who call the submenu for display the correct section selected
   */
  useEffect(() => {
    let searchParam;
    if (forcedSelectSectionByRef) {
      searchParam = forcedSelectSectionByRef;
      setNewSection([]);
    }

    const sec = sectionResults?.find(elm => elm.refSection === searchParam);

    if (sec) {
      setValue(sec?.refSection);
      if (showNewSectionTab) {
        onCategoryChange(sec);
      }
    } else if (showNewSectionTab) {
      setValue(sectionResults[0]?.refSection);
      onCategoryChange(sectionResults[0]);
    }
  }, [sectionResults, forcedSelectSectionByRef]);

  function allProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    let category = {};
    if (newValue >= sectionResults.length) {
      category = {
        refSection: text.AdminCategories.refNewSection,
        shortLabel: text.AdminCategories.newSection
      };
      setNewSection([category]);
      setValue(category.refSection);
    } else {
      category = sectionResults.find(sec => sec.refSection === newValue);
      setValue(newValue);
    }
    onCategoryChange(category);
  };

  const addNewSectionTab = () => (
    <Tab
      label={text.AdminCategories.addSection}
      style={styles.addSectionTab}
      {...allProps('addNewSection')}
    />
  );

  return (
    <>
      {/* Disable the submenu if we are in mode selection mode */}
      {selectedModeProp?.mode === modeSelection ? (
        <></>
      ) : (
        <div>
          <AppBar position="static" className={className}>
            <Tabs
              value={value || forcedSelectSectionByRef}
              textColor="primary"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              style={styles.scrollButton}
            >
              {sectionResults.length > 0 &&
                sectionResults.concat(newSection)?.map(section => {
                  if (
                    showNewSectionTab ||
                    section?.urlSection ||
                    section?.url
                  ) {
                    return (
                      <Tab
                        key={section.refSection}
                        label={section.shortLabel}
                        style={styles.menuTab}
                        value={section.refSection}
                        {...allProps(section.refSection)}
                      />
                    );
                  }

                  return null;
                })}
              {showNewSectionTab && addNewSectionTab()}
            </Tabs>
          </AppBar>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  sectionResults: selectSectionsResult,
  selectedModeProp: selectedMode
});

const mapDispatchToProps = dispatch => ({
  fetchSectionResults: () => dispatch(fetchSectionStart())
});

Submenu.propTypes = {
  fetchSectionResults: PropTypes.func,
  sectionResults: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  onCategoryChange: PropTypes.func,
  showNewSectionTab: PropTypes.bool,
  forcedSelectSectionByRef: PropTypes.string,
  className: PropTypes.string,
  selectedModeProp: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

export default connect(mapStateToProps, mapDispatchToProps)(Submenu);
