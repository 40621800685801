import CreateFolder from './createFolderTypes';

const INITIAL_STATE = {
  message: null,
  error: null,
  isLoading: false
};

const createFolderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CreateFolder.SEND_CREATE_FOLDER_START:
      return { ...state, isLoading: true, succeed: false };

    case CreateFolder.SEND_CREATE_FOLDER_SUCCESS:
      return { ...state, message: action.payload, error: null, succeed: true };

    case CreateFolder.SEND_CREATE_FOLDER_FAILURE:
      return { ...state, error: action.payload.response.data, succeed: false };

    case CreateFolder.SEND_CREATE_FOLDER_FINISHED:
      return { ...state, isLoading: false, succeed: false };
    default:
      return state;
  }
};

export default createFolderReducer;
