import { put, all, call, takeLatest } from 'redux-saga/effects';
import MediaOriginalActionTypes from './media-original.types';
import api from '../../api/api.jsx';
import {
  fetchMediaOriginalSuccess,
  fetchMediaOriginalFailure,
  fetchMediaOriginalFinish
} from './media-original.actions';

export function* fetchMediaOriginal({ payload: params }) {
  try {
    const data = yield call(api.getMediaOriginal, params);
    yield put(fetchMediaOriginalSuccess(data));
  } catch (error) {
    yield put(fetchMediaOriginalFailure(error));
  }
  yield put(fetchMediaOriginalFinish());
}

export function* onFetchMediaOriginalStart() {
  yield takeLatest(
    MediaOriginalActionTypes.FETCH_MEDIA_ORIGINAL_START,
    fetchMediaOriginal
  );
}

export function* mediaOriginalSagas() {
  yield all([call(onFetchMediaOriginalStart)]);
}
