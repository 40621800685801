import TagListTypes from './tag.list.types';

const INITIAL_STATE = {
  result: [],
  error: null,
  isLoading: false
};

const TagListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TagListTypes.TAG_LIST_START:
      return { ...state, isLoading: true };
    case TagListTypes.TAG_LIST_SUCCESS:
      return { ...state, result: action.payload, error: null };
    case TagListTypes.TAG_LIST_FAILURE:
      return { ...state, error: action.payload };
    case TagListTypes.TAG_LIST_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default TagListReducer;
