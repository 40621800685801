export default {
  cancelButton: {
    background: '#4E5360',
    width: '170px'
  },
  saveButton: {
    background: '#C82832',
    'border-radius': '10px',
    width: '600px',
    '&:disabled': {
      border: '1px solid #555555',
      color: '#BF7703',
      background: '#C82832',
      opacity: 0.5
    }
  }
};
