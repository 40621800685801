import { put, all, call, takeLatest } from 'redux-saga/effects';
import SearchCopyActionTypes from './searchCopy.types';
import { searchCopyBuilder } from './searchCopy-builder';
import api from '../../api/api.jsx';
import {
  fetchSearchCopyResultsSuccess,
  fetchSearchCopyResultsFailure,
  fetchSearchCopyResultsFinished,
  fetchSearchCopyResultsWithMediaSuccess,
  fetchSearchCopyResultsWithMediaFailure,
  fetchSearchCopyResultsWithMediaFinished,
  fetchSearchCopyResultsWithoutMediaSuccess,
  fetchSearchCopyResultsWithoutMediaFailure,
  fetchSearchCopyResultsWithoutMediaFinished,
  fetchSearchCopySuggestionsSuccess,
  fetchSearchCopySuggestionsFinished,
  fetchSearchCopySuggestionsFailure
} from './searchCopy.actions';
import { SEARCH_TYPE } from '../../constants/global-constants';

export function* fetchSearchCopyResults({ payload: params }) {
  try {
    const { data } = yield call(api.getSearchResults, params);
    const dataBuilder = yield call(searchCopyBuilder, data);
    yield put(fetchSearchCopyResultsSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSearchCopyResultsFailure(error?.data));
  }
  yield put(fetchSearchCopyResultsFinished());
}

export function* onFetchSearchCopyResultsStart() {
  yield takeLatest(
    SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_START,
    fetchSearchCopyResults
  );
}

export function* fetchSearchCopyResultsWithMedia({ payload: params }) {
  try {
    let paramsWithMedia = {
      ...params
    };

    if (params?.type === SEARCH_TYPE.program) {
      paramsWithMedia = {
        ...paramsWithMedia,
        withMedia: 'TRUE'
      };
    }
    const { data } = yield call(api.getSearchResults, paramsWithMedia);
    const dataBuilder = yield call(searchCopyBuilder, data);
    yield put(fetchSearchCopyResultsWithMediaSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSearchCopyResultsWithMediaFailure(error?.data));
  }
  yield put(fetchSearchCopyResultsWithMediaFinished());
}

export function* onFetchSearchCopyResultsWithMediaStart() {
  yield takeLatest(
    SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHMEDIA_START,
    fetchSearchCopyResultsWithMedia
  );
}

export function* fetchSearchCopyResultsWithoutMedia({ payload: params }) {
  try {
    let paramsWithoutMedia = {
      ...params,
      size: 2000
    };

    if (params?.type === SEARCH_TYPE.program) {
      paramsWithoutMedia = {
        ...paramsWithoutMedia,
        withMedia: 'FALSE'
      };
    }

    const { data } = yield call(api.getSearchResults, paramsWithoutMedia);
    const dataBuilder = yield call(searchCopyBuilder, data);
    yield put(fetchSearchCopyResultsWithoutMediaSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSearchCopyResultsWithoutMediaFailure(error?.data));
  }
  yield put(fetchSearchCopyResultsWithoutMediaFinished());
}

export function* onFetchSearchCopyResultsWithoutMediaStart() {
  yield takeLatest(
    SearchCopyActionTypes.FETCH_SEARCHCOPY_RESULTS_WITHOUTMEDIA_START,
    fetchSearchCopyResultsWithoutMedia
  );
}

export function* fetchSearchCopySuggests({ payload: params }) {
  try {
    const { data } = yield call(api.getSearchCopySuggestions, params);
    yield put(fetchSearchCopySuggestionsSuccess(data?.results));
  } catch (error) {
    yield put(fetchSearchCopySuggestionsFailure(error?.data));
  }
  yield put(fetchSearchCopySuggestionsFinished());
}

export function* onFetchSearchCopySuggestsStart() {
  yield takeLatest(
    SearchCopyActionTypes.FETCH_SEARCHCOPY_SUGGESTIONS_START,
    fetchSearchCopySuggests
  );
}

export function* searchCopyResultsSagas() {
  yield all([
    call(onFetchSearchCopyResultsStart),
    call(onFetchSearchCopySuggestsStart),
    call(onFetchSearchCopyResultsWithMediaStart),
    call(onFetchSearchCopyResultsWithoutMediaStart)
  ]);
}
