import { put, all, call, takeLatest } from 'redux-saga/effects';
import SubformatActionTypes from './subformat.types';
import { subformatListBuilder } from './subformat.builder';
import apiNomenclature from '../../api/apiNomenclature';
import {
  fetchSubformatSuccess,
  fetchSubformatFailure,
  fetchSubformatFinished
} from './subformat.actions';

export function* fetchSubformat({ payload: params }) {
  try {
    const { data } = yield call(apiNomenclature.getListOfSubformats, params);
    const dataBuilder = yield call(subformatListBuilder, data);
    yield put(fetchSubformatSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchSubformatFailure(error));
  }
  yield put(fetchSubformatFinished());
}

export function* onFetchSubformatStart() {
  yield takeLatest(SubformatActionTypes.FETCH_SUBFORMAT_START, fetchSubformat);
}

export function* subformatSagas() {
  yield all([call(onFetchSubformatStart)]);
}
