export default {
  iconTop: {
    borderRadius: '5px'
  },
  iconBot: {
    background: 'rgba(245, 246, 250, 0.5)',
    borderRadius: '5px',
    height: '30px',
    paddingTop: '6px',
    marginRight: '6px'
  },
  playIcon: {
    height: '200px',
    width: '200px',
    fill: 'white'
  }
};
