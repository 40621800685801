import PutConditionsTypes from './putConditionsTypes';

export const sendPutConditionsDataStart = formData => ({
  type: PutConditionsTypes.SEND_PUT_CONDITIONS_START,
  payload: formData
});

export const sendPutConditionsDataSuccess = message => ({
  type: PutConditionsTypes.SEND_PUT_CONDITIONS_SUCCESS,
  payload: message
});

export const sendPutConditionsDataFailure = error => ({
  type: PutConditionsTypes.SEND_PUT_CONDITIONS_FAILURE,
  payload: error
});

export const sendPutConditionsDataFinished = () => ({
  type: PutConditionsTypes.SEND_PUT_CONDITIONS_FINISHED
});
