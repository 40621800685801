import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import folderDetailsActionTypes from './folder-details.types';
import { folderDetailsBuilder } from './folder-details.builder';
import api from '../../api/api.jsx';
import {
  fetchFolderDetailsSuccess,
  fetchFolderDetailsFailure,
  fetchFolderDetailsFinished
} from './folder-details.actions';

export const selectCreateProgram = state => state.createProgram;

export function* fetchFolderDetails({ payload: params }) {
  try {
    const { data } = yield call(api.getFolderDetails, params);
    const { communes } = yield select(selectCreateProgram);
    const dataBuilder = yield call(folderDetailsBuilder, data, communes);
    yield put(fetchFolderDetailsSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchFolderDetailsFailure(error));
  }
  yield put(fetchFolderDetailsFinished());
}

export function* onFetchFolderDetailsStart() {
  yield takeLatest(
    folderDetailsActionTypes.FETCH_FOLDER_DETAILS_START,
    fetchFolderDetails
  );
}

export function* FolderDetailsSagas() {
  yield all([call(onFetchFolderDetailsStart)]);
}
