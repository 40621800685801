import { put, all, call, takeLatest } from 'redux-saga/effects';
import ModificationTypes from './media-modification.types';
import { modificationBuilder } from './media-modification.builder';
import api from '../../api/api.jsx';
import {
  sendModificationDataSuccess,
  sendModificationDataFailure,
  sendModificationDataFinished
} from './media-modification.actions';

export function* sendModificationMedia({ payload: media }) {
  try {
    const data = yield call(api.patchModificationMedias, media);
    const dataBuilder = yield call(modificationBuilder, data);
    yield put(sendModificationDataSuccess(dataBuilder));
  } catch (error) {
    yield put(sendModificationDataFailure(error));
  }
  yield put(sendModificationDataFinished());
}

export function* onSendModificationMedia() {
  yield takeLatest(
    ModificationTypes.SEND_MODIFICATION_DATA_START,
    sendModificationMedia
  );
}

export function* modificationSagas() {
  yield all([call(onSendModificationMedia)]);
}
