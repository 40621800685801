import NaturalPersonsTypes from './naturalPersons.types';

const INITIAL_STATE = {
  result: [],
  error: null,
  isLoading: false
};

const NaturalPersonsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NaturalPersonsTypes.FETCH_NATURALPERSONS_START:
      return { ...state, isLoading: true };
    case NaturalPersonsTypes.FETCH_NATURALPERSONS_SUCCESS:
      return { ...state, result: action.payload, error: null };
    case NaturalPersonsTypes.FETCH_NATURALPERSONS_FAILURE:
      return { ...state, error: action.payload };
    case NaturalPersonsTypes.FETCH_NATURALPERSONS_FINISHED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default NaturalPersonsReducer;
