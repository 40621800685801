import React from 'react';
import { Combobox as ComboboxWidget } from 'react-widgets';
import PropTypes from 'prop-types';
import removeAccents from 'remove-accents';
import t from '../../constants/translates';
import './combobox.scss';

const Combobox = ({ label, data, onChangeHandler, ...props }) => {
  function filterAccents(valueFromList, valueSearched) {
    const valueFromListWithoutAccent = removeAccents(
      valueFromList
    ).toLowerCase();
    const valueSearchedWithoutAccent = removeAccents(
      valueSearched
    ).toLowerCase();
    return valueFromListWithoutAccent.includes(valueSearchedWithoutAccent);
  }

  return (
    <>
      <p className="label">{label}</p>
      <ComboboxWidget
        {...props}
        valueField="id"
        className="combobox"
        textField="name"
        data={data}
        filter={filterAccents}
        messages={{
          emptyList: t.emptyList,
          emptyFilter: t.noResults
        }}
        onChange={onChangeHandler}
      />
    </>
  );
};

Combobox.propTypes = {
  label: PropTypes.string,
  onChangeHandler: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
};

export default Combobox;
