import TagListTypes from './tag.list.types';

export const fetchTagListStart = params => ({
  type: TagListTypes.TAG_LIST_START,
  payload: params
});

export const fetchTagListSuccess = data => ({
  type: TagListTypes.TAG_LIST_SUCCESS,
  payload: data
});

export const fetchTagListFailure = error => ({
  type: TagListTypes.TAG_LIST_FAILURE,
  payload: error
});

export const fetchTagListFinished = () => ({
  type: TagListTypes.TAG_LIST_FINISHED
});
