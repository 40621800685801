import { SEARCH_TYPES, PROGRAM_TYPE } from '../../constants/global-constants';

const mediaItemBuilder = (item, datathumbnails) => {
  return {
    refMedia: item?.refmedia,
    name: item?.medianame,
    nameExt: item?.nameExt,
    section: {
      name: item?.sectionname,
      refSection: item?.sectioncode
    },
    category: item?.category?.length > 0 ? item?.category[0] : undefined,
    subCategory:
      item?.subcategory?.length > 0
        ? item?.subcategory[0].split('/')[1]
        : undefined,
    status: item?.status,
    typeMedia: item?.typemedia,
    mediaContentType: item?.mediacontenttype,
    dateUpload: item?.createddate,
    userUpload: item?.createdby,
    dateUpdate: item?.updateddate,
    userUpdate: item?.updatedby,
    height: item?.height,
    width: item?.width,
    size: item?.size,
    thumbnailUrl: item?.thumbnail,
    thumbnailS3: datathumbnails?.find(
      thumbItem => thumbItem.thumbnailApiUrl === item?.thumbnail
    )?.thumbnail,
    programName: item?.programname,
    city: {
      name: item?.city
    },
    programRef: item?.refprogram,
    format: item?.format,
    code: item?.refprogram,
    title: item?.title
  };
};

const programItemBuilder = (item, datathumbnails) => {
  return {
    programRef: item?.refprogram,
    name: item?.programname,
    address: item?.address,
    thumbnailUrl: item?.thumbnail,
    thumbnailS3: datathumbnails?.find(
      thumbItem => thumbItem.thumbnailApiUrl === item?.thumbnail
    )?.thumbnail,
    city: {
      name: item?.city
    },
    totalImages: item?.totalimages,
    totalVideos: item?.totalvideos,
    totalDocuments: item?.totaldocuments,
    section: {
      name: item?.sectionname,
      type:
        item?.sectioncode === PROGRAM_TYPE.IE_PROGRAM_NAME
          ? PROGRAM_TYPE.IE_PROGRAM
          : PROGRAM_TYPE.IR_PROGRAM,
      refSection: item?.sectioncode
    },
    code: item?.refprogram,
    organizationalentitypaths: item?.organizationalentitypaths,
    legalStructure: item?.legalstructure,
    cityText: item?.citytext,
    department: item?.department,
    regionText: item?.regiontext,
    location: {
      lat: item?.lat,
      lng: item?.lng
    },
    perimeter: item?.perimeter
  };
};

const matchItemBuilder = (item, type, datathumbnails) => {
  if (SEARCH_TYPES.program === type) {
    return programItemBuilder(item, datathumbnails);
  }

  return mediaItemBuilder(item, datathumbnails);
};

const subBucketItemBuilder = item => {
  return {
    value: item?.value,
    count: item?.count
  };
};

const bucketItemBuilder = item => {
  return {
    value: item?.value,
    count: item?.count,
    subFacet: {
      name: item?.subFacet?.fieldName,
      subBuckets: item?.subFacet?.subBuckets?.map(sub =>
        subBucketItemBuilder(sub)
      )
    }
  };
};

const facetsItemBuilder = item => {
  return {
    name: item?.fieldName,
    buckets: item?.buckets?.map(bucket => bucketItemBuilder(bucket))
  };
};

const facetsListBuilder = list => {
  return list?.map(item => facetsItemBuilder(item));
};

const resultsBuilder = (list, datathumbnails) => {
  return list?.map(item => {
    return {
      // eslint-disable-next-line camelcase
      match: matchItemBuilder(item?.match, item?.type_document, datathumbnails),
      // eslint-disable-next-line camelcase
      type: item?.type_document
    };
  });
};

const pageItemBuilder = item => {
  return {
    nextCursor: item?.nextCursor,
    size: item?.size,
    totalElements: item?.totalElements,
    totalPages: Math.ceil(item?.totalElements / item?.size)
  };
};

export function searchBuilder(data, datathumbnails) {
  const resBuilder = resultsBuilder(data?.results, datathumbnails);
  const res = {
    withoutMedia: resBuilder
      ?.filter(
        search =>
          search.type === SEARCH_TYPES.program &&
          search.match?.totalVideos === 0 &&
          search.match?.totalImages === 0
      )
      ?.map(item => item?.match),
    withMedia: resBuilder?.filter(
      search =>
        (search.type === SEARCH_TYPES.program &&
          (search.match?.totalVideos > 0 || search.match?.totalImages > 0)) ||
        search.type === SEARCH_TYPES.media
    )
  };
  return {
    results: res,
    facets: facetsListBuilder(data?.facets),
    page: pageItemBuilder(data?.page)
  };
}
