import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const LabelInput = ({ label, onChangeHandler, ...props }) => (
  <>
    <p className="label">{label}</p>
    <input className="input" onChange={onChangeHandler} {...props} />
  </>
);

LabelInput.propTypes = {
  label: PropTypes.string,
  onChangeHandler: PropTypes.func
};

export default LabelInput;
