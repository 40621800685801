/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Badge } from '@material-ui/core';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AppsIcon from '@material-ui/icons/Apps';
import { withRouter } from 'react-router-dom';
import SearchInput from '../../searchInput/SearchInput';
import texts from '../../../constants/translates';
import styles from './style.jsx';
import './style.scss';
import MySelection from '../../../containers/mySelection/MySelection.jsx';
import { selectMyCurrentSelection } from '../../../redux/mySelection/mySelection.selectors.js';
import { selectSearchToolbarVisible } from '../../../redux/search/search.selectors';
import { selectLogin } from '../../../redux/login/loginSelectors';
import { ROUTES_CONSTANTS } from '../../../constants/route.constants';
import { modeSelection } from '../../../constants/global-constants';
import { getFilialLogo } from '../../../shared/functions/filialLogo.jsx';
import { selectedMode } from '../../../redux/modeController/modeController.selectors';
import { clearAllCookies } from '../../../shared/functions/domFunctions';
import { useWindowDimensions } from '../../../shared/functions/useWindowDimensions';
import { checkVitrine } from '../../../shared/functions/vitrine';

const Header = ({
  history,
  myCurrentSelection,
  login,
  location,
  userInfo,
  selectedModeProp
}) => {
  const classes = makeStyles(styles)();
  const [showMyCollection, setShowMyCollection] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteSearch, setDeleteSearch] = useState(false);
  const bigScreenResolution = useWindowDimensions();

  const handleShowMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    clearAllCookies();
    login.logOut();
  };
  const redirect = () => {
    if (selectedModeProp?.mode !== modeSelection && checkVitrine(history)) {
      // Disable home redirection if we are in mode selection mode
      if (history?.location?.pathname.includes('vitrine')) {
        setDeleteSearch(true);
        history.push(ROUTES_CONSTANTS.vitrineMediatheque);
      } else {
        window.location.reload(false);
      }
    } else {
      history.push(ROUTES_CONSTANTS.home);
    }
  };

  return (
    <div className="menu__div">
      <AppBar position="fixed" style={styles.menu}>
        <Toolbar disableGutters>
          <span onClick={() => redirect()} className="menu__span">
            {getFilialLogo(userInfo)}
          </span>

          {location.pathname !== ROUTES_CONSTANTS.home && (
            <section className="menu__search">
              {selectedModeProp?.mode !== modeSelection && (
                <SearchInput deleteSearch={deleteSearch} />
              ) // Disable recherche glpbal if we are in mode selection mode
              }
            </section>
          )}
          <section className="menu__toolbar--right">
            <IconButton
              color="inherit"
              aria-label="menu"
              id="buttonMenu"
              onClick={handleShowMenu}
            >
              <PersonOutlineOutlinedIcon style={styles.iconStyle} />
              {bigScreenResolution && (
                <Typography style={styles.userText} id="userTitle">
                  {texts.formatString(texts.header.welcome, {
                    userName: login?.user?.givenName
                  })}
                </Typography>
              )}
              <KeyboardArrowDownOutlinedIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              getContentAnchorEl={null}
              onClose={handleClose}
              className={classes.menuUser}
            >
              <MenuItem onClick={handleLogout} className={classes.logOut}>
                <PowerSettingsNewIcon style={styles.logOutIcon} />
                <span>{texts.header.logout}</span>
              </MenuItem>
            </Menu>
            {!checkVitrine(history) && (
              <section className="menu__button--gray">
                <IconButton
                  id="button-show-my-collection"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setShowMyCollection(true)}
                >
                  <Badge
                    color="secondary"
                    badgeContent={myCurrentSelection?.length}
                  >
                    <AppsIcon style={styles.iconStyleWhite} />
                  </Badge>
                  <Typography variant="body1" className="menu__text--white">
                    {texts.header.collection}
                  </Typography>
                </IconButton>
                <MySelection
                  open={showMyCollection}
                  setOpen={setShowMyCollection}
                />
              </section>
            )}
          </section>
        </Toolbar>
      </AppBar>
    </div>
  );
};

Header.propTypes = {
  history: PropTypes.func,
  myCurrentSelection: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string])
  ),
  login: PropTypes.oneOfType([PropTypes.string]),
  location: PropTypes.objectOf(PropTypes.object()),
  userInfo: PropTypes.string,
  selectedModeProp: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

const mapStateToProps = createStructuredSelector({
  myCurrentSelection: selectMyCurrentSelection,
  searchVisible: selectSearchToolbarVisible,
  login: selectLogin,
  selectedModeProp: selectedMode
});

export default withRouter(connect(mapStateToProps)(Header));
