/* eslint-disable no-unused-expressions */
import { put, all, call, takeLatest } from 'redux-saga/effects';
import ModeControllerTypes from './modeController.types';
import { ROUTES_CONSTANTS } from '../../constants/route.constants';
import {
  setModeSuccess,
  sendMediasToCallbackSuccess,
  sendMediasToCallbackFailure,
  sendMediasToCallbackFinished
} from './modeController.actions';
import apiCallback from '../../api/apiCallback';

const getRefMediasFromSelection = medias => {
  let refMedias = '';
  // eslint-disable-next-line array-callback-return
  medias.map((media, index) => {
    if (index + 1 === medias.length) {
      refMedias += `${media.mediaDetails?.refMedia}`;
    } else {
      refMedias += `${media.mediaDetails?.refMedia},`;
    }
  });
  return refMedias;
};

export function* setMode({ payload: params }) {
  yield put(setModeSuccess(params.modeParams));

  if (params?.modeParams?.programCode) {
    params?.history?.push(
      `${ROUTES_CONSTANTS.mediaSummaryBase}${params.modeParams.programCode}`
    );
  } else {
    params?.history?.push('/');
  }
}

export function* sendMediasToCallback({ payload: params }) {
  try {
    const getRefMedias = yield getRefMediasFromSelection(params.items);
    yield call(apiCallback.postSelectionMedias, {
      getRefMedias,
      callback: params.callback
    });
    yield put(sendMediasToCallbackSuccess());
  } catch (error) {
    yield put(sendMediasToCallbackFailure(error));
  }
  yield put(sendMediasToCallbackFinished());
}

export function* onSetMode() {
  yield takeLatest(ModeControllerTypes.SET_MODE_START, setMode);
}

export function* onSendMediasToCallback() {
  yield takeLatest(
    ModeControllerTypes.SEND_MEDIAS_TO_CALLBACK_START,
    sendMediasToCallback
  );
}

export function* setModeSagas() {
  yield all([call(onSetMode), call(onSendMediasToCallback)]);
}
