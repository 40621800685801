import { put, all, call, takeLatest } from 'redux-saga/effects';
import CopyProgramListTypes from './copy.program.list.types';
import api from '../../api/api.jsx';
import {
  fetchCopyProgramListFailure,
  fetchCopyProgramListFinished,
  fetchCopyProgramListSuccess
} from './copy.program.list.actions';
import { searchBuilder } from '../search/search-builder';

/**
 * Sagas manager for program list results at copy/paste modal
 * @param {Object} params Params from fetch start
 */
export function* CopyProgramListResults({ payload: params }) {
  try {
    const { data } = yield call(api.getSearchResults, params);
    const dataBuilder = yield call(searchBuilder, data);
    yield put(fetchCopyProgramListSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchCopyProgramListFailure(error));
  }
  yield put(fetchCopyProgramListFinished());
}

/**
 * Saga fetch when copy/paste program list action start
 */
export function* onFetchCopyProgramListStart() {
  yield takeLatest(
    CopyProgramListTypes.FETCH_COPY_PROGRAM_LIST_START,
    CopyProgramListResults
  );
}

/**
 * Sagas of copy program list
 */
export function* CopyProgramListSagas() {
  yield all([call(onFetchCopyProgramListStart)]);
}
