import ProgramListCleoTypes from './programListCleoTypes';

export const fetchProgramListCleoStart = params => ({
  type: ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_START,
  payload: params
});

export const fetchProgramListCleoSuccess = data => ({
  type: ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_SUCCESS,
  payload: data
});

export const fetchProgramListCleoFailure = error => ({
  type: ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_FAILURE,
  payload: error
});

export const fetchProgramListCleoFinished = () => ({
  type: ProgramListCleoTypes.FETCH_PROGRAM_LIST_CLEO_FINISHED
});
