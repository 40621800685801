import { put, all, call, takeLatest } from 'redux-saga/effects';
import Keycloak from 'keycloak-js';
import apiNomenclature from '../../api/apiNomenclature';
import apiIntervenants from '../../api/apiIntervenants';
import apiStructure from '../../api/apiStructure';
import api from '../../api/api';
import apiCpi from '../../api/apiCpi';
import apiTlc from '../../api/apiTlc';
import apiPlg from '../../api/apiPlg';
import loginActionTypes from './loginTypes';
import {
  fetchLoginSuccess,
  fetchLoginFailure,
  fetchLoginFinished,
  fetchLoginUpdateSuccess,
  fetchLoginUpdateFailure,
  fetchLoginUpdateFinished
} from './loginActions';
import { loginBuilder } from './loginBuilder';
import {
  KEYCLOAK_ROOT_URL,
  KEYCLOAK_FRONT_ID,
  KEYCLOAK_REALM_APP
} from '../../api/api.conf';
import { EXTERNE } from '../../constants/global-constants';
import { ATTRIBUTES } from '../../shared/rights/roles';

const keyConfig = {
  realm: KEYCLOAK_REALM_APP,
  url: KEYCLOAK_ROOT_URL,
  public: true,
  clientId: KEYCLOAK_FRONT_ID
};

const initConfig = {
  onLoad: 'check-sso',
  promiseType: 'native',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  pkceMethod: 'S256',
  token: localStorage.jwtToken,
  refreshToken: localStorage.refreshToken
};

/**
 * configure partenaire keycloak
 */
if (window.location.href.includes(EXTERNE)) {
  // this condition should be added after demo !isInternal() &&
  initConfig.redirectUri =
    window.location.origin +
    '/partner/' +
    window.location.href.split(EXTERNE)[1];
}

const kc = new Keycloak(keyConfig);

function* updateToken() {
  try {
    return yield call(kc.updateToken, 5);
  } catch (error) {
    kc.login();
    return false;
  }
}

export function* fetchLogin() {
  let hasRoleExterne = false;
  try {
    const authenticated = yield call(kc.init, initConfig);
    if (!authenticated) {
      kc.login();
    }

    const dataBuilder = yield call(loginBuilder, kc, authenticated);

    if (dataBuilder?.user?.roles?.includes(ATTRIBUTES.EXTERN)) {
      hasRoleExterne = true;
    }
    yield call(
      api.setAutorizationToken,
      dataBuilder.token,
      dataBuilder.refreshToken,
      dataBuilder.sessionId,
      dataBuilder.expireIn
    );
    yield call(
      apiNomenclature.setAutorizationToken,
      dataBuilder.token,
      dataBuilder.refreshToken
    );
    yield call(apiIntervenants.setAutorizationToken, dataBuilder.token);
    yield call(apiStructure.setAutorizationToken, dataBuilder.token);
    yield call(apiCpi.setAutorizationToken, dataBuilder.token);
    yield call(apiTlc.setAutorizationToken, dataBuilder.token);
    yield call(apiPlg.setAutorizationTokenPlg, dataBuilder.token);

    yield put(fetchLoginSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchLoginFailure(error));
  }
  yield put(fetchLoginFinished());

  // If user come from partner login and has the correct role we make redirection to home
  if (window.location.href.includes(EXTERNE) && hasRoleExterne) {
    window.location = window.location.origin;
  }
}

export function* fetchLoginUpdate() {
  try {
    yield call(updateToken);
    const dataBuilder = yield call(loginBuilder, kc, true);
    yield call(
      api.setAutorizationToken,
      dataBuilder.token,
      dataBuilder.refreshToken
    );
    yield call(
      apiNomenclature.setAutorizationToken,
      dataBuilder.token,
      dataBuilder.refreshToken
    );
    yield call(apiPlg.setAutorizationTokenPlg, dataBuilder.token);

    yield put(fetchLoginUpdateSuccess(dataBuilder));
  } catch (error) {
    yield put(fetchLoginUpdateFailure(error));
  }
  put(fetchLoginUpdateFinished());
}

export function* onFetchLoginStart() {
  yield takeLatest(loginActionTypes.FETCH_LOGIN_START, fetchLogin);
}

export function* onFetchLoginUpdateStart() {
  yield takeLatest(loginActionTypes.FETCH_LOGIN_UPDATE_START, fetchLoginUpdate);
}

export function* LoginSagas() {
  yield all([call(onFetchLoginStart), call(onFetchLoginUpdateStart)]);
}
