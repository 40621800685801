export const VERSIONS = {
  version1: 'v1'
};

export const PATHS = {
  medias: '/media',
  programs: '/programs',
  categories: '/categories',
  copy: '/copy',
  sections: '/sections',
  folders: '/folders',
  tags: '/tags',
  files: '/files',
  partners: '/partners',
  search: '/search',
  suggest: '/suggest',
  thumbnail: '/thumbnail',
  userInfo: '/userInfo',
  mediaShare: '/media/share',
  cms: '/cms',
  faq: '/faq',
  generalConditions: '/general-conditions',
  login: '/login',
  download: '/download',
  tlc: '/tlc',
  lots: '/lots',
  indicators: '/indicators',
  assumptions: '/assumptions',
  refProgram: '?refPrograms=',
  favAssumptions: '/favorite-assumption',
  blocks: '/blocks',
  operations: '/operations',
  advancedSearch: '/advanced-search'
};

export const API_ROUTES = {
  medias: `${VERSIONS.version1}${PATHS.medias}`,
  programs: `${VERSIONS.version1}${PATHS.programs}`,
  categories: `${VERSIONS.version1}${PATHS.categories}`,
  sections: `${VERSIONS.version1}${PATHS.sections}`,
  folders: `${VERSIONS.version1}${PATHS.folders}`,
  tags: `${VERSIONS.version1}${PATHS.tags}`,
  searchResults: `${VERSIONS.version1}${PATHS.search}`,
  searchSuggest: `${VERSIONS.version1}${PATHS.suggest}`,
  createProgram: `${VERSIONS.version1}${PATHS.programs}`,
  publishMedia: `${VERSIONS.version1}${PATHS.medias}`,
  partners: `${VERSIONS.version1}${PATHS.partners}`,
  userInfo: `${PATHS.userInfo}`,
  shareMedia: `${VERSIONS.version1}${PATHS.mediaShare}`,
  getFaq: `${VERSIONS.version1}${PATHS.cms}${PATHS.faq}`,
  putFaq: `${VERSIONS.version1}${PATHS.cms}${PATHS.faq}`,
  getGeneralConditions: `${VERSIONS.version1}${PATHS.cms}${PATHS.generalConditions}`,
  putGeneralConditions: `${VERSIONS.version1}${PATHS.cms}${PATHS.generalConditions}`,
  partnersLogin: `${VERSIONS.version1}${PATHS.partners}${PATHS.login}`,
  download: `${PATHS.download}`,
  infoProgram: `${VERSIONS.version1}${PATHS.programs}`,
  lot: `${VERSIONS.version1}${PATHS.tlc}${PATHS.lots}${PATHS.indicators}${PATHS.refProgram}`,
  assumptions: `${VERSIONS.version1}${PATHS.assumptions}`,
  favAssumptions: `${PATHS.favAssumptions}${PATHS.blocks}`,
  advancedSearch: `api/${VERSIONS.version1}${PATHS.operations}${PATHS.advancedSearch}`
};
// api/v1/operations/advanced-search?pageSize=1&pageIndex=1
