import thumnailsTypes from './thumbnailsTypes';

const INITIAL_STATE = {
  thumbnails: [],
  error: null,
  isLoading: false,
  singleThumbnail: {},
  singleThumbnailLoading: false,
  singleThumbnailError: null
};

const thumbnailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case thumnailsTypes.GET_THUMBNAILS_START:
      return { ...state, isLoading: true };

    case thumnailsTypes.GET_THUMBNAILS_SUCCESS:
      return {
        ...state,
        thumbnails: state.thumbnails.slice(-50, 50).concat(action.payload),
        error: null
      };

    case thumnailsTypes.GET_THUMBNAILS_FAILURE:
      return { ...state, error: action.payload };

    case thumnailsTypes.GET_THUMBNAILS_FINISHED:
      return { ...state, isLoading: false };
    case thumnailsTypes.GET_SINGLE_THUMBNAILS_START:
      return { ...state, singleThumbnailLoading: true };
    case thumnailsTypes.GET_SINGLE_THUMBNAILS_SUCCESS:
      return {
        ...state,
        singleThumbnail: action.payload,
        singleThumbnailError: null
      };
    case thumnailsTypes.GET_SINGLE_THUMBNAILS_FAILURE:
      return { ...state, singleThumbnailError: action.payload };
    case thumnailsTypes.GET_SINGLE_THUMBNAILS_FINISHED:
      return { ...state, singleThumbnailLoading: false };
    case thumnailsTypes.RESET_THUMBNAILS:
      return { ...state, thumbnails: [] };
    default:
      return state;
  }
};

export default thumbnailsReducer;
