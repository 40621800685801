import { createSelector } from 'reselect';

const selectSubformats = state => state.subformats;

export const selectSubformatsResult = createSelector(
  [selectSubformats],
  subformats => subformats
);

export const selectSubformatsCount = createSelector(
  [selectSubformats],
  subformats => subformats?.length
);

export const selectSubformatsLoading = createSelector(
  [selectSubformats],
  subformats => subformats.isLoading
);

export const selectSubformatsError = createSelector(
  [selectSubformats],
  subformats => subformats.error
);
