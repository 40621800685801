export default {
  buttonShowMore: {
    border: '1px solid #BF7703',
    boxSizing: 'border-box',
    borderRadius: '10px',
    color: '#BF7703',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      border: '1px solid #BF7703',
      color: '#BF7703'
    }
  },
  addMediaButton: {
    marginLeft: 'auto'
  },
  gridContainer: {
    alignContent: 'flex-start'
  }
};
